// Gestion de cookie dans angular: https://itnext.io/angular-8-how-to-use-cookies-14ab3f2e93fc

import * as jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { GraphQLClient } from 'graphql-request';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material';
import { LoadingComponent } from '../@wegeo/_modale/loading/loading.component';
import drawLocales from 'leaflet-draw-locales';
import { environment } from 'src/environments/environment';

declare var L: any;

@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  HOST_URL = environment.url;
  ERROR_HOST_URL = env.ERROR_HOST_URL;
  API_URL = env.API_URL;
  API_HOST = env.API_HOST;
  MAPBOX_TOKEN = 'sk.eyJ1IjoibmFmOTQiLCJhIjoiY2s5Y21zMnJ5MDAwYjNvdDc4MzlibnZkZiJ9.YRbsJnKjgrYBee-EAdbVjg';

  TYPE_USER = {
    acheteur: 71,
    planteur: 70,
    cooperative: 72,
    union: 73,
    federation: 75,
  };
  /*
  HOST_URL = "http://192.168.1.16:8008/graphql/";
  API_URL = "http://192.168.1.16:8008/media/";
  */
  PAGINATION = 2000;
  NAVIGATION = [
    {
      link: '/wegeo/dashboard',
      name: 'Tableau de bord',
      image: '../../assets/img/sig/Dashboard-Map/MenuInactive/drawable-xhdpi/Dashboard.png',
      active_image: '../../assets/img/sig/Dashboard-Map/MenuActive/drawable-xhdpi/Dashboard.png',
      active: true,
      hover: false
    },
    {
      link: '/wegeo/employes',
      name: 'Gestion des employés',
      image: '../../assets/img/sig/Dashboard-Map/MenuInactive/drawable-xhdpi/GestionGroup.png',
      active_image: '../../assets/img/sig/Dashboard-Map/MenuActive/drawable-xhdpi/GestionGroup.png',
      active: false,
      hover: false
    },
    {
      link: '/wegeo/plantation',
      name: 'Planteurs & Plantations',
      image: '../../assets/img/sig/Dashboard-Map/MenuInactive/drawable-xhdpi/Planteurs.png',
      active_image: '../../assets/img/sig/Dashboard-Map/MenuActive/drawable-xhdpi/Planteurs.png',
      active: false,
      hover: false
    },
    {
      link: '/wegeo/formulaire',
      name: 'Formulaire',
      image: '../../assets/img/sig/Dashboard-Map/MenuInactive/drawable-xhdpi/Forms.png',
      active_image: '../../assets/img/sig/Dashboard-Map/MenuActive/drawable-xhdpi/Forms.png',
      active: false,
      hover: false
    },
    {
      link: '/wegeo/zone',
      name: 'Zones',
      image: '../../assets/img/sig/Dashboard-Map/MenuInactive/drawable-xhdpi/Zone.png',
      active_image: '../../assets/img/sig/Dashboard-Map/MenuActive/drawable-xhdpi/Zone.png',
      active: false,
      hover: false
    },
    {
      link: '/wegeo/performance',
      name: 'Performance',
      image: '../../assets/img/sig/Dashboard-Map/MenuInactive/drawable-xhdpi/Performance.png',
      active_image: '../../assets/img/sig/Dashboard-Map/MenuActive/drawable-xhdpi/Performance.png',
      active: false,
      hover: false
    },
    /*  {
       link: "/wegeo/employes/formation",
       name: "Gestion des formations",
       image: "../../assets/img/sidebar/formation.png",
       active: false
     }, */
    // {
    //   link: '/wegeo/tracking',
    //   name: 'Tracking',
    //   image: '../../assets/img/sig/Dashboard-Map/MenuInactive/drawable-xhdpi/Tracking1.png',
    //   active_image: '../../assets/img/sig/Dashboard-Map/MenuActive/drawable-xhdpi/Tracking1.png',
    //   active: false,
    //   hover: false
    // }
    /* ,
    {
      link: '/market/',
      name: 'Marketplace',
      image: '../../assets/ico/market_place.png',
      active: false
    }, */
    // {
    //   link: "/wegeo/admin",
    //   name: "Administration",
    //   image: "../../assets/ico/administration.png",
    //   active: false
    // }
  ];

  MARKET_NAVIGATION = [
    {
      link: '/market',
      name: 'Accueil',
      image: '../../assets/ico/market_place.png',
      active: true,
      hover: false
    },
    {
      link: '/market/dashboard',
      name: 'Tableau de bord',
      image: '../../assets/ico/Tableau_de_bord.png',
      active: false,
      hover: false
    },
  ];

  DRONE_NAVIGATION = [
    {
      link: '/drone/rapport',
      name: 'Rapports',
      image: '../../assets/img/sidebar/dashboard.png'
    },
    {
      link: '/wegeo/dashboard',
      name: 'Gestion d\'équipe',
      image: '../../assets/img/sidebar/dashboard.png'
    },
    {
      link: '/drone/gestion-parcelle',
      name: 'Gestion de parcelle',
      image: '../../assets/img/sidebar/dashboard.png'
    }
  ];

  // client = new GraphQLClient(this.HOST_URL, {
  //   headers: {
  //     Authorization: `JWT ${this.cookie.get('weflyHeader_token')}`,
  //     // entreprise:  ''
  //     // HTTP_AUTHORIZATION: `JWT ${this.cookie.get("weflyHeader_token")}`
  //   }
  // });

  client = new GraphQLClient(this.HOST_URL, {
    headers: {
      Authorization: `JWT ${this.getStorage('weflyHeader_token')}`,
      // entreprise:  ''
      // HTTP_AUTHORIZATION: `JWT ${this.cookie.get("weflyHeader_token")}`
    },
  
  });

  clientErrors = new GraphQLClient(this.ERROR_HOST_URL);

  TypeTime = {
    annee: 1,
    mois: 2,
    jour: 3 | 0,
    heure: 4,
    minute: 5,
    seconde: 6,
    milliseconde: 7
  };
  userInfo: any = null;
  measureControl:any

  constructor(
    private snakbar: MatSnackBar,
    private cookie: CookieService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
  
  ) {
    try {
      this.userInfo = JSON.parse(this.decrypt(this.getCookie('_wefly_')));

      // debugger
      // && this.userInfo.profile.admin == true
      if (this.userInfo != null) {
        this.NAVIGATION.push({
          link: '/wegeo/admin',
          name: 'Administration',
          image: '../../assets/img/sig/Dashboard-Map/MenuInactive/drawable-xhdpi/Admin.png',
          active_image: '../../assets/img/sig/Dashboard-Map/MenuActive/drawable-xhdpi/Admin.png',
          active: false,
          hover: false
        });
        /* if (this.userInfo.profile.admin) {

        } */
        // Chargement formulaire
      }
    } catch (error) { }

  }

  formIsNotNull(f: any, required: string[]): string[] {
    const inputWithError: string[] = [];
    Object.keys(f.value).forEach(elt => {
      if (required.indexOf(elt) > -1) {
        // on vérifie que le champs en cours n'est pas obligatoire
        if (!f.value[elt] || f.value[elt] === '') {
          // le champs est obligatoire et on vérifie mtn qu'il est non vide
          inputWithError.push(elt); // si le champs ne contient rien
        }
      }
    });

    return inputWithError;
  }

  encrypt(str: string, key: string = 'wefly_agri_v3'): string {
    return btoa(CryptoJS.AES.encrypt(str.trim(), key.trim()).toString());
  }

  decrypt(str: string, key: string = 'wefly_agri_v3'): string {
    return CryptoJS.AES.decrypt(atob(str.trim()), key.trim()).toString(CryptoJS.enc.Utf8);
  }

  lengthOf(obj: any) {
    /*
     *
     * fonction retournant la taille des objets envoyés
     */
    let lengthObj = 0;
    Object.keys(obj).forEach(elt => {
      lengthObj = lengthObj + 1;
    });
    return lengthObj;
  }

  async saveUserToken(token:string, username, userId, entrepriseId) {
    const res = await Promise.resolve();
    this.cookie.set('weflyHeader_token', token, this.setExpired(), '/', '', false, 'Lax');
//  this.cookie.set("weflyHeader_username", username, this.setExpired(), "/");
//  this.cookie.set("weflyHeader_id", userId, this.setExpired(), "/");
//  this.cookie.set("weflyHeader_entreprise", entrepriseId, this.setExpired(),"/");
    console.log('saving cookies', res);
  }

  getUserToken() {
    try {
      return jwt_decode(this.cookie.get('weflyHeader_token'));
    } catch (Error) {
      return null;
    }
  }
  getUserToken1(tokenName:string) {
return new Promise((resolve, reject)=>{
  let token:string;
  token = jwt_decode(this.getStorage(tokenName));
  resolve(token)
})
  }

  setStorage(key:string, value:string){
    return localStorage.setItem(key, value)
  }
  getStorage(key:string){
    return localStorage.getItem(key)
  }
  deleteStorage(key:string){
     localStorage.removeItem(key)
  }

  getCurrentUser() {
    return JSON.parse(this.decrypt(this.getCookie('_wefly_')));
  }

  fileToBase64(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.selectedFile = reader.result;
        return reader.result;
      };
    } else {
    }
  }

  getTimes() {
    const hours = [];
    const mins = [];
    for (let i = 0; i <= 23; i++) {
      if (i < 10) {
        hours.push(
          {
            name: i < 10 ? `0${i}` : `${i}`,
            id:i
          }
        );
      } else {
        hours.push(
          {
            name:i < 10 ? `0${i}` : `${i}`,
            id:i
          }
        );
      }
    }

    for (let i = 0; i <= 59; i++) {
      if (i < 10) {
        mins.push(
          {
            name:i < 10 ? `0${i}` : `${i}`,
            id:i
          }
        );
      } else {
        mins.push( {
          name:i < 10 ? `0${i}` : `${i}`,
          id:i
        });
      }
    }
    return { heure: hours, minutes: mins };
  }

  createQuery(mop, filter: [any]) {
    const values = [];
    filter.forEach(element => {
      values.push(element);
    });
    const operator = {
      op: mop,
      value: filter
    };
    return operator;
  }

  snack(msg) {
    this.snakbar.open(msg, 'Ok', { duration: 8000 });
  }

  public setCookie(
    name: string,
    value: string,
    expired: { nb: number; unite: number } = { nb: 7, unite: 3 },
    path = '/'
  ): void {
    try {
      this.cookie.set(
        name,
        value,
        this.setExpired(expired.nb, expired.unite),
        path,
        '',
        false,
        'Lax'
      );
    } catch (e) {
    }
  }

  public getCookie(name: string = null): any {
    // retourne le cookie dont le nom est passé en paramètre ou tout les cookies si rien n'est passé en argument
    return name == null ? this.cookie.getAll() : this.cookie.get(name);
  }

  public removeCookie(name: string = null, path = '/'): void {
    // supprime le cookie dont le nom est passé en paramètre ou tout les cookies si rien n'est passé en argument
    if (name != null) {
      this.cookie.delete(name, path);
    } else {
      this.cookie.deleteAll(path);
    }
  }

  public setExpired(nb: number = 7, unite: number = 3): Date {
    // renvoie la date d'expiration(bien formatée) des cookies(par défaut ce sera  7 jours) en tenant compte de l'unité de temps envoyé (année=1, mois=2, jours=3, heure=4, minute=5, seconde=6, milliseconde=7)
    let now = Date.now();
    let annee = new Date(now).getUTCFullYear();
    let bix =
      annee % 400 == 0 || (annee % 4 == 0 && annee % 100 != 0) ? 366 : 365; // année bissextile ou pas, si oui 366 sinon 365
    switch (unite) {
      case this.TypeTime.annee:
        return new Date(now + 3600 * 24 * bix * nb * 1000);
        break;
      case this.TypeTime.mois:
        return new Date(now + 3600 * 24 * 30 * nb * 1000); // on suppose qu'un mois fait 30 jours ici
        break;
      case this.TypeTime.heure:
        return new Date(now + 3600 * nb * 1000);
        break;
      case this.TypeTime.minute:
        return new Date(now + 60 * nb * 1000);
        break;
      case this.TypeTime.seconde:
        return new Date(now + nb * 1000);
        break;
      case this.TypeTime.milliseconde:
        return new Date(now + nb);
        break;
      default:
        // alert(new Date(now + (3600 * 24 * nb)));
        return new Date(now + 3600 * 24 * nb * 1000);
        break;
    }
  }

  public dateFormater(
    annee: number = new Date().getFullYear(),
    mois: number = new Date().getMonth(),
    jours: number = new Date().getDate(),
    heure: number = new Date().getHours(),
    minute: number = new Date().getMinutes(),
    seconde: number = new Date().getSeconds(),
    milliseconde: number = new Date().getMilliseconds()
  ): Date {
    // renvoie une date formatée
    return new Date(annee, mois, jours, heure, minute, seconde, milliseconde);
  }

  public dateTimeFormater(d: Date): String {
    let mois = d.getMonth() < 9 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    let jour = d.getDate() < 9 ? '0' + d.getDate() : d.getMonth();
    return (d.getFullYear() + '-' + mois + '-' + jour + 'T' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds());
  }

  public genTabNumber(max: number, min: number = 1): number[] {
    // permet de générer des number en ordre à partir d'un min et un max passé en paramètre.

    let tab: number[] = [];
    for (let i = min; i <= max; i++) tab.push(i);
    return tab;
  }

  public logout(): void {
    
    this.removeCookie('weflyHeader_token');
    this.removeCookie('weflyHeader_username');
    this.removeCookie('weflyHeader_id');
    this.removeCookie('weflyHeader_entreprise');
    this.removeCookie('wefly_profile');
    this.removeCookie('_wefly_');
    sessionStorage.removeItem('entreprise');
    this.deleteStorage('weflyHeader_token')
    this.deleteStorage('token')
  }

  public myround(str: string, nbAfterVirgule: number = 4): string {
    return parseFloat(str).toFixed(nbAfterVirgule);
  }

  public replaceAll(recherche: String, remplacement: String, chaineAModifier:any): string {
    return chaineAModifier.split(recherche).join(remplacement);
  }

  public setErrorsHttp(errors: any) {
    return
    let errors_string = `${errors}`
    let deviceInfo = this.deviceService.getDeviceInfo();
    const query = `
      query($os : String, $version : String, $message : String, $cpu : Int!, $user : String){
        error(data : {
          side : "WEB",
          os : $os,
          version : $version,
          message : $message,
          cpu_number : $cpu,
          user_id : $user
        }){
          id,
        }
      }
    `
    return new Promise((resolve, reject) => {
       this.clientErrors.request(query, { 'os': deviceInfo.browser, 'version': deviceInfo.browser_version, 'message': errors_string, 'cpu': window.navigator.hardwareConcurrency, 'user': JSON.parse(this.decrypt(this.getCookie('_wefly_'))).id }).then(
        (res: any) => {
          resolve(res)
        },
        (error: any) => {
          reject(error)
        }
      )
    });
  }

  public getEntrepriseFilter(route: ActivatedRoute) {
    return route.snapshot.paramMap.get('entrepriseId');
  }

  get Entreprise(): any {
    let data: Object = JSON.parse(this.decrypt(this.getCookie('_wefly_')));
    return data['profile']['entreprise'];
  }

  fullFilter(filter: string, file_name: string): string {
    if (this.Entreprise['id'] != null && filter.indexOf('entreprise') == -1) {
      filter = filter + `, ${file_name} : "${this.Entreprise['id']}"`
    }
    return filter
  }

  capitalizeFLetter(text) {
    const data = text.toLowerCase();
    return data.charAt(0).toUpperCase() +
      data.slice(1);
  }

  convertValue(value: any): string {
    let regx = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])T(\d{2}):\d{2}/
    if (typeof value == 'string') {
      if (value == 'false' || value == 'true') {
        if (value == 'true') {
          value = 'OUI'
        } else {
          value = 'NON'
        }
      }
      else if (value.search(regx) != -1) {
        this.dateFormat(regx.exec(value)[0].split('T')[0])
        value = `${this.dateFormat(regx.exec(value)[0].split('T')[0])} à ${regx.exec(value)[0].split('T')[1]}`;
      }
    }
    return value
  }

  dateFormat(date: string) {
    return `${date.split('-')[2]}-${date.split('-')[1]}-${date.split('-')[0]}`
  }

  openLoadingDialog(): void {
    const dialogRef = this.dialog.open(LoadingComponent, {
      width: '300px',
      disableClose: true,
      id: 'wefly_loading_modale_',
      data:{msg:"Veuillez patienter svp ..."}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public getMyPosition(): number[] {
    let geo = [5.555256597490789, -5.768960955969305];
    if (navigator.geolocation) {
      // L'API est disponible
      navigator.geolocation.getCurrentPosition(
        maPosition,
        errorCallback,
        {
          enableHighAccuracy: true,
          timeout: 10000, // 10s
          maximumAge: 0
        }
      );
    } else {
      // Pas de support, proposer une alternative ?
    }

    function maPosition(position) {
      geo = [position.coords.latitude, position.coords.longitude];
    }

    // La fonction d'échec teste l'erreur contenue dans "error"
    function errorCallback(error) {
      //Un message d'erreur est affiché selon le type d'erreur
      switch (error.code) {
        case error.PERMISSION_DENIED:
          break;
        case error.POSITION_UNAVAILABLE:
          break;
        case error.TIMEOUT:
          break;
        case error.UNKNOW_ERROR:
          break;
      }
    }

    return geo;
  }

  public get mapBox() {
 

    // tslint:disable-next-line: max-line-length
    return L.tileLayer('https://api.mapbox.com/styles/v1/naf94/ckhhopnxn2go319nu282fi710/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibmFmOTQiLCJhIjoiY2s5Y21nMzNjMDA3cTNlczUxNTU4Y2s0YiJ9.KduEUSPHqfHmsjg5EQAKpw', {
      // tslint:disable-next-line: max-line-length
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 24,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'your.mapbox.access.token'
    });

 
  }

  public get openStreetMap() {

     this.measureControl = new L.Control.Measure({
      position: 'topright',
      locale:'fr',
      primaryLengthUnit: 'meters',
      secondaryLengthUnit: 'kilometers',
      primaryAreaUnit: 'sqmeters',
      secondaryAreaUnit: 'hectares'
  });

    let zoom = {
      minZoom: 3,
      maxZoom: 18
    };
    return L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { minZoom: zoom.minZoom, maxZoom: zoom.maxZoom });
  }

  public get worldImage() {
    let zoom = {
      minZoom: 3,
      maxZoom: 18
    };
    // tslint:disable-next-line: max-line-length
    return L.tileLayer('//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { minZoom: zoom.minZoom, maxZoom: zoom.maxZoom, 
    //attribution: '&copy; Esri &mdash; Sources: Esri, DigitalGlobe, Earthstar Geographics, CNES/Airbus DS, GeoEye, USDA FSA, USGS, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community' 
  });
  }

  public get googleSatelite() {
    let zoom = {
      minZoom: 3,
      maxZoom: 23
    };
    // tslint:disable-next-line: max-line-length
    return L.tileLayer('https://api.mapbox.com/styles/v1/naf94/ckzwthza7000014rvih7lx5gm/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibmFmOTQiLCJhIjoiY2s5Y21nMzNjMDA3cTNlczUxNTU4Y2s0YiJ9.KduEUSPHqfHmsjg5EQAKpw',
     {
        minZoom: zoom.minZoom,
        maxZoom: zoom.maxZoom,
        //attribution: '&copy; Esri &mdash; Sources: Esri, DigitalGlobe, Earthstar Geographics, CNES/Airbus DS, GeoEye, USDA FSA, USGS, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community'
      });
  }





  public callMap(Maptype = 'default'): any {
    let zoom = {
      minZoom: 3,
      maxZoom: 18
    };
    let map: any;
   
    let mapBox = this.mapBox;

    let openStreetMap = this.openStreetMap;

    let worldImg = this.worldImage;
    

    

    if (Maptype === 'mapBox') {
      
    
      map = L.map('map', {
        center: this.getMyPosition(),
        zoom: 7,
        //measureControl: true,
        layers: [mapBox],
        fullscreenControl: true,
       
       
      });
     
    
    } else {
      
      
      map = L.map('map', {
        center: this.getMyPosition(),
        zoom: 7,
       // measureControl: true,
        layers: [openStreetMap, worldImg],
        fullscreenControl: true,
      });
      this.measureControl.addTo(map);
      L.control.zoom({position:'topright'}).addTo(map);
      L.control.polylineMeasure({position:'topright'}).addTo(map);
      L.control.fullscreen({position:'topright'}).addTo(map);
      
     
    }

    /* L.control.layers({
      "Wefly Agri": mapBox,
      "Open Street Map": openStreetMap,
      "Image du monde": worldImg,
    }).addTo(map); // switch entre les différentes type de carte
 */
    try {
      const options = {
        //position: 'topleft',            // Position to show the control. Values: 'topright', 'topleft', 'bottomright', 'bottomleft'
        unit: 'metres',                 // Show imperial or metric distances. Values: 'metres', 'landmiles', 'nauticalmiles'
        clearMeasurementsOnStop: true,  // Clear all the measurements when the control is unselected
        showBearings: false,            // Whether bearings are displayed within the tooltips
        bearingTextIn: 'In',            // language dependend label for inbound bearings
        bearingTextOut: 'Out',          // language dependend label for outbound bearings
        tooltipTextFinish: 'Click to <b>finish line</b><br>',
        tooltipTextDelete: 'Press SHIFT-key and click to <b>delete point</b>',
        tooltipTextMove: 'Click and drag to <b>move point</b><br>',
        tooltipTextResume: '<br>Press CTRL-key and click to <b>resume line</b>',
        tooltipTextAdd: 'Press CTRL-key and click to <b>add point</b>',
        // language dependend labels for point's tooltips
        measureControlTitleOn: 'Turn on PolylineMeasure',   // Title for the control going to be switched on
        measureControlTitleOff: 'Turn off PolylineMeasure', // Title for the control going to be switched off
        measureControlLabel: '&#8614;', // Label of the Measure control (maybe a unicode symbol)
        measureControlClasses: [],      // Classes to apply to the Measure control
        showClearControl: false,        // Show a control to clear all the measurements
        clearControlTitle: 'Clear Measurements', // Title text to show on the clear measurements control button
        clearControlLabel: '&times',    // Label of the Clear control (maybe a unicode symbol)
        clearControlClasses: [],        // Classes to apply to clear control button
        showUnitControl: false,         // Show a control to change the units of measurements
        distanceShowSameUnit: false,    // Keep same unit in tooltips in case of distance less then 1 km/mi/nm
        unitControlTitle: {             // Title texts to show on the Unit Control button
          text: 'Change Units',
          metres: 'metres',
          landmiles: 'land miles',
          nauticalmiles: 'nautical miles'
        },
        unitControlLabel: {             // Unit symbols to show in the Unit Control button and measurement labels
          metres: 'm',
          kilometres: 'km',
          feet: 'ft',
          landmiles: 'mi',
          nauticalmiles: 'nm'
        },
        tempLine: {                     // Styling settings for the temporary dashed line
          color: '#28a745',              // Dashed line color
          weight: 2                   // Dashed line weight
        },
        fixedLine: {                    // Styling for the solid line
          color: '#28a745',              // Solid line color
          weight: 2                   // Solid line weight
        },
        startCircle: {                  // Style settings for circle marker indicating the starting point of the polyline
          color: '#000',              // Color of the border of the circle
          weight: 1,                  // Weight of the circle
          fillColor: '#0f0',          // Fill color of the circle
          fillOpacity: 1,             // Fill opacity of the circle
          radius: 3                   // Radius of the circle
        },
        intermedCircle: {               // Style settings for all circle markers between startCircle and endCircle
          color: '#000',              // Color of the border of the circle
          weight: 1,                  // Weight of the circle
          fillColor: '#ff0',           // Fill color of the circle
          fillOpacity: 1,             // Fill opacity of the circle
          radius: 3                   // Radius of the circle
        },
        currentCircle: {                // Style settings for circle marker indicating the latest point of the polyline during drawing a line
          color: '#000',              // Color of the border of the circle
          weight: 1,                  // Weight of the circle
          fillColor: '#f0f',          // Fill color of the circle
          fillOpacity: 1,             // Fill opacity of the circle
          radius: 3                   // Radius of the circle
        },
        endCircle: {                    // Style settings for circle marker indicating the last point of the polyline
          color: '#000',              // Color of the border of the circle
          weight: 1,                  // Weight of the circle
          fillColor: '#f00',          // Fill color of the circle
          fillOpacity: 1,             // Fill opacity of the circle
          radius: 3                   // Radius of the circle
        },
      };
     
    } catch (error) {
    }

    document.getElementsByClassName('leaflet-control-attribution leaflet-control')[0].innerHTML = "D\u00e9velopp\u00e9 par<a target=\"_blank\" href=\"https://www.jool-international.com/\"> Jool International</a>: +2722557825";

    map.on('fullscreenchange', function () {
      if (map.isFullscreen()) {
      } else {
      }
    });

    const locale = drawLocales('fr')
    locale.draw.toolbar.buttons.polygon = 'Awesome polygon!'
    L.drawLocal = locale

    return map;
  }

  public regexMail(str: string): boolean {
    const mailReg = /^[a-zA-Z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/;
    return mailReg.test(str);
  }

  public regexTel(str: string): boolean {
    // let numReg = /^(.[0-9]{11,13})|([0-9]{8,9})$/;
    return env.phoneReg.test(str);
  }

  public regexPassword(str: string): boolean {
    return env.passwordReg.test(str);
  }

  public processFile(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.selectedFile = reader.result;
        return reader.result;
      };
    } else {
    }
  }

  public trackExec(msg) {
  }
  public getAttibut(formCouch, acteur) {
    formCouch = formCouch.find(fcouche => fcouche.formulaire.couche.id === acteur.couche.id);
    const attributs = [];
    if(!formCouch) return
    formCouch.formulaire.compartiments.forEach((comp: any) => {
        comp.attributs.forEach((_attribut: any) => {
          attributs.push(environment.libelleBd[_attribut.attribut.libellebd]);
        });
      });
      // Ajout code plantation
    if (attributs.indexOf('codePlantation') === -1) {
      attributs.push('codePlantation');
    }
     // Ajout code planteur
    if (attributs.indexOf('codePlanteur') === -1) {
      attributs.push('codePlanteur');
    }

    return attributs;
  }


  OrdrecroisantArray(array:any[], odreName:string){
    array.sort((a, b) => {
      const nomA = a[odreName].toUpperCase(); // Convertit en majuscules pour ignorer la casse
      const nomB = b[odreName].toUpperCase();
    
      if (nomA < nomB) {
        return -1; // a précède b
      }
      if (nomA > nomB) {
        return 1; // b précède a
      }
      return 0; // a et b sont égaux
    });

    console.log('croisant', array)
  }
  ordreDecrossantArray(array:any[], odreName:string){
    array.sort((a, b) => {
      const nomA = a[odreName].toUpperCase(); // Convertit en majuscules pour ignorer la casse
      const nomB = b[odreName].toUpperCase();
    
      if (nomA > nomB) {
        return -1; // b précède a (ordre inverse)
      }
      if (nomA < nomB) {
        return 1; // a précède b (ordre inverse)
      }
      return 0; // a et b sont égaux
    });
    console.log('decroisant', array)

  }

  OrdrecroisantArrayPLanteur(array:any[], odreName:string){
    
    array.sort((a, b) => {
      const nomA = a.acteurPerso[0][odreName].toUpperCase(); // Convertit en majuscules pour ignorer la casse
      const nomB = b.acteurPerso[0][odreName].toUpperCase();
    
      if (nomA < nomB) {
        return -1; // a précède b
      }
      if (nomA > nomB) {
        return 1; // b précède a
      }
      return 0; // a et b sont égaux
    });

    console.log('croisant', array)

  }

  ordreDecrossantArrayPlanteur(array:any[], odreName:string){
    array.sort((a, b) => {
      const nomA = a.acteurPerso[0][odreName].toUpperCase(); // Convertit en majuscules pour ignorer la casse
      const nomB = b.acteurPerso[0][odreName].toUpperCase();
      if (nomA > nomB) {
        return -1; // b précède a (ordre inverse)
      }
      if (nomA < nomB) {
        return 1; // a précède b (ordre inverse)
      }
      return 0; // a et b sont égaux
    });
    console.log('decroisant', array)

  }




  OrdrecroisantArrayPLantation(array:any[], odreName:string){
    array.sort((a, b) => {
      if(a.acteurGen && a.acteurGen[0] && a.acteurGen[0][odreName]){
        const nomA = a.acteurGen[0][odreName].toUpperCase(); // Convertit en majuscules pour ignorer la casse
        const nomB = b.acteurGen[0][odreName].toUpperCase();
      
        if (nomA < nomB) {
          return -1; // a précède b
        }
        if (nomA > nomB) {
          return 1; // b précède a
        }
        return 0; // a et b sont égaux

      }
     
    });

    console.log('croisant', array)

  }

  ordreDecrossantArrayPlantation(array:any[], odreName:string){
    array.sort((a, b) => {
      if(a.acteurGen && a.acteurGen[0] && a.acteurGen[0][odreName]){
        const nomA = a.acteurGen[0][odreName].toUpperCase(); // Convertit en majuscules pour ignorer la casse
        const nomB = b.acteurGen[0][odreName].toUpperCase();
        if (nomA > nomB) {
          return -1; // b précède a (ordre inverse)
        }
        if (nomA < nomB) {
          return 1; // a précède b (ordre inverse)
        }
        return 0; // a et b sont égaux
        
      }
    
    });
    console.log('decroisant', array)

  }

}



export const env = {
  // HOST_URL: 'http://46.101.252.174:8001/graphql/',
  // API_HOST: 'http://46.101.252.174:8001/',
  API_HOST: environment.domaine + '/',
  HOST_URL: environment.domaine + '/graphql/',
  ERROR_HOST_URL: environment.domaine + '/graphql/',
  API_URL: environment.domaine + '/media/',
  // API_URL: 'http://46.101.252.174:8001/media/',
  passwordReg: /^(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-\|\[\]]{8,25}$/,
  phoneReg: /^([0-9]{8,14})$/,
}
