import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'planteur'
})
export class PlanteurPipe implements PipeTransform {

  transform(value: any[], args: string){
    if (!value) return null
    if(!args) return value
    if(typeof args !== 'string') return value


    args = args.toLowerCase();

    return value.filter((value: any) => {
    if(value.acteurPerso[0] && value.acteurPerso[0]){
      const acteurPerso = value.acteurPerso[0];
      return acteurPerso.nom.toLowerCase().includes(args) ||
          acteurPerso.prenoms.toLowerCase().includes(args);
    }
    return false
  })
  }
}
