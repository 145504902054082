import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datePipe'
})
export class DatePipePipe implements PipeTransform {

  transform(value: any): any {
    let regx = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])T(\d{2}):\d{2}/
    if(typeof value == 'string' && value.search(regx) != -1){
      this.dateFormat(regx.exec(value)[0].split("T")[0])
      value = `${this.dateFormat(regx.exec(value)[0].split("T")[0])} à ${regx.exec(value)[0].split("T")[1]}`;
    }
    return value;
  }

  dateFormat(date : string){
    return `${date.split("-")[2]}-${date.split("-")[1]}-${date.split("-")[0]}`
  }

}
