import { RegionService } from './../../../_services/region.service';
import { UtilsService } from 'src/app/_services/utils.service';
import { NgForm } from '@angular/forms';
import { PropositionDemandeService } from './../../_services/proposition-demande.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UniteDeMesureService } from 'src/app/_services/unite-mesure.service';

@Component({
  selector: 'app-proposition-demande',
  templateUrl: './proposition-demande.component.html',
  styleUrls: ['./proposition-demande.component.scss']
})
export class PropositionDemandeComponent implements OnInit {
  unites = [];
  user: any;
  regions = [];
  fields = [];
  requesting = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<PropositionDemandeComponent>,
    private uniteService: UniteDeMesureService,
    private propositionDemandeService: PropositionDemandeService,
    private regionService: RegionService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.user = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
    this.readUnite();
    this.readRegion();
   
  }

  readUnite() {
    this.uniteService.read().then(
      (res: []) => {
          this.unites = res;
      },
      (err) => {
      
      }
    );
  }

  readRegion() {
    this.regionService.read().then(
      (res: []) => {
      
        this.regions = res;
      },
      (err) => {
        
      }
    );
  }

  onSubmit(f: NgForm) {
    this.requesting = true;
    this.propositionDemandeService.create(
      f.value.commentaire,
      false,
      this.data.instance.id,
      f.value.prix,
      f.value.qte,
      f.value.region,
      f.value.unite,
      this.user.id
    ).then(
      (res) =>{
        this.requesting = false;
        this.dialogRef.close(true);
      },
      (err) => {
       
        this.requesting = false;
        this.utils.setErrorsHttp(err);
        this.dialogRef.close(false);
      }
    );
  }

}
