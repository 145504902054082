import { Component, OnInit } from '@angular/core';
import { TypeDeCoucheService } from '../_services/type-couche.service';
import { UtilsService } from '../_services/utils.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import 'jquery';
import 'bootstrap';
import { MatDialog } from '@angular/material';
import { DeletedComponent } from '../_modale/deleted/deleted.component';
import { NgForm } from '@angular/forms';
declare const $: any;
@Component({
  selector: 'app-type-couche',
  templateUrl: './type-couche.component.html',
  styleUrls: ['./type-couche.component.css']
})
export class TypeCoucheComponent implements OnInit {

  submitted  : boolean = false

  // Parametre filter
  fields_selected_ : Array<any> = [
    { libelle_db : "libelle_Icontains", libelle : "Libelle" },
    { libelle_db : "description_Icontains", libelle : "Description" }
  ]

  filter : any = {
    field : null,
    value : null
  }

  loading : boolean = true

    // Fin paramatre


  updating = false;
  couche = {
    id: '',
    libelle: '',
    description: ''
  };
  fields = [];
  Couches: any;


  totalCount :  number = 0;
  maxOfPages : number = 20;
  currentPage : number = 1;

  constructor(
    private service: TypeDeCoucheService,
    private utils: UtilsService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getTypeCouche();
  }

  getTypeCouche(page : number = 1, filter_field : any = null) {
    this.loading = true
    let filter = 'deleted : false'
    if(filter_field != null && filter_field.field != null){
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`
    }
    this.service.read(filter, page).then(
      (res: any) => {
        this.loading = false
        this.totalCount = res.searchTypeDeCouche.totalCount
        this.Couches = res.searchTypeDeCouche
      },
      (err) => {
      }
    );
  }
  clickUpdate(mcouche) {
    this.updating = true;
    this.couche.libelle = mcouche.libelle;
    this.couche.description = mcouche.description;
    this.couche.id = mcouche.id;
  }

  onSubmitformCouch(f : NgForm) {
    this.submitted = true
    this.fields = this.utils.formIsNotNull(f, ['couche.libelle', 'couche.description']);

    if (this.fields.length === 0) {
      if (this.updating) {

        this.service.update(
          this.couche.id,
          this.couche.description,
          this.couche.libelle,
          false
        ).then(
          (res: any) => {
            this.submitted = false
            this.updating = false;
            let index = this.Couches.results.findIndex(element => element.id == res.updateTypeDeCouche.typedecouche.id);
            if(index != -1){
              this.Couches.results[index] = res.updateTypeDeCouche.typedecouche;
            }
            this.snackBar.open('Mise à jour effectuée avec succès', 'Ok', {
              duration: 5000
            });
            f.reset();
          }, (err) => {
          }
        );
      } else {
        this.service.create(
          this.couche.description,
          this.couche.libelle,
          false
        ).then(
          (res: any) => {
            this.submitted = false
            f.reset();
            this.totalCount += 1
            this.snackBar.open('Type Couche Créée avec succès', 'Ok', {
              duration: 5000
            });
            this.Couches.results.unshift(res.createTypeDeCouche.typedecouche);
            // this.getTypeCouche();
          },
          (err) => {
          }
        );
      }
    }
  }

  delete(mcouche) {
    this.couche.libelle = mcouche.libelle;
    this.couche.description = mcouche.description;
    this.couche.id = mcouche.id;
    // $('#fonctionDelete').modal('show');
    if (confirm('Souhaitez vous supprimer ?')) {
      this.onDelete();
    }
  }

  openDeletedDialog(mcouche): void {
    this.couche.libelle = mcouche.libelle;
    this.couche.description = mcouche.description;
    this.couche.id = mcouche.id;

    const dialogRef = this.dialog.open(DeletedComponent, {
      width: '300px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.utils.openLoadingDialog();
        this.service.update(
          this.couche.id,
          this.couche.description,
          this.couche.libelle,
          true
        ).then(
          (res: any) => {
            this.snackBar.open('Suppression effectuée avec succès', 'Ok', { duration: 5000 });
            this.Couches.results.forEach((value, index) => {
              if (value.id === this.couche.id) {
                this.totalCount -= 1;
                this.Couches.results.splice(index, 1);
              }
            });
            this.dialog.getDialogById("wefly_loading_modale_").close()
          },
          (err) => {
            this.dialog.getDialogById("wefly_loading_modale_").close()
            // tslint:disable-next-line:max-line-length
            this.snackBar.open('Désolé un problème est survenu lors de l\'exécution de votre requête, veuillez réessayer plus tard.', 'Ok', { duration: 5000 });
          }
        );
      }else{
      }
    });
  }

  onDelete() {
    this.service.update(
      this.couche.id,
      this.couche.description,
      this.couche.libelle,
      true
    ).then(
      (res: any) => {
        this.snackBar.open('Suppression effectuée avec succès', 'Ok', { duration: 5000 });
        // this.getTypeCouche();
        this.Couches.results.forEach((value, index) => {
          if (value.id === this.couche.id) {
            this.totalCount -= 1;
            this.Couches.results.splice(index, 1);
          }
        });
      },
      (err) => {
        // tslint:disable-next-line:max-line-length
        this.snackBar.open('Désolé un problème est survenu lors de l\'exécution de votre requête, veuillez réessayer plus tard.', 'Ok', { duration: 5000 });
      }
    );
    $('#fonctionDelete').modal('hide');
  }

  addNew(f) {
    f.reset();
  }

  onChangePage(data){
    this.currentPage = data.id;
    this.getTypeCouche(data.id)
  }


  filterData(data){
    if(data.action === 0){
      return
      this.filter = data.data_
    }else{
      this.loading = true
      this.filter = data.data_
      if(data.data_.value.length == 0){
        this.getTypeCouche(1)
      }else{
        this.getTypeCouche(1, this.filter)
      }
    }
  }

}
