import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class PanierService {

  paniersSubject = new Subject<any[]>();
  private paniers : any[] = []

  constructor() { }


  emitPanierSubject() {
    this.paniersSubject.next(this.paniers.slice());
  }

  ajouterPanier(panier : any){
    let result = this.paniers.findIndex(value  => value.id == panier.id)
    if(result != -1){
      this.paniers[result] = panier
    }else{
      this.paniers.push(panier)
    }
    this.emitPanierSubject()
  }

  removePanier(id : string){
    this.paniers.filter((value, index) => {
      if(value.stock.id == id){
        this.paniers.splice(index, 1)
        this.emitPanierSubject()
        return      
      }
    })
  }

  removeAll(){ 
    this.paniers = []
    this.emitPanierSubject()
  }
}
