import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { StatisitiquesService } from '../_services/statisitiques.service';
import { UtilsService } from '../_services/utils.service';
import { UserService } from '../_services/user.service';
import { DataCacheService } from '../_services/data-cache.service';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.css']
})
export class PieComponent implements OnInit, OnChanges {
  cultureData: any = [];
  option: any = null
  chartOption: any;
  cultureDataName: String[] = [];
  eltStatRepartitioncultures: any = null;
  profileLogged: any = null;

  loading: boolean = true
  @Input() entreprise: any;
  dataCUlture:any

  constructor(
    public utils: UtilsService,
    private statServ: StatisitiquesService,
    private userService: UserService,
    private dataCulture:DataCacheService
  ) { }

  ngOnInit() {

      this.profileLogged = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_'))); 
      this.statCulture();
    
  }

  ngOnChanges() {
   // this.ngOnInit() 
  }

  // public statCulture1(): void {
  //   this.loading = true
  //       this.statServ.readRepartitioncultures(`entreprise: "${this.utils.getCurrentUser().profile.entreprise.id}"`).then(
  //         (res: any) => {
  //           console.log('res res culture', res)
  //           this.eltStatRepartitioncultures = null;
  //           if (res.results && res.results.length > 0 && res.results[0].piechart && res.results[0].piechart != "[{'value': '', 'name': ''}]") {
  //             this.eltStatRepartitioncultures = res.results[0];
  //             this.eltStatRepartitioncultures.piechart = JSON.parse(this.utils.replaceAll('\'', '"', this.eltStatRepartitioncultures.piechart));
  //             console.log(typeof(this.eltStatRepartitioncultures.piechart))
  //             this.eltStatRepartitioncultures.piechart.forEach(elt => {
  //               if (elt.name == '') {
  //                 this.cultureDataName.push('Inconnu');
  //               } else {
  //                 this.cultureDataName.push(elt.name);
  //               }
  //             });
  //             this.option = {
  //               title: {
  //                 // text: 'Repartition des cultures',
  //                 subtext: '',
  //                 // left: 'center'
  //               },
  //               tooltip: {
  //                 trigger: 'item',
  //                 formatter: '{a} <br/>{b} : {c} ({d}%)'
  //               },
  //               // legend: {
  //               //   // orient: 'vertical',
  //               //   // top: 'middle',
  //               //   bottom: 10,
  //               //   left: 'center',
  //               //   data: this.cultureDataName
  //               // },
  //               series: [
  //                 {
  //                   type: 'pie',
  //                   radius: '65%',
  //                   center: ['50%', '50%'],
  //                   selectedMode: 'single',
  //                   data: this.eltStatRepartitioncultures.piechart,
  //                   itemStyle: {
  //                     emphasis: {
  //                       shadowBlur: 10,
  //                       shadowOffsetX: 0,
  //                       shadowColor: 'rgba(0, 0, 0, 0.5)'
  //                     }
  //                   }
  //                 }
  //               ]
  //             };
  //             this.chartOption = this.option;
  //             this.loading = false
  //           } else {
  //             this.loading = false
  //           }
  //         }, (err) => {
  //           this.loading = false
  //         }
  //       );

     
   

  // }








  public statCulture(): void {

    this.loading = true
    let page = 1
    let json:any
    
        this.statServ.readRepartitionculturesNew(page , this.utils.getCurrentUser().profile.entreprise.id).then(
          (res: any) => {
            console.log('res culture', res)
             this.dataCulture.cultureData = false
          console.log('no cultures', this.dataCulture.cultureData)
            this.eltStatRepartitioncultures = null;
            if (res.data.searchRepartitioncultures.results && res.data.searchRepartitioncultures.results.length > 0 && res.data.searchRepartitioncultures.results[0].piechart && res.data.searchRepartitioncultures.results[0].piechart != "[{'value': '', 'name': ''}]" && res.data.searchRepartitioncultures.results[0].piechart != "[]") {
              this.dataCulture.cultureData = true
              console.log('cultures', this.dataCulture.cultureData)
              this.eltStatRepartitioncultures = res.data.searchRepartitioncultures.results[0].piechart;
              json = this.utils.replaceAll('\'', '"', this.eltStatRepartitioncultures)
              this.dataCUlture = JSON.parse(json);
              
              console.log('json', this.dataCUlture )
              
              this.dataCUlture.forEach((elt:any) => {
                if (elt.name == '') {
                  this.cultureDataName.push('Inconnu');
                } else {
                  this.cultureDataName.push(elt.name);
                }
              });
              this.option = {
                title: {
                  // text: 'Repartition des cultures',
                  //subtext: '',
                  // left: 'center'
                },
                tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                // legend: {
                //   // orient: 'vertical',
                //   // top: 'middle',
                //   bottom: 10,
                //   left: 'center',
                //   data: this.cultureDataName
                // },
                series: [
                  {
                    type: 'pie',
                    radius: '65%',
                    center: ['50%', '50%'],
                    selectedMode: 'single',
                    data: this.dataCUlture,
                    itemStyle: {
                      emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                    }
                  }
                ]
              };
              this.chartOption = this.option;
              this.loading = false
            } else {
              this.loading = false
            }
          }, (err) => {
            console.log('erreor search repar culture',err)
            this.loading = false
          }
        );

     
   

  }

}
