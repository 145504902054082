import { Component, OnInit } from "@angular/core";
import { UtilsService } from "src/app/_services/utils.service";
import "bootstrap";
import "jquery";
import { UserService } from "src/app/_services/user.service";
import { NgForm } from "@angular/forms";
import { ProfileService } from "src/app/_services/profile.service";
import { ZoneActionService } from "src/app/_services/zone.action.service";
import { FonctionService } from 'src/app/_services/fonction.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { EditProfilComponent } from '../_modale/edit-profil/edit-profil.component';
import { EmployeFormComponent } from '../_modale/employe-form/employe-form.component';
import { environment } from "src/environments/environment";
import { ConfirmComponent } from "src/app/confirm/confirm.component";
import { LoadingComponent } from "../_modale/loading/loading.component";
import { NewAlertConfirmComponent } from "src/ui/new-alert-confirm/new-alert-confirm.component";
import { CacheStorage } from "html2canvas/dist/types/core/cache-storage";
import { DataCacheService } from "src/app/_services/data-cache.service";
import { ProfileCollecteur } from "src/app/_models/user";
declare var $;

@Component({
  selector: "app-gestion-employe",
  templateUrl: "./gestion-employe.component.html",
  styleUrls: ["./gestion-employe.component.scss"]
})
export class GestionEmployeComponent implements OnInit {
  environment = environment

  // Pagin param
  totalCount: number = 0;

  maxOfPages: number = 20;
  currentPage: number = 1;
  loading: boolean = false;
  // filter:string | any = null

  // Filter param
  fields_selected_: Array<any> = [
    { libelle_db: "nom_Icontains", libelle: "Nom" },
    { libelle_db: "prenom_Icontains", libelle: "Prénoms" }
  ]


  // filter: any = {
  //   field: null,
  //   value: null
  // }
  isSelected:boolean = true
  isSelectedDec:boolean = true
  filter: string

  submitted: boolean = false
  page: any;
  fields: any = [];
  jour: number[] = [];
  mois: number[] = [];
  annee: number[] = [];
  profiles: any = [];
  p: any = null;

  fonction: any = '';
  error: any = {
    confPass: null
  }; // message d'erreur
  // profileD: any = null; // user à supprimer
  profileD: {
    id: String;
    codeDeVerification: String;
    categorie: String;
    contact: String;
    dateNaissance: String;
    deleted: Boolean;
    displayName: String;
    genre: String;
    nom: String;
    photo: String;
    photoUrl: String;
    prenom: String;
    tracking: Boolean;
    createdAt: String;
    updateAt: String;
    user: {
      id: number;
      password: String;
      username: String;
      dateJoined: String;
      email: String;
      firstName: String;
      isActive: Boolean;
      isStaff: Boolean;
      isSuperuser: Boolean;
      lastLogin: String;
      lastName: String;
    };
    entreprise: {
      id: String;
      raisonSociale: String;
    };
    fonction: {
      id: String,
      libelle: String
    },
    pere: {
      id: number,
      password: String,
      username: String,
      dateJoined: String,
      email: String,
      firstName: String,
      isActive: Boolean,
      isStaff: Boolean,
      isSuperuser: Boolean,
      lastLogin: String,
      lastName: String,
    },
    region: {
      libelle: String,
      id: String
    },
    district: {
      libelle: String,
      id: String
    },
    departement: {
      libelle: String,
      id: String
    }
    zone: {
      id: ''
    }
  } = {
      id: "",
      codeDeVerification: "",
      categorie: "",
      contact: "",
      dateNaissance: "",
      deleted: false,
      displayName: "",
      genre: "0",
      nom: "",
      photo: "",
      photoUrl: "",
      prenom: "",
      tracking: true,
      createdAt: "",
      updateAt: "",
      user: {
        id: 0,
        password: "",
        username: "",
        dateJoined: "",
        email: "",
        firstName: "",
        isActive: true,
        isStaff: false,
        isSuperuser: false,
        lastLogin: "",
        lastName: ""
      },
      entreprise: {
        id: "",
        raisonSociale: ""
      },
      fonction: {
        id: "",
        libelle: ""
      },
      pere: {
        id: 0,
        password: '',
        username: '',
        dateJoined: '',
        email: '',
        firstName: '',
        isActive: true,
        isStaff: false,
        isSuperuser: false,
        lastLogin: '',
        lastName: '',
      },
      zone: {
        id: ''
      },
      region: {
        libelle: '',
        id: ''
      },
      district: {
        libelle: '',
        id: ''
      },
      departement: {
        libelle: '',
        id: ''
      }
    };
  roles: any = [];
  activeUpdateForm: Boolean = false;
  photoProfile: any | String = " ";
  fonctions: any = [];
  userConnected: any = null;
  Zones = [];


  constructor(
    public utils: UtilsService,
    private userServ: UserService,
    private profileServ: ProfileService,
    private zone: ZoneActionService,
    private fonctionServ: FonctionService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private dataCache: DataCacheService
  ) { }

  ngOnInit() {
    this.jour = this.utils.genTabNumber(31);
    this.mois = this.utils.genTabNumber(12);
    const nowYear = new Date().getFullYear();
    this.annee = this.utils.genTabNumber(nowYear - 18, nowYear - 75).reverse();
    this.userConnected = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
    // this.read(this.page);
    this.getEmploye()
    this.getZone();
    this.readFunction();
  }

  getEmploye() {
    const cacheKey = 'cache-employe'
    const cachedData = this.dataCache.getData(cacheKey)

    if (cachedData) {
      this.profiles = cachedData
      this.loading = false
      console.log('cache employe', this.profiles)
    } else {
      this.read(this.page);
    }
  }



  public readFunction(): void {
    this.fonctionServ.readAllFonction().then(
      (res: any) => {
        this.fonctions = res;
      }, (err) => {
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  getZone() {
    this.zone.read(`entreprise: "${this.userConnected.profile.entreprise.id}", deleted: false`).then(
      (zonesres: any) => {
        const delimitations = [];
        this.Zones = zonesres.searchZoneAction.results;
        this.Zones.map(zone => {
          delimitations.push(JSON.parse(zone.zoneDelim));
        });
      }, err => {
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  goToPage(n: number): void {
    this.page = n;
    this.read(this.page);
  }

  onNext(): void {
    this.page = this.page + 1;
    this.read(this.page);
  }

  onPrev(): void {
    this.page = this.page > 1 ? this.page + 1 : this.page;
    this.read(this.page);
  }




  public read(page: number = 1, inline_filter: string = null): void {
    // console.log("this.userConnected.profile.entreprise.id",this.userConnected.profile.entreprise.id);

    this.loading = true
    let filter = `entreprise:"${this.userConnected.profile.entreprise.id}",deleted: false,
    ${inline_filter ? inline_filter : ""}`
    // if (filter_field != null && filter_field.field != null) {
    //   filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`
    // }
    // filter = filter + ')'
    this.profileServ.searchProfiles(new ProfileCollecteur(page, this.userConnected.profile.entreprise.id))
      .then(
        (res: any) => {
          console.log('read profile', res)
          this.profiles = res.data.searchProfile;
          // console.log("profile",this.profiles)


          const cacheKey = 'cache-employe'
          this.dataCache.setData(cacheKey, this.profiles)
          this.loading = false
          this.totalCount = res.data.searchProfile.totalCount
        },
        err => {
          console.log('erreur', err)
          this.loading = false
          // console.log('profile error', err)
        }
      ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  

  croissant() {
    this.isSelected = !this.isSelected
    if(!this.isSelected){
      this.isSelectedDec = true
      this.utils.OrdrecroisantArray(this.profiles.results, 'prenom')
    }
      
  }


  decroissant() {
    this.isSelectedDec = !this.isSelectedDec
    if(!this.isSelectedDec){
      this.isSelected = true
      this.utils.ordreDecrossantArray(this.profiles.results, 'prenom')
    }
  }








  public setTypeForm(profile, view: Boolean = true): void {
    // Permet d'envoyer un boolean à activeUpdateForm afin d'activer la visibilité du formulaire de modification ou pas
    this.activeUpdateForm = view;
    this.profileD = profile;
    // if (this.profileD.fonction == null) {
    //   this.profileD.fonction = {id: '', libelle: ''}
    // }
    this.photoProfile = (this.profileD.photo == "../../assets/img/avatar.png") ? "" : this.profileD.photo;
    $('#formulaireGestionEmploye').modal('show');
  }

  public processimage(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.selectedFile = reader.result;
        // console.clear();
        this.photoProfile = reader.result;
      };
    } else {
      // tslint:disable-next-line:no-console
      this.photoProfile = '';
      console.info("Un problème est survenu lors du chargement du fichier.");
    }
  }

  public gotsearchValue(e): any { }


  onChangePage(data) {
    this.currentPage = data.id;
    this.read(data.id)
  }


  filterData(filter: string) {
    this.filter = filter

  }

  // filterData(value:string){
  //     this.loading = true
  //     this.filter = value
  //    this.profileServ.filterRequest(value).then(
  //      (res:any)=>{
  //        this.loading = false
  //        console.log('filterData',res)
  //        this.profiles.results = res.searchProfileBy
  //        console.log('presente array', this.profiles)
  //      }
  //    )

  // }


  openFormEmployeDialog(profile: any = null): void {
    const dialogRef = this.dialog.open(EmployeFormComponent, {
      width: '60rem',
      data: { profile: profile },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.snackBar.open('Employé ajouté', 'Ok', { duration: 3000 });
        this.profiles.results.unshift(result)
      } else {
        this.snackBar.open('Ajout annulé avec succès', 'Ok', { duration: 3000 });
      }
    });
  }



  deleteEmployeProfile(profile: any) {
    console.log('profile to delete', profile)
    const dialogRef = this.dialog.open(NewAlertConfirmComponent, {
      width: "33rem",
      data: {
        message: `Vous êtes sur le point de supprimer le collecteur  ""${profile.user.username}"". Voulez vous continuer`,
        title: "Suppression d'un collecteur"
      }

    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        const dialogRef2 = this.dialog.open(LoadingComponent, {
          width: '300px',
          disableClose: true,
          id: 'wefly_loading_modale',
          data: { loader: true, msg: 'Veuillez patienter ...' }
        });

        this.profileServ.deleteEmploye(profile.id).then(
          (res2: any) => {
            console.log("delete profile", res2)
            if (res2.updateProfile.profile) {
              console.log('delete profile ok', res2)
              this.dialog.getDialogById("wefly_loading_modale").close()
              this.profiles.results.forEach((res, index) => {
                if (res.id === profile.id) {
                  this.profiles.results.splice(index, 1)
                }
              })
            }
          }, (err2) => {
            this.dialog.getDialogById("wefly_loading_modale").close()
          }
        ).catch(reason => this.utils.setErrorsHttp(reason));
      }
    })
  }

  updateEmployeProfile(profile: any) {
    //   const dialogRef = this.dialog.open(EmployeFormComponent, { 
    //   width: '60rem',
    //   data: { profile : profile },
    //   disableClose : true,
    // });

    // dialogRef.afterClosed().subscribe((result:any) => {
    //   if(result){
    //     console.log('resulte profile update', result)

    //     this.profiles.results.forEach((res:any)=>{
    //       if(res.id === result.id){
    //         console.log('le profile modier', res)
    //         res == result
    //       }
    //     })
    //     this.snackBar.open("Profil modifié avec succès", "OK", {duration : 3000})
    //   }else{

    //   }
    // });
  }
}






