import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-principal-menu',
  templateUrl: './principal-menu.component.html',
  styleUrls: ['./principal-menu.component.css', './principal-menu.component2.css']
})
export class PrincipalMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
