import { WEATHER_CODE } from './../../_modale/weather';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { UtilsService } from 'src/app/_services/utils.service';
import { environment } from 'src/environments/environment';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit {
  weatherData:any
  currentDate :any
  currentDatePlus:any
  hebdoWeatherData:any
  latitude:any
  longitude:any
  imagNotFound :boolean = false
  lottie:string = ""


  constructor(
    private utils: UtilsService,
    private http: HttpClient
  ) { }
  Curently: any = null;
  Daily: any = [];
   
  data_weather = {
    "longitude": -5.629234,
    "latitude": 7.252407
  }
  ngOnInit() {
    this.currentDate = new Date();
    this.currentDatePlus = new Date();
    this.currentDatePlus.setDate(this.currentDate.getDate() + 7)
    this.currentPosition()
  }

  // getWeather() {
  //   this.http.post(
  //     environment.weathe_url+'get_prevision_hebdo',
  //      this.data_weather, {
  //        headers: {
  //         username:"jool_weather_api",
  //         password:"95Dpms2HHwhXruU6By46KLqprfaR7"
  //        }
  //      }).subscribe(
  //     (res: any) => {
  //       
  //       this.Curently = res[0];
  //       this.Daily = res
  //       // console.table(this.Curently);
  //     },
  //     (err) => {
  //       
  //     }
  //   )
  // }

  async getWeather(lng: number, lat: number){
    const options:AxiosRequestConfig = {
      url: " https://adb.jool-tech.com/_db/db/weather_api/get_prevision_jour",
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        "username": "jool_weather_api",
        "password": "95Dpms2HHwhXruU6By46KLqprfaR7",
      },
      data:{
        "longitude": lng,
        "latitude": lat,
        "debut":this.currentDate.toISOString().split('T')[0],
        "fin":this.currentDatePlus.toISOString().split('T')[0]
      }
    }
    axios(options).then((responseWetherDay:AxiosResponse) => {
      this.weatherData = responseWetherDay.data[0]
      if(this.weatherData){
        this.lottie = this.getWeatherIcone(this.weatherData.code)
      }
     
    })
    options.url = "https://adb.jool-tech.com/_db/db/weather_api/get_prevision_hebdo"
      axios(options).then((responseWeatherHebdo:AxiosResponse)=>{
        this.hebdoWeatherData = responseWeatherHebdo.data
        console.log('prevision', this.hebdoWeatherData)
      })
  }

  currentPosition() {
    navigator.geolocation.getCurrentPosition((position) =>{
       this.latitude = position.coords.latitude;
       this.longitude = position.coords.longitude;
         this.getWeather(this.longitude, this.latitude)
    }, (err)=>{
      this.imagNotFound = true
    });
  };

  getWeatherIcone(code: number){
    let lottiepath:any
    WEATHER_CODE.forEach(
      (value: {code: number, day: string, night: string, icon: number}) => {
        if(code===value.code){
          lottiepath = "./../../../assets/lottiegif/" + value.icon + ".gif" 
        }
      });
     return lottiepath;
    
}



}
