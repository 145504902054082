import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';

@Injectable({
  providedIn: 'root'
})
export class TypeAttributService {

  constructor(private utils: UtilsService) { }
  create(
    description: String,
    libelle: String,
    deleted: Boolean,

  ) {
    const query = `
    mutation(
			$description:  String!,
			$libelle:  String!,
			$deleted:  Boolean!,
    ) {
      createTypeAttribut(newTypeattribut: {
				description: $description,
				libelle: $libelle,
				deleted: $deleted,
      }) {typeattribut{
 id,
				description,
				libelle,
				deleted,
				}      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          description,
          libelle,
          deleted,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
  update(id: String,
    description: String,
    libelle: String,
    deleted: Boolean,
  ) {
    const query = `
    mutation($id: UUID!,
			$description:  String!,
			$libelle:  String!,
			$deleted:  Boolean!,
    ) {
      updateTypeAttribut(newTypeattribut: {
 id: $id,
				description: $description,
				libelle: $libelle,
				deleted: $deleted,
      }) {typeattribut{
        id,
				description,
				libelle,
				deleted,
				}      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id,
          description,
          libelle,
          deleted,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
  read(filter: string = `deleted : false`, page: number = 1, limit: number = this.utils.PAGINATION, order: string = 'id') {
    const query = `
    query($page: Int!, $order: String!) {
      searchTypeAttribut(${filter}){
        totalCount
        results(page: $page, ordering: $order)
        { id,
        description,
				libelle,
				deleted,
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { limit, page, order }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

