import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../_services/utils.service';
import { ConnexionService } from '../_services/connexion.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss', '../login/login.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  fields = [];
  loader = false;
  viewPasssword: boolean = false;
  error_msg: string = null;
  uid: string = null;

  constructor(
    private route: ActivatedRoute,
    public utils: UtilsService,
    public fpServ: ConnexionService,
    private router: Router
  ) {
   }

  ngOnInit() {
    if (this.route.snapshot.params['uid']) {
      this.viewPasssword = true;
      this.uid = this.route.snapshot.params['uid'];
      this.fpServ.verif_uid_used(`id: "${this.uid}"`).then(
        (res: any) => {
          if (res.totalCount == 1) {
            if (res.results[0].alreadyUsed) {
              this.setMsg('Ce lien a déjà été utilisé.');
            } else if (res.results[0].expiredState) {
              this.setMsg('Ce lien a expiré, veuillez reprendre tout le processus de réinitialisation de mot de passe.Cliquer pour reprendre.')
            }
          } else {
            this.setMsg('Ce lien est invalide, veuillez reprendre tout le processus de réinitialisation de mot de passe.Cliquer pour reprendre.')
          }
        }, (err: any) => {
          this.setMsg('Ce lien est invalide, veuillez reprendre tout le processus de réinitialisation de mot de passe.Cliquer pour reprendre.')
        }
      );

    } else {
      this.viewPasssword = false;
    }
  }

  onSubmit(form: NgForm) {
    let f = form.value;
    this.fields = this.utils.formIsNotNull(form, ['password', 'confpassword']);
    if (this.fields.length == 0) {
      if (f.password == f.confpassword) {
        if (this.utils.regexPassword(f.password)) {
          this.loader = true;
          this.error_msg = null;

          this.fpServ.create_new_password(this.uid, f.password).then(
            (res: any) => {
              if (true) {
                document.getElementById('password-form').innerHTML = `<label class="ins-label"><a href="/login">Mot de passe réinitialisé avec succès. Vous pouvez vous connecter à présent.</a></label>`;
                setTimeout(() => {
                  this.router.navigate(['/login']);
                }, 10000);
              }
            }, (err) => {
              this.setMsg('Ce lien est invalide, veuillez reprendre tout le processus de réinitialisation de mot de passe. Cliquer pour reprendre', 'password-form')
             
            }
          );
        } else {
          this.error_msg = 'Votre mot de passe doit contenir une lettre majuscule et un chiffre au moins.';
        }
      } else {
        this.error_msg = 'Les deux mots de passe doivent être identique.';
      }
    }
  }

  set_mail(form: NgForm) {
    console.clear();
    let f = form.value.mail;
    this.fields = this.utils.formIsNotNull(form, ['mail']);
    if (this.fields.length == 0) {
      if (this.utils.regexMail(f)) {
        this.loader = true;
        this.fpServ.create_new_mail(f).then(
          (res: any) => {
            document.getElementById('mail-form').innerHTML = `<label class="ins-label">Un mail de réinitialisation de mot de passe vous a été envoyé, veuillez le consulter.</label>`;
          }, (err: any) => {
            this.loader = false;
            if (JSON.stringify(err).indexOf('Mail non envoyé') > -1) {
              this.error_msg = 'Email inexistant.'
            }
          }
        );
      } else {
        this.error_msg = 'Ce champ doit être une adresse email valide.';
      }
    } else {
      this.error_msg = 'Ce champ est obligatoire.'
    }

  }

  private setMsg(str: string, id: string='password-form'): void {
    document.getElementById(id).innerHTML = `<label class="ins-label" style="color: red"><a href="/forget-password">${str}</a></label>`;
    setTimeout(() => {
      this.router.navigate(['/forget-password'])
    }, 10000);
  }

}
