import { ZoneActionService } from "src/app/_services/zone.action.service";
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { ActeurService } from "src/app/_services/acteurs.service";
import { ParcsService } from "src/app/_services/parcs.service";
import { PaysService } from "src/app/_services/pays.service";
import { UtilsService } from "src/app/_services/utils.service";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { LoadingComponent } from "../_modale/loading/loading.component";
import { CoucheService } from "src/app/_services/couche.service";
import { GestionPlantationComponent } from "../gestion-plantation/gestion-plantation.component";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { WEATHER_CODE } from "../../_modale/weather";
import { NewActeurService } from "src/app/_services/newActeurs.service";
import { UserService } from "src/app/_services/user.service";
import { ExcelFieldToDowloadComponent } from "src/app/excel-field-to-dowload/excel-field-to-dowload.component";
import { DataCacheService } from "src/app/_services/data-cache.service";

declare var $;
declare var L: any;

@Component({
  selector: "app-map-dash",
  templateUrl: "./map-dash.component.html",
  styleUrls: ["./map-dash.component.scss"],
  providers: [GestionPlantationComponent],
})
export class MapDashComponent implements OnInit, OnChanges, AfterViewInit {
  total_parcs_online: number = 0;
  total_plantation_legale_online: number = 0;
  total_plantation_illegale_online: number = 0;

  cpt = 0;
  parcs: any = [];
  pays: any = [];
  map: any = null;
  markerOrLine: number = 2; // 1: line, 2: marker
  plantationOrParcs: number = 3; // 2: parcs , 3: plantation
  openWeather: boolean = false;
  currentDate: any;
  currentDatePlus: any;
  weatherData: any;
  hebdoWeatherData: any;
  lottie: string = "";
  openedPlantation:any

  clusterCourDeau = L.markerClusterGroup();
  clusterParcsLine = L.markerClusterGroup();
  clusterParcsMarker = L.markerClusterGroup();
  clusterPlantationLine = L.markerClusterGroup();
  clusterPlantationMarker = L.markerClusterGroup();
  clusterPlantationOnParcLine = L.markerClusterGroup();
  clusterPlantationOnParcMarker = L.markerClusterGroup();

  legende: {
    icon: String;
    libelle: String;
  }[] = [
    { icon: "parc.png", libelle: "Parcs" },
    { icon: "planteur.png", libelle: "plantation" },
  ];
  viewLegend: boolean = false;
  userInfo: any;
  courDeau: any;
  plantations: any = [];
  plantationsOnPacrs: any = [];
  @Input() entreprise: any = null;
  acteursCouche = [];
  checkbox: any = [
    { select: true, id: 1, libelle: "Plantations légales" },
    { select: true, id: 3, libelle: "Plantations illégales" },
    { select: false, id: 2, libelle: "Parcs" },
  ];
  @Output() dowloadExcel = new EventEmitter<any>();

  layers = [
    {
      image:
        "../../../assets/img/sig/Dashboard-Map/MenuInactive/drawable-xhdpi/GoogleMap.png",
      imageActive:
        "../../../assets/img/sig/Dashboard-Map/MenuActive/drawable-xhdpi/GoogleMap.png",
      lib: "Google Map",
      active: true,
    },
    {
      image:
        "../../../assets/img/sig/Dashboard-Map/MenuInactive/drawable-xhdpi/OpenStreet.png",
      imageActive:
        "../../../assets/img/sig/Dashboard-Map/MenuActive/drawable-xhdpi/OpenStreet.png",
      lib: "Open Street Map",
      active: false,
    },
    {
      image:
        "../../../assets/img/sig/Dashboard-Map/MenuInactive/drawable-xhdpi/OpenStreet.png",
      imageActive:
        "../../../assets/img/sig/Dashboard-Map/MenuActive/drawable-xhdpi/ImagMonde.png",
      lib: "Image du monde",
      active: false,
    },
    {
      image:
        "../../../assets/img/sig/Dashboard-Map/MenuInactive/drawable-xhdpi/OpenStreet.png",
      imageActive:
        "../../../assets/img/sig/Dashboard-Map/MenuActive/drawable-xhdpi/ImagMonde.png",
      lib: "Google satelite",
      active: false,
    },
  ];
  show_repartition = false;
  plantation_on_parc_loading: boolean = true;
  plantation_out_parc_loading: boolean = true;
  openedPlantationOnMap:any
  constructor(
    private U: UtilsService,
    // private acteurServ: ActeurService,
    private acteurServ: NewActeurService,
    private parcServ: ParcsService,
    private paysServ: PaysService,
    public dialog: MatDialog,
    private coucheServ: CoucheService,
    private gestPlantationComp: GestionPlantationComponent,
    private zoneServ: ZoneActionService,
    private excelField: ExcelFieldToDowloadComponent,
    private userServ :UserService,
    private dataCulture:DataCacheService,
    private acteurService:ActeurService
  ) {}
  ngAfterViewInit(): void {
   
    setTimeout(() => {
    
      this.show_repartition = true;
    }, 3000);
  }

  ngOnInit() {
    this.currentDate = new Date();
    this.currentDatePlus = new Date();
    this.currentDatePlus.setDate(this.currentDate.getDate() + 7); 
    console.log("cellular", navigator);

    this.userInfo = JSON.parse(this.U.decrypt(this.U.getCookie("_wefly_"))); 
    this.getZoneGlobale()
   
      
  
    // this.userService.searchUser().then(
    //   (res:any)=>{
    //     this.userInfo = JSON.parse(this.U.decrypt(this.U.getCookie("_wefly_"))); 
    //   }
    // )
     
    try {
      this.map = this.U.callMap();
      this.switchLayer(2);
    } catch (error) {}
    $(document).ready(function ($) {
      $(".boom").click();
      setTimeout(() => {
        $(".boom").click();
      }, 250);
    });


  }

  switchLayer(index) {
    if (index === 0) {
      this.map.removeLayer(this.U.openStreetMap);
      this.map.removeLayer(this.U.worldImage);
      this.map.removeLayer(this.U.googleSatelite);
      this.map.addLayer(this.U.mapBox);
      this.layers[0].active = true;
      this.layers[1].active = false;
      this.layers[2].active = false;
      this.layers[3].active = false;
    } else if (index === 1) {
      this.map.removeLayer(this.U.mapBox);
      this.map.removeLayer(this.U.worldImage);
      this.map.removeLayer(this.U.googleSatelite);
      this.map.addLayer(this.U.openStreetMap);
      this.layers[1].active = true;
      this.layers[0].active = false;
      this.layers[2].active = false;
      this.layers[3].active = false;
    } else if (index === 2) {
      this.map.removeLayer(this.U.openStreetMap);
      this.map.removeLayer(this.U.mapBox);
      this.map.removeLayer(this.U.googleSatelite);
      this.map.addLayer(this.U.worldImage);
      this.layers[2].active = true;
      this.layers[0].active = false;
      this.layers[1].active = false;
      this.layers[3].active = false;
    } else if (index === 3) {
      this.map.removeLayer(this.U.openStreetMap);
      this.map.removeLayer(this.U.mapBox);
      this.map.removeLayer(this.U.worldImage);
      this.map.addLayer(this.U.googleSatelite);
      this.layers[2].active = false;
      this.layers[0].active = false;
      this.layers[1].active = false;
      this.layers[3].active = true;
    }
  }

  ngOnChanges() {
    this.clusterPlantationOnParcLine.clearLayers();
    this.clusterPlantationOnParcMarker.clearLayers();
    this.clusterPlantationLine.clearLayers();
    this.clusterPlantationMarker.clearLayers();
    this.clusterParcsMarker.clearLayers();
    this.clusterParcsLine.clearLayers();
    this.plantations = [];
    this.readCouche();

    // plantation illégale

    this.acteurServ
      .readTotalCount(
        `couche_Entreprise_Id: "${this.entreprise}", couche_TypeCouche_Etiquette: "PLANTATION", deleted: false, acteurDelimitation_Pforest: true`
      )
      .then(
        (total: number) => {
          this.total_plantation_illegale_online = total;
          // alert('total de plantation illégale en ligne:\n' + this.total_plantation_illegale_online);
          // plantation légale
        },
        (err) => {}
      );

    this.acteurServ
      .readTotalCount(
        `couche_Entreprise_Id: "${this.entreprise}", couche_TypeCouche_Etiquette: "PLANTATION", deleted: false, acteurDelimitation_Pforest: false`
      )
      .then(
        (total: number) => {
          
          this.total_plantation_legale_online = total;
          console.log('total plantation', total)
          // alert('total de plantation légale online:' + this.total_plantation_legale_online);
        },
        (err) => {}
      );

    this.parcServ.readTotalcount("deleted: false").then(
      (total: number) => {
        this.total_parcs_online = total;
        // alert(this.total_parcs_online);
        this.readParcs();
      },
      (errT) => {}
    );
  }

  async getCourDeau() {
    const datas = "media/json_delim_acteurconc_coord.json";

    let res = await axios.get(this.U.API_HOST + datas);

    // console.log("res.data ", res.data);

    if (res.data !== null && res.data !== undefined && res.data !== "") {
      res.data.forEach((detailElt) => {
        let coordonnee = JSON.parse(detailElt.coordonnees);
        let geoJson = L.geoJson(coordonnee.geometry, {
          onEachFeature: function (feature, layer) {
            layer.bindPopup(" <h3> Point d'eau à proximité <h3>", {
              className: "custom-pop-up-plantation",
            });
          },
        });
        this.clusterCourDeau.addLayer(geoJson);
        this.map.addLayer(this.clusterCourDeau);
      });
    } else {
      alert("Pas de point d'eau disponible");
    }
  }

  public hideDash(): void {
    this.show_repartition = false;
    const button = parent.document.getElementById("map-button");
    button.setAttribute("style", "display: block");

    // const a = parent.document.getElementById('map-dash');
    let a = document.getElementById("tracked-list");
    let b = document.getElementById("map");

    a.style.width = "0%";
    b.style.width = "85%";
  }

  public showDash(): void {
    this.show_repartition = !this.show_repartition;
    if (this.show_repartition) {
      let b = document.getElementById("map");
      const button = parent.document.getElementById("map-button");
      b.style.width = "85%";
    } else {
      const button = parent.document.getElementById("map-button");
      button.setAttribute("style", "display: block");
      let b = document.getElementById("map");
      b.style.width = "85%";
    }
  }

  public readParcs(offset: number = 0, limit: number = 1) {
    this.U.trackExec("ReadParcs | 148");
    this.parcServ
      .read(
        "deleted: false",
        offset,
        limit,
        "-created_at",
        this.total_parcs_online
      )
      .then(
        (res: any) => {
          if (!this.parcs.results) {
            // premier arrivée du user sur la carte, chargement d"un seul parcs
            this.parcs = res;
            this.addParcsOnMap(res.results[0]);
          }

          // if (this.parcs.results.length < 5) {
          if (this.parcs.results.length < this.parcs.totalCount) {
            // suite du chargement des parcs un à un
            offset += limit;
            this.readParcs(offset);
            this.parcs.results = [...this.parcs.results, ...res.results];
            this.addParcsOnMap(res.results[0]);
          } else {
            // this.markerOrLine = 1;
          }
        },
        (err) => {}
      );
  }

  public readPays(offset: number = 0, limit: number = 1): void {
    this.paysServ.read("deleted: false", offset, limit).then(
      (res: any) => {
        console.log('red',res)
        if (!this.pays.results) {
          // premier arrivée du user sur la carte, chargement d"un seul pays
          this.pays = res;
          this.addPaysOnMap(res.results[0]);
        }

        if (this.pays.results.length < this.pays.totalCount) {
          // suite du chargement des pays un à un
          offset += limit;
          this.readPays(offset);
          this.pays.results = [...this.pays.results, ...res.results];
          this.addPaysOnMap(res.results[0]);
        }
      },
      (err) => {}
    );
  }

  public readPlantations(
    couche: string,
    entreprise: string,
    page: number = 1
  ): void {
  
    if (this.entreprise === entreprise) {
      this.U.trackExec("map-dah | readPlantations 200");
      // acteur_ActeurDelimitation_Pforest: false
      this.acteurServ
        .readPlantationOnDash(
          `couche: "${couche}", acteur_Deleted:false`,
          page,
          "-created_at"
        )
        .then(
          (res: any) => {
            console.log('plantations map', res)
            let elt:any = res;
            elt.results.forEach((element:any) => {
              if(element){
                this.addPlantationOnMap(element);
                this.plantations.push(element);

              }
             
              
              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            });

            // if (this.plantations.results.length < 2) {

            if (this.plantations.length < elt.totalCount) {
              console.log('totalget', this.plantations.length)
              console.log('totalcount', elt.totalCount)
              page += 1;
              this.readPlantations(couche, entreprise, page);
              this.plantations = [...this.plantations, elt.results];

            } else {
              this.plantation_out_parc_loading = false;
              try {
                this.map.fitBounds(this.clusterPlantationLine.getBounds());
              } catch (error) {}
              try {
                // this.map.fitBounds(this.clusterPlantationMarker.getBounds());
              } catch (error) {}
            }
          },
          (err) => {}
        );
    }
  }

  public readPlantationsOnParcs(
    couche: string,
    entreprise,
    page: number = 1
  ): any {
    // this.acteurServ.readLocale(`couche_Entreprise_Id: "${this.entreprise}", couche_TypeCouche_Etiquette: "Plantation", deleted: false, acteurDelimitation_Pforest: true`, page, "created_at", this.total_plantation_illegale_online, false).then(
    if (this.entreprise === entreprise) {
      this.acteurServ
        .read(
          `couche: "${couche}" deleted: false, acteur_ActeurDelimitation_Pforest: true`,
          page,
          "created_at"
        )
        .then(
          (res: any) => {
            let elt = res;
            elt.results.forEach((element) => {
              this.addPlantationOnMap(element, "#FFA500", 2);
            });

            if (!this.plantationsOnPacrs.results) {
              this.plantationsOnPacrs = elt;
              try {
                // this.map.fitBounds(this.clusterPlantationOnParcLine.getBounds());
                // this.map.fitBounds(this.clusterPlantationOnParcMarker.getBounds());
              } catch (error) {}
            }

            if (
              this.plantationsOnPacrs.results.length < this.plantationsOnPacrs.totalCount
            ) {
              // if (this.plantationsOnPacrs.results.length < 2) {
              page += 1;
              this.readPlantationsOnParcs(couche, page);
              this.plantationsOnPacrs.results = [...this.plantationsOnPacrs.results, ...elt.results,];
            } else {
              this.plantation_on_parc_loading = false;
              try {
                // this.map.fitBounds(this.clusterPlantationOnParcLine.getBounds());
                // this.map.fitBounds(this.clusterPlantationOnParcMarker.getBounds());
              } catch (error) {}
            }
          },
          (err) => {}
        );
    }
  }

  public readProprioPlantation(idSuperieur: string): any {
    // this.acteurServ.readLocale(`id: "${idSuperieur}", couche_TypeCouche_Etiquette: "Planteur", deleted: false`).then(
    this.acteurServ
      .readActeur(
        `id: "${idSuperieur}", couche_TypeCouche_Etiquette: "PLANTEUR", deleted: false`
      )
      .then(
        (res: any) => {
          console.log('proprioPlanteur', res)
          // console.clear();
          let elt = res.results[0];
          let p = document.querySelectorAll(".myplantationpopup");
          let q = document.querySelectorAll(".proprio");
          q.forEach((el, i) => {
            q[i].parentNode.removeChild(q[i]);
          });

          p.forEach((el, i) => {
            p[i].innerHTML =
              `
          <div class="row proprio">
            <div class="col-12">
              <h6 style="text-align: center" class="greenColor"><strong>PLANTEUR</strong><br></h6>
            </div>
            <div class="col-9">
              <p class="blackColor"><b>${
                elt.acteurPerso[0].nom + " " + elt.acteurPerso[0].prenoms
              }</b></p>
              <p class="blackColor">Contact: <b>${
                elt.acteurPerso[0].contact
              }</b></p>
            </div>
            <div class="col-3">
              <img style="height: auto; width: 100%;" src="${
                this.U.API_URL + elt.acteurPerso[0].photoUrl
              }"/>
            </div>
          </div>
        ` + p[i].innerHTML;
          });
          let btn = document.querySelector("#downloadKml")
            btn.innerHTML="Télécharger le fichier KML"
            
            btn.addEventListener("click", ()=>{
                  this.downLoadKml()
                  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            });

        },
        (err) => {

        }
      );
    // }
  }

  public addPlantationOnMap(
    elt: any,
    color = "#058B46",
    type = 1,
    kmlBaseUrl: String = this.U.API_URL,
    argThis = this
  ): void {
    if(elt){
      this.openedPlantation = elt
    }
  
    try {
      let ele:any
      if (this.plantations.findIndex((item: any) => item.id === elt.id) < 0) {
        let LeafIcon = L.Icon.extend({
          options: {
            iconUrl:
              "../../../assets/img/sig/Dashboard-Map/drawable-xhdpi/Composant 14 – 11.png",
            iconSize: [60, 65], // size of the icon [largeur, hauteur]
            shadowSize: [50, 64], // size of the shadow
            iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62], // the same for the shadow
            popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
          },
        });
        let plantationIcon = new LeafIcon();

        let codePlantation =
          elt.acteurGen[0] && elt.acteurGen[0].codePlantation
            ? elt.acteurGen[0].codePlantation
            : "Inconnu";
        let surface =
          elt.acteurDelimitation[0] && elt.acteurDelimitation[0].surface
            ? elt.acteurDelimitation[0].surface
            : "Inconnu";
        let perimetre =
          elt.acteurDelimitation[0] && elt.acteurDelimitation[0].perimetre
            ? elt.acteurDelimitation[0].perimetre
            : "Inconnu";
        let kmlUrl =
          elt.acteurDelimitation[0] && elt.acteurDelimitation[0].kml
            ? kmlBaseUrl + elt.acteurDelimitation[0].kml
            : "";

        let culture = "";

        if (elt.acteurDelimitation.length === 0) {
          culture = "Inconnu";
        } else {
          culture = elt.acteurDelimitation[0].culture;
        }

        if (elt.acteurDelimitation.length > 0) {
          let html = `
          <div class="myplantationpopup"><br>
          <h6 style="text-align: center" class="greenColor"><strong>PLANTATION</strong></h6>
          <center>
            <p class="blackColor">Code de la plantation: <b>${codePlantation}</b></p>
            <p class="blackColor">Surface: <b>${surface} hectares </b><p/>
            <p class="blackColor">Périmètre: <b>${perimetre} mètres </b><p/>
            <p class="blackColor">Cultures:  <b>${culture}</b></p>

            <div class="getKmlBtn"  type="button" id='downloadKml'  title="Télécharger le fichier KML">
            
            Veuillez patienter...
            <div>

          </center>
        <div>
         `;

          let geoJ = L.geoJSON(
            JSON.parse(
              this.U.replaceAll("'", '"', elt.acteurDelimitation[0].geometrie)
            ),
            {
              color: color,
              weight: 4,
              opacity: 1,
              onEachFeature: function (feature, layer) {
                
                // layer.editing._marker.options.icon = plantationIcon;
                layer.bindPopup(html, {
                  className: "custom-pop-up-plantation",
                }),
                  layer.on("click", function (e, argThisPrime = argThis) {
                    console.log('elt 1', e)
                    argThisPrime.readProprioPlantation(elt.idSuperieur);
                  });
              },
            }
          );

          if (type == 1) {
            this.clusterPlantationLine.addLayer(geoJ);
            // this.map.addLayer(this.clusterPlantationLine);
            // this.map.fitBounds(this.clusterPlantationLine.getBounds());
          } else {
            this.clusterPlantationOnParcLine.addLayer(geoJ);
            // this.map.addLayer(this.clusterPlantationOnParcLine);
            // this.map.fitBounds(this.clusterPlantationOnParcLine.getBounds());
          }

          let marker = L.geoJson(
            JSON.parse(this.U.replaceAll("'", '"', elt.coordonnees)),
            {
              onEachFeature: function (feature, layer) {
                layer.editing._marker.options.icon = plantationIcon;
                layer.bindPopup(html, {
                  className: "custom-pop-up-plantation",
                });

                layer.on("click", function (e, argThisPrime = argThis) {
                  argThisPrime.getValuePLantation(elt)
                  argThisPrime.readProprioPlantation(elt.idSuperieur);
                });

              


              },
              
             
            },
          
            
          );

          if (type == 1) {
            this.clusterPlantationMarker.addLayer(marker);
            // this.map.addLayer(this.clusterPlantationMarker);
            // this.map.fitBounds(this.clusterPlantationMarker.getBounds());
          } else {
            this.clusterPlantationOnParcMarker.addLayer(marker);
            // this.map.addLayer(this.clusterPlantationOnParcMarker);
            // this.map.fitBounds(this.clusterPlantationOnParcMarker.getBounds());
          }
        }
        this.view();
      } else {
      }
    } catch (error) {
      this.cpt++;
    }
  }

  public addPaysOnMap(elt: any): void {
    try {
      L.geoJSON(JSON.parse(elt.mpoly), { color: "#fff", weight: 2, opacity: 1 })
        .bindPopup(
          `<div class="my"><br><h6><strong>${elt.name}<strong></h6><center>Périmètre: ${elt.name} <br> Aire: ${elt.name}</center><div>`,
          { className: "custom-pop-up-pays" }
        )
        .addTo(this.map);

      L.marker([elt.lat, elt.lon])
.addTo(this.map)
        .bindPopup(
          `<div class="my"><br><h6><strong>${elt.name}<strong></h6><center>Périmètre: ${elt.name} <br> Aire: ${elt.name}</center><div>`,
          { className: "custom-pop-up-pays" }
        );
    } catch (error) {}
  }

  public addParcsOnMap(elt: any): void {

    let LeafIcon = L.Icon.extend({
      options: {
        shadowUrl: "../../assets/popup-shadow.png",
        iconUrl:
          "../../../assets/img/sig/Dashboard-Map/drawable-xhdpi/Composant 16 – 1.png",
        iconSize: [40, 45], // size of the icon [largeur, hauteur]
        shadowSize: [50, 64], // size of the shadow
        iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62], // the same for the shadow
        popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
      },
    });
    let parcsIcon = new LeafIcon();
    try {
      if (typeof elt.parcPoly == "string") {
        elt.parcPoly = JSON.parse(elt.parcPoly);
      } else {
        elt.parcPoly = JSON.parse(JSON.stringify(elt.parcPoly));
      }

      let line = L.geoJSON(elt.parcPoly, {
        color: "#ff0000",
        weight: 2,
        opacity: 0.65,
        onEachFeature: function (feature, layer) {
          layer.bindPopup(
            `<div class="my"><br>
            <h6><strong>${elt.libelle}<strong></h6>
            <center>Périmètre: ${elt.perimetre} <br> Aire: ${elt.acres}</center>
            <div>`,
            { className: "custom-pop-up-parcs" }
          );
        },
      });
      this.clusterParcsLine.addLayer(line);
      // this.map.addLayer(this.clusterParcsLine);

      // permet de clusteriser les polygones
      L.Polygon.addInitHook(function () {
        this._latlng = this._bounds.getCenter();
      });

      // L.Polygon.include({
      //   getLatLng: function () {
      //     return this._latlng;
      //   },
      //   setLatLng: function () { } // Dummy method.
      // });

      try {
        let marker = L.geoJson(JSON.parse(elt.coords), {
          onEachFeature: function (feature, layer) {
            layer.editing._marker.options.icon = parcsIcon;
            layer.bindPopup(
              `<div class="my"><br><h6><strong>${elt.libelle}<strong></h6><center>Périmètre: ${elt.perimetre} <br> Aire: ${elt.acres}</center><div>`,
              { className: "custom-pop-up-parcs" }
            );
          },
        });
      } catch (error) {}
      // marker.addTo(this.map);
      // this.clusterParcsMarker.addLayer(marker)
      // this.map.addLayer(this.clusterParcsLine);
      this.view();
    } catch (error) {}
  }

  public viewWithClear(ml: number = this.markerOrLine): void {
    if (this.map) {
      this.markerOrLine = ml;
      this.map.removeLayer(this.clusterPlantationOnParcMarker);
      this.map.removeLayer(this.clusterPlantationMarker);
      this.map.removeLayer(this.clusterParcsMarker);

      this.map.removeLayer(this.clusterPlantationOnParcLine);
      this.map.removeLayer(this.clusterPlantationLine);
      this.map.removeLayer(this.clusterParcsLine);
      this.view();
    }
  }

  public view(): void {
    if (this.markerOrLine === 2 && this.map) {
      // marker visible
      this.map.removeLayer(this.clusterPlantationOnParcLine);
      this.map.removeLayer(this.clusterPlantationLine);
      // this.map.removeLayer(this.clusterParcsLine);
      this.checkbox.forEach((elt, i) => {
        if (elt.id == 1 && !elt.select) {
          this.map.addLayer(this.clusterPlantationMarker);
        } else if (elt.id == 2 && !elt.select) {
          this.map.addLayer(this.clusterParcsLine);
        } else if (elt.id == 3 && !elt.select) {
          this.map.addLayer(this.clusterPlantationOnParcMarker);
        }
      });
    } else {
      // ligne visible
      if (this.map) {
        this.map.removeLayer(this.clusterPlantationOnParcMarker);
        this.map.removeLayer(this.clusterPlantationMarker);
        // this.map.removeLayer(this.clusterParcsMarker);
        this.checkbox.forEach((elt, i) => {
          if (elt.id == 1 && !elt.select) {
            this.map.addLayer(this.clusterPlantationLine);
          } else if (elt.id == 2 && !elt.select) {
            this.map.addLayer(this.clusterParcsLine);
          } else if (elt.id == 3 && !elt.select) {
            this.map.addLayer(this.clusterPlantationOnParcLine);
          }
        });
      }
    }
  }

  public viewClicked(idChecked: number, f: NgForm): void {
    this.checkbox[idChecked].select = f.value[this.checkbox[idChecked].id];
    this.viewWithClear(this.markerOrLine);
    try {
      if (
        this.checkbox[idChecked].id == 1 &&
        this.checkbox[idChecked].select == false
      ) {
        this.map.fitBounds(this.clusterPlantationLine.getBounds());
        this.map.fitBounds(this.clusterPlantationMarker.getBounds());
      } else if (
        this.checkbox[idChecked].id == 2 &&
        this.checkbox[idChecked].select == false
      ) {
        this.map.fitBounds(this.clusterParcsLine.getBounds());
        this.map.fitBounds(this.clusterParcsMarker.getBounds());
      } else if (
        this.checkbox[idChecked].id == 3 &&
        this.checkbox[idChecked].select == false
      ) {
        this.map.fitBounds(this.clusterPlantationOnParcLine.getBounds());
        this.map.fitBounds(this.clusterPlantationOnParcMarker.getBounds());
      }
    } catch (error) {}
  }

  public markerOrLineStr(): string {
    return this.markerOrLine.toString();
  }

  public setLegendDash(): void {
    this.viewLegend = !this.viewLegend;
  }

  public openLoadingDialog(profile = 1): void {
    const dialogRef = this.dialog.open(LoadingComponent, {
      width: "500px",
      data: { profile: profile },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  public DownloadExcel(type) {
    const dialogRef = this.dialog.open(LoadingComponent, {
      width: "500px",
      data: {
        msg: "Veuillez patienter...",
        loader: true,
      },
    });
    this.coucheServ
      .read(
        `entreprise: "${this.entreprise}", principal : false, libelle_Icontains: "Plantation"`
      )
      .then(
        (couche: any) => {
          console.log('coucheplantation', couche)

         

          this.excelField.listExcelField("Plantations").then((res: Map<string, string>) => {
            const field = [...res.keys()] 
            console.log('execel data',field.toString())
            const attribut = field.toString()

            if(type === 'excel'){
              this.userServ.createZoneFile("",this.userInfo.profile.user.id, couche.results[0].id,"",false, false, false, true, false, attribut).then((res:any)=>{
                console.log('create exel', res)
                if( res.createZoneFiles.zonefiles.filepath !== null && res.createZoneFiles.zonefiles.filepath !== ""){
                  window.open(this.U.API_URL + res.createZoneFiles.zonefiles.filepath, '_self')
                  dialogRef.close()
                }
              })
            
             }
             //else{
            //   this.userServ.createZoneFile("",this.userInfo.profile.user.id, couche.results[0].id,"",false, false, false, true, false, attribut).then((res:any)=>{
            //     console.log('create exel', res)
            //     if( res.createZoneFiles.zonefiles.filepath !== null && res.createZoneFiles.zonefiles.filepath !== ""){
            //       window.open(this.U.API_URL + res.createZoneFiles.zonefiles.filepath, '_self')
            //       dialogRef.close()
            //     }
            //   })

            // }
         
      
          })
      
          // dialogRef.close();
          // this.gestPlantationComp.download(
          //   { couche: res.results[0].id, type: type },
          //   this.userInfo
          // );
        },
        (err) => {}
      )
      .catch((reason) => this.U.setErrorsHttp(reason));
  }

  readCouche() {
    this.plantation_on_parc_loading = false;
    this.plantation_out_parc_loading = false;
    this.coucheServ
      .read(`entreprise: "${this.entreprise}", principal : false`)
      .then(
        (res: any) => {
          this.acteursCouche = res.results.filter((item: any) => {
            return item.libelle.includes("Plantation");
          });

          /* res.results.forEach((elt, i) => {
          this.readActeur(elt, i);
        }); */
          if (this.acteursCouche.length) {
            setTimeout(() => {
              this.plantation_on_parc_loading = true;
              this.plantation_out_parc_loading = true;
              this.plantations = [];
              this.readPlantations(this.acteursCouche[0].id, this.entreprise);
              this.readPlantationsOnParcs(
                this.acteursCouche[0].id,
                this.entreprise
              );
            }, 2000);
          }
        },
        (err) => {}
      )
      .catch((reason) => this.U.setErrorsHttp(reason));
  }

  showWeather() {
    this.openWeather = true;
    if (this.openWeather) {
      this.map.on("click", (e) => {
        this.getWeather(e.latlng.lng, e.latlng.lat);
      });
    }
  }
  stopClick() {
    this.map.originalEvent.preventDefault();
  }
  closeWeather() {
    this.map.off("click");
    this.openWeather = false;
  }
  getWeather(lng: number, lat: number) {
    const options: AxiosRequestConfig = {
      url: " https://adb.jool-tech.com/_db/db/weather_api/get_prevision_jour",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        username: "jool_weather_api",
        password: "95Dpms2HHwhXruU6By46KLqprfaR7",
      },
      data: {
        longitude: lng,
        latitude: lat,
        debut: this.currentDate.toISOString().split("T")[0],
        fin: this.currentDatePlus.toISOString().split("T")[0],
      },
    };
    axios(options).then((responseWetherDay: AxiosResponse) => {
      this.weatherData = responseWetherDay.data[0];
      if (this.weatherData) {
        this.lottie = this.getWeatherIcone(this.weatherData.code);
       
      }
    });
    options.url =
      "https://adb.jool-tech.com/_db/db/weather_api/get_prevision_hebdo";
    axios(options).then((responseWeatherHebdo: AxiosResponse) => {
      this.hebdoWeatherData = responseWeatherHebdo.data;
    });
  }

  getWeatherIcone(code: number) {
    let lottiepath: any;
    WEATHER_CODE.forEach(
      (value: { code: number; day: string; night: string; icon: number }) => {
        if (code === value.code) {
          lottiepath = "../../../assets/lottiegif/" + value.icon + ".gif";
        }
      }
    );
    return lottiepath;
  }

  downLoadKml(){
   console.log('openedPlantation', this.openedPlantation)
   console.log('user', this.userInfo)

   this.openLoadingDialoge({
     msg: "Veuillez patienter s'il vous plaît.",
     loader: true,
   });
   this.userServ.createZoneFile(this.openedPlantation.id,this.userInfo.profile.user.id, this.openedPlantation.couche.id, this.userInfo.profile.zone.id, true, false, false, false,false,'').then((data:any)=>{
     console.log("create kml", data)
     this.closeLoadingDialog();
     if(data.createZoneFiles.zonefiles.filepath !== null){
       window.open(this.U.API_URL + data.createZoneFiles.zonefiles.filepath,'_self')
     }else{
       return
     }
   },(error:any)=>{
     console.log('erreorceate kml', error) 
   })

  }

  public openLoadingDialoge(d = null, id = "loaderTrackingItem"): void {
    const dialogRef = this.dialog.open(LoadingComponent, {
      width: "500px",
      id: id,
      data: d,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  public closeLoadingDialog(id = "loaderTrackingItem"): void {
    try {
      this.dialog.getDialogById(id).close();
    } catch (error) {}
  }

  getValuePLantation(value:any){
    this.openedPlantation = value
    console.log(this.openedPlantation)

  }

  getZoneGlobale(){

    var myStyle = {
      color: "#058b46",
      weight: 5, 
      opacity: 1,
      fill: false,
    };

        this.zoneServ.read(`deleted : false, zoneGlobale: true`).then(
      (res: any) => {
        console.log('zone delim', res)
        let clusterZone = L.markerClusterGroup();
        res.searchZoneAction.results.forEach((elt:any, id:any) => {
          let a = L.geoJSON(JSON.parse(elt.zoneDelim), {
            style: myStyle,
          });
          clusterZone.addLayer(a);
        });
        if(this.map){
          this.map.addLayer(clusterZone);
          this.map.fitBounds(clusterZone.getBounds());

        }
          
      },
      (err) => {}
    );

  }
}
