import { Component, OnInit } from "@angular/core";
import { UtilsService } from "../_services/utils.service";
import { PeriodeTrackingService } from "../_services/periode-tracking.service";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import "jquery";
import { UserService } from "../_services/user.service";
import { MatDialog } from "@angular/material";
import { DeletedComponent } from "../_modale/deleted/deleted.component";
import { NewAlertConfirmComponent } from "src/ui/new-alert-confirm/new-alert-confirm.component";
declare var $: any;

@Component({
  selector: "app-periode-tracking",
  templateUrl: "./periode-tracking.component.html",
  styleUrls: ["./periode-tracking.component.css"],
})
export class PeriodeTrackingComponent implements OnInit {
  isSelected:boolean = true
  isSelectedDec:boolean = true
  filter: string | any = null;
  // Parametre filter
  fields_selected_: Array<any> = [
    { libelle_db: "libelle_Icontains", libelle: "Libelle" },
  ];
  // filter: any = {
  //   field: null,
  //   value: null,
  // };

  alertField:boolean = false
  loading: boolean = true;
   trackingForm : FormGroup
  // Fin paramatre

  jours = [
    {
      name: "Lundi",
      id: 1,
    },
    {
      name: "Mardi",
      id: 2,
    },
    {
      name: "Mercredi",
      id: 3,
    },
    {
      name: "Jeudi",
      id: 4,
    },
    {
      name: "Vendredi",
      id: 5,
    },
    {
      name: "Samedi",
      id: 6,
    },
    {
      name: "Dimanche",
      id: 7,
    },
  ];
  nombres = ['', 0, 1, 2, 3, 4, 5]
  times: any;
  track
  periode: {
    id: String;
    entreprise: { id: String };
    libelle: String;
    jourDebut: number;
    jourFin: number;
    heureDebut: number;
    heureFin: number;
    deleted?: Boolean;
    createdAt?: String;
    updatedAt?: String;
  } = {
      id: "",
      entreprise: { id: "" },
      libelle: "",
      jourDebut: 0,
      jourFin: 0,
      heureDebut: 0,
      heureFin: 0,
      deleted: false,
      createdAt: "",
      updatedAt: "",
    };
  fields = [];
  periodeTrackings: any = { results: [] };
  doUpdate: Boolean = false;
  userLogged: any = null;

  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;
  submitted: boolean = false;
  forms: NgForm

  constructor(
    private utils: UtilsService,
    private periodeTrackingServ: PeriodeTrackingService,
    private userServ: UserService,
    public dialog: MatDialog,
    private fb:FormBuilder
  ) {
    this.trackingForm = this.fb.group({
      'libelle':['', Validators.required],
      'startDay':['', Validators.required],
      'endDay':['', Validators.required],
      'startHr':['', Validators.required],
      'endHr':['',Validators.required],
      'startMin':['', Validators.required],
      'endMin':['', Validators.required]
    })
   }

  ngOnInit() {

    this.userLogged = JSON.parse(
      this.utils.decrypt(this.utils.getCookie("_wefly_"))
    );
    this.getPeriodeTracking();
    this.times = this.utils.getTimes();
    this.periodeTrackings.results = [];
  }

  createTrack(formData:FormGroup){
console.log(formData)
    if(formData.valid){
      this.submitted = true

      let f = formData.value
      f.startHr = f.startHr * 60 + f.startMin * 1;
      f.endHr = f.endHr * 60 + f.endMin * 1;

      if (!this.doUpdate) {
        this.periodeTrackingServ.create(
          this.userLogged.profile.entreprise.id,
          f.libelle,
          + f.startDay,
          + f.endDay,
          f.startHr,
          f.endHr,
          false
        ).then(
          (res: any) => {
            console.log('create track', res)
            this.submitted = false;
            this.periodeTrackings.results.unshift(res);
            this.trackingForm = this.fb.group({
              'libelle':['', Validators.required],
              'startDay':['', Validators.required],
              'endDay':['', Validators.required],
              'startHr':['', Validators.required],
              'endHr':['',Validators.required],
              'startMin':['', Validators.required],
              'endMin':['', Validators.required]
            })
            this.utils.snack('Tracking créé avec succès')
          },
          (err) => {
            this.submitted = false;
          }
        )
        .catch((reason) => this.utils.setErrorsHttp(reason));

      }else{
        this.periodeTrackingServ.update(
          this.track.id,
          this.userLogged.profile.entreprise.id,
          f.libelle,
         + f.startDay,
         + f.endDay,
          f.startHr,
          f.endHr,
          false
        )
        .then(
          (res: any) => {
            this.submitted = false;
            // this.getPeriodeTracking();
            let index = this.periodeTrackings.results.findIndex(
              (element) => element.id == res.id
            );
            if (index != -1) {
              this.periodeTrackings.results[index] = res;
            }
            this.trackingForm = this.fb.group({
              'libelle':['', Validators.required],
              'startDay':['', Validators.required],
              'endDay':['', Validators.required],
              'startHr':['', Validators.required],
              'endHr':['',Validators.required],
              'startMin':['', Validators.required],
              'endMin':['', Validators.required]
            })
            this.alertField = false
            this.doUpdate = false
            this.utils.snack('Tracking modifié avec succès')
           
          },
          (err) => {
            this.submitted = false;
          }
        )
        .catch((reason) => this.utils.setErrorsHttp(reason));

      this.doUpdate = false;
      }
    }else{
      this.alertField = true
    }
  }

  public onSubmit(form: NgForm): void {
    let f = form.value;
   console.log('periode', this.periode)
    console.log('value val', f)
    f["periodeTrackingHeureDebut"] = f.heuredeb * 60 + f.mindeb * 1;
    f["periodeTrackingHeureFin"] = f.heurefin * 60 + f.minfin * 1;

    this.fields = this.utils.formIsNotNull(form, [
      "periodeTrackingLibelle",
      "periodeTrackingJourDebut",
      "periodeTrackingJourFin",

    ]);

    if (this.fields.length === 0) {
      console.log('value ', f.periodeTrackingHeureDebut, + ' ' + f.periodeTrackingHeureFin)
      this.submitted = true;
      if (!this.doUpdate) {
        this.periodeTrackingServ
          .create(
            this.userLogged.profile.entreprise.id,
            f.periodeTrackingLibelle,
            f.periodeTrackingJourDebut,
            f.periodeTrackingJourFin,
            f.periodeTrackingHeureDebut,
            f.periodeTrackingHeureFin,
            false
          )
          .then(
            (res: any) => {
              console.log('create track', res)
              this.submitted = false;
              this.periodeTrackings.results.unshift(res);
              // form.reset();
              this.periode.libelle = "";
               this.periode.jourDebut = null;
               this.periode.jourFin = null;
              
            },
            (err) => {
              this.submitted = false;
            }
          )
          .catch((reason) => this.utils.setErrorsHttp(reason));
      } else {
        // on effectue un update
        this.periodeTrackingServ
          .update(
            this.periode.id,
            this.userLogged.profile.entreprise.id,
            f.periodeTrackingLibelle,
            f.periodeTrackingJourDebut,
            f.periodeTrackingJourFin,
            f.periodeTrackingHeureDebut,
            f.periodeTrackingHeureFin,
            this.periode.deleted
          )
          .then(
            (res: any) => {
              this.submitted = false;
              // this.getPeriodeTracking();
              let index = this.periodeTrackings.results.findIndex(
                (element) => element.id == res.id
              );
              if (index != -1) {
                this.periodeTrackings.results[index] = res;
              }
              form.reset();
             
            },
            (err) => {
              this.submitted = false;
            }
          )
          .catch((reason) => this.utils.setErrorsHttp(reason));

        this.doUpdate = false;
      }
    }
  }

  private getPeriodeTracking(page: number = 1, filter_field: any = null): void {
    this.loading = true;
    let filter = `entreprise: "${this.userLogged.profile.entreprise.id}", deleted: false`;
    if (filter_field != null && filter_field.field != null) {
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`;
    }
    this.periodeTrackingServ.read(filter, page).then(
        (res: any) => {
          console.log('tracks', res)
          this.periodeTrackings = res.results == null ? [] : res;
          this.totalCount = res.totalCount;
          this.loading = false;
          // this.supprimerTouteLesPeriodesTracking()
        },
        (err) => { }
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }

  public updateClick(periode: any): void {
    this.track = periode
    this.doUpdate = true;
    console.log ('periode', periode)

    this.trackingForm = this.fb.group({
      'libelle':[periode.libelle, Validators.required],
      'startDay':[periode.jourDebut, Validators.required],
      'endDay':[periode.jourFin, Validators.required],
      'startHr':['', Validators.required],
      'endHr':['',Validators.required],
      'startMin':['', Validators.required],
      'endMin':['', Validators.required]
    })
  }

  public deleteClick(periode: any): void {
    this.periode = periode;
  }

  openDeletedDialog(periode): void {
    

    const dialogRef = this.dialog.open(NewAlertConfirmComponent, {
      width: "35rem",
      data:{title:'Suppression tracking', message:`Vous êtes sur le point de suprimer la période tracking "" ${periode.libelle} "". Voulez-vous continuer ?`}
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.utils.openLoadingDialog();
        this.periodeTrackingServ.update(
            periode.id,
            periode.entreprise.id,
            periode.libelle,
            periode.jourDebut,
            periode.jourFin,
            periode.heureDebut,
            periode.heureFin,
            true
          )
          .then(
            (res: any) => {
              console.log('tracking delete', res)
              let index = this.periodeTrackings.results.findIndex(
                (element) => element.id == res.id
              );
              if (index != -1) {
                this.totalCount -= 1;
                this.periodeTrackings.results.splice(index, 1);
              }
               this.dialog.getDialogById("wefly_loading_modale_").close();
            },
            (err) => {
              this.dialog.getDialogById("wefly_loading_modale_").close();
            }
          )
          .catch((reason) => this.utils.setErrorsHttp(reason));
      } else {
      }
    });
  }

  public confirmDelete(): void {
    this.periodeTrackingServ
      .update(
        this.periode.id,
        this.periode.entreprise.id,
        this.periode.libelle,
        this.periode.jourDebut,
        this.periode.jourFin,
        this.periode.heureDebut,
        this.periode.heureFin,
        true
      )
      .then(
        (res: any) => {
          // this.getPeriodeTracking();
          let index = this.periodeTrackings.results.findIndex(
            (element) => element.id == res.id
          );
          if (index != -1) {
            this.periodeTrackings.results.splice(index, 1);
          }
        },
        (err) => { }
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
    this.closeModal();
  }

  onCreate(f: NgForm): void {
    this.doUpdate = false;
    this.periode = {
      id: "",
      entreprise: { id: "" },
      libelle: "",
      jourDebut: 0,
      jourFin: 0,
      heureDebut: 0,
      heureFin: 0,
      deleted: false,
      createdAt: "",
      updatedAt: "",
    };
    f.reset();
  }

  resetForm(form: NgForm) {
    form.reset()
  }

  private closeModal(): void {
    $("#confirmation-delete").modal("hide");
    $("#cancel-delete").click();
  }

  public min2hour(m: number): { hour: number; minute: number } {

    let minute: number = m % 60;
    let hour: number = (m - minute) / 60;
    return { hour: hour, minute: minute };
  }

  public supprimerTouteLesPeriodesTracking(): void {
    // permet de supprimer tout les tracking
    this.periodeTrackings.results.forEach((value, index) => {
      this.periodeTrackingServ
        .update(
          value.id,
          value.entreprise.id,
          value.libelle,
          value.jourDebut,
          value.jourFin,
          value.heureDebut,
          value.heureFin,
          true
        )
        .then(
          (res: any) => { },
          (err) => { }
        )
        .catch((reason) => this.utils.setErrorsHttp(reason));
    });
    this.getPeriodeTracking();
  }

  onChangePage(data) {
    this.currentPage = data.id;
    this.getPeriodeTracking(data.id);
  }

  filterData(data:string) {
   this.filter = data
}

croissant() {
  this.isSelected = !this.isSelected
  if(!this.isSelected){
    this.isSelectedDec = true
    this.utils.OrdrecroisantArray(this.periodeTrackings.results, 'libelle')
  }
}


decroissant() {
  this.isSelectedDec = !this.isSelectedDec
  if(!this.isSelectedDec){
    this.isSelected = true
    this.utils.ordreDecrossantArray(this.periodeTrackings.results, 'libelle')
    
  }
}
}
