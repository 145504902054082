import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "libFilter",
  pure: false,
})
export class LibFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;
    if (!args) return value;
    args = args.value !== null ? args.value.toLowerCase() : "";

    return value.filter((data: any) => {
      return JSON.stringify(data).toLowerCase().includes(args);
    });
  }
}
