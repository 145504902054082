import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ZoneActionService } from 'src/app/_services/zone.action.service';
import { UserService } from 'src/app/_services/user.service';
import { ProfileService } from 'src/app/_services/profile.service';
import { CategorieProfileService } from 'src/app/_services/profile-categorie.service';
import { FonctionService } from 'src/app/_services/fonction.service';
import { UtilsService } from 'src/app/_services/utils.service';


export interface DialogData {
  user : any;
}

@Component({
  selector: 'app-edit-profil',
  templateUrl: './edit-profil.component.html',
  styleUrls: ['./edit-profil.component.scss']
})


export class EditProfilComponent implements OnInit {

  user : any = null;
  edit : boolean = false;

  obj : any = {}


  profilForm : FormGroup
  zones : any[];
  categories : any[];
  fonctions : any[];

  constructor(
      public dialogRef: MatDialogRef<EditProfilComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      private formBuilder : FormBuilder,
      private zoneService : ZoneActionService,
      private userService : UserService,
      private profilService : ProfileService,
      private categorieService : CategorieProfileService,
      private fonctionService : FonctionService,
      private snackBar: MatSnackBar,
      private utils : UtilsService
  ){}

  ngOnInit() {
    this.user = this.data.user;
   
    this.init();
    this.readZone()
    this.readCategorie()
    this.readFonction()

  }

  init(){
    this.profilForm = this.formBuilder.group({
      photo : [this.user.profile.photoUrl ? this.user.profile.photoUrl : ""],
      nom : [this.user.profile.nom, [Validators.required]],
      prenom : [this.user.profile.prenom, [Validators.required]],
      contact : [this.user.profile.contact, [Validators.required]],
      email : [this.user.email, [Validators.email]],
      genre : [this.user.profile.genre],
      dateNaissance : [this.user.profile.dateNaissance],
      zone : [this.user.profile.zone ? this.user.profile.zone.id : "", [Validators.required]],
      categorie : [this.user.profile.categorie ? this.user.profile.categorie.id : "", [Validators.required]],
      fonction : [this.user.profile.fonction ? this.user.profile.fonction.id : "", [Validators.required]]
    });
  }

  readZone(){
    let filter = `entreprise : "${this.user.profile.entreprise.id}"`;
    this.zoneService.read(filter).then(
      (res : any) => {
       
        this.zones = res['searchZoneAction']['results']
      },
      (error) => {
        console.error(error)
      }
    )
  }

  readCategorie(){
    this.categorieService.read().then(
      (res)  => {
        this.categories = res['searchCategorieProfile'].results
       
      },
      (error)  => {
        console.error(error)
      }
    )
  }


  readFonction(){
    this.fonctionService.readAllFonction().then(
      (res : any)  => {
        this.fonctions = res.results
      },
      (error)  => {
        console.error(error)
      }
    )
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(){
    if(this.profilForm.invalid){
      
      return
    }
    
    this.userService.update(this.user.id, this.user.username, this.user.dateJoined, this.profilForm.value['email'], this.user.firstName, this.user.isActive, this.user.isStaff, this.user.isSuperuser, this.user.lastLogin, this.user.lastName).then(
      (res) => {
        this.user.email = this.profilForm.value['email'];
        this.profilService.update(
          this.user.profile.id,
          this.profilForm.value['categorie'],
          this.user.profile.codeDeVerification ? this.user.profile.codeDeVerification : '',
          this.profilForm.value['contact'],
          this.profilForm.value['dateNaissance'],
          false,
          this.user.profile.displayName ? this.user.profile.displayName : "",
          this.user.profile.entreprise.id,
          this.profilForm.value['fonction'],
          this.profilForm.value['genre'] ,
          this.profilForm.value['nom'],
          this.user.id,
          this.user.profile.photo,
          this.profilForm.value['prenom'],
          this.user.profile.tracking,
          this.user.id,
          this.profilForm.value['zone']
          ).then(
            (res) => {
        
              this.user.profile['categorie']['id'] = this.profilForm.value['categorie'];
              this.user.profile.contact = this.profilForm.value['contact'];
              this.user.profile.dateNaissance = this.profilForm.value['dateNaissance']
              this.user.profile['fonction']['id'] = this.profilForm.value['fonction']
              this.user.profile.genre = this.profilForm.value['genre']
              this.user.profile.nom = this.profilForm.value['nom']
              this.user.profile.prenom = this.profilForm.value['prenom']
              this.user.profile['zone']['id'] = this.profilForm.value['zone']
              this.init()
              this.snackBar.open('Profil modifié avec succès', 'Ok', { duration: 3000 });
              let profileDetail = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
              if(profileDetail.id == this.user.id){
            
                this.utils.setCookie('_wefly_', this.utils.encrypt(JSON.stringify(this.user)), { nb: 7, unite: 3 });
              }
              this.edit = true;
            },
            (error) => {
              console.error("Error update profil, ", error)
            }
          )
      },
      (error) => {
        console.error("Error lors du modification de USER")
      }
    )
  }

  public processFile(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profilForm.get("photo").setValue(reader.result)
        this.user.profile.photo = reader.result
      };
    } else {
      // tslint:disable-next-line:no-console
    }
  }

}
