import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  viewProviders: [
    
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class SelectInputComponent implements OnInit {
  @Input() controlName: string;
  @Input() label?: string = 'input';
  @Input() className: string = 'formClass'
  @Input() options: {name: string, value: any}[] = []
  @Input() value:any
  @Input() icon:string
  
  @Input() readOnly: boolean = false;
  @Input() redBorder?: boolean = false;

  constructor() { }

  ngOnInit() {
    
  }

}
