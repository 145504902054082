import { Component, OnInit } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Component({
  selector: 'app-test-de-serv',
  templateUrl: './test-de-serv.component.html',
  styleUrls: ['./test-de-serv.component.css']
})
export class TestDeServComponent implements OnInit {

  constructor(
    private dbService: NgxIndexedDBService
  ) { }


  ngOnInit() {
  }
}