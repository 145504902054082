import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../_services/profile.service';
import { ActivatedRoute } from '@angular/router';
import { ProfileModel } from '../models';
import { UtilsService } from '../_services/utils.service';

@Component({
  selector: 'app-listusers',
  templateUrl: './listusers.component.html',
  styleUrls: ['./listusers.component.css']
})
export class ListusersComponent implements OnInit {
  Profiles: any;
  id: any;
  toModifiy: ProfileModel;
  userInfo: any;

  constructor(
    private profileservice: ProfileService,
    private route: ActivatedRoute,
    private utils: UtilsService,
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')))
    this.getAllProfiles();
  }
   getAllProfiles() {
    //  this.profileservice.readAllProfile().then(data => {
    //   this.Profiles = data;
    //  }).catch(reason =>  this.utils.setErrorsHttp(reason));
   }

  getOneProfile(id,page:number = 1) {
    // this.profileservice.read(`(entreprise: "${this.userInfo.profile.entreprise.id}")`,page).then(data => {
    // }).catch(reason =>  this.utils.setErrorsHttp(reason));
    // this.getAllProfiles();
  }


  updateProfile(data) {
    // this.profileservice.updateProfile(data).then(data => {
    // });
    this.getAllProfiles();
  }
}
