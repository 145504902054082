import { Component, OnChanges, SimpleChanges } from "@angular/core";
import {
  trigger,
  style,
  animate,
  transition,
  query,
} from "@angular/animations";
import { UtilsService } from "./_services/utils.service";

import { detectionConnexion } from "./networkDetection/index.js";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  animations: [
    trigger("routerAnimation", [
      transition("* <=> *", [
        query(
          ":enter",
          style({
            position: "fixed",
            width: "100%",
            transform: "translateX(-100%)",
          }),
          { optional: true }
        ),

        query(
          ":leave",
          animate(
            "500ms ease",
            style({
              position: "fixed",
              width: "100%",
              transform: "translateX(100%)",
            })
          ),
          { optional: true }
        ),

        query(
          ":enter",
          animate(
            "500ms ease",
            style({
              opacity: 1,
              transform: "translateX(0%)",
            })
          ),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AppComponent implements OnChanges {
  constructor(private utils: UtilsService) {}

  ngOnInit() {
  
    detectionConnexion(this.utils.API_HOST, "detaiConnect")
  }
  ngOnChanges(changes: SimpleChanges) {
    
  }
  title = "wefly-agri";

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }
}
