import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../_services/utils.service';
import { CoucheService } from '../_services/couche.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import 'bootstrap';
import 'jquery';
import { TypeDeCoucheService } from '../_services/type-couche.service';
import { UserService } from '../_services/user.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { validateEvents } from 'angular-calendar/modules/common/util';
import { DeletedComponent } from '../_modale/deleted/deleted.component';
declare const $: any;
@Component({
  selector: 'app-couche',
  templateUrl: './couche.component.html',
  styleUrls: ['./couche.component.css']
})
export class 
CoucheComponent implements OnInit {


  // Parametre filter
  fields_selected_ : Array<any> = [
    { libelle_db : "libelle_Icontains", libelle : "Libelle" },
    { libelle_db : "description_Icontains", libelle : "Description" }
  ]

  filter : any = {
    field : null,
    value : null
  }

  loading : boolean = true

  // Fin paramatre

  couches: any = null
  typeCouches: any[]
  coucheForm: FormGroup;
  update: boolean = false;
  submitted: boolean = false
  userEntreprise: any;

  // Pagin parametre
  maxOfPages : number = 20;
  totalCount : number = 0;
  currentPage : number = 1;

  constructor(
    private utils: UtilsService,
    private service: CoucheService,
    private snackBar: MatSnackBar,
    private typeService: TypeDeCoucheService,
    private usersv: UserService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getTypeCouche();
    this.initFormCouche();
    this.userEntreprise = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')))
    this.getCouche();
  }

  initFormCouche() {
    this.coucheForm = this.formBuilder.group({
      'libelle': ['', Validators.required],
      'description': ['', Validators.required],
      'couchePrincipale': [false],
      'typeCouche': ['', Validators.required],
      'coucheSup': ['']
    });
  }

  get f() { return this.coucheForm.controls; }

  getCouche(page : number = 1, filter_field : any = null) {
    this.loading = true
    let filter = `entreprise: "${this.userEntreprise.profile.entreprise.id}", deleted: false`
    if(filter_field != null && filter_field.field != null){
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`
    }
    this.service.read(filter, page).then(
      (res) => {
        this.loading = false
        this.couches = res
        this.totalCount = res['totalCount']
      },
      (error) => {
       
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason))
  }

  getTypeCouche() {
    this.typeService.read().then(
      (res) => {
        
        this.typeCouches = res['searchTypeDeCouche']['results']
      },
      (error) => {
      
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason));
  }

  submit() {
    this.submitted = true
    if (this.coucheForm.invalid) {
      return
    }
    let libelle = this.coucheForm.value['libelle']; let description = this.coucheForm.value['description'];
    let couchePrincipale = this.coucheForm.value['couchePrincipale']; let typeCouche = this.coucheForm.value['typeCouche'];
    let coucheSup = this.coucheForm.value['coucheSup']
    if (!this.coucheForm.value.id) {
      this.service.create(coucheSup, description, this.userEntreprise.profile.entreprise.id, libelle, couchePrincipale, typeCouche).then(
        (res) => {
          this.submitted = false;
          this.initFormCouche()
          this.snackBar.open('Enregistré', 'Ok', { duration: 3000 });
          this.couches.results.unshift(res)
        },
        (error) => {
          this.snackBar.open('Erreur d\'enregistrement', 'Ok', { duration: 3000 });
        }
      ).catch(reason =>  this.utils.setErrorsHttp(reason))
    } else {
      let id = this.coucheForm.value['id']
      this.service.update(id, coucheSup, description, this.userEntreprise.profile.entreprise.id, libelle, couchePrincipale, typeCouche).then(
        (res) => {
          this.submitted = false
          this.initFormCouche()
          let index = this.couches.results.findIndex(element => element.id == res['id']);
          if(index != -1){
            this.couches.results[index] = res
          }
        },
        (error) => {
        }
      ).catch(reason =>  this.utils.setErrorsHttp(reason))
    }
    this.update = false
  }

  edit(id: string) {
    this.update = true
    if (this.coucheForm.value['id']) {
      this.coucheForm.controls['id'].setValue(id);
    } else {
      this.coucheForm.addControl('id', new FormControl(id, Validators.required));
    }
    let couche = this.couches.results.filter((couche) => {
      if (couche['id'] === id) {
        return couche
      }
    });
    this.coucheForm.controls['description'].setValue(couche[0]['description']);
    this.coucheForm.controls['libelle'].setValue(couche[0]['libelle']);
    this.coucheForm.controls['couchePrincipale'].setValue(couche[0]['principal']);
    this.coucheForm.controls['typeCouche'].setValue(couche[0]['typeCouche']['id']);
    this.coucheForm.controls['coucheSup'].setValue(couche[0]['coucheSup']['id']);
  }

  create() {
    this.initFormCouche()
    this.update = false;
  }

  delete(d: any) {
    let c = confirm('Voulez-vous supprimer la couche ' + d.libelle + '?');
    if (c)
      this.service.delete(d.id).then(
        (res) => {
          console.clear();
          this.couches.results.filter((value, index) => {
            if(value.id == d.id){
              this.couches.results.splice(index, 1)
            }
          })
        },
        (error) => {
        }
      ).catch(reason =>  this.utils.setErrorsHttp(reason))
  }



  openDeletedDialog(d): void {
    const dialogRef = this.dialog.open(DeletedComponent, {
      width: '300px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.utils.openLoadingDialog();
        this.service.delete(d.id).then(
          (res) => {
            this.couches.results.filter((value, index) => {
              if(value.id == d.id){
                this.couches.results.splice(index, 1)
              }
            });
            this.dialog.getDialogById("wefly_loading_modale_").close()
          },
          (error) => {
            this.dialog.getDialogById("wefly_loading_modale_").close()
          }
        ).catch(reason =>  this.utils.setErrorsHttp(reason))
      }else{
      }
    });
  }



  onChangePage(data){
    this.currentPage = data.id;
    this.getCouche(data.id)
  }

  filterData(data){
    if(data.action === 0){
      return
      this.filter = data.data_
    }else{
      this.loading = true
      this.filter = data.data_
      if(data.data_.value.length == 0){
        this.getCouche(1)
      }else{
        this.getCouche(1, this.filter)
      }
    }
  }

}
