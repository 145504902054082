import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
	providedIn: 'root'
})
export class PeriodeTrackingService {

	constructor(private utils: UtilsService) { }

	create(entreprise: String, libelle: String, jourDebut: number, jourFin: number, heureDebut: Number, heureFin: Number, deleted: Boolean = false) {
		const query = `
    mutation(
		$deleted: Boolean,
		$entreprise: ID,
		$heureDebut: Int,
		$heureFin: Int,
		$jourDebut: Int,
		$jourFin: Int,
		$libelle: String,

	) {
      createPeriodeTracking(newPeriodetracking: {
			deleted: $deleted,
			entreprise: $entreprise,
			heureDebut: $heureDebut,
			heureFin: $heureFin,
			jourDebut: $jourDebut,
			jourFin: $jourFin,
			libelle: $libelle,

		}) {
        periodetracking {
          id
					deleted,
					entreprise{ id },
					heureDebut,
					heureFin,
					jourDebut,
					jourFin,
					libelle,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query, {
					deleted,
					entreprise,
					heureDebut,
					heureFin,
					jourDebut,
					jourFin,
					libelle,
				}).then(
					(res) => {
						
						if (res['createPeriodeTracking']['ok']) {
							resolve(res['createPeriodeTracking']['periodetracking'])
						}
						reject(res['createPeriodeTracking']['errors']);
					}, (err) => {
						this.utils.setErrorsHttp(err)
						reject(err);
					}
				);
			}
		);
	}

	update(id: String, entreprise: String, libelle: String, jourDebut: number, jourFin: number, heureDebut: Number, heureFin: Number, deleted: Boolean = false) {
		
		const query = `
    mutation(
			$id: UUID!,
		$deleted: Boolean,
		$entreprise: ID,
		$heureDebut: Int,
		$heureFin: Int,
		$jourDebut: Int,
		$jourFin: Int,
		$libelle: String,
		) {
      updatePeriodeTracking(newPeriodetracking: {
				id: $id, deleted: $deleted,
			entreprise: $entreprise,
			heureDebut: $heureDebut,
			heureFin: $heureFin,
			jourDebut: $jourDebut,
			jourFin: $jourFin,
			libelle: $libelle,
			}) {
        periodetracking {
          id
					deleted,
					entreprise{ id },
					heureDebut,
					heureFin,
					jourDebut,
					jourFin,
					libelle,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query, {
					id, deleted,
					entreprise,
					heureDebut,
					heureFin,
					jourDebut,
					jourFin,
					libelle,
				}).then(
					(res) => {
						if (res['updatePeriodeTracking']['ok']) {
							resolve(res['updatePeriodeTracking']['periodetracking'])
						}
						reject(res['updatePeriodeTracking']['errors']);
					}, (err) => {
						this.utils.setErrorsHttp(err)
						reject(err);
					}
				);
			}
		);
	}

	read(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
		filter = (filter == null || filter == '') ? 'deleted: false' : filter;
		const query = `
		query {
			searchPeriodeTracking (${filter}){
			results(page:` + page + `, ordering:"` + order + `") {
				id
				deleted,
				entreprise{ id },
				heureDebut,
				heureFin,
				jourDebut,
				jourFin,
				libelle,
				createdAt
				updateAt
				}
				totalCount
			}
		}
    `;
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query).then(
					(res: any) => {
						resolve(res.searchPeriodeTracking);
					}, (err) => {
						this.utils.setErrorsHttp(err)
						reject(err);
					}
				);
			}
		);
	}

	read1(entreprise: string , page: number = 1, order: String = '-created_at'){
		const query = `
		query ($entreprise:ID) {
			searchPeriodeTracking (
		entreprise:$entreprise,
		deleted:false
	  ){
			results(page:` + page + `, ordering:"` + order + `") {
				id
				deleted,
				entreprise{ id },
				heureDebut,
				heureFin,
				jourDebut,
				jourFin,
				libelle,
				createdAt
				updateAt
				}
				totalCount
			}
		}
		`;
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query,{
					entreprise
				}).then(
					(res: any) => {
						resolve(res);
					}, (err) => {
						this.utils.setErrorsHttp(err)
						reject(err);
					}
				);
			}
		);
		}
}

