import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  link = "https://instagram.fabj4-1.fna.fbcdn.net/vp/04927fbed5ad1112a674bd21e480e265/5DBCBC06/t50.2886-16/69661606_130445851561066_5734840794355540601_n.mp4?_nc_ht=instagram.fabj4-1.fna.fbcdn.net&_nc_cat=110";
  constructor( public utils: UtilsService) { }

  ngOnInit() {
  }

}
