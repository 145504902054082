import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MatSlideToggleChange, MAT_DIALOG_DATA } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../_store/app.state';
import { selectForm } from '../_store/selectors/form.selectors';

@Component({
  selector: 'app-excel-field-to-dowload',
  templateUrl: './excel-field-to-dowload.component.html',
  styleUrls: ['./excel-field-to-dowload.component.scss']
})
export class ExcelFieldToDowloadComponent implements OnInit {
  formcouches$: Observable<object[]>;
  formCouches: any[]
  fields: Map<string, string> = new Map()
  private dialogRef: any;
  public data: any;
  constructor(
    private injector: Injector,
    private store: Store<AppState>,
  ) {
     this.data = this.injector.get(MAT_DIALOG_DATA, null)
     this.dialogRef = this.injector.get(MatDialogRef, null)
   }

  ngOnInit() {
    this.listExcelField()
  }

  listExcelField(couche = this.data.couche){
    this.formcouches$ = this.store.pipe(select(selectForm));
    return new Promise(
      (resolve, reject) => {
        this.formcouches$.subscribe(
          (res: any) => {
            if(res){
             let couches_copy = res.filter(
               (element: any) => {
                 return element.libelle === couche
                }
             )
             this.formCouches = JSON.parse(JSON.stringify(couches_copy))
    
             couches_copy.map(
               (form) => {
                 form.formulaire.compartiments.map(
                   (comp: any, compIndex) => {
                     comp.attributs.map(
                       (attr, index) => {
                         if(attr.attribut.typeAttribut.libelle === "file"){
                          this.formCouches[0].formulaire.compartiments[compIndex].attributs[index] = null
                         }
                       }
                     )
                   }
                 )
               }
             )
             this.formCouches.map(
               (form) => {
                form.formulaire.compartiments.map(
                  (comp: any, compIndex) => {
                    let atr_list = comp.attributs.filter(
                      (atr) => atr !== null
                    )
                    if (atr_list.length === 0){
                      return comp.attributs = []
                    }else{
                      for (const _element of atr_list) {
                           this.fields.set(_element.attribut.libellebd, _element.attribut.libellebd)
                      }
                      resolve(this.fields)
                     /*  atr_list.forEach((_element: any) => {
                          
                          
                      }); */
                    }
                  }
                )
               }
             )
            }
          }
        )
      }
    )
  }

  selectField(field: MatSlideToggleChange){
     if(field.checked){
       this.fields.set(field.source.id, field.source.id)
     }else{
        if(this.fields.has(field.source.id)){
          this.fields.delete(field.source.id)
        }
     }

  }
  exportFields(){
    this.dialogRef.close(this.fields)
  }
  cancelDialog(){
    this.dialogRef.close(false)
  }

  isAllFieldSelected(attributs: {attribut: {libelle: string, libellebd: string}}[]){
     return attributs.every(
       (item) => {
             if(item){
               return this.fields.has(item.attribut.libellebd)
             }else{
               return true
             }
             
       })
  }

  selectAllField(all: MatSlideToggleChange, attributs: {attribut: {libelle: string, libellebd: string}}[]){
    if(all.checked){
      attributs.forEach(
        (item) => {
          if(item){
            this.fields.set(item.attribut.libellebd, item.attribut.libellebd) 
          }
        }
      )
    }else{
      attributs.forEach(
        (item) => {
          if(item){
            this.fields.delete(item.attribut.libellebd)
          }
        }
      )
    }
  }

}
