import { ConfirmComponent } from './../confirm/confirm.component';
import { FamilleDeCultureService } from './../_services/famille-de-culture.service';
import { UtilsService } from '../_services/utils.service';
import { FormStockComponent } from './../@marketplace/_modals/form-stock/form-stock.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-famille-culture',
  templateUrl: './famille-culture.component.html',
  styleUrls: ['./famille-culture.component.scss']
})
export class FamilleCultureComponent implements OnInit {
  familleCultures = [];
  columnsToDisplay = ['libelle', 'description', 'action'];
  dataSource = new MatTableDataSource<any>();
  // tslint:disable-next-line: max-line-length
  constructor(public dialog: MatDialog, private util: UtilsService, public familleCulture: FamilleDeCultureService) { }

  ngOnInit() {
    this.readFamilleCulture();
  }
   readFamilleCulture() {
     this.familleCulture.read().then(
       (res: []) => {
           this.familleCultures = res;
           this.dataSource.connect().next(this.familleCultures);
        },
        (err) => {
        }
     );
   }
  addFamille() {
    const dialogRef = this.dialog.open(FormStockComponent, {
      width: '60%',
      data: { ref: 'familleCulture', formType: 'Famille de cuture', instance: {
        libelle: null,
        description: null
      }}
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.familleCultures.push(res);
          this._updateDataSource();
          this.util.snack('Famille de culture enregistrée avec succès');
        }
      }
    );
  }

  update(i) {
    const intance = this.familleCultures[i];
    const dialogRef = this.dialog.open(FormStockComponent, {
      width: '60%',
      data: { ref: 'familleCulture', formType: 'Famille de cuture', instance: intance}
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.familleCultures[i] = res;
          this._updateDataSource();
          this.util.snack('Famille de culture mis à jour avec succès');
        }
      }
    );
  }

  delete(i) {
    const libelle = this.familleCultures[i].libelle;
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '50%',
      data : { message: `Souhaitez vous supprimer la famille de culture ${this.familleCultures[i].libelle}`}
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.familleCulture.update(
            this.familleCultures[i].id,
            true,
            this.familleCultures[i].description,
            this.familleCultures[i].displayName,
            this.familleCultures[i].libelle
          ).then(
            (result) => {
              this.familleCultures.splice(i, 1);
              this._updateDataSource();
              this.util.snack(`${libelle} supprimé avec succès`);
            },
            (err) => {
              this.util.setErrorsHttp(err);
            }
          );
        }
      }
    );
  }
  _updateDataSource() {
    this.dataSource.connect().next(this.familleCultures);
  }
}
