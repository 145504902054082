import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PuceModel } from '../models';


@Component({
  selector: 'app-puce',
  templateUrl: './puce.component.html',
  styleUrls: ['./puce.component.css']
})
export class PuceComponent implements OnInit {
/*
 *
 * puce s'utilise de la façon suivante:
 * dans le html: <app-puce (outputPuce)="receive($event)" [puce]="puce">testeur</app-puce>
 * receive(dans le fichier ts du père) correspond à la fonction qui recevra le output(l'élément envoyé par app-puce)
 * puce est un tableau qui contiendra toutes vos puces son formattage se fait en suivant son modelèle
 * receive(v: PuceModel) {
 *  //traitement
 * }
 */

  @Input() puce: PuceModel[];
  @Output() outputPuce = new EventEmitter<PuceModel>();
  
  constructor() { }

  ngOnInit() {
  }

  emitter(v: PuceModel) {
    v = v as PuceModel;
    this.outputPuce.emit(v);
  }
}
