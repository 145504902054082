import { FormsService } from "./../../_facade/forms.facade.service";
import { Component, OnInit } from "@angular/core";
import { UtilsService } from "src/app/_services/utils.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/_services/user.service";
import { UserNavComponent } from "src/app/user-nav/user-nav.component";
import { Navigation } from "src/app/_models/navigation";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  menus = [
    new Navigation('./../../../assets/icon/dashboard.svg','./../../../assets/icon/dashboard_border-white.svg','Tableau de bord','dashBoard','/wegeo/dashboard',"/wegeo"),
    new Navigation('./../../../assets/icon/gestion_collecteur_border.svg','./../../../assets/icon/gestion_collecteur-white.svg','Gestion des collecteurs','collecteur','/wegeo/employes',""),
    new Navigation('./../../../assets/icon/planteur_plantation_border.svg','./../../../assets/icon/planteur_plantation_border-white.svg','Planteurs & Plantations','planteur','/wegeo/plantation',""), 
    new Navigation('./../../../assets/icon/Formulaires.svg','./../../../assets/icon/formulaires-white.svg','Formulaires','formulaire','/wegeo/formulaire',""),
    new Navigation('./../../../assets/icon/Zones.svg','./../../../assets/icon/zones-border-white.svg','Zones','zone','/wegeo/zone',""),
    new Navigation('./../../../assets/icon/Performance.svg','./../../../assets/icon/performance_border-white.svg','Performances','performance','/wegeo/performance',""),
    //new Navigation('./../../../assets/icon/Tracking.svg','./../../../assets/icon/tracking-white.svg','Tracking','tracking'),
    new Navigation('./../../../assets/icon/administration_border.svg','./../../../assets/icon/administration_border-white.svg','Administration', 'admin','/wegeo/admin/fonction', '/wegeo/admin/periode_traking')
  
  ]
  
  constructor(
    public utils: UtilsService,
    private formFacade: FormsService,
    private router: Router,
    private userService:UserService,
   
  ) {}

  ngOnInit() {

       
          this.getFormCouche(result =>{
          });

    
    // console.log("chexxk", this.utils.getCurrentUser().profile.entreprise);
    // window.location.href = 'accueil'
  }

  public getFormCouche(formGeted) {
    
    
    if (this.utils.getCurrentUser().profile.entreprise) {
      this.formFacade.getEntrepriseForm(
        this.utils.getCurrentUser().profile.entreprise.id, 
        (onresult) => {
          if (onresult) {
            formGeted(true);
          } else {
            formGeted(false);
          }
        }
      );
    } else {
   
      // window.location.href = 'accueil';
      this.router.navigate(["/accueil"]);
    }
  }
}
