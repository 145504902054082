import { HebDoweatherItemComponent } from "./@wegeo/heb-doweather-item/heb-doweather-item.component";
import { WeatherDayComponent } from "./@wegeo/weather-day/weather-day.component";
import { LayerSwitchBtnComponent } from "./@wegeo/map-dash/layer-switch-btn/layer-switch-btn.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { IndexComponent } from "./index/index.component";

import {
  MatNativeDateModule,
  MatSnackBarModule,
  MatDialogModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatTabsModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatCardModule,
  MatFormFieldModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatMenuModule,
  MatExpansionModule,
  MatAccordion,
  MatStepperModule,
  MatSlideToggleModule,
} from "@angular/material";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { NgxEchartsModule } from "ngx-echarts";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import {MatProgressBarModule} from '@angular/material/progress-bar'
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { CommonModule, registerLocaleData } from "@angular/common";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { InscriptionComponent } from "./inscription/inscription.component";
import { NavbarComponent } from "./navbar/navbar.component";
import "pretty-checkbox";
import { TestDeServComponent } from "./test-de-serv/test-de-serv.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { ImgLoaderComponent } from "./img-loader/img-loader.component";
import { FonctionnaliteEntrepriseComponent } from "./fonctionnalite-entreprise/fonctionnalite-entreprise.component";
import { AuthgardService } from "./login/authgard.service";
import { CookieService } from "ngx-cookie-service";
import { NgxPaginationModule } from "ngx-pagination";
import { PageDashboardComponent } from "./page-dashboard/page-dashboard.component";
import { TesteurComponent } from "src/ui/testeur/testeur.component";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { DatePipePipe } from "./_pipe/date-pipe.pipe";
import { ConvertBooleanPipe } from "./_pipe/convert-boolean.pipe";
import { AddZeroPipe } from "./_pipe/add-zero.pipe";
import { DynamicFormComponent } from "./dynamic-form/dynamic-form.component";
import { PeriodeTrackingUserComponent } from "./_modale/periode-tracking-user/periode-tracking-user.component";
import { HomeComponent as HomeWegeo } from "./@wegeo/home/home.component";
import { HomeComponent as HomeDrone } from "./@drone/home/home.component";
import { HomeComponent as HomeMarket } from "./@marketplace/home/home.component";
import { GestionEmployeComponent } from "./@wegeo/gestion-employe/gestion-employe.component";
import { FiltreComponent } from "./filtre/filtre.component";
import { DataFilterPipe } from "./_pipe/data-filter.pipe";
import { PagineFilterPipe } from "./_pipe/pagine-filter.pipe";
import { LibFilterPipe } from "./_pipe/lib-filter.pipe";
import { LibZoneFilterPipe } from "./_pipe/zone-filter.pipe";
import { PlanteurPipe} from "./_pipe/planteur.pipe"
import { PlantationPipe } from "./_pipe/plantation.pipe"
import {EmployePipe } from "./_pipe/employe.pipe";
import { DeleteActeurComponent } from "./_modale/delete-acteur/delete-acteur.component";
import { ZeroInjectorPipe } from "./_pipe/zero-injector.pipe";
import { RoleUserComponent } from "./_modale/role-user/role-user.component";
import { GSearchComponent } from "./g-search/g-search.component";
import { PaginationComponent } from "./graphql/pagination/pagination.component";
import { ProfileComponent } from "./profile/profile.component";
import { RoleComponent } from "./role/role.component";
import { FonctionComponent } from "./fonction/fonction.component";
import { PermissionComponent } from "./permission/permission.component";
import { ListusersComponent } from "./listusers/listusers.component";
import { PaginationsComponent } from "./paginations/paginations.component";
import { CooperativeDashboardComponent } from "./@wegeo/cooperative-dashboard/cooperative-dashboard.component";
import { SidebarComponent } from "src/ui/sidebar/sidebar.component";
import { UserNavComponent } from "./user-nav/user-nav.component";
import { UserComponent } from "./user/user.component";
import { EmployeDetailsComponent } from "./@wegeo/employe-details/employe-details.component";
import { EmployeComponent } from "./@wegeo/employe/employe.component";
import { LeafletSampleComponent } from "./@wegeo/leaflet-sample/leaflet-sample.component";
import { GestionPlantationComponent } from "./@wegeo/gestion-plantation/gestion-plantation.component";
import { TrackingComponent } from "./@wegeo/tracking/tracking.component";
import { PerformanceComponent } from "./@wegeo/performance/performance.component";
import { AttributComponent } from "./attribut/attribut.component";
import { AccueilComponent } from "./accueil/accueil.component";
import { FormationComponent } from "./@wegeo/formation/formation.component";
import { FormsComponent } from "./@wegeo/forms/forms.component";
import { WegeoAdminComponent } from "./@wegeo/wegeo-admin/wegeo-admin.component";
import { TypeAttributComponent } from "./type-attribut/type-attribut.component";
import { EntrepriseComponent } from "./entreprise/entreprise.component";
import { CompartimentFormComponent } from "./compartiment-form/compartiment-form.component";
import { FormcoucheComponent } from "./formcouche/formcouche.component";
import { PlanteurDetailComponent } from "./@wegeo/planteur-detail/planteur-detail.component";
import { CoucheComponent } from "./couche/couche.component";
import { TypeCoucheComponent } from "./type-couche/type-couche.component";
import { StatCardComponent } from "./@wegeo/stat-card/stat-card.component";
import { FormbuilderComponent } from "./@wegeo/formbuilder/formbuilder.component";
import { TraficComponent } from "./trafic/trafic.component";
import { PieComponent } from "./pie/pie.component";
import { DynamicInputComponent } from "./dynamic-input/dynamic-input.component";
import { MapDashComponent } from "./@wegeo/map-dash/map-dash.component";
import { WeatherComponent } from "./@wegeo/weather/weather.component";
import { ProfileCategorieComponent } from "./profile-categorie/profile-categorie.component";
import { PrincipalMenuComponent } from "./principal-menu/principal-menu.component";
import { PaysComponent } from "./pays/pays.component";
import { FonctionaliteComponent } from "./fonctionalite/fonctionalite.component";
import { PeriodeTrackingComponent } from "./periode-tracking/periode-tracking.component";
import { WgZoneComponent } from "./@wegeo/wg-zone/wg-zone.component";
import { EditFormComponent } from "./@wegeo/edit-form/edit-form.component";
import { PlanteurComponent } from "./@wegeo/planteur/planteur.component";
import { ActeursComponent } from "./@wegeo/acteurs/acteurs.component";
import { ZoneComponent } from "./zone/zone.component";
import { PlantationsComponent } from "./@wegeo/plantations/plantations.component";
import { DeletePlantationsComponent } from "./@wegeo/_modale/delete-plantations/delete-plantations.component";
import { EnteteListComponent } from "src/ui/entete-list/entete-list.component";
import { FilterComponent } from "./@wegeo/filter/filter.component";
import { InputComponent } from "src/ui/input/input.component";
import { AllComponent } from "src/ui/all/all.component";
import { EditProfilComponent } from "./@wegeo/_modale/edit-profil/edit-profil.component";
import { PaginationControlsComponent } from "./_pagination/pagination-controls/pagination-controls.component";
import { ActiveOrDesactiveUserComponent } from "./@wegeo/_modale/active-or-desactive-user/active-or-desactive-user.component";
import { ActiveOrDesactiveTrackingUserComponent } from "./@wegeo/_modale/active-or-desactive-tracking-user/active-or-desactive-tracking-user.component";
import { ButtonComponent } from "src/ui/button/button.component";
import { PuceComponent } from "src/ui/puce/puce.component";
import { LoginComponent } from "./login/login.component";
import { DeletedComponent } from "./_modale/deleted/deleted.component";
import { DetailActeurComponent } from "./@wegeo/detail-acteur/detail-acteur.component";
import { ListSimpleComponent } from "src/ui/list-simple/list-simple.component";
import { ModalComponent } from "src/ui/modal/modal.component";
import { LoadingComponent } from "./@wegeo/_modale/loading/loading.component";
import { EmployeFormComponent } from "./@wegeo/_modale/employe-form/employe-form.component";
import { ResetPasswordEmployeComponent } from "./@wegeo/_modale/reset-password-employe/reset-password-employe.component";
import { UtilsService } from "./_services/utils.service";
import { ActeurService } from "./_services/acteurs.service";
import { NewActeurService } from "./_services/newActeurs.service";
import { ActeurAttributValeurService } from "./_services/acteur-attribut-valeur.service";
import { AttributFormulaireService } from "./_services/attribut-formulaire.service";
import { AttributOptionsService } from "./_services/attribut-options.service";
import { AdresseService } from "./_services/adresse.service";
import { AttributService } from "./_services/attribut.service";
import { CompartimentService } from "./_services/compartiment.service";
import { CoucheService } from "./_services/couche.service";
import { FonctionaliteService } from "./_services/fonctionalite.service";
import { FamilleDeCultureService } from "./_services/famille-de-culture.service";
import { CultureService } from "./_services/culture.service";
import { ConnexionService } from "./_services/connexion.service";
import { EntrepriseService } from "./_services/entreprise.service";
import { FormCoucheService } from "./_services/form-couche.service";
import { FonctionService } from "./_services/fonction.service";
import { InformationDelactPlantationService } from "./_services/information-delact-plantation.service";
import { InfoFormationActeurPersService } from "./_services/info-formations-acteur-pers.service";
import { InformationDelimitationActeurPlantationService } from "./_services/information-delimitation-acteur-plantation.service";
import { InformationEnfantActeurPersService } from "./_services/information-enfant-acteur-pers.service";
import { InformationProductionActeurPlantationService } from "./_services/information-production-acteur-plantation.service";
import { InformationNotationActeurPlantationService } from "./_services/information-notation-acteur-plantation.service";
import { OrganisationActeurPersService } from "./_services/organisation-acteur-pers.service";
import { InformationsEntretienActeurPlantationService } from "./_services/informations-entretien-acteur-plantation.service";
import { PaysService } from "./_services/pays.service";
import { ParcsService } from "./_services/parcs.service";
import { PermissionService } from "./_services/permission.service";
import { PeriodeTrackingService } from "./_services/periode-tracking.service";
import { CategorieProfileService } from "./_services/profile-categorie.service";
import { ProfileService } from "./_services/profile.service";
import { TypeDeCoucheService } from "./_services/type-couche.service";
import { VarieteParCultureService } from "./_services/variete-par-culture.service";
import { RoleService } from "./_services/role.service";
import { TypeAttributService } from "./_services/type-attribut.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { RegionService } from "./_services/region.service";
import { UserService } from "./_services/user.service";
import { SettingsService } from "./_services/settings.service";
import { ZoneActionService } from "./_services/zone.action.service";
import { StatisitiquesService } from "./_services/statisitiques.service";
import { TrackItemService } from "./_services/track-item.service";
import { TrackService } from "./_services/track.service";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { A11yModule } from "@angular/cdk/a11y";
import { NavWegeoComponent } from "./@wegeo/nav-wegeo/nav-wegeo.component";
import { NavComponent } from "./@drone/nav/nav.component";
import { PlantationLightItemComponent } from "./@drone/plantation-light-item/plantation-light-item.component";
import { ListHeadBarComponent } from "./@drone/list-head-bar/list-head-bar.component";
import { GeojsonComponent } from "./@drone/geojson/geojson.component";
import { DorneSidebarComponent } from "./@drone/dorne-sidebar/dorne-sidebar.component";
import { GestionParcelleComponent } from "./@drone/gestion-parcelle/gestion-parcelle.component";
import { LecteurVrComponent } from "./@drone/lecteur-vr/lecteur-vr.component";
import { LecteurVideoComponent } from "./@drone/lecteur-video/lecteur-video.component";
import { RapportComponent } from "./@drone/rapport/rapport.component";
import { CalendarComponent } from "./@drone/calendar/calendar.component";
import { NgbModule, NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { InlineSVGModule } from "ng-inline-svg";
import { FlatpickrModule } from "angularx-flatpickr";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { FormStockComponent } from "./@marketplace/_modals/form-stock/form-stock.component";
import { ArticleItemComponent } from "./@marketplace/article-item/article-item.component";
import { ArticleListeComponent } from "./@marketplace/article-liste/article-liste.component";
import { MySidebarComponent } from "./@marketplace/mysidebar/mysidebar.component";
import { NgxIndexedDBModule } from "ngx-indexed-db";
import { environment } from "src/environments/environment";
import { SuggestionComponent } from "./_modale/suggestion/suggestion.component";
import { ForgetPasswordComponent } from "./forget-password/forget-password.component";
import { ArticleMapComponent } from "./@marketplace/article-map/article-map.component";
import { MarketDashComponent } from "./@marketplace/market-dash/market-dash.component";
import { MainComponent } from "./@marketplace/main/main.component";
import { FamilleCultureComponent } from "./famille-culture/famille-culture.component";
import { VarieteCultureComponent } from "./variete-culture/variete-culture.component";
import { CultureComponent } from "./culture/culture.component";
import { ConfirmComponent } from "./confirm/confirm.component";
import { OffreVenteComponent } from "./@marketplace/_modals/offre-vente/offre-vente.component";
import { DemandeProductionComponent } from "./@marketplace/_modals/demande-production/demande-production.component";
import { UniteMesureComponent } from "./unite-mesure/unite-mesure.component";
import { SuccessMsgComponent } from "./_modale/success-msg/success-msg.component";
import { ErrorMsgComponent } from "./_modale/error-msg/error-msg.component";
import { ReponseOffreComponent } from "./@marketplace/reponse-offre/reponse-offre.component";
import { ReponseOffreListComponent } from "./@marketplace/reponse-offre-list/reponse-offre-list.component";
import { DemandeDeProductionItemComponent } from "./@marketplace/demande-de-production-item/demande-de-production-item.component";
import localeFr from "@angular/common/locales/fr";
registerLocaleData(localeFr);
import { DemandeDeProductionListComponent } from "./@marketplace/demande-de-production-list/demande-de-production-list.component";
import { OffreVenteListComponent } from "./@marketplace/offre-vente-list/offre-vente-list.component";
import { PropositionDemandeComponent } from "./@marketplace/_modals/proposition-demande/proposition-demande.component";
import { StoreModule } from "@ngrx/store";
import * as formsReducers from "./_store/reducers/forms.reducers";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { PerformStatComponent } from "./@wegeo/employe-details/perform-stat/perform-stat.component";
import { ExcelFieldToDowloadComponent } from "./excel-field-to-dowload/excel-field-to-dowload.component";
import { JwtInterceptorService } from "./login/jwt-interceptor.service";
import { SharedModule } from "./shared/shared.module";
import { SpinnerComponent } from "src/ui/spinner/spinner.component";
import { VillageComponent } from "./village/village.component";
import { CampementComponent } from "./campement/campement.component";
import { EmployePulseComponent } from "./@wegeo/employe-pulse/employe-pulse.component";
import { ConfirmeUserRegisterComponent } from "./confirme-user-register/confirme-user-register.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { AlertDialogComponent } from "./alert-dialog/alert-dialog.component";
import { SelectInputComponent } from "src/ui/select-input/select-input.component";
import { FileInputComponent } from "src/ui/file-input/file-input.component";
import { NewEmployeDetailComponent } from "./@wegeo/new-employe-detail/new-employe-detail.component";
import { NewAlertConfirmComponent } from "src/ui/new-alert-confirm/new-alert-confirm.component";



const dbConfig = {
  name: "wegeo",
  version: 2,
  objectStoresMeta: [
    {
      store: "parcs",
      storeConfig: { keyPath: "locale_id", autoIncrement: true },
      storeSchema: [
        // { name: 'name', keypath: 'name', options: { unique: false } },
      ],
    },
    {
      store: "plantation_legale",
      storeConfig: { keyPath: "locale_id", autoIncrement: true },
      storeSchema: [
        { name: "user", keypath: "user", options: { unique: false } },
        { name: "profil", keypath: "profil", options: { unique: false } },
        {
          name: "entreprise",
          keypath: "entreprise",
          options: { unique: false },
        },
      ],
    },
    {
      store: "plantation_illegale",
      storeConfig: { keyPath: "locale_id", autoIncrement: true },
      storeSchema: [
        { name: "user", keypath: "user", options: { unique: false } },
        { name: "profil", keypath: "profil", options: { unique: false } },
        {
          name: "entreprise",
          keypath: "entreprise",
          options: { unique: false },
        },
      ],
    },
  ],
};

@NgModule({
  declarations: [
    AppComponent,
    TesteurComponent,
    ConfirmeUserRegisterComponent,
    ResetPasswordComponent,
    IndexComponent,
    InscriptionComponent,
    NavbarComponent,
    TestDeServComponent,
    ImgLoaderComponent,
    FonctionnaliteEntrepriseComponent,
    PageDashboardComponent,
    NavbarComponent,
    AccessDeniedComponent,
    AlertDialogComponent,
    NewAlertConfirmComponent,

    //wegeo
    DatePipePipe,
    ConvertBooleanPipe,
    AddZeroPipe,
    PeriodeTrackingUserComponent,
    HomeWegeo,
    FiltreComponent,
    PagineFilterPipe,
    DataFilterPipe,
    LibFilterPipe,
    LibZoneFilterPipe,
    PlanteurPipe, 
    PlantationPipe,
    EmployePipe,
    
    ZeroInjectorPipe,
    RoleUserComponent,
    DeleteActeurComponent,
    GSearchComponent,
    DynamicFormComponent,
    PaginationComponent,
    RoleComponent,
    ProfileComponent,
    PermissionComponent,
    FonctionComponent,
    ListusersComponent,
    PaginationsComponent,
    SidebarComponent,
    AppComponent,
    NavWegeoComponent,
    CooperativeDashboardComponent,
    UserComponent,
    UserNavComponent,
    GestionEmployeComponent,
    EmployeComponent,
    EmployeDetailsComponent,
    LeafletSampleComponent,
    PaginationsComponent,
    GestionPlantationComponent,
    EmployeDetailsComponent,
    TrackingComponent,
    PerformanceComponent,
    FormationComponent,
    WegeoAdminComponent,
    FormsComponent,
    EntrepriseComponent,
    AttributComponent,
    CompartimentFormComponent,
    TypeAttributComponent,
    FormcoucheComponent,
    CoucheComponent,
    TypeCoucheComponent,
    PlanteurDetailComponent,
    StatCardComponent,
    DynamicInputComponent,
    FormbuilderComponent,
    PieComponent,
    TraficComponent,
    WeatherComponent,
    MapDashComponent,
    AccueilComponent,
    PrincipalMenuComponent,
    ProfileCategorieComponent,
    PeriodeTrackingComponent,
    FonctionaliteComponent,
    PaysComponent,
    PlanteurComponent,
    EditFormComponent,
    ActeursComponent,
    WgZoneComponent,
    ZoneComponent,
    PlantationsComponent,
    DeletePlantationsComponent,
    PaginationControlsComponent,
    EditProfilComponent,
    ActiveOrDesactiveUserComponent,
    ActiveOrDesactiveTrackingUserComponent,
    ButtonComponent,
    StatCardComponent,
    PuceComponent,
    LoginComponent,
    ModalComponent,
    ListSimpleComponent,
    EnteteListComponent,
    InputComponent,
    SelectInputComponent,
    AllComponent,
    PeriodeTrackingUserComponent,
    FilterComponent,
    DetailActeurComponent,
    LoadingComponent,
    DeletedComponent,
    ResetPasswordEmployeComponent,
    EmployeFormComponent,
    LayerSwitchBtnComponent,
    PerformStatComponent,
    ExcelFieldToDowloadComponent,
    SpinnerComponent,
    WeatherDayComponent,
    HebDoweatherItemComponent,
    EmployePulseComponent,
    // drone
    NavComponent,
    HomeDrone,
    PlantationLightItemComponent,
    ListHeadBarComponent,
    DorneSidebarComponent,
    GeojsonComponent,
    GestionParcelleComponent,
    LecteurVideoComponent,
    LecteurVrComponent,
    RapportComponent,
    CalendarComponent,

    //market
    ArticleItemComponent,
    ArticleListeComponent,
    MySidebarComponent,
    HomeMarket,
    FormStockComponent,
    SuggestionComponent,
    ForgetPasswordComponent,
    ArticleMapComponent,
    MarketDashComponent,
    MainComponent,
    FamilleCultureComponent,
    VarieteCultureComponent,
    CultureComponent,
    ConfirmComponent,
    OffreVenteComponent,
    DemandeProductionComponent,
    UniteMesureComponent,
    OffreVenteListComponent,
    DemandeDeProductionListComponent,
    SuccessMsgComponent,
    ErrorMsgComponent,
    ReponseOffreComponent,
    ReponseOffreListComponent,
    DemandeDeProductionItemComponent,
    PropositionDemandeComponent,
    VillageComponent,
    CampementComponent,
    FileInputComponent,
    NewEmployeDetailComponent
  ],
  imports: [
    HttpLinkModule,
    ApolloModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    MatIconModule,
    MatProgressBarModule,
    MatMenuModule,
    MatTabsModule,
    MatBottomSheetModule,
    MatDividerModule,
    MatSliderModule,
    MatRadioModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSortModule,
    MatTableModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatListModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    MatExpansionModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    NgxEchartsModule,
    MatStepperModule,
    NgxPaginationModule,
    HttpClientModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatBottomSheetModule,
    MatDatepickerModule,
    MatInputModule,
    MatDividerModule,
    MatSliderModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSelectModule,
    MatListModule,
    MatSlideToggleModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    NgxPaginationModule,

    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatInputModule,
    BrowserModule,
    // PdfViewerModule,
    InlineSVGModule.forRoot(),
    AppRoutingModule,
    CommonModule,

    NgbModule,
    NgbModalModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    StoreModule.forRoot({ forms: formsReducers.reducer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    SharedModule,
  ],
  entryComponents: [
    // DeletedComponent
    PropositionDemandeComponent,
    OffreVenteComponent,
    DemandeProductionComponent,
    ConfirmComponent,
    DeletedComponent,
    HomeWegeo,
    RoleUserComponent,
    DeleteActeurComponent,
    PeriodeTrackingUserComponent,
    DeletePlantationsComponent,
    EditProfilComponent,
    ActiveOrDesactiveTrackingUserComponent,
    ActiveOrDesactiveUserComponent,
    LoadingComponent,
    ResetPasswordEmployeComponent,
    EmployeFormComponent,
    SuggestionComponent,
    FormStockComponent,
    ArticleItemComponent,
    SuccessMsgComponent,
    ErrorMsgComponent,
    ReponseOffreComponent,
    ExcelFieldToDowloadComponent,
    AlertDialogComponent,
    NewEmployeDetailComponent,
    NewAlertConfirmComponent
  ],
  exports: [
      ExcelFieldToDowloadComponent,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatDividerModule,
    MatSliderModule,
    MatTabsModule,
    MatBottomSheetModule,
    MatDividerModule,
    MatSliderModule,
    MatSelectModule,
    MatRadioModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatIconModule,
    MatProgressBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSortModule,
    MatTableModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    InfiniteScrollModule,
    MatExpansionModule,
    NewAlertConfirmComponent
  ],
  providers: [
    MatDialogModule,
    HttpClient,
    CookieService,
    UtilsService,
    ActeurAttributValeurService,
    ActeurService,
    NewActeurService,
    AdresseService,
    AttributFormulaireService,
    AttributOptionsService,
    AttributService,
    CompartimentService,
    ConnexionService,
    CoucheService,
    CultureService,
    EntrepriseService,
    FamilleDeCultureService,
    FonctionService,
    FonctionaliteService,
    FormCoucheService,
    InfoFormationActeurPersService,
    InformationDelactPlantationService,
    InformationDelimitationActeurPlantationService,
    InformationEnfantActeurPersService,
    InformationNotationActeurPlantationService,
    InformationProductionActeurPlantationService,
    InformationsEntretienActeurPlantationService,
    OrganisationActeurPersService,
    ParcsService,
    PaysService,
    PeriodeTrackingService,
    PermissionService,
    ProfileService,
    CategorieProfileService,
    RegionService,
    RoleService,
    SettingsService,
    ZoneActionService,
    UserService,
    TypeDeCoucheService,
    TrackItemService,
    StatisitiquesService,
    VarieteParCultureService,
    TypeAttributService,
    TrackService,
    DeviceDetectorService,
    CookieService,
    ExcelFieldToDowloadComponent,
    { provide: LOCALE_ID, useValue: "fr-FR" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    AuthgardService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(apollo : Apollo, httpLink: HttpLink){
    const http = httpLink.create({uri: environment.url})
    const cache = new InMemoryCache();

    const apolloClient = new ApolloClient({
      link:http,
      cache
    });
    apollo.setClient(apolloClient);

  }
}
