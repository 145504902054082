import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { UserService } from 'src/app/_services/user.service';
import { UtilsService } from 'src/app/_services/utils.service';

export interface DialogData {
  user : any;
}


@Component({
  selector: 'app-active-or-desactive-user',
  templateUrl: './active-or-desactive-user.component.html',
  styleUrls: ['./active-or-desactive-user.component.scss']
})
export class ActiveOrDesactiveUserComponent implements OnInit {

  user : any = null;

  constructor(
    public dialogRef: MatDialogRef<ActiveOrDesactiveUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService : UserService,
    private snackBar: MatSnackBar,
    private utils : UtilsService
  ) { }

  onNoClick(result : boolean = false): void {
    this.dialogRef.close(result);
  }

  ngOnInit() {
    this.user = this.data.user;
  }


  ok(){
    let active = ! this.user.isActive;
    this.userService.activeOrDesactiveUser(this.user.id, active).then(
      (res) => {

        if(this.user.isActive){
          this.snackBar.open('User desactivé', 'Ok', { duration: 3000 });
        }else{
          this.snackBar.open('User activé', 'Ok', { duration: 3000 });
        }
        this.user.isActive = active
        let profileDetail = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
        if(profileDetail.id == this.user.id){
          
          this.utils.setCookie('_wefly_', this.utils.encrypt(JSON.stringify(this.user)), { nb: 7, unite: 3 });
        }
        this.onNoClick(true)
      },
      (error) => {
        this.onNoClick(false)
        console.error(error);
      }
    )
  }

}
