import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';

@Component({
  selector: 'app-planteur-detail',
  templateUrl: './planteur-detail.component.html',
  styleUrls: ['./planteur-detail.component.scss']
})
export class PlanteurDetailComponent implements OnInit {
  toggle = false;
  menu = this.utils.NAVIGATION;
  constructor(public utils: UtilsService) { }

  ngOnInit() {
  }
}
