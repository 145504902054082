import { Injectable } from '@angular/core';
import { ResolveEnd } from '@angular/router';
import { UtilsService } from './utils.service';
const createCampementRequest = `
mutation($libelle:String){
  createSubdivision7(newSubdivision7:{
    libelle:$libelle
  }){
    ok
    subdivision7{
    id
    libelle
    }
  }
}
`;
const updateCampementRequest = `
mutation($id:UUID,$libelle:String){
  updateSubdivision7(newSubdivision7:{
    id:$id
    libelle:$libelle
  }){
    ok
    subdivision7{
      libelle
      id
    }
  }
}
`;
const createVillageRequest = `
mutation($libelle:String){
  createSubdivision6(newSubdivision6:{
    libelle:$libelle
  }){
    ok
    subdivision6{
    id
    libelle
    }
  }
}
`;
const updateVillageRequest = `
mutation($id:UUID,$libelle:String){
  updateSubdivision6(newSubdivision6:{
    id:$id
    libelle:$libelle
  }){
    ok
    subdivision6{
      libelle
      id
    }
  }
}
`;

@Injectable({
  providedIn: 'root'
})
export class SubdivisionService {
  

  constructor(private utils: UtilsService) { }

  subDivision1(){
    const query = `
    query{
      searchSubdivision1{
        results{
          libelle
          id
        }
      }
    }
    `;
    return new Promise((resolve, reject)=>{
      this.utils.client.request(query).then(
        (res)=>{
          resolve(res);
        }, (err) => {
          reject(err);
        }
      )
    })
  }

  subDivision2(){
    const query = `
    query{
      searchSubdivision2{
        results{
          libelle
          id
        }
      }
    }
    `;
    return new Promise((resolve, reject)=>{
      this.utils.client.request(query).then(
        (res)=>{
          resolve(res);
        }, (err) => {
          reject(err);
        }
      )
    })
  }

  subDivision3(){
    const query = `
    query{
      searchSubdivision3{
        results{
          libelle
          id
        }
      }
    }
    `;
    return new Promise((resolve, reject)=>{
      this.utils.client.request(query).then(
        (res)=>{
          resolve(res);
        }, (err) => {
          reject(err);
        }
      )
    })
  }



  searchCampement(niveau: number,libelle_Icontains: String){
    const query = `
    query($niveau:Int,$libelle_Icontains:String){
      searchSubdivision7(deleted:false,libelle_Icontains:$libelle_Icontains){
        totalCount
        results(page:$niveau){
          libelle
          id
        }
      }
      
    }
    `;
    return new Promise((resolve, reject)=>{
      this.utils.client.request(query,{niveau, libelle_Icontains}).then(
        (res)=>{
          resolve(res)
        }, (err) => {
          reject(err)
        }
      )
      
    })
}

searchVillage(niveau:number,libelle_Icontains: String){
  const query = `
  query($niveau:Int,$libelle_Icontains:String){
    searchSubdivision6(deleted:false, libelle_Icontains:$libelle_Icontains){
      totalCount
      results(page:$niveau){
        libelle
        id
      }
    }
    
  }
  `;
  return new Promise((resolve, reject)=>{
    this.utils.client.request(query,{niveau, libelle_Icontains}).then(
      (res)=>{
        resolve(res)
      }, (err) => {
        reject(err)
      }
    )
    
  })

}
deleteVillage(id: string){
  const query = `
  mutation($id:UUID){
    updateSubdivision6(newSubdivision6:{
      id:$id
      deleted:true
    }){
      ok
      subdivision6{
        libelle
        id
        deleted
      }
    }
  }
  `;
  return new Promise((resolve, reject)=>{
    this.utils.client.request(query,{
      id
    }).then(
      (res)=>{
        resolve(res);
    }, (err) => {
      reject(err);
    }
    )
  })
}

deleteCampement(id: string){
  const query = `
  mutation($id:UUID){
    updateSubdivision7(newSubdivision7:{
      id:$id
      deleted:true
    }){
      ok
      subdivision7{
        libelle
        id
        deleted
      }
    }
  }
  `;
  return new Promise((resolve, reject)=>{
    this.utils.client.request(query,{
      id
    }).then(
      (res)=>{
        resolve(res);
    }, (err) => {
      reject(err);
    }
    )
  })
}






createCampement(id:string,libelle:string) {
  const query = id ? updateCampementRequest : createCampementRequest
  return new Promise((resolve, reject)=>{
    this.utils.client.request(query,{
      id,
      libelle
    }).then(
      (res)=>{
        resolve(res)
      }, (err) => {
        reject(err)
      }
    )
  })
}

createVillage(id:string,libelle:string){
  const query = id ? updateVillageRequest : createVillageRequest;
  return new Promise((resolve, reject) =>{
    this.utils.client.request(query,{
      id,
      libelle
    }).then(
      (res)=>{
        resolve(res)
      }, (err) => {
        reject(err)
      }
    )
  })
}

}
