import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import gql from 'graphql-tag';
import { Apollo, QueryRef } from 'apollo-angular';
import { Page } from '../_models/user';

const READ_PAYS = gql`
query ($limit: Int! , $offset: Int!){
  searchPays(deleted:false){
    results(offset: $offset,limit:$limit,ordering: "name") {
      id
       name
    }
    totalCount
  }
}
`;

@Injectable({
  providedIn: 'root'
})
export class PaysService {

  constructor(private utils: UtilsService, private apollo:Apollo) { }
  create(name: String, pop2005: number=1, subregion: number=0, un: number=0) {
    const query = `
    mutation(
		$pop2005: Int!,
		$subregion: Int!,
		$un: Int!,
		$name: String,

	) {
      createPays(newPays: {
			pop2005: $pop2005,
			subregion: $subregion,
			un: $un,
			name: $name,

		}) {
        pays {
          id
					pop2005,
					subregion,
					un,
					name,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {pop2005,
					subregion,
					un,
					name,
					}).then(
          (res) => {
            if (res['createPays']['ok']) {
              resolve(res['createPays']['pays'])
            }
            reject(res['createPays']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, name: String, deleted: Boolean=false, pop2005: number=1, subregion: number=0, un: number=0) {
    const query = `
    mutation(
			$id: UUID!,
		$pop2005: Int!,
		$subregion: Int!,
		$un: Int!,
		$name: String,
    $deleted: Boolean
		) {
      updatePays(newPays: {
				id: $id, pop2005: $pop2005,
			subregion: $subregion,
			un: $un,
      name: $name,
      deleted: $deleted
			}) {
        pays {
          id
					pop2005,
					subregion,
					un,
					name,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, pop2005,
					subregion,
					un,
          name,
          deleted
					}).then(
          (res) => {
            if (res['updatePays']['ok']) {
              resolve(res['updatePays']['pays'])
            }
            reject(res['updatePays']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
  readPays(page:Page){
    const {limit, offset} = page
    return this.apollo.watchQuery<any>({
      query:READ_PAYS,
      variables:{
        limit, offset
      }
    })as QueryRef<any>
  }

  getPays(page:Page){
    return new Promise((resolve, reject)=>{
      this.readPays(page).valueChanges.subscribe(
        (res:any)=>{
          resolve(res)
        },(err:any)=>{
          reject(err)
        }
      )
    })
  }

  read(filter: string = `deleted : false`, offset: number = 0, limit: number = 1000, order: string = '-created_at') {
    const query = `
    query($limit: Int! , $offset: Int!, $order: String!) {
      searchPays(${filter}){
        results(limit: $limit, offset: $offset, ordering: $order) {
          id
         	name
        }
        totalCount
      }
    }
    `;

    
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { limit, offset, order }).then(
          (res:any) => {
            resolve(res.searchPays);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

