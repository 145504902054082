import { FormState, initialFormState } from './../states/forms.state';
import * as formActions from '../actions/form.actions';
import { Action, createReducer, on } from '@ngrx/store';

const formsReducer = createReducer(
    initialFormState,
    on(formActions.setForm, (state, {mforms} ) => {
        return { ...state, cpForms: mforms};
        }
    ),
    
);

export function reducer(state: FormState | undefined, action: Action) {
    return formsReducer(state, action);
}
