import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { UtilsService } from "src/app/_services/utils.service";
import "bootstrap";
import "jquery";
import { ActeurService } from "src/app/_services/acteurs.service";
import { CoucheService } from "src/app/_services/couche.service";
import { UserService } from "src/app/_services/user.service";
import { EntrepriseService } from "src/app/_services/entreprise.service";
import { MatDialog } from "@angular/material";
import { LoadingComponent } from "../_modale/loading/loading.component";
import { error } from "console";
declare var $;
declare var L: any;

@Component({
  selector: "app-gestion-plantation",
  templateUrl: "./gestion-plantation.component.html",
  styleUrls: ["./gestion-plantation.component.scss"],
})
export class GestionPlantationComponent implements OnInit {
  page: any;
  viewMap: boolean = false;
  profiles = [];
  showlist = true;
  acteursCouche: any[] = [];
  userConnected: any = {};
  entreprise: any;
  userCurrent: any;
  entrepriseFilter: any;
  current:string = 'planteur'
  loading:boolean = false

  constructor(
    public utils: UtilsService,
    private acteurServ: ActeurService,
    private coucheServ: CoucheService,
    private userServ: UserService,
    public router: ActivatedRoute,
    public mrouter: Router,
    private entrepriseService: EntrepriseService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.userConnected = JSON.parse(
      this.utils.decrypt(this.utils.getCookie("_wefly_"))
    );
    this.entreprise = this.userConnected.profile.entreprise.id;
    this.readCouche();
    // this.readActeur();
  }
  goToPage(n: number): void {
    this.page = n;
    // this.getFonctions(n);
  }

  onNext(): void {
    this.page++;
    // this.getFonctions(this.page);
  }

  onPrev(): void {
    this.page--;
    // this.getFonctions(this.page);
  }

  goToMap(go: boolean = false) {
    this.viewMap = go;
  }

  loadMap() {
    setTimeout(() => {
      (function (L, document) {
        var map = L.map("map", {
          center: [29.749817, -95.080757],
          zoom: 16,
          measureControl: true,
        });

        L.tileLayer(
          "//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          {
            minZoom: 14,
            maxZoom: 18,
            //attribution:"&copy; Esri &mdash; Sources: Esri, DigitalGlobe, Earthstar Geographics, CNES/Airbus DS, GeoEye, USDA FSA, USGS, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community",
          }
        ).addTo(map);

        map.on("measurefinish", function (evt) {
          writeResults(evt);
        });

        function writeResults(results) {
          document.getElementById("eventoutput").innerHTML = JSON.stringify(
            {
              area: results.area,
              areaDisplay: results.areaDisplay,
              lastCoord: results.lastCoord,
              length: results.length,
              lengthDisplay: results.lengthDisplay,
              pointCount: results.pointCount,
              points: results.points,
            },
            null,
            2
          );
        }

        var editableLayers = new L.FeatureGroup();

        // Initialise the draw control and pass it the FeatureGroup of editable layers
        var drawControl = new L.Control.Draw({
          position: "topright",
          draw: {
            polygon: {
              allowIntersection: false, // Restricts shapes to simple polygons
              drawError: {
                color: "#e1e100", // Color the shape will turn when intersects
                message: "<strong>Oh snap!<strong> you can't draw that!", // Message that will show when intersect
              },
              shapeOptions: {
                color: "#97009c",
              },
            },
            // disable toolbar item by setting it to false
            polyline: true,
            circle: true, // Turns off this drawing tool
            rectangle: true,
            marker: true,
          },
          edit: {
            featureGroup: editableLayers, //REQUIRED!!
            remove: true,
          },
        });
        map.addControl(drawControl);

        map.addLayer(editableLayers);

        map.on("draw:created", function (e) {
          var type = e.layerType,
            layer = e.layer;

          if (type === "marker") {
            layer.bindPopup("A popup!");
          }

          editableLayers.addLayer(layer);
        });
      })(L, window.document);
    }, 2000);
  }

  Switch() {
    this.showlist = !this.showlist;
  }

  /* private readActeur(couche: any, index: number): any {
    let filter = '';
    if (this.entrepriseFilter) {
      filter = `couche_Entreprise_Id: "${this.entrepriseFilter}",
      couche_TypeCouche_Libelle: "${couche.typeCouche.libelle}",
      deleted : false,`;
    } else if (this.userConnected.profile.entreprise.categorie.numero === 75) {
      filter = `couche_Entreprise_EntrepriseMere_EntrepriseMere_Id:
      "${this.userConnected.profile.entreprise.id}", couche_TypeCouche_Libelle: "${couche.typeCouche.libelle}",
       deleted : false `;
    } else if (this.userConnected.profile.entreprise.categorie.numero === 73) {
      filter = `couche_Entreprise_EntrepriseMere_Id: "${this.userConnected.profile.entreprise.id}",
      couche_TypeCouche_Libelle: "${couche.typeCouche.libelle}",
      deleted : false,`;
    } else {
      filter = `couche: "${couche.id}", deleted : false`;
    }
    this.acteurServ.read(filter).then(
      (res: any) => {
        this.acteursCouche.push({ acteurs: res, couche: couche, img: [] });
      }, (err) => {
      }
    );
  } */

  readCouche() {
    this.loading = true
    this.coucheServ
      .read(`entreprise: "${this.entreprise}", principal : false`)
      .then(
        (res: any) => {
          this.loading = false
          this.acteursCouche = res.results.reverse();
          console.log('couches', res)
          /* res.results.forEach((elt, i) => {
          this.readActeur(elt, i);
        }); */
        },
        (err) => {
          this.loading = false
        }
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }

  couche(id: string): string {
    let couche = this.acteursCouche.find((element) => element.id == id);
    return couche.libelle;
  }


  download(data:any, user =this.userConnected){
    console.log('user',user)
    let couche = data.couche;
    let type = data.type;


    this.openLoadingDialog({
      msg: "Veuillez patienter s'il vous plaît.",
      loader: true,
    });
    if (!user.profile.zone) {
    
      console.info("Vous n'avez pas de zone");
      this.closeLoadingDialog();
      return;
    }
    let zoneId = user.profile.zone.id;

    if (
      user.profile.entreprise.categorie.etiquette == "COOPERATIVE" ||
      user.profile.entreprise.categorie.etiquette == "PLANTEUR" ||
      user.profile.entreprise.categorie.etiquette == "ACHETEUR_VENDEUR" ||
      user.profile.entreprise.categorie.etiquette == "UNION"

    ) {

      switch(type){
        case "excel":
          console.log('execel data',data)
          const field = [...data.res.keys()] 
          console.log('execel data',field.toString())
          const attribut = field.toString()
          
          this.userServ.createZoneFile("",this.userConnected.profile.user.id, couche, zoneId, false, false, false, true,false,attribut).then((data:any)=>{
            console.log("create excel", data)
            this.closeLoadingDialog();
            if(data.createZoneFiles.zonefiles.filepath !== null){
               window.open(this.utils.API_URL + data.createZoneFiles.zonefiles.filepath,'_self')
            }else{
              return
            }
          },(error:any)=>{
            console.log('erreorceate excel', error) 
          })
          break;
        
        case "kml":
        
        this.userServ.createZoneFile("",this.userConnected.profile.user.id,couche, zoneId, true, false, false, false,false,'').then((data:any)=>{
            console.log("create kml", data)
            this.closeLoadingDialog();
            if(data.createZoneFiles.zonefiles.filepath !== null){
              window.open(this.utils.API_URL + data.createZoneFiles.zonefiles.filepath,'_self')
             
            }else{
              return
            }
          },(error:any)=>{
            console.log('erreorceate kml', error) 
          })
          break;
        
        case "gpx":

          this.userServ.createZoneFile("",this.userConnected.profile.user.id,couche, zoneId, false, true, false, false,false,'').then((data:any)=>{
            console.log('create gpx', data)
            this.closeLoadingDialog();
            if(data.createZoneFiles.zonefiles.filepath !== null && data.createZoneFiles.zonefiles.filepath !== ""){
              window.open(this.utils.API_URL + data.createZoneFiles.zonefiles.filepath,'_self')
              
            }else{
              return
            }
            })
          break;
        
        case "shp":
     
          this.userServ.createZoneFile("",this.userConnected.profile.user.id,couche, zoneId, false, false, true, false,false,'').then((data:any)=>{
              console.log('create shp', data)
              this.closeLoadingDialog();
              if(data.createZoneFiles.zonefiles.filepath !== null){
                window.open(this.utils.API_URL + data.createZoneFiles.zonefiles.filepath,'_self')
              }else{
                return
              }
          })
          break;

      }

    

    }


  }





    download1(data, user = this.userConnected) {
 
    console.log('user',user)
    
    let couche = data.couche;
    let type = data.type;


    this.openLoadingDialog({
      msg: "Veuillez patienter s'il vous plaît.",
      loader: true,
    });
    if (!user.profile.zone) {
    
      console.info("Vous n'avez pas de zone");
      this.closeLoadingDialog();
      return;
    }
    let zoneId = user.profile.zone.id;
    if (
      user.profile.entreprise.categorie.numero == 72 ||
      user.profile.entreprise.categorie.numero == 71 ||
      user.profile.entreprise.categorie.numero == 70
    ) {


      if(type === "excel"){

        this.userServ.createZoneFile("",this.userConnected.profile.user.id,couche, zoneId, true, false, false, false,false,'').then((data:any)=>{
          console.log('create zone file', data)
          if (data.createZoneFiles.ok) {
     
            let url = "";
            url = this.utils.API_URL + data.createZoneFiles.zonefiles.excelfile;
            if (url == this.utils.API_URL) {
                
            } else {
              console.log('link',url)
        
             // window.location.href = url;
               
            }
            this.closeLoadingDialog();
            return
          }else{}


        }, (error) => {
            console.error(error);
          }
        )
        .catch((reason) => this.utils.setErrorsHttp(reason));

      }else if(type === "kml"){
        

      }

    } else {
 
    
      this.entrepriseService.read(`deleted : false, idMere : "${user.profile.entreprise.id}"`).then(
          (res: any) => {
            console.log('data entreprise', res)
            res.searchEntreprises.results.filter((value, index) => {
              this.coucheServ.read(`entreprise: "${value.id}", principal : false, libelle_Icontains : "${this.couche(couche)}"`).then(
                  (couches: any) => {
                    console.log('couche',couche);
                    if (type === "excel") {
                      couches.results.filter((value, index) => {
                        this.userServ.createZoneFile("",this.userConnected.profile.user.id,value.id, zoneId, true, false, false, false,false,'').then((data:any)=>{
                          console.log('create zone',data);
                          if (data.createZoneFile["ok"]) {
                            let url = "";
                            url = this.utils.API_URL + data.createZoneFile.zonefiles.excelfile;
                            if (url == this.utils.API_URL) {
                               
                            } else {
                           
                              console.log('link',url)
                           
                            }
                            this.closeLoadingDialog();
                            return;
                          }
                         
                        }, (error)=>{
                          console.log('error create zone',error);
                          console.error(error);
                          this.closeLoadingDialog();
  
                        })
                        .catch((reason) => this.utils.setErrorsHttp(reason));

                      });
                      this.closeLoadingDialog();

                    }else{
                      return
                    }

                  },
                  (error) => {
                  console.log('erreur read couche',error );
                  
                    console.error(error);
                    this.closeLoadingDialog();
                  }
                )
                .catch((reason) => this.utils.setErrorsHttp(reason));
            });
          },
          (error) => {
            console.log(' erre entreprise', error)
          }
        )
        .catch((reason) => this.utils.setErrorsHttp(reason));
    }
  }






















  // download(data, user = this.userConnected) {
  //   alert('this.download')
    
  //   let couche = data.couche;
  //   let type = data.type;


  //   this.openLoadingDialog({
  //     msg: "Veuillez patienter s'il vous plaît.",
  //     loader: true,
  //   });
  //   if (!user.profile.zone) {
    
  //     console.info("Vous n'avez pas de zone");
  //     this.closeLoadingDialog();
  //     return;
  //   }
  //   let zoneId = user.profile.zone.id;
  //   if (
  //     user.profile.entreprise.categorie.numero == 72 ||
  //     user.profile.entreprise.categorie.numero == 71 ||
  //     user.profile.entreprise.categorie.numero == 70
  //   ) {
     
  //     this.userServ.createZoneFile(couche, zoneId).then(
  //         (data: any) => {
  //           console.log('create zone file', data)
  //           if (data.createZoneFiles.ok) {
      
  //             let url = "";
  //             if (type === "excel") {
  //               url =
  //                 this.utils.API_URL + data.createZoneFiles.zonefiles.excelfile;
               
  //             } else {

  //               url = this.utils.API_URL + data.createZoneFiles.zonefiles.kmzfile;
                  
  //             }
  //             if (url == this.utils.API_URL) {
                
  //             } else {
  //               window.location.href = url;
                 
  //             }
  //             this.closeLoadingDialog();
  //             return;
  //           }else{
             
  //           }
           
  //         },
  //         (error) => {
  //           console.error(error);
  //         }
  //       )
  //       .catch((reason) => this.utils.setErrorsHttp(reason));
  //   } else {
    
  //     this.entrepriseService.read(`deleted : false, idMere : "${user.profile.entreprise.id}"`).then(
  //         (res: any) => {
  //           console.log('data entreprise', res)
  //           res.searchEntreprises.results.filter((value, index) => {
  //             this.coucheServ.read(`entreprise: "${value.id}", principal : false, libelle_Icontains : "${this.couche(couche)}"`).then(
  //                 (couches: any) => {
  //                   console.log('couche',couche);
                    
  //                   couches.results.filter((value, index) => {
  //                     this.userServ
  //                       .createZoneFile(value.id, zoneId)
  //                       .then(
  //                         (data: any) => {
  //                           console.log('create zone',data);
                            
  //                           if (data.createZoneFile["ok"]) {
  //                             let url = "";
  //                             if (type === "excel") {
  //                               url =
  //                                 this.utils.API_URL +
  //                                 data.createZoneFile.zonefiles.excelfile;
  //                             } else {
  //                               url =
  //                                 this.utils.API_URL +
  //                                 data.createZoneFile.zonefiles.kmzfile;
  //                             }
  //                             if (url == this.utils.API_URL) {
                             
  //                             } else {
  //                               // alert(url)
  //                               window.location.href = url;
  //                             }
  //                             this.closeLoadingDialog();
  //                             return;
  //                           }
  //                           console.error("EROROROR");
  //                         },
  //                         (error) => {
  //                           console.log('error create zone',error);
                            
  //                           console.error(error);
  //                           this.closeLoadingDialog();
  //                         }
  //                       )
  //                       .catch((reason) => this.utils.setErrorsHttp(reason));
  //                   });
  //                   this.closeLoadingDialog();
  //                 },
  //                 (error) => {
  //                 console.log('erreur read couche',error );
                  
  //                   console.error(error);
  //                   this.closeLoadingDialog();
  //                 }
  //               )
  //               .catch((reason) => this.utils.setErrorsHttp(reason));
  //           });
  //         },
  //         (error) => {
  //           console.log(' erre entreprise', error)
  //         }
  //       )
  //       .catch((reason) => this.utils.setErrorsHttp(reason));
     
  //   }
  // }

  public gotsearchValue(e): any {}

  makeFilter(filter) {
    this.acteursCouche = [];
    this.entrepriseFilter = filter.id;
    this.readCouche();
  }

  public openLoadingDialog(d = null, id = "loaderTrackingItem"): void {
    const dialogRef = this.dialog.open(LoadingComponent, {
      width: "500px",
      id: id,
      data: d,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  public closeLoadingDialog(id = "loaderTrackingItem"): void {
    try {
      this.dialog.getDialogById(id).close();
    } catch (error) {}
  }

  getCurrent(current:string){
    this.current = current

  }
}
