import { Component, OnInit } from "@angular/core";
import { ZoneActionService } from "../_services/zone.action.service";
import { UtilsService } from "../_services/utils.service";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material";
import { NewAlertConfirmComponent } from "src/ui/new-alert-confirm/new-alert-confirm.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";




@Component({
  selector: "app-zone",
  templateUrl: "./zone.component.html",
  styleUrls: ["./zone.component.scss"],
})
export class ZoneComponent implements OnInit {
  hidelist:boolean = true
  Zones = [];
  mzone = {
    id: "",
    libelle: "",
    zoneDelim: "",
    deleted: false,
    entreprise: { id: "" },
    zoneGlobale: false,
  };
  entrePriseId: any;
  update:boolean = false;
  filter: string | any = null;

  // Param filter
  fields_selected_: Array<any> = [
    { libelle_db: "libelle_Icontains", libelle: "Libelle" },
  ];

  // filter: any = {
  //   field: null,
  //   value: null
  // }
  stateLibelle = false;
  stateZoneDelim = false;

  loading: boolean = true;
  isHide:boolean = false
  alertInput:boolean = false

  // Pagin param
  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;
  zoneForm:FormGroup


  constructor(private zone: ZoneActionService,
     private utils: UtilsService, 
     private dialog:MatDialog,
     private fb:FormBuilder) {
      this.zoneForm = this.fb.group({
        'libelle':['', Validators.required]
      })

     }

  submitted: any = null;
  userInfo: any;

  ngOnInit() {
    this.userInfo = JSON.parse(
      this.utils.decrypt(this.utils.getCookie("_wefly_"))
    );
   

    this.getZone();
  }
  // checkLibelle(newValue) {
  //   // console.log("checkLibelle", this.mzone.libelle);
  //   newValue !== null && newValue !== undefined && newValue !== ""
  //     ? (this.stateLibelle = false)
  //     : "";
  // }

  getZone(page: number = 1, filter_field: any = null) {
    return new Promise((resolve, reject)=>{
      this.loading = true;
      let filter = `entreprise: "${this.userInfo.profile.entreprise.id}", deleted: false`;
      
      if (filter_field != null && filter_field.field != null) {
        filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`;
      }
      this.zone.read(filter, page).then(
        (zonesres: any) => {
          console.log('zones',zonesres)
          resolve(zonesres)
        
  
          this.loading = false;
          this.Zones = zonesres.searchZoneAction.results;
          this.totalCount = zonesres.searchZoneAction.totalCount;
        },
        (err) => {
          this.loading = false;
        }
      );

    })
    

  }
  create(f) {
    this.mzone.libelle = null;
  }

  getpolygon(data:any) {
   

    data = data.features[0];
    JSON.stringify(data);
    this.mzone.zoneDelim = JSON.stringify(data);
  }
  getPolygoneMarker(data:any){
     
     data = data.features[0];
     JSON.stringify(data);
     this.mzone.zoneDelim = JSON.stringify(data);
  }

  updateZone(formData:FormGroup){
    this.zone.update(
      this.mzone.id,
      this.mzone.deleted,
      this.mzone.entreprise.id,
      formData.value.libelle,
      this.mzone.zoneDelim,
      this.mzone.zoneGlobale
    )

    .then(
      (res) => {
        
        this.utils.snack("Zone modifiée avec succès !");
        this.getZone();
        formData.reset();
        this.isHide = false
      },
      (err) => {}
    );
    this.update = false;
  }

  createZone(formData:FormGroup){
    if (formData.valid ) {
      console.log(this.mzone.zoneDelim)
      if (this.mzone.zoneDelim !== "" && this.mzone.zoneDelim !== null && this.mzone.zoneDelim !== undefined ) {
       
        this.stateZoneDelim = false;
        this.zone.create(false, this.userInfo.profile.entreprise.id, formData.value.libelle, this.mzone.zoneDelim, false).then(
            (res:any) => {
            
              this.Zones.unshift(res.createZoneAction.zoneaction)
              this.getZone().then((res:any)=>{
              this.Zones = res.searchZoneAction.results});;
              formData.reset();
              this.utils.snack("Zone crée avec succès !");
              this.isHide = false
            },

            (err) => {
              
            }
          );
      } else {
        this.stateZoneDelim = true;
      }
    } else {
      this.stateLibelle = true;
    }

  }

  onSubmit(formData:FormGroup){
    if(this.stateZoneDelim || this.alertInput){
      this.stateZoneDelim = false
      this.alertInput = false
    }
   
    if(formData.valid){
      console.log('fomrm',formData)
      if (!this.update){
        this.createZone(formData)
     }else{
  
       this.updateZone(formData)
     }
    }else{
      this.alertInput = true
    }
  }


  edit(zone:any) {
    console.log(zone)
    this.update = true;
   //this.stateLibelle = false;
    this.mzone = zone;
    this.isHide = true
    this.zoneForm = this.fb.group({
      "libelle":[zone.libelle, Validators.required]
    })
  }

  delete(zone:any) {
   const dialogRef  =  this.dialog.open(NewAlertConfirmComponent,{
      width:'32rem',
      data:{title:'Suppression de zone',message:`Vous êtes sur le point de supprimer la zone '' ${zone.libelle} '' . Voulez-vous continuer ?`}
    })

    dialogRef.afterClosed().subscribe((res:any)=>{
      if(res === true){
        this.mzone = zone;
        this.zone.update(
          this.mzone.id,
          true,
          this.mzone.entreprise.id,
          this.mzone.libelle,
          this.mzone.zoneDelim,
          this.mzone.zoneGlobale
        )
        .then(
          (res:any) => {
            this.Zones.forEach((zone:any, index:number)=>{
              if(zone.id === res.updateZoneAction.zoneaction.id){
                  this.Zones.splice(index,1)
                  this.utils.snack("Zone modifiée avec succès !");
              }
            })
          },
          (err) => {}
        );

      }
    })
   
  }

  onChangePage(data) {
    this.currentPage = data.id;
    this.getZone(data.id);
  }

  filterData(value: string) {
    this.filter = value;
  }
  addZone(){
    this.isHide = true

  }
  hideZone(){
    this.isHide = false
    this.zoneForm.reset()
    this.alertInput = false
    this.stateZoneDelim = false
  }

  showDash( ){
    this.hidelist = !this.hidelist

  }
  
}
