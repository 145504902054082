import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class InformationQualiteDeVieActeurPersService {

  constructor(private utils: UtilsService) { }

  create(acteur: String, deleted: Boolean, distanceMaisonEcole: String, distanceMaisonHospis: String, identMobile: String, moyenDeDeplacement: String, natureDuLogement: String, natureDuLogementAutre: String, natureToitDuLogement: String, nbrePiecesMenage: Number, sourceDEau: String, sourceDEclairage: String, statutOccupationLogement: String, user: String, ) {
	const dateCreationMobile = new Date()
    const query = `
    mutation(
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$deleted: Boolean,
		$distanceMaisonEcole: String,
		$distanceMaisonHospis: String,
		$identMobile: String,
		$moyenDeDeplacement: String,
		$natureDuLogement: String,
		$natureDuLogementAutre: String,
		$natureToitDuLogement: String,
		$nbrePiecesMenage: Int,
		$sourceDEau: String,
		$sourceDEclairage: String,
		$statutOccupationLogement: String,
		$user: ID,

	) {
      createInformationQualiteDeVieActeurPers(newInformationqualitedevieacteurpers: {
			dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			deleted: $deleted,
			distanceMaisonEcole: $distanceMaisonEcole,
			distanceMaisonHospis: $distanceMaisonHospis,
			identMobile: $identMobile,
			moyenDeDeplacement: $moyenDeDeplacement,
			natureDuLogement: $natureDuLogement,
			natureDuLogementAutre: $natureDuLogementAutre,
			natureToitDuLogement: $natureToitDuLogement,
			nbrePiecesMenage: $nbrePiecesMenage,
			sourceDEau: $sourceDEau,
			sourceDEclairage: $sourceDEclairage,
			statutOccupationLogement: $statutOccupationLogement,
			user: $user,

		}) {
        informationqualitedevieacteurpers {
          id
					dateCreationMobile,
					acteur{ id },
					deleted,
					distanceMaisonEcole,
					distanceMaisonHospis,
					identMobile,
					moyenDeDeplacement,
					natureDuLogement,
					natureDuLogementAutre,
					natureToitDuLogement,
					nbrePiecesMenage,
					sourceDEau,
					sourceDEclairage,
					statutOccupationLogement,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {dateCreationMobile,
					acteur,
					deleted,
					distanceMaisonEcole,
					distanceMaisonHospis,
					identMobile,
					moyenDeDeplacement,
					natureDuLogement,
					natureDuLogementAutre,
					natureToitDuLogement,
					nbrePiecesMenage,
					sourceDEau,
					sourceDEclairage,
					statutOccupationLogement,
					user,
					}).then(
          (res) => {
            if (res['createInformationQualiteDeVieActeurPers']['ok']) {
              resolve(res['createInformationQualiteDeVieActeurPers']['informationqualitedevieacteurpers'])
            }
            reject(res['createInformationQualiteDeVieActeurPers']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, dateCreationMobile: String, acteur: String, deleted: Boolean, distanceMaisonEcole: String, distanceMaisonHospis: String, identMobile: String, moyenDeDeplacement: String, natureDuLogement: String, natureDuLogementAutre: String, natureToitDuLogement: String, nbrePiecesMenage: Number, sourceDEau: String, sourceDEclairage: String, statutOccupationLogement: String, user: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$deleted: Boolean,
		$distanceMaisonEcole: String,
		$distanceMaisonHospis: String,
		$identMobile: String,
		$moyenDeDeplacement: String,
		$natureDuLogement: String,
		$natureDuLogementAutre: String,
		$natureToitDuLogement: String,
		$nbrePiecesMenage: Int,
		$sourceDEau: String,
		$sourceDEclairage: String,
		$statutOccupationLogement: String,
		$user: ID,
		) {
      updateInformationQualiteDeVieActeurPers(newInformationqualitedevieacteurpers: {
				id: $id, dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			deleted: $deleted,
			distanceMaisonEcole: $distanceMaisonEcole,
			distanceMaisonHospis: $distanceMaisonHospis,
			identMobile: $identMobile,
			moyenDeDeplacement: $moyenDeDeplacement,
			natureDuLogement: $natureDuLogement,
			natureDuLogementAutre: $natureDuLogementAutre,
			natureToitDuLogement: $natureToitDuLogement,
			nbrePiecesMenage: $nbrePiecesMenage,
			sourceDEau: $sourceDEau,
			sourceDEclairage: $sourceDEclairage,
			statutOccupationLogement: $statutOccupationLogement,
			user: $user,
			}) {
        informationqualitedevieacteurpers {
          id
					dateCreationMobile,
					acteur{ id },
					deleted,
					distanceMaisonEcole,
					distanceMaisonHospis,
					identMobile,
					moyenDeDeplacement,
					natureDuLogement,
					natureDuLogementAutre,
					natureToitDuLogement,
					nbrePiecesMenage,
					sourceDEau,
					sourceDEclairage,
					statutOccupationLogement,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, dateCreationMobile,
					acteur,
					deleted,
					distanceMaisonEcole,
					distanceMaisonHospis,
					identMobile,
					moyenDeDeplacement,
					natureDuLogement,
					natureDuLogementAutre,
					natureToitDuLogement,
					nbrePiecesMenage,
					sourceDEau,
					sourceDEclairage,
					statutOccupationLogement,
					user,
					}).then(
          (res) => {
            if (res['updateInformationQualiteDeVieActeurPers']['ok']) {
              resolve(res['updateInformationQualiteDeVieActeurPers']['informationqualitedevieacteurpers'])
            }
            reject(res['updateInformationQualiteDeVieActeurPers']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchInformationQualiteDeVieActeurPers (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					dateCreationMobile,
					acteur{ id },
					deleted,
					distanceMaisonEcole,
					distanceMaisonHospis,
					identMobile,
					moyenDeDeplacement,
					natureDuLogement,
					natureDuLogementAutre,
					natureToitDuLogement,
					nbrePiecesMenage,
					sourceDEau,
					sourceDEclairage,
					statutOccupationLogement,
					user{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

