import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-article-liste',
  templateUrl: './article-liste.component.html',
  styleUrls: ['./article-liste.component.css']
})
export class ArticleListeComponent implements OnInit {
  @Input() datas: any = [];
  constructor() { }

  ngOnInit() {
  }

}
