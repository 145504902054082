import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class InformationsEntretienActeurPlantationService {

  constructor(private utils: UtilsService) { }

  create(achetePar: String, acteur: String, coutAngrais: Number, deleted: Boolean, freqTraitementPhyto: String, identMobile: String, lieuAchatAngrais: String, nomOrgAchat: String, periodeTraitementPhyto: String, qteAngrais: Number, traitementPhyto: String, typeAngrais: String, user: number, utilisationAngrais: Boolean, ) {
	const dateCreationMobile = new Date();
	const query = `
    mutation(
		$dateCreationMobile: CustomDateTime!,
		$achetePar: String,
		$acteur: ID,
		$coutAngrais: Int,
		$deleted: Boolean,
		$freqTraitementPhyto: String,
		$identMobile: String,
		$lieuAchatAngrais: String,
		$nomOrgAchat: String,
		$periodeTraitementPhyto: String,
		$qteAngrais: Int,
		$traitementPhyto: String,
		$typeAngrais: String,
		$user: ID,
		$utilisationAngrais: String,

	) {
      createInformationsEntretienActeurPlantation(newInformationsentretienacteurplantation: {
			dateCreationMobile: $dateCreationMobile,
			achetePar: $achetePar,
			acteur: $acteur,
			coutAngrais: $coutAngrais,
			deleted: $deleted,
			freqTraitementPhyto: $freqTraitementPhyto,
			identMobile: $identMobile,
			lieuAchatAngrais: $lieuAchatAngrais,
			nomOrgAchat: $nomOrgAchat,
			periodeTraitementPhyto: $periodeTraitementPhyto,
			qteAngrais: $qteAngrais,
			traitementPhyto: $traitementPhyto,
			typeAngrais: $typeAngrais,
			user: $user,
			utilisationAngrais: $utilisationAngrais,

		}) {
        informationsentretienacteurplantation {
          	id
			dateCreationMobile,
			achetePar,
			acteur{ id },
			coutAngrais,
			deleted,
			freqTraitementPhyto,
			identMobile,
			lieuAchatAngrais,
			nomOrgAchat,
			periodeTraitementPhyto,
			qteAngrais,
			traitementPhyto,
			typeAngrais,
			user{ id },
			utilisationAngrais,
			createdAt
			updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {dateCreationMobile,
					achetePar,
					acteur,
					coutAngrais,
					deleted,
					freqTraitementPhyto,
					identMobile,
					lieuAchatAngrais,
					nomOrgAchat,
					periodeTraitementPhyto,
					qteAngrais,
					traitementPhyto,
					typeAngrais,
					user,
					utilisationAngrais,
					}).then(
          (res) => {
            if (res['createInformationsEntretienActeurPlantation']['ok']) {
              resolve(res['createInformationsEntretienActeurPlantation']['informationsentretienacteurplantation'])
            }
            reject(res['createInformationsEntretienActeurPlantation']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, dateCreationMobile: String, achetePar: String, acteur: String, coutAngrais: Number, deleted: Boolean, freqTraitementPhyto: String, identMobile: String, lieuAchatAngrais: String, nomOrgAchat: String, periodeTraitementPhyto: String, qteAngrais: Number, traitementPhyto: Boolean, typeAngrais: String, user: String, utilisationAngrais: Boolean, ) {
    const query = `
    mutation(
			$id: UUID!,
		$dateCreationMobile: CustomDateTime!,
		$achetePar: String,
		$acteur: ID,
		$coutAngrais: Int,
		$deleted: Boolean,
		$freqTraitementPhyto: String,
		$identMobile: String,
		$lieuAchatAngrais: String,
		$nomOrgAchat: String,
		$periodeTraitementPhyto: String,
		$qteAngrais: Int,
		$traitementPhyto: Boolean,
		$typeAngrais: String,
		$user: ID,
		$utilisationAngrais: Boolean,
		) {
      updateInformationsEntretienActeurPlantation(newInformationsentretienacteurplantation: {
				id: $id, dateCreationMobile: $dateCreationMobile,
			achetePar: $achetePar,
			acteur: $acteur,
			coutAngrais: $coutAngrais,
			deleted: $deleted,
			freqTraitementPhyto: $freqTraitementPhyto,
			identMobile: $identMobile,
			lieuAchatAngrais: $lieuAchatAngrais,
			nomOrgAchat: $nomOrgAchat,
			periodeTraitementPhyto: $periodeTraitementPhyto,
			qteAngrais: $qteAngrais,
			traitementPhyto: $traitementPhyto,
			typeAngrais: $typeAngrais,
			user: $user,
			utilisationAngrais: $utilisationAngrais,
			}) {
        informationsentretienacteurplantation {
          id
					dateCreationMobile,
					achetePar,
					acteur{ id },
					coutAngrais,
					deleted,
					freqTraitementPhyto,
					identMobile,
					lieuAchatAngrais,
					nomOrgAchat,
					periodeTraitementPhyto,
					qteAngrais,
					traitementPhyto,
					typeAngrais,
					user{ id },
					utilisationAngrais,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, dateCreationMobile,
					achetePar,
					acteur,
					coutAngrais,
					deleted,
					freqTraitementPhyto,
					identMobile,
					lieuAchatAngrais,
					nomOrgAchat,
					periodeTraitementPhyto,
					qteAngrais,
					traitementPhyto,
					typeAngrais,
					user,
					utilisationAngrais,
					}).then(
          (res) => {
            if (res['updateInformationsEntretienActeurPlantation']['ok']) {
              resolve(res['updateInformationsEntretienActeurPlantation']['informationsentretienacteurplantation'])
            }
            reject(res['updateInformationsEntretienActeurPlantation']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchInformationsEntretienActeurPlantation (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					dateCreationMobile,
					achetePar,
					acteur{ id },
					coutAngrais,
					deleted,
					freqTraitementPhyto,
					identMobile,
					lieuAchatAngrais,
					nomOrgAchat,
					periodeTraitementPhyto,
					qteAngrais,
					traitementPhyto,
					typeAngrais,
					user{ id },
					utilisationAngrais,
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

