import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { Apollo, QueryRef} from 'apollo-angular';
import gql from 'graphql-tag';


const READ_CATEGORIE = gql`
query {
  searchCategorieProfile(deleted:false){
    totalCount
    results(ordering: "-created_at")
    {
    id,
    deleted,
    description,
    libelle,
    numero,
    etiquette
  }
  }
}

`;

@Injectable({
  providedIn: 'root'
})
export class CategorieProfileService {

  constructor(private utils: UtilsService, private apollo:Apollo) { }
  create(
    deleted: Boolean,
    description: String,
    libelle: String,
    numero: Number,

  ) {
    const query = `
    mutation(
			$deleted:  Boolean!,
			$description:  String!,
			$libelle:  String!,
			$numero:  Int!,
    ) {
      createCategorieProfile(newCategorieprofile: {
				deleted: $deleted,
				description: $description,
				libelle: $libelle,
				numero: $numero,
      }) {
				categorieprofile{
          id
          libelle
          description
        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          deleted,
          description,
          libelle,
          numero,
        }).then(
          (res) => {
            resolve(res);
            if(res['createCategorieProfile']['ok']){
              resolve(res['createCategorieProfile']['categorieprofile'])
            }
            reject(res['createCategorieProfile']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
  update(
    id: String,
    deleted: Boolean,
    description: String,
    libelle: String,
    numero: Number,
  ) {
    const query = `
    mutation(
      $id: UUID!
			$deleted:  Boolean!,
			$description:  String!,
			$libelle:  String!,
			$numero:  Int!,
    ) {
      updateCategorieProfile(newCategorieprofile: {
        id: $id,
				deleted: $deleted,
				description: $description,
				libelle: $libelle,
				numero: $numero,
      }) {
				categorieprofile{
          id
          libelle
          description
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id,
          deleted,
          description,
          libelle,
          numero,
        }).then(
          (res) => {
            resolve(res);
            if(res['updateCategorieProfile']['ok']){
              resolve(res['updateCategorieProfile']['categorieprofile'])
            }
            reject(res['updateCategorieProfile']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  readCategorie(){
    return this.apollo.watchQuery<any>({
      query:READ_CATEGORIE
    }) as QueryRef<any>
  }
  getCategorie(){
    return new Promise((resolve, reject)=>{
      this.readCategorie().valueChanges.subscribe(
        (res:any)=>{
          console.log("categorie", res)
          resolve(res)
        },(err:any)=>{
          console.log('erreur categorie', err);
          reject(err)
        }
      )
    })
  }
  read(filter: string = `deleted: false`, page: number = 1, order: string = '-created_at') {
    const query = `query($page: Int!, $order: String) {
      searchCategorieProfile(${filter}){
        totalCount
        results(page: $page, ordering: $order)
        {
        id,
        deleted,
				description,
				libelle,
        numero,
       
      }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { page: page, order: order }).then(
          (res) => {
            console.log('catt', res)
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }


  // return new Promise(
  //   (resolve, reject) => {
  //     this.utils.client.request(query, { limit, offset, order }).then(
  //       (res:any) => {
  //         resolve(res.searchPays);
  //       }, (err) => {
  //         this.utils.setErrorsHttp(err)
  //         reject(err);
  //       }
  //     );
  //   }
  // );
}

