import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { CompartimentService } from "../_services/compartiment.service";
import { TypeDeCoucheService } from "../_services/type-couche.service";
import { UtilsService } from "../_services/utils.service";
import { CoucheService } from "../_services/couche.service";
import { FormCoucheService } from "../_services/form-couche.service";
import { UserService } from "../_services/user.service";
import { Router } from "@angular/router";
import { AttributService } from "../_services/attribut.service";
import { AttributOptionsService } from "../_services/attribut-options.service";
import { HttpClient } from "@angular/common/http";
import axios from "axios";

@Component({
  selector: "app-dynamic-form",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.css"],
})
export class DynamicFormComponent implements OnInit {
  @Output() stateValue = new EventEmitter();
  state = false;

  allCompartiment: any[] = [];
  typeCouches: any = [];
  Couches: any = [];
  userEntreprise: any;
  dataCouche;

  load = false;
  constructor(
    private compartimentServ: CompartimentService,
    private service: TypeDeCoucheService,
    private utils: UtilsService,
    private coucheserv: CoucheService,
    private formCouche: FormCoucheService,
    private user: UserService,
    private router: Router,
    private attributService: AttributService,
    private optinsv: AttributOptionsService,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
  
    this.userEntreprise = JSON.parse(this.utils.decrypt(this.utils.getCookie("_wefly_")));
    
  
    // this.stateValue.emit(this.state);
    this.compartimentServ
      .read()
      .then(
        (res: any) => {
          // this.allCompartiment = res.searchCompartiemnt.data;
          // console.table(this.allCompartiment)
        },
        (err) => {}
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));

    this.getTypeCouche();
  }

  getTypeCouche() {
    this.service.read().then(
        (res: any) => {
          console.log('typecouche', res)
          this.load = true;
          this.typeCouches = res.searchTypeDeCouche.results == null? [] : res.searchTypeDeCouche.results;
          this.getCouche();
          console.log('typecouche', this.typeCouches)
        },
        (err) => {}
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }
  getCouche() {
    this.coucheserv
      .read(
        `entreprise: "${this.userEntreprise.profile.entreprise.id}", principal: false`
      )
      .then(
        (mres: any) => {
          console.log('readcouche', mres)
          if (mres.results.length > 0) {
            // On recherche les formulaires par couche
            // console.log("mres", mres.results);
            mres.results.forEach((element) => {
              this.formCouche
                .read(`couche: "${element.id}"`)
                .then(
                  async (fres: any) => {

                     console.log("readCoucheEl", fres);
                    // On recherche le type couches correspondants au formulaire dans les données types Couches foramtées
                    // console.log("this.typeCouches.", this.typeCouches);
                    //
                    // console.log("typecouchesss", this.typeCouches);
                    this.typeCouches.forEach(async (typecouche) => {
                      // console.log("typecouche", typecouche);
                      if (element.typeCouche.id === typecouche.id) {
                        console.log('element compare', element.typeCouche.id + '========' + typecouche.id)

                        if (fres.searchFormCouche.results) {
                          // Si le formulaire existe on l'insère dans les données type couche formatées
                          if (fres.searchFormCouche.results.length > 0) {
                            // Pour chaque formCouche on recherche les attributs forulaires
                            typecouche.formCouche =
                              fres.searchFormCouche.results[0];

                            // fres.searchFormCouche.results.forEach(async (dataJson) => {
                            if (
                              typecouche.formCouche.formschemajsonUrl !== null
                            ) {
                              // console.log("formCouche", typecouche.formCouche);
                              const datas =
                                typecouche.formCouche.formschemajsonUrl;
                              let res = await axios.get(
                                this.utils.API_HOST + "media/" + datas
                              );
                               //console.log(" res.data", res.data);
                              res.data !== null && res.data !== undefined
                                ? (this.dataCouche = res.data)
                                : [];
                              //
                              typecouche.formCouche.compartiments =
                                this.dataCouche;
                              // console.log(
                              //   "this.dataCouche",
                              //   typecouche.formCouche.compartiments
                              // );
                            }else{
                              typecouche.formCouche.compartiments = [];
                            }
                            this.load = false;
                            this.state = true;
                            this.stateValue.emit(this.state);
                            // console.log(" this.dataCouche", this.dataCouche);
                            // });
                            // this.httpClient.get(formschemajsonUrl).subscribe(console.log);
                          } else {
                            // Sinon on le crée puis on l'insère dans le type couche
                            this.formCouche
                              .create(
                                element.id,
                                false,
                                `Formulaire `,
                                `Formulaire ${typecouche.libelle}`
                              )
                              .then(
                                (res: any) => {
                                  window.location.reload();
                                  typecouche.formCouche = res.createFormCouche;
                                  console.log('res ss', res)
                                },
                                (err) => {}
                              )
                              .catch((reason) =>
                                this.utils.setErrorsHttp(reason)
                              );
                          }
                        } else {
                          this.formCouche
                            .create(
                              element.id,
                              false,
                              `Formulaire `,
                              `Formulaire ${typecouche.libelle}`
                            )
                            .then(
                              (res: any) => {
                                typecouche.formCouche = res.createFormCouche;
                                window.location.reload();
                              },
                              (err) => {}
                            )
                            .catch((reason) =>
                              this.utils.setErrorsHttp(reason)
                            );
                        }
                      }
                    });
                  },
                  (err) => {}
                )
                .catch((reason) => this.utils.setErrorsHttp(reason));
            });
          } else {
            if (confirm("Veuiller créer des couches avant de continuer")) {
              this.router.navigate(["couche"]);
            }
          }
        },
        (err) => {}
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }
}