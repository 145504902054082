import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
	providedIn: 'root'
})
export class InfoFormationActeurPersService {

	constructor(private utils: UtilsService) { }

	create(acteur: String, ameliorationTravail: String, certification: String, dateCertif: String, deleted: Boolean, identMobile: String, nivDEtude: String, nombreDeSessionFormation: Number, nonScolarise: String, participationFormation: String, preuveCertifImage: String, raisonInutile: String, user: String, ) {
		const dateCreationMobile = new Date();
		const query = `
    mutation(
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$ameliorationTravail: String,
		$certification: String,
		$dateCertif: CustomDateTime,
		$deleted: Boolean,
		$identMobile: String,
		$nivDEtude: String,
		$nombreDeSessionFormation: Int,
		$nonScolarise: String,
		$participationFormation: String,
		$preuveCertifImage: String,
		$raisonInutile: String,
		$user: ID,

	) {
      createInfoFormationActeurPers(newInfoformationacteurpers: {
			dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			ameliorationTravail: $ameliorationTravail,
			certification: $certification,
			dateCertif: $dateCertif,
			deleted: $deleted,
			identMobile: $identMobile,
			nivDEtude: $nivDEtude,
			nombreDeSessionFormation: $nombreDeSessionFormation,
			nonScolarise: $nonScolarise,
			participationFormation: $participationFormation,
			preuveCertifImage: $preuveCertifImage,
			raisonInutile: $raisonInutile,
			user: $user,

		}) {
        infoformationacteurpers {
          id
					dateCreationMobile,
					acteur {
            id
          },
					ameliorationTravail,
					certification,
					dateCertif,
					deleted,
					identMobile,
					nivDEtude,
					nombreDeSessionFormation,
					nonScolarise,
					participationFormation,
					preuveCertifImage,
					raisonInutile,
					user {
            id
          },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query, {
					dateCreationMobile,
					acteur,
					ameliorationTravail,
					certification,
					dateCertif,
					deleted,
					identMobile,
					nivDEtude,
					nombreDeSessionFormation,
					nonScolarise,
					participationFormation,
					preuveCertifImage,
					raisonInutile,
					user,
				}).then(
					(res) => {
						if (res['createInfoFormationActeurPers']['ok']) {
							resolve(res['createInfoFormationActeurPers']['infoformationacteurpers'])
						}
						reject(res['createInfoFormationActeurPers']['errors']);
					}, (err) => {
						this.utils.setErrorsHttp(err)
						reject(err);
					}
				);
			}
		);
	}

	update(id: String, dateCreationMobile: String, acteur: String, ameliorationTravail: Boolean, certification: Boolean, dateCertif: String, deleted: Boolean, identMobile: String, nivDEtude: String, nombreDeSessionFormation: Number, nonScolarise: Boolean, participationFormation: Boolean, preuveCertifImage: String, raisonInutile: String, user: String, ) {
		const query = `
    mutation(
			$id: UUID!,
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$ameliorationTravail: Boolean,
		$certification: Boolean,
		$dateCertif: CustomDateTime,
		$deleted: Boolean,
		$identMobile: String,
		$nivDEtude: String,
		$nombreDeSessionFormation: Int,
		$nonScolarise: Boolean,
		$participationFormation: Boolean,
		$preuveCertifImage: String,
		$raisonInutile: String,
		$user: ID,
		) {
      updateInfoFormationActeurPers(newInfoformationacteurpers: {
				id: $id, dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			ameliorationTravail: $ameliorationTravail,
			certification: $certification,
			dateCertif: $dateCertif,
			deleted: $deleted,
			identMobile: $identMobile,
			nivDEtude: $nivDEtude,
			nombreDeSessionFormation: $nombreDeSessionFormation,
			nonScolarise: $nonScolarise,
			participationFormation: $participationFormation,
			preuveCertifImage: $preuveCertifImage,
			raisonInutile: $raisonInutile,
			user: $user,
			}) {
        infoformationacteurpers {
          id
					dateCreationMobile,
					acteur {
            id
          },
					ameliorationTravail,
					certification,
					dateCertif,
					deleted,
					identMobile,
					nivDEtude,
					nombreDeSessionFormation,
					nonScolarise,
					participationFormation,
					preuveCertifImage,
					raisonInutile,
					user {
            id
          },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query, {
					id, dateCreationMobile,
					acteur,
					ameliorationTravail,
					certification,
					dateCertif,
					deleted,
					identMobile,
					nivDEtude,
					nombreDeSessionFormation,
					nonScolarise,
					participationFormation,
					preuveCertifImage,
					raisonInutile,
					user,
				}).then(
					(res) => {
						if (res['updateInfoFormationActeurPers']['ok']) {
							resolve(res['updateInfoFormationActeurPers']['infoformationacteurpers'])
						}
						reject(res['updateInfoFormationActeurPers']['errors']);
					}, (err) => {
						this.utils.setErrorsHttp(err)
						reject(err);
					}
				);
			}
		);
	}

	read(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
		filter = (filter == null || filter == '') ? 'deleted: false' : filter;
		const query = `
    query {
      searchInfoFormationActeurPers (${filter}){
        results(page:` + page + `, ordering:` + order + `) {
          id
					dateCreationMobile,
					acteur {
            id
          },
					ameliorationTravail,
					certification,
					dateCertif,
					deleted,
					identMobile,
					nivDEtude,
					nombreDeSessionFormation,
					nonScolarise,
					participationFormation,
					preuveCertifImage,
					raisonInutile,
					user {
            id
          },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query).then(
					(res) => {
						resolve(res);
					}, (err) => {
						this.utils.setErrorsHttp(err)
						reject(err);
					}
				);
			}
		);
	}
}

