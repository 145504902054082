import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {
  @Output() uploadedFile: EventEmitter<any> = new EventEmitter<any>();
  @Input() files: any;

  constructor() { }

  ngOnInit() {
  }

  getFile(event: any) {
    const clonedFiles = [...event.target.files];
    this.uploadedFile.emit(clonedFiles)
     this.files = event.target.files;
    // this.store.dispatch(setFilesUploaded({files: clonedFiles}))
 } 

}
