import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertBoolean'
})
export class ConvertBooleanPipe implements PipeTransform {

  transform(value: any): any {
    if(typeof value == 'string' && (value == 'false' || value == 'true')){
      if(value == 'true'){
        value = 'OUI'
      }else{
        value = 'NON'
      }
    }
    return value;
  }

}
