import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plantation'
})
export class PlantationPipe implements PipeTransform {

  transform(value: any[], args: string){
    if (!value) return null
    if(!args) return value
    if(typeof args !== 'string') return value


    args = args.toLowerCase();

    return value.filter((value: any) => {
    if(value.acteurGen && value.acteurGen[0]){
      const acteurGen = value.acteurGen[0];
      return acteurGen.codePlantation.toLowerCase().includes(args)
    }
    return false
  })
  }

}
