import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActeurService } from 'src/app/_services/acteurs.service';
import { UtilsService } from 'src/app/_services/utils.service';

export interface DialogData {
  acteur : string;
}

@Component({
  selector: 'app-delete-acteur',
  templateUrl: './delete-acteur.component.html',
  styleUrls: ['./delete-acteur.component.css']
})
export class DeleteActeurComponent implements OnInit {

  constructor(
    private utils: UtilsService,
    private acteurService : ActeurService,
    public dialogRef: MatDialogRef<DeleteActeurComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    console.log(this.data.acteur);
    
  }

  okSupprimer(){
    this.acteurService.deleted(this.data.acteur).then(
      (res : any) => {
      },
      (error) => {
        console.error(error)
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
