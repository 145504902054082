import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/_services/utils.service';

@Component({
  selector: 'app-wegeo-admin',
  templateUrl: './wegeo-admin.component.html',
  styleUrls: ['./wegeo-admin.component.scss']
})
export class WegeoAdminComponent implements OnInit {

  userInfo: any;
  menu = [];

  currentLib:string
  isrouterfonction:boolean = false
  isrouterperiode:boolean = false

  constructor(public U: UtilsService, private router:Router) { }

  ngOnInit() {
  
    console.log('router', this.router.url)
    this.U.setStorage('currentUrl', this.router.url)

    this.userInfo = JSON.parse(this.U.decrypt(this.U.getCookie('_wefly_')));
    if (this.userInfo.isStaff && this.userInfo.isStaff == true) {
      this.menu = [
        { link: '/wegeo/admin/role', name: 'Rôles' },
        { link: '/wegeo/admin/permission', name: 'Permissions' },
        { link: '/wegeo/admin/fonction', name: 'Fonctions' },
        { link: '/wegeo/admin/entreprise', name: 'Entreprises' },
        { link: '/wegeo/admin/type_attribut', name: 'Type attributs' },
        { link: '/wegeo/admin/attribut', name: 'Attributs' },
        { link: '/wegeo/admin/compartiment', name: 'Compartiments' },
        { link: '/wegeo/admin/couche', name: 'Couches' },
        { link: '/wegeo/admin/type_couche', name: 'Types Couches' },
        { link: '/wegeo/admin/form_couche', name: 'Formulaires Couches' },
        { link: '/wegeo/admin/categorie_profile', name: 'Categories Profiles'},
        { link: '/wegeo/admin/periode_traking', name: 'Périodes Tracking' },
        // { link: '/wegeo/admin/village',name:'Village'},
        // {link: '/wegeo/admin/campement',name:'Campement'},
        { link: '/wegeo/admin/pays', name: 'Pays' },
      ];
    } else {
      this.menu = [
        { link: '/wegeo/admin/periode_traking', name: 'Périodes Tracking' },
        { link: '/wegeo/admin/fonction', name: 'Fonctions' },
        
        // { link:'/wegeo/admin/village', name: 'Village' },
        // { link:'/wegeo/admin/campement', name: 'Campement' },
      ];
    }
  }

  switch(libelle:string){
    this.currentLib = libelle

    if(this.currentLib === 'fonction'){
      this.U.setStorage('currentUrl', '/wegeo/admin/fonction')
      this.router.navigateByUrl('/wegeo/admin/fonction')
      
    }else{
      this.U.setStorage('currentUrl', '/wegeo/admin/periode_traking')
      this.router.navigateByUrl('/wegeo/admin/periode_traking')
      

    }
  }
  getUrl(){
    return this.U.getStorage('currentUrl')
  }

}
