import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UtilsService } from "src/app/_services/utils.service";
import { UtilsService as wegeoUtil } from "src/app/@wegeo/_services/utils.service";
import { GeneratePdfService } from "../_services/generate-pdf.service";
import { DeleteActeurComponent } from "src/app/_modale/delete-acteur/delete-acteur.component";
import { ActeurService } from "src/app/_services/acteurs.service";
import { DateRange } from "src/app/shared/interval-date-filter/interval-date-filter.component";
import { ExcelFieldToDowloadComponent } from "src/app/excel-field-to-dowload/excel-field-to-dowload.component";
import { GetExcelService } from "src/app/_services/get-excel.service";
import { Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import { AppState } from "src/app/_store/app.state";
import { selectForm } from "src/app/_store/selectors/form.selectors";
import { NewActeurService } from "src/app/_services/newActeurs.service";
import { Router } from "@angular/router";
import { NewAlertConfirmComponent } from "src/ui/new-alert-confirm/new-alert-confirm.component";
import { DataCacheService } from "src/app/_services/data-cache.service";
import { UserService } from "src/app/_services/user.service";
import { LoadingComponent } from "../_modale/loading/loading.component";

@Component({
  selector: "app-plantations",
  templateUrl: "./plantations.component.html",
  styleUrls: ["./plantations.component.scss"],
  providers: [ExcelFieldToDowloadComponent],
})
export class PlantationsComponent implements OnInit {
  isSelected:boolean = true
  isSelectedDec:boolean = true
  total_plantation_online: number = 0;
  online_db: boolean = false;
  activeSearch:boolean = false

  plantations: any[] = [];
  @Input() entrepriseFilter: any;
  @Input() userConnected: any;
  @Input() couche: any;
  @Output() dowloadExcel = new EventEmitter<any>();
profile:any
  // Pagin param
  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;
  loading: boolean = true;
  getFilShp: any;
  fileLoading:boolean = false
  // Filter param
  fields_selected_: Array<any> = [
    {
      libelle_db: "acteurConc_ActeurPerso_CodePlanteur_Icontains",
      libelle: "Code planteur",
    },
    // { libelle_db: "acteurPerso_Nom_Icontains", libelle: "Nom" },
    // { libelle_db: "acteurPerso_Prenoms_Icontains", libelle: "Prénoms" }
  ];

  // filter: any = {
  //   field: null,
  //   value: null,
  // };
  filter:string

  coucheId: any;
  formcouches$: Observable<object[]>;
  formCouche: object[];
  constructor(
    public dialog: MatDialog,
    private utils: UtilsService,
    private generatePdfService: GeneratePdfService,
    // private acteurServ: ActeurService,
    private acteurServ: NewActeurService,
    private excelField: ExcelFieldToDowloadComponent,
    private wegeoUtils: wegeoUtil,
    private store: Store<AppState>,
    private router : Router,
    private acteurService : ActeurService,
    private dataCache : DataCacheService,
    private userServ: UserService,
  ) {
    this.formcouches$ = this.store.pipe(select(selectForm));
    this.formcouches$.subscribe((form) => (this.formCouche = form));
  }

  ngOnInit() {
    this.getPlantations()
    this.profile = JSON.parse(
     this.utils.decrypt(this.utils.getCookie("_wefly_"))
   );
    
     
     console.log("pro profile", this.profile);
   }

  generatePdf(acteur:any) {
    console.log(acteur);
    
    this.excelField.listExcelField("Plantations").then((res: Map<string, string>) => {
      const field = [...res.keys()] 
      console.log('execel data',field.toString())
      const attribut = field.toString()
      this.userServ.createZoneFile(acteur.id,this.profile.profile.user.id, this.couche.id,"",false, false, false, false, true, attribut).then((res:any)=>{
        console.log('create pdf', res)
      })

    })

    
  }
  



  openDialog(acteur): void {
    console.log('acteur', acteur);
    
  
    const dialogRef = this.dialog.open(NewAlertConfirmComponent, {
      width: "35rem",
      data: { title:"Suppression plantation", message:"Vous êtes sur le point de supprimer cette plantation. Voulez-vous continuer ?" },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.acteurService.deleted(acteur).then(
          (res : any) => {
            console.log('plantation delete ok', res)
            this.plantations.filter((value, index) => {
              if (value.id == acteur) {
                this.plantations.splice(index, 1);
                this.utils.snack('Plantation supprimée avec succès')
              }
            });
          },
          (error) => {
            console.log('erreur delete plantation', error)
            console.error(error)
          }
        ).catch(reason =>  this.utils.setErrorsHttp(reason));
       
      } 
    });
  }

  getLastPersoNomPrenoms(pers: []) {
    let element: any = {};
    if (pers.length > 0) {
      element = pers[pers.length - 1];
      return element.nom + " " + element.prenoms;
    }
    return "";
  }

  getLastPersoContact(pers: []) {
    let element: any = {};
    if (pers.length > 0) {
      element = pers[pers.length - 1];
      return element.contact;
    }
    return "";
  }

  getMedia(url: string): string {
    return this.utils.API_URL + url;
  }
  getPlantations(){
    const cacheKey = 'cache-plantation'
    const cacheTotalCount = 'totale-count-plantation'
    const planteurPageCache = 'plantation-page-data'
    const currentPage = this.dataCache.getData(planteurPageCache)
    const cachedData = this.dataCache.getData(cacheKey)
    const totaldata = this.dataCache.getData(cacheTotalCount)
 
    if(cachedData){
     this.plantations = cachedData
     this.totalCount = totaldata
     if(currentPage){
      this.currentPage = currentPage
     }else{
      this.currentPage = this.currentPage
     }
     this.loading = false
    }else{
      this.readActeur(this.couche,"");
    }
  }
 

  private readActeur(
    couche: any,
    Icontains:string,
    page: number = 1,
    filter_field: any = null,
    periodic_filter: DateRange = null
  ): any {
    try {
      this.loading = true;
      let filter = "";
  

      filter = `couche: "${couche.id}", acteur_ActeurGen_CodePlantation_Icontains:"${Icontains}", deleted: false, acteur_Deleted: false`;
      if (filter_field != null && filter_field.field != null) {
        filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`;
      }

      this.acteurServ
        .readPlantation(filter, page, "-created_at", periodic_filter)
        .then(
          (res: any) => {
            const cacheKey = 'cache-plantation'
            const cacheTotalCount = 'totale-count-plantation'
            
            this.loading = false;
            this.plantations = res.results;
            this.dataCache.setData(cacheKey, this.plantations)
            this.totalCount = res.totalCount;
            this.dataCache.setData(cacheTotalCount, this.totalCount)
            console.log('plantations', this.plantations)
            this.activeSearch = false
          },
          (err) => {}
        )
        .catch((reason) => this.utils.setErrorsHttp(reason));
    } catch (error) {
      this.plantations = [];
      this.loading = false;
    }
  }

  onChangePage(data) {
    const planteurPageCache = 'plantation-page-data'
    this.currentPage = data.id;
    this.dataCache.setData(planteurPageCache,this.currentPage)
    this.readActeur(this.couche,"",data.id);
  }



  getValueLive(val:string){
    console.log('val live', val)
    if(val ===""){
      this.readActeur(this.couche, "")
      this.currentPage = 1
    }
  }

  filterData(filter:string){
    if(filter){
      console.log('filter plantation val',filter )
      this.readActeur(this.couche, filter)
      this.activeSearch = true
    }
   
  }

  /* Telecharger fichier SHP */

  downloadAllShpFile(CouchId) {
    return this.utils.API_HOST + "get-shp/plantation/" + CouchId + "/";
  }

  DownloadExcel(type) {
    const couche = this.couche.id;
    if (type === "kml" || type === "shp" || type === "gpx") {
      this.dowloadExcel.emit({ couche, type }); 
    } else if(type === "excel"){
      this.excelField.listExcelField("Plantations").then((res: Map<string, string>) => {
        this.dowloadExcel.emit({ couche, type, res }); 

          // this.wegeoUtils.getExcelFile(res, this.couche, this.userConnected).then((res) => {
          //     this.fileLoading = false;
          //   });
        });
    }
  }
  selectExcelField() {
    const dialog = this.dialog.open(ExcelFieldToDowloadComponent, {
      width: "70vw",
      data: {
        couche: "Plantations",
      },
    });
    dialog.afterClosed().subscribe((field: Map<string, string>) => {
      if (field) {
        console.log('field', field)
        this.fileLoading = true;
        this.wegeoUtils
          .getExcelFile(field, this.couche, this.userConnected) 
          .then((res) => {
            this.fileLoading = false;
          });
      }
    });
  }

  getDate(date_range: DateRange) {
    this.readActeur(this.couche,"",this.currentPage, null, date_range);
  }

  getShpFile(){
    //this.couche.id ? (this.getFilShp = this.utils.API_HOST + "get-shp/plantation/" + this.couche.id + "/") : alert("Pas de fichier SHP disponible");
    if( this.couche.id){
      window.open(this.utils.API_HOST + "get-shp/plantation/" + this.couche.id + "/", '_self')

    }else{
      alert('Pas de fichier SHP disponible')
    }
  }

  getDetails(plantation:any){
    this.router.navigate(['/wegeo/acteur/detail/', plantation.id])
  }


  croissant() {
    this.isSelected = !this.isSelected
    if(!this.isSelected){
      this.isSelectedDec = true
      this.utils.OrdrecroisantArrayPLantation(this.plantations, 'codePlantation')
    }
  }


  decroissant() {
    this.isSelectedDec = !this.isSelectedDec
    if(!this.isSelectedDec){
      this.isSelected = true
      this.utils.ordreDecrossantArrayPlantation(this.plantations, 'codePlantation')
    }
  }
  getKml(plantation:any){
    console.log('kml', plantation)
    console.log("cou couche", this.couche)

    this.openLoadingDialog({
      msg: "Veuillez patienter s'il vous plaît.",
      loader: true,
    });

    this.userServ.createZoneFile(plantation.id,this.profile.profile.user.id, plantation.couche.id, this.profile.profile.zone.id, true, false, false, false,false,'').then((data:any)=>{
      console.log("create kml", data)
      this.closeLoadingDialog();
      if(data.createZoneFiles.zonefiles.filepath !== null){
        window.open(this.utils.API_URL + data.createZoneFiles.zonefiles.filepath,'_self')
      }else{
        return
      }
    },(error:any)=>{
      console.log('erreorceate kml', error) 
    })
  }


  public closeLoadingDialog(id = "loaderTrackingItem"): void {
    try {
      this.dialog.getDialogById(id).close();
    } catch (error) {}
  }

  public openLoadingDialog(d = null, id = "loaderTrackingItem"): void {
    const dialogRef = this.dialog.open(LoadingComponent, {
      width: "500px",
      id: id,
      data: d,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
