import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';
import { Router } from '@angular/router';

declare var jQuery: any;

@Component({
  selector: 'app-dorne-sidebar',
  templateUrl: './dorne-sidebar.component.html',
  styleUrls: ['./dorne-sidebar.component.css']
})
export class DorneSidebarComponent implements OnInit {
  @Input() menuList: any[];
  @Input() rapportList: any[] = [];
  constructor(
    public utils: UtilsService,
    private router: Router
  ) { }

  ngOnInit() {}

  createRapport() {
    this.router.navigate(['/drone/rapport/0']);
    jQuery('#file').trigger('click');

  }

  updateRapport(id) {
    this.router.navigate(['/drone/rapport/'+id+'/1']);
    jQuery('#file').trigger('click');
  }

  deleteRapport(id) {
    this.router.navigate(['/drone/rapport/'+id+'/0']);
  }
}
