import { EmployeComponent } from './../employe/employe.component';
import { TrackingComponent } from './../tracking/tracking.component';
import { Component, OnInit, NgModule, ViewChild, ElementRef } from '@angular/core';
import 'bootstrap';
import 'jquery';
import jspdf, * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from 'src/app/_services/utils.service';
import { UserService } from 'src/app/_services/user.service';
import { NgForm } from '@angular/forms';
import { ProfileService } from 'src/app/_services/profile.service';
import { GestionEmployeComponent } from '../gestion-employe/gestion-employe.component';
declare var $;
import { ZoneActionService } from 'src/app/_services/zone.action.service';
import { MatDialog } from '@angular/material';
import { EditProfilComponent } from '../_modale/edit-profil/edit-profil.component';
import { ActiveOrDesactiveUserComponent } from '../_modale/active-or-desactive-user/active-or-desactive-user.component';
import { ActiveOrDesactiveTrackingUserComponent } from '../_modale/active-or-desactive-tracking-user/active-or-desactive-tracking-user.component';
import { StatisitiquesService } from 'src/app/_services/statisitiques.service';

@Component({
  selector: 'app-employe-details',
  templateUrl: './employe-details.component.html',
  styleUrls: ['./employe-details.component.scss'],
  providers: [GestionEmployeComponent, TrackingComponent, EmployeComponent],
})

export class EmployeDetailsComponent implements OnInit {
  navId: number = null;
  menu_: any[] = []
  profileDetail: any = null;
  photoProfile: any | String = '';
  jour: number[] = [];
  mois: number[] = [];
  annee: number[] = [];
  Zones = [];
  user_id: number = null;
  statsEmploye: any = [];
  @ViewChild('content', { static: false }) content: ElementRef;

  constructor(
    private route: ActivatedRoute,
    public utils: UtilsService,
    private userServ: UserService,
    private profileServ: ProfileService,
    public dialog: MatDialog,
    private statServ: StatisitiquesService,
    public tracking: TrackingComponent,
    public emplComp: EmployeComponent
  ) { }

  ngOnInit() {
    this.jour = this.utils.genTabNumber(31);
    this.mois = this.utils.genTabNumber(12);
    let nowYear = (new Date().getFullYear());
    this.annee = this.utils.genTabNumber(nowYear - 15, nowYear - 100).reverse();

    if (this.route.snapshot.params['id']) {
      let user_id = this.route.snapshot.params['id'];
      this.getUser(user_id)
    } else {
      this.profileDetail = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
      this.getUser(this.profileDetail.id);
    }
  }

  getUser(user_id) {
    this.userServ.read(`id: ${user_id}`, 1, 'id').then( 
      (res: any) => {
        if (res.searchUser.results != null) {
          this.profileDetail = res.searchUser.results.find(element => element.id == user_id);
          this.readStatsemployes(user_id,
            this.profileDetail.profile.entreprise.id);
        } else {

        }
      },
      (err) => {
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  openDialogEditProfil(): void {
    const dialogRef = this.dialog.open(EditProfilComponent, {
      width: '1000px',
      data: { user: this.profileDetail }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.route.snapshot.params['id']) {
          let user_id = this.route.snapshot.params['id'];
          this.getUser(user_id)
        } else {
          this.profileDetail = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
        }
      }
    });
  }

  openDialogUserActiveOrDesactive(): void {
    const dialogRef = this.dialog.open(ActiveOrDesactiveUserComponent, {
      width: '450px',
      data: { user: this.profileDetail }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  openDialogtrackingActiveOrDesactive(): void {
    const dialogRef = this.dialog.open(ActiveOrDesactiveTrackingUserComponent, {
      width: '450px',
      data: { user: this.profileDetail }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.route.snapshot.params['id']) {
          let user_id = this.route.snapshot.params['id'];
          this.getUser(user_id)
        } else {
          this.profileDetail = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
        }
      }
    });
  }

  public makePdf() {
    var data = document.getElementById('content');
    html2canvas(data).then((canvas) => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('cv.pdf'); // Generated PDF
    });
  }

  public onSubmit(form: NgForm): void {
    let f = form.value;
    let active: Boolean = (typeof ((f.active) == 'boolean') && (f.active == true)) ? false : true;
    let tracking: Boolean = (typeof ((f.tracking) == 'boolean') && (f.tracking == true)) ? false : true;
    this.userServ.update(this.profileDetail.id, f.username, new Date().toISOString(), f.mail, f.prenom, active, false, false, new Date().toISOString(), f.nom).then(
      (res: any) => {
        if (res.updateUser.user.id) {
          this.profileServ.update(this.profileDetail.profile.id, '', '', f.contact, new Date(f.year, f.month - 1, f.day).toISOString().slice(0, 10), false, '', this.profileDetail.profile.entreprise.id, '', f.genre, f.nom, '', this.photoProfile, f.prenom, tracking, res.updateUser.user.id, f.zone).then(
            (res2: any) => {
            }, (err2) => {
            }
          );
        } else {
        }

      }, (err) => {
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  public processFile(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.selectedFile = reader.result;
        // console.clear();
        this.photoProfile = reader.result;
      };
    } else {
      // tslint:disable-next-line:no-console
      console.info('Un problème est survenu lors du chargement du fichier.');
    }
  }

  public gotsearchValue(e): any { }

  public readStatsemployes(employe: number | string, entreprise): void {
    this.statServ.readStatsemployes(`deleted: false, employe:${employe}, entreprise: "${entreprise}"`).then(
      (res: any) => {

        if (res.results.length === 0) {
          this.statsEmploye = {
            nbrePlanteurs: 0,
            nbrePlantations: 0,
            nbrePlantationsNotees: 0,
            supTotale: 0
          };
        } else {
          this.statsEmploye = res.results[0];
        }
      }, (err) => {
      }
    );
  }

}
