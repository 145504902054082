import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, NgForm } from "@angular/forms";
import { UtilsService } from "src/app/_services/utils.service";
import { ProfileService } from "src/app/_services/profile.service";
import { TrackItemService } from "src/app/_services/track-item.service";
import * as turf from "@turf/turf";
import { MatDatepickerInputEvent, MatDialog } from "@angular/material";
import { LoadingComponent } from "../_modale/loading/loading.component";
import { StatisitiquesService } from "src/app/_services/statisitiques.service";
import { Router } from "@angular/router";
import { IconsTracking } from "./IconTracking/IconTracking";
import { PlantationPerformanceService } from "src/app/_services/plantation-performance.service";
import { ActeurService } from "src/app/_services/acteurs.service";

declare var $;
declare var L: any;

@Component({
  selector: "app-tracking",
  templateUrl: "./tracking.component.html",
  styleUrls: ["./tracking.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TrackingComponent implements OnInit {
  statsEmploye: any = [];
  parcs: any = [];
  pays: any = [];
  map: any = null;
  parcsMarkerOrLine: number = -1; // 0: line, 1: marker
  clusterLine = L.markerClusterGroup();
  clusterMarker = L.markerClusterGroup();

  clusterPlantationMarker = L.markerClusterGroup();
  clusterPlantation = L.markerClusterGroup();
  clusterPlantationOne = L.markerClusterGroup();

  clustertracking: any = L.markerClusterGroup();
  clustertrackingOne: any = L.markerClusterGroup();

  profileTracked: any = [];
  oneTrack: any = [];
  oneTrackProfile: any = [];
  userInfo: any;
  element: any;
  tableTrachking: any = [];
  cpt = 0;
  date;
  // polyline = L.polyline([], { color: 'red' });
  polyline = L.polyline([], { color: "transparent" });

  //Initialize the pagination for the search query.
  maxOfPages: number = 20;
  totalCount: number = 0;
  currentPage: number = 1;
  loading: boolean = true;
  dateSelectedDebut: any;
  dateSelectedFin: any;
  performanceUrl: boolean = false;
  markerOrLine: number = 1;
  lengthActeurChev;
  dataChevauche;
  hidelist:boolean = true;
  filter:string
  loardStat:boolean = false
  // Filtre param
  fields_selected_: Array<any> = [
    { libelle_db: "nom_Icontains", libelle: "Nom" },
    { libelle_db: "prenom_Icontains", libelle: "Prénoms" },
  ];

  // filter: any = {
  //   field: null,
  //   value: null,
  // };

  events: string[] = []; // sauvegarde chaque sélection de date du user
  constructor(
    public utils: UtilsService,
    private profileServ: ProfileService,
    private trackItemServ: TrackItemService,
    private statServ: StatisitiquesService,
    public dialog: MatDialog,
    public router: Router,
    public plantationPerformanceService: PlantationPerformanceService,
    private acteurServ: ActeurService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.performanceUrl =
      this.router.url.indexOf("performance") > -1 ? true : false;
    this.userInfo = JSON.parse(
      this.utils.decrypt(this.utils.getCookie("_wefly_"))
    );
    // this.callMap();
    this.map = this.utils.callMap();
    this.readTracks();
  }
  // ngOnChanges() {
  //   this.clusterPlantationMarker.clearLayers();
  // }
  checkbox: any = [
    { select: true, id: 1, libelle: "Trackings" },
    { select: true, id: 2, libelle: "Plantations" },
  ];
  errorHandler(event) {
    console.debug(event);
    event.target.src = "../../../assets/img/avatar.png";
  }

  dateClass = (d: Date) => {
    // colorie toutes les dates où des données sont présentes
    const date = d.getDate();
    // Highlight the 1st and 20th day of each month.
    // return (date === 30 || date === 28) ? 'example-custom-date-class' : 'desable-calendar';
  };

  desable = (d: Date) => {
    // désactive toutes les dates où aucunes données n'est présente
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    // return day !== 0 && day !== 6;
    return true;
  };

  public onDate(e): any {}

  public selectedDate(): string[] {
    let first: string = "",
      last: string = "";
    for (let i = 0; i < this.events.length; i += 2) {
      if (this.events[i] == "1") {
        first = this.events[i + 1];
      } else {
        last = this.events[i + 1];
      }
    }

    return [last, first];
  }
  public hideDash(): void {
    // const button = parent.document.getElementById("map-button");
    // button.setAttribute("style", "display: block");

    // // const a = parent.document.getElementById('map-dash');
    // let a = document.getElementById("tracked-list");
    // let b = document.getElementById("map");

    // a.style.width = "0%";
    // b.style.width = "100%";
  }

  public showDash(): void {
    this.hidelist = !this.hidelist
    // let a = document.getElementById("tracked-list");
    // let b = document.getElementById("map");
    // const button = parent.document.getElementById("map-button");
    // a.style.width = "25%";
    // b.style.width = "100%";
    // button.setAttribute("style", "display: none");
  }

  public readTracks(page: number = 1, filter_field: any = null): void {
    this.loading = true;
    let filter = `deleted: false, entreprise: "${this.userInfo.profile.entreprise.id}"`;
    if (filter_field != null && filter_field.field != null) {
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`;
    }

    this.profileServ
      .read(page, filter)
      .then(
        (res: any) => {
          this.loading = false;
          this.totalCount = res.searchProfile.totalCount;
          this.profileTracked = res.searchProfile;
          console.log('employe', this.profileTracked)
          setTimeout(() => {
            if (this.profileTracked.results.length > 0 && this.performanceUrl) {
              this.clickViewOneTrack(this.profileTracked.results[0]);
            }
          }, 1000);

          // for (
          //   let index = 0;
          //   index < this.profileTracked.results.length;
          //   index++
          // ) {
          //   this.profileTracked.results[index].user
          //     ? (this.tableTrachking.push(
          //         this.profileTracked.results[index].user.tracks[0]
          //       ),
          //       // this.dateSelected =this.tableTrachking[0].jourDeTrack,
          //       this.clickViewOneTrack(this.profileTracked.results[index]))
          //     : "";
          // }
        },

        (err) => {}
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }

  onChangePage(data) {
    this.currentPage = data.id;
    this.readTracks(this.currentPage);
  }

  public addTracks(eltT: any): void {
    console.clear();
    try {
      eltT.user.tracks.forEach((eltItem) => {
        // console.log("eltItem", eltItem);
        eltItem.tItems.forEach((elt, i) => {
          // console.log('elt==>',elt,i);
          let t = L.marker([elt.latitude, elt.longitude], {
            icon: this.iconTracking(i),
          }).bindPopup(
            this.popUpTemplateTracking(eltT.nom + " " + eltT.prenom, elt.date),
            { className: "custom-pop-up-pays" }
          );
          if (i > 0) {
            // var polyline = L.polyline([], { color: 'red' });
            this.polyline.addLatLng({
              lat: eltItem.tItems[i - 1].latitude,
              lng: eltItem.tItems[i - 1].longitude,
            });
            this.polyline.addLatLng({ lat: elt.latitude, lng: elt.longitude });
            this.polyline.addTo(this.map);
          }
          this.clustertracking.addLayer(t);
          this.clusterPlantation.addLayer(t);
          this.map.addLayer(this.clustertracking);
        });
      });
    } catch (error) {}
  }

  public async clickViewOneTrack(_t, index?: number) {
    // console.log("dadat", index);

    // _t: profile tracké dont on voudrait afficher les déplacement sur la carte

    if (this.oneTrackProfile.id != _t.id) {
      this.oneTrackProfile = _t;
      this.map.removeLayer(this.clustertracking);
      this.map.removeLayer(this.clustertrackingOne);

      this.clustertrackingOne = new L.markerClusterGroup();
      this.openLoadingDialog({
        msg: "Veuillez patienter s'il vous plaît.",
        loader: true,
      });
      const user = _t.user;
      user.entreprise = _t.entreprise;
      this.viewOneTrack(user);
      this.readStatsemployes(_t.user.id, this.userInfo.profile.entreprise.id);
      // console.log("_t.user.id", _t.user.id);

      // console.log("user", user);
    }
    const user = _t.user;
    user.entreprise = _t.entreprise;
    this.clusterPlantationMarker.clearLayers();
     console.log("user user user =================", user);

    let plantations = this.getUserPlantations(user);
  }

  public viewOneTrack(user: any, page: number = 1): void {

    console.log(this.oneTrackProfile.user.id)


    let dateFilter = `deleted: false, tracks_User_Id: ${this.oneTrackProfile.user.id}, tracks_Periode_Entreprise_Id: "${this.userInfo.profile.entreprise.id}"`;

    // this.getUserPlantations(user);

    if (this.selectedDate()[0] == "" && this.selectedDate()[1] == "") {
    } else if (this.selectedDate()[0] == "" && this.selectedDate()[1] != "") {
      dateFilter += `, date_Gte: "${new Date(this.selectedDate()[1])
        .toISOString()
        .toString()}"`;
    } else if (this.selectedDate()[0] != "" && this.selectedDate()[1] == "") {
      dateFilter += `, date_Lte: "${new Date(this.selectedDate()[0])
        .toISOString()
        .toString()}"`;
    } else {
      dateFilter += `, date_Lte: "${new Date(this.selectedDate()[0])
        .toISOString()
        .toString()}", date_Gte: "${new Date(this.selectedDate()[1])
        .toISOString()
        .toString()}"`;
    }
    // console.log("dateFilter", dateFilter);

    this.oneTrack = [];
    this.trackItemServ.read(dateFilter, page).then(
      (res: any) => {
        // console.log("resss", res);

        if (!this.oneTrack.results) {
          this.closeLoadingDialog();
          this.oneTrack = res;
          if (this.oneTrack.totalCount == 0) {
            this.openLoadingDialog(
              {
                msg: "Cet utilisateur ne possède pas de trajet.",
                loader: false,
              },
              "msg"
            );
            setTimeout(() => {
              this.closeLoadingDialog("msg");
            }, 10000);
          }
          // console.clear();
        } else {
          this.oneTrack.results = [...this.oneTrack.results, ...res.results];
        }

        if (this.oneTrack.results.length < this.oneTrack.totalCount) {
          // if (this.oneTrack.results.length < 100) {
          page++;
          this.viewOneTrack(page);
        } else {
          // this.map.fitBounds(this.clustertrackingOne.getBounds());
        }

        this.map.removeLayer(this.polyline);
        res.results.forEach((elt, i) => {
          // console.log('elt2',i);
          this.addTrackItem(elt, i + 1);
          if (i > 0) {
            // var polyline = L.polyline([], { color: 'red' });
            // polyline.addLatLng({lat: 5.3331416, lng: -3.9561812});
            // polyline.addLatLng({lat: 5.3331416, lng: -3.9561812});
            this.polyline.addLatLng({
              lat: res.results[i - 1].latitude,
              lng: res.results[i - 1].longitude,
            });
            this.polyline.addLatLng({ lat: elt.latitude, lng: elt.longitude });
            this.polyline.addTo(this.map);
          }
        });
      },
      (err) => {
        
        console.log('errr', err)
      }
    );
  }

  addEvent(type: string, e: MatDatepickerInputEvent<Date>) {
    // console.log("typeaaa", type, e);

    this.events.push(type);
    this.events.push(e.value.toString());
    // console.clear();
    let dateFilter = `deleted: false, tracks_Periode_Entreprise_Id: "${this.userInfo.profile.entreprise.id}"`;
    // console.log("selectedDate", this.selectedDate());
    let page = 1;
    if (this.selectedDate()[0] == "" && this.selectedDate()[1] == "") {
    } else if (this.selectedDate()[0] == "" && this.selectedDate()[1] != "") {
      dateFilter += `, date_Gte: "${new Date(this.selectedDate()[1])
        .toISOString()
        .toString()}"`;
    } else if (this.selectedDate()[0] != "" && this.selectedDate()[1] == "") {
      dateFilter += `, date_Lte: "${new Date(this.selectedDate()[0])
        .toISOString()
        .toString()}"`;
    } else {
      dateFilter += `, date_Lte: "${new Date(this.selectedDate()[0])
        .toISOString()
        .toString()}", date_Gte: "${new Date(this.selectedDate()[1])
        .toISOString()
        .toString()}"`;
    }
    // console.log("dateFilter", dateFilter);

    this.oneTrack = [];
    this.trackItemServ.read(dateFilter, page).then(
      (res: any) => {
        // console.log("resss", res);

        if (!this.oneTrack.results) {
          this.closeLoadingDialog();
          this.oneTrack = res;
          if (this.oneTrack.totalCount == 0) {
            this.openLoadingDialog(
              {
                msg: "Pas de tracking disponible pour cette date.",
                loader: false,
              },
              "msg"
            );
            setTimeout(() => {
              this.closeLoadingDialog("msg");
            }, 10000);
          }
          // console.clear();
        } else {
          this.oneTrack.results = [...this.oneTrack.results, ...res.results];
        }

        if (this.oneTrack.results.length < this.oneTrack.totalCount) {
          // if (this.oneTrack.results.length < 100) {
          page++;
          this.viewOneTrack(page);
        } else {
          // this.map.fitBounds(this.clustertrackingOne.getBounds());
        }

        this.map.removeLayer(this.polyline);
        res.results.forEach((elt, i) => {
          // console.log('elt2',i);
          this.addTrackItem(elt, i + 1);
          if (i > 0) {
            // var polyline = L.polyline([], { color: 'red' });
            // polyline.addLatLng({lat: 5.3331416, lng: -3.9561812});
            // polyline.addLatLng({lat: 5.3331416, lng: -3.9561812});
            this.polyline.addLatLng({
              lat: res.results[i - 1].latitude,
              lng: res.results[i - 1].longitude,
            });
            this.polyline.addLatLng({ lat: elt.latitude, lng: elt.longitude });
            this.polyline.addTo(this.map);
          }
        });
      },
      (err) => {}
    );
  }

  public addTrackItem(item, index): void {
    // console.log('addTrackItem', item);
    let t = L.marker([item.latitude, item.longitude], {
      icon: this.iconTracking(index),
    }).bindPopup(
      this.popUpTemplateTracking(
        item.tracks.user.profile.nom + " " + item.tracks.user.profile.prenom,
        item.date
      ),
      { className: "custom-pop-up-pays" }
    );
    this.clustertrackingOne.addLayer(t);
    this.map.addLayer(this.clustertrackingOne);
    this.map.fitBounds(this.clustertrackingOne.getBounds());
  }

  public gotsearchValue(e): any {}

  public popUpTemplateTracking(
    header: string = "nom et prenoms",
    date: any = "28/01/1998 à 07h45"
  ): string {
    let splitDate = date.split("T")[0].split("-");
    let splitHeure = date.split("T")[1].split(":");
    return `<div class="my"> <h6 class="greenColor">${header}</h6> <p class="blackColor"> était ici le ${
      splitDate[2] +
      "/" +
      splitDate[1] +
      "/" +
      splitDate[0] +
      " à " +
      splitHeure[0] +
      "h" +
      splitHeure[1] +
      "mn"
    } </p> <div>`;
  }

  public iconTracking(index?: number): any {
    // console.log('iconTracking',index);
    let iconUrl = "../../assets/ico/marker-tracking.png";
    let LeafIcon = IconsTracking(L, iconUrl, index);
    //  L.Icon.extend({
    //   options: {
    //     shadowUrl: "../../assets/popup-shadow.png",
    //     iconUrl: "../../assets/ico/marker-tracking.png",
    //     iconSize: [35, 50], // size of the icon [largeur, hauteur]
    //     shadowSize: [50, 64], // size of the shadow
    //     iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    //     shadowAnchor: [4, 62], // the same for the shadow
    //     popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
    //   },
    // });
    return LeafIcon;
  }

  public getBgColor(_t) {
    return this.oneTrackProfile.id == _t.id ? "#55AAA0" : "#FFF";
  }
  public ColorStyle(_t) {
    return this.oneTrackProfile.id == _t.id ? "#FFF" : "#058B46";
  }

  filterData(data:string) {
     console.log("filter", data);
     this.filter = data

    // if (data.action === 0) {
    //   this.filter = data.data_;
    //   return;
    // } else {
    //   this.loading = true;
    //   this.filter = data.data_;
    //   if (data.data_.value.length == 0) {
    //     this.readTracks(1);
    //   } else {
    //     this.readTracks(1, this.filter);
    //   }
    // }
  }

  public openLoadingDialog(d = null, id = "loaderTrackingItem"): void {
    const dialogRef = this.dialog.open(LoadingComponent, {
      width: "500px",
      id: id,
      data: d,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  public openEmptyDialog(d = null, id = "emptyDialog") {
    const dialogRef = this.dialog.open(LoadingComponent, {
      width: "500px",
      id: id,
      data: d,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  public closeLoadingDialog(id = "loaderTrackingItem"): void {
    try {
      this.dialog.getDialogById(id).close();
    } catch (error) {}
  }

  public readStatsemployes(employe: number | string, entreprise): void {
    this.loardStat = true

    this.statServ
      .readStatsemployes(`deleted: false, employe:${employe}, entreprise: "${entreprise}"`).then(
        (res: any) => {
          this.loardStat = false
          console.log('statEmploy', res)
          if (res.results.length === 0) {
            
            this.statsEmploye = {
              nbrePlanteurs: 0,
              nbrePlantations: 0,
              nbrePlantationsNotees: 0,
              supTotale: 0,
            };
          } else {
            this.statsEmploye = res.results[0];
          }

          // console.log(" this.statsEmploye", this.statsEmploye);
        },
        (err) => {
          this.loardStat = false
        
          console.log('errrrrrrrrr',err)

        }
      );
  }

  async getCouch(userEntreprise, typeCouch = "Plantation") {
    // console.log("userEntreprise", userEntreprise);

    const query = `
        query{
          searchCouche(entreprise:"${userEntreprise.id}",typeCouche_Etiquette:"${typeCouch}",deleted:false){
            totalCount,
            results{
              id
              libelle
              typeCouche{
                id
              }
            }
          }
        }
  `;

    return new Promise((resolve, reject) => {
      this.utils.client.request(query).then(
        (res: any) => {
          // console.log("res==>", );
          resolve(res);
          // return res.searchCouche.results[0].id;
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async getUserPlantations(user) {
    
    console.log("user=>>", user);


    let  couch:any = await this.getCouch(user.entreprise, "PLANTATION");
    console.log('couche traks', couch)

    this.getAllActeursByCouch(couch.searchCouche.results[0], user);
  }



  async getAllActeursByCouch(couch, user) {
    
    let page = 1;
    let type = 1;
    let acteurs = [];
    let isClicked = false;
    // let totalActeurs = [];
    let totalCount = 0;
    let lengthgetActeur = 0;

    let datas;
    await this.acteurByCouch(couch.id, user.id, page).then((res: any) => {
      datas = res.results;
      totalCount = res.totalCount;
    });
    // console.log("datasdatas", datas);
    // console.log("totalCount", totalCount);

    if (datas.length < totalCount) {
      while (datas.length < totalCount) {
        let datas = await this.acteurByCouch(couch.id, user.id, page);
        acteurs.push(datas);
        // console.log("lenee1", acteurs);

        page += 1;
      }
    } else {
      acteurs = datas;
      // console.log("lenee2", acteurs);
    }
    // console.log("acteurs", acteurs);
    // this.map.removeLayer(this.clusterPlantationMarker);
    for (const item of acteurs) {
      // console.log("item", item);

      try {
        let color = "#058B46";
        let argThis = this;
        let LeafIcon = L.Icon.extend({
          options: {
            iconUrl:
              "../../../assets/img/sig/Dashboard-Map/drawable-xhdpi/Composant 14 – 11.png",
            iconSize: [60, 65], // size of the icon [largeur, hauteur]
            shadowSize: [50, 64], // size of the shadow
            iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62], // the same for the shadow
            popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
          },
        });
        let kmlBaseUrl = this.utils.API_URL;
        let shpBaseUrl = this.utils.API_URL;
        // this.plantationPerformanceService.addPlantationOnMap(item);
        let plantationIcon = new LeafIcon();
        let codePlantation =
          item.acteurGen[0] && item.acteurGen[0].codePlantation
            ? item.acteurGen[0].codePlantation
            : "Inconnu";
        let surface =
          item.acteurDelimitation[0] && item.acteurDelimitation[0].surface
            ? item.acteurDelimitation[0].surface
            : "Inconnu";
        let perimetre =
          item.acteurDelimitation[0] && item.acteurDelimitation[0].perimetre
            ? item.acteurDelimitation[0].perimetre
            : "Inconnu";
        let kmlUrl =
          item.acteurDelimitation[0] && item.acteurDelimitation[0].kml
            ? kmlBaseUrl + item.acteurDelimitation[0].kml
            : "";
        let shpUrl =
          item.acteurDelimitation[0] && item.acteurDelimitation[0].shp
            ? shpBaseUrl + item.acteurDelimitation[0].shp
            : "";

        let culture = "";
        if (item.acteurDelimitation.length === 0) {
          culture = "Inconnu";
        } else {
          culture = item.acteurDelimitation[0].culture;
        }
        let getFileShp = this.removeExtension(item.acteurDelimitation[0].shp);

        if (item.acteurDelimitation.length > 0) {
          let html = `


      <div class="myplantationpopup"><br>   <div id='infoPrincipale'>
      <h6 style="text-align: center" class="greenColor"><strong>PLANTATION</strong></h6>
      <center>
        <p class="blackColor">Code de la plantation: <b>${codePlantation}</b></p>
        <p class="blackColor">Surface: <b>${surface} hectares </b><p/>
        <p class="blackColor">Périmètre: <b>${perimetre} mètres </b><p/>
        <p class="blackColor">Cultures:  <b>${culture}</b></p>

        <p class="blackColor"  style="display: flex;" >Plantation-Chevauché${
          this.lengthActeurChev > 1 ? "s" : ""
        }:   <button class="download-kml" id="${item.id}"> <b>${
            this.lengthActeurChev
          } Voir detail </b></button></p>
        ${
          kmlUrl !== ""
            ? `
        <a class="download-kml" href="${kmlUrl}" title="Télécharger le fichier KML">
        <img class="kml-img" src="../../../assets/img/sig/Dashboard-Map//drawable-xhdpi/kmz.png"/>
        Télécharger le fichier KML
        </a>`
            : `<a class="download-kml-desable"  title="Télécharger le fichier KML">
        <img class="kml-img" src="../../../assets/img/sig/Dashboard-Map//drawable-xhdpi/kmz.png"/>
        Télécharger le fichier KML
        </a>`
        }
        <br/>
        ${
          shpUrl !== ""
            ? `
        <a class="download-kml" href="${getFileShp}" download title="Télécharger le fichier SHP">
        <img class="kml-img" src="../../../assets/img/sig/Dashboard-Map//drawable-xhdpi/kmz.png"/>
        Télécharger le fichier SHP
        </a>`
            : `<a class="download-kml-desable"  title="Télécharger le fichier SHP">
        <img class="kml-img" src="../../../assets/img/sig/Dashboard-Map//drawable-xhdpi/kmz.png"/>
        Télécharger le fichier SHP
        </a>`
        }
        </div>
      <div id='infoChevauche'>
      </div>
      </center>
    <div>
     `;

          /**
           * Popup chevauchement
           */
          let htmlChevauche = "";
          let marker;
          if (
            item.idSuperieur !== null &&
            item.idSuperieur !== undefined &&
            item.idSuperieur !== ""
          ) {
            // console.log("chevauche", chevauche);

            this.dataChevauche = await this.getPlantationChevauche(
              item.idSuperieur
            );
            // console.log("chevauche", this.dataChevauche.results);

            if (
              this.dataChevauche.results !== undefined &&
              this.dataChevauche.results.length !== "" &&
              this.dataChevauche.results.length > 0
            ) {
              for (let i = 0; i < this.dataChevauche.results.length; i++) {
                const element = this.dataChevauche.results[i];
                // console.log("elele", element);
                if (element !== undefined) {
                  htmlChevauche += ` <div class="myplantationpopup"><br>
                  <h6 style="text-align: center" class="greenColor"><strong>Detail Plantation chevauché</strong></h6>
                  <center>
                    <p class="blackColor">Nom et prenom du planteur : <b>${element.acteurChev.superieur.acteurPerso[0].nom}  ${element.acteurChev.superieur.acteurPerso[0].prenoms} </b></p>
                    <p class="blackColor">Contact: <b>${element.acteurChev.superieur.acteurPerso[0].contact} mètres </b><p/>
                    <p class="blackColor">Plantation  ${element.acteurChev.acteurGen[0].codePlantation}:  <b> <a  href="/wegeo/acteur/detail/${element.acteurChev.acteurGen[0].id}" class="">Voir detail </a></b></p><hr/>
                  `;
                }
                marker = L.geoJson(
                  JSON.parse(this.utils.replaceAll("'", '"', item.coordonnees)),
                  {
                    onEachFeature: function (feature, layer) {
                      layer.editing._marker.options.icon = plantationIcon;
                      layer.bindPopup(html, {
                        className: "custom-pop-up-plantation",
                      });

                      layer.on("click", function (e, argThisPrime = argThis) {
                        item.idSuperieur !== null
                          ? argThisPrime.readProprioPlantation(item.idSuperieur)
                          : "";

                        document
                          .getElementById(item.id)
                          .addEventListener("click", () => {
                            // console.log("element", item.id);
                            document.getElementById(
                              "infoPrincipale"
                            ).style.display = "none";
                            document.getElementById("infoChevauche").innerHTML =
                              htmlChevauche;
                          });
                      });

                      // layer.on({
                      //   mouseover: function () {
                      //     if (!isClicked) {
                      //       this.openPopup();
                      //     }
                      //   },
                      //   mouseout: function () {
                      //     if (!isClicked) {
                      //       this.closePopup();
                      //     }
                      //   },
                      // });
                    },
                  }
                );
              }
            }
          }

          // let marker = L.geoJson(
          //   JSON.parse(this.utils.replaceAll("'", '"', item.coordonnees)),
          //   {
          //     onEachFeature: function (feature, layer) {
          //       layer.editing._marker.options.icon = plantationIcon;
          //       layer.bindPopup(html, {
          //         className: "custom-pop-up-plantation",
          //       });

          //       layer.on("click", function (e, argThisPrime = argThis) {
          //         item.idSuperieur !== null
          //           ? argThisPrime.readProprioPlantation(item.idSuperieur)
          //           : "";

          //         document
          //           .getElementById(item.id)
          //           .addEventListener("click", () => {
          //             console.log("element", item.id);
          //             document.getElementById("infoChevauche").innerHTML =
          //               htmlChevauche;
          //           });
          //       });

          //       // layer.on({
          //       //   mouseover: function () {
          //       //     if (!isClicked) {
          //       //       this.openPopup();
          //       //     }
          //       //   },
          //       //   mouseout: function () {
          //       //     if (!isClicked) {
          //       //       this.closePopup();
          //       //     }
          //       //   },
          //       // });
          //     },
          //   }
          // );

          // console.log("marker", marker);

          let geoJ = L.geoJSON(
            JSON.parse(
              this.utils.replaceAll(
                "'",
                '"',
                item.acteurDelimitation[0].geometrie
              )
            ),
            {
              color: color,
              weight: 4,
              opacity: 1,
              onEachFeature: function (feature, layer) {
                // layer.editing._marker.options.icon = plantationIcon;
                layer.bindPopup(html, {
                  className: "custom-pop-up-plantation",
                }),
                  layer.on("click", function (e, argThisPrime = argThis) {
                    item.idSuperieur !== null
                      ? argThisPrime.readProprioPlantation(item.idSuperieur)
                      : "";
                  });
              },
            }
          );
          // console.log("geoJ", geoJ);
          this.clusterPlantationMarker.addLayer(geoJ);
          // this.map.addLayer(this.clusterPlantationLine);
          // this.map.fitBounds(this.clusterPlantationLine.getBounds());

          this.clusterPlantationMarker.addLayer(marker);
          this.map.addLayer(this.clusterPlantationMarker);
        }
        this.view();
      } catch (error) {
        this.cpt++;
      }
    }

    // total = [...datas.]
  }
  public removeExtension(filename) {
    let remove_extension =
      filename.substring(0, filename.lastIndexOf(".")) || filename;
    return this.downloadShpFile(this.getFilename(remove_extension));
  }

  public getFilename(fullPath) {
    return fullPath.replace(/^.*[\\\/]/, "");
  }

  public downloadShpFile(idPlantaion) {
    return `${this.utils.API_HOST}get-shp/${idPlantaion}/`;
  }

  public view(): void {
    // ligne visible
    if (this.map) {
      this.map.removeLayer(this.clustertrackingOne);
      this.map.removeLayer(this.clusterPlantationMarker);
      this.checkbox.forEach((elt, i) => {
        if (elt.id == 1 && !elt.select) {
          this.map.addLayer(this.clustertrackingOne);
        }
        if (elt.id == 2 && !elt.select) {
          this.map.addLayer(this.clusterPlantationMarker);
        }
      });
    }
  }

  public viewWithClear(ml: number = this.markerOrLine): void {
    if (this.map) {
      this.markerOrLine = ml;
      this.map.removeLayer(this.clustertrackingOne);
      this.map.removeLayer(this.clusterPlantationMarker);
      this.view();
    }
  }

  public viewClicked(idChecked: number, f: NgForm): void {
    this.checkbox[idChecked].select = f.value[this.checkbox[idChecked].id];
    this.viewWithClear(this.markerOrLine);
    try {
      if (
        this.checkbox[idChecked].id == 1 &&
        !this.checkbox[idChecked].select
      ) {
        this.map.fitBounds(this.clustertrackingOne.getBounds());
      }
      if (
        this.checkbox[idChecked].id == 2 &&
        !this.checkbox[idChecked].select
      ) {
        this.map.fitBounds(this.clusterPlantationMarker.getBounds());
      }
    } catch (error) {}
  }

  async acteurByCouch(idCouch, userId, page: number = 1) {
    // console.log("idCouch,userId", idCouch, userId);

    const query = `
    {
      searchActeur(couche:"${idCouch}",user:"${userId}", deleted:false){
        results(page:${page}){
          id
          acteurGen{
            codePlantation
          }
          acteurPerso{
            nom
            prenoms
            contact
            photoUrl
          }
          idSuperieur
          coordonnees
          acteurDelimitation{
            geometrie
            perimetre
            surface
            kml
            culture
            shp
          }
        }
        totalCount
        }
    }
    `;

    return new Promise((resolve, reject) => {
      this.utils.client.request(query).then(
        (res: any) => {
          const data = {
            results: res.searchActeur.results.map((item: any) => item),
            totalCount: res.searchActeur.totalCount,
          };
          // console.log("data", data);

          resolve(data);
          // console.log("res==>", data);
          // resolve(res);
          // this.coucheId = res.searchCouche.results[0].id;
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public readProprioPlantation(idSuperieur: string): any {
    // this.acteurServ.readLocale(`id: "${idSuperieur}", couche_TypeCouche_Libelle: "Planteur", deleted: false`).then(
    this.acteurServ
      .readActeur(
        `id: "${idSuperieur}", couche_TypeCouche_Etiquette: "PLANTEUR", deleted: false`
      )
      .then(
        (res: any) => {
          // console.clear();
          // console.log("res", res.results[0]);
          if (res.results[0] !== undefined) {
            let elt = res.results[0];
            let photoUrl = elt.acteurPerso[0].photoUrl
              ? elt.acteurPerso[0].photoUrl !== "" &&
                elt.acteurPerso[0].photoUrl !== undefined
              : "../../../assets/img/avatar.png";
            let p = document.querySelectorAll(".myplantationpopup");
            let q = document.querySelectorAll(".proprio");
            q.forEach((el, i) => {
              q[i].parentNode.removeChild(q[i]);
            });

            p.forEach((el, i) => {
              p[i].innerHTML =
                `
            <div class="row proprio">
              <div class="col-12">
                <h6 style="text-align: center" class="greenColor"><strong>PLANTEUR</strong><br></h6>
              </div>
              <div class="col-9">
                <p class="blackColor"><b>${
                  elt.acteurPerso[0].nom + " " + elt.acteurPerso[0].prenoms
                }</b></p>
                <p class="blackColor">Contact: <b>${
                  elt.acteurPerso[0].contact
                }</b></p>
              </div>
              <div class="col-3">
                <img style="height: auto; width: 100%;" src="${photoUrl}"/>
              </div>
            </div>
          ` + p[i].innerHTML;
            });
          }
        },
        (err) => {}
      );
    // }
  }

  async getPlantationChevauche(id) {
    const query = `
    {
      searchActeurOverlaps(acteur_Id:"${id}",acteurChev_Deleted:false){
        results{
          id

          acteurChev{
          superieur{
              id
              acteurPerso{
                  nom
                  prenoms
                  contact
            }
          }

            acteurGen{
              id
              codePlantation
            }

          }
        }
          totalCount
      }
    }
    `;

    return new Promise((resolve, reject) => {
      this.utils.client.request(query).then(
        (res: any) => {
          const data = {
            results: res.searchActeurOverlaps.results.map((item: any) => item),
            totalCount: res.searchActeurOverlaps.totalCount,
          };
          // console.log("ress", data.results);

          // if (
          //   data.results.length > 0 &&
          //   data.results[0].acteurChev.acteurGen.length > 0
          // ) {
          // let datas = [];
          this.lengthActeurChev = data.totalCount;
          // data.results[0].acteurChev.acteurGen.map(
          //   (item) => item.filter((val) => val),
          //   datas.push()
          // );
          // console.log("datas", datas);
          // let newArr = data.results[0].acteurChev.acteurGen.filter(
          //   (val, i) => {
          //     if (data.results[0].acteurChev.acteurGen.indexOf(val) === i) {
          //     }
          //     data.results[0].acteurChev.acteurGen.indexOf(val) === i;
          //   }
          // );
          // console.log("newArr", newArr);

          // data.results[0].acteurChev.acteurGen.forEach((element, index) => {
          //   console.log("aaaa", datas.includes(element));

          //   return datas.includes(element);
          // });

          // const compareArrays = (arr1, arr2) => {
          //   // console.log("Overlaps", arr1, arr2)
          //   arr1.every((element,index)=>{
          //     return arr2.includes(element);
          //   } );
          //   // if (arr1.codePlantation === arr2.codePlantation) {
          //   //   return arr2.codePlantation;
          //   // } else {
          //   //   return;
          //   // }
          // };
          // const NewArra = compareArrays(data.results[0].acteurChev, datas[0]);
          // console.log("NewArra", NewArra);
          // } else {
          //   this.lengthActeurChev = 0;
          // }

          resolve(data);
          // console.log("res==>", data);
          // resolve(res);
          // this.coucheId = res.searchCouche.results[0].id;
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
