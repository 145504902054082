import { Injectable } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CompositionDeOffreService {

  constructor(private utils: UtilsService) { }

  create(culture: String, deleted: Boolean, image: String, imageUrl: String, offreVente: String, qteDispo: Number, user: String, ) {
    const query = `
    mutation(
		$culture: ID,
		$deleted: Boolean,
		$image: String,
		$imageUrl: String,
		$offreVente: ID,
		$qteDispo: Float,
		$user: ID,

	) {
      createCompositiondeoffre(newCompositiondeoffre: {
			culture: $culture,
			deleted: $deleted,
			image: $image,
			imageUrl: $imageUrl,
			offreVente: $offreVente,
			qteDispo: $qteDispo,
			user: $user,

		}) {
        compositiondeoffre {
          id
					culture{ id },
					deleted,
					image,
					imageUrl,
					offreVente{ id },
					qteDispo,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {culture,
					deleted,
					image,
					imageUrl,
					offreVente,
					qteDispo,
					user,
					}).then(
          (res:any) => {
            
            if (res.createCompositiondeoffre.ok) {
                resolve(res.createCompositiondeoffre.compositiondeoffre);
            }
            reject(res.createCompositiondeoffre.errors);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, culture: String, deleted: Boolean, image: String, imageUrl: String, offreVente: String, qteDispo: Number, user: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$culture: ID,
		$deleted: Boolean,
		$image: String,
		$imageUrl: String,
		$offreVente: ID,
		$qteDispo: Float,
		$user: ID,
		) {
      updateCompositionDeOffre(newCompositiondeoffre: {
				id: $id, culture: $culture,
			deleted: $deleted,
			image: $image,
			imageUrl: $imageUrl,
			offreVente: $offreVente,
			qteDispo: $qteDispo,
			user: $user,
			}) {
        compositiondeoffre {
          id
					culture{ id },
					deleted,
					image,
					imageUrl,
					offreVente{ id },
					qteDispo,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, culture,
					deleted,
					image,
					imageUrl,
					offreVente,
					qteDispo,
					user,
					}).then(
          (res) => {
           
            if (res['updateCompositionDeOffre']['ok']) {
              resolve(res['updateCompositionDeOffre']['compositiondeoffre'])
            }
            reject(res['updateCompositionDeOffre']['errors']);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchCompositionDeOffre (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					culture{ id },
					deleted,
					image,
					imageUrl,
					offreVente{ id },
					qteDispo,
					user{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }
}

