import { Injectable } from '@angular/core';
import { request } from 'graphql-request';
import { UtilsService } from 'src/app/_services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(private utils: UtilsService) { }


  create(query, variables){
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, variables).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }


  update(query, variables) {
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, variables).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  read(query : string, variables, filter: string = `deleted : false`) {
    let index = query.indexOf('search')
    let newQuery = query
    if(index > -1){
      let index2 = query.indexOf('{', index)
      let mot = query.substring(index, index2)
      let chaineFilter = mot + "(" + filter + ")"
      newQuery = query.replace(mot, chaineFilter)
    }
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(newQuery, variables).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }
}


