import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private utils: UtilsService) { }
  create(
    pays: number,
    cc2: String,
    deleted: Boolean,
    engtype2: String,
    gid0: String,
    gid1: String,
    gid2: String,
    hasc2: String,
    mpoly: String,
    name0: String,
    name1: String,
    name2: String,
    nlName1: String,
    nlName2: String,
    nomRegion: String,
    type2: String,
    varname2: String,

  ) {
    const query = `
    mutation(
			$pays:  ID!,
			$cc2:  String!,
			$deleted:  Boolean!,
			$engtype2:  String!,
			$gid0:  String!,
			$gid1:  String!,
			$gid2:  String!,
			$hasc2:  String!,
			$mpoly:  String!,
			$name0:  String!,
			$name1:  String!,
			$name2:  String!,
			$nlName1:  String!,
			$nlName2:  String!,
			$nomRegion:  String!,
			$type2:  String!,
			$varname2:  String!,
    ) {
      createRegion(newRegion: {
				pays: $pays,
				cc2: $cc2,
				deleted: $deleted,
				engtype2: $engtype2,
				gid0: $gid0,
				gid1: $gid1,
				gid2: $gid2,
				hasc2: $hasc2,
				mpoly: $mpoly,
				name0: $name0,
				name1: $name1,
				name2: $name2,
				nlName1: $nlName1,
				nlName2: $nlName2,
				nomRegion: $nomRegion,
				type2: $type2,
				varname2: $varname2,
      }) {region{
 id,
				pays,
				cc2,
				deleted,
				engtype2,
				gid0,
				gid1,
				gid2,
				hasc2,
				mpoly,
				name0,
				name1,
				name2,
				nlName1,
				nlName2,
				nomRegion,
				type2,
				varname2,
				}      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          pays,
          cc2,
          deleted,
          engtype2,
          gid0,
          gid1,
          gid2,
          hasc2,
          mpoly,
          name0,
          name1,
          name2,
          nlName1,
          nlName2,
          nomRegion,
          type2,
          varname2,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }
  update(id: String,
    pays: number,
    cc2: String,
    deleted: Boolean,
    engtype2: String,
    gid0: String,
    gid1: String,
    gid2: String,
    hasc2: String,
    mpoly: String,
    name0: String,
    name1: String,
    name2: String,
    nlName1: String,
    nlName2: String,
    nomRegion: String,
    type2: String,
    varname2: String,
  ) {
    const query = `
    mutation($id: UUID!,
			$pays:  ID!!,
			$cc2:  String!,
			$deleted:  Boolean!,
			$engtype2:  String!,
			$gid0:  String!,
			$gid1:  String!,
			$gid2:  String!,
			$hasc2:  String!,
			$mpoly:  String!,
			$name0:  String!,
			$name1:  String!,
			$name2:  String!,
			$nlName1:  String!,
			$nlName2:  String!,
			$nomRegion:  String!,
			$type2:  String!,
			$varname2:  String!,
    ) {
      updateRegion(newRegion: {
 id: $id,
				pays: $pays,
				cc2: $cc2,
				deleted: $deleted,
				engtype2: $engtype2,
				gid0: $gid0,
				gid1: $gid1,
				gid2: $gid2,
				hasc2: $hasc2,
				mpoly: $mpoly,
				name0: $name0,
				name1: $name1,
				name2: $name2,
				nlName1: $nlName1,
				nlName2: $nlName2,
				nomRegion: $nomRegion,
				type2: $type2,
				varname2: $varname2,
      }) {region{
 id,
				pays,
				cc2,
				deleted,
				engtype2,
				gid0,
				gid1,
				gid2,
				hasc2,
				mpoly,
				name0,
				name1,
				name2,
				nlName1,
				nlName2,
				nomRegion,
				type2,
				varname2,
				}      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id,
          pays,
          cc2,
          deleted,
          engtype2,
          gid0,
          gid1,
          gid2,
          hasc2,
          mpoly,
          name0,
          name1,
          name2,
          nlName1,
          nlName2,
          nomRegion,
          type2,
          varname2,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = `deleted : false`, page: number = 1, limit: number = this.utils.PAGINATION, order: string = 'id') {
    const query = `
    query($order: String!) {
      searchRegions(${filter}){
        results(ordering: $order)
        { id,
         	pays{
            id
        },
				cc2,
				deleted,
				engtype2,
				gid0,
				gid1,
				gid2,
				hasc2,
				mpoly,
				name0,
				name1,
				name2,
				nlName1,
				nlName2,
				nomRegion,
				type2,
				varname2,

        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { limit, page, order }).then(
          (res) => {
            resolve(res.searchRegions.results);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }
}

