import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { UtilsService } from '../../_services/utils.service';
import { DemandeDeProductionService } from '../_services/demande-production.service';
import { DemandeProductionComponent } from '../_modals/demande-production/demande-production.component';
import { SuccessMsgComponent } from 'src/app/_modale/success-msg/success-msg.component';
import { ErrorMsgComponent } from 'src/app/_modale/error-msg/error-msg.component';
import { ConfirmComponent } from 'src/app/confirm/confirm.component';
import { LoadingComponent } from 'src/app/@wegeo/_modale/loading/loading.component';

@Component({
  selector: 'app-demande-de-production-list',
  templateUrl: './demande-de-production-list.component.html',
  styleUrls: ['./demande-de-production-list.component.scss']
})
export class DemandeDeProductionListComponent implements OnInit {
  // Parametre filter
  fields_selected_: Array<any> = [
    { libelle_db: "libelle", libelle: "Libelle" },
    { libelle_db: "ville", libelle: "Ville" },
  ];
  filter: string = null;


  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;


  demande: any = {
    results: [],
    first: true
  };
  dataSource = new MatTableDataSource<any>();
  columnsToDisplay = ['vendeur', 'culture', 'cout', 'quantite', 'disponibilite', 'localisation', 'action'];

  constructor(
    private dialog: MatDialog,
    private utils: UtilsService,
    private demandeProdServ: DemandeDeProductionService,
  ) { }

  ngOnInit() {
    console.clear();
    this.getDemandeProd();
  }

  getDemandeProd(page: number = 1, filter: string=this.filter) {
    filter = (filter == null)?`deleted : false`: `deleted : false, ${filter}`;
    this.demandeProdServ.read(filter, page).then(
      (r_demande: any) => {
        this.demande = r_demande;
        this.dataSource = this.demande.results;
        this.totalCount = this.demande.totalCount;
        this.demande.first = false;
        
      }, (e_demande: any) => {
        this.utils.setErrorsHttp(e_demande);
      }
    );
  }

  updateDataSource() {
    this.dataSource = new MatTableDataSource<any>();
    this.dataSource.connect().next(this.demande.results);
  }

  addDemande() {
    const dialogRef = this.dialog.open(DemandeProductionComponent, {
      width: '40rem'
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        
        if (res) {
          if (res != '___') {
            
            const mdialogRef = this.dialog.open(SuccessMsgComponent, {
              width: '20rem',
              data: { msg: 'Votre demande a été enregistré avec succès. Il sera disponible après la validation par nos administrateurs.' }
            });
          }
        } else {
          this.dialog.open(ErrorMsgComponent, {
            width: '20rem',
            data: { msg: 'Une erreur est survenue, veuillez réessayer plus tard.' }
          });
        }
      }
    );
  }

  deleteDemande(i: number) {
    let demande = this.demande.results[i]
    console.clear();
    const dialogRef = this.dialog.open(
      ConfirmComponent,
      {
        width: '25rem',
        data: { message: `Souhaitez vous supprimer ${demande.libelle}` }
      }
    );

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res && res == true) {
          const delDialog = this.dialog.open(
            LoadingComponent,
            {
              width: '25rem',
              data: {
                msg: 'Veuillez patienter svp',
                loader: true
              }
            }
          );
          this.demandeProdServ.update(demande.id, demande.dateDebut, demande.dateFin, true, demande.description, demande.libelle, demande.region.id, demande.uniteMesure.id, demande.user.id, demande.ville).then(
            (r_dmd) => {
              delDialog.close();
              this.demande.results.splice(i, 1);
              this.updateDataSource();
            },
            (err) => {
              delDialog.close();
              this.utils.setErrorsHttp(err);
              this.dialog.open(ErrorMsgComponent, {
                width: '20rem',
                data: { msg: 'Une erreur est survenue veuillez réessayer plus tard.' }
              });
            }
          );
        } else {
        }
      }
    );
  }

  updateDemande(i: number) {
    console.clear();

    const dialogRef = this.dialog.open(DemandeProductionComponent, {
      width: '40rem',
      data: this.demande.results[i],
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
       
        if (res) {
          if (res != '___') {
            console.clear();
            const mdialogRef = this.dialog.open(SuccessMsgComponent, {
              width: '20rem',
              data: { msg: 'Votre demande a été enregistré avec succès. Il sera disponible après la validation par nos administrateurs.' }
            });
          }
        } else {
          this.dialog.open(ErrorMsgComponent, {
            width: '20rem',
            data: { msg: 'Une erreur est survenue, veuillez réessayer plus tard.' }
          });
        }
      }
    );
  }

  onChangePage(data) {
    this.currentPage = data.id;
    // this.page = this.currentPage;
    
    this.getDemandeProd(this.currentPage);
  }

  filterData(data) {
    this.filter = `${data.data_.field}: "${data.data_.value}"`;
   

    if (data.action != 0) {
      if (data.data_.value.length == 0) {
        this.getDemandeProd();
      } else {
        this.getDemandeProd(1, this.filter);
      }
    }
  }

}
