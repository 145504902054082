import { Observable } from "rxjs";
import { setForm } from "../_store/actions/form.actions";
import { Injectable } from "@angular/core";
import { CoucheService } from "../_services/couche.service";
import { FormCoucheService } from "../_services/form-couche.service";
import { CompartimentService } from "../_services/compartiment.service";
import { AttributFormulaireService } from "../_services/attribut-formulaire.service";
import { Store, select } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class FormsService {
  constructor(
    private couche: CoucheService,
    private formCouche: FormCoucheService,
    private compartiment: CompartimentService,
    private atrForm: AttributFormulaireService,
    private store: Store<{ forms: object[] }>
  ) {}

  public async getEntrepriseForm(entreprise, formcoucheGeted) {
  
    // On recupère les couches
    // Debut
    await this.couche
      .read(`entreprise: "${entreprise}", deleted: false, principal: false`)
      .then(
        async (couche: any) => {
          if (couche.results.length > 0) {
            // On recupère le formulaire pour chaque couche

            for (const element of couche.results) {
              await this.formCouche
                .read(
                  `couche_Entreprise_Id: "${entreprise}", couche: "${element.id}", deleted: false`
                )
                .then(async (form: any) => {
                  element.formulaire = form.searchFormCouche.results[0];

                  element.formulaire.compartiments = [];

                  // Recupéarion des compartiment de couche

                  await this.compartiment
                    .read(`typeCoucheId: "${element.typeCouche.id}"`)
                    .then(
                      async (compartmt: any) => {
                        // Récupération des attributs formulaires
                        for (const compt of compartmt.searchCompartiment
                          .results) {
                          // Debut
                          await this.atrForm
                            .read(
                              // tslint:disable-next-line: max-line-length
                              `formcouche_Id: "${form.searchFormCouche.results[0].id}", formcouche_Couche_Entreprise_Id: "${entreprise}", attribut_Compartiment_Id:"${compt.id}", deleted: false`
                            )
                            .then((atrform: any) => {
                              if (
                                atrform.searchAttributFormulaire.results
                                  .length > 0
                              ) {
                                compt.attributs =
                                  atrform.searchAttributFormulaire.results;
                                element.formulaire.compartiments.push(compt);
                              }
                            });
                          // fin
                        }
                      },
                      (err) => {}
                    );
                });
            }
            this.store.dispatch(setForm({ mforms: couche.results }));
            formcoucheGeted(true);
          }
        },
        (err) => {
          formcoucheGeted(false);
        }
      )
      .catch((reason) => {});
    // Fin
  }
}
