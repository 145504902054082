import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  
  
  constructor() {
    
  }

  ngOnInit() {
    
  }

  boxes = [1, 2, 3, 4, 5, 6, 7, 8, 9 , 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

  onScroll(e) {
    /*
    if(this.boxes.length>=100) {
      this.boxes.push(21);
      this.boxes.push(22);
      this.boxes.push(23);
      this.boxes.push(24);
      this.boxes.push(25);
      this.boxes.push(26);
      this.boxes.push(27);
      return;
    }
    */
    const moreBoxes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    this.boxes = [...this.boxes, ...moreBoxes];
  }

}
