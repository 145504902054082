import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';

@Component({
  selector: 'app-formation',
  templateUrl: './formation.component.html',
  styleUrls: ['./formation.component.scss']
})
export class FormationComponent implements OnInit {

  constructor(public utils: UtilsService) { }

  ngOnInit() {
  }
  public gotsearchValue(e): any {}

}
