import { Injectable } from "@angular/core";
import { UtilsService } from "src/app/_services/utils.service";

@Injectable({
  providedIn: "root",
})
export class OffreDeVenteService {
  constructor(private utils: UtilsService) {}

  create(
    deleted: Boolean,
    description: String,
    disponibilite: Date,
    epuise: Boolean,
    libelle: String,
    region: string,
    uniteMesure: string,
    user: string,
    vendu: Boolean,
    ville: String
  ) {
    const query = `
    mutation(
		$deleted: Boolean,
		$description: String,
		$disponibilite: CustomDateTime!,
		$epuise: Boolean,
		$libelle: String,
		$region: ID,
		$uniteMesure: ID,
		$user: ID,
		$vendu: Boolean,
		$ville: String,
	) {
      createOffredevente(newOffredevente: {
			deleted: $deleted,
			description: $description,
			disponibilite: $disponibilite,
			epuise: $epuise,
			libelle: $libelle,
			region: $region,
			uniteMesure: $uniteMesure,
			user: $user,
			vendu: $vendu,
			ville: $ville,
		}) {
        offredevente {
                    id
					caracteristiquesOffresDeVente{
						id
					},
					codeVente,
					compositionOffresDeVente{
						id
					},
					deleted,
					description,
					disponibilite,
					epuise,
					libelle,
					localisation,
					region{
						id
					},
					uniteMesure{
						id
					}
					updateAt,
					user{
						id
					}
					vendu,
					ville,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          deleted,
          description,
          disponibilite,
          epuise,
          libelle,
          region,
          uniteMesure,
          user,
          vendu,
          ville,
        })
        .then(
          (res) => {
           
            if (res["createOffredevente"]["ok"]) {
              resolve(res["createOffredevente"]["offredevente"]);
            }
            reject(res["createOffredevente"]["errors"]);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  update(
    id: String,
    deleted: Boolean,
    description: String,
    disponibilite: String,
    libelle: String,
    region: string,
    uniteMesure: string,
    user: string,
    ville: String
  ) {
    const query = `
    mutation(
			$id: UUID!,
		$deleted: Boolean,
		$description: String,
		$disponibilite: CustomDateTime,
		$libelle: String,
		$region: ID,
		$uniteMesure: ID,
		$user: ID,
		$ville: String,
		) {
      updateOffredevente(newOffredevente: {
      id: $id,
			deleted: $deleted,
			description: $description,
			disponibilite: $disponibilite,
      libelle: $libelle,
      region: $region,
			uniteMesure: $uniteMesure,
			user: $user,
			ville: $ville,
			}) {
        offredevente {
          id
					deleted,
					description,
					disponibilite,
					libelle,
					uniteMesure{
            id
            libelle
            abbreviation
          }
					user{
						profile {
            admin
            deleted
            photo
            photoUrl
            id,
            codeDeVerification,
            contact,
            dateNaissance,
            deleted,
            displayName,
            genre,
            nom,
            prenom,
            tracking,
            entreprise {
              logo
              logoUrl
              id,
              raisonSociale,
              telephone
               email
              categorie{
                libelle,
                numero
              }
            }
          }
        }
					ville,
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          id,
          deleted,
          description,
          disponibilite,
          libelle,
          region,
          uniteMesure,
          user,
          ville,
        })
        .then(
          (res) => {
           
            if (res["updateOffredevente"]["ok"]) {
              resolve(res["updateOffredevente"]["offredevente"]);
            }
            reject(res["updateOffredevente"]["errors"]);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  read(
    filter: string = "deleted: false",
    page: number = 1,
    order: String = "-created_at"
  ) {
    filter = filter == null || filter == "" ? "deleted: false" : filter;
    const query =
      `
    query {
      searchOffredevente (${filter}){
        results(page:` +
      page +
      `, ordering:"` +
      order +
      `") {
                    id
					caracteristiquesOffresDeVente{
						id
            prixVente,
					  qteGenDispo,
					},
					codeVente,
					compositionOffresDeVente{
            id
            culture {
              id,
              libelle
              culture{ id, libelle }
            }
					},
					createdAt,
					deleted,
					description,
					disponibilite,
					epuise,
					libelle,
					localisation,
					propostionDemandeDeProduction{
						id
					},
					region{
            id
            name0
            name1
					},
					reponsesOffresDeVente{
						id
					},
					uniteMesure{
            id
            abbreviation
            libelle
					}
					updateAt,
					user{
						profile {
            admin
            deleted
            photo
            photoUrl
            id,
            codeDeVerification,
            contact,
            dateNaissance,
            deleted,
            displayName,
            genre,
            nom,
            prenom,
            tracking,
            entreprise {
              logo
              logoUrl
              id,
              raisonSociale,
              telephone
               email
              categorie{
                libelle,
                numero
              }
            }
          }
        }
					vendu,
					status,
					ville,
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query).then(
        (res:any) => {
         resolve(res.searchOffredevente);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
