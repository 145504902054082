import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../_services/utils.service';
@Injectable()
export class JwtInterceptorService implements HttpInterceptor {
  [x: string]: any;

  constructor(private utils: UtilsService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const idToken = this.utils.getCookie('weflyHeader_token');

    const idToken2 = this.utils.getStorage('weflyHeader_token')
    
    
    if (idToken2) {

      request = request.clone({
        setHeaders: {
          Authorization: `JWT ${idToken2}`,
        }
      });
      return next.handle(request); 
    } else {
    
      request = request.clone({
        setHeaders: {
          // 'Content-Type': 'application/json'
        }
      });
      return next.handle(request);
    }
  }
}
