import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import 'jquery';
import 'bootstrap';
import { delay } from 'q';
import { UtilsService } from '../_services/utils.service';
import { AttributService } from '../_services/attribut.service';
import { CompartimentService } from '../_services/compartiment.service';
import { TypeAttributService } from '../_services/type-attribut.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AttributOptionsService } from '../_services/attribut-options.service';
import { DeletedComponent } from '../_modale/deleted/deleted.component';

declare var $: any;

@Component({
  selector: 'app-attribut',
  templateUrl: './attribut.component.html',
  styleUrls: ['./attribut.component.css']
})
export class AttributComponent implements OnInit {

  // Parametre filter
  fields_selected_: Array<any> = [
    { libelle_db: "libelle_Icontains", libelle: "Libelle" },
  ]

  filter: any = {
    field: null,
    value: null
  }

  loading: boolean = true

  // Fin paramatre


  allData: any = [];
  total = 0;
  page = 1;
  limit = 20;

  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;

  toDelete: any = {
    attributId: "",
    attributTypeAttribut: "",
    attributCompartiement: null,
    attributLibelle: "",
    attributLibellebd: "",
    attributRequis: false,
    attributAutogenere: false,
    attributOptions: [],
    attributOrdreAffichage: "",
    attributDeleted: false,
    compartiment: {
      id: ''
    },
    typeAttribut: {
      id: ''
    }
  };
  updating = false;
  updated = false;
  pageAct: any = 1;

  allCompartiment: any;
  allType: any;
  allOptions = [];
  @Input() inputOpt: String = null;
  constructor(
    private serv: AttributService,
    private utils: UtilsService,
    private compartimentServ: CompartimentService,
    private typeAtttributServ: TypeAttributService,
    private snackBar: MatSnackBar,
    private optionssv: AttributOptionsService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.onReadAll();
    this.compartimentServ.read().then(
      (res: any) => {
        this.allCompartiment = res.searchCompartiment.results;
      }, (err) => {
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
    this.typeAtttributServ.read().then(
      (res: any) => {
        this.allType = res.searchTypeAttribut.results;
      }, (err) => {
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  onSubmit(f: NgForm) {
    console.clear();
    if (f.value.attributRequis === null) {
      f.value.attributRequis = false;
    }

    if (f.value.attributAutogenere === null) {
      f.value.attributAutogenere = false;
    }

    if (this.updating === true) {
      this.onSubmitToUpdate(f);
    } else {
      this.serv.create(
        f.value.attributAutogenere,
        false,
        f.value.attributCompartiement,
        f.value.attributLibelle,
        f.value.attributLibellebd,
        f.value.attributRequis,
        f.value.attributTypeAttribut,
        false
      ).then(
        (res: any) => {
          let option: any[];
          this.allOptions.forEach(element => {
            this.optionssv.create(res.data.createAttribut.attribut.id, false, element.libelle).then(
              (mres: any) => {
                option.push(mres.data.createAttributOptions.attributoptions)
              },
              (err) => {
              }
            );
          });
          f.reset();
          this.snackBar.open('Requête effectuée avec succès', 'Ok', { duration: 5000 });
          // this.onReadAll();
          res.data.createAttribut.attribut.attributsOptions.concat(option)
          this.allData.searchAttribut.results.unshift(res.data.createAttribut.attribut);
          this.allOptions = [];
        }, (err) => {
        }
      ).catch(reason => this.utils.setErrorsHttp(reason));
    }
  }

  delete(supprime) {
    $('#Delete').modal('show');
    this.toDelete = supprime;
    this.toDelete.attributDeleted = true;
  }



  openDeletedDialog(supprime): void {
    this.toDelete = supprime;
    this.toDelete.attributDeleted = true;

    const dialogRef = this.dialog.open(DeletedComponent, {
      width: '300px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.utils.openLoadingDialog();
        if (!this.toDelete.attributOrdreAffichage) {
          this.toDelete.attributOrdreAffichage = '1';
        }
        this.serv.update(this.toDelete.id, this.toDelete.autogenere, this.toDelete.champsDeBase, this.toDelete.compartiment.id, this.toDelete.libelle, this.toDelete.libellebd, this.toDelete.requis, this.toDelete.typeAttribut.id, true).then(
          (res) => {
            for (let i = 0; i < this.allData.lenth; i++) {
              if (this.allData[i].id === this.toDelete.id) {
                this.snackBar.open('Requête effectuée avec succès', 'Ok', { duration: 5000 });
                this.allData.splice(i, 1);
              }
            }
            this.dialog.getDialogById("wefly_loading_modale_").close()
            let index = this.allData.searchAttribut.results.findIndex(element => element.id == this.toDelete.id);
            if (index != -1) {
              this.allData.searchAttribut.results.splice(index, 1)
            }
          }, (err) => {
            this.dialog.getDialogById("wefly_loading_modale_").close()
          }
        ).catch(reason => this.utils.setErrorsHttp(reason));
      } else {
      }
    });
  }



  onReadAll() {
    this.getData(1);
  }

  onDelete() {
    if (!this.toDelete.attributOrdreAffichage) {
      this.toDelete.attributOrdreAffichage = '1';
    }

    this.serv.update(this.toDelete.id, this.toDelete.autogenere, this.toDelete.champsDeBase, this.toDelete.compartiment.id, this.toDelete.libelle, this.toDelete.libellebd, this.toDelete.requis, this.toDelete.typeAttribut.id, true).then(
      (res) => {
        for (let i = 0; i < this.allData.lenth; i++) {
          if (this.allData[i].id === this.toDelete.id) {
            this.snackBar.open('Requête effectuée avec succès', 'Ok', { duration: 5000 });
            this.allData.splice(i, 1);
          }
        }
        // this.onReadAll();
        $('#Delete').modal('hide');
        let index = this.allData.searchAttribut.results.findIndex(element => element.id == this.toDelete.id);
        if (index != -1) {
          this.allData.searchAttribut.results.splice(index, 1)
        }
      }, (err) => {
        $('#Delete').modal('hide');
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  gotsearchValue(a) {
    return a;
  }

  onSubmitToUpdate(f: NgForm) {
    this.serv.update(
      this.toDelete.attributId,
      f.value.attributAutogenere,
      false,
      f.value.attributCompartiement,
      f.value.attributLibelle,
      f.value.attributLibellebd,
      f.value.attributRequis,
      f.value.attributTypeAttribut,
      false
    ).then(
      (res: any) => {
        // this.onReadAll();
        let option: any[] = []
        this.snackBar.open('Requête effectuée avec succès', 'Ok', { duration: 5000 });
        f.reset();
        // this.onReadAll();
        this.allOptions.forEach(element => {
          console.table(element);
          if (element.id) {
          } else {
            this.optionssv.create(res.data.updateAttribut.attribut.id, false, element.libelle).then(
              (mres: any) => {
                option.push(mres.data.createAttributOptions.attributoptions)
              },
              (err) => {
              }
            );
          }
        });
        let index = this.allData.searchAttribut.results.findIndex(element => element.id == this.toDelete.attributId);
        if (index != -1) {
          this.allData.searchAttribut.results[index] = res.data.updateAttribut.attribut;
          this.allData.searchAttribut.results[index].attributsOptions.concat(option)
        }
        this.allOptions = [];
        this.updating = false;
      }, (err) => {
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  clickUpdate(upd) {

    this.toDelete = upd;
    this.toDelete.attributId = upd.id,
      this.toDelete.attributTypeAttribut = upd.typeAttribut.id,
      this.toDelete.attributCompartiement = upd.compartiment,
      this.toDelete.attributLibelle = upd.libelle,
      this.toDelete.attributLibellebd = upd.libellebd,
      this.toDelete.attributRequis = upd.requis,
      this.toDelete.attributAutogenere = upd.autogenere,
      this.allOptions = upd.attributsOptions,
      this.toDelete.attributDeleted = upd.deleted,
      this.updating = true;
    console.clear();
  }

  addNew(f: NgForm) {
    f.reset();
    this.updating = false;
  }

  getData(page: number = 1, filter_field: any = null) {
    this.loading = true
    let filter = 'deleted : false'
    if (filter_field != null && filter_field.field != null) {
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`
    }
    this.serv.read(filter, page).then(
      (res: any) => {
        this.loading = false
        this.allData = res;
        this.totalCount = res['searchAttribut']['totalCount']
      }, (err) => {
        return err;
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  addOption(opt: String, f: NgForm) {
    f.reset({
      attributOptions: "",
      attributTypeAttribut: f.value.attributTypeAttribut,
      attributCompartiement: f.value.attributCompartiement,
      attributLibelle: f.value.attributLibelle,
      attributLibellebd: f.value.attributLibellebd,
      attributRequis: f.value.attributRequis,
      attributAutogenere: f.value.attributAutogenere,
    })
    this.allOptions.push({ libelle: opt });
    console.table('Options', this.allOptions);
  }

  remOption(opt) {
    this.allOptions.splice(this.allOptions.indexOf(opt), 1);
    if (opt.id) {
      this.optionssv.update(opt.id, true, opt.libelle).then(
        (res) => {
          this.utils.snack('Options supprimée avec avec succès.');
        },
        (err) => {
        }
      ).catch(reason => this.utils.setErrorsHttp(reason));
    }
    console.table('Options', this.allOptions);
  }

  autoSelected(option: string, comp: string = ''): Boolean {
    for (let index = 0; index < 5; index++) {
      if (index == 3) {
        return true;
      } else {
        return false;
      }
    }
  }

  onChangePage(data) {
    this.currentPage = data.id;
    this.getData(data.id)
  }

  goToPage(n: number): void {
    this.page = n;
    this.getData(n);
  }

  onNext(): void {
    this.page++;
    this.getData(this.page);
  }

  onPrev(): void {
    this.page--;
    this.getData(this.page);
  }

  filterData(data) {
    if (data.action === 0) {
      return
      this.filter = data.data_
    } else {
      this.loading = true
      this.filter = data.data_
      if (data.data_.value.length == 0) {
        this.getData(1)
      } else {
        this.getData(1, this.filter)
      }
    }
  }

}
