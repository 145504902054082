export const IconsTracking = (mapLayout, Icon, Index) => {
  return mapLayout.divIcon({
    className: 'my-div-icon',
    iconSize: [35, 50],
    html:
      '<div style="background: #343a40d9;width: 2.5rem;height: 50px;text-align: center;color: white;"><div>' +Index +'</div><img src=' + Icon +' alt=' +
      Icon +
      '/>'
  });
};
 