import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";

@Injectable({
  providedIn: "root",
})
export class InformationPersoActeurService {
  constructor(private utils: UtilsService) {}

  create(
    dateCreationMobile: String,
    dateNaissance: String,
    acteur: String,
    autrePiece: String,
    codePlanteur: String,
    contact: String,
    deleted: Boolean,
    genre: String,
    identMobile: String,
    nationalite: String,
    nom: String,
    numeroDePiece: String,
    origine: String,
    photo: String,
    prenoms: String,
    typePiece: String,
    user: String,
    natureDemande: String,
    village: String,
    chefVillage: String
  ) {
    const query = `
    mutation(
		$dateCreationMobile: CustomDateTime!,
		$dateNaissance: CustomDateTime!,
		$acteur: ID,
		$autrePiece: String,
		$codePlanteur: String,
		$contact: String,
		$deleted: Boolean,
		$genre: String,
		$identMobile: String,
		$nationalite: String,
		$nom: String,
		$numeroDePiece: String,
		$origine: String,
		$photo: String,
		$prenoms: String,
		$typePiece: String,
		$user: ID,
		$natureDemande: String,
		$village: String,
		$chefVillage: String
	) {
      createInformationPersoActeurPers(newInformationpersoacteurpers: {
			dateCreationMobile: $dateCreationMobile,
			dateNaissance: $dateNaissance,
			acteur: $acteur,
			autrePiece: $autrePiece,
			codePlanteur: $codePlanteur,
			contact: $contact,
			deleted: $deleted,
			genre: $genre,
			identMobile: $identMobile,
			nationalite: $nationalite,
			nom: $nom,
			numeroDePiece: $numeroDePiece,
			origine: $origine,
			photo: $photo,
			prenoms: $prenoms,
			typePiece: $typePiece,
			user: $user,
			natureDemande: $natureDemande,
			village: $village,
			chefVillage: $chefVillage
		}) {
        informationpersoacteurpers {
          id
					dateCreationMobile,
					dateNaissance,
					acteur{ id },
					autrePiece,
					codePlanteur,
					contact,
					deleted,
					genre,
					identMobile,
					nationalite,
					nom,
					numeroDePiece,
					origine,
					photo,
					prenoms,
					typePiece,
					# user{ id },
					natureDemande,
					village,
					chefVillage
					createdAt,
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          dateCreationMobile,
          dateNaissance,
          acteur,
          autrePiece,
          codePlanteur,
          contact,
          deleted,
          genre,
          identMobile,
          nationalite,
          nom,
          numeroDePiece,
          origine,
          photo,
          prenoms,
          typePiece,
          user,
          natureDemande,
          village,
          chefVillage,
        })
        .then(
          (res) => {
            if (res["createInformationPersoActeurPers"]["ok"]) {
              resolve(
                res["createInformationPersoActeurPers"][
                  "informationpersoacteurpers"
                ]
              );
            }
            reject(res["createInformationPersoActeurPers"]["errors"]);
          },
          (err) => {
            this.utils.setErrorsHttp(err);
            reject(err);
          }
        );
    });
  }

  update(
    id: String,
    dateCreationMobile: String,
    dateNaissance: String,
    acteur: String,
    autrePiece: String,
    codePlanteur: String,
    contact: String,
    deleted: Boolean,
    genre: String,
    identMobile: String,
    nationalite: String,
    nom: String,
    numeroDePiece: String,
    origine: String,
    photo: String,
    prenoms: String,
    typePiece: String,
    user: String
  ) {
    const query = `
    mutation(
			$id: UUID!,
		$dateCreationMobile: CustomDateTime!,
		$dateNaissance: CustomDateTime!,
		$acteur: ID,
		$autrePiece: String,
		$codePlanteur: String,
		$contact: String,
		$deleted: Boolean,
		$genre: String,
		$identMobile: String,
		$nationalite: ID,
		$nom: String,
		$numeroDePiece: String,
		$origine: String,
		$photo: String,
		$prenoms: String,
		$typePiece: String,
		$user: ID,
		) {
      updateInformationPersoActeurPers(newInformationpersoacteurpers: {
				id: $id, dateCreationMobile: $dateCreationMobile,
			dateNaissance: $dateNaissance,
			acteur: $acteur,
			autrePiece: $autrePiece,
			codePlanteur: $codePlanteur,
			contact: $contact,
			deleted: $deleted,
			genre: $genre,
			identMobile: $identMobile,
			nationalite: $nationalite,
			nom: $nom,
			numeroDePiece: $numeroDePiece,
			origine: $origine,
			photo: $photo,
			prenoms: $prenoms,
			typePiece: $typePiece,
			user: $user,
			}) {
        informationpersoacteurpers {
          id
					dateCreationMobile,
					dateNaissance,
					acteur{ id },
					autrePiece,
					codePlanteur,
					contact,
					deleted,
					genre,
					identMobile,
					nationalite{ id },
					nom,
					numeroDePiece,
					origine,
					photo,
					prenoms,
					typePiece,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          id,
          dateCreationMobile,
          dateNaissance,
          acteur,
          autrePiece,
          codePlanteur,
          contact,
          deleted,
          genre,
          identMobile,
          nationalite,
          nom,
          numeroDePiece,
          origine,
          photo,
          prenoms,
          typePiece,
          user,
        })
        .then(
          (res) => {
            if (res["updateInformationPersoActeurPers"]["ok"]) {
              resolve(
                res["updateInformationPersoActeurPers"][
                  "informationpersoacteurpers"
                ]
              );
            }
            reject(res["updateInformationPersoActeurPers"]["errors"]);
          },
          (err) => {
            this.utils.setErrorsHttp(err);
            reject(err);
          }
        );
    });
  }

  read(
    filter: string = "deleted: false",
    page: number = 1,
    order: String = "-created_at"
  ) {
    filter = filter == null || filter == "" ? "deleted: false" : filter;
    const query =
      `
    query {
      searchInformationPersoActeurPers (${filter}){
        results(page:` +
      page +
      `, ordering:` +
      order +
      `) {
          id
					dateCreationMobile,
					dateNaissance,
					acteur{ id },
					autrePiece,
					codePlanteur,
					contact,
					deleted,
					genre,
					identMobile,
					nationalite{ id },
					nom,
					numeroDePiece,
					origine,
					photo,
					prenoms,
					typePiece,
					user{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.utils.setErrorsHttp(err);
          reject(err);
        }
      );
    });
  }
}
