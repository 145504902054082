import { Component, OnInit } from '@angular/core';
import { PuceModel } from '../models';

@Component({
  selector: 'app-testeur',
  templateUrl: './testeur.component.html',
  styleUrls: ['./testeur.component.css']
})
export class TesteurComponent implements OnInit {
  res: any = null;
  puce: PuceModel[] = [
    {name: "one", value: 1, icon: "assets/img.jpg", circle: true},
    {name: "two", value: 2, right: false, icon: 'assets/img.jpg', descr: "loremmmmmmmLorem ipsum dolor sit amet consectetur adipisicing elit. Vel distinctio reiciendis vitae ducimus autem? Ipsum quidem, ab repellendus ipsa nam esse eum minima veritatis delectus iusto, doloremque cumque tempore libero."},
    {name: "three", value: 3, icon: "assets/img.jpg", descr: "loremmmmmmm", right: true},
  ];
  
  html = '<div class="custom-control custom-radio custom-control-inline"><input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input">\
<label class="custom-control-label" for="customRadioInline1">Toggle this custom radio</label>\
</div>\
<div class="custom-control custom-radio custom-control-inline">\
  <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input">\
  <label class="custom-control-label" for="customRadioInline2">Or toggle this other custom radio</label>\
</div>';

  constructor() { }

  ngOnInit() {
  }

  receive(v: PuceModel) {
    this.res = v;
  }

}
