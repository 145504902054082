import { Component, OnInit } from '@angular/core';
import 'jquery';
import 'bootstrap';
import { UtilsService } from '../_services/utils.service';
import { UserService } from '../_services/user.service';
declare var $: any;
import * as jwt_decode from 'jwt-decode';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaysService } from '../_services/pays.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategorieProfileService } from '../_services/profile-categorie.service';
import { AdresseService } from '../_services/adresse.service';
import { EntrepriseService } from '../_services/entreprise.service';
import { ProfileService } from '../_services/profile.service';
import { Router } from '@angular/router';
import { Entreprise, Page, SearchUser, UpdateProfile } from '../_models/user';
import { CreateAdress } from '../_models/createAdress';



@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {
  entrepriseForm:FormGroup
  alertField:boolean = false
  pays: any = [];
  checkbox: any;
  loader:boolean = false
  imageFile:any
  userEntreprise: any;
  files:any[]

  constructor(private utils: UtilsService,  
    private fb:FormBuilder,
    private paysv: PaysService,
    private snackbar: MatSnackBar,
    private categorieService: CategorieProfileService,
    private addressServ : AdresseService,
    private entService : EntrepriseService,
    private profileServ: ProfileService,
    private usersv: UserService,
    private router : Router) {
    this.entrepriseForm = this.fb.group({
      "socialRaison":['', Validators.required],
      "categorie":['', Validators.required],
      "pay":['', Validators.required],
      "ville":['', Validators.required],
      "quartier":['', Validators.required],
      "tel":['',[Validators.required, Validators.minLength(8)]],
      "email":['', [Validators.required, Validators.email]],
      "siteWeb":['', Validators.required]
    })
   }
  


  ngOnInit() {
    this.getPays()
    this.readCategorie()
    this.readUser()
    // Verification si entreprise

    // const username = (this.utils.getUserToken().username).toString();
    // let username = (jwt_decode( this.utils.getStorage('weflyHeader_token')).username)
    // this.user.read(`username: "${username}"`, 1, 'id').then(
    //   (res: any) => {
     
    //     if (res.searchUser.results[0].profile.entreprise !== null) {
    //     } else {
    //       this.showEntreprise();
    //     }
    //   },
    //   (err) => {
    //   }
    // ).catch(reason =>  this.utils.setErrorsHttp(reason));
  }
// showEntreprise() {
//     $('#entreprisePop').modal('show');
// }

readUser() {
  const  username = (jwt_decode( this.utils.getStorage('weflyHeader_token')).username)
  // const username = this.utils.getUserToken().username.toString();
  this.usersv
    .searchUserNew( new SearchUser(username) )
    .then(
      (res: any) => {
        console.log('user user ent', res)
        // console.clear();
        this.userEntreprise = res.data.searchUser.results[0];
 
        this.utils.setCookie("_wefly_",this.utils.encrypt(JSON.stringify(this.userEntreprise)),{ nb: 7, unite: 3 }
        );
      },
      (err) => {
        console.log('errur search userr', err)
      }
    )
    .catch((reason) => this.utils.setErrorsHttp(reason));
}


public getPays(offset: number = 0) {
  this.paysv
    .getPays(new Page(25, offset))
    .then(
      (res: any) => {
       console.log('pay', res)
        const result: any = res.data.searchPays;
        // console.log('les pays', res)
        this.pays = this.pays.concat(result.results);
        if (this.pays.length < result.totalCount) {
          this.getPays(this.pays.length);
        } else {
         // this.loadingPays = false;
        }
      },
      (err) => {
        console.log('pay', err)
        this.snackbar.open(
          "Désolé un problème est survenu, \nveuillez réessayer plus tard",
          "Ok",
          { duration: 5000 }
        );
      }
    )
    .catch((reason) => this.utils.setErrorsHttp(reason));
}

readCategorie() {
      this.categorieService.getCategorie().then(
      (res: any) => {
        console.log("catégorieEnter", res);
        if (res.data.searchCategorieProfile.results) {
          console.log('elementelement',res.data.searchCategorieProfile.results),
          res.data.searchCategorieProfile.results =
            res.data.searchCategorieProfile.results.filter(
              (element: any) =>
                !element.libelle.toLowerCase().includes("fédération") &&
                !element.libelle.toLowerCase().includes("union")
            );
        }
        this.checkbox = res.data.searchCategorieProfile.results == null ? []  : res.data.searchCategorieProfile.results;
        // console.log(" this.checkbox", this.checkbox);

        this.checkbox.forEach((element) => {
          element.value = false;
        });
      },
      (err) => {
        console.log(err);
      }
    )
    .catch((reason) => this.utils.setErrorsHttp(reason));
}

// onReadAll() {
//   this.getEnt(1);
// }
// getEnt(page: number = 1, filter_field: any = null) {
//   this.loading = true;
//   let entRes: any;
//   // let filter = 'deleted : false'
//   if (this.route.snapshot.params["list"]) {
//     this.entService.read().then(
//       (res) => {
//         this.loading = false;
//         entRes = res;
//         this.allEnt = entRes.searchEntreprises;
//       },
//       (err) => {
//         return err;
//       }
//     );
//   } else {
//     //const username = this.utils.getUserToken().username.toString();
//     const  username = (jwt_decode( this.utils.getStorage('weflyHeader_token')).username)
//     this.usersv
//       .read(`username: "${username}"`, 1, "id")
//       .then(
//         (res: any) => {
//           if (res.searchUser.results[0].profile.entreprise !== null) {
//             let entreprise = res.searchUser.results[0].profile.entreprise.id;
//             let filter = `id : "${entreprise}", deleted : false`;
//             if (filter_field != null && filter_field.field != null) {
//               filter =
//                 filter + `,  ${filter_field.field} : "${filter_field.value}"`;
//             }
//             this.entService
//               .read(filter, page)
//               .then(
//                 (res) => {
//                   this.loading = false;
//                   entRes = res;
//                   this.totalCount = entRes.searchEntreprises.totalCount;
//                   this.allEnt = entRes.searchEntreprises;
//                 },
//                 (err) => {
//                   return err;
//                 }
//               )
//               .catch((reason) => this.utils.setErrorsHttp(reason));
//           } else {
//           }
//         },
//         (error) => {}
//       )
//       .catch((reason) => this.utils.setErrorsHttp(reason));
//   }
// }
// getLogo(logoFile:any){
//   // const  imageFile: FormData  = new FormData()
//   // imageFile.append('name', logoFile.name)
//   // imageFile.append('file', logoFile)
//   // this.imageFile = imageFile
//   // console.log(logoFile)
// }

getLogo(event) {
  console.log(event)
  this.files = event
  const reader = new FileReader();
  if (event) {
    const file = event[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      // this.selectedFile = reader.result;
      this.imageFile = reader.result;
    };
  } else {
    // tslint:disable-next-line:no-console
  }
}

createEntreprise(formData:FormGroup){
  if(formData.valid){
    this.loader = true
    console.log(formData.value)
    // CREER L'ADDRESS
    this.addressServ.pastAdress(new CreateAdress(formData.value.pay, false, "", formData.value.quartier, formData.value.ville)).then(
      (res:any)=>{
        console.log('create address ok', res)
         // CREER L'ENTREPRISE
        this.entService.createEntreprise(new Entreprise(false,"",formData.value.email,"",this.imageFile? this.imageFile : "", formData.value.socialRaison, formData.value.siteWeb, formData.value.tel, res.data.createAdresse.adresse.id, formData.value.categorie) ).then(
          (res:any)=>{
            console.log('create entreprise ok', res)
            console.log ('profile ent', this.userEntreprise)
           // METTRE A JOUR LE PROFILE EN LIANT UTILISATEUR A L'ENTREPRISE
            this.profileServ.NewUpdateProfile(new UpdateProfile(
              this.userEntreprise.profile.id,
              formData.value.categorie,
              "",
              this.userEntreprise.profile.contact,
              this.userEntreprise.profile.dateNaissance,
              false,
              "",
              res.data.createEntreprise.entreprise.id,
              "",
              this.userEntreprise.profile.genre,
              this.userEntreprise.profile.nom,
              "",
              "",
              // this.userEntreprise.profile.photo,
              this.userEntreprise.profile.prenom,
              false,
              this.userEntreprise.id,
              "",
            )).then(
              (eres: any) => {
                console.log('update profile', eres)


                
                this.loader = false;
                this.userEntreprise.profile = eres.data.updateProfile.profile;
                let unlessTof = JSON.parse(JSON.stringify(this.userEntreprise));
                sessionStorage.entreprise = this.utils.encrypt(JSON.stringify(eres.data.updateProfile.profile.entreprise));
             
                this.utils.setCookie("_wefly_",this.utils.encrypt(JSON.stringify(unlessTof)),{ nb: 7, unite: 3 });
                this.router.navigate(["/wegeo/"]);
                $("#entreprisePop").modal("hide");
                this.utils.snack("Votre entrprise à été créée avec succès!");
              },
              (err) => {
                console.log('erreur update profile', err)
                this.loader = false;
              }
            )
            .catch((reason) => this.utils.setErrorsHttp(reason));

            this.snackbar.open("Entreprise créée avec succès", "Ok", {
              duration: 8000,
            });
            //this.entrepriseForm.reset();

          },
        (err) => {
          this.snackbar.open(
            "Désolé un problème est survenu, \nveuillez réessayer plus tard",
            "Ok",
            { duration: 5000 }
          );
         console.log('err entreprise', err)
         this.loader = false
        }
        )


      },(err:any)=>{
        console.log('err create address', err)
        this.loader = false
        this.snackbar.open(
          "Désolé un problème est survenu, \nveuillez réessayer plus tard",
          "Ok",
          { duration: 5000 }
        );
      }
    )

  }else{
    this.alertField = true
  }
 
}




}

