import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class VarieteParCultureService {

  constructor(private utils: UtilsService) { }

  create(culture: String, deleted: Boolean, description: String, libelle: String, ) {
    const query = `
    mutation(
		$culture: ID!,
		$deleted: Boolean,
		$description: String,
		$libelle: String,

	) {
      createVarieteParCulture(newVarieteparculture: {
			culture: $culture,
			deleted: $deleted,
			description: $description,
			libelle: $libelle,

		}) {
        varieteparculture {
          id
					culture{ id, libelle },
					deleted,
					description,
					libelle,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {culture,
					deleted,
					description,
					libelle,
					}).then(
          (res) => {
            if (res['createVarieteParCulture']['ok']) {
              resolve(res['createVarieteParCulture']['varieteparculture'])
            }
            reject(res['createVarieteParCulture']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, culture: String, deleted: Boolean, description: String, libelle: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$culture: ID!,
		$deleted: Boolean,
		$description: String,
		$libelle: String,
		) {
      updateVarieteParCulture(newVarieteparculture: {
				id: $id, culture: $culture,
			deleted: $deleted,
			description: $description,
			libelle: $libelle,
			}) {
        varieteparculture {
          id
					culture{ id, libelle},
					deleted,
					description,
					libelle,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, culture,
					deleted,
					description,
					libelle,
					}).then(
          (res) => {
            if (res['updateVarieteParCulture']['ok']) {
              resolve(res['updateVarieteParCulture']['varieteparculture'])
            }
            reject(res['updateVarieteParCulture']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchVarieteParCultures (${filter}){
        results(page:` + page +`, ordering:"` + order + `") {
          id
					culture{ id, libelle },
					deleted,
					description,
					libelle,
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res: any) => {
            resolve(res.searchVarieteParCultures.results);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

