import { Component, OnInit, NgModule } from '@angular/core';
import { FonctionService } from '../_services/fonction.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import 'jquery';
import 'bootstrap';
import { UtilsService } from '../_services/utils.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../_services/user.service';
import { DeletedComponent } from '../_modale/deleted/deleted.component';
import { MatDialog } from '@angular/material';
import { NewAlertConfirmComponent } from 'src/ui/new-alert-confirm/new-alert-confirm.component';
import { LoadingComponent } from '../@wegeo/_modale/loading/loading.component';
@NgModule({
})

@Component({
  selector: 'app-fonction',
  templateUrl: './fonction.component.html',
  styleUrls: ['./fonction.component.css']
})
export class FonctionComponent implements OnInit {

  fields_selected_: Array<any> = [
    { libelle_db: "libelle_Icontains", libelle: "Libelle" },
    { libelle_db: "description_Icontains", libelle: "Description" }
  ]

  // filter: any = {
  //   field: null,
  //   value: null
  // }

  isSelected:boolean = true
  isSelectedDec:boolean = true

  filter:string

  loading: boolean = true
  action: number = 0

  fonctionForm: FormGroup;
  submitted: boolean = false;
  fonctions: any = [];
  update: boolean = false;
  entreprise: any = {};
  fonctionId:string


  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;
  userInfo: any;
  alert:boolean = false

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private fonctionService: FonctionService,
    private utils: UtilsService,
    private user: UserService,
    public dialog: MatDialog
  ) { 
    this.fonctionForm = this.formBuilder.group({
      'libelle': ['', [Validators.required, Validators.minLength(3)]],
      'description': ['', Validators.required]
    })
  }

  ngOnInit() {
    this.userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')))
    this.readAllFonction();
  
  }

  get f() { return this.fonctionForm.controls; }

  createFonction(formData:FormGroup) {
    if(formData.valid){
      if(!this.update){
        // création
        this.submitted = true
        this.fonctionService.create(formData.value.description, this.userInfo.profile.entreprise.id, formData.value.libelle).then(
          (res) => {
            this.submitted = false;
            this.snackBar.open('Enregistré', 'Ok', { duration: 3000 });
            this.fonctions.results.unshift(res);
            this.fonctionForm.reset()
            this.update = false
            this.alert = false
          },
          (error) => {
             this.submitted = false
            this.snackBar.open('Erreur d\'enregistrement', 'Ok', { duration: 3000 });
          }
        ).catch(reason =>  this.utils.setErrorsHttp(reason));


      }else{

        // modification
        if(formData.valid){
          this.submitted = true
          this.fonctionService.update(this.fonctionId, formData.value.description, this.userInfo.profile.entreprise.id, formData.value.libelle, false).then(
            (res: any) => {
              this.submitted = false
              this.fonctions.results.forEach((elt, i) => {
                if (elt.id == res.id) {
                  this.fonctions.results[i] = res;
                }
              });
              this.snackBar.open('Modifié', 'Ok', { duration: 3000 });
              this.fonctionForm.reset()
              this.update = false
              this.alert = false
            },
            (error) => {
              this.submitted = false
              this.snackBar.open('Erreur de modification', 'Ok', { duration: 3000 });
            }
          ).catch(reason =>  this.utils.setErrorsHttp(reason))

        }else{
          this.alert = false
        }
   
        
      }

    }else{
      this.alert = true
    }

  }

  readAllFonction(page: number = 1, filter_field: any = null) {
    this.loading = true;
    let filter = `entreprise : "${this.userInfo.profile.entreprise.id}", deleted : false`;
    if(filter_field != null && filter_field.field != null){
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`;
    }
    this.fonctionService.readAllFonction(filter, page).then(
      (res: any) => {
        this.fonctions = res;
        this.totalCount = res.totalCount
        this.loading = false
      },
      (error) => {
        this.loading = false
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason))
  }

  edit(fonctionData: any) {
    this.fonctionForm = this.formBuilder.group({
      'libelle':[fonctionData.libelle,Validators.required],
      'description':[fonctionData.description, Validators.required]
    })
    this.fonctionId = fonctionData.id
    this.update = true
  }
 

  onChangePage(data) {
    this.currentPage = data.id;
    this.readAllFonction(data.id)
  }

  filterData(filter:string) {
    this.filter = filter
    // if (data.action === 0) {
    //   return
    //   this.filter = data.data_
    // } else {
    //   this.loading = true
    //   this.filter = data.data_
    //   if (data.data_.value.length == 0) {
    //     this.readAllFonction(1)
    //   } else {
    //     this.readAllFonction(1, this.filter)
    //   }
    // }
  }

  openDeletedDialog(d : any): void {
    const dialogRef = this.dialog.open(NewAlertConfirmComponent, {
      width: '35rem',
      data:{title:"Suppression fonction ", message:`Vous êtes sur le point de supprimer la fonction "" ${d.libelle} "". Voulez-vous continuer ?` }
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result === true){
        this.openLoadingDialog({
          msg: "Veuillez patienter s'il vous plaît.",
          loader: true,
        });
       
        this.fonctionService.update(d.id, d.description, this.userInfo.profile.entreprise.id, d.libelle, true).then(
          (res: any) => {
            console.log('res supp', res)
            this.fonctions.results.forEach((elt, i) => {
              if (elt.id == res.id) {
                this.fonctions.results.splice(i, 1);
              }
            });
          this.closeLoadingDialog()
            this.snackBar.open('Suppression effectuée avec succès', 'Ok', { duration: 3000 });
          },
          (error) => {
            console.log('erreur sup' , error)
            this.closeLoadingDialog()
            this.snackBar.open('Erreur de suppression', 'Ok', { duration: 3000 });
          }
        ).catch(reason =>  this.utils.setErrorsHttp(reason))
      }else{
      }
    });
  }

  croissant() {
    this.isSelected = !this.isSelected
    if(!this.isSelected){
      this.isSelectedDec = true
      this.utils.OrdrecroisantArray(this.fonctions.results, 'libelle')
    }
  }


  decroissant() {
    this.isSelectedDec = !this.isSelectedDec
    if(!this.isSelectedDec){
      this.isSelected = true
      this.utils.ordreDecrossantArray(this.fonctions.results, 'libelle')
      
    }
  }


  public openLoadingDialog(d = null, id = "loaderTrackingItem"): void {
    const dialogRef = this.dialog.open(LoadingComponent, {
      width: "500px",
      id: id,
      data: d,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  public closeLoadingDialog(id = "loaderTrackingItem"): void {
    try {
      this.dialog.getDialogById(id).close();
    } catch (error) {}
  }
}

