import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class OrganisationActeurPersService {

  constructor(private utils: UtilsService) { }

  create(acteur: String, appartenanceCoop: String, dateEntreeCoop: Number, deleted: Boolean, desirAppartenanceCoop: String, identMobile: String, niveauSatisfaction: String, prkwPasSatisfaisant: String, receptionEquipementAnneeEnCours: Boolean, receptionIntrantAnneeEnCours: String, user: String, ) {
	const dateCreationMobile = new Date()
	const query = `
    mutation(
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$appartenanceCoop: String,
		$dateEntreeCoop: Int,
		$deleted: Boolean,
		$desirAppartenanceCoop: String,
		$identMobile: String,
		$niveauSatisfaction: String,
		$prkwPasSatisfaisant: String,
		$receptionEquipementAnneeEnCours: String,
		$receptionIntrantAnneeEnCours: String,
		$user: ID,

	) {
      createOrganisationActeurPers(newOrganisationacteurpers: {
			dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			appartenanceCoop: $appartenanceCoop,
			dateEntreeCoop: $dateEntreeCoop,
			deleted: $deleted,
			desirAppartenanceCoop: $desirAppartenanceCoop,
			identMobile: $identMobile,
			niveauSatisfaction: $niveauSatisfaction,
			prkwPasSatisfaisant: $prkwPasSatisfaisant,
			receptionEquipementAnneeEnCours: $receptionEquipementAnneeEnCours,
			receptionIntrantAnneeEnCours: $receptionIntrantAnneeEnCours,
			user: $user,

		}) {
        organisationacteurpers {
          id
					dateCreationMobile,
					acteur{ id },
					appartenanceCoop,
					dateEntreeCoop,
					deleted,
					desirAppartenanceCoop,
					identMobile,
					niveauSatisfaction,
					prkwPasSatisfaisant,
					receptionEquipementAnneeEnCours,
					receptionIntrantAnneeEnCours,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {dateCreationMobile,
					acteur,
					appartenanceCoop,
					dateEntreeCoop,
					deleted,
					desirAppartenanceCoop,
					identMobile,
					niveauSatisfaction,
					prkwPasSatisfaisant,
					receptionEquipementAnneeEnCours,
					receptionIntrantAnneeEnCours,
					user,
					}).then(
          (res) => {
            if (res['createOrganisationActeurPers']['ok']) {
              resolve(res['createOrganisationActeurPers']['organisationacteurpers'])
            }
            reject(res['createOrganisationActeurPers']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, dateCreationMobile: String, acteur: String, appartenanceCoop: Boolean, dateEntreeCoop: Number, deleted: Boolean, desirAppartenanceCoop: Boolean, identMobile: String, niveauSatisfaction: String, prkwPasSatisfaisant: String, receptionEquipementAnneeEnCours: String, receptionIntrantAnneeEnCours: Boolean, user: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$appartenanceCoop: Boolean,
		$dateEntreeCoop: Int,
		$deleted: Boolean,
		$desirAppartenanceCoop: Boolean,
		$identMobile: String,
		$niveauSatisfaction: String,
		$prkwPasSatisfaisant: String,
		$receptionEquipementAnneeEnCours: Boolean,
		$receptionIntrantAnneeEnCours: Boolean,
		$user: ID,
		) {
      updateOrganisationActeurPers(newOrganisationacteurpers: {
				id: $id, dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			appartenanceCoop: $appartenanceCoop,
			dateEntreeCoop: $dateEntreeCoop,
			deleted: $deleted,
			desirAppartenanceCoop: $desirAppartenanceCoop,
			identMobile: $identMobile,
			niveauSatisfaction: $niveauSatisfaction,
			prkwPasSatisfaisant: $prkwPasSatisfaisant,
			receptionEquipementAnneeEnCours: $receptionEquipementAnneeEnCours,
			receptionIntrantAnneeEnCours: $receptionIntrantAnneeEnCours,
			user: $user,
			}) {
        organisationacteurpers {
          id
					dateCreationMobile,
					acteur{ id },
					appartenanceCoop,
					dateEntreeCoop,
					deleted,
					desirAppartenanceCoop,
					identMobile,
					niveauSatisfaction,
					prkwPasSatisfaisant,
					receptionEquipementAnneeEnCours,
					receptionIntrantAnneeEnCours,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, dateCreationMobile,
					acteur,
					appartenanceCoop,
					dateEntreeCoop,
					deleted,
					desirAppartenanceCoop,
					identMobile,
					niveauSatisfaction,
					prkwPasSatisfaisant,
					receptionEquipementAnneeEnCours,
					receptionIntrantAnneeEnCours,
					user,
					}).then(
          (res) => {
            if (res['updateOrganisationActeurPers']['ok']) {
              resolve(res['updateOrganisationActeurPers']['organisationacteurpers'])
            }
            reject(res['updateOrganisationActeurPers']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchOrganisationActeurPers (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					dateCreationMobile,
					acteur{ id },
					appartenanceCoop,
					dateEntreeCoop,
					deleted,
					desirAppartenanceCoop,
					identMobile,
					niveauSatisfaction,
					prkwPasSatisfaisant,
					receptionEquipementAnneeEnCours,
					receptionIntrantAnneeEnCours,
					user{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

