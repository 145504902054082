import { MatExpansionPanel } from "@angular/material";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from "@angular/core";
import { UtilsService } from "../_services/utils.service";
import { EntrepriseService } from "../_services/entreprise.service";
import { Router } from "@angular/router";
import { UserService } from "../_services/user.service";

/**
 * Node for to-do item
 */

@Component({
  selector: "app-filtre",
  templateUrl: "./filtre.component.html",
  styleUrls: ["./filtre.component.scss"],
})
export class FiltreComponent implements OnInit {
  user = null;
  entreprises: any = {};
  selectedId = null;
  entrepriseSelected = null;
  expansionStatus = null;
  entrepriseAffiche;
  @ViewChild("panel1", { static: false }) firstPanel: MatExpansionPanel;
  @Output() filterParam: EventEmitter<any> = new EventEmitter<any>();
  @Input() basRoute: string;
  constructor(
    private utils: UtilsService,
    private entrepriseService: EntrepriseService,
    private router: Router,
    private userService: UserService

  ) { }
  ngOnInit() {
  
     
      this.user = JSON.parse(this.utils.decrypt(this.utils.getCookie("_wefly_"))); 
      this.getEntrepriseChildren();
    
   

    // this.userService.searchUser().then(
    //   (res: any) => {
    //     this.user = JSON.parse(this.utils.decrypt(this.utils.getCookie("_wefly_"))); 
    //     this.getEntrepriseChildren();

    //   }
    // )

  }

  getEntrepriseChildren() {
    // let filter = `deleted : false, id : "${this.user.profile.entreprise.id}"`
    const filter = `id : "${this.user.profile.entreprise.id}", deleted : false`;
    this.entrepriseService.read(filter).then(
        (res: any) => {
          this.entreprises = res["searchEntreprise"]["results"][0];
          sessionStorage.entreprise = this.utils.encrypt(
            JSON.stringify(this.entreprises)
          );
          this.entrepriseSelected = this.entreprises.raisonSociale;
        },
        (error) => {
          console.error(error);
        }
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }

  Clicked(entreprise) {
    sessionStorage.entreprise = this.utils.encrypt(JSON.stringify(entreprise));
    this.selectedId = entreprise.id;
    this.entrepriseSelected = entreprise.raisonSociale;
    this.filterParam.emit(entreprise);
  }
}
