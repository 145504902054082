import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class FamilleDeCultureService {

  constructor(private utils: UtilsService) { }

  create(deleted: Boolean, description: String, displayName: String, libelle: String, ) {
    const query = `
    mutation(
		$deleted: Boolean,
		$description: String,
		$displayName: String,
		$libelle: String,

	) {
      createFamilleDeCulture(newFamilledeculture: {
			deleted: $deleted,
			description: $description,
			displayName: $displayName,
			libelle: $libelle,

		}) {
        familledeculture {
          id
					deleted,
					description,
					displayName,
					libelle,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {deleted,
					description,
					displayName,
					libelle,
					}).then(
          (res) => {
            if (res['createFamilleDeCulture']['ok']) {
              resolve(res['createFamilleDeCulture']['familledeculture'])
            }
            reject(res['createFamilleDeCulture']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, deleted: Boolean, description: String, displayName: String, libelle: String, ) {
    const query = `
    mutation(
		$id: UUID!,
		$deleted: Boolean,
		$description: String,
		$displayName: String,
		$libelle: String,
		) {
      updateFamilleDeCulture(newFamilledeculture: {
      id: $id,
      deleted: $deleted,
			description: $description,
			displayName: $displayName,
			libelle: $libelle,
			}) {
        familledeculture {
          id
					deleted,
					description,
					displayName,
					libelle,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, deleted,
					description,
					displayName,
					libelle,
					}).then(
          (res) => {
            if (res['updateFamilleDeCulture']['ok']) {
              resolve(res['updateFamilleDeCulture']['familledeculture'])
            }
            reject(res['updateFamilleDeCulture']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchFamilleDeCultures(${filter}){
        results(page:` + page +`, ordering:"` + order + `") {
          id
					deleted,
					description,
					displayName,
					libelle,
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res:any) => {
            resolve(res.searchFamilleDeCultures.results);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

