import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class TrackService {

  constructor(private utils: UtilsService) { }

  create(deleted: Boolean, jourDeTrack: String, periode: String, user: String, ) {
    const query = `
    mutation(
      $deleted: Boolean,
      $jourDeTrack: CustomDateTime,
      $periode: ID,
      $user: ID,
  	) {
      createTracks(newTracks: {
			deleted: $deleted,
			jourDeTrack: $jourDeTrack,
			periode: $periode,
			user: $user,
		}) {
      tracks {
        id
				deleted,
				jourDeTrack,
				periode{ id, libelle },
				user{ id },
				createdAt
				updateAt
			}
      errors {
        messages
        field
      }
      ok
    }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          deleted,
          jourDeTrack,
          periode,
          user,
        }).then(
          (res) => {
            // console.log("create",res);

            if (res['createTracks']['ok']) {
              resolve(res['createTracks']['tracks'])
            }
            reject(res['createTracks']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, deleted: Boolean, jourDeTrack: String, periode: String, user: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$deleted: Boolean,
		$jourDeTrack: CustomDateTime,
		$periode: ID,
		$user: ID,
		) {
      updateTracks(newTracks: {
				id: $id, deleted: $deleted,
			jourDeTrack: $jourDeTrack,
			periode: $periode,
			user: $user,
			}) {
        tracks {
          id
					deleted,
					jourDeTrack,
					periode{ id, libelle },
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id, deleted,
          jourDeTrack,
          periode,
          user,
        }).then(
          (res) => {
            // console.log("updateTracks",res);

            if (res['updateTracks']['ok']) {
              resolve(res['updateTracks']['tracks'])
            }
            reject(res['updateTracks']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    const query = `
    query {
      searchTracks (${filter}){
        results(page:` + page + `, ordering:"` + order + `") {
          id
					deleted,
					jourDeTrack,
					periode{ id, libelle },
					user{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res: any) => {
            // console.log("search",res);

            resolve(res.searchTracks);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

