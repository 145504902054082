import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class TrackItemService {
  constructor(private utils: UtilsService) { }
  create(date: String, deleted: Boolean, latitude: Number, longitude: Number, tracks: String, ) {
    const query = `
    mutation(
		$date: CustomDateTime,
		$deleted: Boolean,
		$latitude: Float,
		$longitude: Float,
		$tracks: ID,
	) {
      createTrackItem(newTrackitem: {
			date: $date,
			deleted: $deleted,
			latitude: $latitude,
			longitude: $longitude,
			tracks: $tracks,
		}) {
        trackitem {
          id
					date,
					deleted,
					latitude,
					longitude,
					tracks{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          date,
          deleted,
          latitude,
          longitude,
          tracks,
        }).then(
          (res) => {
            if (res['createTrackItem']['ok']) {
              resolve(res['createTrackItem']['trackitem'])
            }
            reject(res['createTrackItem']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, date: String, deleted: Boolean, latitude: Number, longitude: Number, tracks: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$date: CustomDateTime,
		$deleted: Boolean,
		$latitude: Float,
		$longitude: Float,
		$tracks: ID,
		) {
      updateTrackItem(newTrackitem: {
				id: $id, date: $date,
			deleted: $deleted,
			latitude: $latitude,
			longitude: $longitude,
			tracks: $tracks,
			}) {
        trackitem {
          id
					date,
					deleted,
					latitude,
					longitude,
					tracks{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id, date,
          deleted,
          latitude,
          longitude,
          tracks,
        }).then(
          (res) => {
            if (res['updateTrackItem']['ok']) {
              resolve(res['updateTrackItem']['trackitem'])
            }
            reject(res['updateTrackItem']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string, page: number = 1, order: String = '-created_at') {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    const query = `
    query {
      searchTracksItem (${filter}){
        totalCount
        results(page:` + page + `, ordering:"` + order + `") {
          id
					date,
					deleted,
					latitude,
					longitude,
					tracks {
            id,
            user {
              id
              profile {
                id
                nom
                prenom
              }
            }
            createdAt
          },
					createdAt
					updateAt
				}
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res['searchTracksItem']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

