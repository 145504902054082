import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";

@Injectable({
  providedIn: "root"
})
export class CultureService {
  constructor(private utils: UtilsService) {}

  create(
    familleCulture: String,
    deleted: Boolean,
    description: String,
    libelle: String
  ) {
    const query = `
    mutation(
		$familleCulture: ID!,
		$deleted: Boolean,
		$description: String,
		$libelle: String,

	) {
      createCulture(newCulture: {
			familleCulture: $familleCulture,
			deleted: $deleted,
			description: $description,
			libelle: $libelle,

		}) {
        culture {
          id
					familleCulture{ id },
					deleted,
					description,
					libelle,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, { familleCulture, deleted, description, libelle })
        .then(
          res => {
            if (res["createCulture"]["ok"]) {
              resolve(res["createCulture"]["culture"]);
            }
            reject(res["createCulture"]["errors"]);
          },
          err => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
    });
  }

  update(
    id: String,
    familleCulture: String,
    deleted: Boolean,
    description: String,
    libelle: String
  ) {
    const query = `
    mutation(
			$id: UUID!,
		$familleCulture: ID!,
		$deleted: Boolean,
		$description: String,
		$libelle: String,
		) {
      updateCulture(newCulture: {
				id: $id, familleCulture: $familleCulture,
			deleted: $deleted,
			description: $description,
			libelle: $libelle,
			}) {
        culture {
          id
					familleCulture{ id },
					deleted,
					description,
					libelle,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, { id, familleCulture, deleted, description, libelle })
        .then(
          res => {
            if (res["updateCulture"]["ok"]) {
              resolve(res["updateCulture"]["culture"]);
            }
            reject(res["updateCulture"]["errors"]);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  read(
    filter: string = "deleted: false",
    page: number = 1,
    order: String = "-created_at"
  ) {
    filter = filter == null || filter == "" ? "deleted: false" : filter;
    const query =
      `
    query {
      searchCultures (${filter}){
        results(page:` +
      page +
      `, ordering:"` +
      order +
      `") {
          id
					familleCulture{ id },
					deleted,
					description,
					libelle,
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query).then(
        (res:any) => {
          resolve(res.searchCultures.results);
        },
        (err:any) => {
          reject(err);
        }
      );
    });
  }
}
