import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UtilsService } from "src/app/_services/utils.service";
declare var L: any;
import drawLocales from "leaflet-draw-locales";
import { Observable } from "rxjs";





@Component({
  selector: "app-leaflet-sample",
  templateUrl: "./leaflet-sample.component.html",
  styleUrls: ["./leaflet-sample.component.scss"],
})
export class LeafletSampleComponent implements OnInit {
  @Input() geojson: any;
  @Input() geojsonDetails: any;
  @Output() polyDraw = new EventEmitter();
  @Output() markerDraw = new EventEmitter()



  


  constructor(private u: UtilsService,) {}

  ngOnInit() {

    let map = this.u.callMap();

    if (this.geojson != null) {
      let clusterZone = L.markerClusterGroup();
      // console.log('geojson', this.geojson)
      this.geojson.reverse().forEach((elt:any) => {
  
        if (elt.zoneDelim !== undefined) {
          let a = L.geoJSON(JSON.parse(elt.zoneDelim), {
            onEachFeature: (feature, layer) => {
              layer.bindPopup(
                `<div class="my"> <h6 class="greenColor">${elt.libelle}</h6> <p class="blackColor"></p> <div>`,
                { className: "custom-pop-up-plantation" }
              );

              // layer.on("click", function (e, argThisPrime = argThis) {
              // argThisPrime.readProprioPlantation(elt.idSuperieur);
              // });
            },
          });
          clusterZone.addLayer(a);
          map.addLayer(clusterZone);
          map.fitBounds(clusterZone.getBounds());
        }
      });
    } else {
    }

    map.on("measurefinish", function (evt) {
      writeResults(evt);
    });

    function writeResults(results) {
      document.getElementById("eventoutput").innerHTML = JSON.stringify(
        {
          area: results.area,
          areaDisplay: results.areaDisplay,
          lastCoord: results.lastCoord,
          length: results.length,
          lengthDisplay: results.lengthDisplay,
          pointCount: results.pointCount,
          points: results.points,
        },
        null,
        2
      );
    }

    var editableLayers = new L.FeatureGroup();

    // Initialise the draw control and pass it the FeatureGroup of editable layers
    var drawControl = new L.Control.Draw({
      position: "topright",
      draw: {
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          drawError: {
            color: "#e1e100", // Color the shape will turn when intersects
            message: "<strong>Oh snap!<strong> you can't draw that!", // Message that will show when intersect
          },
          shapeOptions: {
            color: "#97009c",
          },
        },
        // disable toolbar item by setting it to false
        polyline: false,
        circle: false, // Turns off this drawing tool
        rectangle: false,
        marker: false,
        circleMarker: false,
      },
      edit: {
        featureGroup: editableLayers, //REQUIRED!!
        remove: true,
      },
    });
    map.addControl(drawControl);

    map.addLayer(editableLayers);

    map.on("draw:created", (e) => {
      const type = e.layerType;
      const layer = e.layer;
      console.log('type', type)

      if (type === "circlemarker") {
        
        layer.bindPopup("A popup!");
        const layerMarker = e.layer
        editableLayers.addLayer(layerMarker);
        const markerPolygone = editableLayers.toGeoJSON();
        this.markerDraw.emit(markerPolygone)
        
      }

      if (type === "polygon") {
        // tslint:disable-next-line:no-shadowed-variable
        const layer = e.layer;
        editableLayers.addLayer(layer);
        const polygon = editableLayers.toGeoJSON();
        this.polyDraw.emit(polygon);
      }
      editableLayers.addLayer(layer);
    });

    const locale = drawLocales("fr");
    locale.draw.toolbar.buttons.polygon = "Awesome polygon!";
    L.drawLocal = locale;
  }
}
