import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActeurService } from 'src/app/_services/acteurs.service';


@Component({
  selector: 'app-delete-plantations',
  templateUrl: './delete-plantations.component.html',
  styleUrls: ['./delete-plantations.component.scss']
})
export class DeletePlantationsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeletePlantationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private acteurService : ActeurService
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  okSupprimer(){
  
    this.acteurService.deleted(this.data.id).then(
      (res : any) => {
       
      },
      (error) => {
        // console.error(error)
      }
    )
  }

}
