import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { Apollo, QueryRef } from 'apollo-angular';
import gql from 'graphql-tag';
import { EmployeStatRead } from '../_models/user';

const SEARCH_STAT = gql`
query($employe:ID, $entreprise:UUID) {
  searchStatsemployes (
    employe:$employe,
    entreprise:$entreprise,
    deleted:false
  ){
    totalCount
    results(ordering:"-created_at") {
      id
      nbrePlanteurs
      nbrePlantations
      nbrePlantationsNotees
      supTotale
      createdAt
      updateAt
    }
  }
}
`;



@Injectable({
  providedIn: 'root'
})
export class StatisitiquesService {

  constructor(private utils: UtilsService, private apollo:Apollo) { }

  readPlantation(entreprise:string , page: number = 1, order: String = '-created_at') {
  
    const query = `
    query {
      searchStatsplantation (`+ entreprise +`, deleted:false){
        totalCount
        results(page:` + page + `, ordering:"` + order + `") {
          id
					nbrePlantations
          supMax
          supMax
          supMoy
          superficieTotale
          nbrePlantationsFc
          prcentageFc
					createdAt
					updateAt
          deleted
				}
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res['searchStatsplantation']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  readPlanteur(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    const query = `
    query {
      searchStatsplanteur (${filter}){
        totalCount
        results(page:` + page + `, ordering:"` + order + `") {
          id
          nbreH
          nbreF
          prcentageF
          prcentageH
          nbrePlanteurs
          createdAt
          updateAt
				}
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res['searchStatsplanteur']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  getRepartitioncultures(page:number | null, entreprise:string){
    return this.apollo
          .query({
            query:gql`
            query($entreprise:UUID, $page:Int) {
              searchRepartitioncultures (entreprise:$entreprise,deleted:false){
                totalCount
                results(page:$page, ordering:"-created_at") {
                  id
                  piechart
                  barchart
                  createdAt 
                  updateAt
                }
              }
            }
            `,
            variables:{page:page, entreprise:entreprise}
          }) as QueryRef<any> | any
  }
  readRepartitionculturesNew(page:number | null, entreprise:string){
    return new Promise((resolve, reject)=>{
      this.getRepartitioncultures(page,entreprise).subscribe(
        (res:any)=>{
          resolve(res)
        },(err:any)=>{
          reject(err)
        }
      )
    })
   
  }

  // readRepartitioncultures(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
  //   filter = (filter == null || filter == '') ? 'deleted: false' : filter;
  //   const query = `
  //   query {
  //     searchRepartitioncultures (${filter}){
  //       totalCount
  //       results(page:` + page + `, ordering:"` + order + `") {
  //         id
  //         piechart
  //         barchart
  //         createdAt 
  //         updateAt
	// 			}
  //     }
  //   }
  //   `;
  //   return new Promise(
  //     (resolve, reject) => {
  //       this.utils.client.request(query).then(
  //         (res) => {
  //           resolve(res['searchRepartitioncultures']);
  //         }, (err) => {
  //           this.utils.setErrorsHttp(err)
  //           reject(err);
  //         }
  //       );
  //     }
  //   );
  // }

  readCoutLocalmp(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    const query = `
    query {
      searchCourslocalmp (${filter}){
        totalCount
        results(page:` + page + `, ordering:"` + order + `") {
          id
          detailLocal
          culture {
            id
            libelle
          }
          sourceInfo
          createdAt
          updateAt
				}
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res['searchCourslocalmp']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  readCoursintermp(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    const query = `
    query {
      searchCoursintermp (${filter}){
        totalCount
        results(page:` + page + `, ordering:"` + order + `") {
          detailsInter
          id
          deleted
          createdAt
          updateAt
          sourceInfo
				}
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res['searchCoursintermp']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  getEmployeStat(readStat:EmployeStatRead){
     const {employe, entreprise} = readStat
     return this.apollo.watchQuery<any>({
      query:SEARCH_STAT,
      variables:{employe, entreprise}
     }) as QueryRef<any>
  }
  NewReadStatsemployes(readStat:EmployeStatRead){
    return new Promise((resolve, reject)=>{
      this.getEmployeStat(readStat).valueChanges.subscribe((res:any)=>{
        resolve(res)
      }, (err:any)=>{
        reject(err)
      })
    })

  }


  readStatsemployes(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    const query = `
    query {
      searchStatsemployes (${filter}){
        totalCount
        results(page:` + page + `, ordering:"` + order + `") {
          id
          nbrePlanteurs
          nbrePlantations
          nbrePlantationsNotees
          supTotale
          createdAt
          updateAt
				}
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res['searchStatsemployes']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }


}
