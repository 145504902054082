import { UniteDeMesureService } from './../../../_services/unite-mesure.service';
import { VarieteParCultureService } from './../../../_services/variete-par-culture.service';
import { CultureService } from './../../../_services/culture.service';
import { FamilleDeCultureService } from './../../../_services/famille-de-culture.service';
import { UtilsService } from '../../../_services/utils.service';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseService } from '../../_services/base.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { CREATE_STOCK, READ_VARIETE_CULTURE } from 'src/environments/environment';

@Component({
  selector: 'app-form-stock',
  templateUrl: './form-stock.component.html',
  styleUrls: ['./form-stock.component.scss']
})
export class FormStockComponent implements OnInit {
  @Input() formType: string;
  stockForm: FormGroup;
  cultures: Array<any>;
  fields = [];
  requesting = false;
  constructor(private formBuilder : FormBuilder, private baseService : BaseService,
              public dialogRef: MatDialogRef<FormStockComponent>, public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private util: UtilsService,
              private familleService: FamilleDeCultureService,
              private cultureService: CultureService,
              private varieteService: VarieteParCultureService,
              private uniteService: UniteDeMesureService) { }

  ngOnInit() {
 
    this.initStockForm()
    this.readCulture()
  }

  initStockForm() {
    if (this.data.formType === 'Vendre') {
      this.stockForm = this.formBuilder.group({
      'libelle' : ['', Validators.required],
      'description' : ['', Validators.required],
      'culture' : ['', Validators.required],
      'status' : [true],
      'nature' : ['', Validators.required],
      'qteDispo' : ['', Validators.required],
      'prixUnitaire' : [5000, Validators.required]
    });
    } else {
      this.stockForm = this.formBuilder.group({
        'libelle': ['', Validators.required],
        'description': ['', Validators.required],
        'culture': ['', Validators.required],
        'status': [true],
        'nature': ['', Validators.required],
        'qteDispo': ['', Validators.required],
        'prixUnitaire': [5000, Validators.required]
      });
    }
  }

  readCulture(){
    this.baseService.read(READ_VARIETE_CULTURE, {}).then(
      (res : any[])  => {
        this.cultures = res['searchVarieteParCultures']['results']
      },
      (error)  => {
        console.error(error)
      }
    )
  }

  onSubmit(){

    if(this.stockForm.invalid){
      console.error(this.stockForm.errors)
      return
    }
    this.baseService.create(CREATE_STOCK, this.stockForm.value).then(
      (res) => {
       
        this.dialog.closeAll()
      },
      (error) => {
        console.error(error)
      }
    )
  }

  submitFamilleCulture(f) {
    this.fields = this.util.formIsNotNull(f, ['libelle', 'description']);
    if (this.fields.length === 0) {
      this.requesting = true;
      // Si mise à jour famille culture
      if (this.data.instance.id) {
        this.familleService.update(this.data.instance.id, false,
          f.value.description, '', f.value.libelle).then(
          (res) => {
            this.dialogRef.close(res);
         
          },
          (err) => {
            this.requesting = false;
           
          }
        );
      } else {
        // Sinon création
        this.familleService.create(false, f.value.description, '', f.value.libelle).then(
          (res) => {
            this.dialogRef.close(res);
          
          },
          (err) => {
            this.requesting = false;
         
          }
        )
      }
    }
  }

  submitCulture(f) {
  
    this.fields = this.util.formIsNotNull(f, ['libelle', 'description', 'familleCulture']);
    if (this.fields.length === 0) {
      this.requesting = true;
      if (this.data.instance.id) {
         this.cultureService.update (
           this.data.instance.id,
           f.value.familleCulture,
           false,
           f.value.description,
           f.value.libelle
         ).then(
           (res) =>{
             this.requesting = false;
             this.dialogRef.close(res);
           },
           (err) =>{
             this.requesting = false;
             this.util.snack('Une erreur est survenue veuillez reéssayer');
             this.util.setErrorsHttp(err);
           }
         );
      } else {
        this.cultureService.create(f.value.familleCulture, false, f.value.description, f.value.libelle).then(
          (res) => {
            this.requesting = false;
            this.dialogRef.close(res);
          },
          (err) => {
            this.requesting = false;
            this.util.snack('Une erreur est survenue veuillez reéssayer');
            this.util.setErrorsHttp(err);
          }
        );
      }
    }
  }

  submitVarieteCulture(f) {
    this.fields = this.util.formIsNotNull(f, ['libelle', 'description', 'culture']);
    if (this.fields.length === 0) {
      this.requesting = true;
      if (this.data.instance) {
        this.varieteService.update(
          this.data.instance.id, f.value.culture, false, f.value.description,
           f.value.libelle).then(
          (res) => {
            this.requesting = false;
            this.dialogRef.close(res);
          },
          (err) => {
            this.requesting = false;
            
          }
        );
      } else {
        this.varieteService.create(f.value.culture, false, f.value.description, f.value.libelle).then(
          (res) => {
            this.requesting = false;
            this.dialogRef.close(res);
          },
          (err) => {
            this.requesting = false;
            
          }
        );
      }
      this.varieteService.create(f.value.culture, false, f.value.description, f.value.libelle).then(
        (res) => {
          this.requesting = false;
          this.dialogRef.close(res);
        },
        (err) => {
          this.requesting = false;
          
        }
      );
    }
  }

  submitUnite(f) {
    this.fields = this.util.formIsNotNull(f, ['libelle', 'abbreviation']);
    this.requesting = true;
    if (this.fields.length === 0) {
      if (this.data.instance.id) {
        this.uniteService.update(
          this.data.instance.id,
          f.value.abbreviation,
          false,
          f.value.description,
          f.value.libelle
        ).then(
          (res) => {
           
            this.requesting = false;
            this.dialogRef.close(res);
          },
          (err) => {
           
            this.requesting = false;
          }
        );
      } else {
        this.uniteService.create(
          f.value.abbreviation,
          false,
          f.value.description,
          f.value.libelle
        ).then(
          (res) => {
           
            this.requesting = false;
            this.dialogRef.close(res);
          },
          (err) => {
           
            this.requesting = false;
            
          }
        );
      }
    }
  }

}
