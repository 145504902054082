import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilsService } from "src/app/_services/utils.service";
import request from "graphql-request";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  token: any;
  emailUser: any;
  idUser: any;
  form = {
    value: {
      useremail: null,
      password: null,
      confirm_password: null,
    },
  };
  loader = false;
  isPassword;
  isPassword1;
  show = false;
  show1 = false;
  constructor(
    public utils: UtilsService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isPassword = "password";
    this.isPassword1 = "password";
    this.token = this.activeRoute.snapshot.queryParamMap.get("token");
    // console.log("token", this.token);
    this.tokenVerify(this.token);
  }

  onIsPassword() {
    this.show = !this.show;
    this.show === true
      ? (this.isPassword = "text")
      : (this.isPassword = "password");
  }
  onIsPassword1() {
    this.show1 = !this.show1;
    this.show1 === true
      ? (this.isPassword1 = "text")
      : (this.isPassword1 = "password");
  }
  async tokenVerify(paramsToken: String) {
    const query = `
    {
      searchForgetPassword(linkid: "${paramsToken}") {
        totalCount
        results {
          id
          userEmail
        }
      }
    }


    `;

    return new Promise((resolve, reject) => {
      request(this.utils.HOST_URL, query).then(
        (res: any) => {
          this.form.value.useremail =
            res.searchForgetPassword.results[0].userEmail;
          this.idUser = res.searchForgetPassword.results[0].id;

          // response = res;
          resolve(res);
          // if (res.confirmAcompte.response) {
          //   // setTimeout(() => {
          //   console.log("okkk");

          //   this.router.navigate(["/login"]);

          //   // }, 1500);
          // }
          // // return res.searchCouche.results[0].id;
        },
        (err) => {
          reject(err);
        }
      );
    });

    // {"id": "d2538bc9-4d70-4265-9f92-7166815be4fd"}
  }
  onSubmit() {
    const form = this.form;
    // console.log("onSubmit", form);
    this.loader = true;
    const query = `
      mutation {
        updateForgetPassword(newForgetpassword: {id:"${this.idUser}", userEmail: "${form.value.useremail}", newpassword:"${form.value.confirm_password}"}) {
          ok
        }
      }
    `;
    // console.log("query", query);
    return new Promise((resolve, reject) => {
      request(this.utils.HOST_URL, query).then(
        (res: any) => {
          // console.log("res==>", res.updateForgetPassword.ok);
          // response = res;
          resolve(res);
          if (res.updateForgetPassword.ok) {
            alert(
              "Votre mot de passe a été réinitilisation"
            );
            // console.log("okkk");
            this.loader = false;
            this.router.navigate(["/login"]);
          }
        },
        (err) => {
          reject(err);

          alert("Un soucis est survenu veuiller réessayer");
          this.loader = false;
        }
      );
    });
  }
}
