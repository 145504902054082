import { Injectable } from '@angular/core';
import { GetExcelService } from 'src/app/_services/get-excel.service';
import { UtilsService as globalUtils } from 'src/app/_services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private getExcelService: GetExcelService,
    private utils: globalUtils
  ) { }

  getExcelFile(field: Map<string, string>, couche: any, userConnected: any ){ 
    const arrays = [...field.keys()]
    return new Promise(
      (resolve, reject) => {
        this.getExcelService.getExcel(`?couche=${couche.id}&&zone=${userConnected.profile.zone.id}&&fields=${arrays.toString()}`).subscribe(
          (res: any) => {
            console.log('res get planteur excel', res)
             window.location.href = this.utils.API_URL + res.url
             resolve(res)
          }, (err) => {
            
          }
        )
      }
    )
  }
}
