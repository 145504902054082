import { Injectable } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CompositionDemandeService {

  constructor(private utils: UtilsService) { }

  create(culture: String, deleted: Boolean, demandeProduction: String, image: String, imageUrl: String, qteDemandee: Number, user: String, ) {
    const query = `
    mutation(
		$culture: ID,
		$deleted: Boolean,
		$demandeProduction: ID,
		$image: String,
		$imageUrl: String,
		$qteDemandee: Float,
		$user: ID,
	) {
      createCompositiondemande(newCompositiondemande: {
			culture: $culture,
			deleted: $deleted,
			demandeProduction: $demandeProduction,
			image: $image,
			imageUrl: $imageUrl,
			qteDemandee: $qteDemandee,
			user: $user,

		}) {
        compositiondemande {
          id
					culture{ id },
					deleted,
					demandeProduction{ id },
					image,
					imageUrl,
					qteDemandee,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
   
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          culture,
					deleted,
					demandeProduction,
					image,
					imageUrl,
					qteDemandee,
					user,
					}).then(
          (res) => {
            if (res['createCompositiondemande']['ok']) {
              resolve(res['createCompositiondemande']['compositiondemande'])
            }
            reject(res['createCompositiondemande']['errors']);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, culture: String, deleted: Boolean, demandeProduction: String, image: String, imageUrl: String, qteDemandee: Number, user: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$culture: ID,
		$deleted: Boolean,
		$demandeProduction: ID,
		$image: String,
		$imageUrl: String,
		$qteDemandee: Float,
		$user: ID,
		) {
      updateCompositiondemande(newCompositiondemande: {
				id: $id, culture: $culture,
			deleted: $deleted,
			demandeProduction: $demandeProduction,
			image: $image,
			imageUrl: $imageUrl,
			qteDemandee: $qteDemandee,
			user: $user,
			}) {
        compositiondemande {
          id
					culture{ id },
					deleted,
					demandeProduction{ id },
					image,
					imageUrl,
					qteDemandee,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, culture,
					deleted,
					demandeProduction,
					image,
					imageUrl,
					qteDemandee,
					user,
					}).then(
          (res) => {
          
            if (res['updateCompositiondemande']['ok']) {
              resolve(res['updateCompositiondemande']['compositiondemande'])
            }
            reject(res['updateCompositiondemande']['errors']);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchCompositionDemande (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					culture{ id },
					deleted,
					demandeProduction{ id },
					image,
					imageUrl,
					qteDemandee,
					user{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }
}

