import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../_services/utils.service';
import { MatSnackBar } from '@angular/material';
import { FonctionaliteService } from '../_services/fonctionalite.service';
import { EntrepriseService } from '../_services/entreprise.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-fonctionnalite-entreprise',
  templateUrl: './fonctionnalite-entreprise.component.html',
  styleUrls: ['./fonctionnalite-entreprise.component.css']
})
export class FonctionnaliteEntrepriseComponent implements OnInit {
  public fonctionnalites: any = [];
  public entreprises: any = [];
  public entreprisesJ: any = [];
  public viewFonctionnalite: String;
  public entFoncClassees: { tabEntId: String[], tabFoncId: any, tabFoncEntId: any } = { tabEntId: [], tabFoncId: [], tabFoncEntId: [] };

  constructor(private utils: UtilsService, private snackBar: MatSnackBar, private fonctionnaliteServ: FonctionaliteService, private entrepriseServ: EntrepriseService) { }

  ngOnInit() {
    this.entrepriseServ.read().then(
      (res: any) => {
        this.entreprises = res.searchEntreprises;
        this.entreprises.results = (this.entreprises.totalCount === 0 || this.entreprises.results == null) ? [] : this.entreprises.results;

        this.entFoncClassees = this.tabFonctionnalites(this.entreprises);
      }, (err) => {
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason));

    this.fonctionnaliteServ.read().then(
      (res: any) => {
        this.fonctionnalites = res;
        this.fonctionnalites.results = (this.fonctionnalites.totalCount === 0 || this.fonctionnalites.results == null) ? [] : this.fonctionnalites.results;
      }, (err) => {
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason));

  }

  public click(ent): void {
    // permet d'afficher la liste des fonctionnalité d'une entreprise donnée par un formulaire
    this.viewFonctionnalite = ent;
  }

  public isChecked(entrepriseId: String, fonctionnaliteId: String): Boolean {
    // permet de dire si oui ou non une entreprise (id) passée possède une fonctionnalitées (id) passée également en  paramètre
    if (this.entFoncClassees.tabEntId.indexOf(entrepriseId) > -1)
      if (this.entFoncClassees.tabFoncId[this.entFoncClassees.tabEntId.indexOf(entrepriseId)].indexOf(fonctionnaliteId) > -1)
        return true;
    return false;
  }

  private tabFonctionnalites(ent: any): any {
    // renvoie deux tableaux dont le premier est la liste des entreprises possédant au moins une fonctionnalité et
    // un second tableau contenant des tableaux des fonctionnalités regroupés par entreprise(selon l'index du premier tableau renvoyé)
    // visuellement ça donne: tabEntId = ['identreprise1', 'identreprise2', ..., 'identrepriseN'] et
    // tabFoncId = [
    //   ['fonctionnaliteEntreprise11', 'fonctionnaliteEntreprise12', ..., 'fonctionnaliteEntreprise1N']
    //    ...
    //    ['fonctionnaliteEntrepriseN1', 'fonctionnaliteEntrepriseN2', ..., 'fonctionnaliteEntrepriseNN']
    // ]
    let tabEntId: String[] = [];
    let tabFoncId: any[] = [];
    let tabFoncEntId: any[] = [];

    this.fonctionnaliteServ.readFonctionnaliteEntreprise().then(
      (res: any) => {
        res.results.forEach((elt, i) => {
          if (tabEntId.indexOf(elt.entreprise.id) > -1) {
            tabFoncId[tabEntId.indexOf(elt.entreprise.id)].push(elt.fonctionnalite.id);
            tabFoncEntId[tabEntId.indexOf(elt.entreprise.id)].push(elt.id);
          } else {
            tabEntId.push(elt.entreprise.id);
            tabFoncId.push([elt.fonctionnalite.id]);
            tabFoncEntId.push([elt.id]);
          }
        });;
      }, (err) => {
      }
    );

    return { tabEntId, tabFoncId, tabFoncEntId };
  }

  public onSubmit(form: NgForm): void {
    console.clear();
    let f = form.value;
    let foncA = [];
    let foncR = [];

    this.fonctionnalites.results.forEach((elt, i) => {
      if ((f[elt.id]) && (!this.isChecked(f.entrepriseId, elt.id))) {
        foncA.push(elt.id);
      } else if (!(f[elt.id]) && (this.isChecked(f.entrepriseId, elt.id))) {
        foncR.push(elt.id);
      }
    });


    foncA.forEach((elt, i) => {
      this.fonctionnaliteServ.createFonctionnaliteEntreprise(f.entrepriseId, elt).then(
        (res: any) => {
          this.entFoncClassees.tabFoncId[this.entFoncClassees.tabEntId.indexOf(f.entrepriseId)].push(elt);
          this.entFoncClassees.tabFoncEntId[this.entFoncClassees.tabEntId.indexOf(f.entrepriseId)].push(res.id);
        }, (err) => {
        }
      ).catch(reason =>  this.utils.setErrorsHttp(reason));
    });

    foncR.forEach((elt, i) => {
      if (this.entFoncClassees.tabEntId.indexOf(f.entrepriseId) > -1) {
        if (this.entFoncClassees.tabFoncId[this.entFoncClassees.tabEntId.indexOf(f.entrepriseId)].indexOf(elt) > -1) {
          console.clear();
          let id = this.entFoncClassees.tabFoncEntId[this.entFoncClassees.tabEntId.indexOf(f.entrepriseId)][this.entFoncClassees.tabFoncId[this.entFoncClassees.tabEntId.indexOf(f.entrepriseId)].indexOf(elt)];
          this.fonctionnaliteServ.updateFonctionnaliteEntreprise(id, f.entrepriseId, elt, true).then(
            (res: any) => {
              this.entFoncClassees.tabFoncId[this.entFoncClassees.tabEntId.indexOf(f.entrepriseId)].splice(elt, 1);
              this.entFoncClassees.tabFoncEntId[this.entFoncClassees.tabEntId.indexOf(f.entrepriseId)].splice(res.id, 1);
            }, (err) => {
            }
          ).catch(reason =>  this.utils.setErrorsHttp(reason));
        }
      }
    });

    
  }

  public changeTab(e): void {
    this.click(this.entreprises.results[e.index])
  }

}
