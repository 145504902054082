import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'employeFilter'
})
export class EmployePipe implements PipeTransform {

  transform(value: any[], args: string){
    if (!value) return null
    if(!args) return value

    args = args.toLowerCase();

    return value.filter((value: any) => 
    value.nom.toLowerCase().includes(args) || 
    value.prenom.toLowerCase().includes(args) || 
    value.contact.toLowerCase().includes(args) ||
    value.user.username.toLowerCase().includes(args) 
    )
  }

}
