import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilsService } from "src/app/_services/utils.service";
import request from "graphql-request";
@Component({
  selector: "app-confirme-user-register",
  templateUrl: "./confirme-user-register.component.html",
  styleUrls: ["./confirme-user-register.component.scss"],
})
export class ConfirmeUserRegisterComponent implements OnInit {
  paramsID: any;
  constructor(
    public utils: UtilsService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // this.router.queryParams.subscribe((params) => {
    //   console.log("params", params);
    //   this.emaiVerify(params)

    // });
    this.paramsID = this.activeRoute.snapshot.queryParamMap.get("id");
    // console.log("idd", this.router.snapshot.queryParamMap.get("id"));
    this.emaiVerify(this.paramsID);
  }

  async emaiVerify(paramsId: String) {
    let response;
    const query = `
    mutation($paramsId:String!){
      confirmAcompte(id:$paramsId){
        response
        ok
      }
    }

    `;

    return new Promise((resolve, reject) => {
      request(this.utils.HOST_URL, query, { paramsId }).then(
        (res: any) => {
          // console.log("res==>", res.confirmAcompte.response);
          response = res.confirmAcompte.response;
          resolve(res);
          if (res.confirmAcompte.response) {
            // setTimeout(() => {
            // console.log("okkk");

            this.router.navigate(["/login"]);

            // }, 1500);
          }
          // return res.searchCouche.results[0].id;
        },
        (err) => {
          reject(err);
        }
      );
    });

    // {"id": "d2538bc9-4d70-4265-9f92-7166815be4fd"}
  }
}
