import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DeletedComponent } from '../_modale/deleted/deleted.component';
import { SubdivisionService } from '../_services/subdivision.service';

@Component({
  selector: 'app-campement',
  templateUrl: './campement.component.html',
  styleUrls: ['./campement.component.scss']
})
export class CampementComponent implements OnInit {
  campementForm: FormGroup;
  listCampement:any;
  campementId:string;
  submitted:boolean = false;
  loading:boolean = false;
  filter:string | any = null
 

  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;
  

  fields_selected_: Array<any> = [
    { libelle_db: "libelle_Icontains", libelle: "Libelle" },
  ]


  constructor(private fb: FormBuilder,
    private subDivisionService:SubdivisionService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,) { 
    this.campementForm = this.fb.group({
      libelle:["", Validators.required]
    })
  }


  ngOnInit() {
    this.loading = true
    this.subDivisionService.searchCampement(this.currentPage,"").then(
      (res:any)=>{
        this.loading = false
        
        this.listCampement = res.searchSubdivision7.results
        this.totalCount = res.searchSubdivision7.totalCount
      },
      (err:any)=>{
        
      }
    )
  }
  onChangePage(data:any) {
   
    this.currentPage = data.id;
    this.loading = true;
    this.subDivisionService.searchCampement(this.currentPage,"").then(
      (res:any)=>{
        this.loading = false;
        this.loading = false
       
        this.listCampement = res.searchSubdivision7.results
        this.totalCount = res.searchSubdivision7.totalCount
      },
      (err:any)=>{
        this.loading = false
       
      }
    )

  }

  sendCampementLibelle(libelle:FormGroup){
    this.submitted = true;
   
    this.subDivisionService.createCampement(this.campementId,libelle.value.libelle).then(
      (res:any) => {
        this.submitted = false
        this.campementForm.reset()
        if(this.campementId){
          for(const obj of this.listCampement){
            if(obj.id === this.campementId){
              obj.libelle = res.updateSubdivision7.subdivision7.libelle
            }
          }
        }

        if(!this.campementId){
          this.listCampement.push(res.createSubdivision7.subdivision7)
        }

        this.snackBar.open('Campement enregistré avec succès', 'Ok', { duration: 3000 });
        
    },
    (err:any) => {
      this.submitted = false
      this.snackBar.open("Erreur d'enregistrement, veuillez réessayer", 'Ok', { duration: 3000 });
     
    }
    )
  }
  updateClick(campement){
    
    this.campementId = campement.id
    this.campementForm = this.fb.group({
      libelle:[campement.libelle,Validators.required]
    })
  }
  openDeletedDialog(campement:any, index:number){
    
    const dialogRef = this.dialog.open(DeletedComponent,{
      width: '300px'
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if(result){
          
          this.subDivisionService.deleteCampement(campement.id).then(
          (res:any)=>{
            this.snackBar.open('Campement supprimé avec succès', 'Ok', { duration: 3000 });
            this.listCampement.splice(index, 1)
            
          }, (err) => {
            this.snackBar.open('Erreur de suppression', 'Ok', { duration: 3000 });
            
          }
          )

        }
      }
    )
  }

  filterData(value:string){
    this.loading = true
   this.filter = value
   this.subDivisionService.searchCampement(null,value).then(
     (res:any)=>{
       this.listCampement = res.searchSubdivision7.results
      
       this.loading = false
     },(err)=>{
       this.loading = false
     }
   )
  }

}
