import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plantation-light-item',
  templateUrl: './plantation-light-item.component.html',
  styleUrls: ['./plantation-light-item.component.css']
})
export class PlantationLightItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
