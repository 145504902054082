import { RoleService } from '../_services/role.service';
import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import 'jquery';
import 'bootstrap';
import { PermissionService } from '../_services/permission.service';
import { UtilsService } from '../_services/utils.service';
import { MatSnackBar } from '@angular/material';
import { UserService } from '../_services/user.service';
import { RoleEnCours } from './models';
import { element } from 'protractor';

declare var $: any;

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})

export class RoleComponent implements OnInit {
  rolesData: any = null;
  entreprise: any;
  roleEnCours: any[] = [];
  permissionEnCours: any[] = [];
  roleForm: FormGroup;
  update: boolean;
  permissions: any;
  submitted : boolean = false


  // Pagination param
  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;

  // Filtre param
  fields_selected_: Array<any> = [
    { libelle_db: "role_Name_Icontains", libelle: "Libelle" },
  ]

  filter: any = {
    field: null,
    value: null
  }

  loading: boolean = true
  userInfo: any;

  errorMsg: any = null;


  constructor(
    private roleService: RoleService,
    private permissionService: PermissionService,
    private utils: UtilsService,
    private snackBar: MatSnackBar,
    private user: UserService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')))
    this.initForm()
    this.readRole();
    // this.getPermissions();
  }

  ajouterPermission() {
    this.permissionService.read(`permissionSuiteRole_Role_Entreprise_Id: "${this.userInfo.profile.entreprise.id}", deleted: false`).then(
      (res) => {
        
        this.permissions = res['searchPermissionSuite']['results']
      },
      (error) => {
       
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason))
    $('#ajouterPermission').modal('show');
  }

  addPermissionToRole(permission_id) {
    let role_id = this.roleEnCours[0].id
    this.roleService.createRolePermissionSuite(role_id, permission_id).then(
      (res) => {
        this.permissionEnCours.push(res['createRolePermissionSuite']['rolepermissionsuite'])
      },
      (error) => {
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason))
  }

  initForm() {
    this.roleForm = this.formBuilder.group({
      'libelle': ['', Validators.required],
      'niveau': ['']
    });
  }

  getPermissions(role, index) {
    this.roleService.getPermissions(role).then(
      (res) => {
        let data = this.rolesData.results[index]
        this.roleEnCours[0] = data
        this.permissionEnCours = res['results']
      },
      (error) => {
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason))
  }

  onSubmit() {
    this.errorMsg = null;
    this.submitted = true
    if (this.roleForm.invalid) {
      return
    }
    let name = this.roleForm.value['libelle']
    if (!this.roleForm.value['id']) {
      this.roleService.createGroup(name).then(
        (res) => {
          let group_id = res['id']
          this.roleService.create(this.userInfo.profile.entreprise.id, group_id).then(
            (res) => {
              this.submitted = false
              this.roleForm.controls['libelle'].setValue('')
              $('#ajouterRole').modal('hide');
              this.snackBar.open('Enregistré', 'Ok', { duration: 3000 });
              this.rolesData.results.push(res)
            }, (error) => {
              this.submitted = false
            }
          ).catch(reason =>  this.utils.setErrorsHttp(reason))
        },
        (error) => {
          this.submitted = false;
          // "group with this name already exists."
          if (error[0].messages == "group with this name already exists.") {
            this.errorMsg = "Ce rôle existe déjà."
          }
        }
      ).catch(reason =>  this.utils.setErrorsHttp(reason))
    } else {
      let id = this.roleForm.value['id'];
      let role = this.rolesData.results.filter((role) => {
        if (role['id'] === id) {
          return role
        }
      });
      if (role.length != 0) {
        let group_id = role[0].role.id
        this.roleService.updateGroup(group_id, name).then(
          (res) => {
            this.submitted = false
            this.rolesData.results.filter((role) => {
              if (role['id'] == id) {
                let index = this.rolesData.results.indexOf(role)
                this.rolesData.results[index].role = res
              }
            });
            $('#ajouterRole').modal('hide');
            this.roleForm.controls['libelle'].setValue('')
            this.roleForm.removeControl('id')
            this.snackBar.open('Modifié', 'Ok', { duration: 3000 });
          },
          (error) => {
            this.submitted = false
           
          }
        ).catch(reason =>  this.utils.setErrorsHttp(reason))
      }
    }

  }

  edit(id: string) {
  
    this.update = true
    this.roleForm.addControl('id', new FormControl(id, Validators.required));
    let role = this.rolesData.results.filter((role) => {
      if (role['id'] === id) {
        return role
      }
    })
    this.roleForm.controls['libelle'].setValue(role[0]['role']['name']);
    $('#ajouterRole').modal('show');
  }



  readRole(page: number = 1, filter_field: any = null) {
    this.loading = true;
    let filter = `entreprise: "${this.userInfo.profile.entreprise.id}", deleted: false`;
    if(filter_field != null && filter_field.field != null){
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`
    }
    this.roleService.read(filter, page).then(
      (res: any) => {
        this.loading = false
        this.rolesData = res;
        this.totalCount = res['totalCount']
      },
      (error) => {
        this.loading = false;
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason))
  }

  ajouterRole() {
    if (this.roleForm.value['id']) {
      this.roleForm.removeControl('id');
    }
    $('#ajouterRole').modal('show');
  }


  seedRolePermission(index: number) {
    let data = this.rolesData.results[index]
    this.roleEnCours[0] = data
  }


  deletedPermissionToRole(permission) {
    let role_id = this.roleEnCours[0].id
    let filter = 'deleted : false, role : "' + role_id + '", permission : "' + permission + '"'
    this.roleService.readRolePermissionSuite(filter).then(
      (res: any) => {
        let role_permission_id = res["results"][0].id;
        this.roleService.updatePermissionToRole(role_permission_id).then(
          (res) => {
            let permiEnCours = this.permissionEnCours.filter(
              (role_permission, index) => {
                if (role_permission.id == res['id']) {
                  this.permissionEnCours.splice(index, 1)
                  return index
                }
              }
            );
          },
          (error) => {
          }
        ).catch(reason =>  this.utils.setErrorsHttp(reason))
      },
      (error) => {
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason))
  }


  filterData(data) {
    if (data.action === 0) {
      return
      this.filter = data.data_
    } else {
      this.loading = true
      this.filter = data.data_
      if (data.data_.value.length == 0) {
        this.readRole(1)
      } else {
        this.readRole(1, this.filter)
      }
    }
  }

  public onChangePage(data) {
    this.currentPage = data.id;
    this.readRole(data.id)
  }


  verify(permission_id) {
    let index = this.permissionEnCours.findIndex(element => element.permission.id == permission_id);
    if (index != -1) {
      return false
    }
    return true
  }

}
