import { Component, OnInit, NgModule } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import 'jquery';
import 'bootstrap';
import { UtilsService } from '../_services/utils.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { error } from 'util';
import { FonctionaliteService } from '../_services/fonctionalite.service';
@NgModule({
})

@Component({
  selector: 'app-fonctionalite',
  templateUrl: './fonctionalite.component.html',
  styleUrls: ['./fonctionalite.component.css']
})
export class FonctionaliteComponent implements OnInit {

  fonctionaliteForm : FormGroup;
  submitted : boolean = false;
  fonctionalites : any;
  update : boolean = false;
  delete: any = null;

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder : FormBuilder,
    private fonctionaliteService : FonctionaliteService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.initForm()
    this.readAllFonctionalite()
  }

  initForm(){
    this.fonctionaliteForm = this.formBuilder.group({
      'libelle' : ['', [Validators.required, Validators.minLength(3)]],
      'description' : ['', Validators.required]
    })
  }

  get f() { return this.fonctionaliteForm.controls; }

  onSubmit(){
    this.submitted = true;
    if(this.fonctionaliteForm.invalid){
      return
    }
    let libelle = this.fonctionaliteForm.value['libelle']; let description = this.fonctionaliteForm.value['description'];
    if(!this.fonctionaliteForm.value.id){
      this.fonctionaliteService.create(libelle, description).then(
        (res) => {
          this.submitted = false
          this.fonctionaliteForm.controls['libelle'].setValue("");
          this.fonctionaliteForm.controls['description'].setValue("");
          this.snackBar.open('Enregistré', 'Ok', { duration: 3000 });
          this.fonctionalites.results.unshift(res)
        },
        (error) => {
          // this.submitted = false
          this.snackBar.open('Erreur d\'enregistrement', 'Ok', { duration: 3000 });
        }
      ).catch(reason =>  this.utils.setErrorsHttp(reason))
    }else{
      let id = this.fonctionaliteForm.value['id'];
      this.fonctionaliteService.update(id, libelle, description).then(
        (res) => {
          this.submitted = false
          this.fonctionaliteForm.controls['libelle'].setValue("");
          this.fonctionaliteForm.controls['description'].setValue("");
          this.fonctionaliteForm.controls['id'].setValue("");
          this.fonctionaliteForm.removeControl('id')
          this.snackBar.open('Modifié', 'Ok', { duration: 3000 });
          let fonctionalite = this.fonctionalites.results.filter((fonctionalite) => {
            if (fonctionalite['id'] == res['id']){
              return fonctionalite;
            }
          })
          let index = this.fonctionalites.results.indexOf(fonctionalite[0])
          this.fonctionalites.results[index] = res

        },
        (error) => {
          this.submitted = false
          // this.fonctionForm.removeControl('id')
          this.snackBar.open('Erreur de modification', 'Ok', { duration: 3000 });
        }
      ).catch(reason =>  this.utils.setErrorsHttp(reason))
      this.update = false
    }
  }

  readAllFonctionalite(){
    this.fonctionaliteService.read().then(
      (res : any) => {
        this.fonctionalites = res;
      },
      (error) => {
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason))
  }

  edit(id : string){
    this.update = true
    this.fonctionaliteForm.addControl('id', new FormControl(id, Validators.required));
    let fonctionalite = this.fonctionalites.results.filter((fonctionalite) => {
      if (fonctionalite['id'] === id){
        return fonctionalite
      }
    })
    this.fonctionaliteForm.controls['description'].setValue(fonctionalite[0]['description']);
    this.fonctionaliteForm.controls['libelle'].setValue(fonctionalite[0]['libelle']);
  }

  create(){
    if (this.fonctionaliteForm.value.id){
      this.fonctionaliteForm.removeControl('id')
    }
    this.fonctionaliteForm.controls['libelle'].setValue("");
    this.fonctionaliteForm.controls['description'].setValue("");
    this.update = false;
  }

  public confDelete(): void {
    if (this.delete != null) {
      this.fonctionaliteService.update(this.delete.id, this.delete.libelle, this.delete.description, true).then(
        (res: any) => {
          this.fonctionalites.results.forEach((value, i) => {
            if (value.id == res.id) {
              this.fonctionalites.results.splice(i, 1);
            }
          });
          this.snackBar.open('Suppression effectué avec succès.', 'Ok', { duration: 3000 });

        },
        (error) => {
          this.submitted = false
          // this.fonctionForm.removeControl('id')
          this.snackBar.open('Erreur de modification', 'Ok', { duration: 3000 });
        }
      ).catch(reason =>  this.utils.setErrorsHttp(reason))
    }

  }

  public toDelete(d: any): void {
    this.delete = d;
  }
}
