export class SearchUser{
    username:string

    constructor(username:string){
        this.username = username
    }
}
export class Email{
    email:string
    constructor(email:string){
        this.email = email
    }
}

export class CreateProfil{
    contact:string
    dateNaissance:string
    deleted:boolean
    genre:string
    nom:string
    prenom:string
    user:string
    
    constructor(contact:string, dateNaissance:string,deleted:boolean, genre:string, nom:string, prenom:string, user:string){
        this.contact = contact
        this.dateNaissance = dateNaissance
        this.deleted = deleted
        this.genre = genre
        this.nom = nom
        this.prenom = prenom
        this.user = user
    }


}

export class Page{
    limit:number
    offset:number

    constructor(limit:number, offset:number){
        this.limit = limit
        this.offset = offset
    }

   
}
export class Entreprise {
    deleted: Boolean
    displayName: String
    email: String
    entrepriseMere: String
    logo: String
    raisonSociale: String
    siteWeb: String 
    telephone: String
    adresse: String
    categorie: string
    constructor( 
        deleted: Boolean,
        displayName: String,
        email: String,
        entrepriseMere: String,
        logo: String,
        raisonSociale: String,
        siteWeb: String ,
        telephone: String,
        adresse: String,
        categorie: string
        ) {
            this.deleted = deleted,
            this.displayName = displayName,
            this.email = email,
            this.entrepriseMere = entrepriseMere
            this.logo =logo,
            this.raisonSociale = raisonSociale,
            this.siteWeb = siteWeb ,
            this.telephone = telephone,
            this.adresse = adresse,
            this.categorie = categorie
        
    }

  

}
export class ProfileCollecteur {
    page:number
    entreprise:string
    constructor(page:number,
        entreprise:string) {
            this.page = page
            this.entreprise = entreprise
        
    }
}

export class EmployeStatRead{
    employe:string
    entreprise:string

    constructor(employe:string, entreprise:string){
        this.employe = employe
        this.entreprise = entreprise
    }
}

export class UpdateProfile{
    id: String
    categorie: String
    codeDeVerification: String
    contact: String
    dateNaissance: String
    deleted: Boolean
    displayName: String
    entreprise: String
    fonction: String
    genre: String
    nom: String
    pere: String
    photo: String
    prenom: String
    tracking: Boolean
    user: String
    zone: String

    constructor( id: String,
        categorie: String,
        codeDeVerification: String,
        contact: String,
        dateNaissance: String,
        deleted: Boolean,
        displayName: String,
        entreprise: String,
        fonction: String,
        genre: String,
        nom: String,
        pere: String,
        photo: String,
        prenom: String,
        tracking: Boolean,
        user: String,
        zone: String,
        ){
            this.id = id
            this.categorie = categorie
            this.codeDeVerification = codeDeVerification
            this.contact = contact
            this.dateNaissance = dateNaissance
            this.deleted = deleted
            this.displayName = displayName
            this.entreprise = entreprise
            this.fonction = fonction
            this.genre  = genre
            this.nom = nom
            this.pere = pere
            this.photo = photo
            this.prenom = prenom
            this.tracking = tracking
            this.user = user
            this.zone = zone
        }
    
}