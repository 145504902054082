import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PeriodeTrackingService } from 'src/app/_services/periode-tracking.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { TrackService } from 'src/app/_services/track.service';
import { UtilsService } from 'src/app/_services/utils.service';
import { NewAlertConfirmComponent } from 'src/ui/new-alert-confirm/new-alert-confirm.component';


@Component({
  selector: 'app-periode-tracking-user',
  templateUrl: './periode-tracking-user.component.html',
  styleUrls: ['./periode-tracking-user.component.scss']
})
export class PeriodeTrackingUserComponent implements OnInit {
  public periodes: any = [];
  public d = 0;
  public tracks: any = [];
  userInfo: any;
  trackingForm:FormGroup
  alertField:boolean = false
  loader:boolean = false

  constructor(
    public dialogRef: MatDialogRef<PeriodeTrackingUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private periodeTrackingServ: PeriodeTrackingService,
    private trackServ: TrackService,
    private U: UtilsService,
    private fb:FormBuilder,
    private dialog:MatDialog
  ) { 
    this.trackingForm = this.fb.group({
      'track':['', Validators.required]
    })
  }

  ngOnInit() {
    console.log('data', this.data)
    this.userInfo = JSON.parse(this.U.decrypt(this.U.getCookie('_wefly_')));
    // this.readTrack();
    this.readPeriodeTracking();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }




  // private getPeriodeTracking(page: number = 1, filter_field: any = null): void {
  //   this.loading = true;
  //   let filter = `entreprise: "${this.userLogged.profile.entreprise.id}", deleted: false`;
  //   if (filter_field != null && filter_field.field != null) {
  //     filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`;
  //   }
  //   this.periodeTrackingServ.read(filter, page).then(

  public readPeriodeTracking(page: number = 1, filter_field: any = null): void {


    let filter = `entreprise: "${this.userInfo.profile.entreprise.id}", deleted: false`;
    if (filter_field != null && filter_field.field != null) {
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`;
    }
    this.periodeTrackingServ.read(filter, page).then(

    // this.periodeTrackingServ.read1(this.userInfo.profile.entreprise.id).then(
      (res: any) => {
        this.periodes = res;
        console.log('periode tracking', res)
        this.readTrack()
        let _periodes: any = [];


        // this.periodes.results.forEach((elt, i)=> {
        //   _periodes.push(elt.id);
        //               // this.periodes.results.splice(_tracks.indexOf(elt.id), 1)
        //               // if (_tracks.indexOf(elt.id) > -1) {
        //              //   this.periodes.results.splice(i, 1)
        //            // }
        // });

        // this.tracks.results.forEach((elt, i) => {
        //   if (_periodes.indexOf(elt.periode.id) > -1 ) {
        //     this.periodes.results.splice(_periodes.indexOf(elt.periode.id), 1);
        //   }
        // });


      }, (err) => {
      }
    ).catch(reason => this.U.setErrorsHttp(reason));
  }

  public onSubmit(form: NgForm): void {
    let f = form.value;
    console.clear();
    this.trackServ.create(false, new Date().toISOString(), f.periode, this.data.profile.user.id).then(
      (res: any) => {
        this.tracks.results.unshift(res);
      }, (err) => {
      }
    ).catch(reason => this.U.setErrorsHttp(reason));
  }

  public removeTrack(track: any): void {
    const dialog = this.dialog.open(NewAlertConfirmComponent,{
      width:'35rem',
      data:{
        title:"Suppression tracking",
        message:`Vous êtes sur le point de retirer cette période tracking de ' ${this.data.profile.nom} ${this.data.profile.prenom}'. Voulez-vous continuer ?`
      }
    })
    dialog.afterClosed().subscribe((res:any)=>{
      if(res === true){
        this.trackServ.update(track.id, true, new Date().toISOString(), track.periode.id, track.user.id).then(
          (res: any) => {
            // this.tracks.results.unshift(res);
            this.tracks.results.forEach((elt, i) => {
              if (elt.id == res.id) {
                this.tracks.results.splice(i, 1);
                this.U.snack('Retrait effectué avec succès.');
              }
            });
          }, (err) => {
            this.U.snack('Une erreur est survenue, veuillez reessayer s\'il vous plaît.');
          }
        ).catch(reason => this.U.setErrorsHttp(reason));

      }
    })
    // let c = confirm('Voulez-vous vraiment rétirer cette période du tracking de ' + this.data.profile.nom + ' ' + this.data.profile.prenom + '(' + this.data.profile.user.username + ') ?');
    // if (c) {
    //   this.trackServ.update(track.id, true, new Date().toISOString(), track.periode.id, track.user.id).then(
    //     (res: any) => {
    //       // this.tracks.results.unshift(res);
    //       this.tracks.results.forEach((elt, i) => {
    //         if (elt.id == res.id) {
    //           this.tracks.results.splice(i, 1);
    //           this.U.snack('Retrait effectué avec succès.');
    //         }
    //       });
    //     }, (err) => {
    //       this.U.snack('Une erreur est survenue, veuillez reessayer s\'il vous plaît.');
    //     }
    //   ).catch(reason => this.U.setErrorsHttp(reason));
    // }
  }

  public readTrack() {
    this.trackServ.read(`deleted: false, user_Id: ${this.data.profile.user.id}`).then(
      (res: any) => {
        this.tracks = res;
        console.log('user track',this.tracks)
        // this.readPeriodeTracking();
      }, (err) => {
      }
    ).catch(reason => this.U.setErrorsHttp(reason));
  }

  linkTrack(trackData:FormGroup){
    console.log('track selected', trackData)
    if(trackData.valid){
      this.loader = true
      console.log('track selected', trackData)
      this.trackServ.create(false, new Date().toISOString(), trackData.value.track, this.data.profile.user.id).then(
        (res: any) => {
           this.loader = false
          console.log('track ok', res)
          this.tracks.results.unshift(res);
         this.U.snack('Période tracking attribuée avec succès.')
        }, (err) => {
          this.loader = false
          console.log('erreur track', err)
        }
      ).catch(reason => this.U.setErrorsHttp(reason));
    }else{
      this.alertField = true
      this.loader = false
    }
  

  }
  cancelDialog(){
    this.dialogRef.close()
  }

}
