import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { MatDatepicker } from '@angular/material';
import * as moment from 'moment';
import { Moment, MomentInput } from 'moment';
;
export class DateRange {
  start: String;
  endDate: String;
  constructor(start: Date, end: Date){
    this.start = start.toISOString().toString()
    this.endDate = end.toISOString().toString()
  }
}

@Component({
  selector: 'app-interval-date-filter',
  templateUrl: './interval-date-filter.component.html',
  styleUrls: ['./interval-date-filter.component.scss']
})
export class IntervalDateFilterComponent implements OnInit {
  @Output() sendDate: EventEmitter<DateRange> = new EventEmitter<DateRange>()
  startDate: Date
  endDate: Date
  constructor() { }
  ngOnInit() {
  }
  _sendDate(){
    this.sendDate.emit(new DateRange(this.startDate, this.endDate))
  }

  _yearStartdHandler(date: Date, datePicker: MatDatepicker<Moment>){
     datePicker.close()
     this.startDate = date
  }

  _yearEndHandler(date: Date, datePicker: MatDatepicker<Moment>){
    datePicker.close()
    this.endDate = moment(date).add(11, 'M').add(30, 'd').toDate()
  }

}
