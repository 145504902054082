import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';
declare var $: any;

@Component({
  selector: 'app-mysidebar',
  templateUrl: './mysidebar.component.html',
  styleUrls: ['./mysidebar.component.css']
})
export class MySidebarComponent implements OnInit {

  navbarOpen: boolean = false;

  constructor(public utils: UtilsService) { }

  ngOnInit() {
    $(".menu-toggle").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
 
  }
}
