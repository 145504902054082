import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { ProfileService } from 'src/app/_services/profile.service';
import { UtilsService } from 'src/app/_services/utils.service';

export interface DialogData {
  user : any;
}

@Component({
  selector: 'app-active-or-desactive-tracking-user',
  templateUrl: './active-or-desactive-tracking-user.component.html',
  styleUrls: ['./active-or-desactive-tracking-user.component.scss']
})
export class ActiveOrDesactiveTrackingUserComponent implements OnInit {

  user : any = null

  constructor(
    public dialogRef: MatDialogRef<ActiveOrDesactiveTrackingUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private profileService : ProfileService,
    private snackBar: MatSnackBar,
    private utils : UtilsService
  ) { }

  onNoClick(result : boolean = false): void {
    this.dialogRef.close(result);
  }

  ngOnInit() {
    this.user = this.data.user;
  }

  ok(){
    let active = ! this.user.profile.tracking;
    this.profileService.activeOrDesactiveTrackingOnUser(this.user.profile.id, active).then(
      (res) => {
        if(this.user.profile.tracking){
          this.snackBar.open('Traking desactivé', 'Ok', { duration: 3000 });
        }else{
          this.snackBar.open('Traking activé', 'Ok', { duration: 3000 });
        }
        this.user.profile.tracking = active
        let profileDetail = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
        if(profileDetail.id == this.user.id){
         
          this.utils.setCookie('_wefly_', this.utils.encrypt(JSON.stringify(this.user)), { nb: 7, unite: 3 });
        }
        this.onNoClick(true)
      },
      (error) => {
        this.onNoClick(false)
        console.error(error);
      }
    )
  }

}
