import { Component, OnInit, Inject } from '@angular/core';
import { ZoneActionService } from 'src/app/_services/zone.action.service';
import { FonctionService } from 'src/app/_services/fonction.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilsService } from 'src/app/_services/utils.service';
import { UserService } from 'src/app/_services/user.service';
import { ProfileService } from 'src/app/_services/profile.service';
import { MustMatch, passwordValidator, phoneValidator } from '../../_validators/passwordValidators';
import { UsernameExit } from '../../_validators/usernameExit';
import { SubdivisionService } from 'src/app/_services/subdivision.service';
import * as moment from 'moment';

@Component({
  selector: 'app-employe-form',
  templateUrl: './employe-form.component.html',
  styleUrls: ['./employe-form.component.scss']
})
export class EmployeFormComponent implements OnInit {
  profilFormStepperOne: FormGroup
  profilFormStepperTwo: FormGroup

  employeForm:FormGroup
  alertField:boolean = false
  loader:boolean = false
  loaderupdate:boolean = false
  alertMessageExitYet:boolean = false
  alertMessageExitEmailYet:boolean = false
  imageProfil:any
  alertIdentiquePassWord:boolean = false
  zones: any[] = [];
  fonctions: any[] = [];
  subDivision1:any
  subDivision2:any
  subDivision3:any


  photo: any = "";
  userOnline: any

  submittedOne: boolean = false;
  submittedTwo: boolean = false;
  submitted: boolean = false;

  jour: number[] = [];
  mois: number[] = [];
  annee: number[] = [];

  msg: any = {};

  constructor(
    public dialogRef: MatDialogRef<EmployeFormComponent>,
    private utilsService: UtilsService,
    private userService: UserService,
    private profilService: ProfileService,
    private formBuilder: FormBuilder,
    private zoneService: ZoneActionService,
    private zone :  ZoneActionService,
    private fonctionService: FonctionService,
    public dialog: MatDialog,
    public u: UtilsService,
    private subDivisionService:SubdivisionService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

      this.employeForm = this.formBuilder.group({
        "nom":["", Validators.required],
        "prenom":["", Validators.required],
        "genre":["",Validators.required],
        "contact":["", [Validators.required, Validators.minLength(8)]],
        "dateNaissance":["", Validators.required],
        "email":["", [Validators.required, Validators.email]],
        "username":["",Validators.required],
        "fonction":["", Validators.required],
        "zone":["", Validators.required],
        "password":["", [Validators.required, Validators.minLength(8)]],
        "confpassword":["", [Validators.required, Validators.minLength(8)]]
      })


    }

  ngOnInit() {

    this.jour = this.u.genTabNumber(31);
    this.mois = this.u.genTabNumber(12);
    const nowYear = new Date().getFullYear();
    this.annee = this.u.genTabNumber(nowYear - 18, nowYear - 75).reverse();
    this.userOnline = JSON.parse(this.utilsService.decrypt(this.utilsService.getCookie('_wefly_')));
   
    this.readFonction();
    this.readZone();
    console.log(this.data)

    if(this.data.profile != null){
      
      this.employeForm = this.formBuilder.group({
        'nom':[this.data!.profile.nom, Validators.required],
        'prenom':[this.data!.profile.prenom, Validators.required],
        'genre':[this.data!.profile.genre, Validators.required],
        'contact':[this.data!.profile.contact, [Validators.required, Validators.minLength(8)]],
        'dateNaissance':[this.data!.profile.dateNaissance, Validators.required],
        "username":[this.data.profile.user.username,Validators.required],
        'email':[this.data!.profile.user.email, [Validators.required, Validators.email]],
        'fonction':[this.data!.profile.fonction ? this.data!.profile.fonction.id : ""],
        'zone':[this.data!.profile.zone ? this.data!.profile.zone.id : ""],
        "password":[this.data.profile.user.password, [Validators.required, Validators.minLength(8)]],
        "confpassword":[this.data.profile.user.password, [Validators.required, Validators.minLength(8)]]
      })

    }

 

  
  }

  

  get fOne() { return this.profilFormStepperOne.controls; }
  get fTwo() { return this.profilFormStepperTwo.controls; }

  verifyOne() {
    this.submittedOne = true
  }

  verifyTwo() {
    this.submittedTwo = true
  }

 

  readZone() {
    let filter = `entreprise : "${this.userOnline.profile.entreprise.id}", deleted: false`;
    this.zone.read(filter).then(
      (res: any) => {
        
        this.zones = res['searchZoneAction']['results']
        console.log('zones', this.zones)
      },
      (error) => {
        console.error(error)
      }
    )
  }

  readFonction() {
    this.fonctionService.readAllFonction().then(
      (res: any) => {
        this.fonctions = res.results
        console.log('fonctions', this.fonctions)
      },
      (error) => {
        console.error(error)
      }
    )
  }

  public processFile(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profilFormStepperOne.get("photo").setValue(reader.result);
        this.photo = reader.result;
      };
    } else {
      // tslint:disable-next-line:no-console
      console.info('Un problème est survenu lors du chargement du fichier.');
    }
  }
    // const dat = this.profilFormStepperTwo.value['year'] + '-' + this.profilFormStepperTwo.value['month'] + '-' + this.profilFormStepperTwo.value['day']
  //   //  this.profilFormStepperTwo.value['dateNaissance'] = new Date(dat)
  //    this.profilFormStepperTwo.value['dateNaissance'] = moment(new Date(dat)).format("YYYY-MM-DD")


  createCollecteur(formData:FormGroup){
   
    console.log('form data',formData)
    const employeForm = formData.value
    if(this.data.profile == null){
      if(formData.valid){
        if(formData.value.password === formData.value.confpassword){
          if(this.alertIdentiquePassWord || this.alertMessageExitYet || this.alertMessageExitEmailYet){
            this.alertIdentiquePassWord = false
            this.alertMessageExitYet = false
            this.alertMessageExitEmailYet = false
          }
          this.loader = true
          this.userService.inscription(employeForm.username, employeForm.email, employeForm.nom, employeForm.prenom, employeForm.password).then(
            (res: any) => {
                console.log('employe inscription ok', res)
                if(res.errors == null){
                  this.profilService.create(
                  
                  "",
                  employeForm.contact,
                  employeForm.dateNaissance,
                  false, '', 
                  this.userOnline.profile.entreprise.id,
                  employeForm.fonction,
                  employeForm.genre,
                  employeForm.nom,
                  this.userOnline.id,
                  this.imageProfil? this.imageProfil : "",
                  employeForm.prenom,
                  true, 
                  res.user.id,
                  employeForm.zone
                  ).then(
                    (profil: any) => {
                      console.log('create profile ok', profil)
                      this.loader = false
    
                      this.dialogRef.close(profil.createProfile.profile)
      
                    },
                    (error) => {
                      this.loader = false
      
                    }
                  );     
                }else{
                  this.alertMessageExitYet = true
                  this.loader = false
                }
               
            }, 
            (error) => {
              this.alertMessageExitEmailYet = true
              console.log('erreur employe inscription', error)
              this.loader = false
  
            })

        }else{
          this.alertIdentiquePassWord = true

        }
      }else{
        this.alertField = true
      }
    }else{
    
      if(formData.valid){
        console.log('update data',formData)
        this.loader = true
       
        this.userService.update(this.data.profile.user.id, 
          this.data.profile.user.username, 
          this.data.profile.user.dateJoined, 
          employeForm.email, 
          this.data.profile.user.firstName, 
          this.data.profile.user.isActive, 
          this.data.profile.user.isStaff, 
          this.data.profile.user.isSuperuser, 
          this.data.profile.user.lastLogin, 
          this.data.profile.user.lastName).then(
          (res) => {
            console.log('update user ok', res)

            this.profilService.update(
              this.data.profile.id,
              this.data.profile.categorie ? this.data.profile.categorie.id : "",
              this.data.profile.codeDeVerification ? this.data.profile.codeDeVerification : '',
              employeForm.contact,
              employeForm.dateNaissance,
              false,
              this.data.profile.displayName ? this.data.profile.displayName : "",
              this.data.profile.entreprise.id,
              employeForm.fonction,
              employeForm.genre,
              employeForm.nom,
              this.data.profile.user.id,
              this.imageProfil? this.imageProfil  : this.data.profile.photoUrl,
              employeForm.prenom,
              this.data.profile.tracking,
              this.data.profile.user.id,
              employeForm.zone,
            ).then(
              (res:any) => {
                console.log('update profile ok', res)
                this.loader = false
                this.dialogRef.close(res.updateProfile.profile)

                
              },(err:any)=>{
                console.log('erreur update profile', err)
                this.loader = false
              }
              )

  
          }, (error) => {
            console.log('erreur update user', error)
            this.loader = false
            console.error("Error lors du modification de USER", error)
          }
          )

      }else{
        this.alertField = true
      }

     

    }
  }


  // onSubmit() {
  //   // console.log(this.data.profile)
  //   const dat = this.profilFormStepperTwo.value['year'] + '-' + this.profilFormStepperTwo.value['month'] + '-' + this.profilFormStepperTwo.value['day']
  //   //  this.profilFormStepperTwo.value['dateNaissance'] = new Date(dat)
  //    this.profilFormStepperTwo.value['dateNaissance'] = moment(dat).format("YYYY-MM-DD")
  //   if (this.profilFormStepperOne.invalid || this.profilFormStepperTwo.invalid) {
  //     return
  //   }

  //   if (this.data.profile != null) {

  //     this.userService.update(this.data.profile.user.id, 
  //       this.data.profile.user.username, 
  //       this.data.profile.user.dateJoined, 
  //       this.profilFormStepperOne.value['email'], 
  //       this.data.profile.user.firstName, 
  //       this.data.profile.user.isActive, 
  //       this.data.profile.user.isStaff, 
  //       this.data.profile.user.isSuperuser, 
  //       this.data.profile.user.lastLogin, 
  //       this.data.profile.user.lastName).then(
  //       (res) => {

  //         this.data.profile.user.email = this.profilFormStepperOne.value['email'];
  //         this.profilService.update(
  //           this.data.profile.id,
  //           '',
  //           this.data.profile.codeDeVerification ? this.data.profile.codeDeVerification : '',
  //           this.profilFormStepperOne.value['contact'],
  //           this.profilFormStepperTwo.value['dateNaissance'],
  //           false,
  //           this.data.profile.displayName ? this.data.profile.displayName : "",
  //           this.data.profile.entreprise.id,
  //           this.profilFormStepperTwo.value['fonction'],
  //           this.profilFormStepperTwo.value['genre'],
  //           this.profilFormStepperOne.value['nom'],
  //           this.data.profile.user.id,
  //           this.profilFormStepperOne.value['photo'] != '' ? this.profilFormStepperOne.value['photo'] : this.data.profile.photoUrl,
  //           this.profilFormStepperOne.value['prenoms'],
  //           this.data.profile.tracking,
  //           this.data.profile.user.id,
  //           this.profilFormStepperTwo.value['zone'],false,
           
  //         ).then(
  //           (res) => {

  //             this.data.profile.contact = this.profilFormStepperOne.value['contact'];
  //             this.data.profile.dateNaissance = this.profilFormStepperTwo.value['dateNaissance']
  //             this.data.profile.genre = this.profilFormStepperTwo.value['genre']
  //             this.data.profile.nom = this.profilFormStepperOne.value['nom']
  //             this.data.profile.prenom = this.profilFormStepperOne.value['prenoms']
  //             this.data.profile.photoUrl = this.profilFormStepperOne.value['photo'] ? this.profilFormStepperOne.value['photo'] : this.data.profile.photoUrl

  //             this.data.profile['zone'] = {
  //               id: this.profilFormStepperTwo.value['zone']
  //             };
  //             this.data.profile['fonction'] = {
  //               id: this.profilFormStepperTwo.value['fonction']
  //             };

  //             this.data.profile['district'] = {
  //               id: this.profilFormStepperTwo.value['district']
  //             };


  //             this.data.profile['region'] = {
  //               id:this.profilFormStepperTwo.value['region']
  //             };
  //             this.data.profile['departement'] = {
  //               id:this.profilFormStepperTwo.value['departement']
  //             }
          
  //             this.submitted = false
  //             this.dialogRef.close(this.data.profile)
  //           },
  //           (error) => {
  //             this.submitted = false
  //             console.error("Error update profil, ", error)
  //           }
  //         )
  //       },
  //       (error) => {
  //         console.error("Error lors du modification de USER")
  //       }
  //     )
  //   } else {
  //     if (this.u.regexPassword(this.profilFormStepperOne.value.password)) {


  //       this.userService.inscription(this.profilFormStepperOne.value["username"], this.profilFormStepperOne.value["email"], this.profilFormStepperOne.value["nom"], this.profilFormStepperOne.value["prenoms"], this.profilFormStepperOne.value["password"]).then(
  //         (res: any) => {
            


  //           this.profilService.create(
  //            "",
  //             this.profilFormStepperOne.value["contact"],
  //              this.profilFormStepperTwo.value["dateNaissance"],
  //               false, '', this.userOnline.profile.entreprise.id,
  //                this.profilFormStepperTwo.value["fonction"],
  //                 this.profilFormStepperTwo.value["genre"],
  //                  this.profilFormStepperOne.value["nom"],
  //                   this.userOnline.id,
  //                   this.profilFormStepperOne.value['photo'],
  //                    this.profilFormStepperOne.value["prenoms"],
  //                    true, 
  //                    res.user.id,
  //                    this.profilFormStepperTwo.value["zone"],
  //                    false,
  //                    ).then(
  //             (profil: any) => {
  //               this.submitted = false

  //               this.dialogRef.close(profil.createProfile.profile)

  //             },
  //             (error) => {
  //               this.submitted = false

  //             }
  //           );
  //         },
  //         (error) => {
  //           this.submitted = false

  //         }
  //       )




  //     } else {
  //       this.submittedOne = true;
  //       this.msg.password = "true";
  //     }
  //   }

  // }

  cancelDialog() {
    this.dialogRef.close()
  }
  getLogo(event){
    console.log(event)
    const reader = new FileReader();
    if (event) {
      const file = event[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.selectedFile = reader.result;
        this.imageProfil = reader.result;
      };
    } else {
      // tslint:disable-next-line:no-console
    }
  }



}
