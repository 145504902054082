import { Component, OnInit } from "@angular/core";
import "jquery";
import "bootstrap";
declare var $: any;
import "popper.js";
import { UtilsService } from "../_services/utils.service";
import { Router } from "@angular/router";
import { UserService } from "../_services/user.service";
import { CooperativeDashboardComponent } from '../@wegeo/cooperative-dashboard/cooperative-dashboard.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { SuggestionComponent } from '../_modale/suggestion/suggestion.component';
import * as jwt_decode from 'jwt-decode';
import { ConfirmComponent } from "../confirm/confirm.component";
import { NewEmployeDetailComponent } from "../@wegeo/new-employe-detail/new-employe-detail.component";
import { NewAlertConfirmComponent } from "src/ui/new-alert-confirm/new-alert-confirm.component";
import { DataCacheService } from "../_services/data-cache.service";

@Component({
  selector: "app-user-nav",
  templateUrl: "./user-nav.component.html",
  styleUrls: ["./user-nav.component.css"],
  providers: [CooperativeDashboardComponent]
})
export class UserNavComponent implements OnInit {
  imageExist:boolean=true;
  profileLogged: any = null;
  expand:boolean = false
  constructor(
    private usersv: UserService,
    public U: UtilsService,
    private router: Router,
    public dialog: MatDialog,
    private dataCache : DataCacheService
    ) { }

  ngOnInit() {
    $(".dropdown-toggle").dropdown();
    // this.searchUserLoggedProfile();

      this.profileLogged = JSON.parse(this.U.decrypt(this.U.getCookie("_wefly_")));
      console.log('decrypt', this.profileLogged)
        
  }
  getMedia(url: string): string {
    return this.U.API_URL + url
  }
  public searchUserLoggedProfile(): any { 


    const token = this.U.getStorage('weflyHeader_token')
    const tokenDecode:any = jwt_decode(token)

    // const username = this.U.getUserToken().username;
    this.usersv.read(`username: "${tokenDecode.username}"`, 1, "").then(
      (res: any) => {
        console.log('profile', res)
        this.profileLogged = res.searchUser.results[0];
      },
      err => {
      }
    );
  }

  public tryLogout(): void {
    var rep = confirm("Voulez-vous vous déconntecter?");
    if (rep) {
      // si le user accepte de se déconnecter
      this.U.logout();
      this.router.navigate(["/"]);
    } else {
    }
  }

  public openSuggestionDialog() {
    const dialogRef = this.dialog.open(SuggestionComponent, {
      width: '45rem',
      data: { profile: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  

  deconnexion(){}
  getSuggestion(){}
  updateProfil(){}
  getAction(){
    this.expand = !this.expand
    

  }

  log0ut(){
    const dialogRef = this.dialog.open(NewAlertConfirmComponent, {
      width:"35rem",
      data:{title:"Déconnexion", message:"Voulez-vous vraiment vous déconnecter ?"}
    })
    dialogRef.afterClosed().subscribe((res)=>{
      console.log('back', res)
      if(res === true){
        this.dataCache.clearData('cache-planteur')
        this.dataCache.clearData('cache-plantation')
        this.dataCache.clearData('cache-employe')
       
        this.U.deleteStorage('weflyHeader_token')
        this.router.navigate(['/'])
      }else{
        return
      }
    })
   
 
   }
   getProfil(profile:any){
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.direction = 'ltr';
    dialogConfig.width = '27rem';
    dialogConfig.height ="100vh"
    dialogConfig.position = { top: '0', right: '0' };
    dialogConfig.panelClass = "slide-from-right";

    dialogConfig.data = profile
    this.dialog.open(NewEmployeDetailComponent, dialogConfig
  
   )
   }

}
