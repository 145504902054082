import { setForm } from "./../../_store/actions/form.actions";
import { FormState } from "./../../_store/states/forms.state";
import { selectForm } from "./../../_store/selectors/form.selectors";
import { AppState } from "./../../_store/app.state";
import { Store, select } from "@ngrx/store";
import { Component, OnInit } from "@angular/core";
import { FormCoucheService } from "src/app/_services/form-couche.service";
import { UtilsService } from "src/app/_services/utils.service";
import { TypeDeCoucheService } from "src/app/_services/type-couche.service";
import { CoucheService } from "src/app/_services/couche.service";
import { CompartimentService } from "src/app/_services/compartiment.service";
import { UserService } from "src/app/_services/user.service";
import { AttributFormulaireService } from "src/app/_services/attribut-formulaire.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { InformationPersoActeurService } from "src/app/_services/informations-perso-acteur.service";
import { InformationsFamilialesActeurPersService } from "src/app/_services/informations-famille-acteur-pers.service";
import { InformationsSourceRevActeurPersService } from "src/app/_services/informations-source-rev-acteur-pers.service";
import { OrganisationActeurPersService } from "src/app/_services/organisation-acteur-pers.service";
import { InformationQualiteDeVieActeurPersService } from "src/app/_services/informations-qualite-de-vie-acteur-pers.service";
import { InformationSocioprofActeurPersService } from "src/app/_services/informations-socioprof-acteur-pers.service";
import { InfoFormationActeurPersService } from "src/app/_services/info-formations-acteur-pers.service";
import { InformationNotationActeurPlantationService } from "src/app/_services/information-notation-acteur-plantation.service";
import { InformationsGenActeurPlantationService } from "src/app/_services/informations-gen-acteur-plantation.service";
import { InformationsMainDoeuvreActeurPlantationService } from "src/app/_services/informations-main-doeuvre-acteur-plantation.service";
import { InformationsEntretienActeurPlantationService } from "src/app/_services/informations-entretien-acteur-plantation.service";
import { InformationProductionActeurPlantationService } from "src/app/_services/information-production-acteur-plantation.service";
import { InformationDelimitationActeurPlantationService } from "src/app/_services/information-delimitation-acteur-plantation.service";
import { InformationEnfantActeurPersService } from "src/app/_services/information-enfant-acteur-pers.service";
import { ActeurService } from "src/app/_services/acteurs.service";
import * as turf from "@turf/turf";
import { Observable } from "rxjs";
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: "app-edit-form",
  templateUrl: "./edit-form.component.html",
  styleUrls: ["./edit-form.component.scss"],
})
export class EditFormComponent implements OnInit {
  toggle = false;
  formcouches$: Observable<object[]>;
  compartiments = [];
  show = false;
  showspin = true;
  loading = false;
  firstFormGroup: FormGroup;
  geojsonFeature = [
    {
      type: "Feature",
      properties: {
        name: "Coors Field",
        show_on_map: true,
      },
      geometry: {
        type: "Point",
        coordinates: [-100.99404, 39.75621],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "Busch Field",
        show_on_map: false,
      },
      geometry: {
        type: "Point",
        coordinates: [-104.98404, 39.74621],
      },
    },
    {
      type: "Feature",
      properties: { party: "Republican" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.05, 48.99],
            [-97.22, 48.98],
            [-96.58, 45.94],
            [-104.03, 45.94],
            [-104.05, 48.99],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { party: "Democrat" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-109.05, 41.0],
            [-102.06, 40.99],
            [-102.03, 36.99],
            [-109.04, 36.99],
            [-109.05, 41.0],
          ],
        ],
      },
    },
  ];
  userEntreprise: any;
  localisation: any;

  constructor(
    private formCouche: FormCoucheService,
    private utils: UtilsService,
    private typeCouhe: TypeDeCoucheService,
    private couche: CoucheService,
    private compartiment: CompartimentService,
    private usersv: UserService,
    private atrForm: AttributFormulaireService,
    private formBuilder: FormBuilder,
    private PersService: InformationPersoActeurService,
    private fammilleService: InformationsFamilialesActeurPersService,
    private sourceRevService: InformationsSourceRevActeurPersService,
    private organisationService: OrganisationActeurPersService,
    private qualiteDevieService: InformationQualiteDeVieActeurPersService,
    private socioProfService: InformationSocioprofActeurPersService,
    private infoFormationSevice: InfoFormationActeurPersService,
    private notationService: InformationNotationActeurPlantationService,
    private infoGenService: InformationsGenActeurPlantationService,
    private maindoeuvreService: InformationsMainDoeuvreActeurPlantationService,
    private entretientActService: InformationsEntretienActeurPlantationService,
    private productionService: InformationProductionActeurPlantationService,
    private delimitationService: InformationDelimitationActeurPlantationService,
    private enfantActeurService: InformationEnfantActeurPersService,
    private acteurService: ActeurService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.formcouches$ = this.store.pipe(select(selectForm));
    console.log(
      "this.formcouches$",
      this.formcouches$.subscribe((data) => {
        console.log("data", data);
        if (data === null || data.length <= 0) {
          this.loading = false;
        } else {
          this.loading = true;
        }
      })
    );

    this.getMyPosition()
      .then(
        (res) => {
          this.localisation = res;
        },
        (err) => {}
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));

    // const username = this.utils.getUserToken().username.toString();
    const username = jwt_decode( this.utils.getStorage('weflyHeader_token')).username
    this.usersv
      .read(`username: "${username}"`, 1, "id")
      .then(
        (res: any) => {
          this.userEntreprise = res.searchUser.results[0];
          this.getFormCouche();
          this.getTypeCouchePlantation();
        },
        (err) => {}
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }

  toggleSidebar() {
    const a = parent.document.getElementById("map-content");
    const b = parent.document.getElementById("map-info");
    this.toggle = !this.toggle;
    if (this.toggle) {
      a.removeAttribute("class");
      b.removeAttribute("class");
      a.setAttribute("class", "col-md-12");
      b.setAttribute("style", "max-width: 0px");
    } else {
      a.setAttribute("class", "col-md-7");
      b.setAttribute("class", "col-md-5");
      b.setAttribute("style", "max-width : 41.666667%");
    }
  }

  getFormCouche() {
    this.formCouche
      .read(
        `couche_Entreprise_Id: "${this.userEntreprise.profile.entreprise.id}", deleted: false`
      )
      .then(
        (fres: any) => {},
        (err) => {}
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }

  public getTypeCouchePlantation(
    entreprise: string = this.userEntreprise.profile.entreprise.id
  ) {
  
    // On recupère les couches
    return new Promise(async (resolve, reject) => {
      await this.couche
        .read(`entreprise: "${entreprise}", deleted: false, principal: false`)
        .then(async (couche: any) => {
          if (couche.results.length > 0) {
            // On recupère le formulaire pour chaque couche

            for (const element of couche.results) {
              await this.formCouche
                .read(
                  `couche_Entreprise_Id: "${entreprise}", couche: "${element.id}", deleted: false`
                )
                .then(async (form: any) => {
                  element.formulaire = form.searchFormCouche.results[0];
                  element.formulaire.compartiments = [];

                  // Recupéarion des compartiment de couche

                  await this.compartiment
                    .read(`typeCoucheId: "${element.typeCouche.id}"`)
                    .then(
                      async (compartmt: any) => {
                        // Récupération des attributs formulaires
                        for (const compt of compartmt.searchCompartiment
                          .results) {
                          // Debut
                          await this.atrForm
                            .read(
                              // tslint:disable-next-line: max-line-length
                              `formcouche_Id: "${form.searchFormCouche.results[0].id}", formcouche_Couche_Entreprise_Id: "${entreprise}", attribut_Compartiment_Id:"${compt.id}", deleted: false`
                            )
                            .then((atrform: any) => {
                              if (
                                atrform.searchAttributFormulaire.results
                                  .length > 0
                              ) {
                                compt.attributs =
                                  atrform.searchAttributFormulaire.results;
                                element.formulaire.compartiments.push(compt);
                              }
                            });
                          // fin
                        }
                      },
                      (err) => {}
                    );
                });
            }
            this.showspin = false;
            this.show = true;
            this.store.dispatch(setForm({ mforms: couche.results }));
            resolve(couche.results);
          }
        })
        .catch((reason) => this.utils.setErrorsHttp(reason));
    });
  }

  onSubmit(f, couche) {
    console.clear();

    this.acteurService
      .create(
        JSON.stringify(
          turf.point([this.localisation.longitude, this.localisation.latitude])
        ),
        couche.id,
        false,
        10,
        false,
        "",
        2,
        this.userEntreprise.id
      )
      .then(
        (res: any) => {
          if (f.value.InformationsPersoActeur) {
            this.PersService.create(
              new Date().toISOString().toString(),
              f.value.InformationsPersoActeur.date_naiss
                ? new Date(f.value.InformationsPersoActeur.date_naiss)
                    .toISOString()
                    .toString()
                : new Date().toISOString().toString(),
              res.id,
              f.value.InformationsPersoActeur.autre_piece
                ? f.value.InformationsPersoActeur.autre_piece
                : "",
              f.value.InformationsPersoActeur.code_planteur
                ? f.value.InformationsPersoActeur.code_planteur
                : "",
              f.value.InformationsPersoActeur.contact
                ? f.value.InformationsPersoActeur.contact
                : "",
              false,
              f.value.InformationsPersoActeur.genre
                ? f.value.InformationsPersoActeur.genre
                : 1,
              f.value.InformationsPersoActeur.ident_mobile
                ? f.value.InformationsPersoActeur.ident_mobile
                : "",
              f.value.InformationsPersoActeur.nationalite
                ? f.value.InformationsPersoActeur.nationalite
                : "",
              f.value.InformationsPersoActeur.nom
                ? f.value.InformationsPersoActeur.nom
                : "",
              f.value.InformationsPersoActeur.numero_de_piece
                ? f.value.InformationsPersoActeur.numero_de_piece
                : "",
              f.value.InformationsPersoActeur.origine
                ? f.value.InformationsPersoActeur.origine
                : "",
              f.value.InformationsPersoActeur.photo
                ? f.value.InformationsPersoActeur.photo
                : "",
              f.value.InformationsPersoActeur.prenoms
                ? f.value.InformationsPersoActeur.prenoms
                : "",
              f.value.InformationsPersoActeur.type_piece
                ? f.value.InformationsPersoActeur.type_piece
                : "",
              this.userEntreprise.id,
              f.value.InformationsPersoActeur.nature_demande
                ? f.value.InformationsPersoActeur.nature_demande
                : "",
              f.value.InformationsPersoActeur.village
                ? f.value.InformationsPersoActeur.village
                : "",
              f.value.InformationsPersoActeur.chef_village
                ? f.value.InformationsPersoActeur.chef_village
                : ""
            )
              .then(
                (res) => {},
                (err) => {}
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }
          if (f.value.InformationsFamilleActeurPers) {
            this.fammilleService
              .create(
                res.id,
                f.value.InformationsFamilleActeurPers.concubinage
                  ? f.value.InformationsFamilleActeurPers.concubinage
                  : false,
                false,
                f.value.InformationsFamilleActeurPers.nbre_concubinage
                  ? f.value.InformationsFamilleActeurPers.nbre_concubinage
                  : 0,
                f.value.InformationsFamilleActeurPers.nbre_epouses
                  ? f.value.InformationsFamilleActeurPers.nbre_epouses
                  : 0,
                f.value.InformationsFamilleActeurPers.nbre_filles_scol
                  ? f.value.InformationsFamilleActeurPers.nbre_filles_scol
                  : 0,
                f.value.InformationsFamilleActeurPers.nbre_garcons_scol
                  ? f.value.InformationsFamilleActeurPers.nbre_garcons_scol
                  : 0,
                f.value.InformationsFamilleActeurPers.nbre_pers_a_charge
                  ? f.value.InformationsFamilleActeurPers.nbre_pers_a_charge
                  : 0,
                f.value.InformationsFamilleActeurPers.nbre_total_enf
                  ? f.value.InformationsFamilleActeurPers.nbre_total_enf
                  : 0,
                f.value.InformationsFamilleActeurPers.sit_matrim
                  ? f.value.InformationsFamilleActeurPers.sit_matrim
                  : "",
                this.userEntreprise.id
              )
              .then(
                (res) => {},
                (err) => {}
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }

          if (f.value.InfoFormationsActeurPers) {
            this.infoFormationSevice
              .create(
                res.id,
                f.value.InfoFormationsActeurPers.amelioration_travail
                  ? f.value.InfoFormationsActeurPers.amelioration_travail
                  : false,
                f.value.InfoFormationsActeurPers.certification
                  ? f.value.InfoFormationsActeurPers.certification
                  : "",
                f.value.InfoFormationsActeurPers.date_certif
                  ? new Date(f.value.InfoFormationsActeurPers.date_certif)
                      .toISOString()
                      .toString()
                  : new Date().toISOString().toString(),
                false,
                "",
                f.value.InfoFormationsActeurPers.niv_d_etude
                  ? f.value.InfoFormationsActeurPers.niv_d_etude
                  : "",
                f.value.InfoFormationsActeurPers.nombre_de_session_formation
                  ? f.value.InfoFormationsActeurPers.nombre_de_session_formation
                  : 0,
                f.value.InfoFormationsActeurPers.non_scolarise
                  ? f.value.InfoFormationsActeurPers.non_scolarise
                  : "",
                f.value.InfoFormationsActeurPers.participation_formation
                  ? f.value.InfoFormationsActeurPers.participation_formation
                  : "",
                f.value.InfoFormationsActeurPers.preuve_certif_image
                  ? f.value.InfoFormationsActeurPers.preuve_certif_image
                  : "",
                f.value.InfoFormationsActeurPers.raison_inutile
                  ? f.value.InfoFormationsActeurPers.raison_inutile
                  : "",
                this.userEntreprise.id
              )
              .then(
                (formation) => {},
                (err) => {}
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }

          if (f.value.InformationsSocioprofActeurPers) {
            this.socioProfService
              .create(
                res.id,
                f.value.InformationsSocioprofActeurPers.activite_principale
                  ? f.value.InformationsSocioprofActeurPers.activite_principale
                  : "",
                f.value.InformationsSocioprofActeurPers.activite_secondaire
                  ? f.value.InformationsSocioprofActeurPers.activite_secondaire
                  : "",
                f.value.InformationsSocioprofActeurPers
                  .annees_d_experience_act_princ
                  ? f.value.InformationsSocioprofActeurPers
                      .annees_d_experience_act_princ
                  : 0,
                f.value.InformationsSocioprofActeurPers
                  .annees_d_experience_act_sec
                  ? f.value.InformationsSocioprofActeurPers
                      .annees_d_experience_act_sec
                  : 0,
                false,
                "",
                this.userEntreprise.id,
                f.value.InformationsSocioprofActeurPers.profession
                  ? f.value.InformationsSocioprofActeurPers.profession
                  : ""
              )
              .then(
                (res) => {},
                (err) => {}
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }

          if (f.value.InformationsSourceRevActeurPers) {
            this.sourceRevService
              .create(
                res.id,
                f.value.InformationsSourceRevActeurPers.benef_last_recolte
                  ? f.value.InformationsSourceRevActeurPers.benef_last_recolte
                  : 0,
                false,
                "",
                f.value.InformationsSourceRevActeurPers.montant_epargne
                  ? f.value.InformationsSourceRevActeurPers.montant_epargne
                  : 0,
                "",
                f.value.InformationsSourceRevActeurPers.revenus_suffisant
                  ? f.value.InformationsSourceRevActeurPers.revenus_suffisant
                  : false,
                f.value.InformationsSourceRevActeurPers
                  .srce_revenu_autre_act_princ
                  ? f.value.InformationsSourceRevActeurPers
                      .srce_revenu_autre_act_princ
                  : "",
                this.userEntreprise.id
              )
              .then(
                (res) => {},
                (err) => {}
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }

          if (f.value.InformationsQualiteDeVieActeurPers) {
            this.qualiteDevieService
              .create(
                res.id,
                false,
                f.value.InformationsQualiteDeVieActeurPers.distance_maison_ecole
                  ? f.value.InformationsQualiteDeVieActeurPers
                      .distance_maison_ecole
                  : "",
                f.value.InformationsQualiteDeVieActeurPers
                  .distance_maison_hopital
                  ? f.value.InformationsQualiteDeVieActeurPers
                      .distance_maison_hopital
                  : "",
                "",
                f.value.InformationsQualiteDeVieActeurPers.moyen_de_deplacement
                  ? f.value.InformationsQualiteDeVieActeurPers
                      .moyen_de_deplacement
                  : "",
                f.value.InformationsQualiteDeVieActeurPers.nature_du_logement
                  ? f.value.InformationsQualiteDeVieActeurPers
                      .nature_du_logement
                  : "",
                f.value.InformationsQualiteDeVieActeurPers
                  .nature_du_logement_autre
                  ? f.value.InformationsQualiteDeVieActeurPers
                      .nature_du_logement_autre
                  : "",
                f.value.InformationsQualiteDeVieActeurPers
                  .nature_toit_du_logement
                  ? f.value.InformationsQualiteDeVieActeurPers
                      .nature_toit_du_logement
                  : "",
                f.value.InformationsQualiteDeVieActeurPers.nbre_pieces_menage
                  ? f.value.InformationsQualiteDeVieActeurPers
                      .nbre_pieces_menage
                  : 0,
                f.value.InformationsQualiteDeVieActeurPers.source_d_eau
                  ? f.value.InformationsQualiteDeVieActeurPers.source_d_eau
                  : "",
                f.value.InformationsQualiteDeVieActeurPers.source_d_eclairage
                  ? f.value.InformationsQualiteDeVieActeurPers
                      .source_d_eclairage
                  : "",
                f.value.InformationsQualiteDeVieActeurPers
                  .statut_occupation_logement
                  ? f.value.InformationsQualiteDeVieActeurPers
                      .statut_occupation_logement
                  : "",
                this.userEntreprise.id
              )
              .then(
                (res) => {},
                (err) => {}
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }

          if (f.value.OrganisationActeurPers) {
            this.organisationService
              .create(
                f.value.OrganisationActeurPers.acteur
                  ? f.value.OrganisationActeurPers.acteur
                  : "",
                f.value.OrganisationActeurPers.appartenanceCoop
                  ? f.value.OrganisationActeurPers.appartenanceCoop
                  : "",
                f.value.OrganisationActeurPers.dateEntreeCoop
                  ? f.value.OrganisationActeurPers.dateEntreeCoop
                  : 0,
                false,
                f.value.OrganisationActeurPers.desirAppartenanceCoop
                  ? f.value.OrganisationActeurPers.desirAppartenanceCoop
                  : "",
                "",
                f.value.OrganisationActeurPers.niveauSatisfaction
                  ? f.value.OrganisationActeurPers.niveauSatisfaction
                  : "",
                f.value.OrganisationActeurPers.prkwPasSatisfaisant
                  ? f.value.OrganisationActeurPers.prkwPasSatisfaisant
                  : "",
                f.value.OrganisationActeurPers.receptionEquipementAnneeEnCours
                  ? f.value.OrganisationActeurPers
                      .receptionEquipementAnneeEnCours
                  : "",
                f.value.OrganisationActeurPers.receptionIntrantAnneeEnCours
                  ? f.value.OrganisationActeurPers.receptionIntrantAnneeEnCours
                  : false,
                this.userEntreprise.id
              )
              .then(
                (res) => {},
                (err) => {}
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }

          if (f.value.InformationsGenActeurPlantation) {
            this.infoGenService
              .create(
                res.id,
                f.value.InformationsGenActeurPlantation.annee_obtention_parcelle
                  ? f.value.InformationsGenActeurPlantation
                      .annee_obtention_parcelle.annee_obtention_parcelle
                  : 0,
                f.value.InformationsGenActeurPlantation.code_plantation
                  ? f.value.InformationsGenActeurPlantation.code_plantation
                  : "",
                false,
                "",
                f.value.InformationsGenActeurPlantation
                  .mode_acquisition_parcelle
                  ? f.value.InformationsGenActeurPlantation
                      .mode_acquisition_parcelle
                  : "",
                f.value.InformationsGenActeurPlantation.mode_gestion_parcelle
                  ? f.value.InformationsGenActeurPlantation
                      .mode_gestion_parcelle
                  : "",
                f.value.InformationsGenActeurPlantation.preuve_proprietaire
                  ? f.value.InformationsGenActeurPlantation.preuve_proprietaire
                  : "",
                this.userEntreprise.id
              )
              .then(
                (res) => {},
                (err) => {}
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }

          if (f.value.InformationsMainDoeuvreActeurPlantation) {
            this.maindoeuvreService
              .create(
                res.id,
                false,
                f.value.InformationsMainDoeuvreActeurPlantation
                  .fourchette_paiement
                  ? f.value.InformationsMainDoeuvreActeurPlantation
                      .fourchette_paiement
                  : "",
                "",
                f.value.InformationsMainDoeuvreActeurPlantation
                  .moment_util_main_doeuvre
                  ? f.value.InformationsMainDoeuvreActeurPlantation
                      .moment_util_main_doeuvre
                  : "",
                f.value.InformationsMainDoeuvreActeurPlantation.moyen_paiement
                  ? f.value.InformationsMainDoeuvreActeurPlantation
                      .moyen_paiement
                  : "",
                f.value.InformationsMainDoeuvreActeurPlantation
                  .nature_main_doeuvre
                  ? f.value.InformationsMainDoeuvreActeurPlantation
                      .nature_main_doeuvre
                  : "",
                f.value.InformationsMainDoeuvreActeurPlantation
                  .origine_maindoeuvre
                  ? f.value.InformationsMainDoeuvreActeurPlantation
                      .origine_maindoeuvre
                  : "",
                f.value.InformationsMainDoeuvreActeurPlantation
                  .temps_travail_par_jour
                  ? f.value.InformationsMainDoeuvreActeurPlantation
                      .temps_travail_par_jour
                  : "",
                f.value.InformationsMainDoeuvreActeurPlantation
                  .type_main_doeuvre
                  ? f.value.InformationsMainDoeuvreActeurPlantation
                      .type_main_doeuvre
                  : "",
                f.value.InformationsMainDoeuvreActeurPlantation.type_paiement
                  ? f.value.InformationsMainDoeuvreActeurPlantation
                      .type_paiement
                  : "",
                this.userEntreprise.id
              )
              .then(
                (res) => {},
                (err) => {}
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }

          if (f.value.InformationsEntretienActeurPlantation) {
            this.entretientActService
              .create(
                f.value.InformationsEntretienActeurPlantation.achete_par
                  ? f.value.InformationsEntretienActeurPlantation.achete_par
                  : "",
                res.id,
                f.value.InformationsEntretienActeurPlantation.cout_angrais
                  ? f.value.InformationsEntretienActeurPlantation.cout_angrais
                  : null,
                false,
                f.value.InformationsEntretienActeurPlantation
                  .freq_traitement_phyto
                  ? f.value.InformationsEntretienActeurPlantation
                      .freq_traitement_phyto
                  : "",
                "",
                f.value.InformationsEntretienActeurPlantation.lieu_achat_angrais
                  ? f.value.InformationsEntretienActeurPlantation
                      .lieu_achat_angrais
                  : "",
                f.value.InformationsEntretienActeurPlantation.nom_org_achat
                  ? f.value.InformationsEntretienActeurPlantation.nom_org_achat
                  : "",
                f.value.InformationsEntretienActeurPlantation.periode_traitement
                  ? f.value.InformationsEntretienActeurPlantation
                      .periode_traitement
                  : "",
                f.value.InformationsEntretienActeurPlantation.qte_angrais
                  ? f.value.InformationsEntretienActeurPlantation.qte_angrais
                  : null,
                f.value.InformationsEntretienActeurPlantation.traitement_phyto
                  ? f.value.InformationsEntretienActeurPlantation
                  : "",
                f.value.InformationsEntretienActeurPlantation.type_angrais
                  ? f.value.InformationsEntretienActeurPlantation.type_angrais
                  : "",
                this.userEntreprise.id,
                f.value.InformationsEntretienActeurPlantation
                  .utilisation_angrais
                  ? f.value.InformationsEntretienActeurPlantation
                      .utilisation_angrais
                  : ""
              )
              .then(
                (res) => {},
                (err) => {}
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }

          if (f.value.InformationProcductionActeurPlantation) {
            this.productionService
              .create(
                f.value.InformationProcductionActeurPlantation.date_production
                  ? new Date(
                      f.value.InformationProcductionActeurPlantation.date_production
                    )
                      .toISOString()
                      .toString()
                  : new Date().toISOString().toString(),
                res.id,
                false,
                "",
                f.value.InformationProcductionActeurPlantation.qte_produite
                  ? f.value.InformationProcductionActeurPlantation.qte_produite
                  : 0,
                this.userEntreprise.id,
                f.value.InformationProcductionActeurPlantation.type_acheteur
                  ? f.value.InformationProcductionActeurPlantation.type_acheteur
                  : "",
                f.value.InformationProcductionActeurPlantation.nom_acheteur
                  ? f.value.InformationProcductionActeurPlantation.type_acheteur
                  : ""
              )
              .then(
                (res) => {},
                (err) => {}
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }

          if (f.value.InformationDelimitationActeurPlantation) {
          }

          if (f.value.InformationNotationActeurPlantation) {
            this.notationService
              .create(
                f.value.InformationNotationActeurPlantation.acces
                  ? f.value.InformationNotationActeurPlantation.acces
                  : 0,
                res.id,
                f.value.InformationNotationActeurPlantation.commentaire
                  ? f.value.InformationNotationActeurPlantation.commentaire
                  : "",
                false,
                f.value.InformationNotationActeurPlantation.densite
                  ? f.value.InformationNotationActeurPlantation.densite
                  : 0,
                "",
                f.value.InformationNotationActeurPlantation.nettoyage
                  ? f.value.InformationNotationActeurPlantation.nettoyage
                  : 0,
                f.value.InformationNotationActeurPlantation.sante_plante
                  ? f.value.InformationNotationActeurPlantation.sante_plante
                  : 0,
                this.userEntreprise.id
              )
              .then(
                (res) => {},
                (err) => {}
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }
        },
        (err) => {}
      );
  }

  public getMyPosition(): any {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        // L"API est disponible
        navigator.geolocation.getCurrentPosition(maPosition, errorCallback, {
          enableHighAccuracy: true,
          timeout: 10000,
        });
      } else {
        // Pas de support, proposer une alternative ?
        
        reject("La géolocalisation n'est pas supportée par le navigateur.");
      }

      async function maPosition(position) {
        resolve(position.coords);
      }

      // La fonction d"échec teste l"erreur contenue dans "error"
      function errorCallback(error) {
        //Un message d"erreur est affiché selon le type d'erreur
        switch (error.code) {
          case error.PERMISSION_DENIED:
            break;
          case error.POSITION_UNAVAILABLE:
            break;
          case error.TIMEOUT:
            break;
          case error.UNKNOW_ERROR:
            break;
        }
        reject(error);
      }
    });
  }
}
