import { Injectable } from "@angular/core";
// import * as L from 'leaflet';
import { UtilsService } from "./utils.service";
import { ActeurService } from "./acteurs.service";
declare var L: any;
@Injectable({
  providedIn: "root",
})
export class PlantationPerformanceService {
  plantations: any;
  cpt = 0;
  // parcs: any = [];
  // pays: any = [];
  markerOrLine: number = 2;
  map: any = null;
  clusterPlantationLine = L.markerClusterGroup();
  clusterPlantationMarker = L.markerClusterGroup();
  clusterPlantationOnParcLine = L.markerClusterGroup();
  clusterPlantationOnParcMarker = L.markerClusterGroup();
  clusterParcsLine = L.markerClusterGroup();

  checkbox: any = [
    { select: true, id: 1, libelle: 'Plantations légales' },
    { select: true, id: 3, libelle: 'Plantations illégales' },
    { select: false, id: 2, libelle: 'Parcs' },
  ];

  constructor(private U: UtilsService, private acteurServ: ActeurService) {}

  public addPlantationOnMap(
    elt: any,
    color = "#058B46",
    type = 1,
    kmlBaseUrl: String = this.U.API_URL,
    argThis = this
  ): void {


    try {
      // if (this.plantations.findIndex((item: any) => item.id === elt.id) < 0) {
        let LeafIcon = L.Icon.extend({
          options: {
            iconUrl:
              "../../../assets/img/sig/Dashboard-Map/drawable-xhdpi/Composant 14 – 11.png",
            iconSize: [60, 65], // size of the icon [largeur, hauteur]
            shadowSize: [50, 64], // size of the shadow
            iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62], // the same for the shadow
            popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
          },
        });
        let plantationIcon = new LeafIcon();

        let codePlantation =
          elt.acteurGen[0] && elt.acteurGen[0].codePlantation
            ? elt.acteurGen[0].codePlantation
            : "Inconnu";
        let surface =
          elt.acteurDelimitation[0] && elt.acteurDelimitation[0].surface
            ? elt.acteurDelimitation[0].surface
            : "Inconnu";
        let perimetre =
          elt.acteurDelimitation[0] && elt.acteurDelimitation[0].perimetre
            ? elt.acteurDelimitation[0].perimetre
            : "Inconnu";
        let kmlUrl =
          elt.acteurDelimitation[0] && elt.acteurDelimitation[0].kml
            ? kmlBaseUrl + elt.acteurDelimitation[0].kml
            : "";

        let culture = "";

        if (elt.acteurDelimitation.length === 0) {
          culture = "Inconnu";
        } else {
          culture = elt.acteurDelimitation[0].culture;
        }

        if (elt.acteurDelimitation.length > 0) {
          let html = `
          <div class="myplantationpopup"><br>
          <h6 style="text-align: center" class="greenColor"><strong>PLANTATION</strong></h6>
          <center>
            <p class="blackColor">Code de la plantation: <b>${codePlantation}</b></p>
            <p class="blackColor">Surface: <b>${surface} hectares </b><p/>
            <p class="blackColor">Périmètre: <b>${perimetre} mètres </b><p/>
            <p class="blackColor">Cultures:  <b>${culture}</b></p>
            ${
              kmlUrl !== ""
                ? `
            <a class="download-kml" href="${kmlUrl}" title="Télécharger le fichier KML">
            <img class="kml-img" src="../../../assets/img/sig/Dashboard-Map//drawable-xhdpi/kmz.png"/>
            Télécharger le fichier KML
            </a>`
                : `<a class="download-kml-desable"  title="Télécharger le fichier KML">
            <img class="kml-img" src="../../../assets/img/sig/Dashboard-Map//drawable-xhdpi/kmz.png"/>
            Télécharger le fichier KML
            </a>`
            }

          </center>
        <div>
         `;

          let geoJ = L.geoJSON(
            JSON.parse(
              this.U.replaceAll("'", '"', elt.acteurDelimitation[0].geometrie)
            ),
            {
              color: color,
              weight: 4,
              opacity: 1,
              onEachFeature: function (feature, layer) {
                // layer.editing._marker.options.icon = plantationIcon;
                layer.bindPopup(html, {
                  className: "custom-pop-up-plantation",
                }),
                  layer.on("click", function (e, argThisPrime = argThis) {
                    argThisPrime.readProprioPlantation(elt.idSuperieur);
                  });
              },
            }
          );

          if (type == 1) {
            this.clusterPlantationLine.addLayer(geoJ);
            // this.map.addLayer(this.clusterPlantationLine);
            // this.map.fitBounds(this.clusterPlantationLine.getBounds());
          } else {
            this.clusterPlantationOnParcLine.addLayer(geoJ);
            // this.map.addLayer(this.clusterPlantationOnParcLine);
            // this.map.fitBounds(this.clusterPlantationOnParcLine.getBounds());
          }

          let marker = L.geoJson(
            JSON.parse(this.U.replaceAll("'", '"', elt.coordonnees)),
            {
              onEachFeature: function (feature, layer) {
                layer.editing._marker.options.icon = plantationIcon;
                layer.bindPopup(html, {
                  className: "custom-pop-up-plantation",
                });

                layer.on("click", function (e, argThisPrime = argThis) {
                  argThisPrime.readProprioPlantation(elt.idSuperieur);
                });
              },
            }
          );

          if (type == 1) {
            this.clusterPlantationMarker.addLayer(marker);
            // this.map.addLayer(this.clusterPlantationMarker);
            // this.map.fitBounds(this.clusterPlantationMarker.getBounds());
          } else {
            this.clusterPlantationOnParcMarker.addLayer(marker);
            // this.map.addLayer(this.clusterPlantationOnParcMarker);
            // this.map.fitBounds(this.clusterPlantationOnParcMarker.getBounds());
          }
        }
        this.view();
      // } else {
      // }
    } catch (error) {
      this.cpt++;
    }
  }

  public readProprioPlantation(idSuperieur: string): any {
    // this.acteurServ.readLocale(`id: "${idSuperieur}", couche_TypeCouche_Libelle: "Planteur", deleted: false`).then(
    this.acteurServ
      .readActeur(
        `id: "${idSuperieur}", couche_TypeCouche_Etiquette: "PLANTEUR", deleted: false`
      )
      .then(
        (res: any) => {
          // console.clear();
          let elt = res.results[0];
          let p = document.querySelectorAll(".myplantationpopup");
          let q = document.querySelectorAll(".proprio");
          q.forEach((el, i) => {
            q[i].parentNode.removeChild(q[i]);
          });

          p.forEach((el, i) => {
            p[i].innerHTML =
              `
          <div class="row proprio">
            <div class="col-12">
              <h6 style="text-align: center" class="greenColor"><strong>PLANTEUR</strong><br></h6>
            </div>
            <div class="col-9">
              <p class="blackColor"><b>${
                elt.acteurPerso[0].nom + " " + elt.acteurPerso[0].prenoms
              }</b></p>
              <p class="blackColor">Contact: <b>${
                elt.acteurPerso[0].contact
              }</b></p>
            </div>
            <div class="col-3">
              <img style="height: auto; width: 100%;" src="${
                this.U.API_URL + elt.acteurPerso[0].photoUrl
              }"/>
            </div>
          </div>
        ` + p[i].innerHTML;
          });
        },
        (err) => {}
      );
    // }
  }


  public view(): void {
    if (this.markerOrLine === 2 && this.map) {
      // marker visible
      this.map.removeLayer(this.clusterPlantationOnParcLine);
      this.map.removeLayer(this.clusterPlantationLine);
      // this.map.removeLayer(this.clusterParcsLine);
      this.checkbox.forEach((elt, i) => {
        if (elt.id == 1 && !elt.select) {
          this.map.addLayer(this.clusterPlantationMarker);
        } else if (elt.id == 2 && !elt.select) {
          this.map.addLayer(this.clusterParcsLine);
        } else if (elt.id == 3 && !elt.select) {
          this.map.addLayer(this.clusterPlantationOnParcMarker);
        }
      });
    } else {
      // ligne visible
      if (this.map) {
        this.map.removeLayer(this.clusterPlantationOnParcMarker);
        this.map.removeLayer(this.clusterPlantationMarker);
        // this.map.removeLayer(this.clusterParcsMarker);
        this.checkbox.forEach((elt, i) => {
          if (elt.id == 1 && !elt.select) {
            this.map.addLayer(this.clusterPlantationLine);
          } else if (elt.id == 2 && !elt.select) {
            this.map.addLayer(this.clusterParcsLine);
          } else if (elt.id == 3 && !elt.select) {
            this.map.addLayer(this.clusterPlantationOnParcLine);
          }
        });
      }
    }
  }
}
