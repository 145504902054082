import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class GetExcelService {

  constructor(
    private http: HttpClient,
    private utils: UtilsService
  ) {

   }

   getExcel(params: string){
    console.log('param execel', this.utils.API_HOST + 'get-excel/' + params)
    const idToken = this.utils.getCookie('weflyHeader_token');
     return this.http.get(this.utils.API_HOST + 'get-excel/' + params)
   }
}
