import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class InformationDelimitationActeurPlantationService {

  constructor(private utils: UtilsService) { }

  create(dateCreationMobile: String, dateDelimitation: String, acteur: String, courDEau: Boolean, deleted: Boolean, erosion: Boolean, geometrie: String, identMobile: String, imageParcelle: String, nomGuide: String, perimetre: Number, pointDrone: String, rang: Number, region: String, surface: Number, telPhone: String, tempsDelim: Number, typeCourEau: String, typeDeDelimitation: String, userDelimitation: String, ) {
    const query = `
    mutation(
		$dateCreationMobile: CustomDateTime!,
		$dateDelimitation: CustomDateTime!,
		$acteur: ID,
		$courDEau: Boolean,
		$deleted: Boolean,
		$erosion: Boolean,
		$geometrie: String,
		$identMobile: String,
		$imageParcelle: String,
		$nomGuide: String,
		$perimetre: Float,
		$pointDrone: String,
		$rang: Int,
		$region: ID,
		$surface: Float,
		$telPhone: String,
		$tempsDelim: Int,
		$typeCourEau: String,
		$typeDeDelimitation: String,
		$userDelimitation: ID,

	) {
      createInformationDelimitationActeurPlantation(newInformationdelimitationacteurplantation: {
			dateCreationMobile: $dateCreationMobile,
			dateDelimitation: $dateDelimitation,
			acteur: $acteur,
			courDEau: $courDEau,
			deleted: $deleted,
			erosion: $erosion,
			geometrie: $geometrie,
			identMobile: $identMobile,
			imageParcelle: $imageParcelle,
			nomGuide: $nomGuide,
			perimetre: $perimetre,
			pointDrone: $pointDrone,
			rang: $rang,
			region: $region,
			surface: $surface,
			telPhone: $telPhone,
			tempsDelim: $tempsDelim,
			typeCourEau: $typeCourEau,
			typeDeDelimitation: $typeDeDelimitation,
			userDelimitation: $userDelimitation,

		}) {
        informationdelimitationacteurplantation {
          id
					dateCreationMobile,
					dateDelimitation,
					acteur{ id },
					courDEau,
					deleted,
					erosion,
					geometrie,
					identMobile,
					imageParcelle,
					nomGuide,
					perimetre,
					pointDrone,
					rang,
					region{ id },
					surface,
					telPhone,
					tempsDelim,
					typeCourEau,
					typeDeDelimitation,
					userDelimitation{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {dateCreationMobile,
					dateDelimitation,
					acteur,
					courDEau,
					deleted,
					erosion,
					geometrie,
					identMobile,
					imageParcelle,
					nomGuide,
					perimetre,
					pointDrone,
					rang,
					region,
					surface,
					telPhone,
					tempsDelim,
					typeCourEau,
					typeDeDelimitation,
					userDelimitation,
					}).then(
          (res) => {
            if (res['createInformationDelimitationActeurPlantation']['ok']) {
              resolve(res['createInformationDelimitationActeurPlantation']['informationdelimitationacteurplantation'])
            }
            reject(res['createInformationDelimitationActeurPlantation']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, dateCreationMobile: String, dateDelimitation: String, acteur: String, courDEau: Boolean, deleted: Boolean, erosion: Boolean, geometrie: String, identMobile: String, imageParcelle: String, nomGuide: String, perimetre: Number, pointDrone: String, rang: Number, region: String, surface: Number, telPhone: String, tempsDelim: Number, typeCourEau: String, typeDeDelimitation: String, userDelimitation: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$dateCreationMobile: CustomDateTime!,
		$dateDelimitation: CustomDateTime!,
		$acteur: ID,
		$courDEau: Boolean,
		$deleted: Boolean,
		$erosion: Boolean,
		$geometrie: String,
		$identMobile: String,
		$imageParcelle: String,
		$nomGuide: String,
		$perimetre: Float,
		$pointDrone: String,
		$rang: Int,
		$region: ID,
		$surface: Float,
		$telPhone: String,
		$tempsDelim: Int,
		$typeCourEau: String,
		$typeDeDelimitation: String,
		$userDelimitation: ID,
		) {
      updateInformationDelimitationActeurPlantation(newInformationdelimitationacteurplantation: {
				id: $id, dateCreationMobile: $dateCreationMobile,
			dateDelimitation: $dateDelimitation,
			acteur: $acteur,
			courDEau: $courDEau,
			deleted: $deleted,
			erosion: $erosion,
			geometrie: $geometrie,
			identMobile: $identMobile,
			imageParcelle: $imageParcelle,
			nomGuide: $nomGuide,
			perimetre: $perimetre,
			pointDrone: $pointDrone,
			rang: $rang,
			region: $region,
			surface: $surface,
			telPhone: $telPhone,
			tempsDelim: $tempsDelim,
			typeCourEau: $typeCourEau,
			typeDeDelimitation: $typeDeDelimitation,
			userDelimitation: $userDelimitation,
			}) {
        informationdelimitationacteurplantation {
          id
					dateCreationMobile,
					dateDelimitation,
					acteur{ id },
					courDEau,
					deleted,
					erosion,
					geometrie,
					identMobile,
					imageParcelle,
					nomGuide,
					perimetre,
					pointDrone,
					rang,
					region{ id },
					surface,
					telPhone,
					tempsDelim,
					typeCourEau,
					typeDeDelimitation,
					userDelimitation{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, dateCreationMobile,
					dateDelimitation,
					acteur,
					courDEau,
					deleted,
					erosion,
					geometrie,
					identMobile,
					imageParcelle,
					nomGuide,
					perimetre,
					pointDrone,
					rang,
					region,
					surface,
					telPhone,
					tempsDelim,
					typeCourEau,
					typeDeDelimitation,
					userDelimitation,
					}).then(
          (res) => {
            if (res['updateInformationDelimitationActeurPlantation']['ok']) {
              resolve(res['updateInformationDelimitationActeurPlantation']['informationdelimitationacteurplantation'])
            }
            reject(res['updateInformationDelimitationActeurPlantation']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchInformationDelimitationActeurPlantation (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					dateCreationMobile,
					dateDelimitation,
					acteur{ id },
					courDEau,
					deleted,
					erosion,
					geometrie,
					identMobile,
					imageParcelle,
					nomGuide,
					perimetre,
					pointDrone,
					rang,
					region{ id },
					surface,
					telPhone,
					tempsDelim,
					typeCourEau,
					typeDeDelimitation,
					userDelimitation{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

