import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';

@Injectable({
  providedIn: 'root'
})
export class CoucheService {

  constructor(private utils: UtilsService) { }
  create(
		coucheSup :  String,
		description:  String,
		entreprise:  String,
		libelle:  String,
		principal:  Boolean,
		typeCouche:  String,
){
    const query = `
    mutation(
			$coucheSup :  ID!,
			$description:  String!,
			$entreprise:  ID!,
			$libelle:  String!,
			$principal:  Boolean!,
			$typeCouche:  ID!,
    ) {
      createCouche(newCouche: {
				coucheSup : $coucheSup ,
				description: $description,
				entreprise: $entreprise,
				libelle: $libelle,
				principal: $principal,
        typeCouche: $typeCouche,
        createur : 1
      }) {
        ok,
        errors{
          messages
        },
        couche{
          id,
          coucheSup{
            id
          } ,
          description,
          entreprise{
            id
          },
          libelle,
          principal,
          typeCouche{
            id
          },
          entreprise {
            id
            raisonSociale
          },
          deleted
        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          coucheSup ,
          description,
          entreprise,
          libelle,
          principal,
          typeCouche,
        }).then(
          (res) => {
            if(res['createCouche']['ok']){
              resolve(res['createCouche']['couche'])
            }
            reject(res['createCouche']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }


  update(
    id: String,
		coucheSup :  String,
		description:  String,
		entreprise:  String,
		libelle:  String,
		principal:  Boolean,
    typeCouche:  String,
    deleted: Boolean = false
  ) {
    const query = `
    mutation(
      $id: UUID!,
			$coucheSup :  ID!,
			$description:  String!,
			$entreprise:  ID!,
			$libelle:  String!,
			$principal:  Boolean!,
      $typeCouche:  ID!,
      $deleted: Boolean,
    ) {
      updateCouche(newCouche: {
        id : $id,
				coucheSup : $coucheSup ,
				description: $description,
				entreprise: $entreprise,
				libelle: $libelle,
				principal: $principal,
        typeCouche: $typeCouche,
        createur : 1,
        deleted: $deleted
      }){
        ok,
        errors{
          messages
        },
        couche{
          id,
          coucheSup{
            id
          } ,
          description,
          entreprise{
            id
          },
          libelle,
          principal,
          typeCouche{
            id
          }
          entreprise {
            id
            raisonSociale
          }
          deleted
        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id,  coucheSup , description, entreprise, libelle, principal, typeCouche, deleted }).then(
          (res) => {
            if(res['updateCouche']['ok']){
              resolve(res['updateCouche']['couche'])
            }
            reject(res['updateCouche']['errors']);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  delete(
    id: String,
    deleted : Boolean = true
  ) {
    const query = `
    mutation(
      $id: UUID!,
      $deleted: Boolean,
    ) {
      updateCouche(newCouche: {
        id : $id,
        deleted: $deleted
      }){
        ok,
        errors{
          messages
        },
        couche{
          id,
          coucheSup{
            id
          } ,
          description,
          entreprise{
            id
          },
          libelle,
          principal,
          typeCouche{
            id
          }
          entreprise {
            id
            raisonSociale
          }
          deleted
        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, deleted }).then(
          (res) => {
            if(res['updateCouche']['ok']){
              resolve(res['updateCouche']['couche'])
            }
            reject(res['updateCouche']['errors']);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = `deleted: false`, page: number = 1, limit: number = this.utils.PAGINATION, order: string = '-created_at') {
    const query = `
    query{
      searchCouche(${filter}){
        totalCount,
        results(page: ${page}, ordering: "${order}"){
          id,
          libelle,
          principal,
          description,
          coucheSup{
            id
          },
          createur{
            id
          },
          typeCouche{
            id
            libelle
          },
          entreprise {
            id
            raisonSociale
          },
          deleted
        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { limit, page }).then(
          (res) => {
            resolve(res['searchCouche']);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }
}
