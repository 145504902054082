import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, OnDestroy {
  sendToParent: any = '___'; // cette variable est utilisé dans la mesure où le user clique à l'extérieur du pop-up pour le fermer, dans ce cas on envoie 3 underscores pour différencier.
  constructor(
    private matdialog: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() { 
  }

  ngOnDestroy() {
    this.matdialog.close(this.sendToParent);
  }

  yes() {
    this.sendToParent = true;
    this.matdialog.close(true);
  }

  no() {
    this.sendToParent = false;
    this.matdialog.close(false);
  }

}
