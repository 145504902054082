import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class ActeurAttributValeurService {

  constructor(private utils: UtilsService) { }

  create(acteur: String, attribut: String, deleted: Boolean, valeur: String, ) {
    const query = `
    mutation(
		$acteur: ID!,
		$attribut: ID!,
		$deleted: Boolean,
		$valeur: String,

	) {
      createActeurAttributValeur(newActeurattributvaleur: {
			acteur: $acteur,
			attribut: $attribut,
			deleted: $deleted,
			valeur: $valeur,

		}) {
        acteurattributvaleur {
          id
					acteur{ id },
					attribut{ id },
					deleted,
					valeur,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {acteur,
					attribut,
					deleted,
					valeur,
					}).then(
          (res) => {
            if (res['createActeurAttributValeur']['ok']) {
              resolve(res['createActeurAttributValeur']['acteurattributvaleur'])
            }
            reject(res['createActeurAttributValeur']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, acteur: String, attribut: String, deleted: Boolean, valeur: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$acteur: ID!,
		$attribut: ID!,
		$deleted: Boolean,
		$valeur: String,
		) {
      updateActeurAttributValeur(newActeurattributvaleur: {
				id: $id, acteur: $acteur,
			attribut: $attribut,
			deleted: $deleted,
			valeur: $valeur,
			}) {
        acteurattributvaleur {
          id
					acteur{ id },
					attribut{ id },
					deleted,
					valeur,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, acteur,
					attribut,
					deleted,
					valeur,
					}).then(
          (res) => {
            if (res['updateActeurAttributValeur']['ok']) {
              resolve(res['updateActeurAttributValeur']['acteurattributvaleur'])
            }
            reject(res['updateActeurAttributValeur']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchActeurAttributValeur (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					acteur{ id },
					attribut{ id },
					deleted,
					valeur,
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

