import { UtilsService } from 'src/app/_services/utils.service';
import { Component, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatDatepickerInputEvent, MatSnackBar } from '@angular/material';
declare var L: any;

@Component({
  selector: 'app-gestion-parcelle',
  templateUrl: './gestion-parcelle.component.html',
  styleUrls: ['./gestion-parcelle.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class GestionParcelleComponent implements OnInit, OnChanges {
  // tslint:disable-next-line:max-line-length
  link = 'https://instagram.fabj4-1.fna.fbcdn.net/vp/04927fbed5ad1112a674bd21e480e265/5DBCBC06/t50.2886-16/69661606_130445851561066_5734840794355540601_n.mp4?_nc_ht=instagram.fabj4-1.fna.fbcdn.net&_nc_cat=110'; // lien de la video
  navigation = 1; // permet de choisir la data à afficher, soit orthophoto, dtm, dsm, contours, video ou vr

  dateSelected: any; // sauvegarde la date selectionnée par l'utilisateur
  events: string[] = []; // sauvegarde chaque sélection de date du user
  token: string = null;
  httpOptions: any = null;

  geojson: any = []; // tableau de tout les geojson
  map: any = null; // la carte

  geojsonOnMap: any[] = []; // permet de nettoyer les geojsons affiché sur la carte

  constructor(
    public utils: UtilsService,
    private httpClient: HttpClient,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.navigation = 1;

    /*
        this.logServ().then(
          (res) => {
            let token: any = res;
            // console.table(token);
            this.httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.token,
              })
            };
            this.geoServ().then(
              (resp) => {
                this.geojson.push(resp);
                console.table('Jack', this.geojson);
              }, (error) => {
               
              }
            );
          }, (err) => {
          
          }
        );*/
    this.map = L.map('map', {
      center: [5.543148102295488, -3.075159203825636],
      zoom: 15,
      measureControl: true,
    });

    this.ortho().then(
      (res: any) => {
        this.geojson.push(res);
        console.table('Nafiou', this.geojson[0]);
        let geocontent = '';
        // tslint:disable-next-line:variable-name
        const geocontent_onL = null;
        const markers = new L.markerClusterGroup({
          showCoverageOnHover: false,
          singleMarkerMode: true,
          disableClusteringAtZoom: 12
        });
        geocontent = JSON.parse(JSON.stringify(res.polygon).replace(/&quot;/g, '"'));
        const geo = JSON.parse(geocontent);
        const myLayer = L.geoJSON(geo, {
          style: {
            color: '#ff7800',
            weight: 5,
            opacity: 0.65
          },
          onEachFeature: (feature, layer) => {
            // does this feature have a property named popupContent?
            if (feature.properties) {
              layer.bindPopup(
                // tslint:disable-next-line:max-line-length
                '<div class="info parcelle"><div class="row"><div class="col-md-12 content"> <div class="row"><div class="col-md-12"><span class="title_popup">Nom:</span>' + feature.properties.nom + '</div> <div class="col-md-12"><span class="title_popup">Clone:</span>' + feature.properties.clone + '</div><div class="col-md-12"><span class="title_popup">Etat culture:</span> ' + feature.properties.etat_culture + '</div> <div class="col-md-12"><span class="title_popup">Superficie:</span> ' + feature.properties.superficie + ' ha</div><div class="col-md-12"><span class="title_popup">Nombre d\'arbre:</span> ' + feature.properties.nombre_abre + '</div></div> <div class="row"><div class="col-md-6"><a href="/noeuds/acteurs/' + feature.properties.pk + '/voir/"><button>Détails </button></a></div><div class="col-md-6"><a href="/noeuds/acteurs/' + feature.properties.pk + '/cartes/?mapper=True"><button>Carte </button></a></div></div> </div></div></div>'
              );
            }
          }
        }).addTo(this.map);
        // myLayer.addData(geo);

      }, (err) => {
      }
    );



    L.tileLayer('//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      minZoom: 2.5,
      maxZoom: 18,
      // tslint:disable-next-line:max-line-length
      //attribution: '&copy; Esri &mdash; Sources: Esri, DigitalGlobe, Earthstar Geographics, CNES/Airbus DS, GeoEye, USDA FSA, USGS, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community'
    }).addTo(this.map);

    this.map.on('measurefinish', (evt) => {
      writeResults(evt);
    });

    function writeResults(results) {
      document.getElementById('eventoutput').innerHTML = JSON.stringify(
        {
          area: results.area,
          areaDisplay: results.areaDisplay,
          lastCoord: results.lastCoord,
          length: results.length,
          lengthDisplay: results.lengthDisplay,
          pointCount: results.pointCount,
          points: results.points
        },
        null,
        2
      );
    }


    const editableLayers = new L.FeatureGroup();

    // Initialise the draw control and pass it the FeatureGroup of editable layers
    const drawControl = new L.Control.Draw({
      position: 'topright',
      draw: {
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          drawError: {
            color: '#e1e100', // Color the shape will turn when intersects
            message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
          },
          shapeOptions: {
            color: '#97009c'
          }
        },
        // disable toolbar item by setting it to false
        polyline: true,
        circle: true, // Turns off this drawing tool
        rectangle: true,
        marker: true,
      },
      edit: {
        featureGroup: editableLayers, // REQUIRED!!
        remove: true
      }
    });
    this.map.addControl(drawControl);
    this.map.addLayer(editableLayers);
    this.map.on('draw:created', (e) => {
      const type = e.layerTy;
      const layer = e.layer;

      if (type === 'marker') {
        layer.bindPopup('A popup!');
      }

      editableLayers.addLayer(layer);
    });

    this.resizeWindows();
    window.onresize = () => {
      this.resizeWindows();
    };
  }

  ngOnChanges() { }

  resizeWindows() {
    const height = window.innerHeight - 500;
    document.getElementById('map').style.height = height.toString() + 'px';
    document.getElementById('calendar').style.width = document.getElementById('map').offsetWidth + 'px';
  }

  geoServ() {
    return new Promise(
      (resolve, reject) => {
        this.httpClient.get<any>('http://192.168.1.25:8000/regionnal/parks/', this.httpOptions).subscribe(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  logServ() {
    return new Promise(
      (resolve, reject) => {
        this.httpClient.post<any>(
          'http://192.168.1.25:8000/weflygeo-auth/',
          { username: 'wegeo', password: 'wegeoapp' },
          { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
        ).subscribe(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  changeGeoJson(e) {
    let geocontent = '';
    // tslint:disable-next-line:variable-name
    let geocontent_onL = null;
    const markers = L.markerClusterGroup({
      showCoverageOnHover: false,
      singleMarkerMode: true,
      disableClusteringAtZoom: 12
    });
    geocontent = JSON.parse(JSON.stringify(this.geojson[0]).replace(/&quot;/g, '"'));
   
    geocontent_onL = L.geoJson(geocontent, {
      style: (feature) => {
        switch (feature.properties.etat_culture) {
          case 'Mature': return { color: '#111', opacity: 1, weight: 1, fillColor: '#f03', fillOpacity: 0.5 };
          case 'Immature': return { color: '#111', opacity: 1, weight: 1, fillColor: '#f03', fillOpacity: 0 };
        }
      },
      onEachFeature: (feature, layer) => {
        // does this feature have a property named popupContent?
        if (feature.properties) {
          layer.bindPopup(
            // tslint:disable-next-line:max-line-length
            '<div class="info parcelle"> <div class="row"><div class="col-md-12 content"> <div class="row"><div class="col-md-12"><span class="title_popup">Nom:</span>' + feature.properties.nom + '</div> <div class="col-md-12"><span class="title_popup">Clone:</span>' + feature.properties.clone + '</div><div class="col-md-12"><span class="title_popup">Etat culture:</span> ' + feature.properties.etat_culture + '</div> <div class="col-md-12"><span class="title_popup">Superficie:</span> ' + feature.properties.superficie + ' ha</div><div class="col-md-12"><span class="title_popup">Nombre d\'arbre:</span> ' + feature.properties.nombre_abre + '</div></div> <div class="row"><div class="col-md-6"><a href="/noeuds/acteurs/' + feature.properties.pk + '/voir/"><button>Détails </button></a></div><div class="col-md-6"><a href="/noeuds/acteurs/' + feature.properties.pk + '/cartes/?mapper=True"><button>Carte </button></a></div></div> </div></div></div>'
          );
        }
      }
      // style: function (feature) {
      //   return {"fillColor":"rgb(205, 220, 57)", "fillOpacity":"0.15", "color":"rgb(205, 220, 57)" }
      // },
    });
    markers.addLayer(geocontent_onL).addTo('map');
    // this.map.addLayer(markers);
  }

  dateClass = (d: Date) => {
    // colorie toutes les dates où des données sont présentes
    const date = d.getDate();
    // Highlight the 1st and 20th day of each month.
    return (date === 30 || date === 28) ? 'example-custom-date-class' : 'desable-calendar';
  }

  desable = (d: Date): boolean => {
    // désactive toutes les dates où aucunes données n'est présente
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  addEvent(type: string, e: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${e.value}`);
   
  }

  public onDate(e): any { }


  setNavigation(nav: number): void {
   
    this.navigation = nav;
  }

  ortho() {
    return new Promise(
      (resolve, reject) => {
        this.httpClient.get<any>(
          'http://178.33.130.196:8008/noeuds/carto_tiles/',
          { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
        ).subscribe(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }


  public gotsearchValue(e): any { }

}
