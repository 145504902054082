import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';
import gql from 'graphql-tag';
import { CreateAdress } from '../_models/createAdress';
import { Apollo } from 'apollo-angular';

const CREATE_ADRESS = gql`
mutation(
  $pays:  ID!,
  $deleted:  Boolean!,
  $displayName:  String!,
  $rue:  String!,
  $ville:  String!,
) {
  createAdresse(newAdresse: {
    pays: $pays,
    deleted: $deleted,
    displayName: $displayName,
    rue: $rue,
    ville: $ville,
  }){
    adresse{
    id,
    pays{
      name
    },
    deleted,
    displayName,
    rue,
    ville,
    }      }
}

`;

@Injectable({
  providedIn: 'root'
})
export class AdresseService {

  constructor(private utils: UtilsService, private apollo:Apollo) { }

  createAdress(adress:CreateAdress){
    const {pays, displayName, deleted, rue, ville} = adress
    return this.apollo.mutate({
      mutation:CREATE_ADRESS,
      variables:{pays, displayName, deleted, rue, ville}
    })

  }
  pastAdress(adress:CreateAdress){
    return new Promise((resolve, reject)=>{
      this.createAdress(adress).subscribe((res:any)=>{
        resolve(res)
      },(err:any)=>{
        reject(err)
      })
    })
  }
  create(
    pays: String,
    deleted: Boolean,
    displayName: String,
    rue: String,
    ville: String,
  ) {
    const query = `
    mutation(
			$pays:  ID!,
			$deleted:  Boolean!,
			$displayName:  String!,
			$rue:  String!,
			$ville:  String!,
    ) {
      createAdresse(newAdresse: {
				pays: $pays,
				deleted: $deleted,
				displayName: $displayName,
				rue: $rue,
				ville: $ville,
      }){
        adresse{
        id,
				pays{
          name
        },
				deleted,
				displayName,
				rue,
				ville,
				}      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          pays,
          deleted,
          displayName,
          rue,
          ville,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
  update(id: String,
    pays: String,
    deleted: Boolean,
    displayName: String,
    rue: String,
    ville: String,
  ) {
    const query = `
    mutation(
      $id: UUID!,
			$pays:  ID!,
			$deleted:  Boolean!,
			$displayName:  String!,
			$rue:  String!,
			$ville:  String!,
    ) {
      updateAdresse(newAdresse: {
        id: $id,
				pays: $pays,
				deleted: $deleted,
				displayName: $displayName,
				rue: $rue,
				ville: $ville,
      }) {adresse{
        id,
				pays {
          name
        }
				deleted,
				displayName,
				rue,
				ville,
				}      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id,
          pays,
          deleted,
          displayName,
          rue,
          ville,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = `deleted : false`, page: number = 1, limit: number = this.utils.PAGINATION, order: string = 'id') {
    const query = `
    query($page: Int!, $order: String!) {
      searchAdresse(${filter}){
        results(page: $page, ordering: $order)
        { id,
        pays{
          name
        },
				deleted,
				displayName,
				rue,
				ville,

        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { limit, page, order }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

