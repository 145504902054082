import { UtilsService } from "../_services/utils.service";
import axios from "axios";
export const detectionConnexion = (url, divId) => {
  

      // let utils = UtilsService;
  

  let startTime, endTime;
  let imageSize = "";
  let speedInKbps = 0;
  let image = new Image();

  // let kboutput = document.getElementById("kbs");
  let mboutput = document.getElementById(`${divId}`);

  let imageLink = url + "media/static/JooL-ID-new.svg";
  // console.log("imageLink", imageLink);

  image.onload = function () {
    
    endTime = new Date().getTime();
    setInterval(async () => {

      let res = await axios.get(imageLink);
      imageSize = res.headers["content-length"];

      // console.log("imageSize", imageSize*8);
      // await fetch(imageLink).then((response) => {
      //   imageSize = response.headers.get("content-length");
      calculateSpeed();
      // console.log("BitLengt", BitLengt);
      // });
    }, 160000);
  };


  function calculateSpeed() {

   
    let timeDuration = (endTime - startTime) / 1000;

    let loadedBits = imageSize * 8;
    let speedInBps = (loadedBits / timeDuration).toFixed(2);
    speedInKbps = (speedInBps / 1024).toFixed(2);
    let speedInMbps = (speedInKbps / 1024).toFixed(2);
    console.log("speedInKbps", speedInKbps);

  

    // alert(`${speedInKbps} kbps ${speedInMbps} mbps`); Jacques08141831
    // if (speedInKbps <= 0 || speedInKbps === undefined || speedInKbps === null) {
    //   mboutput.innerHTML = `<span style="font-weight: bold;color:green;"> Hors ligne </span>`;
    // }


     if ( speedInKbps > 0 && speedInKbps < 20 ) {

    //   console.log('connexion bonne', speedInKbps)
    //   resolve(speedInKbps)
    //   mboutput.innerHTML = `Etat de la Connexion :<span style="font-weight: bold;color:green;"> Bonne </span>`;
    // } else if (speedInKbps >= 10) {
    //   mboutput.innerHTML = `Etat de la Connexion :<span style="font-weight: bold;color:green;"> Excelente </span>`;
    // } else {
       mboutput.innerHTML = `Etat de la Connexion :<span style="font-weight: bold;color:orange;">  instable </span>`;
     }



    // mboutput.innerHTML = `<span style="font-weight: bold">${speedInMbps} </span>mbps`;
    // return speedInKbps;
  }

  const init = async () => {
    startTime = new Date().getTime();
    image.src = imageLink;
    
  };
  window.onload = () => {
   
    init();
  };

  

};
