import { MatTableDataSource } from '@angular/material/table';
import { CultureService } from './../_services/culture.service';
import { UtilsService } from '../_services/utils.service';
import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { FormStockComponent } from '../@marketplace/_modals/form-stock/form-stock.component';
import { VarieteParCultureService } from '../_services/variete-par-culture.service';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-variete-culture',
  templateUrl: './variete-culture.component.html',
  styleUrls: ['./variete-culture.component.scss']
})
export class VarieteCultureComponent implements OnInit {
  cultures = [];
  varieteCultures = [];
  columnsToDisplay = ['libelle', 'description', 'culture', 'action'];
  dataSource = new MatTableDataSource<any>();
  constructor(private dialog: MatDialog,
              private util: UtilsService,
              private cultureService: CultureService,
              private varieteCultureService: VarieteParCultureService) { }

  ngOnInit() {
  try {
    this.cultureService.read().then(
      (res: []) => {
        this.cultures = res;
      },
      (err) => {

      }
    );
  } catch (error) {
    this.util.setErrorsHttp(error);
  }
  this.readVariete();
  }
  readVariete() {
    this.varieteCultureService.read().then(
      (res: any) => {
        this.varieteCultures = res;
        this.updateDataSource();
      }
    );
  }
  updateDataSource() {
    this.dataSource.connect().next(this.varieteCultures);
  }

  addvariete() {
    const dialogRef = this.dialog.open(FormStockComponent, {
      width: '60%',
      data: { ref: 'VarieteCulture', formType: 'Variété culture', cultures: this.cultures }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.varieteCultures.push(res);
          this.updateDataSource();
          this.util.snack('Variété culture enregistrée avec succès');
        }
      }
    );
  }

  update(i: number) {
    const instanceObj = this.varieteCultures[i];
    const dialogRef = this.dialog.open(FormStockComponent, {
      width: '60%',
      data: { ref: 'VarieteCulture', formType: 'Variété culture', cultures: this.cultures, instance: instanceObj }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.varieteCultures[i] = res;
          this.updateDataSource();
          this.util.snack(`${instanceObj.libelle} mis à jour avec succès`);
        }
      }
    );
  }

  delete(i: number) {
    const instanceObj = this.varieteCultures[i];
    const dialogRef = this.dialog.open(
      ConfirmComponent,
      {
        width: '50%',
        data: { message: `Souhaitez vous supprimer ${instanceObj.libelle}` }
      }
    );

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.varieteCultureService.update(
            instanceObj.id,
            instanceObj.culture.id,
            true,
            instanceObj.description,
            instanceObj.libelle
          ).then(
            (results) => {
              this.varieteCultures.splice(i, 1);
              this.updateDataSource();
              this.util.snack(`${instanceObj.libelle} supprimé avec succès`);
            }
          );
        }
      }
    );
  }

}
