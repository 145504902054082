import { Component, OnInit, OnChanges, Input, EventEmitter, Output, ViewChildren, QueryList, ElementRef  } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { MatRadioChange, MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  viewProviders: [
    { 
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class InputComponent implements OnInit {
  @Input() controlName:string;
  @Input() icon: string;
  @Input() label: string;
  @Input() type: string;
  @Input() name: string;
  @Input() inputModel: string;
  @Input() redBorder:boolean = false
  @Output() inputModelChange = new EventEmitter<string>();
  @ViewChildren('input') inputs: QueryList<ElementRef>;
  name2: any;
  deflt = '';
  inputValue: string;
  list: Option[];
  multilist: Option[];
  selected = '';
  checked = false;
  multiselected: Option[];

  @Input() active: boolean =false;

  constructor() {
    this.controlName = '';
  }

  ngOnInit() {
  }
  activeLabel() {
    this.active = true
    if(this.active){
      this.inputs.find((item, idx) => {
        return idx === 0;
      }).nativeElement.focus();
    }
  }
}

//Interface pour l'input select
export interface Option {
  key: string;
  value: string;
}

export class InputType {
  nom: string; //libellé d'un champs de formulaire
  nom2?: string; //pour la deuxieme valeur du radio
  placeholder?: string = null; 
  type: string;   //type du champs de formulaire
  defaut?: string; //valeur par defaut
  list?: Option[]; //pour les selects
  multilist?: Option[];
  checked?: boolean; //pour les checkbox
}