export class CreateAdress{
    pays:string
    deleted:boolean
    displayName:string
    rue:string
    ville:string

    constructor(pays:string, deleted:boolean, displayName:string,rue:string,ville:string){
        this.pays = pays
        this.deleted = deleted
        this.displayName = displayName
        this.rue = rue
        this.ville = ville
    }

}

