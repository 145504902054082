import { Component, OnInit } from '@angular/core';
import { PaysService } from '../_services/pays.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilsService } from '../_services/utils.service';

@Component({
  selector: 'app-pays',
  templateUrl: './pays.component.html',
  styleUrls: ['./pays.component.css']
})
export class PaysComponent implements OnInit {
  config: {
    itemsPerPage: number,
    currentPage: number,
    totalItems: number
  } = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: 25
    }; // Pour la pagination
    public maxSize: number = 7;
    public directionLinks: boolean = true;
    public autoHide: boolean = false;
    public responsive: boolean = true;
    public labels: any = {
        previousLabel: '<--',
        nextLabel: '-->',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

  pays = {
    results: [],
    totalCount: 20
  };
  mpays = {
    name: '',
    id: ''
  };
  updating = false;
  constructor(private paysv: PaysService, private snackBar: MatSnackBar, private utils: UtilsService) { }

  ngOnInit() {
    this.getPays();
  }

  onSubmit(f) {
    if (this.updating) {
      this.paysv.update(this.mpays.id, f.value.libelle).then(
        (res: any) => {
          this.snackBar.open('Pays Mis à jour succès', 'Ok', {
            duration: 8000
          });
          this.pays.results.forEach((elt, i) => {
            if (elt.id == res.id) {
              this.pays.results[i] = res;
            }
          });
        },
        (err) => {
        }
      ).catch(reason =>  this.utils.setErrorsHttp(reason));
    } else {
      this.paysv.create(f.value.libelle).then(
        (res: any) => {
          this.snackBar.open('Pays créé avec succès', 'Ok', {
            duration: 8000
          });
          this.pays.results.unshift(res);
        },
        (err) => {
        }
      ).catch(reason =>  this.utils.setErrorsHttp(reason));
    }
  }

  getPays(offset : number = 0) {
    this.paysv.read('deleted : false', offset, 25, 'name').then(
      (res: any) => {
        this.pays.totalCount = res.totalCount;
        this.pays.results = this.pays.results.concat(res.results)
        if(this.pays.results.length < this.pays.totalCount){
          // this.getPays(this.pays.results.length)
        }else{
        }
        this.config = {
          itemsPerPage: 20,
          currentPage: this.config.currentPage,
          totalItems: this.pays.totalCount
        }
      },
      (err) => {
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason));
  }

  clickUpdate(pay) {
    this.updating = true;
    this.mpays.name = pay.name;
    this.mpays.id = pay.id;
  }

  public delete(p: any): void {
    console.clear();
    let confDelete = confirm('Voulez-vous vraiment supprimer ' + p.name + ' ?');
    if (confDelete) {
      this.paysv.update(p.id, p.name, true).then(
        (res: any) => {
          this.snackBar.open('Pays Mis à jour succès', 'Ok', {
            duration: 8000
          });
          this.pays.results.forEach((elt, i) => {
            if (elt.id == res.id) {
              this.pays.results.splice(i, 1);
            }
          });
        },
        (err) => {
        }
      ).catch(reason =>  this.utils.setErrorsHttp(reason));
    }
  }

  public pageChanged(event): void {
    this.config.currentPage = event;
    this.getPays();
    
  }

  public gotsearchValue(e): any {}

}
