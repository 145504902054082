import { UniteDeMesureService } from '../../../_services/unite-mesure.service';
import { RegionService } from '../../../_services/region.service';
import { VarieteParCultureService } from '../../../_services/variete-par-culture.service';
import { CultureService } from '../../../_services/culture.service';
import { FamilleDeCultureService } from '../../../_services/famille-de-culture.service';
import { PaysService } from '../../../_services/pays.service';
import { VarieteCultureComponent } from '../../../variete-culture/variete-culture.component';
import { CultureComponent } from '../../../culture/culture.component';
import { FamilleCultureComponent } from '../../../famille-culture/famille-culture.component';
import { EntrepriseComponent } from '../../../entreprise/entreprise.component';
import { CompositionDeOffreService } from '../../_services/composition-de-offre.service';
import { CaracteristiquesOffreService } from '../../_services/caracteristiques-offre.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UtilsService } from 'src/app/_services/utils.service';
import { NgForm } from '@angular/forms';
import { OffreDeVenteService } from '../../_services/offre-de-vente.service';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-offre-vente',
  templateUrl: './offre-vente.component.html',
  styleUrls: ['./offre-vente.component.scss'],
  providers: [EntrepriseComponent, FamilleCultureComponent, CultureComponent, VarieteCultureComponent]
})
export class OffreVenteComponent implements OnInit {
  user: any;
  image: '';
  familleCultures = [];
  cultures = [];
  varieteCultures = [];
  pays = [];
  regions = [];
  loadingPays = true;
  fields = [];
  unites = [];
  jour = [];
  mois = [];
  annee = [];
  requesting = false;
  libelle: any;
  description: any;
  ville: any;
  prix: any;
  qte: any;
  disponibilite: any;

  constructor(
    private utils: UtilsService,
    private offreService: OffreDeVenteService,
    private dialogRef: MatDialogRef<OffreVenteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private caracteristiqueService: CaracteristiquesOffreService,
    private compoService: CompositionDeOffreService,
    private paysv: PaysService,
    private familleService: FamilleDeCultureService,
    private cultureService: CultureService,
    private varieteService: VarieteParCultureService,
    private regionService: RegionService,
    private uniteService: UniteDeMesureService) {
  }


  ngOnInit() {
    this.user = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
   
    this.getRegion();
    this.getPays();
    this.getFamille();
    this.getCulture();
    this.getVariete();
    this.getRegion();
    this.getUnite();
    this.jour = this.utils.genTabNumber(31);
    this.mois = this.utils.genTabNumber(12);
    const nowYear = new Date().getFullYear();
    this.annee = this.utils.genTabNumber(nowYear + 10, nowYear);
  }

  submit(f: NgForm) {
   
    const disponibilite = this.makeDisponibility(f);
    this.requesting = true;
    this.offreService.create(false, f.value.description,
      disponibilite, false, f.value.libelle, f.value.region,
      f.value.unite, this.user.id, false, f.value.ville).then(
        (res: any) => {
         
          this.saveComposition(f, res);
        },
        (err) =>{
          this.requesting = false;
          this.utils.setErrorsHttp(err);
          this.dialogRef.close(false);
        }
      );
  }

  saveCaracteristique(res, f: NgForm) {
   this.caracteristiqueService.create(
     false,
     res.id,
     f.value.prix,
   ).then(
     (carac_res: any) => {
       
        this.requesting = false;
        this.dialogRef.close(res);
     },
     (err) => {
       
       this.utils.setErrorsHttp(err);
       this.dialogRef.close(false);
     }
   );
  }

  saveComposition(f: NgForm, res) {
    this.compoService.create(f.value.varieteCulture, false, this.image, '',
      res.id, f.value.qte, this.user.id).then(
        (res_comp) => {
          this.saveCaracteristique(res, f);
        
        },
        (err) =>{
          this.utils.setErrorsHttp(err);
          this.dialogRef.close(false);
        }
      );
  }

  getPays(offset: number = 0) {
    this.paysv.read('deleted : false', offset, 20, 'name').then(
      (res: any[]) => {
        const result: any = res;
        this.pays = this.pays.concat(result.results);
        if (this.pays.length < result.totalCount) {
          this.getPays(this.pays.length);
        } else {
          this.loadingPays = false;
        }
      },
      (err) => {
        this.utils.snack('Désolé un problème est survenu, \nveuillez réessayer plus tard');
        
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  getRegion() {
   this.regionService.read().then(
     (res: []) => {
       
       this.regions = res;
     },
     (err) => {
      
     }
   );
  }

  getFamille() {
    this.familleService.read().then(
      (res: []) => {
        this.familleCultures = res;
      }, (err) => {
        this.utils.setErrorsHttp(err);
      }
    );
  }

  getCulture() {
    this.cultureService.read().then(
      (res: []) => {
        this.cultures = res;
      },
      (err) => {
        this.utils.setErrorsHttp(err);
      }
    );
  }

  getVariete() {
    this.varieteService.read().then(
      (res: []) => {
        this.varieteCultures = res;
      }, (err) => {
        this.utils.setErrorsHttp(err);
     
      }
    );
  }

  getUnite() {
    this.uniteService.read().then(
      (res: []) => {
        this.unites = res;
      }
    );
  }

  makeDisponibility(f): Date {
    const date = new Date(f.value.year, f.value.month-1, f.value.day).toISOString().slice(0, 10);
    if (f.value.disponibilite) {
      return new Date(date);
    } else {
      return new Date();
    }
  }
}
