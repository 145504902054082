import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employe-pulse',
  templateUrl: './employe-pulse.component.html',
  styleUrls: ['./employe-pulse.component.scss']
})
export class EmployePulseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
