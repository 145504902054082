import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';

@Injectable({
  providedIn: 'root'
})
export class UniteDeMesureService {

  constructor(private utils: UtilsService) { }
  create(
    abbreviation: String,
    deleted: Boolean,
    description: String,
    libelle: String,

  ) {
    const query = `
    mutation(
			$abbreviation:  String!,
			$deleted:  Boolean!,
			$description:  String!,
			$libelle:  String!,
    ) {
      createUniteDeMesure(newUnitedemesure: {
				abbreviation: $abbreviation,
				deleted: $deleted,
				description: $description,
				libelle: $libelle,
      }) {unitedemesure{
        id,
				abbreviation,
				deleted,
				description,
				libelle,
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          abbreviation,
          deleted,
          description,
          libelle,
        }).then(
          (res) => {
            resolve(res.createUniteDeMesure.unitedemesure);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }
  update(id: String,
    abbreviation: String,
    deleted: Boolean,
    description: String,
    libelle: String,
  ) {
    const query = `
    mutation($id: UUID!,
			$abbreviation:  String!,
			$deleted:  Boolean!,
			$description:  String!,
			$libelle:  String!,
    ) {
      updateUniteDeMesure(newUnitedemesure: {
 id: $id,
				abbreviation: $abbreviation,
				deleted: $deleted,
				description: $description,
				libelle: $libelle,
      }) {unitedemesure{
 id,
				abbreviation,
				deleted,
				description,
				libelle,
				}      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id,
          abbreviation,
          deleted,
          description,
          libelle,
        }).then(
          (res) => {
            resolve(res.updateUniteDeMesure.unitedemesure);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = `deleted : false`, page: number = 1, limit: number = this.utils.PAGINATION, order: string = 'id') {
    const query = `
    query($page: Int!, $order: String!) {
      searchUniteDeMesure(${filter}){
        results(page: $page, ordering: $order)
        { id,
         				abbreviation,
				deleted,
				description,
				libelle,

        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { limit, page, order }).then(
          (res) => {
            resolve(res.searchUniteDeMesure.results);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }
}

