import { Injectable } from '@angular/core';
import request from 'graphql-request';
import { UtilsService } from './utils.service';

@Injectable({
	providedIn: 'root'
})
export class ConnexionService {

	constructor(private utils: UtilsService) { }

	connexion(username: string, password: string) {
		const query = `mutation($username: String!, $password: String!){
			tokenAuth(username: $username, password: $password){
				 token, 
				 __typename}
				}`;
		return new Promise(
			(resolve, reject) => {
				request(this.utils.HOST_URL, query, {
					username,
					password
				}).then(
					(res) => {
						resolve(res);
					}, (err) => {
						this.utils.setErrorsHttp(err)
						reject(err);
					}
				);
			}
		);
	}

	create_new_mail(userEmail: String) {
		const query = `
		mutation($userEmail: String) {
      	createForgetPassword(newForgetpassword: {userEmail: $userEmail}) {
        		forgetpassword {
          		id, alreadyUsed, deleted, expirationDate, expirationDelay, expiredState, linkid, newpassword, userEmail, createdAt, updateAt
				}
        		errors {
          		messages, field
        		}
        		ok
      	}
    	}
	 `;
		console.clear()
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query, { userEmail }).then(
					(res) => {
						if (res['createForgetPassword']['ok']) {
							resolve(res['createForgetPassword']['forgetpassword'])
						}
						reject(res['createForgetPassword']['errors']);
					}, (err) => {
						reject(err);
					}
				);
			}
		);
	}

	create_new_password(id: String, newpassword: String) {
		const query = `
		mutation($id: UUID!, $newpassword: String) {
		  	updateForgetPassword(newForgetpassword: {id: $id, newpassword: $newpassword, deleted: true }) {
			 	forgetpassword {
					id, alreadyUsed, deleted, expirationDate, expirationDelay, expiredState, linkid, newpassword, userEmail, createdAt, updateAt
				}
			 	errors {
					messages
					field
			 	}
			 	ok
		  }
		}
		`;
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query, { id, newpassword }).then(
					(res) => {
						if (res['updateForgetPassword']['ok']) {
							resolve(res['updateForgetPassword']['forgetpassword'])
						}
						reject(res['updateForgetPassword']['errors']);
					}, (err) => {
						reject(err);
					}
				);
			}
		);
	}

	verif_uid_used(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
		filter = (filter == null || filter == '') ? 'deleted: false' : filter;
		const query = `
		query {
		  	searchForgetPassword (${filter}){
			 	results(page:` + page + `, ordering:"` + order + `") {
					id, alreadyUsed, deleted, expirationDate, expirationDelay, expiredState, linkid, newpassword, userEmail, createdAt, updateAt
				}
				totalCount
		  	}
		}
		`;
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query).then(
					(res:any) => {
						resolve(res.searchForgetPassword);
					}, (err) => {
						reject(err);
					}
				);
			}
		);
	}
}
