import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilsService } from '../_services/utils.service';

enum Action{
  local,
  server
}

interface Search{
  field : string
  value : string
}

interface SearchMetaData<T>{
  action : Action,
  data_ : T
}

@Component({
  selector: 'app-g-search',
  templateUrl: './g-search.component.html',
  styleUrls: ['./g-search.component.css']
})
export class GSearchComponent implements OnInit {

  @Output() searchValue:EventEmitter<string> = new EventEmitter<string>();
  @Output() searchValueLive:EventEmitter<string> = new EventEmitter<string>();
  @Input() styles: any = {};
  @Input() placeholder:string = 'Rechercher un collecteur'
  @Input() isWidthFull:boolean = false
  @Input() liveFilter : boolean = true
  val?:string;
  @Input() searchButtonActive : boolean = false



 



  // @Input() fields : Array<any> = []
 
  // @Output() searchValue:EventEmitter<string> = new EventEmitter<string>();

  // val:string

  // searchForm : FormGroup
  constructor(private formBuilder : FormBuilder, private utils: UtilsService) { }

  ngOnInit() {
    //this.initForm()
  }

  // initForm(){
  //   this.searchForm = this.formBuilder.group({
  //     field : [this.fields.length ? this.fields[0].libelle_db : '', Validators.required],
  //     value : ''
  //   })
  // }


  // onKeydownEvent(){
  //   this.searchValue.emit(this.val);
  // }

//   onKeydownEvent(event: KeyboardEvent): void {
//     if (this.searchForm.valid){
//       let data : any = {
//         action : 0,
//         data_ : this.searchForm.value
//       }
//       if(event.keyCode === 13 || this.searchForm.value['value'].length == 0){
//         data.action = 1
//       }
//       this.searchValue.emit(data)
//     }
//  }

onSearchValue(){
  this.searchValue.emit(this.val);
  console.log(this.val)
}
onSearchValueLive(){
  this.searchValueLive.emit(this.val)
  console.log(this.val)
}

getFilterVal(val:string){
  this.searchValue.emit(this.val);
  console.log(this.val)
}

}
