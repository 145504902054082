import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {

  transform(data: any[], champ : string, valeur : string ): any {
    if(!data) return [];
    if(champ == null || valeur == null) return data;
    return data.filter((value, index) => {
      if(typeof value[champ] === 'string'){
        if(value[champ].indexOf(valeur) != -1){
          return value
        }
      }
    });
  }

}
