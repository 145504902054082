import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';

@Injectable({
  providedIn: 'root'
})
export class ZoneActionService {

  constructor(private utils: UtilsService) { }
  create(
		deleted:  Boolean,
		entreprise:  String,
		libelle:  String,
		zoneDelim:  String,
		zoneGlobale:  Boolean,
) {
    const query = `
    mutation(
			$deleted:  Boolean!,
			$entreprise:  ID!,
			$libelle:  String!,
			$zoneDelim:  String!,
			$zoneGlobale:  Boolean!,
    ) {
      createZoneAction(newZoneaction: {
				deleted: $deleted,
				entreprise: $entreprise,
				libelle: $libelle,
				zoneDelim: $zoneDelim,
				zoneGlobale: $zoneGlobale,
      }) {zoneaction{id,
				deleted,
				entreprise{
					id
				}
				id,
				libelle,
				zoneDelim,
				zoneGlobale,
				}      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
       
        this.utils.client.request(query, {
				deleted,
				entreprise,
				libelle,
				zoneDelim,
				zoneGlobale,
        }).then(
          (res) => {
          
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
  update(id: String,
		deleted:  Boolean,
		entreprise: String,
		libelle:  String,
		zoneDelim:  String,
		zoneGlobale:  Boolean,
  ) {
    const query = `
    mutation(
			$deleted:  Boolean!,
			$entreprise:  ID!,
			$id:  UUID!,
			$libelle:  String!,
			$zoneDelim:  String!,
			$zoneGlobale:  Boolean!,
    ) {
      updateZoneAction(newZoneaction: {
				deleted: $deleted,
				entreprise: $entreprise,
				id: $id,
				libelle: $libelle,
				zoneDelim: $zoneDelim,
				zoneGlobale: $zoneGlobale,
      }) {zoneaction{
				deleted,
				entreprise{
					id
				}
				id,
				libelle,
				zoneDelim,
				zoneGlobale,
				}      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
                id,
				deleted,
				entreprise,
				libelle,
				zoneDelim,
				zoneGlobale,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = `deleted : false`, page: number = 1, limit: number = this.utils.PAGINATION, order: string = '-created_at') {
    const query = `
    query($page: Int!, $order: String!) {
      searchZoneAction(${filter}){
        totalCount,
        results(page: $page, ordering: $order)
        {
          deleted,
		  entreprise{
				id
				},
				id,
				libelle,
				zoneDelim,
				zoneGlobale,

        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { limit, page, order }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

