import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";

@Injectable({
  providedIn: "root",
})
export class InformationSocioprofActeurPersService {
  constructor(private utils: UtilsService) {}

  create(
    acteur: String,
    activitePrincipale: String,
    activiteSecondaire: String,
    anneesDExperienceActPrinc: Number,
    anneesDExperienceActSec: Number,
    deleted: Boolean,
    identMobile: String,
    user: String,
    profession: String
  ) {
    const dateCreationMobile = new Date();
    const query = `
    mutation(
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID!,
		$activitePrincipale: String!,
		$activiteSecondaire: String!,
		$anneesDExperienceActPrinc: Int!,
		$anneesDExperienceActSec: Int!,
		$deleted: Boolean!,
		$identMobile: String!,
		$user: ID!,
		$profession: String!
	) {
		createInformationSocioProfActeurPers(newInformationsocioprofacteurpers: {
			dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			activitePrincipale: $activitePrincipale,
			activiteSecondaire: $activiteSecondaire,
			anneesDExperienceActPrinc: $anneesDExperienceActPrinc,
			anneesDExperienceActSec: $anneesDExperienceActSec,
			deleted: $deleted,
			identMobile: $identMobile,
			user: $user,
			profession: $profession
		}) {
        informationsocioprofacteurpers {
          id
					dateCreationMobile,
					acteur{ id },
					activitePrincipale,
					activiteSecondaire,
					anneesDExperienceActPrinc,
					anneesDExperienceActSec,
					deleted,
					identMobile,
					user{ id },
					createdAt
					updateAt
					profession
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          dateCreationMobile,
          acteur,
          activitePrincipale,
          activiteSecondaire,
          anneesDExperienceActPrinc,
          anneesDExperienceActSec,
          deleted,
          identMobile,
          user,
          profession,
        })
        .then(
          (res) => {
            if (res["createInformationSocioProfActeurPers"]["ok"]) {
              resolve(
                res["createInformationSocioProfActeurPers"][
                  "informationsocioprofacteurpers"
                ]
              );
            }
            reject(res["createInformationSocioProfActeurPers"]["errors"]);
          },
          (err) => {
            this.utils.setErrorsHttp(err);
            reject(err);
          }
        );
    });
  }

  update(
    id: String,
    dateCreationMobile: String,
    acteur: String,
    activitePrincipale: String,
    activiteSecondaire: String,
    anneesDExperienceActPrinc: Number,
    anneesDExperienceActSec: Number,
    deleted: Boolean,
    identMobile: String,
    user: String
  ) {
    const query = `
    mutation(
			$id: UUID!,
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$activitePrincipale: String,
		$activiteSecondaire: String,
		$anneesDExperienceActPrinc: Int,
		$anneesDExperienceActSec: Int,
		$deleted: Boolean,
		$identMobile: String,
		$user: ID,
		) {
      updateInformationSocioprofActeurPers(newInformationsocioprofacteurpers: {
				id: $id, dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			activitePrincipale: $activitePrincipale,
			activiteSecondaire: $activiteSecondaire,
			anneesDExperienceActPrinc: $anneesDExperienceActPrinc,
			anneesDExperienceActSec: $anneesDExperienceActSec,
			deleted: $deleted,
			identMobile: $identMobile,
			user: $user,
			}) {
        informationsocioprofacteurpers {
          id
					dateCreationMobile,
					acteur{ id },
					activitePrincipale,
					activiteSecondaire,
					anneesDExperienceActPrinc,
					anneesDExperienceActSec,
					deleted,
					identMobile,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          id,
          dateCreationMobile,
          acteur,
          activitePrincipale,
          activiteSecondaire,
          anneesDExperienceActPrinc,
          anneesDExperienceActSec,
          deleted,
          identMobile,
          user,
        })
        .then(
          (res) => {
            if (res["updateInformationSocioprofActeurPers"]["ok"]) {
              resolve(
                res["updateInformationSocioprofActeurPers"][
                  "informationsocioprofacteurpers"
                ]
              );
            }
            reject(res["updateInformationSocioprofActeurPers"]["errors"]);
          },
          (err) => {
            this.utils.setErrorsHttp(err);
            reject(err);
          }
        );
    });
  }

  read(
    filter: string = "deleted: false",
    page: number = 1,
    order: String = "-created_at"
  ) {
    filter = filter == null || filter == "" ? "deleted: false" : filter;
    const query =
      `
    query {
      searchInformationSocioprofActeurPers (${filter}){
        results(page:` +
      page +
      `, ordering:` +
      order +
      `) {
          id
					dateCreationMobile,
					acteur{ id },
					activitePrincipale,
					activiteSecondaire,
					anneesDExperienceActPrinc,
					anneesDExperienceActSec,
					deleted,
					identMobile,
					user{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.utils.setErrorsHttp(err);
          reject(err);
        }
      );
    });
  }
}
