import { Component, OnInit } from '@angular/core';
import { CategorieProfileService } from '../_services/profile-categorie.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import 'jquery';
import 'bootstrap';
import { UtilsService } from '../_services/utils.service';
import { MatDialog } from '@angular/material';
import { DeletedComponent } from '../_modale/deleted/deleted.component';
declare var $: any;

@Component({
  selector: 'app-profile-categorie',
  templateUrl: './profile-categorie.component.html',
  styleUrls: ['./profile-categorie.component.css']
})
export class ProfileCategorieComponent implements OnInit {
  // Parametre filter
  fields_selected_ : Array<any> = [
    { libelle_db : "libelle_Icontains", libelle : "Libelle" },
    { libelle_db : "description_Icontains", libelle : "Description" }
  ]

  filter : any = {
    field : null,
    value : null
  }

  loading : boolean = true

  // Fin paramatre

  catProfiles: any;
  libelle: any;
  description: any;
  numero: any;
  idprofile: any;
  updating = false;
  todelete: any;
  fields = [];
  totalCount :  number = 0;
  maxOfPages : number = 20;
  currentPage = 1;

  constructor(private profilsv: CategorieProfileService, private snackBar: MatSnackBar, private utils: UtilsService,     public dialog: MatDialog) { }

  ngOnInit() {
    this.readCategorie();
  }

  readCategorie(page : number = 1, filter_field : any = null) {
    this.loading = true
    let filter = 'deleted : false'
    if(filter_field != null && filter_field.field != null){
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`
    }
    this.profilsv.read(filter, page).then(
      (res: any) => {
        this.loading = false
        this.catProfiles = res.searchCategorieProfile;
        this.totalCount = res.searchCategorieProfile.totalCount
      },
      (err) => {
      }
    );
  }

  onSubmitFonction(f) {
    if (this.updating) {
      this.profilsv.update(
        this.idprofile,
        false,
        f.value.description,
        f.value.libelle,
        f.value.numero
      ).then(
        (res: any) => {
          let index = this.catProfiles.results.findIndex(element => element.id == res.updateCategorieProfile.categorieprofile.id);
          if(index != -1){
            this.catProfiles.results[index] = res.updateCategorieProfile.categorieprofile;
          }
          // this.readCategorie();
          this.snackBar.open('Catégorie profile mise à jour avec succès!', 'Ok', {
            duration: 2000
          });
          this.updating = false
          f.reset();
        },
        (err) => {
        }
      );
    } else {
      this.profilsv.create(
        false,
        f.value.description,
        f.value.libelle,
        f.value.numero,
      ).then(
        (res: any) => {
          this.catProfiles.results.unshift(res.createCategorieProfile.categorieprofile);
          // this.readCategorie();
          this.snackBar.open('Catégorie profile créée avec succès!', 'Ok', {
            duration: 2000
          });
          f.reset();
        },
        (err) => {
        }
      );
    }
  }
  clickUpdate(cat) {
    this.updating = true;
    this.libelle = cat.libelle;
    this.description = cat.description;
    this.numero = cat.numero;
    this.idprofile = cat.id;
  }


  deletecategorie(cat) {
    this.idprofile = cat.id;
    $('#catDelete').modal('show');
    this.todelete = cat;
    if (!this.todelete.numero) {
      this.todelete.numero = 1;
    }
  }



  openDeletedDialog(cat): void {

    this.idprofile = cat.id;
    this.todelete = cat;
    if (!this.todelete.numero) {
      this.todelete.numero = 1;
    }

    const dialogRef = this.dialog.open(DeletedComponent, {
      width: '300px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.utils.openLoadingDialog();
        this.profilsv.update(
          this.todelete.id,
          true,
          this.todelete.description,
          this.todelete.libelle,
          this.todelete.numero,
        ).then(
          (res: any) => {
            let index = this.catProfiles.results.findIndex(element => element.id == res.updateCategorieProfile.categorieprofile.id);
            if(index != -1){
              this.catProfiles.results.splice(index, 1)
            }
            this.snackBar.open('Catégorie profile supprimée avec succès!', 'Ok', {
              duration: 2000
            });
            this.dialog.getDialogById("wefly_loading_modale_").close()
          },
          (err) => {
            this.dialog.getDialogById("wefly_loading_modale_").close()
          }
        );
      }else{
      }
    });
  }




  onDelete() {
    this.profilsv.update(
      this.todelete.id,
      true,
      this.todelete.description,
      this.todelete.libelle,
      this.todelete.numero,
    ).then(
      (res: any) => {
        let index = this.catProfiles.results.findIndex(element => element.id == res.updateCategorieProfile.categorieprofile.id);
        if(index != -1){
          this.catProfiles.results.splice(index, 1)
        }
        // this.readCategorie();
        this.snackBar.open('Catégorie profile supprimée avec succès!', 'Ok', {
          duration: 2000
        });
        $('#catDelete').modal('hide');
      },
      (err) => {
      }
    );
  }


  onChangePage(data){
    this.currentPage = data.id;
    this.readCategorie(data.id)
  }

  filterData(data){
    if(data.action === 0){
      return
      this.filter = data.data_
    }else{
      this.loading = true
      this.filter = data.data_
      if(data.data_.value.length == 0){
        this.readCategorie(1)
      }else{
        this.readCategorie(1, this.filter)
      }
    }
  }
}
