import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoleService } from 'src/app/_services/role.service';
import { UtilsService } from 'src/app/_services/utils.service';


export interface DialogData {
  profile: string;
}

@Component({
  selector: 'app-role-user',
  templateUrl: './role-user.component.html',
  styleUrls: ['./role-user.component.css']
})
export class RoleUserComponent implements OnInit {

  roleUser: any[] = []; // liste des rôles que ce utilisateur possède déjà
  roleToSelectForThisUser: any[] = []; // liste des rôles sélectionnables
  addRoleForm: FormGroup;
  userInfo: any;

  constructor(
    private utils: UtilsService,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    public dialogRef: MatDialogRef<RoleUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
    console.clear();
    this.initAddRoleForm();
    this.roleUser = [];
    this.roleToSelectForThisUser = [];
    this.addRoleForm.controls['profile'].setValue(this.data.profile);

    this.readRoleUser();
    /*
    this.roleService.readProfileRoleSuite(this.data.profile).then(
      (res : any) => {
        console.clear();

        this.roleUser = res['searchProfileRoleSuite']['results']
        let allRole = res['searchRoleSuite']['results'];
        for(let i = 0; i < allRole.length; i++){
          if(res['searchProfileRoleSuite']['results'].length != 0){
            for(let j = 0; j < res['searchProfileRoleSuite']['results'].length; j++){
              if(allRole[i]['id'] == res['searchProfileRoleSuite']['results'][j]['role']['id']){
              } else{
                // this.roleToSelectForThisUser.splice(allRole[i], 1);
                this.roleToSelectForThisUser.push(allRole[i])
              }
            }
          }else{
            this.roleToSelectForThisUser = allRole
            this.roleUser= []
          }
        }

        // $("#role").modal("show")
      },
      (error) => {
        console.error(error)
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason))
    */
  }

  readRoleUser(page: number = 1) {
    this.roleService.readProfileRoleSuite(this.data.profile, page).then(
      (res: any) => {
        this.roleUser = res.searchProfileRoleSuite.results
        this.readAllRole();
      }, (err) => {
      }
    );
  }

  readAllRole(page: number = 1) {
    this.roleService.read(`deleted: false, entreprise_Id: "${this.userInfo.profile.entreprise.id}"`, page).then(
      // this.roleService.read(`deleted: false`, page).then(
      (res: any) => {
        this.roleToSelectForThisUser = res.results;

        this.roleToSelectForThisUser.forEach((e, i) => {
          this.roleUser.forEach((e1, i1) => {
            if (e1.role.id == e.id) {
              this.roleToSelectForThisUser.splice(i, 1);
            }
          })
        })

      }, (err) => {
      }
    );
  }

  initAddRoleForm() {
    this.addRoleForm = this.formBuilder.group({
      'role_': ['', Validators.required],
      'profile': ['', Validators.required]
    })
  }


  get f() {
    return this.addRoleForm.controls
  }

  onSubmit() {
    if (this.addRoleForm.invalid) {
      return
    }
    let profile = this.addRoleForm.value['profile']; let role = this.addRoleForm.value['role_']
    this.roleService.createRoleProfileSuite(profile, role).then(
      (res) => {
        this.roleUser.push(res)
        let index = this.roleToSelectForThisUser.filter((role, index) => {
          if (role.id == this.addRoleForm.value['role_']) {
            this.roleToSelectForThisUser.splice(index, 1)
            return index;
          }
        });
      },
      (error) => {
        console.error("error ", error)
      }
    ).catch(reason => this.utils.setErrorsHttp(reason))
  }

  deleteRoleToProfile(role) {
    this.roleService.deleteRoleProfileSuite(role.id).then(
      (res: any) => {
        this.roleToSelectForThisUser.push(role.role);
        this.roleUser.forEach((e1, i1) => {
          if (e1.role.id == res.role.id) {
            this.roleUser.splice(i1, 1)
          }
        })


      },
      (error) => {
        console.error("Error ", error)
      }
    ).catch(reason => this.utils.setErrorsHttp(reason))

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
