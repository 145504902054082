import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor() { }
  // public static API_URL = 'http://127.0.0.1:8000';
  public static API_URL = 'https://reqres.in';
}
