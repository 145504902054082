import { Injectable } from "@angular/core";
import { UtilsService } from "src/app/_services/utils.service";

@Injectable({
  providedIn: "root",
})
export class ReponseOffreService {
  constructor(private utils: UtilsService) {}

  create(
    commentaire: String,
    deleted: Boolean,
    offreVente: String,
    prixDemandee: Number,
    qteDemandee: Number,
    region: String,
    uniteMesure: String,
    user: String
  ) {
    const query = `
    mutation(
		$commentaire: String,
		$deleted: Boolean,
		$offreVente: ID,
		$prixDemandee: Float,
		$qteDemandee: Float,
		$region: ID,
		$uniteMesure: String,
		$user: ID,

	) {
      createReponseoffre(newReponseoffre: {
			commentaire: $commentaire,
			deleted: $deleted,
			offreVente: $offreVente,
			prixDemandee: $prixDemandee,
			qteDemandee: $qteDemandee,
			region: $region,
			uniteMesure: $uniteMesure,
			user: $user,

		}) {
        reponseoffre {
          id
					commentaire,
					deleted,
					offreVente{ id },
					prixDemandee,
					qteDemandee,
					region{ id },
					uniteMesure,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          commentaire,
          deleted,
          offreVente,
          prixDemandee,
          qteDemandee,
          region,
          uniteMesure,
          user,
        })
        .then(
          (res) => {
          
            if (res["createReponseoffre"]["ok"]) {
              resolve(res["createReponseoffre"]["reponseoffre"]);
            } else {
              reject(res["createReponseoffre"]["errors"]);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  update(
    id: String,
    commentaire: String,
    deleted: Boolean,
    offreVente: String,
    prixDemandee: Number,
    qteDemandee: Number,
    region: String,
    uniteMesure: String,
    user: String
  ) {
    const query = `
    mutation(
			$id: UUID!,
		$commentaire: String,
		$deleted: Boolean,
		$offreVente: ID,
		$prixDemandee: Float,
		$qteDemandee: Float,
		$region: ID,
		$uniteMesure: String,
		$user: ID,
		) {
      updateReponseoffre(newReponseoffre: {
				id: $id, commentaire: $commentaire,
			deleted: $deleted,
			offreVente: $offreVente,
			prixDemandee: $prixDemandee,
			qteDemandee: $qteDemandee,
			region: $region,
			uniteMesure: $uniteMesure,
			user: $user,
			}) {
        reponseoffre {
          id
					commentaire,
					deleted,
					offreVente{ id },
					prixDemandee,
					qteDemandee,
					region{ id },
					uniteMesure,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          id,
          commentaire,
          deleted,
          offreVente,
          prixDemandee,
          qteDemandee,
          region,
          uniteMesure,
          user,
        })
        .then(
          (res) => {
            if (res["updateReponseoffre"]["ok"]) {
              resolve(res["updateReponseoffre"]["reponseoffre"]);
            }
            reject(res["updateReponseoffre"]["errors"]);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  read(
    filter: string = "deleted: false",
    page: number = 1,
    order: String = "-created_at"
  ) {
    filter = filter == null || filter == "" ? "deleted: false" : filter;
    const query =
      `
    query {
      searchReponseoffre (${filter}){
        results(page:` +
      page +
      `, ordering:"` +
      order +
      `") {
          id
					commentaire,
					deleted,
					offreVente{
						 id 
						 compositionOffresDeVente{
            id
            culture {
              id,
              libelle
              culture{ id, libelle }
            }
					},
						 user{
						profile {
            admin
            deleted
            photo
            photoUrl
            id, 
            codeDeVerification, 
            contact, 
            dateNaissance, 
            deleted, 
            displayName, 
            genre, 
            nom, 
            prenom, 
            tracking, 
            entreprise {
              logo
              logoUrl 
              id, 
              raisonSociale,
              telephone
               email
              categorie{
                libelle,
                numero
              }
            }
          }
        }
						},
					prixDemandee,
					qteDemandee,
					region{ id },
					uniteMesure,
					user{
            id
						profile {
            admin
            deleted
            photo
            photoUrl
            id, 
            codeDeVerification, 
            contact, 
            dateNaissance, 
            deleted, 
            displayName, 
            genre, 
            nom, 
            prenom, 
            tracking, 
            entreprise {
              logo
              logoUrl 
              id, 
              raisonSociale,
              telephone
               email
              categorie{
                libelle,
                numero
              }
            }
          }
        }
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query).then(
        (res:any) => {
          resolve(res.searchReponseoffre.results);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
