import { DemandeDeProductionService } from './../_services/demande-production.service';
import { ErrorMsgComponent } from './../../_modale/error-msg/error-msg.component';
import { SuccessMsgComponent } from './../../_modale/success-msg/success-msg.component';
import { OffreDeVenteService } from './../_services/offre-de-vente.service';
import { DemandeProductionComponent } from '../_modals/demande-production/demande-production.component';
import { OffreVenteComponent } from '../_modals/offre-vente/offre-vente.component';
import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';
import { BaseService } from '../_services/base.service';
import { CREATE_STOCK, READ_STOCK } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { FormStockComponent } from '../_modals/form-stock/form-stock.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  fields_selected_: Array<any> = [
    { libelle_db: "libelle", libelle: "Libelle" },
    { libelle_db: "ville", libelle: "Ville" },
  ];
  filter: string = null;

  fields_selected_2: Array<any> = [
    { libelle_db: "libelle", libelle: "Libelle" },
    { libelle_db: "ville", libelle: "Ville" },
  ];
  filter2: string = null;


  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;

  // pagination pour les demande
  totalCount2: number = 0;
  maxOfPages2: number = 20;
  currentPage2: number = 1;


  stocks: any[];
  offres = [];
  demandes = [];
  listView = true;
  constructor(public utils: UtilsService, public dialog: MatDialog,
    private offreService: OffreDeVenteService, private demandeService: DemandeDeProductionService) { }

  ngOnInit() {
   
    // tslint:disable-next-line: only-arrow-functions
    $('#menu-toggle').click(function (e) {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
    this.readOffre();
    this.readDemande();
  }

  readOffre(page: number = 1, filter: string = this.filter) {
    filter = (filter == null) ? `deleted : false` : `deleted : false, ${filter}`;
    this.offreService.read(filter, page).then(
      (res: any) => {
        this.totalCount = res.totalCount;
        this.offres = res.results;
      }, (err) => {
      }
    );
  }

  readDemande(page: number = 1, filter: string = this.filter2) {
    filter = (filter == null) ? `deleted : false` : `deleted : false, ${filter}`;
    this.demandeService.read(filter, page).then(
      (res: any) => {
       
        this.totalCount2 = res.totalCount;
        this.demandes = res.results;
      },
      (err) => {
   
      }
    );
  }

  /* readStock(){
    this.baseService.read(READ_STOCK, {page : 1, order : 'id'}, 'deleted : false').then(
      (res) => {
      
        this.stocks = res['searchStock']['results']
      },
      (error) => {
        console.error(error)
      }
    )
  } */

  openDialog(type): void {
    if (type === 'Vendre') {
      const dialogRef = this.dialog.open(OffreVenteComponent, {
        width: '800px'
      });
      dialogRef.afterClosed().subscribe(
        (res) => {
        
          if (res) {
            const mdialogRef = this.dialog.open(SuccessMsgComponent, {
              width: '20rem',
              data: { msg: 'Votre offre a été enregistré avec succès. Il sera disponible après validation par notre administrateur' }
            });
          } else {
            this.dialog.open(ErrorMsgComponent, {
              width: '20rem',
              data: { msg: 'Une erreur est survenue, veuillez réessayer plus tard.' }
            });
          }
        }
      );
    } else {
      const dialogRef = this.dialog.open(DemandeProductionComponent, {
        width: '40rem'
      });
      dialogRef.afterClosed().subscribe(
        (res) => {
         
          if (res) {
            if (res != '___') {
              const mdialogRef = this.dialog.open(SuccessMsgComponent, {
                width: '20rem',
                data: { msg: 'Votre demande a été enregistré avec succès. Il sera disponible après la validation par nos administrateurs.' }
              });
            }
          } else {
            this.dialog.open(ErrorMsgComponent, {
              width: '20rem',
              data: { msg: 'Une erreur est survenue, veuillez réessayer plus tard.' }
            });
          }
        }
      );
    }
  }

  public gotsearchValue(e): any { }
  switch() {
    this.listView = !this.listView;
  }

  onChangePage(data) {
    this.currentPage = data.id;
    this.readOffre(this.currentPage);
  }

  onChangePage2(data) {
    this.currentPage = data.id;
    this.readDemande(this.currentPage);
  }

  filterData(data) {
    this.filter = `${data.data_.field}: "${data.data_.value}"`;
    // console.clear();

    if (data.action != 0) {
      if (data.data_.value.length == 0) {
        this.readOffre();
      } else {
        this.readOffre(1, this.filter);
      }
    }
  }

  filterData2(data) {
    this.filter2 = `${data.data_.field}: "${data.data_.value}"`;
    // console.clear(); 
    if (data.action != 0) {
      if (data.data_.value.length == 0) {
        this.readDemande();
      } else {
        this.readDemande(1, this.filter);
      }
    }
  }
}
