import { Component, OnInit, Input, OnChanges } from "@angular/core";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDragExit,
} from "@angular/cdk/drag-drop";
import { AttributService } from "src/app/_services/attribut.service";
import { FormCoucheService } from "src/app/_services/form-couche.service";
import { UtilsService } from "src/app/_services/utils.service";
import { AttributFormulaireService } from "src/app/_services/attribut-formulaire.service";
import { UserService } from "src/app/_services/user.service";
import { element } from "protractor";
import { resolve } from "url";
@Component({
  selector: "app-formbuilder",
  templateUrl: "./formbuilder.component.html",
  styleUrls: ["./formbuilder.component.scss"],
})
export class FormbuilderComponent implements OnInit, OnChanges {
  toform: [any];
  finalform = [];
  formcouche: any;
  mcouches: any;
  attributformulaires = [];
  userInfo: any;
  loading:boolean = false;
  showspin = false;
  dataJson = [];
  profileLogged:any
  // @Input() attributs: [any];
  @Input() couches;
  @Input() compartiments: any; 
  constructor(
    private service: FormCoucheService,
    public utils: UtilsService,
    private attributformulaire: AttributFormulaireService,
    private user: UserService
  ) {}
  ngOnInit() {

    this.userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie("_wefly_")));
      if(this.userInfo && this.couches){
        this.getAttributformulaire();
      } 

    //  console.log("this.compartiments", this.compartiments);
      console.log("user", this.userInfo);
    this.toform = this.compartiments.attributComp;
   
  }

  show = false;
  onChange;
  isEdit;
  inputNewLabel = "";
  active = false;
  hover(toActive) {
    this.active = toActive;
  }

  leave() {
    this.active = null;
  }
  onEdit(datas: any, idx: any) {
    // console.log("datas and index", datas,idx);
    // console.log("inputNewLabel", this.inputNewLabel);
    // this.inputNewLabel.length !== undefined
    //   ? (datas.libelle = this.inputNewLabel)
    //   : this.inputNewLabel =datas.libelle  ;
    // //  datas  = this.inputNewLabel
    this.onChange = idx;
    this.show = !this.show;
    this.show === true ? (this.isEdit = "text") : (this.isEdit = "hidden");
    // this.onChange = !this.onChange
  }
  // onSearchChange(searchValue: string): void {
  //   console.log(searchValue);
  //   this.inputNewLabel =searchValue ;
  //   this.onChange = !this.onChange
  // }
  ngOnChanges() {
    if (this.couches) {
      // this.userInfo = JSON.parse(
      //   this.utils.decrypt(this.utils.getCookie("_wefly_"))
      // );
      console.log('current couche', this.couches)

      if (this.couches) this.getAttributformulaire();
    }
  }

  getAttributFromFormcoucheCompartiment(libelle) {
    // console.log("this.couches.compartiments", libelle);
    return this.couches.compartiments.findIndex((params) => {
      // console.log(params.libelle, libelle);
      return params.libelle === libelle;
    });
  }

  getAttributformulaire() {
    console.log("compatimaent")
  let userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie("_wefly_")))
    // On recherche les attributs formulaires en fonction de form couche ici "couches"
     this.attributformulaire
     .read(
       `formcouche_Id: "${this.couches.id}",
     attribut_Compartiment_Id:"${this.compartiments.id}", formcouche_Couche_Entreprise_Id: "${userInfo.profile.entreprise.id}",
     deleted: false`
     )
     .then((res: any) => {
     console.log('res',res);
    console.log("comp",this.compartiments.attributComp);
    console.log("function",this.couches);
 
    if (this.compartiments.attributComp.length > 0) {

      this.attributformulaires = res.searchAttributFormulaire.results;
            const tosort = this.compartiments.attributComp;
            res.searchAttributFormulaire.results.forEach((atrform) => {
              this.compartiments.attributComp.forEach((atr, index) => {
                // console.table(atr);
                if (atr.id === atrform.attribut.id) {
                  this.compartiments.attributComp.splice(index, 1);
                  this.finalform.push(atr);
                }
              });
      });
      /* console.log('ok compartiment', this.compartiments)
      const tosort = this.compartiments.attributComp;
      // this.attributformulaires = res.searchAttributFormulaire.results
      if (
        this.couches.compartiments !== undefined &&
        this.couches.compartiments.length > 0
      ) {
        console.log('compartiment defined')
        this.attributformulaires =
          this.couches.compartiments[
            this.getAttributFromFormcoucheCompartiment(
              this.compartiments.libelle
            )
          ].attributComp;
        // console.log("zezez", this.attributformulaires);
        if (this.attributformulaires !== undefined) {
 
          console.log('attribut formulaire', this.attributformulaires)
          this.attributformulaires.forEach((atrform) => {
           
            atrform.attributformulaire.forEach((atrFormlelement) => {
               console.table("atrFormlelement", atrFormlelement);
              this.compartiments.attributComp.forEach((atr, index) => {
                 console.log("getAtr", atr.id, atrFormlelement.id);
                if (atr.id === atrFormlelement.attribut.id) {
                  alert('yaya')
                  // console.log("getAtr match", atr, atrFormlelement.id);
                  this.compartiments.attributComp.splice(index, 1);
                  this.finalform.push(atr);
                  console.log('finale form ', this.finalform)
                }
              });
            });
          });
        }
      } */

      // console.log(
      //   "attributformulaires",
      //   this.attributformulaires
      // );
      // Fin loading local
      // this.compartiments.attributComp.forEach((atr, index) => {
      // console.table(atr);
      // Add require attributes
      /* if (atr.requis) {
               this.compartiments.attributComp.splice(index, 1);
               this.finalform.push(atr);
               // Ajout des attribut formulaire si formulaire vide et attribut requis
               if (res.searchAttributFormulaire.results.length === 0) {
                 // this.saveformattribut(atr, index);
               }
             } */
      // });
      this.showspin = false;
    }
    //  }
    //    (err) => {}
    //  )
    //  .catch((reason) => this.utils.setErrorsHttp(reason));
  })
}

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }


  async saveForm(){
    this.loading = true
    this.finalform.forEach(async (element, index) => {
     
      await this.attributformulaire.read(
       `formcouche_Id: "${this.couches.id}",
       attribut: "${element.id}",
       formcouche_Couche_Entreprise_Id: "${this.userInfo.profile.entreprise.id}", deleted: false`
      )
      .then(
        async (attrres: any) => {
           console.log('search attre',attrres)
          if (attrres.searchAttributFormulaire.results.length === 0) {
            this.createFormattribut(element,index)
          }else{
            this.updateFormattribut(attrres, index).then((res:any)=>{
            })
          
          }
        }
      )

    })
    

    this.compartiments.attributComp.forEach((attr:any, index:number) => {
      this.removeformattribut(attr, index);
    });
   
  }

  createFormattribut(element:any, index:number){
    console.log('log element form', element)
      this.attributformulaire.create(element.id, this.userInfo.id, this.couches.id, index, element.requis, false).then(
        (res) => {
         this.loading = false
          console.log('save formulaire',res)
          this.utils.snack("Création effectuée avec succès");
        },
        (err) => {
         this.loading = false
        }
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }

   updateFormattribut(element:any, index){
    return new Promise((resolve, reject)=>{
      console.log('element', element)
 
      this.attributformulaire.update(
       element.searchAttributFormulaire.results[0].id,
       element.searchAttributFormulaire.results[0].attribut.id,
       this.userInfo.id,
       this.couches.id,
       index,
       element.searchAttributFormulaire.results[0].requis,
       false
     )
     .then(
       (atr) => {
         console.log('modif form', atr)
         resolve(atr)
           this.utils.snack("Modification enregistrée avec succès");
           this.loading = false
       },
       (err) => {
         this.loading = false
         console.log('error update', err)
       }
     )
     .catch((reason) => this.utils.setErrorsHttp(reason));

    })
 
    

   }




  setAttributOnform(index) {
    this.compartiments.attributComp.forEach((element) => {
      console.log(
        "element.id ===  this.finalform[index].id",
        element.id,
        this.finalform[index].id
      );

      if (element.id === this.finalform[index].id) {
        element.onForm = true;
      }
    });
  }

  removeformattribut(element, index) {
    this.attributformulaire
      .read(
        `formcouche_Id: "${this.couches.id}", attribut: "${element.id}", formcouche_Couche_Entreprise_Id: "${this.userInfo.profile.entreprise.id}", deleted: false`
      )
      .then(
        (attrres: any) => {
          if (attrres.searchAttributFormulaire.results.length > 0) {
            this.attributformulaire
              .update(
                attrres.searchAttributFormulaire.results[0].id,
                element.id,
                this.userInfo.id,
                this.couches.id,
                index,
                element.requis,
                true
              )
              .then(
                (res) => {
                    this.loading = false;
                    this.utils.snack("Les modifications ont été enregistrées avec succès");
                  
                  // this.utils.snack('Attribut supprimé avec succès !!');
                },
                (err) => {
                  this.loading = false;
                }
              )
              .catch((reason) => this.utils.setErrorsHttp(reason));
          }
        },
        (err) => {}
      );
    
  }

  checkCheckBoxvalue(event, i) {
    console.log('data checked', event.checked, i, this.finalform[i]);
    this.finalform[i].requis = event.checked;
    console.log('data checked XXXXXXXXXXXXXX', event.checked, i, this.finalform[i]);
  }
}
