import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';
// import {
//   PDFProgressData,
//   PdfViewerComponent,
//   PDFDocumentProxy,
//   PDFSource,
// } from 'ng2-pdf-viewer';
import { ActivatedRoute } from '@angular/router';

declare var jQuery: any;
declare var $: any;


// https://www.pdftron.com/blog/angular/build-a-pdf-viewer-in-angular-and-pdf-js/

@Component({
  selector: 'app-rapport',
  templateUrl: './rapport.component.html',
  styleUrls: ['./rapport.component.css']
})
export class RapportComponent implements OnInit {
  // pdfSrc: string | PDFSource | ArrayBuffer = null;

  error: any;
  page = 1;
  rotation = 0;
  zoom = 1.0;
  originalSize = true;
  pdf: any;
  renderText = true;
  // progressData: PDFProgressData;
  isLoaded = false;
  stickToPage = false;
  showAll = true;
  autoresize = true;
  fitToPage = false;
  outline: any[];
  isOutlineShown = false;
  pdfQuery = '';

  rapportList: any[] = [
    { id: "1", libelle: "Angular", pdfSrc: "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf" },
    { id: "2", libelle: "Amazon", pdfSrc: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/149125/relativity.pdf" },
  ]

  // @ViewChild(PdfViewerComponent, null) private pdfComponent: PdfViewerComponent;

  constructor(
    public utils: UtilsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    window.onload = () => {
      this.resizeWindows();
    }
    window.onresize = () => {
      this.resizeWindows()
    };
  }

  openLocalFile() {
    jQuery('#file').trigger('click');
  }

  toggleOutline() {
    this.isOutlineShown = !this.isOutlineShown;
  }

  incrementPage(amount: number) {
    this.page += amount;
  }

  incrementZoom(amount: number) {
    this.zoom += amount;
  }

  rotate(angle: number) {
    this.rotation += angle;
  }

  /**
   * Render PDF preview on selecting file
   */
  onFileSelected() {
    const $pdf: any = document.querySelector('#file');

    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        // this.pdfSrc = e.target.result;
      };

      reader.readAsArrayBuffer($pdf.files[0]);
    }
  }

  /**
   * Get pdf information after it's loaded
   * @param pdf
   */
  // afterLoadComplete(pdf: PDFDocumentProxy) {
  //   this.pdf = pdf;
  //   this.isLoaded = true;

  //   this.loadOutline();
  // }

  /**
   * Get outline
   */
  loadOutline() {
    this.pdf.getOutline().then((outline: any[]) => {
      this.outline = outline;
    });
  }

  // /**
  //  * Handle error callback
  //  *
  //  * @param error
  //  */
  // onError(error: any) {
  //   this.error = error; // set error

  //   if (error.name === 'PasswordException') {
  //     const password = prompt(
  //       'This document is password protected. Enter the password:'
  //     );

  //     if (password) {
  //       this.error = null;
  //       this.setPassword(password);
  //     }
  //   }
  // }

  // setPassword(password: string) {
  //   let newSrc;
  //   if (this.pdfSrc instanceof ArrayBuffer) {
  //     newSrc = { data: this.pdfSrc };
  //   } else if (typeof this.pdfSrc === 'string') {
  //     newSrc = { url: this.pdfSrc };
  //   } else {
  //     newSrc = { ...this.pdfSrc };
  //   }
  //   newSrc.password = password;
  //   this.pdfSrc = newSrc;
  // }

  // /**
  //  * Pdf loading progress callback
  //  * @param {PDFProgressData} progressData
  //  */
  // onProgress(progressData: PDFProgressData) {
  //   this.progressData = progressData;
  //   this.isLoaded = false;
  //   this.error = null; // clear error
  // }

  getInt(value: number): number {
    return Math.round(value);
  }

  // /**
  //  * Navigate to destination
  //  * @param destination
  //  */
  // navigateTo(destination: any) {
  //   this.pdfComponent.pdfLinkService.navigateTo(destination);
  // }

  // /**
  //  * Scroll view
  //  */
  // scrollToPage() {
  //   this.pdfComponent.pdfViewer.scrollPageIntoView({
  //     pageNumber: 3,
  //   });
  // }

  /**
   * Page rendered callback, which is called when a page is rendered (called multiple times)
   *
   * @param {CustomEvent} e
   */
  pageRendered(e: CustomEvent) {
  }

  // searchQueryChanged(newQuery: string) {
  //   if (newQuery !== this.pdfQuery) {
  //     this.pdfQuery = newQuery;
  //     this.pdfComponent.pdfFindController.executeCommand('find', {
  //       query: this.pdfQuery,
  //       highlightAll: true,
  //     });
  //   } else {
  //     this.pdfComponent.pdfFindController.executeCommand('findagain', {
  //       query: this.pdfQuery,
  //       highlightAll: true,
  //     });
  //   }
  // }

  resizeWindows() {
    let width = window.innerWidth;
    let toolbar = document.getElementById('toolbar');
    let navbar = document.getElementById('navbar');

    toolbar.style.width = (width - navbar.offsetWidth).toString() + 'px';
  }

  getDisplay(pdf) {
    // permet d'afficher le bon pdf envoyé en paramètre dans le lien
    if (pdf == this.route.snapshot.params['id']) {
      return 'flex';
    } else {
      return 'none';
    }
  }

  public gotsearchValue(e): any {}


}
