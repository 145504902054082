import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private utils: UtilsService) { }

  create(fonctionnalite: String, permission: String, deleted: Boolean, displayName: String, ) {
    const query = `
    mutation($fonctionnalite: ID!, $permission: ID!, $deleted: Boolean, $displayName: String, ) {
      createPermissionSuite(newPermissionsuite: {fonctionnalite: $fonctionnalite, permission: $permission, deleted: $deleted, displayName: $displayName, }) {
        permissionsuite {
          id, fonctionnalite { id, libelle }, permission { id, name, codename }, deleted, displayName, createdAt, updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { fonctionnalite, permission, deleted, displayName, }).then(
          (res) => {
            if (res['createPermissionSuite']['ok']) {
              resolve(res['createPermissionSuite']['permissionsuite'])
            }
            reject(res['createPermissionSuite']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, fonctionnalite: String, permission: String, deleted: Boolean, displayName: String, ) {
    const query = `
    mutation($id: UUID!, $fonctionnalite: ID!, $permission: ID!, $deleted: Boolean, $displayName: String, ) {
      updatePermissionSuite(newPermissionsuite: { id: $id, fonctionnalite: $fonctionnalite, permission: $permission, deleted: $deleted, displayName: $displayName, }) {
        permissionsuite {
          id, fonctionnalite { id, libelle }, permission { id, name, codename }, deleted, displayName, createdAt, updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, fonctionnalite, permission, deleted, displayName, }).then(
          (res) => {
            if (res['updatePermissionSuite']['ok']) {
              resolve(res['updatePermissionSuite']['permissionsuite'])
            }
            reject(res['updatePermissionSuite']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    const query = `
    query {
      searchPermissionSuite (${filter}){
        totalCount
        results(page:` + page + `, ordering:"` + order + `") {
          id, fonctionnalite { id, libelle }, permission { id, name, codename }, deleted, displayName, createdAt, updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  readPermission(filter: string = '', page: number = 1, order: String = 'id') {
    const query = `
    query {
      searchPermission${filter}{
        results(page:` + page + `, ordering:"` + order + `") {
          id, codename, name
				}
        totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

