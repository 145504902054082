import { Component, OnInit, Input } from '@angular/core';
import { type } from 'os';

@Component({
  selector: 'app-stat-card',
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.scss']
})
export class StatCardComponent implements OnInit {

  @Input() titre: string = '';
  @Input() nb: string = '';
  @Input() icon: String = '';
  @Input() split: boolean = false;
  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

  isNaN(nb : any){
    if(typeof nb == 'object' || typeof nb == 'undefined' || (typeof nb == 'string' && nb.indexOf("NaN") != -1)){
      return true
    }
    return false
  }

  

}
