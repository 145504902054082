import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class InformationNotationActeurPlantationService {

  constructor(private utils: UtilsService) { }

  create(access: Number, acteur: String, commentaire: String, deleted: Boolean, densite: Number, identMobile: String, nettoyage: Number, santePlantes: Number, user: String, ) {
    const dateCreationMobile = new Date()
    const query = `
    mutation(
		$dateCreationMobile: CustomDateTime!,
		$access: Float,
		$acteur: ID,
		$commentaire: String,
		$deleted: Boolean,
		$densite: Float,
		$identMobile: String,
		$nettoyage: Float,
		$santePlantes: Float,
		$user: ID,

	) {
      createInformationNotationActeurPlantation(newInformationnotationacteurplantation: {
			dateCreationMobile: $dateCreationMobile,
			access: $access,
			acteur: $acteur,
			commentaire: $commentaire,
			deleted: $deleted,
			densite: $densite,
			identMobile: $identMobile,
			nettoyage: $nettoyage,
			santePlantes: $santePlantes,
			user: $user,

		}) {
        informationnotationacteurplantation {
          id
					dateCreationMobile,
					access,
					acteur {
            id
          },
					commentaire,
					deleted,
					densite,
					identMobile,
					nettoyage,
					santePlantes,
					user {
            id
          },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          dateCreationMobile,
          access,
          acteur,
          commentaire,
          deleted,
          densite,
          identMobile,
          nettoyage,
          santePlantes,
          user,
        }).then(
          (res) => {
            if (res['createInformationNotationActeurPlantation']['ok']) {
              resolve(res['createInformationNotationActeurPlantation']['informationnotationacteurplantation'])
            }
            reject(res['createInformationNotationActeurPlantation']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, dateCreationMobile: String, access: Number, acteur: String, commentaire: String, deleted: Boolean, densite: Number, identMobile: String, nettoyage: Number, santePlantes: Number, user: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$dateCreationMobile: CustomDateTime!,
		$access: Float,
		$acteur: ID,
		$commentaire: String,
		$deleted: Boolean,
		$densite: Float,
		$identMobile: String,
		$nettoyage: Float,
		$santePlantes: Float,
		$user: ID,
		) {
      updateInformationNotationActeurPlantation(newInformationnotationacteurplantation: {
				id: $id, dateCreationMobile: $dateCreationMobile,
			access: $access,
			acteur: $acteur,
			commentaire: $commentaire,
			deleted: $deleted,
			densite: $densite,
			identMobile: $identMobile,
			nettoyage: $nettoyage,
			santePlantes: $santePlantes,
			user: $user,
			}) {
        informationnotationacteurplantation {
          id
					dateCreationMobile,
					access,
					acteur {
            id
          },
					commentaire,
					deleted,
					densite,
					identMobile,
					nettoyage,
					santePlantes,
					user {
            id
          },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id, dateCreationMobile,
          access,
          acteur,
          commentaire,
          deleted,
          densite,
          identMobile,
          nettoyage,
          santePlantes,
          user,
        }).then(
          (res) => {
            if (res['updateInformationNotationActeurPlantation']['ok']) {
              resolve(res['updateInformationNotationActeurPlantation']['informationnotationacteurplantation'])
            }
            reject(res['updateInformationNotationActeurPlantation']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    const query = `
    query {
      searchInformationNotationActeurPlantation (${filter}){
        results(page:` + page + `, ordering:` + order + `) {
          id
					dateCreationMobile,
					access,
					acteur {
            id
          },
					commentaire,
					deleted,
					densite,
					identMobile,
					nettoyage,
					santePlantes,
					user {
            id
          },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

