import { UtilsService } from '../_services/utils.service';
import { UniteDeMesureService } from '../_services/unite-mesure.service';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormStockComponent } from '../@marketplace/_modals/form-stock/form-stock.component';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-unite-mesure',
  templateUrl: './unite-mesure.component.html',
  styleUrls: ['./unite-mesure.component.scss']
})
export class UniteMesureComponent implements OnInit {
  uniteMesures = [];
  dataSource = new MatTableDataSource<any>();
  columnsToDisplay = ['libelle', 'description', 'abbreviation', 'action']
  constructor(private uniteMesureService: UniteDeMesureService,
              private dialog: MatDialog,
              private util: UtilsService) { }

  ngOnInit() {
    this.readUnite();
  }

  readUnite() {
    this.uniteMesureService.read().then(
      (res:[]) => {
        this.uniteMesures = res;
        this.updateDataSource();
      }
    );
  }

  updateDataSource() {
    this.dataSource.connect().next(this.uniteMesures);
  }

  addUnite() {
    const dialogRef = this.dialog.open(FormStockComponent, {
      width: '60%',
      data: {
        ref: 'unite', formType: 'Unité de mesure',
        instance: { libelle: null, description: '', abbreviation: null},
      }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.uniteMesures.push(res);
          this.updateDataSource();
        }
      }
    );
  }

  update(i: number) {
    const instanceObj = this.uniteMesures[i];
    const dialogRef = this.dialog.open(FormStockComponent, {
      width: '60%',
      data: { ref: 'unite', formType: 'Unité de mesure', instance: instanceObj }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.uniteMesures[i] = res;
          this.updateDataSource();
          this.util.snack(`${instanceObj.libelle} mis à jour avec succès`);
        }
      }
    );
  }

  delete(i: number) {
    const instanceObj = this.uniteMesures[i];
    const dialogRef = this.dialog.open(
      ConfirmComponent,
      {
        width: '50%',
        data: { message: `Souhaitez vous supprimer ${instanceObj.libelle}` }
      }
    );

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.uniteMesureService.update(
            instanceObj.id,
            instanceObj.abbreviation,
            true,
            instanceObj.description,
            instanceObj.libelle
          ).then(
            (results) => {
              this.uniteMesures.splice(i, 1);
              this.updateDataSource();
            }
          );
        }
      }
    );
  }

}
