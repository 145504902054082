import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';

@Component({
  selector: 'app-wg-zone',
  templateUrl: './wg-zone.component.html',
  styleUrls: ['./wg-zone.component.scss']
})
export class WgZoneComponent implements OnInit {

  constructor(public utils: UtilsService) { }

  ngOnInit() {
  }
  public gotsearchValue(e): any {}

}
