import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { UtilsService } from '../_services/utils.service';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthgardService {

  constructor(private utils: UtilsService, private router: Router) { }
  canActivate( router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userToken = jwt_decode( this.utils.getStorage('weflyHeader_token'))

    if (userToken && userToken.username && userToken.username.length > 3) {
      return true;
    }
    this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
    alert('Veuillez-vous connecter afin d\'accéder à cette page!');
    return false;
  }
}


