import { Component, OnInit } from '@angular/core';
import { TypeAttributService } from '../_services/type-attribut.service';
import { NgForm } from '@angular/forms';
import 'jquery';
import 'bootstrap';
import { delay } from 'q';
import { UtilsService } from '../_services/utils.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { DeletedComponent } from '../_modale/deleted/deleted.component';
import { element } from 'protractor';

declare var $: any;

@Component({
  selector: 'app-type-attribut',
  templateUrl: './type-attribut.component.html',
  styleUrls: ['./type-attribut.component.css']
})
export class TypeAttributComponent implements OnInit {
    // Parametre filter
    fields_selected_ : Array<any> = [
      { libelle_db : "libelle_Icontains", libelle : "Libelle" },
    ]

    filter : any = {
      field : null,
      value : null
    }

    loading : boolean = true

    // Fin paramatre
  allData: any;
  total = 0;
  page = 1;
  limit = 20;

  toDelete: {
    id: string,
    libelle: string,
    description: string,
    deleted: boolean,
  } = {
      deleted: false,
      id: '',
      libelle: '',
      description: '-'
    };

  updting = false;
  updated = false;
  pageAct: any = 1;


  totalCount: number = 0;
  maxOfPages : number = 20;
  currentPage : number = 1;

  constructor(
    private serv: TypeAttributService,
    private snackBar: MatSnackBar,
    private utils: UtilsService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.onReadAll();
  }

  onSubmit(f: NgForm) {

    if (this.updting === true) {
      this.onSubmitToUpdate(f);
    } else {
      this.serv.create(
        f.value.typeAttributDescription,
        f.value.typeAttributLibelle,
        false
      ).then(
        (res: any) => {
          console.table(res);
          this.allData.results.unshift(res.createTypeAttribut.typeattribut)
          f.reset();
          this.snackBar.open('Requête effectuée avec succès', 'Ok', { duration: 5000 });
          // this.onReadAll();
        }, (err) => {
        }
      );
    }
  }

  delete(supprime) {
    this.toDelete = supprime;
    this.toDelete.deleted = true;
    $('#Delete').modal('show');
  }


  openDeletedDialog(supprime): void {
    this.toDelete = supprime;
    this.toDelete.deleted = true;

    const dialogRef = this.dialog.open(DeletedComponent, {
      width: '300px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.utils.openLoadingDialog();
        this.serv.update(this.toDelete.id, this.toDelete.description, this.toDelete.libelle,  true).then(
          (res : any) => {
            // this.onReadAll();
            let index = this.allData.results.findIndex(element => element.id == this.toDelete.id);
            if(index != -1){
              this.allData.results.splice(index, 1)
            }
            this.dialog.getDialogById("wefly_loading_modale_").close()
            this.snackBar.open('Requête effectuée avec succès', 'Ok', { duration: 5000 });
          }, (err) => {
            // tslint:disable-next-line:max-line-length
            this.dialog.getDialogById("wefly_loading_modale_").close()
            this.snackBar.open('Désolé un problème est survenu lors de l\'exécution de votre requête, veuillez réessayer plus tard.', 'Ok', { duration: 5000 });
          }
        );

      }else{
      }
    });
  }


  onReadAll() {
    this.getData(1);
  }

  onScroll(e) {
  }


  onDelete() {
    this.serv.update(this.toDelete.id, this.toDelete.description, this.toDelete.libelle,  true).then(
      (res) => {
        this.onReadAll();
        this.snackBar.open('Requête effectuée avec succès', 'Ok', { duration: 5000 });
      }, (err) => {
        // tslint:disable-next-line:max-line-length
        this.snackBar.open('Désolé un problème est survenu lors de l\'exécution de votre requête, veuillez réessayer plus tard.', 'Ok', { duration: 5000 });
      }
    );
    $('#Delete').modal('hide');
  }


  gotsearchValue(a) {
    return a;
  }

  onSubmitToUpdate(f: NgForm) {
    this.toDelete.libelle = f.value.typeAttributLibelle;
    this.toDelete.description = f.value.typeAttributDescription;
    this.serv.update(this.toDelete.id, this.toDelete.description, this.toDelete.libelle,  false).then(
      (res: any) => {
        let index = this.allData.results.findIndex(element => element.id == this.toDelete.id);
        if(index != -1){
          this.allData.results[index] = res.updateTypeAttribut.typeattribut
        }
        f.reset();
        this.snackBar.open('Requête effectuée avec succès', 'Ok', { duration: 5000 });
      }, (err) => {
      }
    );
  }

  clickUpdate(fonction) {
    this.toDelete = fonction;
    this.updting = true;
  }

  onReadOne(id: number) {
    // const fctRes = this.serv.readFonctionById(id);
    // return fctRes;
  }

  addNew(f: NgForm) {
    f.reset();
    this.updting = false;
  }

  getMessages(): void {
    this.loading = true;
    // this.messageService.getMessages({ page: this.page, limit: this.limit }).subscribe(res => {
    //   this.total = res.total;
    //   this.messages = res.messages;
    //   this.loading = false;
    // });
  }
  goToPage(n: number): void {
    this.page = n;
    this.getData(n);
  }

  onNext(): void {
    this.page++;
    this.getData(this.page);
  }

  onPrev(): void {
    this.page--;
    this.getData(this.page);
  }


  getData(page : number = 1, filter_field : any = null) {
    this.loading = true
    let filter = 'deleted : false';
    if(filter_field != null && filter_field.field != null){
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`
    }
    let rep: any;
    this.serv.read(filter, page).then(
      (res) => {
        this.loading = false
        rep = res;
        this.allData = rep.searchTypeAttribut
        this.totalCount = rep.searchTypeAttribut.totalCount
      }, (err) => {
        return err;
      }
    );
  }

  onChangePage(data){
    this.currentPage = data.id;
    this.getData(data.id)
  }





  filterData(data){
    if(data.action === 0){
      return
      this.filter = data.data_
    }else{
      this.loading = true
      this.filter = data.data_
      if(data.data_.value.length == 0){
        this.getData(1)
      }else{
        this.getData(1, this.filter)
      }
    }
  }

}
