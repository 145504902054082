import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addZero'
})
export class AddZeroPipe implements PipeTransform {

  transform(value: any): string {
    // return null;
    let returnValue : string
    returnValue = value
    if (value < 10) {
      returnValue =  `0${value}`;
    }
    
    return returnValue
  }

}
