import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import 'jquery';
import 'bootstrap';
import { delay } from 'q';
import { UtilsService } from '../_services/utils.service';
import { CompartimentService } from '../_services/compartiment.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { TypeDeCoucheService } from '../_services/type-couche.service';
import { DeletedComponent } from '../_modale/deleted/deleted.component';

declare var $: any;

@Component({
  selector: 'app-compartiment-form',
  templateUrl: './compartiment-form.component.html',
  styleUrls: ['./compartiment-form.component.css']
})
export class CompartimentFormComponent implements OnInit {


  // Parametre filter
  fields_selected_: Array<any> = [
    { libelle_db: "libelle_Icontains", libelle: "Libelle" },
    { libelle_db: "description_Icontains", libelle: "Description" }
  ]

  filter: any = {
    field: null,
    value: null
  }

  loading: boolean = true

  // Fin paramatre


  allData: any = null
  total = 0;
  page = 1;
  limit = 20;
  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;
  toDelete: {
    compartimentId: String,
    compartimentLibelle: String,
    compartimentLibelledb: String,
    compartimentDescription: String,
    compartimentTypeCouche: String,
    compartimentDeleted: Boolean,
  } = {
      compartimentId: "",
      compartimentLibelle: "",
      compartimentLibelledb: "",
      compartimentDescription: "",
      compartimentTypeCouche: "",
      compartimentDeleted: false
    };
  updting = false;
  updated = false;
  pageAct: any = 1;
  allTypeCouche: any[] = [];
  comIcone: any;

  constructor(
    private snackBar: MatSnackBar,
    private serv: CompartimentService,
    private typeCoucheServ: TypeDeCoucheService,
    private utils: UtilsService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.typeCoucheServ.read().then(
      (res: any) => {
        this.allTypeCouche = (res.searchTypeDeCouche.results != null) ? res.searchTypeDeCouche.results : [];
      }, (err) => {
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
    this.onReadAll();
  }

  onSubmit(f: NgForm) {

    if (this.updting === true) {
      this.onSubmitToUpdate(f);
    } else {
      // tslint:disable-next-line:max-line-length
      this.serv.create(
        f.value.compartimentDescription,
        f.value.compartimentLibelle,
        f.value.compartimentLibelledb,
        f.value.compartimentTypeCouche,
        this.comIcone,
        false).then(
          (res: any) => {
            console.table(res.createCompartiment);
            this.allData.results.unshift(res.createCompartiment.compartiment)
            this.snackBar.open('Requête effectuée avec succès', 'Ok', { duration: 5000 });
            // this.allData.push(res.createCompartiment);
            f.reset();
            // this.onReadAll();
          }, (err) => {
          }
        ).catch(reason => this.utils.setErrorsHttp(reason));
    }
  }

  delete(upd) {
    $('#Delete').modal('show');
    this.toDelete.compartimentId = upd.id;
    this.toDelete.compartimentLibelle = upd.libelle;
    this.toDelete.compartimentLibelledb = upd.libelledb;
    this.toDelete.compartimentDescription = upd.description;
    this.toDelete.compartimentTypeCouche = upd.typeCouche.id;
    this.toDelete.compartimentDeleted = upd.deleted;
  }








  openDeletedDialog(upd): void {
    this.toDelete.compartimentId = upd.id;
    this.toDelete.compartimentLibelle = upd.libelle;
    this.toDelete.compartimentLibelledb = upd.libelledb;
    this.toDelete.compartimentDescription = upd.description;
    this.toDelete.compartimentTypeCouche = upd.typeCouche.id;
    this.toDelete.compartimentDeleted = upd.deleted;

    const dialogRef = this.dialog.open(DeletedComponent, {
      width: '300px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.utils.openLoadingDialog();
        this.serv.update(this.toDelete.compartimentId, this.toDelete.compartimentLibelle, this.toDelete.compartimentLibelledb, this.toDelete.compartimentDescription, this.toDelete.compartimentTypeCouche, this.comIcone, true).then(
          (res) => {
            this.dialog.getDialogById("wefly_loading_modale_").close()
            let index = this.allData.results.findIndex(element => element.id == this.toDelete.compartimentId);
            if (index != -1) {
              this.allData.results.splice(index, 1)
            }
          }, (err) => {
          }
        );
      } else {
      }
    });
  }









  onReadAll() {
    this.getData(1);
  }

  onDelete() {
    // tslint:disable-next-line:max-line-length
    this.serv.update(this.toDelete.compartimentId, this.toDelete.compartimentLibelle, this.toDelete.compartimentLibelledb, this.toDelete.compartimentDescription, this.toDelete.compartimentTypeCouche, this.comIcone, true).then(
      (res) => {
        $('#Delete').modal('hide');
        let index = this.allData.results.findIndex(element => element.id == this.toDelete.compartimentId);
        if (index != -1) {
          this.allData.results.splice(index, 1)
        }
      }, (err) => {
      }
    );
  }
  gotsearchValue(a) {
    return a;
  }

  onDismis() {
    $('#Delete').modal('hide');
  }

  onSubmitToUpdate(f: NgForm) {
    // console.clear();
    console.table(f.value);

    this.toDelete.compartimentLibelle = f.value.compartimentLibelle;
    this.toDelete.compartimentDescription = f.value.compartimentDescription;
    this.toDelete.compartimentLibelledb = f.value.compartimentLibelledb;
    this.toDelete.compartimentTypeCouche = f.value.compartimentTypeCouche;
    this.toDelete.compartimentDeleted = false;

    // tslint:disable-next-line:max-line-length
    this.serv.update(
      this.toDelete.compartimentId,
      this.toDelete.compartimentDescription,
      this.toDelete.compartimentLibelle,
      this.toDelete.compartimentLibelledb,
      this.toDelete.compartimentTypeCouche, this.comIcone, false).then(
        (res: any) => {
          this.snackBar.open('Requête effectuée avec succès', 'Ok', { duration: 5000 });
          // this.onReadAll();
          let index = this.allData.results.findIndex(element => element.id == this.toDelete.compartimentId);
          if (index != -1) {
            this.allData.results[index] = res.updateCompartiment.compartiment;
          }
          f.reset();
          this.updting = false;
          this.updated = false;
        }, (err) => {
        }
      ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  clickUpdate(upd) {
    this.toDelete.compartimentId = upd.id;
    this.toDelete.compartimentLibelle = upd.libelle;
    this.toDelete.compartimentLibelledb = upd.libelledb;
    this.toDelete.compartimentDescription = upd.description;
    this.toDelete.compartimentTypeCouche = upd.typeCouche.id;
    this.toDelete.compartimentDeleted = upd.deleted;
    this.updting = true;
  }
  onReadOne(id: number) {
    // const fctRes = this.serv.readFonctionById(id);
    // return fctRes;
  }

  /**
   * Ajout de nouveau
   */
  addNew(f: NgForm) {
    f.reset();
    this.updting = false;
  }

  getMessages(): void {
    this.loading = true;
    // this.messageService.getMessages({ page: this.page, limit: this.limit }).subscribe(res => {
    //   this.total = res.total;
    //   this.messages = res.messages;
    //   this.loading = false;
    // });
  }
  goToPage(n: number): void {
    this.page = n;
    this.getData(n);
  }

  onNext(): void {
    this.page++;
    this.getData(this.page);
  }

  onPrev(): void {
    this.page--;
    this.getData(this.page);
  }


  getData(page: number = 1, filter_field: any = null) {
    this.loading = true
    let rep: any;
    let filter = 'deleted : false'
    if (filter_field != null && filter_field.field != null) {
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`
    }
    this.serv.read(filter, page).then(
      (res) => {
        this.loading = false;
        rep = res;
        this.allData = rep.searchCompartiment;
        this.totalCount = rep.searchCompartiment.totalCount
      }, (err) => {
        return err;
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
  }


  onChangePage(data) {
    this.currentPage = data.id;
    this.getData(data.id);
  }


  filterData(data) {
    if (data.action === 0) {
      return
      this.filter = data.data_
    } else {
      this.loading = true
      this.filter = data.data_
      if (data.data_.value.length == 0) {
        this.getData(1)
      } else {
        this.getData(1, this.filter)
      }
    }
  }

  processFile(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.selectedFile = reader.result;
        // console.clear();
        this.comIcone = reader.result;
      };
    } else {
      // tslint:disable-next-line:no-console
    }
  }

}
