import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import request from 'graphql-request';
import * as jwt_decode from 'jwt-decode';
import gql from 'graphql-tag';
import { Apollo, QueryRef } from 'apollo-angular';
import { Email, SearchUser } from '../_models/user';


const RESET_COMPTE = gql`
mutation($email: String!) {
  createForgetPassword( newForgetpassword: {userEmail: $email} ){
    ok
  }
}
`;


const SEARCH_USER = gql `
query($username:String){
  searchUser(username:$username){
    results{
          id,
          password,
          username,
          dateJoined,
          email,
          firstName,
          isActive,
          isStaff,
          isSuperuser,
          lastLogin,
          lastName,
      
          profile {
            user{
              id
              email
              firstName
              lastName
              isActive
              isStaff
              isSuperuser
              username
              password
            }
            admin
            deleted
            photo
            photoUrl
            id,
            codeDeVerification,
            contact,
            dateNaissance,
            deleted,
            displayName,
            genre,
            nom,
            prenom,
            tracking,
            
            entreprise {
              logo
              logoUrl
              id,
              raisonSociale,
              telephone
               email
              categorie{
                libelle,
                numero,
                etiquette
              }
            },
            fonction{
              id,
              libelle
            },
             zone{
              id
            },
            categorie{
              id,
              libelle
            }
          }
    }
  }
}
`;


@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(private utils: UtilsService, private apollo:Apollo) { }

  inscription(
    username: String,
    email: String,
    firstName: String,
    lastName: String,
    password: String,
   
  ) {
    const query = `
    mutation(
			$username: String!,
			$email: String!,
			$firstName: String!,
			$lastName: String!,
			$password: String!,
     
    ) {
      createUser(newUser:{username: $username, email: $email, firstName: $firstName, lastName: $lastName, password: $password, groups: [], userPermissions: []}) {
        ok
        errors{
          field
          messages
        }
        user{
          id,
				username,
				email,
				firstName,
				lastName
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        request(this.utils.HOST_URL, query, {
          username,
          email,
          firstName,
          lastName,
          password,

         
        }).then(
          (res:any) => {
            resolve(res.createUser);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  create(password: String, username: String, dateJoined: String, email: String, firstName: String, isActive: Boolean, isStaff: Boolean, isSuperuser: Boolean, lastLogin: String, lastName: String, ) {
    const query = `
    mutation($password: String!, $username: String!, $dateJoined: CustomDateTime, $email: String, $firstName: String, $isActive: Boolean, $isStaff: Boolean, $isSuperuser: Boolean, $lastLogin: CustomDateTime, $lastName: String, ) {
      createUser(newUser: {password: $password, username: $username, dateJoined: $dateJoined, email: $email, firstName: $firstName, isActive: $isActive, isStaff: $isStaff, isSuperuser: $isSuperuser, lastLogin: $lastLogin, lastName: $lastName, }) {
        user {
          id, password, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName,
        }
        errors {
          messages
          field
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          password, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: number, username: String, dateJoined: String, email: String, firstName: String, isActive: Boolean, isStaff: Boolean, isSuperuser: Boolean, lastLogin: String, lastName: String, ) {
    const query = `
      mutation($id: ID!, $username: String!, $dateJoined: CustomDateTime, $email: String, $firstName: String, $isActive: Boolean, $isStaff: Boolean, $isSuperuser: Boolean, $lastLogin: CustomDateTime, $lastName: String, ) {
        updateUser(newUser: {id: $id, username: $username, dateJoined: $dateJoined, email: $email, firstName: $firstName, isActive: $isActive, isStaff: $isStaff, isSuperuser: $isSuperuser, lastLogin: $lastLogin, lastName: $lastName, groups: [], userPermissions: []}) {
          user {
            id, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName,
          }
          errors {
            messages
            field
          }
        }
      }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: String = 'deleted: false', page: Number = 1, order: String = "id") {
  
    const query = `
    query {
      searchUser(${filter}){
        results(page:` + page + `, ordering:"` + order + `") {
          id,
          password,
          username,
          dateJoined,
          email,
          firstName,
          isActive,
          isStaff,
          isSuperuser,
          lastLogin,
          lastName,
          profile {
            admin
            deleted
            photo
            photoUrl
            id,
            codeDeVerification,
            contact,
            dateNaissance,
            deleted,
            displayName,
            genre,
            nom,
            prenom,
            tracking,
            entreprise {
              logo
              logoUrl
              id,
              raisonSociale,
              telephone
               email
              categorie{
                libelle,
                numero
              }
            },
            fonction{
              id,
              libelle
            },
             zone{
              id
            },
            categorie{
              id,
              libelle
            }
          }
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
      
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  createSingleFile(acteur:string, isExcel:boolean, isGpx:boolean, isKml:boolean, isPdf:boolean, isShp:boolean){
    const query = `
    mutation($acteur:ID,$isExcel:Boolean, $isGpx:Boolean, $isKml:Boolean, $isPdf:Boolean, $isShp:Boolean){
      createActeurFiles(newActeurfiles:{
        acteur:$acteur,
        isExcel:$isExcel,
        isGpx:$isGpx,
        isKml:$isKml,
        isPdf:$isPdf,
        isShp:$isShp
      }){
        acteurfiles{
          id
          isGpx
          isExcel
          isGpx
          isKml
          isPdf
          isShp
          filepath
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { acteur, isExcel, isGpx, isKml, isPdf, isShp }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  createZoneFile(acteur:string,user:string, couche:string, zone:string,  isKml:boolean , isGpx:boolean, isShp:boolean, isExcel:boolean, isPdf:boolean, attributs:string) {
    const query = `
    mutation($acteur:ID,$user:ID!, $couche : ID, $zone : ID, $isGpx:Boolean, $isExcel:Boolean, $isKml:Boolean, $isShp:Boolean $isPdf:Boolean,$attributs:String){
      createZoneFiles(newZonefiles : {
        acteur:$acteur
        user:$user
        couche : $couche,
        zone : $zone
        isGpx:$isGpx,
        isExcel:$isExcel,
        isKml:$isKml,
        isShp:$isShp,
        isPdf:$isPdf
        attributs:$attributs,
      }){
        ok,
        errors{
          messages
        },
        zonefiles{
          id,
          filepath
          isKml
          isGpx
          isShp
          isPdf
          isExcel,
          user{
            id,
            username
          }
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {acteur,user, couche, zone, isKml, isGpx, isShp, isExcel,isPdf, attributs }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  searchZoneFile(filter: string = "deleted : false") {
    const query = `
      query{
        searchZoneFile(${filter}){
          totalCount,
          results{
            zoneCorr{
              id
            },
            kmzfile,
            excelfile
          }
        }
      }
    `
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  activeOrDesactiveUser(id: number, isActive: Boolean) {
    const query = `
      mutation($id: ID!, $isActive: Boolean,) {
        updateUser(newUser: {id: $id, isActive: $isActive }) {
          user {
            id, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName,
          }
          errors {
            messages
            field
          }
        }
      }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id, isActive
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  resetPasswordByAdmin(id:string, newPassword:string) {
    const query = `
    mutation($id:String!, $newPassword:String!){
      resetPassword(id:$id, newPassword:$newPassword){
        response
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, newPassword }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  existUser(username: string) {
    const query = `query{searchUser(username : "${username}"){totalCount}}`;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res:any) => {
            if (res.searchUser.totalCount == 0 || res.searchUser.totalCount == '0' || parseInt(res.searchUser.totalCount) == 0) {
              resolve(false);
            } else {
              resolve(true);
            }
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  public searchUserLoggedProfile(): any { 

    const token  = this.utils.getStorage('weflyHeader_token')
    const tokenDecode:any = jwt_decode(token)

    // const username = this.U.getUserToken().username;
    this.read(`username: "${tokenDecode.username}"`, 1, "").then(
      (res: any) => {
        console.log('profile profile', res)
       
      },
      (err:any) => {
        console.log('errrror profile .........', err)
      }
    );
  }

  searchUserConnect(userData:SearchUser){
    const  {username} = userData
    return this.apollo.watchQuery<any>({
      query:SEARCH_USER,
      variables:{username},
      fetchPolicy:'no-cache'
    }) as QueryRef<any>

  }
  searchUserNew(userData:SearchUser){
    return new Promise((resolve:any, rejects:any)=>{
      this.searchUserConnect(userData).valueChanges.subscribe(
        (res:any)=>{
          resolve(res)
        },(err:any)=>{
          rejects(err)
        }
      )
    })
  }
  resetCompte(emailData:Email){
    const {email} = emailData
    return this.apollo.mutate({
      mutation:RESET_COMPTE,
      variables:{email}
    })
  }
  sendEmailCompte(email:Email){
    return new Promise((resolve, reject)=>{
      this.resetCompte(email).subscribe(
        (res:any)=>{
          resolve(res)
        },(err:any)=>{
          reject(err)
        }
      )
    })

  }



  searchUser(){
    
    return new Promise((resolve, rejects)=>{
      const token = this.utils.getStorage('weflyHeader_token')
      const tokenDecode:any = jwt_decode(token)
     
        console.log('token', tokenDecode)
        console.log('username', tokenDecode.username)
        if(tokenDecode.username){
          
          console.log('username' + tokenDecode.username)
          this.read(`username: "${tokenDecode.username}"`, 1, "").then( 

            (res: any) => {
            
              
              console.log("user data", res);
              if (res.searchUser.results[0].profile.deleted == true) {
               
              } else {
                let unlessTof = JSON.parse(JSON.stringify(res.searchUser.results[0]));
                delete unlessTof.profile.photo;
                if (unlessTof.profile.entreprise)
                  delete unlessTof.profile.entreprise.logo;
                
                this.utils.setCookie("_wefly_",this.utils.encrypt(JSON.stringify(unlessTof)),{ nb: 7, unite: 3 });
                sessionStorage.entreprise = this.utils.encrypt(JSON.stringify(unlessTof.profile.entreprise));
                setTimeout(()=>{
                 
                  resolve(res)
                },5000)
                if (res.searchUser.results.length > 0) {
                  
                }

                
              }
            },
            (err:any) => {
              console.log('user erreur', err)
            }
          ).catch((reason) => this.utils.setErrorsHttp(reason));

        }else{
          console.log('pas de token');
          
        }

    })
         
            
  }

 



}


