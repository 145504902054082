
export const WEATHER_CODE = [
	
	{
		"code" : 200,
		"day" : "thunderstorm with light rain",
		"night" : "thunderstorm with light rain",
		"icon" : 200
	},
	{
		"code" : 201,
		"day" : "thunderstorm with rain",
		"night" : "thunderstorm with rain",
		"icon" : 201
	},
	{
		"code" : 202,
		"day" : "thunderstorm with heavy rain",
		"night" : "thunderstorm with heavy rain",
		"icon" : 201
	},
	{
		"code" : 210,
		"day" : "light thunderstorm",
		"night" : "light thunderstorm",
		"icon" :200
	},
	{
		"code" : 211,
		"day" : " thunderstorm",
		"night" : " thunderstorm",
		"icon" : 200
	},
	{
		"code" : 212,
		"day" : " heavy thunderstorm",
		"night" : " heavy thunderstorm",
		"icon" : 200
	},
	{
		"code" : 221,
		"day" : " ragged thunderstorm",
		"night" : " ragged thunderstorm",
		"icon" : 200
	},
	{
		"code" : 230,
		"day" : " thunderstorm with light drizzle",
		"night" : " thunderstorm with light drizzle",
		"icon" : 200
	},
	{
		"code" : 231,
		"day" : " thunderstorm with drizzle",
		"night" : " thunderstorm with drizzle",
		"icon" : 200
	},
	{
		"code" : 232,
		"day" : " thunderstorm with heavy drizzle",
		"night" : "thunderstorm with heavy drizzle",
		"icon" : 200
	},

	{
		"code" : 300,
		"day" : "light intensity drizzle",
		"night" : "light intensity drizzle",
		"icon" : 300
	},
	{
		"code" : 301,
		"day" : " drizzle",
		"night" : " drizzle",
		"icon" : 300
	},
	{
		"code" : 302,
		"day" : "light intensity drizzle",
		"night" : "light intensity drizzle",
		"icon" : 300
	},
	{
		"code" : 310,
		"day" : "light intensity drizzle rain",
		"night" : "light intensity drizzle rain",
		"icon" : 300
	},
	{
		"code" : 311,
		"day" : "drizzle rain",
		"night" : "drizzle rain",
		"icon" : 300
	},
	{
		"code" : 312,
		"day" : "heavy intensity drizzle rain",
		"night" : "heavy intensity drizzle rain",
		"icon" : 300
	},
	{
		"code" : 313,
		"day" : "shower rain and drizzle",
		"night" : "shower rain and drizzle",
		"icon" : 300
	},
	{
		"code" : 314,
		"day" : "heavy shower rain and drizzle",
		"night" : "heavy shower rain and drizzle",
		"icon" : 300
	},
	{
		"code" : 321,
		"day" : "shower drizzle",
		"night" : "shower drizzle",
		"icon" : 300
	},
	{
		"code" : 500,
		"day" : "light rain",
		"night" : "light rain",
		"icon" : 500
	},
	{
		"code" : 501,
		"day" : "moderate rain",
		"night" : "moderate rain",
		"icon" : 500
	},
	{
		"code" : 502,
		"day" : "heavy intensity rain",
		"night" : "heavy intensity rain",
		"icon" : 500
	},
	{
		"code" : 503,
		"day" : "very heavy rain",
		"night" : "very heavy rain",
		"icon" : 500
	},
	{
		"code" : 503,
		"day" : "very heavy rain",
		"night" : "very heavy rain",
		"icon" : 500
	},
	{
		"code" : 504,
		"day" : "extreme rain",
		"night" : "extreme rain",
		"icon" : 500
	},
	{
		"code" : 701,
		"day" : "mist",
		"night" : "mist",
		"icon" : 700
	},
	{
		"code" : 711,
		"day" : "smoke",
		"night" : "smoke",
		"icon" : 700
	},
	{
		"code" : 721,
		"day" : "haze",
		"night" : "haze",
		"icon" : 700
	},
	{
		"code" : 731,
		"day" : "dust",
		"night" : "dust",
		"icon" : 700
	},
	{
		"code" : 741,
		"day" : "fog",
		"night" : "fog",
		"icon" : 700
	},
	{
		"code" : 751,
		"day" : "sang",
		"night" : "sang",
		"icon" : 700
	},
	{
		"code" : 751,
		"day" : "sang",
		"night" : "sang",
		"icon" : 700
	},
	{
		"code" : 761,
		"day" : "dust",
		"night" : "dust",
		"icon" : 700
	},
	{
		"code" : 762,
		"day" : "ash",
		"night" : "ash",
		"icon" : 700
	},
	{
		"code" : 771,
		"day" : "squall",
		"night" : "squall",
		"icon" : 700
	},
	{
		"code" : 781,
		"day" : "squall",
		"night" : "squall",
		"icon" : 700
	},
	{
		"code" : 800,
		"day" : "Sunny",
		"night" : "Clear",
		"icon" : 800
	},
	
	{
		"code" :801,
		"day" : "partly cloudy",
		"night" : "partly cloudy",
		"icon":801

	},
	{
		"code" : 802,
		"day" : "Partly cloudy",
		"night" : "Partly cloudy",
		"icon" : 802
	},
	{
		"code" : 803,
		"day" : "Cloudy",
		"night" : "Cloudy",
		"icon" : 802
	},
	{
		"code" : 804,
		"day" : "Overcast",
		"night" : "Overcast",
		"icon" : 802
	}
]