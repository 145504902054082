import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
// import { login } from 'authentification.service'
import { AuthentificationService } from "./authentification.service";
import { Router } from "@angular/router";
import { ConnexionService } from "../_services/connexion.service";
import { UtilsService } from "../_services/utils.service";
import { UserService } from "../_services/user.service";
import request from "graphql-request";
import { CookieService } from "ngx-cookie-service";
import * as jwt_decode from 'jwt-decode';
import { Email, SearchUser } from "../_models/user";
import { MatDialog } from "@angular/material";
import { AlertDialogComponent } from "../alert-dialog/alert-dialog.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  @Output() closeForm = new EventEmitter();
  email: any;
  fm: any;
  fields = [];
  loader = false;
  loaderSendEmail: boolean = false
  auStateSuccess = false;
  alertCredential:boolean = false
  auStateFail = false;
  form = {
    value: {
      username: null,
      password: null,
    },
  };
  error: {
    view: Boolean;
    msg: String;
  }[] = [{ view: false, msg: "Pseudo ou mot de passe incorrecte." }];
  isPassword;
  show = false;
  stateForm = false;

  loginForm: FormGroup
  forgetForm: FormGroup
  loginFieldAlert: boolean = false
  resetPassAlert: boolean = false


  constructor(

    private router: Router,
    private login: ConnexionService,
    private utils: UtilsService,
    private userServ: UserService,
    private fb: FormBuilder,
    private dialog : MatDialog

  ) {

    this.loginForm = this.fb.group({
      "username": ['', [Validators.required, Validators.minLength(5)]],
      "password": ['', [Validators.required, Validators.minLength(8)]]
    })

    this.forgetForm = this.fb.group({
      'email': ['', [Validators.required, Validators.email]]
    })

  }

  ngOnInit() {

    this.fm = null;
    this.isPassword = "password";

  }
  getForgetForm() {
    this.stateForm = true
  }
  getConnectForm() {
    this.stateForm = false
  }
  getRegisterForm() {
    this.router.navigate(['/inscription'])
  }
  getDemoLink(){
    window.open("https://drive.google.com/drive/folders/1n8e4AS5Rv-AZc93L10DymVohT9wnRNCP", '_blank')
   }
  onIsPassword() {
    this.show = !this.show;
    this.show === true
      ? (this.isPassword = "text")
      : (this.isPassword = "password");
  }



  closeLogin() {
    this.closeForm.emit();
  }


  connexion(loginform: FormGroup) {
    if (loginform.valid) {
      if(this.alertCredential){
        this.alertCredential = false
      }
      this.loader = true
      this.loginFieldAlert = false
      console.log(loginform.value)
      this.login.connexion(loginform.value.username, loginform.value.password).then(
        (resAuth: any) => {
          console.log('login succes', resAuth.tokenAuth.token)
          this.utils.setStorage('weflyHeader_token', resAuth.tokenAuth.token)

          setTimeout(() => {
        

            const token = this.utils.getStorage('weflyHeader_token')
            const tokenDecode: any = jwt_decode(token)

            this.userServ.searchUserNew(new SearchUser(tokenDecode.username)).then(
              (res: any) => {
                console.log('user profile data', res)

                if (res.data.searchUser.results[0].profile.deleted == true) {
                  this.error[0].view = true;
                  this.loader = false;
                } else {
                  

                  let unlessTof = JSON.parse(JSON.stringify(res.data.searchUser.results[0]));
                  console.log('encript data', unlessTof)
                  delete unlessTof.profile.photo;
                  if (unlessTof.profile.entreprise)
                    delete unlessTof.profile.entreprise.logo;
                  
                  this.utils.setCookie("_wefly_", this.utils.encrypt(JSON.stringify(unlessTof)), { nb: 7, unite: 3 });
                  sessionStorage.entreprise = this.utils.encrypt(JSON.stringify(unlessTof.profile.entreprise));

                  this.router.navigate(["/wegeo"])
                  this.loader = false

                }

              }
            )

          }, 2000)


        },
        (err) => {
          console.log("err1", err);
          this.alertCredential = true
          this.loader = false;
          this.auStateSuccess = false;
          this.auStateFail = true;
          if (
            JSON.stringify(err).indexOf("Please, enter valid credentials") > 0
          ) {
            this.error[0].view = true;
            this.loader = false;
          } else {
          }
        }
      )
        .catch((reason) => this.utils.setErrorsHttp(reason));
    } else {
      this.loginFieldAlert = true
      this.loader = false
    }

  }

  resetPass(sendEmailForm: FormGroup) {
    if (sendEmailForm.valid) {
      this.loaderSendEmail = true
      this.resetPassAlert = false
      console.log(sendEmailForm.value)
      this.userServ.sendEmailCompte(new Email(sendEmailForm.value.email)).then(
        (res: any) => {
          if (res.data.createForgetPassword.ok) {

            this.loaderSendEmail = false
          const dialogRefe = this.dialog.open(AlertDialogComponent,{
              width:'30rem',
              data:{alertTitle:"Tout s'est bien passé ! ", msg:'Votre demande a été prise en compte. Veuillez consulter votre mail pour terminer la procédure.'}
            })

            dialogRefe.afterClosed().subscribe(result => {
              this.stateForm = false
          })
            console.log('email send success', res)
          }
        }, (err: any) => {
          console.log('err send reset', err)
          this.loaderSendEmail = false
        }
      )
    } else {
      this.resetPassAlert = true
    }


  }



}
