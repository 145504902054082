import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SuggestionService } from 'src/app/_services/suggestion.service';
import { UtilsService } from 'src/app/_services/utils.service';

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.scss']
})
export class SuggestionComponent implements OnInit {
  sugForm: FormGroup;
  msgErr: string = null;
  alert:boolean = false
  loader:boolean = false

  constructor(
    public dialogRef: MatDialogRef<SuggestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private sugServ: SuggestionService,
    private utils : UtilsService

  ) { 


    this.sugForm = this.formBuilder.group({
      sujet: ['', [Validators.required]],
      commentaire: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    
  }

  public onClose(): void {
    this.dialogRef.close()
  }
  createSuggestion(formData:FormGroup){
    if(formData.valid){
      this.loader = true
      this.sugServ.create(formData.value.sujet, formData.value.commentaire).then(
        (res: any) => {
          this.loader = false
          console.log('suggestion ok',res)
            this.onClose();
            this.utils.snack('Votre suggestion à été envoyée avec succès.')
        }, (err) => {
          this.loader  = false
          console.log('erreur suggetion',err)
        }
      )

    }else{
      this.alert = true
    }
  }


  //  onSubmit(){

  //   if(this.sugForm.invalid){
  //     this.msgErr = 'Veuillez remplir les deux champs ci-dessus!';
  //     return
  //   } else {
  //     let f = this.sugForm.value;
  //     this.sugServ.create(f.sujet, f.commentaire).then(
  //       (res: any) => {
          
  //         document.getElementById('suggestion-bloc').innerHTML  = '<div class="row"></div><div class="col-md-12" style="color: green; font-size: 1.5em; font-weight: bolder">Suggestion envoyée avec succcès.</div></div>';
  //         setTimeout(() => {
  //           this.onClose();
  //         }, 10000);
  //       }, (err) => {
  //       }
  //     )
  //   }
  // }

}
