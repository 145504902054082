import { ConfirmComponent } from './../confirm/confirm.component';
import { MatTableDataSource } from '@angular/material/table';
import { FamilleDeCultureService } from './../_services/famille-de-culture.service';
import { CultureService } from './../_services/culture.service';
import { UtilsService } from '../_services/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { FormStockComponent } from '../@marketplace/_modals/form-stock/form-stock.component';

@Component({
  selector: 'app-culture',
  templateUrl: './culture.component.html',
  styleUrls: ['./culture.component.scss']
})
export class CultureComponent implements OnInit {
  familleCultures = [];
  dataSource = new MatTableDataSource<any>();
  cultures = [];
  constructor(private dialog: MatDialog, private util: UtilsService, private familleCultureService: FamilleDeCultureService,
    private cultureService: CultureService) { }
  columnsToDisplay = ['libelle', 'description', 'action'];
  ngOnInit() {
    try {
      this.familleCultureService.read().then(
        (res: any) => {
          this.familleCultures = res;
        },
        (err) => {
        }
      );
    } catch (error) {
      this.util.setErrorsHttp(error);
    }

    this.readCulture();
  }

  readCulture() {
    this.cultureService.read().then(
      (res: []) => {
       this.cultures = res;
       this.updateDataSource();
      },
      (err) => {
        this.util.setErrorsHttp(err);
      }
    );
  }

  updateDataSource() {
    this.dataSource.connect().next(this.cultures);
  }

  addCulture() {
    const dialogRef = this.dialog.open(FormStockComponent, {
      width: '60%',
      data: { ref: 'Culture', formType: 'Cuture',
      familleCultures: this.familleCultures, instance: { libelle: null, description: null, familleCulture: null}, }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.cultures.push(res);
          this.updateDataSource();
        }
      }
    );
  }

  update(i: number) {
    const instanceObj = this.cultures[i];
    const dialogRef = this.dialog.open(FormStockComponent, {
      width: '60%',
      data: { ref: 'Culture', formType: 'Cuture', familleCultures: this.familleCultures, instance: instanceObj }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.cultures[i] = res;
          this.updateDataSource();
          this.util.snack(`${instanceObj.libelle} mis à jour avec succès`);
        }
      }
    );
  }

  delete(i: number) {
    const instanceObj = this.cultures[i];
    const dialogRef = this.dialog.open(
      ConfirmComponent,
      {
        width: '50%',
        data: {message: `Souhaitez vous supprimer ${instanceObj.libelle}`}
      }
    );

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
           this.cultureService.update(
             instanceObj.id,
             instanceObj.familleCulture.id,
             true,
             instanceObj.description,
             instanceObj.libelle
           ).then(
             (results) => {
               this.cultures.splice(i, 1);
               this.updateDataSource();
             }
           );
        }
      }
    )
  }


}
