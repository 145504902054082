import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root'
})
export class ParcsService {
  constructor(private utils: UtilsService, private dbService: NgxIndexedDBService) { }

  read(filter: string = `deleted : false`, offset: number = 0, limit: number = 25, order: string = '-created_at', total_online = 0) {
    const query = `
    query($limit: Int! , $offset: Int!, $order: String!) {
      searchParcsReserves(${filter}){
        results(limit: $limit, offset: $offset, ordering: $order) { 
          libelle
          parcPoly
          id
          perimetre
          acres
          coords
          createdAt
          updateAt
          deleted
          region {
            id
          }
        }
        totalCount
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.dbService.count('parcs').then(
          total_offline => {
            if (total_offline > total_online) {
              this.dbService.clear('parcs').then(() => { }, error => {  });
              this.read(filter, offset, limit, order, total_offline);
            }
            if (total_offline == total_online && total_offline != 0) {
              this.dbService.getAll('parcs').then(
                parcs => {
                  let p: any = {
                    results: parcs.slice(offset, offset + limit),
                    totalCount: parcs.length
                  }
                  // this.dbService.getByKey('parcs', 6187).then(
                  //   person => {
                  //   },
                  //   error => {
                  //   }
                  // );
                  resolve(p)
                },
                error => {
                }
              );
            } else {
              if ((offset >= total_offline)) {
                this.utils.client.request(query, { limit, offset, order }).then(
                  (res: any) => {
                    this.dbService.add('parcs', res.searchParcsReserves.results[0]).then(() => { }, error => {  });
                    resolve(res.searchParcsReserves);
                  }, (err) => {
                    this.utils.setErrorsHttp(err)
                    reject(err);
                  }
                );
              } else {
                this.dbService.getAll('parcs').then(
                  parcs => {
                    let p: any = {
                      results: parcs.slice(offset, offset + limit),
                      totalCount: total_online
                    }
                    resolve(p)
                  },
                  error => {
                    
                  }
                );
              }
              /*
              this.utils.client.request(query, { limit, offset, order }).then(
                (res: any) => {
                  if ((offset >= total_offline)) {
                    this.dbService.add('parcs', res.searchParcsReserves.results[0]).then(() => { }, error => {  });
                  }
                  resolve(res.searchParcsReserves);
                }, (err) => {
                  this.utils.setErrorsHttp(err)
                  reject(err);
                }
              );
              */
            }
          },
          error => {  }
        );
      }
    );
  }

  readTotalcount(filter: string = `deleted : false`) {
    const query = `
    query {
      searchParcsReserves(${filter}){
        totalCount
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res: any) => {
            resolve(res.searchParcsReserves.totalCount);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}
