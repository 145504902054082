import { Component, OnInit, Input, Output, OnChanges, EventEmitter, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-pagination-controls',
  templateUrl: './pagination-controls.component.html',
  styleUrls: ['./pagination-controls.component.scss']
})
export class PaginationControlsComponent implements OnInit, OnChanges {

  @Input() totalCount : number = 0
  @Input() pageSize = 10;
  @Input() maxOfPages = 10;
  @Input() currentPage = 1;
  @Output() changePage = new EventEmitter()
  @Input() styles:boolean = false;
  items : any[] = []
  pager : any = {};

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){
    // console.clear();
   
    let pages = Math.ceil(this.totalCount / this.maxOfPages);
    this.items = Array(pages).fill(0).map((x, i) => ({ id: (i + 1)}));
    this.pager.currentPage = this.currentPage;
    this.pager.pages = this.items;
    this.pager.totalPage = this.items.length;
    this.pager.startIndex = 1;
    this.pager.endIndex = 6;
    while(this.currentPage>this.pager.endIndex){
      this.pager.startIndex =  this.pager.endIndex - 1
      this.pager.endIndex = this.pager.startIndex  + 5
    }
  }

  setPage(page :any){
    this.currentPage = page.id;
    this.pager.currentPage = page.id;
    this.pager.startIndex = this.pager.currentPage - 3;
    this.pager.endIndex = this.pager.currentPage + 3
    this.changePage.emit(page);
  }

}
