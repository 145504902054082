import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';

@Injectable({
  providedIn: 'root'
})
export class AttributFormulaireService {

  constructor(private utils: UtilsService) { }
  create(
    attribut: String,
    createur: String,
    formcouche: String,
    ordreAffichage: Number,
    requis: Boolean,
    deleted: Boolean,

  ) {
    const query = `
    mutation(
			$attribut:  ID!,
			$createur:  ID!,
			$formcouche:  ID!,
			$ordreAffichage:  Int!,
			$requis:  Boolean!,
			$deleted:  Boolean!, 
    ) {
      createAttributFormulaire(newAttributformulaire: {
				attribut: $attribut,
				createur: $createur,
				formcouche: $formcouche,
				ordreAffichage: $ordreAffichage,
				requis: $requis,
				deleted: $deleted,
      })
       {attributformulaire{
        id,
				attribut{
          id
          requis
          libelle
        },
				createur{
          id
        },
				formcouche{
          id
        },
				ordreAffichage,
				requis,
				deleted,
        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          attribut,
          createur,
          formcouche,
          ordreAffichage,
          requis,
          deleted,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
  update(id: String,
    attribut: String,
    createur: String,
    formcouche: String,
    ordreAffichage: Number,
    requis: Boolean,
    deleted: Boolean,
  ) {
    const query = `
    mutation($id: UUID!,
			$attribut:  ID!,
			$createur:  ID!,
			$formcouche:  ID!,
			$ordreAffichage:  Int!,
			$requis:  Boolean!,
			$deleted:  Boolean!,
    ) {
      updateAttributFormulaire(newAttributformulaire: {
        id: $id,
				attribut: $attribut,
				createur: $createur,
				formcouche: $formcouche,
				ordreAffichage: $ordreAffichage,
				requis: $requis,
				deleted: $deleted,
      }) {attributformulaire{
        id,
				attribut{
          id,
          libelle
        },
				createur{
          id
        },
				formcouche{
          id
        },
				ordreAffichage,
				requis,
				deleted,
        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id,
          attribut,
          createur,
          formcouche,
          ordreAffichage,
          requis,
          deleted,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = `deleted : false`, page: number = 1, order: string = 'ordre_affichage') {
    const query = `
    query($page: Int!, $order: String!) {
      searchAttributFormulaire(${filter}){
        results(page: $page, ordering: $order)
        {
          id,
          attribut{
            id
            libellebd
            libelle
            champsDeBase
            attributsOptions{
              id
              libelle
            }
            typeAttribut{
              libelle
              id
            }
            compartiment{
              id
            }
          },
          createur{
            id
          },
          formcouche{
            id
          },
          ordreAffichage,
          requis,
          deleted,
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { page, order }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

