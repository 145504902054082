import { Component, OnInit } from "@angular/core";
import { UtilsService } from "src/app/_services/utils.service";
import "jquery";
import "bootstrap";
declare var $;
import * as jwt_decode from 'jwt-decode';
import { userInfo } from "os";


@Component({
  selector: "app-forms",
  templateUrl: "./forms.component.html",
  styleUrls: ["./forms.component.scss"],
})
export class FormsComponent implements OnInit {
  pagesForms = true;
  stated;
  userInfo:any

  constructor(public utils: UtilsService) {}
  ngOnInit() {
   
    this.userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie("_wefly_")));
    console.log('userInfo', this.userInfo)

    const token = jwt_decode(this.utils.getStorage('weflyHeader_token'))
    if (token.entrePriseId === "null") {
      $("#entreprisePop").modal("show");
    }
    // Recupération de la liste de formulaire
    // console.log("boolshow"boolshow);

  }
  boolshow(event) {
    this.stated = event; 
    console.log("this.stated", this.stated);
  }
  Swith() {
    this.pagesForms = !this.pagesForms;
    console.log("this.pagesForms", this.pagesForms);
  }

  public gotsearchValue(e): any {}
}
