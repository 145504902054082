import { Component, OnInit, OnChanges } from '@angular/core';
import 'bootstrap';
import 'jquery';
import { UtilsService } from 'src/app/_services/utils.service';
import { StatisitiquesService } from 'src/app/_services/statisitiques.service';
import { UserService } from 'src/app/_services/user.service';
declare var $;

@Component({
  selector: 'app-cooperative-dashboard',
  templateUrl: './cooperative-dashboard.component.html',
  styleUrls: ['./cooperative-dashboard.component.scss']
})
export class CooperativeDashboardComponent implements OnInit {
  dahboard = false;
  profileLogged: any = null;
  eltStatPlantation: any = null;
  eltStatPlanteur: any = null;

  panelOpenState = true;
  entrepriseId: string = null;

  constructor(
    public utils: UtilsService,
    private statServ: StatisitiquesService,
    private userService:UserService
  ) { }

  ngOnInit() {
    
      this.profileLogged = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_'))); 
      if(this.utils.getCookie('_wefly_')){
        this.entrepriseId = this.profileLogged.profile.entreprise.id;
        this.start();

      }
   
    
  }

  switch() {
    this.dahboard = !this.dahboard;
  }

  public start(): any {
    this.statPlantation();
    this.statPlanteur();
  }

  public statPlantation(): void {
    this.statServ.readPlantation(`entreprise: "${this.entrepriseId}"`).then(
      (res: any) => {
      
        // console.clear();
        this.eltStatPlantation = res.results[0];
      }, (err) => {
       
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason));
  }

  public statPlanteur(): void {
    this.statServ.readPlanteur(`entreprise: "${this.entrepriseId}"`).then(
      (res: any) => {
       
        // console.clear();
        this.eltStatPlanteur = res.results[0];
      }, (err) => {
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason));
  }

  public gotsearchValue(e): any { }

  makeFilter(filter) {
    // console.clear();
    this.entrepriseId = filter.id;
    this.start();
  }

}
