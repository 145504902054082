import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteActeurComponent } from 'src/app/_modale/delete-acteur/delete-acteur.component';
declare var $;
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { UtilsService } from 'src/app/_services/utils.service';
import { UtilsService as wegeoUtils } from 'src/app/@wegeo/_services/utils.service';
import { GeneratePdfService } from '../_services/generate-pdf.service';
import { ActeurService } from 'src/app/_services/acteurs.service';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/_store/app.state';
import { selectForm } from 'src/app/_store/selectors/form.selectors';
import { ExcelFieldToDowloadComponent } from 'src/app/excel-field-to-dowload/excel-field-to-dowload.component';
import { GetExcelService } from 'src/app/_services/get-excel.service';
import { DateRange } from 'src/app/shared/interval-date-filter/interval-date-filter.component';
import { NewActeurService } from 'src/app/_services/newActeurs.service';
import { Router } from '@angular/router';
import { NewAlertConfirmComponent } from 'src/ui/new-alert-confirm/new-alert-confirm.component';
import { DataCacheService } from 'src/app/_services/data-cache.service';
import { UserService } from 'src/app/_services/user.service';
import { LoadingComponent } from '../_modale/loading/loading.component';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-planteur',
  templateUrl: './planteur.component.html',
  styleUrls: ['./planteur.component.scss'],
  providers: [ExcelFieldToDowloadComponent]
})
export class PlanteurComponent implements OnInit, AfterViewInit, OnChanges {

  formcouches$: Observable<object[]>;
  formCouche: object[];
  planteurs: any[] = [];
  profileLogged :any
  aucunResultat:boolean = false
  @Input() entrepriseFilter: any;
  @Input() userConnected: any;
  @Input() couche: any;
  @Input() img: any[] = [];
  @Output() dowloadExcel = new EventEmitter<any>();

  isSelected:boolean = true
  isSelectedDec:boolean = true
  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;
  loading: boolean = true;
  fileLoading: boolean = false;
  activeSearch:boolean = false
  // filter:string | any = null
  // Filter param
  fields_selected_: Array<any> = [
    
    { libelle_db: "acteurConc_ActeurPerso_Nom_Icontains", libelle: "Nom" },
    { libelle_db: "acteurConc_ActeurPerso_Prenoms_Icontains", libelle: "Prénoms" },
    //{ libelle_db: "acteurPerso_CodePlanteur_Icontains", libelle: "Code planteur" },
  ]
  filter: string=""





  @ViewChild('content', { static: false }) content: ElementRef;

  constructor(public dialog: MatDialog,
    private utils: UtilsService,
    private acteurServ: ActeurService,
    private wegeoUtil: wegeoUtils,
    private store: Store<AppState>,
    private excelField: ExcelFieldToDowloadComponent,
    private router : Router,
    private dataCache: DataCacheService,
    private userService: UserService
    ) { }

  ngOnInit() {
    this.profileLogged = JSON.parse(this.utils.decrypt(this.utils.getCookie("_wefly_")));
    this.getPlanteurs()

    this.img = [];
    
    this.formcouches$ = this.store.pipe(select(selectForm));
    this.formcouches$.subscribe(
      (form) => this.formCouche = form
    )
  }

  ngAfterViewInit(): void { }

  ngOnChanges() {
    
  }

  public openLoadingDialog(d = null, id = "loaderTrackingItem"): void {
    const dialogRef = this.dialog.open(LoadingComponent, {
      width: "500px",
      id: id,
      data: d,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  public closeLoadingDialog(id = "loaderTrackingItem"): void {
    try {
      this.dialog.getDialogById(id).close();
    } catch (error) {}
  }

  private readActeurFormated(couche: any, Icontains:string, page: number = 1, filter_field: any = null,
    periodic_filter: DateRange=null): any {
    this.loading = true
    let filter = '';

     filter = `couche: "${couche.id}", acteur_ActeurPerso_Nom_Icontains: "${Icontains}", deleted: false, acteur_Deleted: false,`;
    
    if (filter_field != null && filter_field.field != null) {
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`;
    }

    this.acteurServ.readActeurOnly(filter, page, '-created_at', periodic_filter).then(
      (res: any) => {
        this.loading = false
        const cacheKey = 'cache-planteur'
        const cacheTotalCount = 'totale-count-planteur'
        this.planteurs = res.results;
        this.dataCache.setData(cacheKey,this.planteurs)
        this.dataCache.setData(cacheTotalCount, res.totalCount)
        console.log('planteurs',this.planteurs)
        console.log('filter', this.planteurs)
        this.totalCount = res.totalCount
        this.activeSearch = false

      }, (err) => {
        this.activeSearch = false
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  getPlanteurs(){
    const cacheKey = 'cache-planteur'
    const cacheTotalCount = 'totale-count-planteur'
    const planteurPageCache = 'planteur-page-data'
    const currentPage = this.dataCache.getData(planteurPageCache)
    const cachedData = this.dataCache.getData(cacheKey)
    const totaldata = this.dataCache.getData(cacheTotalCount)
 
    if(cachedData){
     this.planteurs = cachedData
     this.totalCount = totaldata
     if(currentPage){
      this.currentPage = currentPage
     }else{
      this.currentPage = this.currentPage
     }
    
     this.loading = false
     console.log('cache planteur', this.planteurs)
    }else{
     this.readActeurFormated(this.couche, "");
    }
  }

  // generatePdf(acteur, action: string = 'download') {
  //   this.generatePdfService.generatePdf(acteur, action, 'PLANTEUR', this.formCouche);
  // }
  generatePdf(acteur:any) {
    console.log('acteur planteur', acteur)
   
    this.openLoadingDialog({
      msg: "Téléchargement en cours... Veuillez patienter s'il vous plaît.",
      loader: true,
    });


    this.excelField.listExcelField("Planteur").then((res: Map<string, string>) => {
      const field = [...res.keys()] 
      console.log('execel data',field.toString())
      const attribut = field.toString()
      this.userService.createZoneFile(acteur.id,this.profileLogged.profile.user.id,this.couche.id, "", false, false, false, false, true, attribut).then((res:any)=>{
        console.log('create pdf', res)
        this.closeLoadingDialog()
        if(res.createZoneFiles.zonefiles.filepath !== null && res.createZoneFiles.zonefiles.filepath !== ""){
          console.log('link',this.utils.API_URL+ res.createZoneFiles.zonefiles.filepath)
          window.open(this.utils.API_URL + res.createZoneFiles.zonefiles.filepath, '_blank')
        }
      },(err:any)=>{
        this.closeLoadingDialog()
        console.log('erreur pdf planteur', err)
      })
    })



    // this.fileLoading  = true
    // this.userService.createSingleFile(acteur.id, false, false, false, true, false).then((res:any)=>{
    //   this.fileLoading = false
    //   console.log('create pdf planteur', res)
    //   if(res.createActeurFiles.acteurfiles.filepath !== null){
    //     window.open(this.utils.API_URL + res.createActeurFiles.acteurfiles.filepath, '_self')
    //   }else{
    //     this.utils.snack('Erreur de téléchargement, Veuillez réessayer plus tard.')
    //   }
    // },(err:any)=>{
    //   this.fileLoading = false
    //   console.log('erreur pdf', err)
    // })
  }


  getLastPersoNomPrenoms(pers: []) {
    let element: any = {}
    if (pers.length > 0) {
      element = pers[pers.length - 1]
      return element.nom + " " + element.prenoms
    }
    return ""
  }

  getLastPersoContact(pers: []) {
    let element: any = {}
    if (pers.length > 0) {
      element = pers[pers.length - 1]
      return element.contact
    }
    return ""
  }



  openDialog(acteur:any): void {
    console.log('acteur planteur', acteur)
    const dialogRef = this.dialog.open(NewAlertConfirmComponent, {
      width: '35rem',
      data: { title:"Suppression d'un planteur" , message:`Vous êtes sur le point de supprimer le collecteur  ""${acteur.acteurPerso[0].nom}"". Voulez vous continuer`},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.acteurServ.deleted(acteur.id).then(
          (res : any) => {
            this.planteurs.filter((value, index) => {
              if (value.id == acteur.id) {
                this.planteurs.splice(index, 1)
              }
            }
            )
            this.utils.snack(`Le planteur ${acteur.acteurPerso[0].nom} a été supprimer`)
          },
          (error) => {
            console.error(error)
          }
        ).catch(reason =>  this.utils.setErrorsHttp(reason));
      
      } else {
        return
      }
    });
  }


  onChangePage(data) {
    const planteurPageCache = 'planteur-page-data'
    this.currentPage = data.id;
    this.dataCache.setData(planteurPageCache, this.currentPage)
    this.readActeurFormated(this.couche,"", data.id)
  }

  getMedia(url: string): string {
    return this.utils.API_URL + url
  }

  // filterData(data:any) {
  //   // console.log('data', data)
  //   this.filter = data.data_
  //   if (data.action === 0) {
  //     this.readActeurFormated(this.couche, 1, this.filter)
  //   } else {
  //     if (data.data_.value.length == 0) {
  //       this.readActeurFormated(this.couche, 1)
  //     } else {
  //       this.readActeurFormated(this.couche, 1, this.filter)
  //     }
  //   }
  // }
  getValueLive(val:string){
    console.log('val live', val)
    if(val ===""){
      this.readActeurFormated(this.couche, "")
      this.currentPage = 1
      this.activeSearch = false
    }
  }

  filterData(value:string) {
    if(value){
      this.activeSearch = true
      this.filter = value
      console.log('filter planteur val',value)
      this.readActeurFormated(this.couche, value)
    }
  }

  DownloadExcel(type) {
    console.log('type', type)
    const couche = this.couche.id;
    // this.dowloadExcel.emit({ couche, type });
    this.fileLoading = true
    this.excelField.listExcelField("Planteur").then((res: Map<string, string>) => {
      console.log('res res list field', res)
      this.dowloadExcel.emit({ couche, type, res }); 

    })
  }

  selectExcelField(){
    const dialog = this.dialog.open(
      ExcelFieldToDowloadComponent,
      {
        width: '70vw',
        data: {
          couche: 'Planteur'
        }
      }
    )

    dialog.afterClosed().subscribe(
      (field: Map<string, string>) => {
       if(field){
         this.fileLoading = true
        this.wegeoUtil.getExcelFile(field, this.couche, this.userConnected).then(
          (res) => {
            this.fileLoading = false
          }
        )
      }})
  }

  getDate(date_range: DateRange){
    this.readActeurFormated(this.couche,"",this.currentPage, null, date_range)
  }

  getDetails(planteur:any){
    this.router.navigate(['/wegeo/acteur/detail/',planteur.id])

  }

 

  croissant() {
    this.isSelected = !this.isSelected
    if(!this.isSelected){
      this.isSelectedDec = true
      this.utils.OrdrecroisantArrayPLanteur(this.planteurs, 'nom')
    }
  }


  decroissant() {
    this.isSelectedDec = !this.isSelectedDec
    if(!this.isSelectedDec){
      this.isSelected = true
      this.utils.ordreDecrossantArrayPlanteur(this.planteurs, 'nom')
      
    }
  }
}
