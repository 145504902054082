/*
 *
 * Récense tous les modèles de données(type)
 *
 */

export class PuceModel {
   name: string; // élément à afficher à l'utilisateur
   value?: any = null; // valeur qu'on voudrait envoyer
   icon?: any = null; // icone pouvant exister ou pas
   descr?: any = null; // description de la puce en gris
   right = true; // si vous voulez que l'icon soit à droite(true) sinon false ou n'envoyez rien
   circle = true; // si vous voulez que l'icon soit sous forme de cercle: true sinon false ou n'envoyez rien


   constructor(n= null, v= null, i= null, d= null, l= true, c= true) {
      this.name = n;
      this.value = v;
      this.icon = i;
      this.descr = d;
      this.right = (l == null) ? true : l;
      this.circle = (c == null) ? true : c;
   }
}


export class UserModel {
  id?: number;
  username: string;
  nom: string;
  prenom: string;
  password?: string;
  profile: ProfileModel;
  staff: boolean;
  active: boolean;
  isDeleted?: boolean;
}

export class RoleModel {
  id?: number;
  libelle: string;
  niveau: string;
  displayName?: string;
  user: number;
  action: string;
  // code: string;
  // isDeleted?: boolean;
}

export class ProfileModel {
  id?: number ;
  nom: string;
  prenoms: string;
  dateNaissance: string;
  genre: any;
  displayName?: string;
  fonction: FonctionModel;
  // email: string;
  // photo: string;
  // age: number;
  // genre: boolean;
  // fonction: FonctionModel;
  // isDeleted?: boolean;
}

export class PermissionModel {
  id?: number;
  libelle: string;
  code: string;
  isDeleted?: boolean;
}

export class FonctionModel {
  id?: number;
  libelle: string;
  isDeleted?: boolean;
}
