import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataCacheService {
  cultureData:boolean = false
  private cache: { [key: string]: any } = {};

  setData(key: string, data: any) {
    this.cache[key] = data;
  }

  getData(key: string): any {
    return this.cache[key];
  }

  clearData(key: string) {
    delete this.cache[key];
  }

 

  constructor() { }
}
