import { UniteDeMesureService } from '../../../_services/unite-mesure.service';
import { RegionService } from '../../../_services/region.service';
import { VarieteParCultureService } from '../../../_services/variete-par-culture.service';
import { CultureService } from '../../../_services/culture.service';
import { FamilleDeCultureService } from '../../../_services/famille-de-culture.service';
import { PaysService } from '../../../_services/pays.service';
import { VarieteCultureComponent } from '../../../variete-culture/variete-culture.component';
import { CultureComponent } from '../../../culture/culture.component';
import { FamilleCultureComponent } from '../../../famille-culture/famille-culture.component';
import { EntrepriseComponent } from '../../../entreprise/entreprise.component';
import { CaracteristiquesOffreService } from '../../_services/caracteristiques-offre.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UtilsService } from 'src/app/_services/utils.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OffreDeVenteService } from '../../_services/offre-de-vente.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DemandeDeProductionService } from '../../_services/demande-production.service';
import { CaracteristiquesDemandeProductionService } from '../../_services/caracteristiques-demande-production.service';
import { CompositionDemandeService } from '../../_services/composition-demande.service';

@Component({
  selector: 'app-demande-production',
  templateUrl: './demande-production.component.html',
  styleUrls: ['./demande-production.component.scss'],
  providers: [EntrepriseComponent, FamilleCultureComponent, CultureComponent, VarieteCultureComponent]
})

export class DemandeProductionComponent implements OnInit, OnDestroy {
  sendToParent: any = '___'; // cette variable est utilisé dans la mesure où le user clique à l'extérieur du pop-up pour le fermer, dans ce cas on envoie 3 underscores pour différencier.
  user: any;
  image: '';
  familleCultures = [];
  cultures = [];
  varieteCultures = [];
  pays = [];
  regions = [];
  loadingPays = true;
  fields = [];
  unites = [];
  jour = [];
  mois = [];
  annee = [];
  requesting = false;
  requestForm: FormGroup;
  uniteAffichee: any = null;
  errorMsg = null;

  constructor(
    private utils: UtilsService,
    private dialogRef: MatDialogRef<DemandeProductionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private compoService: CompositionDemandeService,
    private paysv: PaysService,
    private familleService: FamilleDeCultureService,
    private cultureService: CultureService,
    private varieteService: VarieteParCultureService,
    private regionService: RegionService,
    private uniteService: UniteDeMesureService,
    private formBuilder: FormBuilder,
    private demanderServ: DemandeDeProductionService,
    private carDemandeServ: CaracteristiquesDemandeProductionService,
  ) { }

  ngOnDestroy() {
    this.dialogRef.close(this.sendToParent);
  }

  ngOnInit() {
    console.clear();
    this.user = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
    this.getPays();
    this.getFamille();
    this.getCulture();
    this.getVariete();
    this.getRegion();
    this.getUnite();
    this.jour = this.utils.genTabNumber(31);
    this.mois = this.utils.genTabNumber(12);
    const nowYear = new Date().getFullYear();
    this.annee = this.utils.genTabNumber(nowYear + 10, nowYear);
    // if(this.data != null) {
    this.initForm();
    // }
  }

  initForm() {
    let annee: number = new Date().getFullYear(), mois: number = new Date().getMonth(), jours: number = new Date().getDate();

    if (this.data == null) {
      this.requestForm = this.formBuilder.group({
        // composition de demande
        varieteCulture: [null, Validators.required],
        culture: [null, Validators.required],
        qteDemandee: [null, Validators.required],

        //caractéristique de la demande de production
        prix: [null, Validators.required], //prix unitaire

        // demande de production
        jDebut: [jours, Validators.required],
        mDebut: [mois + 1, Validators.required],
        aDebut: [annee, Validators.required],
        jFin: [jours, Validators.required],
        mFin: [mois + 2, Validators.required],
        aFin: [annee, Validators.required],
        description: ['  '],
        libelle: [null, Validators.required],
        region: [null, Validators.required],
        uniteMesure: [null, Validators.required],
        ville: [null, Validators.required],
      });
    } else {
      let anneeF: number = new Date(this.data.dateFin).getFullYear(), moisF: number = new Date(this.data.dateFin).getMonth(), joursF: number = new Date(this.data.dateFin).getDate();
      let anneeD: number = new Date(this.data.dateDebut).getFullYear(), moisD: number = new Date(this.data.dateDebut).getMonth(), joursD: number = new Date(this.data.dateDebut).getDate();
      this.requestForm = this.formBuilder.group({
        // composition de demande
        varieteCulture: [this.data.compositionDemandeDeProduction[0].id, Validators.required],
        culture: [this.data.compositionDemandeDeProduction[0].culture.id, Validators.required],
        qteDemandee: [this.data.compositionDemandeDeProduction[0].qteDemandee, Validators.required],

        //caractéristique de la demande de production
        prix: [this.data.caracteristiquesDemandeDeProduction[0].prixAchat, Validators.required], //prix unitaire

        // demande de production
        jDebut: [joursD, Validators.required],
        mDebut: [moisD + 1, Validators.required],
        aDebut: [anneeD, Validators.required],
        jFin: [joursF, Validators.required],
        mFin: [moisF + 1, Validators.required],
        aFin: [anneeF, Validators.required],
        description: [this.data.description],
        libelle: [this.data.libelle, Validators.required],
        region: [null, Validators.required],
        uniteMesure: [this.data.uniteMesure.id, Validators.required],
        ville: [this.data.ville, Validators.required],
      });
    }
  }

  onSubmit() {
    this.errorMsg = null;
    let f = this.requestForm.value;

    // let validF = this.utils.formIsNotNull(this.requestForm, ['culture', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
    // chaineAModifier.split(recherche).join(remplacement);
    // console.clear()
    // this.formatDate(f.jDebut, f.mDebut, f.aDebut);

    if (this.requestForm.valid) {
      try {
        f.qteDemandee = f.qteDemandee.split(',').join('.')
        f.prix = f.prix.split(',').join('.')
      } catch (error) {
      }

      /*
      for (let index = 0; index < 50; index++) {
        this.setDemande(f);
        if (index == 49) {
          alert ('50 rajouté')
        }
      }
      */
      this.setDemande(f);
    } else {
      this.setError('Excepté la description tous les autres champs doivent être remplis.');
    }
  }

  setError(msg: string, timeout: number = 10000) {
    this.errorMsg = msg;
    setTimeout(() => {
      this.errorMsg = null;
    }, timeout);
  }

  onChangeUniteMesure(v) {
    for (let i = 0; i < this.unites.length; i++) {
      const elt = this.unites[i];
      if (elt.id == v) {
        this.uniteAffichee = elt.libelle;
        i = this.unites.length + 1;
      }
    }
  }

  setDemande(f: any) {
    if (this.data == null) {
      this.demanderServ.create(this.formatDate(f.jDebut, f.mDebut, f.aDebut), this.formatDate(f.jFin, f.mFin, f.aFin), false, f.description, f.libelle, f.region, f.uniteMesure, this.user.id, f.ville).then(
        (r_demande: any) => {
    
          this.setCaracteristique(f, r_demande.id);
        }, (e_demande) => {
          this.utils.snack('Désolé un problème est survenu, \nveuillez réessayer plus tard');
        
          this.utils.setErrorsHttp(e_demande);
        }
      )
    } else {
      this.demanderServ.update(this.data.id, this.formatDate(f.jDebut, f.mDebut, f.aDebut), this.formatDate(f.jFin, f.mFin, f.aFin), false, f.description, f.libelle, f.region, f.uniteMesure, this.user.id, f.ville).then(
        (r_demande: any) => {

          this.setCaracteristique(f, r_demande.id);
        }, (e_demande) => {
          this.utils.snack('Désolé un problème est survenu, \nveuillez réessayer plus tard');
         
          this.utils.setErrorsHttp(e_demande);
        }
      )
    }
  }

  setCaracteristique(f: any, demandeId: string) {
    if (this.data == null) {
      this.carDemandeServ.create(false, demandeId, f.prix).then(
        (r_carac) => {
       
          this.setComposition(f, demandeId)
        }, (e_carac) => {
         
          this.utils.snack('Désolé un problème est survenu, \nveuillez réessayer plus tard');
          this.utils.setErrorsHttp(e_carac);
        }
      )
    } else {
      this.carDemandeServ.update(this.data.caracteristiquesDemandeDeProduction[0].id, false, demandeId, f.prix, this.data.caracteristiquesDemandeDeProduction[0].qteGenDemandee).then(
        (r_carac) => {
          
          this.setComposition(f, demandeId)
        }, (e_carac) => {
       
          this.utils.snack('Désolé un problème est survenu, \nveuillez réessayer plus tard');
          this.utils.setErrorsHttp(e_carac);
        }
      )
    }

  }

  setComposition(f: any, demandeId: string) {
    // console.clear();
    
    if (this.data == null) {

      this.compoService.create(f.varieteCulture, false, demandeId, '', '', f.qteDemandee, this.user.id).then(
        (res_comp) => {
  
          this.requesting = false;
          try {
            document.getElementById('demande-form').innerHTML = '<h1>Demande envoyé avec succès.<h1>';
          } catch (error) {}
          // setTimeout(() => {
          this.sendToParent = res_comp;
          this.dialogRef.close(res_comp);
          // }, 5000);
        }, (e_comp) => {
          
          this.utils.snack('Désolé un problème est survenu, \nveuillez réessayer plus tard');
          this.utils.setErrorsHttp(e_comp);
        }
      );
    } else {
      this.compoService.update(this.data.compositionDemandeDeProduction[0].id ,f.varieteCulture, false, demandeId, '', '', f.qteDemandee, this.user.id).then(
        (res_comp) => {
       
          this.requesting = false;
          document.getElementById('demande-form').innerHTML = '<h1>Demande envoyé avec succès.<h1>';
          setTimeout(() => {
          this.sendToParent = res_comp;
          this.dialogRef.close(res_comp);
          }, 5000);
        }, (e_comp) => {
          
          this.utils.snack('Désolé un problème est survenu, \nveuillez réessayer plus tard');
          this.utils.setErrorsHttp(e_comp);
        }
      );
    }
  }

  getPays(offset: number = 0) {
    this.paysv.read('deleted : false', offset, 20, 'name').then(
      (res: any[]) => {
        const result: any = res;
        this.pays = this.pays.concat(result.results);
        if (this.pays.length < result.totalCount) {
          this.getPays(this.pays.length);
        } else {
          this.loadingPays = false;
        }
      },
      (err) => {
        this.utils.snack('Désolé un problème est survenu, \nveuillez réessayer plus tard');
        
      }
    ).catch(reason => this.utils.setErrorsHttp(reason));
  }

  getRegion() {
    this.regionService.read().then(
      (res: []) => {
    
        this.regions = res;
        this.requestForm.controls['region'].setValue(this.regions[0].id, { onlySelf: true })
      },
      (err) => {
        this.utils.snack('Désolé un problème est survenu, \nveuillez réessayer plus tard');
       
      }
    );
  }

  getFamille() {
    this.familleService.read().then(
      (res: []) => {
        this.familleCultures = res;
      }, (err) => {
        this.utils.snack('Désolé un problème est survenu, \nveuillez réessayer plus tard');
        this.utils.setErrorsHttp(err);
      }
    );
  }

  getCulture() {
    this.cultureService.read().then(
      (res: []) => {
        this.cultures = res;
        // this.initForm();
        if (this.cultures.length > 0) {
          this.requestForm.controls['culture'].setValue(this.cultures[0].id, { onlySelf: true })
        } else {
        
        }
        this.getVariete(this.cultures[0].id);
      },
      (err) => {
        this.utils.setErrorsHttp(err);
      }
    );
  }

  getVariete(culture: string = null) {
    let filtre = (culture == null) ? `deleted: false` : `deleted: false, culture:"${culture}"`;
   
    this.varieteService.read(filtre).then(
      (res: any) => {
        this.varieteCultures = res;
        
        if (this.varieteCultures.length > 0) {
          this.requestForm.controls['varieteCulture'].setValue(this.varieteCultures[0].id, { onlySelf: true });
        } else {
         
        }
      }, (err) => {
        this.utils.setErrorsHttp(err);
      
      }
    );
  }

  getUnite() {
    this.uniteService.read().then(
      (res: []) => {
        this.unites = res;
        this.requestForm.controls['uniteMesure'].setValue(this.unites[0].id, { onlySelf: true });
        this.uniteAffichee = this.unites[0].libelle;
      }, (err) => {
        this.utils.setErrorsHttp(err);
        
      }
    );
  }

  formatDate(day, month, year): Date {
    const date = new Date(year, month - 1, day).toISOString().slice(0, 10);
    // if (f.value.disponibilite) {
    return new Date(date);
    // } else {
    // return new Date();
    // }
  }
}
