import { Component, OnInit, HostListener, Output } from "@angular/core";
import anime from "animejs/lib/anime.es.js";
import "jquery";
import "bootstrap";
declare var $: any;
import ScrollMagic from "scrollmagic";
import { delay } from "q";
import "../../assets/js/scroll.js";
import * as inView from "in-view";
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.css", "./index2.component.css"]
})
export class IndexComponent implements OnInit {
  scrolling = false;
  controller = new ScrollMagic.Controller();
  scene1: any = null;
  viewForgetPassword: boolean = false;
  constructor() { }

  ngOnInit() {
    this.dronescrol();
    this.coopscrol();
    inView.offset(1);
    
  }

  Connection() {
    $(".landhome").fadeOut(400);
    $("#register").fadeOut(400);
    delay(400);
    $("#login").fadeIn(400);
  }

  register() {
    $(".landhome").fadeOut(400);
    $("#login").fadeOut(400);
    delay(400);
    $("#register").fadeIn(400);
  }

  closeLogin() {
    this.showHome();
  }

  showHome() {
    $(".landhome").fadeIn(400);
    $("#login").fadeOut(400);
    $("#register").fadeOut(400);
  }
  dronescrol() {
    inView("#drone-content")
      .on("enter", () => {
        setTimeout(() => {
          this.animedrone();
        }, 500);
      })
      .on("exit", el => {
        this.phoneOnAnime("#phone_drone1", "#phone_drone2", "#phone_drone3");
      });
  }
  animecoop() {
    // $('#coop').fadeIn(3000);
    this.phoneCoopAnime("#phone_coop1", "#phone_coop4", "#phone_coop3");
  }
  animedrone() {
    // delay(6000);
    anime({
      targets: "#drone",
      translateX: {
        value: 400,
        duration: 400
      },
      delay: anime.stagger(400)
    }).finished.then(
      this.phoneAnime("#phone_drone1", "#phone_drone2", "#phone_drone3")
    );
  }
  coopscrol() {
    inView("#coop-content")
      .on("enter", () => {
        setTimeout(() => {
          this.animecoop();
        }, 500);
      })
      .on("exit", el => {
        this.phoneCoopOnanime("#phone_coop1", "#phone_coop4", "#phone_coop3");
      });
  }

  phoneAnime(phone1, phone2, phone3) {
    anime({
      targets: phone1,
      translateX: {
        value: -150,
        duration: 1000
      }
    });
    anime({
      targets: phone3,
      translateX: {
        value: 150,
        duration: 1000
      }
    });
  }

  phoneOnAnime(phone1, phone2, phone3) {
    anime({
      targets: phone1,
      translateX: {
        value: 0,
        duration: 1
      }
    });
    anime({
      targets: phone3,
      translateX: {
        value: 0,
        duration: 1
      }
    });
  }

  phoneCoopAnime(phone1, phone2, phone3) {
    anime({
      targets: phone2,
      translateX: {
        value: 150,
        duration: 3000
      }
    });
    anime({
      targets: phone3,
      translateX: {
        value: 300,
        duration: 3000
      }
    });
  }
  phoneCoopOnanime(phone1, phone2, phone3) {
    anime({
      targets: phone2,
      translateX: {
        value: 0,
        duration: 1
      }
    });
    anime({
      targets: phone3,
      translateX: {
        value: 0,
        duration: 1
      }
    });
  }

  @HostListener("window:scroll", ["$event"])
  scrollHandler(event) {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      this.scrolling = true;
    } else {
      this.scrolling = false;
    }
  }
}
