import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class InformationsGenActeurPlantationService {

  constructor(private utils: UtilsService) { }
  create( acteur: String, anneeObtentionParcelle: Number, codePlantation: String, deleted: Boolean, identMobile: String, modeAcquisitionParcelle: String, modeGestionParcelle: String, preuveProprietaire: String, user: String, ) {
	const dateCreationMobile = new Date();
    const query = `
    mutation(
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$anneeObtentionParcelle: Int,
		$codePlantation: String,
		$deleted: Boolean,
		$identMobile: String,
		$modeAcquisitionParcelle: String,
		$modeGestionParcelle: String,
		$preuveProprietaire: String,
		$user: ID,

	) {
      createInformationsGenActeurPlantation(newInformationsgenacteurplantation: {
			dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			anneeObtentionParcelle: $anneeObtentionParcelle,
			codePlantation: $codePlantation,
			deleted: $deleted,
			identMobile: $identMobile,
			modeAcquisitionParcelle: $modeAcquisitionParcelle,
			modeGestionParcelle: $modeGestionParcelle,
			preuveProprietaire: $preuveProprietaire,
			user: $user,

		}) {
        informationsgenacteurplantation {
          id
					dateCreationMobile,
					acteur{ id },
					anneeObtentionParcelle,
					codePlantation,
					deleted,
					identMobile,
					modeAcquisitionParcelle,
					modeGestionParcelle,
					preuveProprietaire,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {dateCreationMobile,
					acteur,
					anneeObtentionParcelle,
					codePlantation,
					deleted,
					identMobile,
					modeAcquisitionParcelle,
					modeGestionParcelle,
					preuveProprietaire,
					user,
					}).then(
          (res) => {
            if (res['createInformationsGenActeurPlantation']['ok']) {
              resolve(res['createInformationsGenActeurPlantation']['informationsgenacteurplantation'])
            }
            reject(res['createInformationsGenActeurPlantation']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, dateCreationMobile: String, acteur: String, anneeObtentionParcelle: Number, codePlantation: String, deleted: Boolean, identMobile: String, modeAcquisitionParcelle: String, modeGestionParcelle: String, preuveProprietaire: String, user: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$anneeObtentionParcelle: Int,
		$codePlantation: String,
		$deleted: Boolean,
		$identMobile: String,
		$modeAcquisitionParcelle: String,
		$modeGestionParcelle: String,
		$preuveProprietaire: String,
		$user: ID,
		) {
      updateInformationsGenActeurPlantation(newInformationsgenacteurplantation: {
				id: $id, dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			anneeObtentionParcelle: $anneeObtentionParcelle,
			codePlantation: $codePlantation,
			deleted: $deleted,
			identMobile: $identMobile,
			modeAcquisitionParcelle: $modeAcquisitionParcelle,
			modeGestionParcelle: $modeGestionParcelle,
			preuveProprietaire: $preuveProprietaire,
			user: $user,
			}) {
        informationsgenacteurplantation {
          id
					dateCreationMobile,
					acteur{ id },
					anneeObtentionParcelle,
					codePlantation,
					deleted,
					identMobile,
					modeAcquisitionParcelle,
					modeGestionParcelle,
					preuveProprietaire,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, dateCreationMobile,
					acteur,
					anneeObtentionParcelle,
					codePlantation,
					deleted,
					identMobile,
					modeAcquisitionParcelle,
					modeGestionParcelle,
					preuveProprietaire,
					user,
					}).then(
          (res) => {
            if (res['updateInformationsGenActeurPlantation']['ok']) {
              resolve(res['updateInformationsGenActeurPlantation']['informationsgenacteurplantation'])
            }
            reject(res['updateInformationsGenActeurPlantation']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchInformationsGenActeurPlantation (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					dateCreationMobile,
					acteur{ id },
					anneeObtentionParcelle,
					codePlantation,
					deleted,
					identMobile,
					modeAcquisitionParcelle,
					modeGestionParcelle,
					preuveProprietaire,
					user{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

