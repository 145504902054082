import { Injectable } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CaracteristiquesDemandeProductionService {

  constructor(private utils: UtilsService) { }

  create(deleted: Boolean, demandeProduction: String, prixAchat: Number) {
    const query = `
    mutation(
		$deleted: Boolean,
		$demandeProduction: ID,
		$prixAchat: Float,
	) {
      createCaracteristiquesdemandeproduction(newCaracteristiquesdemandeproduction: {
			deleted: $deleted,
			demandeProduction: $demandeProduction,
			prixAchat: $prixAchat,
		}) {
        caracteristiquesdemandeproduction {
          id
					deleted,
					demandeProduction{ id },
					prixAchat,
					qteGenDemandee,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          deleted,
					demandeProduction,
					prixAchat,
					}).then(
          (res) => {
            if (res['createCaracteristiquesdemandeproduction']['ok']) {
              resolve(res['createCaracteristiquesdemandeproduction']['caracteristiquesdemandeproduction'])
            }
            reject(res['createCaracteristiquesdemandeproduction']['errors']);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, deleted: Boolean, demandeProduction: String, prixAchat: Number, qteGenDemandee: Number, ) {
    const query = `
    mutation(
			$id: UUID!,
		$deleted: Boolean,
		$demandeProduction: ID,
		$prixAchat: Float,
		$qteGenDemandee: Float,
		) {
      updateCaracteristiquesdemandeproduction(newCaracteristiquesdemandeproduction: {
				id: $id, deleted: $deleted,
			demandeProduction: $demandeProduction,
			prixAchat: $prixAchat,
			qteGenDemandee: $qteGenDemandee,
			}) {
        caracteristiquesdemandeproduction {
          id
					deleted,
					demandeProduction{ id },
					prixAchat,
					qteGenDemandee,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, deleted,
					demandeProduction,
					prixAchat,
					qteGenDemandee,
					}).then(
          (res) => {
           
            if (res['updateCaracteristiquesdemandeproduction']['ok']) {
              resolve(res['updateCaracteristiquesdemandeproduction']['caracteristiquesdemandeproduction'])
            }
            reject(res['updateCaracteristiquesdemandeproduction']['errors']);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchCaracteristiquesdemandeproduction (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					deleted,
					demandeProduction{ id },
					prixAchat,
					qteGenDemandee,
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }
}

