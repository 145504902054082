import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class FonctionaliteService {

  constructor(private utils: UtilsService) { }
  create(
    libelle: String,
    description: String,
  ) {
    const query = `
    mutation(
			$libelle :  String!,
			$description :  String!,
    ){
      createFonctionnalite(newFonctionnalites: {
				libelle : $libelle ,
				description : $description ,
      }){
          ok,
          errors{
            messages
          },
          fonctionnalites{
            id,
            description,
            libelle
          }
        }
      }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          libelle,
          description,
        }).then(
          (res) => {
            if (res['createFonctionnalite']['ok']) {
              resolve(res['createFonctionnalite']['fonctionnalites'])
            }
            reject(res['createFonctionnalite']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }


  update(
    id: String,
    libelle: String,
    description: String,
    deleted: Boolean = false
  ) {
    const query = `
    mutation(
      $id: UUID!,
			$libelle :  String!,
      $description :  String!,
      $deleted: Boolean!
    ){
      updateFonctionnalite(newFonctionnalites: {
        id: $id,
				libelle : $libelle ,
				description : $description,
        deleted: $deleted
      }){
        ok,
        errors{
          messages
        }
        fonctionnalites{
          id,
          libelle ,
          description
        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id,
          libelle,
          description,
          deleted
        }).then(
          (res) => {
            if (res['updateFonctionnalite']['ok']) {
              resolve(res['updateFonctionnalite']['fonctionnalites'])
            }
            reject(res['updateFonctionnalite']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = `deleted : false`, page: number = 1, order: string = '-created_at') {
    const query = `
    query($page: Int!, $order: String!) {
      searchFonctionnalite(${filter}){
        results(page: $page, ordering: $order)
        {
          id,
         	libelle ,
				  description
        }
        totalCount
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { page, order }).then(
          (res: any) => {
            resolve(res.searchFonctionnalite);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  createFonctionnaliteEntreprise(entreprise: String, fonctionnalite: String, deleted: Boolean = false) {
    const query = `
    mutation(
      $entreprise: ID!,
      $fonctionnalite: ID!,
      $deleted: Boolean,
    ) {
      createFonctionnaliteEntreprise(newFonctionnalitesentreprises: {
        entreprise: $entreprise,
        fonctionnalite: $fonctionnalite,
        deleted: $deleted,
      }) {
        fonctionnalitesentreprises {
          id
					entreprise{ id, raisonSociale},
					fonctionnalite{ id, libelle },
          deleted,
          createdAt
          updateAt
        }
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          entreprise,
          fonctionnalite,
          deleted,
        }).then(
          (res) => {
            if (res['createFonctionnaliteEntreprise']['ok']) {
              resolve(res['createFonctionnaliteEntreprise']['fonctionnalitesentreprises'])
            }
            reject(res['createFonctionnaliteEntreprise']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  updateFonctionnaliteEntreprise(id: String, entreprise: String, fonctionnalite: String, deleted: Boolean = false) {
    const query = `
    mutation(
			$id: UUID!,
		$entreprise: ID!,
		$fonctionnalite: ID!,
		$deleted: Boolean,
		) {
      updateFonctionnaliteEntreprise(newFonctionnalitesentreprises: {
        id: $id,
        entreprise: $entreprise,
			  fonctionnalite: $fonctionnalite,
			  deleted: $deleted,
			}) {
        fonctionnalitesentreprises {
          id
					entreprise{ id, raisonSociale}
					fonctionnalite{ id, libelle }
					deleted,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, entreprise, fonctionnalite, deleted }).then(
          (res) => {
            if (res['updateFonctionnaliteEntreprise']['ok']) {
              resolve(res['updateFonctionnaliteEntreprise']['fonctionnalitesentreprises'])
            }
            reject(res['updateFonctionnaliteEntreprise']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  readFonctionnaliteEntreprise(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchFonctionnaliteEntreprise (${filter}){
        results(page:` + page +`, ordering:"` + order + `") {
          id
					entreprise{ id, raisonSociale},
					fonctionnalite{ id, libelle },
					deleted,
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res: any) => {
            resolve(res.searchFonctionnaliteEntreprise);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

