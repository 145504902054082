import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-perform-stat',
  templateUrl: './perform-stat.component.html',
  styleUrls: ['./perform-stat.component.scss']
})
export class PerformStatComponent implements OnInit {
  @Input() titre?: string;
  @Input() icon: string;
  @Input() nb?: any;
  constructor() { }

  ngOnInit() {
  }

}
