import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-img-loader',
  templateUrl: './img-loader.component.html',
  styleUrls: ['./img-loader.component.css']
})
export class ImgLoaderComponent implements OnInit {
  @Input() selectedFile: any = null;
  file: File = null;
  img: string = null;
  constructor(

  ) { }
ngOnInit() {
	}
	
	onFileChanged(event) {
    /*
    // le html nécessaire
    <div class="row">
      <div class="col-12">
          <input type="file" (change)="onFileChanged($event)">
          <button (click)="onUpload()">VALIDER</button>
      </div>
    </div>
    */

		// this.selectedFile = <File>event.target.files[0];
		const reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			reader.readAsDataURL(file);
			reader.onload = () => {
				this.selectedFile = reader.result;
				console.clear();
			};
		} else {
      console.info('Un problème est survenu lors du chargement du fichier.');
    }
	}

	onUpload() {
		//const fd = new FormData();
		//fd.append('image', this.selectedFile, this.selectedFile.name);
	}

}
