import { UtilsService } from 'src/app/_services/utils.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-market-dash',
  templateUrl: './market-dash.component.html',
  styleUrls: ['./market-dash.component.scss']
})
export class MarketDashComponent implements OnInit {
  menu: any;
  userInfo: any;
  constructor(private U: UtilsService) { }
  ngOnInit() {
    this.userInfo = JSON.parse(this.U.decrypt(this.U.getCookie('_wefly_')));
    if (this.userInfo.isStaff && this.userInfo.isStaff === true) {
      this.menu = [
        { link: '/market/dashboard/offres-de-vente', name: 'Offres de vente' },
        { link: '/market/dashboard/demandes-de-production', name: 'Demandes de production' },
        { link: '/market/dashboard/commande-offre-de-vente', name: 'Reponses aux Offres' },
        { link: '/market/dashboard/famille-culture', name: 'Familles cultures' },
        { link: '/market/dashboard/culture', name: 'Cultures' },
        { link: '/market/dashboard/variete-culture', name: 'Variétés cultures' },
        { link: '/market/dashboard/unite-mesure', name: 'Unités de mesure' },
        { link: '/wegeo/admin/type_attribut', name: 'Transactions' },
      ];
    } else {
      this.menu = [
        { link: '/wegeo/admin/fonction', name: 'Fonctions' },
        { link: '/wegeo/admin/type_attribut', name: 'Type attributs' },
        { link: '/wegeo/admin/couche', name: 'Couches' },
        { link: '/wegeo/admin/type_couche', name: 'Types Couches' },
        { link: '/wegeo/admin/periode_traking', name: 'Périodes Tracking' },
      ];
    }
  }

}
