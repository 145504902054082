import { ArticleItemComponent } from './../article-item/article-item.component';
import { UtilsService } from 'src/app/_services/utils.service';
import { Component, OnInit, ComponentFactoryResolver, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var L: any;

@Component({
  selector: 'app-article-map',
  templateUrl: './article-map.component.html',
  styleUrls: ['./article-map.component.scss']
})
export class ArticleMapComponent implements OnInit {
  map: any;
  geoData: any;
  constructor(private utils: UtilsService, private resolver: ComponentFactoryResolver, private injector: Injector) { }
  ngOnInit() {
    // this.initMap();
    // this.AddOffertTomap();
  }
 /* initMap() {
   
   let position = this.utils.getMyPosition();
   this.map = L.map('map').setView(position, 7);
   L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
     attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
     maxZoom: 18,
     id: 'mapbox/streets-v11',
     tileSize: 512,
     zoomOffset: -1,
     accessToken: this.utils.MAPBOX_TOKEN
   }).addTo(this.map);
 } */

  makePopup() {
  }
/*
 AddOffertTomap() {
   const jsonLayer = L.geoJson(environment.MARKET_DATA, {
     style: { color: "#999", weight: 2, fillColor: "#00ad79", fillOpacity: .6 },
     onEachFeature(feature, layer) {
       layer.bindPopup(`
       <div class="item" style="width: 100%;">
  <div class="card">
    <img
      class="culture-img"
      src="../../../assets/img/maize.jpg"
      alt="Card image cap"
    />
    <div class="card-body">
      <div class="detail-offre">
        <div class="row">
           <h4 class="lib-title"> Maïs blanc</h4>
          <div class="col-12 pimpish">
                      <span class="descr-item">Lieu:</span> Rien
          </div>
          <div class="col-12 pimpish">
            <span class="descr-item">Quantité disponible:</span>
            {{ data.qteDispo }}
          </div>
          <div class="col-12 pimpish">
            <span class="descr-item">Disponible:</span> oui
          </div>
          <div class="col-12 pimpish">
            <span class="descr-item">Prix unitaire:</span>
            {{ data.prixUnitaire }} FCFA
          </div>
          <div class="col-12">
             <div class="row">
                <div class="col-md-12">
                    <button class="btn detail">
                        + Détails
                    </button>
                </div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
       `, {
         minWidth: 300
       });
     }
   });
   jsonLayer.addTo(this.map);
   this.map.fitBounds(jsonLayer.getBounds());
 } */
}
