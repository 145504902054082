import { Component, OnInit, Input } from '@angular/core';
import { InputType } from '../input/input.component';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
  @Input() inputJson: ButtonType;
  colored:any;
  texte:any; 
  texteColor:any; 
  link=null;
  ngstyle:any;
  //deflt="";

  constructor() { }

  ngOnInit() {
    this.setButton();
  }

  setButton(){
    this.colored = this.inputJson.color;
    this.texte = this.inputJson.text;
    this.texteColor = this.inputJson.textcolor;
    if(this.inputJson.link != null){
      this.link = this.inputJson.link;
    }
    this.ngstyle={"background-color": this.colored, "color": this.texteColor};
  }

}

export class ButtonType {
  color: string;
  link?: string = null;
  text: string;
  textcolor:string;
}
