import { UserModel, ProfileModel } from '../models';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProfileService } from '../_services/profile.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  nav = 1;
  next: any;

  constructor(private profileservice: ProfileService) { }
  profiles: any;
  prof: ProfileModel;
  
  ngOnInit() {
    this.prof = new ProfileModel();
    this.nav = 2;
  }

  onSubmitUser(f: NgForm) {
    this.next = f;
    this.nav = 2;
  }

  navigate(nav: number) {
    this.nav = nav;
  }

  onSubmitRole(f: NgForm) {
    
  }

  onSubmitPermission(f: NgForm) {
    
  }

  gotsearchValue(a) {
    
    return a;
  }

}