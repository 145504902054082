import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroInjector'
})
export class ZeroInjectorPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/null/g, 'En cours...')
  }

}
