import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";
import gql from "graphql-tag";
import { CreateProfil, Entreprise, ProfileCollecteur, UpdateProfile } from "../_models/user";
import { Apollo, QueryRef } from "apollo-angular";



const CREATE_PROFILE = gql`
mutation(
  $contact:  String!, 
  $dateNaissance:  CustomDate!, 
  $deleted:  Boolean!, 
  $genre:  String!, 
  $nom:  String!, 
  $prenom:  String!, 
  $user:  ID!) {
  createProfile(newProfile: {
    contact: $contact, 
    dateNaissance: $dateNaissance, 
    deleted: $deleted, 
    genre: $genre, 
    nom: $nom, 
    prenom: $prenom, 
    user: $user,
   
  }){
    profile {
      id
      admin
  categorie {
    id
  }
  codeDeVerification
  contact
  dateNaissance
  deleted
  displayName
  entreprise {
    id
    raisonSociale
  }
  fonction {
    id
    libelle
  }
  genre
  nom
  photoUrl
  prenom
  tracking
  zone{
    id
  }
  user {
    id
    password
    username
    dateJoined
    email
    firstName
    isActive
    isStaff
    isSuperuser
    lastLogin
    lastName
  }
  pere {
    id
    password
    username
    dateJoined
    email
    firstName
    isActive
    isStaff
    isSuperuser
    lastLogin
    lastName
  }


    }
  }
}
`;
const UPDATE_PROFILE = gql`
mutation($id: UUID!,$categorie:ID $codeDeVerification:  String!, $contact:  String!, $dateNaissance:  CustomDate!, $deleted:  Boolean!, $displayName:  String!, $entreprise:  ID!, $fonction:  ID!, $genre:  String!, $nom:  String!, $pere:  ID!, $photo:  String!, $prenom:  String!, $tracking:  Boolean!, $user:  ID!, $zone: ID!) {
  updateProfile(
    newProfile: {
      id: $id, categorie: $categorie, codeDeVerification: $codeDeVerification, contact: $contact, dateNaissance: $dateNaissance, deleted: $deleted, displayName: $displayName, entreprise: $entreprise, fonction: $fonction, genre: $genre, nom: $nom, pere: $pere, photo: $photo, prenom: $prenom, tracking: $tracking, user: $user,
    zone: $zone}
  )
    {
    profile{
      admin
      id
  categorie {
    id
  }
  codeDeVerification
  contact
  dateNaissance
  deleted
  displayName
  entreprise {
    id
    raisonSociale
    raisonSociale,
          telephone
           email
          categorie{
            libelle,
            numero
          }
  }
  fonction {
    id
    libelle
  }
  genre
  nom
  photoUrl
  prenom
  tracking
  zone{
    id
  }
  user {
    id
    password
    username
    dateJoined
    email
    firstName
    isActive
    isStaff
    isSuperuser
    lastLogin
    lastName
  }
  pere {
    id
    password
    username
    dateJoined
    email
    firstName
    isActive
    isStaff
    isSuperuser
    lastLogin
    lastName
  }
    }
  }
}

`;
const SEARCH_PROFILE = gql`
query($page: Int, $entreprise:ID) {
  searchProfile(entreprise:$entreprise,deleted: false){
    totalCount
    results(page: $page, ordering:"created_at") {
      id
  categorie {
    id
  }
  admin
  codeDeVerification
  contact
  dateNaissance
  deleted
  displayName
  entreprise {
    id
    raisonSociale
  }
  fonction {
    id,
    libelle
  }
  genre
  nom
  photoUrl
  prenom
  tracking
  zone{
    id
  }
  user {
    id
    password
    username
    dateJoined
    email
    firstName
    isActive
    isStaff
    isSuperuser
    lastLogin
    lastName
    tracks {
      id
      deleted,
      jourDeTrack,
      periode{
        id
      },
      tItems {
        date,
        deleted,
        latitude,
        longitude,
        createdAt
        updateAt
      }
      createdAt,
      updateAt
    }
  }
    }
  }
}

`;
@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(private utils: UtilsService, private apollo:Apollo) {}

  createProfil(profile:CreateProfil){
    const {contact, dateNaissance, deleted, genre, nom, prenom, user} = profile
    return this.apollo.mutate({
      mutation:CREATE_PROFILE,
      variables:{
        contact,
        dateNaissance,
        deleted,
        genre,
        nom,
        prenom,
        user,
      }

    })
  }
  createUserProfile(profil:CreateProfil){
    return new Promise((resolve, reject)=>{
      this.createProfil(profil).subscribe(
        (res:any)=>{
          resolve(res)
        },(err:any)=>{
          reject(err)
        }
      )
    })

  }




  create(

    codeDeVerification: String,
    contact: String,
    dateNaissance: String,
    deleted: Boolean,
    displayName: String,
    entreprise: String,
    fonction: String,
    genre: String,
    nom: String,
    pere: String,
    photo: String,
    prenom: String,
    tracking: Boolean,
    user: String,
    zone: String,
    

  ) {
    
    photo = photo == null ? "" : photo;
    const query = `
    mutation($codeDeVerification:  String!, $contact:  String!, $dateNaissance:  CustomDate!, $deleted:  Boolean!, $displayName:  String!, $entreprise:  ID!, $fonction:  ID!, $genre:  String!, $nom:  String!, $pere:  ID!, $photo:  String!, $prenom:  String!, $tracking:  Boolean!, $user:  ID!,
      $zone: ID!) {
      createProfile(newProfile: {codeDeVerification: $codeDeVerification, contact: $contact, dateNaissance: $dateNaissance, deleted: $deleted, displayName: $displayName, entreprise: $entreprise, fonction: $fonction, genre: $genre, nom: $nom, pere: $pere, photo: $photo, prenom: $prenom, tracking: $tracking, user: $user,
        zone: $zone}){
        profile {
          id
          admin
      categorie {
        id
      }
      codeDeVerification
      contact
      dateNaissance
      deleted
      displayName
      entreprise {
        id
        raisonSociale
      }
      fonction {
        id
        libelle
      }
      genre
      nom
      photoUrl
      prenom
      tracking
      zone{
        id
      }
      user {
        id
        password
        username
        dateJoined
        email
        firstName
        isActive
        isStaff
        isSuperuser
        lastLogin
        lastName
      }
      pere {
        id
        password
        username
        dateJoined
        email
        firstName
        isActive
        isStaff
        isSuperuser
        lastLogin
        lastName
      }


        }
      }
    }
    `;

    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          codeDeVerification,
          contact,
          dateNaissance,
          deleted,
          displayName,
          entreprise,
          fonction,
          genre,
          nom,
          pere,
          photo,
          prenom,
          tracking,
          user,
          zone,
          
        })
        .then(
          (res) => {
            resolve(res);
          },
          (err) => {
            this.utils.setErrorsHttp(err);
            reject(err);
          }
        );
    });
  }

  deleteEmploye(id:string){
    const query = `
    mutation($id: UUID!) {
      updateProfile(newProfile: {id: $id, deleted: true}
      )
        {
          profile {
            admin
            id
            categorie {
              id
            }
            codeDeVerification
            contact
            dateNaissance
            deleted
            displayName
            entreprise {
              id
              raisonSociale
              raisonSociale
              telephone
              email
              categorie {
                libelle
                numero
              }
            }
            fonction {
              id
              libelle
            }
            genre
            nom
            photoUrl
            prenom
            tracking
            zone {
              id
            }
            user {
              id
              password
              username
              dateJoined
              email
              firstName
              isActive
              isStaff
              isSuperuser
              lastLogin
              lastName
            }
            pere {
              id
              password
              username
              dateJoined
              email
              firstName
              isActive
              isStaff
              isSuperuser
              lastLogin
              lastName
            }
          }
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query, {id}).then((response) => {
        resolve(response)
      },(err)=>{
        reject(err)
      })
    })
  }

  updateProfile(updateProfile:UpdateProfile){
    const { id,
      categorie,
      codeDeVerification,
      contact,
      dateNaissance,
      deleted,
      displayName,
      entreprise,
      fonction,
      genre,
      nom,
      pere,
      photo,
      prenom,
      tracking,
      user,
      zone} = updateProfile

      return this.apollo.mutate({
        mutation:UPDATE_PROFILE,
        variables:{ 
          id,
          categorie,
          codeDeVerification,
          contact,
          dateNaissance,
          deleted,
          displayName,
          entreprise,
          fonction,
          genre,
          nom,
          pere,
          photo,
          prenom,
          tracking,
          user,
          zone},
          fetchPolicy:"no-cache"
      })
     
  }
  NewUpdateProfile(updateProfile:UpdateProfile){
    return new Promise((resolve,reject)=>{
      this.updateProfile(updateProfile).subscribe((res:any)=>{
        resolve(res)
      },(err:any)=>{
        reject(err)
      })
    })
  }

  update(

    id: String,
    categorie: String,
    codeDeVerification: String,
    contact: String,
    dateNaissance: String,
    deleted: Boolean,
    displayName: String,
    entreprise: String,
    fonction: String,
    genre: String,
    nom: String,
    pere: String,
    photo: String,
    prenom: String,
    tracking: Boolean,
    user: String,
    zone: String,
    
  ) {
    photo = photo == null ? "" : photo;
    const query = `
    mutation($id: UUID!,$categorie:ID $codeDeVerification:  String!, $contact:  String!, $dateNaissance:  CustomDate!, $deleted:  Boolean!, $displayName:  String!, $entreprise:  ID!, $fonction:  ID!, $genre:  String!, $nom:  String!, $pere:  ID!, $photo:  String!, $prenom:  String!, $tracking:  Boolean!, $user:  ID!, $zone: ID!) {
      updateProfile(
        newProfile: {
          id: $id, categorie: $categorie, codeDeVerification: $codeDeVerification, contact: $contact, dateNaissance: $dateNaissance, deleted: $deleted, displayName: $displayName, entreprise: $entreprise, fonction: $fonction, genre: $genre, nom: $nom, pere: $pere, photo: $photo, prenom: $prenom, tracking: $tracking, user: $user,
        zone: $zone}
      )
        {
        profile{
          admin
          id
      categorie {
        id
      }
      codeDeVerification
      contact
      dateNaissance
      deleted
      displayName
      entreprise {
        id
        raisonSociale
        raisonSociale,
              telephone
               email
              categorie{
                libelle,
                numero
              }
      }
      fonction {
        id
        libelle
      }
      genre
      nom
      photoUrl
      prenom
      tracking
      zone{
        id
      }
      user {
        id
        password
        username
        dateJoined
        email
        firstName
        isActive
        isStaff
        isSuperuser
        lastLogin
        lastName
      }
      pere {
        id
        password
        username
        dateJoined
        email
        firstName
        isActive
        isStaff
        isSuperuser
        lastLogin
        lastName
      }
        }
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          id,
          categorie,
          codeDeVerification,
          contact,
          dateNaissance,
          deleted,
          displayName,
          entreprise,
          fonction,
          genre,
          nom,
          pere,
          photo,
          prenom,
          tracking,
          user,
          zone,
       
        })
        .then(
          (res) => {
            resolve(res);
          },
          (err) => {
            this.utils.setErrorsHttp(err);
            reject(err);
          }
        );
    });
  }

  read(
    page: number = 1,
    filter:string
  ) {
    const query = `
    query($page: Int) {
      searchProfile(${filter != "" ? filter : "deleted: false"}){
        totalCount
        results(page: $page, ordering:"created_at") {
          id
      categorie {
        id
      }
      admin
      codeDeVerification
      contact
      dateNaissance
      deleted
      displayName
      entreprise {
        id
        raisonSociale
      }
      fonction {
        id,
        libelle
      }
      genre
      nom
      photoUrl
      prenom
      tracking
      zone{
        id
      }
      user {
        id
        password
        username
        dateJoined
        email
        firstName
        isActive
        isStaff
        isSuperuser
        lastLogin
        lastName
        tracks {
          id
          deleted,
          jourDeTrack,
          periode{
            id
          },
          tItems {
            date,
            deleted,
            latitude,
            longitude,
            createdAt
            updateAt
          }
          createdAt,
          updateAt
        }
      }
        }
      }
    }
    `;

    return new Promise((resolve, reject) => {
      this.utils.client.request(query, {page}).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.utils.setErrorsHttp(err);
          reject(err);
        }
      );
    });
  }
  readFormated(
    page: number = 1,
    filter:string
  ) {
    const query = `
    query($page: Int!) {
      searchProfile(${filter}){
        totalCount
        results(page: $page) {
          id
      categorie {
        id
      }
      admin
      contact
      dateNaissance
      deleted
      displayName
      entreprise {
        id
        raisonSociale
      }
      fonction {
        id,
        libelle
      }
      genre
      nom
      photoUrl
      prenom
      zone{
        id
      }
      user {
        id
        password
        username
        email
        firstName
        lastLogin
        lastName
        tracks {
          id
          deleted,
          jourDeTrack,
          periode{
            id
          },

        }
      }
        }
      }
    }
    `;

    return new Promise((resolve, reject) => {
      this.utils.client.request(query, {page}).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.utils.setErrorsHttp(err);
          reject(err);
        }
      );
    });
  }

  readOne(
    filter: string = `(deleted: false)`,
    page: number = 1,
    limit: number = this.utils.PAGINATION,
    order: string = "-created_at"
  ) {
    const query = `
    query($page: Int!, $order: String!) {
      searchProfile${filter}{
        results(page: $page, ordering: $order) {
          id
      categorie {
        id
      }
      admin
      codeDeVerification
      contact
      dateNaissance
      deleted
      displayName
      entreprise {
        id
        raisonSociale
      }
      fonction {
        id, libelle
      }
      genre
      nom
      photoUrl
      photo
      prenom
      tracking
      zone{
        id
      }
      user {
        id
        password
        username
        dateJoined
        email
        firstName
        isActive
        isStaff
        isSuperuser
        lastLogin
        lastName
        userTrack {
          id
          deleted,
          jourDeTrack,
          periode{
            id
          },
          tItems {
            date,
            deleted,
            latitude,
            longitude,
            createdAt
            updateAt
          }
          createdAt,
          updateAt
        }
      }
      district{
        libelle
        id
      }
      region{
        libelle
        id
      }
      departement{
        libelle
        id
      }
        }
      }
    }
    `;

    return new Promise((resolve, reject) => {
      this.utils.client.request(query, { limit, page, order }).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.utils.setErrorsHttp(err);
          reject(err);
        }
      );
    });
  }

  activeOrDesactiveTrackingOnUser(id: String, tracking: Boolean) {
    const query = `
    mutation($id: UUID!, $tracking:  Boolean!) {
      updateProfile(newProfile: { id: $id, tracking: $tracking}) {
        profile{
          id
      categorie {
        id
      }
      codeDeVerification
      contact
      dateNaissance
      deleted
      displayName
      entreprise {
        id
        raisonSociale
      }
      fonction {
        id
        libelle
      }
      genre
      nom
      photoUrl
      prenom
      tracking
      zone{
        id
      }
      user {
        id
        password
        username
        dateJoined
        email
        firstName
        isActive
        isStaff
        isSuperuser
        lastLogin
        lastName
      }
      pere {
        id
        password
        username
        dateJoined
        email
        firstName
        isActive
        isStaff
        isSuperuser
        lastLogin
        lastName
      }
        }
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          id,
          tracking,
        })
        .then(
          (res) => {
            resolve(res);
          },
          (err) => {
            this.utils.setErrorsHttp(err);
            reject(err);
          }
        );
    });
  }

  filterRequest(name:string){
    const query = `
    query($name: String!){
      searchProfileBy(deleted: false,name:$name) {
        id
        categorie {
          id
        }
        admin
        codeDeVerification
        contact
        dateNaissance
        deleted
        displayName
        entreprise {
          id
          raisonSociale
        }
        fonction {
          id
          libelle
        }
        genre
        nom
        photoUrl
        prenom
        tracking
        zone {
          id
        }
        user {
          id
          password
          username
          dateJoined
          email
          firstName
          isActive
          isStaff
          isSuperuser
          lastLogin
          lastName

          tracksSet {
            id
            deleted
            jourDeTrack
            periode {
              id

            }
            tItems {
              date
              deleted
              latitude
              longitude
              createdAt
              updateAt
            }
            createdAt
            updateAt
          }
        }
        district {
          libelle
          id
        }
        region {
          libelle
          id
        }
        departement {
          libelle
          id
        }
      }
    }

    `;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query,{name}).then(
        (res:any) => {
          resolve(res);
        }, (err) => {
          reject(err);
        }
      )

    })


  }

  createNew(
    categorie: String,
    codeDeVerification: String,
    contact: String,
    dateNaissance: String,
    deleted: Boolean,
    displayName: String,
    entreprise: String,
    fonction: String,
    genre: String,
    nom: String,
    pere: String,
    photo: String,
    prenom: String,
    tracking: Boolean,
    user: String,
    zone: String
  ) {
    photo = (photo == null)? '': photo;
    const query = `
    mutation($categorie:  ID!, $codeDeVerification:  String!, $contact:  String!, $dateNaissance:  CustomDate!, $deleted:  Boolean!, $displayName:  String!, $entreprise:  ID!, $fonction:  ID!, $genre:  String!, $nom:  String!, $pere:  ID!, $photo:  String!, $prenom:  String!, $tracking:  Boolean!, $user:  ID!,
      $zone: ID!) {
      createProfile(newProfile: { categorie: $categorie, codeDeVerification: $codeDeVerification, contact: $contact, dateNaissance: $dateNaissance, deleted: $deleted, displayName: $displayName, entreprise: $entreprise, fonction: $fonction, genre: $genre, nom: $nom, pere: $pere, photo: $photo, prenom: $prenom, tracking: $tracking, user: $user,
        zone: $zone}){
        profile {
          id
      categorie {
        id
      }
      codeDeVerification
      contact
      dateNaissance
      deleted
      displayName
      entreprise {
        id
        raisonSociale
      }
      fonction {
        id
        libelle
      }
      genre
      nom
      photoUrl
      prenom
      tracking
      zone{
        id
      }
      user {
        id
        password
        username
        dateJoined
        email
        firstName
        isActive
        isStaff
        isSuperuser
        lastLogin
        lastName
      }
      pere {
        id
        password
        username
        dateJoined
        email
        firstName
        isActive
        isStaff
        isSuperuser
        lastLogin
        lastName
      }
        }
      }
    }
    `;

    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          categorie,
          codeDeVerification,
          contact,
          dateNaissance,
          deleted,
          displayName,
          entreprise,
          fonction,
          genre,
          nom,
          pere,
          photo,
          prenom,
          tracking,
          user,
          zone
        })
        .then(
          res => {
            resolve(res);
          },
          err => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
    });
  }

  getProfile(profile:ProfileCollecteur){
     const {page, entreprise} = profile
     return this.apollo.watchQuery<any>({
      query:SEARCH_PROFILE,
      variables:{page, entreprise}

     }) as QueryRef<any>
  }

  searchProfiles(profile:ProfileCollecteur){
    return new Promise((resolve, reject)=>{
      this.getProfile(profile).valueChanges.subscribe((res:any)=>{
        resolve(res)
      },(err:any)=>{
        reject(err)
      })
    })
  }
}
