import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';
import { EntrepriseService } from 'src/app/_services/entreprise.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  profileLogged: any = null;
  filiales: any = [];

  constructor(
    public U: UtilsService,
    private entrepriseServ: EntrepriseService,

  ) { }

  ngOnInit() {
    this.profileLogged = JSON.parse(this.U.decrypt(this.U.getCookie('_wefly_')));
    this.searchEntrepriseChildren();
  }

  public searchEntrepriseChildren(): any {
    this.entrepriseServ.read(`entrepriseMere: "${this.profileLogged.profile.entreprise.id}"`).then(
      (res: any) => {
        this.filiales = res.searchEntreprises;
      }, (err: any) => {
      }
    ).catch(reason =>  this.U.setErrorsHttp(reason));
  }
}
