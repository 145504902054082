import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";
import { request } from "graphql-request";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { Entreprise } from "../_models/user";

const CREATE_ENT = gql`
mutation(
  $deleted:  Boolean!,
  $displayName:  String!,
  $email:  String!,
  $entrepriseMere:  ID!,
  $logo:  String!,
  $raisonSociale:  String!,
  $siteWeb:  String!,
  $telephone:  String!,
  $adresse:  ID!,
  $categorie: ID!
) {
  createEntreprise(newEntreprise: {
    deleted: $deleted,
    displayName: $displayName,
    email: $email,
    entrepriseMere: $entrepriseMere,
    logo: $logo,
    raisonSociale: $raisonSociale,
    siteWeb: $siteWeb,
    telephone: $telephone,
    adresse: $adresse,
    categorie: $categorie
  }) {
    entreprise {
    id,
    categorie{
      id
      libelle
    }
    deleted,
    adresse {
      id
      ville
      pays{
        id,
        name
      },
      rue
    },
    displayName,
    email,
    logo,
    raisonSociale,
    siteWeb,
    telephone,
    adresse {
      id
      ville
      pays{
        name
      }
    },
    }
  }
}

`;

@Injectable({
  providedIn: "root",
})
export class EntrepriseService {
  constructor(private utils: UtilsService, private apollo:Apollo) {


   
  }

  createEnt(entreprise:Entreprise){
       const {deleted, displayName, email, entrepriseMere, logo, raisonSociale, siteWeb, telephone, adresse, categorie} = entreprise
        return this.apollo.mutate({
          mutation:CREATE_ENT,
          variables:{deleted, displayName, email,entrepriseMere, logo,raisonSociale,siteWeb, telephone, adresse, categorie}
        })
  }
  createEntreprise(entreprise:Entreprise){
    return new Promise((resolve, reject)=>{
      this.createEnt(entreprise).subscribe((res)=>{
        resolve(res)
      },(err)=>{
        reject(err)
      })
    })

  }


  create(
    deleted: Boolean,
    displayName: String,
    email: String,
    entrepriseMere: String,
    logo: String,
    raisonSociale: String,
    siteWeb: String = "",
    telephone: String,
    adresse: String,
    categorie: string
  ) {
    const query = `
    mutation(
			$deleted:  Boolean!,
			$displayName:  String!,
			$email:  String!,
			$entrepriseMere:  ID!,
			$logo:  String!,
			$raisonSociale:  String!,
			$siteWeb:  String!,
			$telephone:  String!,
      $adresse:  ID!,
      $categorie: ID!
    ) {
      createEntreprise(newEntreprise: {
				deleted: $deleted,
				displayName: $displayName,
				email: $email,
				entrepriseMere: $entrepriseMere,
				logo: $logo,
				raisonSociale: $raisonSociale,
				siteWeb: $siteWeb,
				telephone: $telephone,
        adresse: $adresse,
        categorie: $categorie
      }) {
        entreprise {
        id,
        categorie{
          id
          libelle
        }
        deleted,
        adresse {
          id
          ville
          pays{
            id,
            name
          },
          rue
        },
				displayName,
				email,
				logo,
				raisonSociale,
				siteWeb,
				telephone,
				adresse {
          id
          ville
          pays{
            name
          }
        },
        }
      }
    }
    `;

    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          deleted,
          displayName,
          email,
          entrepriseMere,
          logo,
          raisonSociale,
          siteWeb,
          telephone,
          adresse,
          categorie,
        })
        .then(
          (res) => {
            resolve(res);
          },
          (err) => {
            this.utils.setErrorsHttp(err);
            reject(err);
          }
        );
    });
  }
  update(
    id: String,
    deleted: Boolean,
    displayName: String = "",
    email: String = "",
    entreprise: String = "",
    logo: String = "",
    raisonSociale: String = "",
    siteWeb: String = "",
    telephone: String = "",
    adresse: String = ""
  ) {
    const query = `
    mutation(
      $id: UUID!,
			$deleted:  Boolean!,
			$displayName:  String!,
			$email:  String!,
			$entreprise:  ID!,
			$logo:  String!,
			$raisonSociale:  String!,
			$siteWeb:  String!,
			$telephone:  String!,
			$adresse:  ID!,
    ) {
      updateEntreprise(newEntreprise: {
        id: $id,
				deleted: $deleted,
				displayName: $displayName,
				email: $email,
				entrepriseMere: $entreprise,
				logo: $logo,
				raisonSociale: $raisonSociale,
				siteWeb: $siteWeb,
				telephone: $telephone,
				adresse: $adresse,
      }) {
        entreprise{
          entrepriseMere {
            id,
				deleted,
				displayName,
				email,
				logo,
				raisonSociale,
				siteWeb,
				telephone,
				adresse {
          id
          ville
          pays{
            name
          }
        },
          }
        id,
				deleted,
				displayName,
				email,
				logo,
				raisonSociale,
				siteWeb,
				telephone,
				}      }
    }
    `;

    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          id,
          deleted,
          displayName,
          email,
          entreprise,
          logo,
          raisonSociale,
          siteWeb,
          telephone,
          adresse,
        })
        .then(
          (res) => {
            resolve(res);
          },
          (err) => {
            this.utils.setErrorsHttp(err);
            reject(err);
          }
        );
    });
  }
  read(
    filter: string = `deleted: false`,
    page: number = 1,
    order: string = "-created_at"
  ) {
    const query = `
    query($page: Int!, $order: String!) {
      searchEntreprise(${filter}){
        results(page: $page, ordering: $order) {
          children{
            id,
            email,
            logoUrl,
            raisonSociale,
            telephone,
            children{
              id,
              email, 
              logoUrl,
              raisonSociale,
              telephone,
              children{
                id,
                email,
                logoUrl,
                raisonSociale,
                telephone,
                children{
                  id,
                  email,
                  logoUrl,
                  raisonSociale,
                  telephone,
                }
              }
            }
          }
          id,
         	deleted,
				  displayName,
				  email,
				  logoUrl,
          raisonSociale,
          adresse {
          id
          ville
          pays{
            id,
            name
          },
          rue
        },
				siteWeb,
				telephone,
        }
        totalCount
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query, { page, order }).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.utils.setErrorsHttp(err);
          reject(err);
        }
      );
    });
  }
  readTitleEntreprise(
    filter: string = `deleted: false`,
    page: number = 1,
    order: string = "-created_at"
  ) {
    const query = `
    query ($page: Int!, $order: String!) {
      searchEntreprise(${filter}) {
        results(page: $page, ordering: $order) {
          id
          raisonSociale
        }
      }
    }

    `;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query, { page, order }).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.utils.setErrorsHttp(err);
          reject(err);
        }
      );
    });
  }
  readImg(
    filter: string = `deleted: false`,
    page: number = 1,
    order: string = "-created_at"
  ) {
    const query = `
    query{
      searchEntreprise(${filter}){
        results{
           id
           logo
          }
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query, { page, order }).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.utils.setErrorsHttp(err);
          reject(err);
        }
      );
    });
  }
}
