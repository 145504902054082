import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import 'bootstrap';
import 'jquery';
import { element } from 'protractor';
declare var $;


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @Input() menuList: any[];
  @Input() showLogo : boolean = true
  selectedIndex:number
  constructor(private router:Router) { }

  ngOnInit() {}

  activeMenu(index:number, menuChoisi:string){
     this.selectedIndex = index
     switch(menuChoisi){
      case "dashBoard":
        this.router.navigate(['/wegeo/dashboard']);
        break;
      case "collecteur":
        this.router.navigate(["/wegeo/employes"]);
        break;
      case "planteur":
        this.router.navigate(["/wegeo/plantation"]);
        break;
      case "formulaire":
        this.router.navigate(["/wegeo/formulaire"]);
        break;
      case "zone":
        this.router.navigate(["/wegeo/zone"]);
        break;
      case "performance":
        this.router.navigate(["/wegeo/performance"]);
        break;
      case "admin":
        this.router.navigate(["/wegeo/admin/fonction"]);
        break;
     }

  }





}
