import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import 'bootstrap';
import 'jquery';
declare var $;
import 'popper.js';
import { GestionEmployeComponent } from '../gestion-employe/gestion-employe.component';
import { RoleService } from 'src/app/_services/role.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/_services/utils.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RoleUserComponent } from 'src/app/_modale/role-user/role-user.component';
import { PeriodeTrackingUserComponent } from 'src/app/_modale/periode-tracking-user/periode-tracking-user.component';
import { ProfileService } from 'src/app/_services/profile.service';
import { EditProfilComponent } from '../_modale/edit-profil/edit-profil.component';
import { ResetPasswordEmployeComponent } from '../_modale/reset-password-employe/reset-password-employe.component';
import { EmployeFormComponent } from '../_modale/employe-form/employe-form.component';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { DeletedComponent } from 'src/app/_modale/deleted/deleted.component';
import { UserService } from 'src/app/_services/user.service';
import { LoadingComponent } from '../_modale/loading/loading.component';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NewEmployeDetailComponent } from '../new-employe-detail/new-employe-detail.component';

@Component({
  selector: 'app-employe',
  templateUrl: './employe.component.html',
  styleUrls: ['./employe.component.scss']
})
export class EmployeComponent implements OnInit {
  constructor(private gesEmplComp: GestionEmployeComponent,
    private profileServ: ProfileService,
    private roleService: RoleService,
    private formBuilder: FormBuilder,
    public utils: UtilsService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router:Router,
    ) { }

  @Input() link: string;
  @Input() profile: any
  @Input() photoEmploye?:string
  @Output() deleteEmploye:EventEmitter<any> = new EventEmitter<any>();
  @Output() updateEmploye:EventEmitter<any> = new EventEmitter<any>();
  roleUser: any[] = [];
  roleToSelectForThisUser: any[] = [];
  userConnecte: any
  addRoleForm: FormGroup;
  departement:string
  region:string
  district:string

  userInfo: any;
  active: string;
  
  ngOnInit() {
    this.userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
    $('.dropdown-toggle').dropdown();
    this.initAddRoleForm();
  }

  getUrl(photo : string){
    let value = photo
    if(photo.indexOf("data:") == -1){
      value = this.utils.API_URL + '/' + photo
    }
    return value
  }

  initAddRoleForm() {
    this.addRoleForm = this.formBuilder.group({
      'role_': ['', Validators.required],
      'profile': ['', Validators.required]
    })
  }

  get f() {
    return this.addRoleForm.controls
  }

  onSubmit() {
    if (this.addRoleForm.invalid) {
      return
    }
    let profile = this.addRoleForm.value['profile']; let role = this.addRoleForm.value['role_']
    this.roleService.createRoleProfileSuite(profile, role).then(
      (res) => {
      },
      (error) => {
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason));
  }

  

  public setTypeFormSendTo(): void {
    this.profileServ.readOne(`(id: "${this.profile.id}", entreprise: "${this.userInfo.profile.entreprise.id}", deleted: false)`).then(
      (res: any) => {
        this.profile = res.searchProfile.results[0];
        this.gesEmplComp.setTypeForm(this.profile, true);
      },
      (err) => {
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason));
  }

  role(profile) {
    this.roleUser = [];
    this.roleToSelectForThisUser = [];
    this.addRoleForm.controls['profile'].setValue(profile);
    this.roleService.readProfileRoleSuite(profile).then(
      (res: any) => {
        // console.log('res', res);

        this.roleUser = res['searchProfileRoleSuite']['results']
        for (let i = 0; i < res['searchRoleSuite']['results'].length; i++) {
          if (res['searchProfileRoleSuite']['results'].length != 0) {
            for (let j = 0; j < res['searchProfileRoleSuite']['results'].length; j++) {
              if (res['searchRoleSuite']['results'][i]['id'] == res['searchProfileRoleSuite']['results'][j]['role']['id']) {
              } else {
                this.roleToSelectForThisUser.push(res['searchRoleSuite']['results'][i])
              }
            }
          } else {
            this.roleToSelectForThisUser = res['searchRoleSuite']['results']
            this.roleUser = []
          }
        }
        $("#role").modal("show")
      },
      (error) => {
        console.error(error)
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason));
  }


  openDialog(profile): void {
    const dialogRef = this.dialog.open(RoleUserComponent, {
      width: '1500px',
      data: { profile: profile }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public openPeriodeTrackingModale(profile): void {
    // console.log("profilezaza",profile);

    const dialogRef = this.dialog.open(PeriodeTrackingUserComponent, {
      width: '40rem',
      data: { profile: profile }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public openResetMdpModale(profile): void {
    const dialogRef = this.dialog.open(ResetPasswordEmployeComponent, {
      width: '750px',
      data: { profile: profile }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log('passWord restok', result)
      }
    });
  }

  openFormEmployeDialog(profile :any): void {
    this.updateEmploye.emit(profile)
    console.log('le profile', profile)

    const dialogRef = this.dialog.open(EmployeFormComponent, { 
      width: '60rem',
      data: { profile : profile },
      disableClose : true,
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result){
        console.log('resulte profile update', result)
        this.profile = result

        // this.profiles.results.forEach((res:any)=>{
        //   if(res.id === result.id){
        //     console.log('le profile modier', res)
        //     res == result
        //   }
        // })
        this.snackBar.open("Profil modifié avec succès", "OK", {duration : 3000})
      }else{
        
      }
    });
  
  }


  openDeletedDialog(profile:any): void {
    this.deleteEmploye.emit(profile)
    console.log('le profile', profile)
      }



  getDetails(profile:any){

    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.direction = 'ltr';
    dialogConfig.width = '27rem';
    dialogConfig.height ="100vh"
    dialogConfig.position = { top: '0', right: '0' };
    dialogConfig.panelClass = "slide-from-right";

    dialogConfig.data = profile
    this.dialog.open(NewEmployeDetailComponent, dialogConfig
  
   )
   // this.router.navigate(['/wegeo/employes/details/', profile.user.id])
  }
  

}

