import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { UtilsService } from '../_services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AdminDjangoService {
  userInfo: any;

  constructor(private U: UtilsService, private router: Router) {
  }

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.userInfo = JSON.parse(this.U.decrypt(this.U.getCookie('_wefly_')));
    if (this.userInfo.isStaff && this.userInfo.isStaff == true) {
      return true;
    }
    this.router.navigate(['/denied'], { queryParams: { returnUrl: state.url }});
    // alert('Vous n\'avez pas le niveau d\' attribution nécessaire pour effectuer cette action!');
    return false;
  }
}
