import { Component, OnInit, ViewChild } from '@angular/core';
import 'bootstrap';
import 'jquery';
import { UtilsService } from 'src/app/_services/utils.service';
import { UserService } from 'src/app/_services/user.service';
import { MatMenuTrigger, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs/internal/Subscription';
import { CREATE_COMMANDE, CREATE_COMPOSER } from 'src/environments/environment';
import { PanierService } from 'src/app/@marketplace/_services/panier.service';
import { BaseService } from 'src/app/@marketplace/_services/base.service';
declare var $;
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-nav-wegeo',
  templateUrl: './nav-wegeo.component.html',
  styleUrls: ['./nav-wegeo.component.scss']
})
export class NavWegeoComponent implements OnInit {

  @ViewChild(MatMenuTrigger, { static : true}) trigger: MatMenuTrigger;
  toggle = false;
  paniers : any[] = [];
  panierSubscription: Subscription;
  constructor(private usersv: UserService, private utils: UtilsService, private panierService : PanierService, private baseService : BaseService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.panierSubscription = this.panierService.paniersSubject.subscribe(
      (paniers : []) => {
        this.paniers = paniers
      }
    )
  }

  removePanier(id){
    this.panierService.removePanier(id)
  }

  createCommande(paniers : any[]){
    let username = (jwt_decode( this.utils.getStorage('weflyHeader_token')).username)
    this.usersv.read(`username: "${username}"`, 1, 'id').then(
      (res: any) => {
        let user = res.searchUser.results[0].id
        if(user !== undefined){
          this.baseService.create(CREATE_COMMANDE, {user : user}).then(
            (res) => {
              if(res['createCommande']['ok']){
                let commande = res['createCommande']['commande']['id']
                this.paniers.forEach((value, index) => {
                  this.baseService.create(CREATE_COMPOSER, {
                    commande : commande, stock : value.stock.id, qteCom : value.quantite
                  }).then(
                    (res : any) => {
                    },
                    (error) => {
                    }
                  )
                })
                this.panierService.removeAll()
                this.snackBar.open('Commande passée', 'Ok', { duration: 3000 });
              }
            },
            (error) => {
            }
          )
        }
      },
      (err) => {
      }
    );
  }

  toggleSidebar() {
    const a = parent.document.getElementById('sidebar-wrapper');
    const b = parent.document.getElementById('navbartop');
    this.toggle = !this.toggle;
    if (this.toggle) {
      a.setAttribute('style', 'width: 0');
      b.setAttribute('style', 'width:100%');
    } else {
      a.setAttribute('style', 'width : 17rem');
      b.setAttribute('style', 'width : 80%');
    }
  }
  gotsearchValue(a) {
    return a;
  }

  public searchUserLoggedProfile(): void {
    let username = (jwt_decode( this.utils.getStorage('weflyHeader_token')).username)
    this.usersv.read(`username: "${username}"`, 1, 'id').then(
      (res: any) => {
        // this.userEntreprise = res.searchUser.results[0];
      },
      (err) => {
      }
    );
  }
}
