import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.css']
})
export class DynamicInputComponent implements OnInit {
  @Input() inputType: string;
  @Input() inputName: string;
  @Input() inputLabel: string;
  @Input() inputRequired: boolean;
  @Input() options: any;
  @Input() datas:any;
  // show = false;
  // isEdit ;
  constructor() { }
  ngOnInit() {
    // console.log('datas',this.datas);

  }

  show = false;
  isEdit ;
  inputNewLabel;
  onEdit(datas:any) {
    // console.log('datas',datas);

    this.show = !this.show;
    this.show === true
      ? (this.isEdit = 'text')
      : (this.isEdit = "hidden");

  }

}
