import { LoadingComponent } from '../../@wegeo/_modale/loading/loading.component';
import { ErrorMsgComponent } from '../../_modale/error-msg/error-msg.component';
import { UtilsService } from 'src/app/_services/utils.service';
import { OffreDeVenteService } from '../_services/offre-de-vente.service';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmComponent } from '../../confirm/confirm.component';

@Component({
  selector: 'app-offre-vente-list',
  templateUrl: './offre-vente-list.component.html',
  styleUrls: ['./offre-vente-list.component.scss']
})
export class OffreVenteListComponent implements OnInit {
  fields_selected_: Array<any> = [
    { libelle_db: "libelle", libelle: "Libelle" },
    { libelle_db: "ville", libelle: "Ville" },
  ];
  filter: string = null;


  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;

  first = true
  offres = [];
  dataSource = new MatTableDataSource<any>();
  columnsToDisplay = ['vendeur', 'culture', 'cout', 'quantite', 'disponibilite', 'localisation', 'action'];

  constructor(
    private offreService: OffreDeVenteService,
    private dialog: MatDialog,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.readOffre();
  }

  updateDataSource() {
    this.dataSource.connect().next(this.offres);
  }

  readOffre(page: number = 1, filter: string=this.filter) {
    filter = (filter == null)?`deleted : false`: `deleted : false, ${filter}`;
    this.offreService.read(filter, page).then(
      (res: any) => {
     
        this.first = false;
        this.offres = res.results;
        this.totalCount = res.totalCount;
        this.updateDataSource();
      }, (err) => {
        
      }
    );
  }

  delete(i: number) {
    const instanceObj = this.offres[i];
    const dialogRef = this.dialog.open(
      ConfirmComponent,
      {
        width: '25rem',
        data: { message: `Souhaitez vous supprimer ${instanceObj.libelle}` }
      }
    );

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          const delDialog = this.dialog.open(
            LoadingComponent,
            {
              width: '25rem',
              data: {
                msg: 'Veuillez patienter svp',
                loader: true
              }
            }
          );
          this.offreService.update(instanceObj.id,
            true, instanceObj.description, instanceObj.disponibilite,
            instanceObj.libelle, instanceObj.region ? instanceObj.region.id : instanceObj.region, instanceObj.uniteMesure.id,
            instanceObj.user.id, instanceObj.ville
          ).then(
            (results) => {
              delDialog.close();
              this.offres.splice(i, 1);
              this.updateDataSource();
            },
            (err) => {
              delDialog.close();
             
              this.utils.setErrorsHttp(err);
              this.dialog.open(ErrorMsgComponent, {
                width: '20rem',
                data: { msg: 'Une erreur est survenue veuillez réessayer plus tard.'}
              });
            }
          );
        }
      }
    );
  }

  addOffre() {

  }

  onChangePage(data) {
    this.currentPage = data.id;
    // this.page = this.currentPage;
    
    this.readOffre(this.currentPage);
  }

  filterData(data) {
    this.filter = `${data.data_.field}: "${data.data_.value}"`;
    // console.clear();

    if (data.action != 0) {
      if (data.data_.value.length == 0) {
        this.readOffre();
      } else {
        this.readOffre(1, this.filter);
      }
    }
  }
}
