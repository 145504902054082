import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntervalDateFilterComponent } from './interval-date-filter/interval-date-filter.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { MatDatepickerModule, MatFormField, MatFormFieldModule } from '@angular/material';


@NgModule({
  declarations: [IntervalDateFilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule
  ],
  exports: [
    IntervalDateFilterComponent
  ]
})
export class SharedModule { }
