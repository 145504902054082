import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {
  private userInfo: any = {}
  constructor(private utils: UtilsService) {
    this.userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
  }

  create(subject: string, commentaire: string, user: number = this.userInfo.id) {
    const query = `
    mutation ($user: ID!, $commentaire: String, $subject: String) {
      createSuggestions(newSuggestions: {
        commentaire: $commentaire,
        user: $user,
        subject: $subject
      }) {
        ok
        suggestions {
          id, user {id,}, commentaire, subject
        }
        errors {
          field, messages
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          subject, commentaire, user
        }).then(
          (res) => {
            if (res['createSuggestions']['ok']) {
              resolve(res['createSuggestions']['suggestions'])
            }
            reject(res['createSuggestions']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}
