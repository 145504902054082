import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { DateRange } from '../shared/interval-date-filter/interval-date-filter.component';

@Injectable({
  providedIn: 'root'
})
export class ActeurService {
  private nb_row_returned: number = 20;
  public openedPlantationOnMap:any

  constructor(private utils: UtilsService, private dbService: NgxIndexedDBService) { }
  create(
    coordonnees: String,
    couche: String,
    deleted: Boolean,
    pourcentageRemplissage: Number,
    principal: Boolean,
    superieur: String,
    tempsEnregis: Number,
    user: number
  ) {
    const dateCreationMobile = new Date().toISOString().toString();
    const query = `
    mutation(
			$coordonnees: String,
			$couche: ID!,
			$deleted: Boolean!,
			$pourcentageRemplissage: Int!,
			$principal: Boolean!,
			$superieur: ID!,
			$tempsEnregis: Int!,
      $user: ID!,
      $dateCreationMobile: CustomDateTime!
			) {
      createActeur(newActeur: {
        dateCreationMobile: $dateCreationMobile,
        coordonnees: $coordonnees,
        couche: $couche,
        deleted: $deleted,
        pourcentageRemplissage: $pourcentageRemplissage,
        principal: $principal, superieur: $superieur, tempsEnregis: $tempsEnregis, user: $user, }) {
        acteur {
          id
					dateCreationMobile
					coordonnees
					couche{
            id
            libelle
          }
					deleted
					identMobile
					pourcentageRemplissage
					principal
					superieur{
            id
          }
					tempsEnregis
					user{
            id
          }
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          coordonnees, couche, deleted, pourcentageRemplissage, principal, superieur, tempsEnregis, user,
          dateCreationMobile
        }).then(
          (res) => {
            if (res['createActeur']['ok']) {
              resolve(res['createActeur']['acteur'])
            }
            reject(res['createActeur']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, dateCreationMobile: String, coordonnees: String, couche: String, deleted: Boolean, identMobile: String, pourcentageRemplissage: Number, principal: Boolean, superieur: String, tempsEnregis: Number, user: number, ) {
    const query = `
    mutation(
			$id: UUID!,
			$dateCreationMobile: CustomDateTime!,
			$coordonnees: String,
			$couche: ID,
			$deleted: Boolean,
			$identMobile: String,
			$pourcentageRemplissage: Int,
			$principal: Boolean,
			$superieur: ID,
			$tempsEnregis: Int,
			$user: ID,
			) {
      updateActeur(newActeur: {
				id: $id, dateCreationMobile: $dateCreationMobile, coordonnees: $coordonnees, couche: $couche, deleted: $deleted, identMobile: $identMobile, pourcentageRemplissage: $pourcentageRemplissage, principal: $principal, superieur: $superieur, tempsEnregis: $tempsEnregis, user: $user, }) {
        acteur {
          id
					dateCreationMobile
					coordonnees
					couche
					deleted
					identMobile
					pourcentageRemplissage
					principal
					superieur
					tempsEnregis
					user
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id, dateCreationMobile, coordonnees, couche, deleted, identMobile, pourcentageRemplissage, principal, superieur, tempsEnregis, user
        }).then(
          (res) => {
            if (res['updateActeur']['ok']) {
              resolve(res['updateActeur']['acteur'])
            }
            reject(res['updateActeur']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }


  deleted(id: String) {
    const query = `
    mutation($id: UUID!){
      updateActeur(newActeur: {
          id: $id,
          deleted : true
      }){
        acteur {
          id
					dateCreationMobile
					coordonnees
					deleted
					identMobile
					pourcentageRemplissage
					principal
					superieur {
					  id
					}
          couche {
            id
          }
					tempsEnregis
					user {
					  id
					}
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
        this.utils.client.request(query, {id}).then(
          (res) => {
            console.log('delete accteur',res)
            if (res['updateActeur']['ok']) {
              resolve(res['updateActeur']['acteur'])
            }
            reject(res['updateActeur']['errors']);
          }, (err) => {
            console.log('erreur suppression acteur',err)
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  readLocale(filter: string = 'deleted: false', page: number = 1, order: String = 'created_at', total_online: number = 0, online_db: boolean = false) {
    let userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
    let entreprise_id = JSON.parse(this.utils.decrypt(sessionStorage.entreprise)).id;

    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    let plantation_legale: number = 0;
    let plantation_bd: string = null;
    let db_data: any = { data: '', user: '', profil: '', entreprise: '' };

    if ((filter.indexOf('couche_TypeCouche_Etiquette: "PLANTATION"') > 0) && (filter.indexOf('deleted: false, acteurDelimitation_Pforest: false') > 0)) {
      // on souhaite obtenir des plantations légales
      plantation_legale = 1;
      plantation_bd = 'plantation_legale';
    } else if ((filter.indexOf('couche_TypeCouche_Etiquette: "PLANTATION"') > 0) && (filter.indexOf('deleted: false, acteurDelimitation_Pforest: true') > 0)) {
      // on souhaite obtenir des plantations illégales
      plantation_bd = 'plantation_illegale';
      plantation_legale = 2;
    } else if (filter.indexOf('couche_TypeCouche_Etiquette: "PLANTEUR"') > 0) {
      plantation_bd = 'planteur';
      plantation_legale = 3
    } else {
      plantation_bd = 'plantation';
      plantation_legale = 4;
    }
    // this.dbService.clear(plantation_bd).then(() => { }, error => {  });        /// a supprimer
    const query = `
    query{
      searchActeur(${filter}){
        totalCount
        results(page:` + page + `, ordering:"` + order + `"){
          idSuperieur,id,
          couche{entreprise{raisonSociale,categorie{libelle}}},
          acteurGen{codePlantation,id},
          acteurFamilliale{concubinage,nbreDepouses,nbreFillesScol,nbreGarconsScol,nbrePersACharge,nbreTotalEnf,nbreTotalEnf,sitMat},
          acteurFormation{ameliorationTravail,certification,dateCertif,nivDEtude,nonScolarise,participationFormation,preuveCertifImage,raisonInutile},
          acteurInfoSociopro{activitePrincipale,activiteSecondaire,anneesDExperienceActSec,anneesDExperienceActPrinc},
          acteurSrceRev{benefLastRecolte,montantEpargne,revenusSuffisant,srceRevenuActPrinc},
          acteurMaindoeuvre{fourchettePaiement,momentUtilMainDoeuvre,moyenPaiement,natureMainDoeuvre,origineMaindoeuvre,tempsTravailParJour,typeMainDoeuvre,typePaiement},
          acteurEntretien{achetePar,coutAngrais,freqTraitementPhyto,lieuAchatAngrais,nomOrgAchat,periodeTraitementPhyto,traitementPhyto,typeAngrais,utilisationAngrais},
          acteurProductionplantation{dateProduction,qteProduite, typeAcheteur,
nomAcheteur},
          acteurNotation {id,nettoyage,densite,access,santePlantes,},
          acteurOrga{appartenanceCoop,createdAt,dateCreationMobile,dateEntreeCoop,deleted,desirAppartenanceCoop,id,identMobile,niveauSatisfaction,prkwPasSatisfaisant,receptionEquipementAnneeEnCours,receptionIntrantAnneeEnCours,updateAt},
          acteurPerso {
            id,
            dateCreationMobile,
            acteur {
              id},
            user {id, password, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName,
              profile {id, categorie {id},codeDeVerification,contact,dateNaissance,deleted,displayName,entreprise {id,raisonSociale},fonction {id},genre,nom,prenom,tracking}},createdAt,updateAt,nom,prenoms,photoUrl,codePlanteur,nationalite,origine,typePiece,contact},
          acteurQltevie{createdAt,dateCreationMobile,deleted,distanceMaisonEcole,distanceMaisonHospis,id,identMobile,moyenDeDeplacement,natureDuLogement,natureDuLogementAutre,natureToitDuLogement,nbrePiecesMenage,sourceDEau,sourceDEclairage,statutOccupationLogement,updateAt},
					dateCreationMobile,coordonnees,couche {id,},deleted,identMobile,pourcentageRemplissage,principal,superieur {id},tempsEnregis,
					user {id, password, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName,
            profile {id,categorie {id,},codeDeVerification,contact,dateNaissance,deleted,displayName,entreprise {id,raisonSociale},fonction {id,}genre,nom,prenom,tracking,}}createdAt,updateAt,
					acteurDelimitation {pforest, culture, acteur {id},kml,courDEau,createdAt,dateCreationMobile,dateDelimitation,deleted,erosion,geometrie,id,identMobile,imageParcelleUrl,nomGuide,perimetre,pointDrone,rang,surface,telPhone,tempsDelim,typeCourEau,typeDeDelimitation,updateAt,
            userDelimitation {id,password,username,dateJoined,email,firstName,isActive,isStaff,isSuperuser,lastLogin,lastName,profile {id,codeDeVerification,contact,dateNaissance,deleted,displayName,genre,nom,prenom,tracking,entreprise { id,raisonSociale }},}createdAt,,updateAt  },
          superieur{
            id,acteurPerso {id,dateCreationMobile,acteur {id},user {id, password, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName, profile {
            id,categorie {id},codeDeVerification,contact,dateNaissance,deleted,displayName,entreprise {id ,raisonSociale},fonction {id},genre,prenom,tracking}},createdAt,updateAt,nom,prenoms,photoUrl,codePlanteur,nationalite,origine,typePiece,contact}
          }
        }
      }
    }
    `;



    return new Promise(
      (resolve, reject) => {
        if (plantation_bd != null && online_db == false) {
          let cpt = 0, total_offline = 0, all_plantation = [];
          this.dbService.openCursorByIndex(plantation_bd, 'user', IDBKeyRange.only(this.encodeur(userInfo.id)), (evt) => {
            var cursor = (<any>evt.target).result;
            if (cursor) {
              if (this.utils.decrypt(cursor.value.entreprise) == entreprise_id) {
                cpt += 1;
                db_data.data = this.utils.decrypt(cursor.value.data);

                db_data.user = cursor.value.user;
                db_data.profil = this.utils.decrypt(cursor.value.profil);
                db_data.entreprise = this.utils.decrypt(cursor.value.entreprise);
                all_plantation.push(JSON.parse(db_data.data));
              }
              cursor.continue();
            } else {
              total_offline = (cpt == 0) ? 0 : ((all_plantation[cpt - 1].length) + ((cpt - 1) * this.nb_row_returned))
              if (total_offline > total_online && total_offline != 0 && total_online != 0) {
                this.dbService.clear(plantation_bd).then(() => { }, error => { });
                this.readLocale(filter, page, order, total_online);
              }

              if (total_offline == total_online && total_offline != 0) {
                let p: any = {

                    results: all_plantation[page - 1],
                    totalCount: total_offline

                }
                resolve(p);
              } else {
                if (page * this.nb_row_returned > total_offline) {
                  if (total_offline % this.nb_row_returned == 0) {
                    this.utils.client.request(query).then(
                      (res: any) => {
                        db_data.data = this.utils.encrypt(JSON.stringify(res.searchActeur.results));

                        db_data.user = this.encodeur(userInfo.id)
                        db_data.profil = this.utils.encrypt(userInfo.profile.id);
                        db_data.entreprise = this.utils.encrypt(entreprise_id);

                        this.dbService.add(plantation_bd, db_data).then(() => { }, error => {  });
                        resolve(res.searchActeur);
                      }, (err) => {
                        this.utils.setErrorsHttp(err)
                        reject(err);
                      }
                    );
                  } else {
                    if (all_plantation.length > 0) {
                      this.dbService.delete(plantation_bd, all_plantation[all_plantation.length - 1].locale_id).then(() => { }, error => {  });
                    }
                    this.readLocale(filter, page, order, total_online);
                  }
                } else {
                  let p: any = {

                      results: all_plantation[page - 1],
                      totalCount: total_online

                  }
                  resolve(p);
                }
              }
            }
          });
        } else {
          this.read(filter, page, order);
        }
      }
    );
  }

  readActeurOnly(filter: string = 'deleted: false',
   page: number = 1,
  order: String = '-created_at',
  periodict_filer:DateRange = null){
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    let _period_fileter = 'deleted: false'
    if(periodict_filer){
       _period_fileter += `,createdAt_Gte:"${periodict_filer.start}", createdAt_Lte:"${periodict_filer.endDate}"`
    }


    const query = `
    query{
      searchActeurCouche(${filter}){
        totalCount
        results(page : ${page} , ordering:"` + order + `"){ 
          acteur{
            id
            acteurPerso(${_period_fileter}){
              photoUrl
              nom
              prenoms
            }
          }
          
        }
      }
    }
    
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res: any) => {
             console.log('planteur first', res);
            const data = {
              results: res.searchActeurCouche.results.map(
                (item: any) => item.acteur
              ),
              totalCount: res.searchActeurCouche.totalCount
            }
            resolve(data);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false',
   page: number = 1,
   order: String = '-created_at',
   periodict_filer:DateRange = null) {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    let _period_fileter = 'deleted: false'
    if(periodict_filer){
       _period_fileter += `,createdAt_Gte:"${periodict_filer.start}", createdAt_Lte:"${periodict_filer.endDate}"`
    }
    const query = `
    query{
      searchActeurCouche(${filter}){
        totalCount
        results(page:` + page + `, ordering:"` + order + `"){
           acteur{

            idSuperieur,
            id,
            couche{entreprise{raisonSociale,categorie{libelle}}},
            acteurGen(${_period_fileter}){codePlantation,
            id,
            createdAt},
            acteurFamilliale{concubinage,nbreDepouses,nbreFillesScol,nbreGarconsScol,nbrePersACharge,nbreTotalEnf,nbreTotalEnf,sitMat},
            acteurFormation{ameliorationTravail,certification,dateCertif,nivDEtude,nonScolarise,participationFormation,preuveCertifImage,raisonInutile},
            acteurInfoSociopro{activitePrincipale,activiteSecondaire,anneesDExperienceActSec,anneesDExperienceActPrinc, profession, blanc1Sociopro, blanc3Sociopro},
            acteurEngagement{
          engagementWeflyUrl
          engagementClientUrl
        }
        acteurBancaire{
          numeroCompte
          numeroRib
          bulletinPaye
          ficheControle
          attestionPlantation
          statutCompte
          nomBanque
          imageRib
        }
        acteurAnnexePerso{
          annexe1Libelle
        annexe1Url
        annexe2Libelle
        annexe2Url
        annexe3Libelle
        annexe3Url
        annexe4Libelle
        annexe4Url
        annexe5Libelle
        annexe5Url
        annexe6Libelle
        annexe6Url
          }
            acteurSrceRev{benefLastRecolte,montantEpargne,revenusSuffisant,srceRevenuActPrinc, partDesRevenusOrigineActPrinc, epargne},
            acteurMaindoeuvre{fourchettePaiement,momentUtilMainDoeuvre,moyenPaiement,natureMainDoeuvre,origineMaindoeuvre,tempsTravailParJour,typeMainDoeuvre,typePaiement},
            acteurEntretien{achetePar,coutAngrais,freqTraitementPhyto,lieuAchatAngrais,nomOrgAchat,periodeTraitementPhyto,traitementPhyto,typeAngrais,utilisationAngrais},
            acteurProductionplantation{dateProduction,qteProduite,typeAcheteur,
          nomAcheteur},
            acteurNotation {id,nettoyage,densite,access,santePlantes,},
            acteurOrga{appartenanceCoop,createdAt,dateCreationMobile,dateEntreeCoop,deleted,desirAppartenanceCoop,id,identMobile,niveauSatisfaction,prkwPasSatisfaisant,receptionEquipementAnneeEnCours,receptionIntrantAnneeEnCours,updateAt},

            acteurPerso(${_period_fileter}) {id,dateCreationMobile, createdAt, dateNaissance, autreCodePlanteur, acteur {id},
              user {id, password, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName,
                profile {id, categorie {id},codeDeVerification,contact,dateNaissance,deleted,displayName,entreprise {id,raisonSociale},fonction {id},genre,nom,prenom,tracking}},createdAt,updateAt,nom,prenoms,photoUrl,codePlanteur,nationalite,origine,typePiece,contact,
                village
                dateNaissance
                genre
                natureDemande},

            acteurQltevie{createdAt,dateCreationMobile,deleted,distanceMaisonEcole,distanceMaisonHospis,id,identMobile,moyenDeDeplacement,natureDuLogement,natureDuLogementAutre,natureToitDuLogement,nbrePiecesMenage,sourceDEau,sourceDEclairage,statutOccupationLogement,updateAt},

            dateCreationMobile,coordonnees,couche {id,},deleted,identMobile,pourcentageRemplissage,principal,superieur {id},tempsEnregis,

            user {id, password, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName,
              profile {id,categorie {id,},codeDeVerification,contact,dateNaissance,deleted,displayName,entreprise {id,raisonSociale},fonction {id,}genre,nom,prenom,tracking,}}createdAt,updateAt,

              acteurDelimitation {pforest, culture, acteur {id},kml,courDEau,createdAt,dateCreationMobile,dateDelimitation,deleted,erosion,geometrie,id,identMobile,imageParcelleUrl,nomGuide,perimetre,pointDrone,rang,surface,telPhone,tempsDelim,typeCourEau,typeDeDelimitation,updateAt,

              userDelimitation {id,password,username,dateJoined,email,firstName,isActive,isStaff,isSuperuser,lastLogin,lastName,profile {id,codeDeVerification,contact,dateNaissance,deleted,displayName,genre,nom,prenom,tracking,entreprise { id,raisonSociale }},}createdAt,,updateAt  },

              superieur{
              id,acteurPerso {id,dateCreationMobile,acteur {id},user {id, password, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName, profile {
              id,categorie {id},codeDeVerification,contact,dateNaissance,deleted,displayName,entreprise {id ,raisonSociale},fonction {id},genre,prenom,tracking}},createdAt,updateAt,nom,prenoms,photoUrl,codePlanteur,nationalite,origine,typePiece,contact}
            }


           }
        }

      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res: any) => {
            const data = {
              results: res.searchActeurCouche.results.map(
                (item: any) => item.acteur
              ),
              totalCount: res.searchActeurCouche.totalCount
            }
            resolve(data);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }



  readActeur(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at', periodict_filer:DateRange = null) {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    let _period_fileter = 'deleted: false'
    if(periodict_filer){
       _period_fileter += `,createdAt_Gte:"${periodict_filer.start}", createdAt_Lte:"${periodict_filer.endDate}"`
    }
    const query = `
    query{
      searchActeur(${filter}){
        totalCount
        results(page:` + page + `, ordering:"` + order + `"){
          idSuperieur,id,
          couche{entreprise{raisonSociale,categorie{libelle}}},
          acteurGen(${_period_fileter}){codePlantation,id, createdAt},
          acteurFamilliale{concubinage,nbreDepouses,nbreFillesScol,nbreGarconsScol,nbrePersACharge,nbreTotalEnf,nbreTotalEnf,sitMat},
          acteurFormation{ameliorationTravail,certification,dateCertif,nivDEtude,nonScolarise,participationFormation,preuveCertifImage,raisonInutile},
          acteurInfoSociopro{activitePrincipale,activiteSecondaire,anneesDExperienceActSec,anneesDExperienceActPrinc, profession, blanc1Sociopro, blanc3Sociopro},
          acteurEngagement{
        engagementWeflyUrl
        engagementClientUrl
      }
      acteurBancaire{
        numeroCompte
        numeroRib
        bulletinPaye
        ficheControle
        attestionPlantation
        statutCompte
        nomBanque
        imageRibUrl
      }
      acteurAnnexePerso{
        annexe1Libelle
        annexe1Url
        annexe2Libelle
        annexe2Url
        annexe3Libelle
        annexe3Url
        annexe4Libelle
        annexe4Url
        annexe5Libelle
        annexe5Url
        annexe6Libelle
        annexe6Url
        }
          acteurSrceRev{benefLastRecolte,montantEpargne,revenusSuffisant,srceRevenuActPrinc,partDesRevenusOrigineActPrinc,epargne},
          acteurMaindoeuvre{fourchettePaiement,momentUtilMainDoeuvre,moyenPaiement,natureMainDoeuvre,origineMaindoeuvre,tempsTravailParJour,typeMainDoeuvre,typePaiement},
          acteurEntretien{achetePar,coutAngrais,freqTraitementPhyto,lieuAchatAngrais,nomOrgAchat,periodeTraitementPhyto,traitementPhyto,typeAngrais,utilisationAngrais},
          acteurProductionplantation{dateProduction,qteProduite,typeAcheteur,
        nomAcheteur},
          acteurNotation {id,nettoyage,densite,access,santePlantes,},
          acteurOrga{appartenanceCoop,createdAt,dateCreationMobile,dateEntreeCoop,deleted,desirAppartenanceCoop,id,identMobile,niveauSatisfaction,prkwPasSatisfaisant,receptionEquipementAnneeEnCours,receptionIntrantAnneeEnCours,updateAt},
          acteurPerso(${_period_fileter}) {id,dateCreationMobile, createdAt, acteur {id},
            user {id, password, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName,
              profile {id, categorie {id},codeDeVerification,contact,dateNaissance,deleted,displayName,entreprise {id,raisonSociale},fonction {id},genre,nom,prenom,tracking}},createdAt,updateAt,nom,prenoms,photoUrl, codePlanteur,nationalite,origine,typePiece,contact,
              village
              dateNaissance
              autreCodePlanteur
              genre
              chefVillage
              natureDemande},

          acteurQltevie{createdAt,dateCreationMobile,deleted,distanceMaisonEcole,distanceMaisonHospis,id,identMobile,moyenDeDeplacement,natureDuLogement,natureDuLogementAutre,natureToitDuLogement,nbrePiecesMenage,sourceDEau,sourceDEclairage,statutOccupationLogement,updateAt},
					dateCreationMobile,coordonnees,couche {id,},deleted,identMobile,pourcentageRemplissage,principal,superieur {id},tempsEnregis,
					user {id, password, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName,
            profile {id,categorie {id,},codeDeVerification,contact,dateNaissance,deleted,displayName,entreprise {id,raisonSociale},fonction {id,}genre,nom,prenom,tracking,}}createdAt,updateAt,
					acteurDelimitation {pforest, culture, acteur {id},kml,courDEau,createdAt,dateCreationMobile,dateDelimitation,deleted,erosion,geometrie,id,identMobile,imageParcelleUrl,nomGuide,perimetre,pointDrone,rang,surface,telPhone,tempsDelim,typeCourEau,typeDeDelimitation,updateAt,
            userDelimitation {id,password,username,dateJoined,email,firstName,isActive,isStaff,isSuperuser,lastLogin,lastName,profile {id,codeDeVerification,contact,dateNaissance,deleted,displayName,genre,nom,prenom,tracking,entreprise { id,raisonSociale }},}createdAt,,updateAt  },
          superieur{
            id,acteurPerso {id,dateCreationMobile,acteur {id},user {id, password, username, dateJoined, email, firstName, isActive, isStaff, isSuperuser, lastLogin, lastName, profile {
            id,categorie {id},codeDeVerification,contact,dateNaissance,deleted,displayName,entreprise {id ,raisonSociale},fonction {id},genre,prenom,tracking}},createdAt,updateAt,nom,prenoms,photoUrl,codePlanteur,nationalite,origine,typePiece,contact}
          }
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res: any) => {
            resolve(res.searchActeur);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  readTotalCount(filter: string = 'deleted: false') {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    const query = `
    query{
      searchActeur(${filter}){
        totalCount
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res: any) => {
            resolve(res.searchActeur.totalCount);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  readImg(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    const query = `
    query{
      searchActeur(${filter}){
        totalCount
        results(page:` + page + `, ordering:"` + order + `"){
          id
          acteurPerso {
            id
            photo
            photoUrl
          }
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res: any) => {
            resolve(res.searchActeur);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  private encodeur(str: string): string {
    return btoa(Math.exp((parseInt(str) / 1000)).toString());
  }

  private decodeur(str: string): string {
    // Math.log(atob(str));
    return atob((Math.log(parseInt(str) - 1000)).toString())

  }

  readActorWithImg(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
    filter = (filter == null || filter == '') ? 'deleted: false' : filter;
    const query = `
    query{
      searchActeur(${filter}){
        totalCount
        results(page:` + page + `, ordering:"` + order + `"){
          idSuperieur,id,
          couche{
            entreprise{
              raisonSociale,
              categorie{libelle}, 
              logo
            }},
          acteurGen{codePlantation,id},
          acteurFamilliale{concubinage,nbreDepouses,nbreFillesScol,nbreGarconsScol,nbrePersACharge,nbreTotalEnf,nbreTotalEnf,sitMat},
          acteurFormation{ameliorationTravail,certification,dateCertif,nivDEtude,nonScolarise,participationFormation,preuveCertifImage,raisonInutile, blanc1Formation},
          acteurInfoSociopro{activitePrincipale,activiteSecondaire,anneesDExperienceActSec,anneesDExperienceActPrinc, blanc1Sociopro, blanc3Sociopro},
          acteurSrceRev{benefLastRecolte,montantEpargne,revenusSuffisant,srceRevenuActPrinc, partDesRevenusOrigineActPrinc,epargne},
          acteurMaindoeuvre{fourchettePaiement,momentUtilMainDoeuvre,moyenPaiement,natureMainDoeuvre,origineMaindoeuvre,tempsTravailParJour,typeMainDoeuvre,typePaiement},
          acteurEntretien{achetePar,coutAngrais,freqTraitementPhyto,lieuAchatAngrais,nomOrgAchat,periodeTraitementPhyto,traitementPhyto,typeAngrais,utilisationAngrais},
          acteurProductionplantation{dateProduction,qteProduite, typeAcheteur,nomAcheteur},
          acteurNotation {id,nettoyage,densite,access,santePlantes,},
          acteurOrga{appartenanceCoop,createdAt,dateCreationMobile,dateEntreeCoop,deleted,desirAppartenanceCoop,id,identMobile,niveauSatisfaction,prkwPasSatisfaisant,receptionEquipementAnneeEnCours,receptionIntrantAnneeEnCours,updateAt},
          acteurPerso
          {id,dateCreationMobile,acteur {id},
              createdAt,updateAt,nom,prenoms,codePlanteur,nationalite,origine,typePiece,contact, photoUrl, photo, dateNaissance, autreCodePlanteur, genre, village,
              chefVillage},
          acteurQltevie
          {createdAt,dateCreationMobile,deleted,distanceMaisonEcole,distanceMaisonHospis,id,identMobile,moyenDeDeplacement,natureDuLogement,natureDuLogementAutre,natureToitDuLogement,nbrePiecesMenage,sourceDEau,sourceDEclairage,statutOccupationLogement,updateAt},

          dateCreationMobile,coordonnees,couche {id,},deleted,identMobile,pourcentageRemplissage,principal,superieur {id},
          tempsEnregis,
          createdAt,updateAt,
					acteurDelimitation {
            pforest, culture, acteur {id},kml,courDEau,createdAt,dateCreationMobile,dateDelimitation,deleted,erosion,geometrie,id,identMobile,imageParcelle,nomGuide,perimetre,pointDrone,rang,surface,telPhone,tempsDelim,typeCourEau,typeDeDelimitation,updateAt,
            createdAt,updateAt
          },
            acteurEngagement{
            engagementWefly
            engagementClient
           }
          acteurBancaire{
            numeroCompte
            numeroRib
            bulletinPaye
            ficheControle
            attestionPlantation
            statutCompte
            nomBanque
            imageRib
          }
          acteurAnnexePerso{
            annexe1
            annexe2
            annexe3
            annexe4
            annexe5
            annexe6
          }
            superieur{
            id,acteurPerso
            {id,dateCreationMobile,acteur
               {id},
                createdAt,updateAt,nom,prenoms,photo,codePlanteur,nationalite,origine,typePiece,contact
            }
          }
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res: any) => {
            resolve(res.searchActeur);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

