import { ReponseOffreListComponent } from "./@marketplace/reponse-offre-list/reponse-offre-list.component";
import { ReponseOffreComponent } from "./@marketplace/reponse-offre/reponse-offre.component";
import { DemandeDeProductionListComponent } from "./@marketplace/demande-de-production-list/demande-de-production-list.component";
import { UniteMesureComponent } from "./unite-mesure/unite-mesure.component";
import { VarieteCultureComponent } from "./variete-culture/variete-culture.component";
import { CultureComponent } from "./culture/culture.component";
import { FamilleCultureComponent } from "./famille-culture/famille-culture.component";
import { MainComponent } from "./@marketplace/main/main.component";
import { MarketDashComponent } from "./@marketplace/market-dash/market-dash.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { IndexComponent } from "./index/index.component";
import { LoginComponent } from "./login/login.component";
import { RoleComponent } from "./role/role.component";
import { UserComponent } from "./user/user.component";
import { ProfileComponent } from "./profile/profile.component";
import { PermissionComponent } from "./permission/permission.component";
import { FonctionComponent } from "./fonction/fonction.component";
import { PaginationComponent } from "./graphql/pagination/pagination.component";
import { PrincipalMenuComponent } from "./principal-menu/principal-menu.component";
import { InscriptionComponent } from "./inscription/inscription.component";
import { AccueilComponent } from "./accueil/accueil.component";
import { UserNavComponent } from "./user-nav/user-nav.component";
import { DynamicFormComponent } from "./dynamic-form/dynamic-form.component";
import { EntrepriseComponent } from "./entreprise/entreprise.component";
import { AttributComponent } from "./attribut/attribut.component";
import { TypeAttributComponent } from "./type-attribut/type-attribut.component";
import { CompartimentFormComponent } from "./compartiment-form/compartiment-form.component";
import { FormcoucheComponent } from "./formcouche/formcouche.component";
import { TestDeServComponent } from "./test-de-serv/test-de-serv.component";
import { PaysComponent } from "./pays/pays.component";
import { ProfileCategorieComponent } from "./profile-categorie/profile-categorie.component";
import { CoucheComponent } from "./couche/couche.component";
import { TypeCoucheComponent } from "./type-couche/type-couche.component";
import { ImgLoaderComponent } from "./img-loader/img-loader.component";
import { PeriodeTrackingComponent } from "./periode-tracking/periode-tracking.component";
import { FonctionaliteComponent } from "./fonctionalite/fonctionalite.component";
import { FonctionnaliteEntrepriseComponent } from "./fonctionnalite-entreprise/fonctionnalite-entreprise.component";
import { AuthgardService } from "./login/authgard.service";
import { ZoneComponent } from "./zone/zone.component";
import { PageDashboardComponent } from "./page-dashboard/page-dashboard.component";
import { FiltreComponent } from "./filtre/filtre.component";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { AdminDjangoService } from "./login/admin-django.service";
import { GeojsonComponent } from "./@drone/geojson/geojson.component";
import { NavComponent } from "./@drone/nav/nav.component";
import { GestionParcelleComponent } from "./@drone/gestion-parcelle/gestion-parcelle.component";
import { LecteurVideoComponent } from "./@drone/lecteur-video/lecteur-video.component";
import { LecteurVrComponent } from "./@drone/lecteur-vr/lecteur-vr.component";
import { PlantationLightItemComponent } from "./@drone/plantation-light-item/plantation-light-item.component";
import { ListHeadBarComponent } from "./@drone/list-head-bar/list-head-bar.component";
import { RapportComponent } from "./@drone/rapport/rapport.component";
import { CalendarComponent } from "./@drone/calendar/calendar.component";
import { HomeComponent as HomeMarket } from "./@marketplace/home/home.component";
import { HomeComponent as HomeWegeo } from "./@wegeo/home/home.component";
import { ArticleListeComponent } from "./@marketplace/article-liste/article-liste.component";
import { MySidebarComponent } from "./@marketplace/mysidebar/mysidebar.component";
import { NavWegeoComponent } from "./@wegeo/nav-wegeo/nav-wegeo.component";
import { CooperativeDashboardComponent } from "./@wegeo/cooperative-dashboard/cooperative-dashboard.component";
import { GestionEmployeComponent } from "./@wegeo/gestion-employe/gestion-employe.component";
import { GestionPlantationComponent } from "./@wegeo/gestion-plantation/gestion-plantation.component";
import { TrackingComponent } from "./@wegeo/tracking/tracking.component";
import { PerformanceComponent } from "./@wegeo/performance/performance.component";
import { FormationComponent } from "./@wegeo/formation/formation.component";
import { FormsComponent } from "./@wegeo/forms/forms.component";
import { LeafletSampleComponent } from "./@wegeo/leaflet-sample/leaflet-sample.component";
import { PlanteurDetailComponent } from "./@wegeo/planteur-detail/planteur-detail.component";
import { MapDashComponent } from "./@wegeo/map-dash/map-dash.component";
import { EmployeDetailsComponent } from "./@wegeo/employe-details/employe-details.component";
import { ActeursComponent } from "./@wegeo/acteurs/acteurs.component";
import { WgZoneComponent } from "./@wegeo/wg-zone/wg-zone.component";
import { DetailActeurComponent } from "./@wegeo/detail-acteur/detail-acteur.component";
import { AdminEntrepriseService } from "./login/admin-entreprise.service";
import { WegeoAdminComponent } from "./@wegeo/wegeo-admin/wegeo-admin.component";
import { ForgetPasswordComponent } from "./forget-password/forget-password.component";
import { OffreVenteListComponent } from "./@marketplace/offre-vente-list/offre-vente-list.component";
import { VillageComponent } from "./village/village.component";
import { CampementComponent } from "./campement/campement.component";
import { ConfirmeUserRegisterComponent } from "./confirme-user-register/confirme-user-register.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

const routes: Routes = [
  { path: "denied", component: AccessDeniedComponent },

  { path: "example", component: PageDashboardComponent },

  {
    path: "",
    component: IndexComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "inscription", component: InscriptionComponent },

      { path: "", redirectTo: "login", pathMatch: "full" },
    ],
  },
  { path: "confirmeUserRegister", component: ConfirmeUserRegisterComponent },
  { path: 'resetPassword', component: ResetPasswordComponent },
  { path: "usernav", component: UserNavComponent },
  { path: "accueil", component: AccueilComponent },
  { path: "role", canActivate: [AuthgardService], component: RoleComponent },
  {
    path: "admin",
    canActivate: [AuthgardService, AdminDjangoService],
    component: UserComponent,
  },
  {
    path: "user/:id_user",
    canActivate: [AuthgardService],
    component: UserComponent,
  },
  {
    path: "profile",
    canActivate: [AuthgardService],
    component: ProfileComponent,
  },
  {
    path: "permission",
    canActivate: [AuthgardService],
    component: PermissionComponent,
  },
  {
    path: "fonction",
    canActivate: [AuthgardService],
    component: FonctionComponent,
  },
  {
    path: "menu",
    canActivate: [AuthgardService],
    component: PrincipalMenuComponent,
  },
  {
    path: "page",
    canActivate: [AuthgardService],
    component: PaginationComponent,
  },
  {
    path: "dynamic-form",
    canActivate: [AuthgardService],
    component: DynamicFormComponent,
  },
  {
    path: "entreprise",
    canActivate: [AuthgardService],
    component: EntrepriseComponent,
  },
  {
    path: "entreprise/:list",
    canActivate: [AuthgardService],
    component: EntrepriseComponent,
  },
  {
    path: "attribut",
    canActivate: [AuthgardService],
    component: AttributComponent,
  },
  {
    path: "type-attribut",
    canActivate: [AuthgardService],
    component: TypeAttributComponent,
  },
  {
    path: "compartiment-form",
    canActivate: [AuthgardService],
    component: CompartimentFormComponent,
  },
  {
    path: "form-couche",
    canActivate: [AuthgardService],
    component: FormcoucheComponent,
  },
  {
    path: "couche",
    canActivate: [AuthgardService],
    component: CoucheComponent,
  },
  {
    path: "pays",
    canActivate: [AuthgardService, AdminDjangoService],
    component: PaysComponent,
  },
  { path: "categorie-profile", component: ProfileCategorieComponent },
  {
    path: "type-couche",
    canActivate: [AuthgardService],
    component: TypeCoucheComponent,
  },
  { path: "geo", canActivate: [AuthgardService], component: GeojsonComponent },
  {
    path: "periode-tracking",
    canActivate: [AuthgardService],
    component: PeriodeTrackingComponent,
  },
  {
    path: "fonctionnalite",
    canActivate: [AuthgardService, AdminDjangoService],
    component: FonctionaliteComponent,
  },
  { path: "testeur", component: TestDeServComponent },
  { path: "zone", component: ZoneComponent },
  { path: "page-dash", component: PageDashboardComponent },
  {
    path: "entreprise-fonctionnalite",
    canActivate: [AuthgardService, AdminDjangoService],
    component: FonctionnaliteEntrepriseComponent,
  },
  // { path: 'img-loader', canActivate: [AuthgardService], component: ImgLoaderComponent },
  // { path: 'controls', component : PaginationControlsComponent},

  // Drone links
  { path: "example", component: PageDashboardComponent },
  { path: "drone/nav", component: NavComponent },
  { path: "drone/home", component: GestionParcelleComponent },
  { path: "drone/video", component: LecteurVideoComponent },
  { path: "drone/vr", component: LecteurVrComponent },
  { path: "drone/plantation-item", component: PlantationLightItemComponent },
  { path: "drone/list-head-bar", component: ListHeadBarComponent },
  { path: "drone/gestion-parcelle", component: GestionParcelleComponent },
  { path: "drone/rapport", component: RapportComponent },
  { path: "drone/rapport/:id", component: RapportComponent },
  { path: "drone/rapport/:id/:action", component: RapportComponent },
  { path: "drone/calendar", component: CalendarComponent },
  { path: "drone", redirectTo: "drone/home" },

  // Market links
  {
    path: "market",
    component: MainComponent,
    children: [
      { path: "", component: HomeMarket },
      {
        path: "dashboard",
        component: MarketDashComponent,
        children: [
          {
            path: "",
            component: OffreVenteListComponent,
          },
          {
            path: "offres-de-vente",
            component: OffreVenteListComponent,
          },
          {
            path: "demandes-de-production",
            component: DemandeDeProductionListComponent,
          },
          {
            path: "commande-offre-de-vente",
            component: ReponseOffreListComponent,
          },
          {
            path: "famille-culture",
            component: FamilleCultureComponent,
          },
          {
            path: "culture",
            component: CultureComponent,
          },
          {
            path: "variete-culture",
            component: VarieteCultureComponent,
          },
          {
            path: "unite-mesure",
            component: UniteMesureComponent,
          },
        ],
      },
      { path: "article-liste", component: ArticleListeComponent },
      { path: "sidebar", component: MySidebarComponent },
    ],
  },

  // wegeo Links
  { path: "wegeo/nav", component: NavWegeoComponent },
  // { path: "resetpassword/:uid", component: ForgetPasswordComponent },
  { path: "forget-password", component: ForgetPasswordComponent },

  {
    path: "wegeo",
    component: HomeWegeo,
    data: { animation: "wegeo" },
    children: [
      {
        path: "filtre",
        component: FiltreComponent,
      },
      {
        path: "",
        canActivate: [AuthgardService],
        component: CooperativeDashboardComponent,
        data: { animation: "wegeo" },
      },
      {
        path: "home/:entrepriseId",
        canActivate: [AuthgardService],
        component: CooperativeDashboardComponent,
        data: { animation: "wegeo" },
      },
      {
        path: "dashboard",
        canActivate: [AuthgardService],
        component: CooperativeDashboardComponent,
        data: { animation: "wegeo" },
      },
      {
        path: "dashboard/:entrepriseId",
        canActivate: [AuthgardService],
        component: CooperativeDashboardComponent,
        data: { animation: "wegeo" },
      },
      {
        path: "employes",
        canActivate: [AuthgardService],
        component: GestionEmployeComponent,
      },
      {
        path: "employes/:entrepriseId",
        canActivate: [AuthgardService],
        component: GestionEmployeComponent,
      },
      {
        path: "plantation",
        canActivate: [AuthgardService],
        component: GestionPlantationComponent,
        data: { animation: "plantation" },
      },
      {
        path: "plantation/:entrepriseId",
        canActivate: [AuthgardService],
        component: GestionPlantationComponent,
        data: { animation: "plantation" },
      },
      {
        path: "employes/tracking",
        canActivate: [AuthgardService],
        component: TrackingComponent,
      },
      {
        path: "employes/tracking/:entrepriseId",
        canActivate: [AuthgardService],
        component: TrackingComponent,
      },
      {
        path: "performance",
        canActivate: [AuthgardService],
        component: PerformanceComponent,
      },
      {
        path: "employes/performance/:entrepriseId",
        canActivate: [AuthgardService],
        component: PerformanceComponent,
      },
      {
        path: "employes/formation",
        canActivate: [AuthgardService],
        component: FormationComponent,
      },
      {
        path: "formulaire",
        canActivate: [AuthgardService],
        component: FormsComponent,
      },
      {
        path: "leaflet",
        canActivate: [AuthgardService],
        component: LeafletSampleComponent,
      },
      {
        path: "tracking",
        canActivate: [AuthgardService],
        component: TrackingComponent,
      },
      {
        path: "planteur/:id",
        canActivate: [AuthgardService],
        component: PlanteurDetailComponent,
      },
      {
        path: "map-dash",
        canActivate: [AuthgardService],
        component: MapDashComponent,
      },
      {
        path: "mon-profil",
        canActivate: [AuthgardService],
        component: EmployeDetailsComponent,
      },
      {
        path: "employes/details/:id",
        canActivate: [AuthgardService],
        component: EmployeDetailsComponent,
      },
      {
        path: "acteurs",
        canActivate: [AuthgardService],
        component: ActeursComponent,
      },
      {
        path: "zone",
        canActivate: [AuthgardService],
        component: WgZoneComponent,
      },
      {
        path: "acteur/detail/:id",
        canActivate: [AuthgardService],
        component: DetailActeurComponent,
      },
      {
        path: "admin",
        canActivate: [AuthgardService, AdminEntrepriseService],
        component: WegeoAdminComponent,
        children: [
          { path: "", component: PeriodeTrackingComponent },
          {
            path: "entreprise",
            component: EntrepriseComponent,
            canActivate: [AdminDjangoService],
          },
          {
            path: "permission",
            component: PermissionComponent,
            canActivate: [AdminDjangoService],
          },
          {
            path: "type_attribut",
            component: TypeAttributComponent,
            canActivate: [AdminDjangoService],
          },
          {
            path: "attribut",
            component: AttributComponent,
            canActivate: [AdminDjangoService],
          },
          {
            path: "compartiment",
            component: CompartimentFormComponent,
            canActivate: [AdminDjangoService],
          },
          {
            path: "form_couche",
            component: FormcoucheComponent,
            canActivate: [AdminDjangoService],
          },
          {
            path: "pays",
            component: PaysComponent,
            canActivate: [AdminDjangoService],
          },
          {
            path: "categorie_profile",
            component: ProfileCategorieComponent,
            canActivate: [AdminDjangoService],
          },
          { path: "role", component: RoleComponent },
          { path: "type_couche", component: TypeCoucheComponent },
          { path: "fonction", component: FonctionComponent },
          { path: "couche", component: CoucheComponent },
          { path: "periode_traking", component: PeriodeTrackingComponent },
          // {path:"village", component: VillageComponent },
          // {path:"campement", component:CampementComponent },
        ],
        data: { animation: "wegeo/admin" },
      },
    ],
  },

  { path: "**", redirectTo: "/login" },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
