export class Navigation{
    defaultIcon:string
    styleIcon:string
    libelle:string
    menuChoisi:string
    pathName:string
    overPathName:string

    constructor(defaultIcon:string, styleIcon:string, libelle:string, menuChoisi:string, pathName:string, overPathName:string){
        this.defaultIcon = defaultIcon
        this.styleIcon = styleIcon
        this.libelle = libelle
        this.menuChoisi = menuChoisi
        this.pathName = pathName
        this.overPathName = overPathName

    }
}