import { Component, OnInit } from '@angular/core';
import { PermissionService } from '../_services/permission.service';
import { NgForm } from '@angular/forms';
import { delay } from 'q';
import { UtilsService } from '../_services/utils.service';
import { utils } from 'protractor';
import { FonctionaliteService } from '../_services/fonctionalite.service';

declare var $: any;

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent implements OnInit {



  // Param filter
  fields_selected_: Array<any> = [
    { libelle_db: "displayName_Icontains", libelle: "Libelle" },
  ]

  filter: any = {
    field: null,
    value: null
  }

  loading: boolean = true

  // Pagin param
  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;

  userInfo: any;

  inputError: any = [];
  allPermission: any = [];
  fctUpdate: any = [];
  toUpdate: {
    createdAt: String,
    updateAt: String,
    displayName: String,
    fonctionnalite: {
      id: String,
      libelle: String
    },
    id: String,
    permission: {
      id: String,
      codename: String,
      name: String
    },
  } = {
      createdAt: '',
      updateAt: '',
      displayName: '',
      fonctionnalite: {
        id: '',
        libelle: ''
      },
      id: '',
      permission: {
        id: '',
        codename: '',
        name: ''
      },
    };
  toDelete: any = [];
  fields: any = [];
  updating = false;
  updated = false;
  pageAct = 1;
  fonctionnalites: any = [];
  permissionDjango: any = [];

  constructor(private permissionService: PermissionService, private service: UtilsService, private foncServ: FonctionaliteService) { }

  ngOnInit() {
    this.userInfo = JSON.parse(this.service.decrypt(this.service.getCookie('_wefly_')))
    this.readFonctionnalite();
    this.onReadAll();
    this.readPermissionDjango();
  }

  gotsearchValue(a) {
    return a;
  }

  onSubmit(form: NgForm) {
    let f = form.value;
    this.fields = this.service.formIsNotNull(form, ['libelle', 'fonctionnalite', 'permissionDjango']);
    if (this.fields.length === 0) {
      if (this.updating) {
        this.permissionService.update(this.toUpdate.id, f.fonctionnalite, f.permissionDjango, false, f.libelle).then(
          (res: any) => {
            this.allPermission.results.forEach((value, index) => {
              if (value.id == res.id) {
                this.allPermission.results[index] = res;
              }
            })
            form.reset();
            this.service.snack('Permission mise à jour avec succès');
          }, (err) => {
          }
        ).catch(reason =>  this.service.setErrorsHttp(reason));
      } else {
        this.permissionService.create(f.fonctionnalite, f.permissionDjango, false, f.libelle).then(
          (res: any) => {
            this.allPermission.results.unshift(res);
            this.allPermission.totalCount += 1;
            this.service.snack('Permission créée avec succès');
          }, (err) => {
          }
        ).catch(reason =>  this.service.setErrorsHttp(reason));
      }
    }
  }

  onReadAll(page: number = 1, filter_field: any = null) {
    this.loading = true
    let filter = `permissionSuiteRole_Role_Entreprise_Id: "${this.userInfo.profile.entreprise.id}", deleted: false`
    if(filter_field != null && filter_field.field != null){
      filter = filter + `,  ${filter_field.field} : "${filter_field.value}"`
    }
    this.permissionService.read(filter, page).then(
      (res: any) => {
        this.loading = false
        this.totalCount = res.searchPermissionSuite.totalCount
        this.allPermission = res.searchPermissionSuite;
      }, (err) => {
      }
    ).catch(reason =>  this.service.setErrorsHttp(reason));
  }

  onScroll(e) {

  }

  clickUpdate(permission) {
    this.updating = true;
    this.toUpdate = permission;
  }

  onDelete() {
    this.permissionService.update(this.toDelete.id, this.toDelete.fonctionnalite.id, this.toDelete.permission.id, true, this.toDelete.displayName).then(
      (res: any) => {
        this.allPermission.results.forEach((value, index) => {
          if (value.id == res.id) {
            this.allPermission.results.splice(index, 1);
          }
        });
        this.service.snack('Permission mise à jour avec succès');
      }, (err) => {
      }
    ).catch(reason =>  this.service.setErrorsHttp(reason));
  }

  clickDelete(permission) {
    this.toDelete = permission;
    $('#deletePopUp').modal('show');
  }

  addNew(f: NgForm) {
    f.reset();
    this.updating = false;
  }

  readFonctionnalite() {
    this.foncServ.read().then(
      (res: any) => {
        this.fonctionnalites = res;
      },
      (err) => {
      }
    ).catch(reason =>  this.service.setErrorsHttp(reason));
  }

  public readPermissionDjango(): void {
    this.permissionService.readPermission().then(
      (res: any) => {
        this.permissionDjango = res.searchPermission;
      }, (err) => {
      }
    ).catch(reason =>  this.service.setErrorsHttp(reason))
  }

  public onChangePage(data) {
    this.currentPage = data.id;
    this.onReadAll(data.id)
  }


  filterData(data) {
    if (data.action === 0) {
      return
      this.filter = data.data_
    } else {
      this.loading = true
      this.filter = data.data_
      if (data.data_.value.length == 0) {
        this.onReadAll(1)
      } else {
        this.onReadAll(1, this.filter)
      }
    }
  }

}
