import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-heb-doweather-item',
  templateUrl: './heb-doweather-item.component.html',
  styleUrls: ['./heb-doweather-item.component.scss']
})
export class HebDoweatherItemComponent implements OnInit {
  @Input() weatherData: any;

  constructor() { }

  ngOnInit() {
  }

}
