import { LoadingComponent } from './../../@wegeo/_modale/loading/loading.component';
import { ReponseOffreService } from './../_services/reponse-offre.service';
import { UtilsService } from './../../_services/utils.service';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmComponent } from 'src/app/confirm/confirm.component';

@Component({
  selector: 'app-reponse-offre-list',
  templateUrl: './reponse-offre-list.component.html',
  styleUrls: ['./reponse-offre-list.component.scss']
})
export class ReponseOffreListComponent implements OnInit {
  responses = [];
  dataSource = new MatTableDataSource<any>();
  columnsToDisplay = ['vendeur', 'acheteur', 'culture', 'quantite', 'etat', 'action'];
  constructor(
    private utils: UtilsService,
    private reponseService: ReponseOffreService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.readOffre();
  }

  readOffre() {
    this.reponseService.read().then(
      (res: []) => {
      
        this.responses = res;
        this.updateDataSource();
      },
      (err) => {
        
      }
    );
  }

  updateDataSource() {
   this.dataSource.connect().next(this.responses);
  }

  delete(i: number) {
    const instanceObj = this.responses[i];
    const dialogRef = this.dialog.open(
      ConfirmComponent,
      {
        width: '50%',
        data: { message: `Souhaitez vous supprimer ?` }
      }
    );

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          const dialogRef = this.dialog.open(
            LoadingComponent, {
              width: '25rem',
              data: {
                loader: true,
                msg: 'Veuillez patienter...'
              }
            }
          );
          this.reponseService.update(
            instanceObj.id,
            instanceObj.commentaire,
            true,
            instanceObj.offreVente.id,
            instanceObj.prixDemandee,
            instanceObj.prixDemandee,
            instanceObj.region.id,
            instanceObj.uniteMesure,
            instanceObj.user.id
          ).then(
            (results) => {
              dialogRef.close();
              this.responses.splice(i, 1);
              this.updateDataSource();
            },
            (err) => {
              dialogRef.close();
            

            }
          );
        }
      }
    )
  }
}
