import { Component, OnInit, NgModule, Input } from "@angular/core";
import { EntrepriseService } from "../_services/entreprise.service";
import { NgForm } from "@angular/forms";
import "jquery";
import "bootstrap";
import { UtilsService } from "../_services/utils.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PaysService } from "../_services/pays.service";
import { AdresseService } from "../_services/adresse.service";
import { UserService } from "../_services/user.service";
import { FonctionaliteService } from "../_services/fonctionalite.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ProfileService } from "../_services/profile.service";
import { MatDialog } from "@angular/material";
import { DeletedComponent } from "../_modale/deleted/deleted.component";
import { ConnexionService } from "../_services/connexion.service";
import { CategorieProfileService } from "../_services/profile-categorie.service";
import * as jwt_decode from 'jwt-decode';
import { SearchUser } from "../_models/user";

declare var $: any;
@NgModule({})
@Component({
  selector: "app-entreprise",
  templateUrl: "./entreprise.component.html",
  styleUrls: ["./entreprise.component.css"],
})
export class EntrepriseComponent implements OnInit {
  plainText: string = "Je suis un Cyber-tracker";
  encryptText: string;
  encPassword: string = "password";
  decPassword: string = "password";
  conversionEncryptOutput: string;
  conversionDecryptOutput: string;

  submitted: boolean = false;

  // Parametre filter
  fields_selected_: Array<any> = [
    { libelle_db: "raisonSociale_Icontains", libelle: "Raison sociale" },
  ];

  filter: any = {
    field: null,
    value: null,
  };

  loading: boolean = true;

  // Fin paramatre

  allEnt: any;
  total = 0;
  page = 1;
  limit = 20;
  fields: any = [];
  entUpdate: any;
  addresseId: string = null;
  toDelete: any = {};
  pays: any = [];
  updating = false;
  updated = false;
  pageAct: any = 1;
  entRue: any;
  entPays: any;
  entVille: any;
  entRaisSociale: any;
  entTel: any;
  entMail: any;
  entWebsite: any;
  entLogo: any = "";
  entId: any;
  entrepriseEntreprise: any;
  userEntreprise: any;
  @Input() showList;
  fonctionnalites: any = [];

  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;

  // Load pays
  loadingPays: boolean = true;

  /* Categoire */
  categorie: any;
  checkbox: any;
  userInfo:any

  constructor(
    private entService: EntrepriseService,
    private utils: UtilsService,
    private snackBar: MatSnackBar,
    private addressServ: AdresseService,
    private paysv: PaysService,
    private usersv: UserService,
    private snackbar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private profileServ: ProfileService,
    private categorieService: CategorieProfileService,
    private actRoute: ActivatedRoute,
    public dialog: MatDialog,
    private loginServ: ConnexionService
  ) {}

  ngOnInit() {
    
    this.userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie("_wefly_")));

    this.onReadAll();
    this.getEnt();
    this.readUser();
    this.fonctionnalites.data = [];
    this.getPays();
    this.readCategorie();
  }

  readCategorie() {
    this.categorieService.getCategorie().then(
        (res: any) => {

          // console.log("catégorieEnter", res);
          if (res.data.searchCategorieProfile.results) {
            console.log('elementelement',res.data.searchCategorieProfile.result),
            res.data.searchCategorieProfile.results =
              res.data.searchCategorieProfile.results.filter(
                (element: any) =>
                  !element.libelle.toLowerCase().includes("fédération") &&
                  !element.libelle.toLowerCase().includes("union")
              );
          }
          this.checkbox = res.data.searchCategorieProfile.results == null ? []  : res.data.searchCategorieProfile.results;
          // console.log(" this.checkbox", this.checkbox);

          this.checkbox.forEach((element) => {
            element.value = false;
          });
        },
        (err) => {
          console.log(err);
        }
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }

  viewClicked(idChecked: number) {
    // console.log("index", idChecked);
    this.checkbox[idChecked].value = !this.checkbox[idChecked].value;
    this.categorie = this.checkbox[idChecked].id;
    console.log("tchekbox", this.categorie);
  }

  public getPays(offset: number = 0) {
    this.paysv
      .read("deleted : false", offset, 20, "name")
      .then(
        (res: any[]) => {
         
          const result: any = res;
          // console.log('les pays', res)
          this.pays = this.pays.concat(result.results);
          if (this.pays.length < result.totalCount) {
            this.getPays(this.pays.length);
          } else {
            this.loadingPays = false;
          }
        },
        (err) => {
          this.snackbar.open(
            "Désolé un problème est survenu, \nveuillez réessayer plus tard",
            "Ok",
            { duration: 5000 }
          );
        }
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }

  onSubmit(f: NgForm) {
    // console.log('to submit', f.value);
    this.submitted = true;
    f.value.entPays = 1;
    f.value.entreprise = "wcw";
    const fonc = [];
    this.fields = this.utils.formIsNotNull(f, [
      "entMail",
      "entPays",
      "entRaisSociale",
      "entTel",
      "entVille",
    ]);
    this.fonctionnalites.data.forEach((elt, i) => {
      if (f.value[elt.fonctionnalitesId]) {
        fonc.push(elt.fonctionnalitesId);
      }
    });

    if (this.fields.length === 0) {
      if (this.updating === true) {
        this.onSubmitToUpdate(f);
      } else {
        this.addressServ
          .create(
            this.entPays,
            false,
            "",
            f.value.entRue ? f.value.entRue : "",
            f.value.entVille ? f.value.entVille : ""
          )
          .then(
            (res: any) => {
              let categorie = "";
              if (
                this.categorie !== "" ||
                this.categorie !== null ||
                this.categorie !== undefined
              ) {
                categorie = this.categorie;
                console.log("dec", categorie);
              } else {
                categorie = "72";
              }
              // try {
              //   this.actRoute.queryParams.subscribe(params => {
              //     // console.log('params', params);
              //     categorie = this.utils.decrypt(params['categorie']);
              //     // console.log('dec')
              //   });
              // } catch (e) {
              //   categorie = '72';
              // }
              this.entService.create(
                  false,
                  "",
                  f.value.entMail,
                  "",
                  this.entLogo,
                  f.value.entRaisSociale,
                  f.value.entWebsite,
                  f.value.entTel,
                  res.createAdresse.adresse.id,
                  categorie
                )
                .then(
                  (mres: any) => {
                    console.log("mres", mres);

                    this.submitted = false;
                    // On assign l'entreprise à l'utilisateur
                    this.profileServ
                      .update(
                        this.userEntreprise.profile.id,
                        categorie,
                        "",
                        this.userEntreprise.profile.contact,
                        this.userEntreprise.profile.dateNaissance,
                        false,
                        "",
                        mres.createEntreprise.entreprise.id,
                        "",
                        this.userEntreprise.profile.genre,
                        this.userEntreprise.profile.nom,
                        "",
                        this.userEntreprise.profile.photo,
                        this.userEntreprise.profile.prenom,
                        false,
                        this.userEntreprise.id,
                        ""
                      )
                      .then(
                        (eres: any) => {
                          console.log('update profile', eres)
                          this.submitted = false;
                          this.userEntreprise.profile =
                            eres.updateProfile.profile;
                          let unlessTof = JSON.parse(
                            JSON.stringify(this.userEntreprise)
                          );
                          sessionStorage.entreprise = this.utils.encrypt(
                            JSON.stringify(
                              eres.updateProfile.profile.entreprise
                            )
                          );
                      
                          this.utils.setCookie("_wefly_",
                            this.utils.encrypt(JSON.stringify(unlessTof)),
                            { nb: 7, unite: 3 }
                          );
                          this.router.navigate(["/wegeo/"]);
                          $("#entreprisePop").modal("hide");
                          this.utils.snack(
                            "Votre entrprise à été créée avec succès!"
                          );
                        },
                        (err) => {
                          console.log('erreur update profile', err)
                          this.submitted = false;
                        }
                      )
                      .catch((reason) => this.utils.setErrorsHttp(reason));
                      
                    this.snackBar.open("Entreprise créée avec succès", "Ok", {
                      duration: 8000,
                    });
                    this.onReadAll();
                    f.reset();
                  },
                  (err) => {
                    this.snackbar.open(
                      "Désolé un problème est survenu, \nveuillez réessayer plus tard",
                      "Ok",
                      { duration: 5000 }
                    );
                    console.log("erreur", err);
                   console.log('err entreprise', err)
                   this.submitted = false
                  }
                )
                .catch((reason) => this.utils.setErrorsHttp(reason));
            },
            (err) => {
              this.snackbar.open(
                "Désolé un problème est survenu, \nveuillez réessayer plus tard",
                "Ok",
                { duration: 5000 }
              );
            }
          )
          .catch((reason) => this.utils.setErrorsHttp(reason));
      }
    }
  }

  delete(ent) {
    this.toDelete = ent;
    this.toDelete.entDeleted = true;
    $("#entDelete").modal("show");
  }

  openDeletedDialog(ent): void {
    this.toDelete = ent;
    this.toDelete.entDeleted = true;

    const dialogRef = this.dialog.open(DeletedComponent, {
      width: "300px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.entService
          .update(
            this.toDelete.id,
            true,
            "",
            this.toDelete.email,
            this.toDelete.entrepriseMere.id,
            "",
            this.toDelete.raisonSociale,
            this.toDelete.siteWeb,
            this.toDelete.telephone,
            this.toDelete.adresse.id
          )
          .then(
            (res) => {
              for (let i = 0; i < this.allEnt.results.length; i++) {
                if (this.allEnt.results[i].id === this.toDelete.id) {
                  this.allEnt.results.splice(i, 1);
                }
              }
              this.dialog.getDialogById("wefly_loading_modale_").close();
            },
            (err) => {
              this.snackbar.open(
                "Désolé un problème est survenu, \nveuillez réessayer plus tard",
                "Ok",
                { duration: 5000 }
              );
              this.dialog.getDialogById("wefly_loading_modale_").close();
            }
          )
          .catch((reason) => this.utils.setErrorsHttp(reason));
      } else {
      }
    });
  }

  onReadAll() {
    this.getEnt(1);
  }

  onScroll(e) {}

  onDelete() {
    let c = confirm("supprimer?");
    if (c)
      this.entService
        .update(
          this.toDelete.id,
          true,
          "",
          this.toDelete.email,
          this.toDelete.entrepriseMere.id,
          "",
          this.toDelete.raisonSociale,
          this.toDelete.siteWeb,
          this.toDelete.telephone,
          this.toDelete.adresse.id
        )
        .then(
          (res) => {
            for (let i = 0; i < this.allEnt.results.length; i++) {
              if (this.allEnt.results[i].id === this.toDelete.id) {
                this.allEnt.results.splice(i, 1);
              }
            }
            $("#entDelete").modal("hide");
          },
          (err) => {
            this.snackbar.open(
              "Désolé un problème est survenu, \nveuillez réessayer plus tard",
              "Ok",
              { duration: 5000 }
            );
            $("#entDelete").modal("hide");
          }
        )
        .catch((reason) => this.utils.setErrorsHttp(reason));
  }

  gotsearchValue(a) {
    return a;
  }

  onDismis() {
    $("#entDelete").modal("hide");
  }

  onSubmitToUpdate(f: NgForm) {
    this.submitted = true;
    this.toDelete = f.value;
    this.toDelete.id = this.entId;
    this.toDelete.entreprise = this.entrepriseEntreprise;
    this.addressServ
      .update(
        this.addresseId,
        this.entPays,
        false,
        "",
        this.entRue,
        this.entVille
      )
      .then(
        (res: any) => {},
        (error) => {}
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
    this.entService
      .update(
        this.toDelete.id,
        false,
        "",
        f.value.entMail,
        "",
        this.entLogo,
        f.value.entRaisSociale,
        f.value.entWebsite,
        f.value.entTel,
        this.addresseId
      )
      .then(
        (res) => {
          this.submitted = false;
          this.onReadAll();
          this.updating = false;
          f.reset();
          this.snackBar.open("Entreprise modifiée avec succès", "Ok", {
            duration: 8000,
          });
        },
        (err) => {
          this.snackbar.open(
            "Désolé un problème est survenu, \nveuillez réessayer plus tard",
            "Ok",
            { duration: 5000 }
          );
        }
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }

  clickUpdate(ent) {
    this.updating = true;
    this.toDelete = ent;
    this.entId = ent.id;
    this.addresseId = ent.adresse.id;
    if (ent.entrepriseMere) {
      this.entrepriseEntreprise = ent.entrepriseMere.id;
    } else {
      this.entrepriseEntreprise = "";
    }
    this.entRaisSociale = ent.raisonSociale;
    this.entTel = ent.telephone;
    this.entMail = ent.email;
    this.entWebsite = ent.siteWeb;
    this.entRue = ent.adresse.rue;
    this.entVille = ent.adresse.ville;
    this.entPays = ent.adresse.pays.id;
  }

  addNew(f: NgForm) {
    f.reset();
    this.updating = false;
  }

  getEnt(page: number = 1, filter_field: any = null) {
    this.loading = true;
    let entRes: any;
    // let filter = 'deleted : false'
    if (this.route.snapshot.params["list"]) {
      this.entService.read().then(
        (res) => {
          this.loading = false;
          entRes = res;
          this.allEnt = entRes.searchEntreprises;
        },
        (err) => {
          return err;
        }
      );
    } else {
      //const username = this.utils.getUserToken().username.toString();
      const  username = (jwt_decode( this.utils.getStorage('weflyHeader_token')).username)
      this.usersv
        .read(`username: "${username}"`, 1, "id")
        .then(
          (res: any) => {
            if (res.searchUser.results[0].profile.entreprise !== null) {
              let entreprise = res.searchUser.results[0].profile.entreprise.id;
              let filter = `id : "${entreprise}", deleted : false`;
              if (filter_field != null && filter_field.field != null) {
                filter =
                  filter + `,  ${filter_field.field} : "${filter_field.value}"`;
              }
              this.entService
                .read(filter, page)
                .then(
                  (res) => {
                    this.loading = false;
                    entRes = res;
                    this.totalCount = entRes.searchEntreprises.totalCount;
                    this.allEnt = entRes.searchEntreprises;
                  },
                  (err) => {
                    return err;
                  }
                )
                .catch((reason) => this.utils.setErrorsHttp(reason));
            } else {
            }
          },
          (error) => {}
        )
        .catch((reason) => this.utils.setErrorsHttp(reason));
    }
  }

  processFile(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.selectedFile = reader.result;
        this.entLogo = reader.result;
      };
    } else {
      // tslint:disable-next-line:no-console
    }
  }

  readUser() {
    const  username = (jwt_decode( this.utils.getStorage('weflyHeader_token')).username)
    // const username = this.utils.getUserToken().username.toString();
    this.usersv
      .searchUserNew(new SearchUser(username))
      .then(
        (res: any) => {
          // console.clear();
          this.userEntreprise = res.data.searchUser.results[0];
          this.utils.setCookie("_wefly_",this.utils.encrypt(JSON.stringify(this.userEntreprise)),{ nb: 7, unite: 3 });
        },
        (err) => {}
      )
      .catch((reason) => this.utils.setErrorsHttp(reason));
  }

  onChangePage(data) {
    this.currentPage = data.id;
    this.getEnt(data.id);
  }

  filterData(data) {
    if (data.action === 0) {
      return;
      this.filter = data.data_;
    } else {
      this.loading = true;
      this.filter = data.data_;
      if (data.data_.value.length == 0) {
        this.getEnt(1);
      } else {
        this.getEnt(1, this.filter);
      }
    }
  }
}
