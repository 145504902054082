import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';
import * as gql from 'gql-query-builder';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AttributOptionsService {

  constructor(private utils: UtilsService, private http: HttpClient) { }
  create(
    attribut: String,
    deleted: Boolean,
    libelle: String,

  ) {
    const mquery = gql.mutation({
      operation: 'createAttributOptions',
      variables: {
        newAttributoptions: {
          value: {
          attribut,
          deleted,
          libelle,
          },
          type: 'AttributOptionsCreateGenericType!'
        }
      },
      fields: [
        {
          attributoptions: [
            'id',
            'libelle',
            'deleted'
          ]
        }]
    }
    );

    //     `
    //     mutation(
    // 			$attribut:  ID!,
    // 			$deleted:  Boolean!,
    // 			$libelle:  String!,
    //     ) {
    //       createAttributOptions(newAttributOptions: {
    // 				attribut: $attribut,
    // 				deleted: $deleted,
    // 				libelle: $libelle,
    //       }) {attributOptions{
    //  id,
    // 				attribut,
    // 				deleted,
    // 				libelle,
    // 				}      }
    //     }
    //     `;

    return new Promise(
      (resolve, reject) => {
        this.http.post(this.utils.HOST_URL, mquery).subscribe(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
  update(
    id: String,
    deleted: Boolean,
    libelle: String,
  ) {
    const mquery = gql.mutation({
      operation: 'updateAttributOptions',
      variables: {
        newAttributoptions: {
          value: {
          id,
          deleted,
          libelle,
          },
          type: 'AttributOptionsUpdateGenericType!'
        }
      },
      fields: [
        {
          attributoptions: [
            'id',
            'libelle',
          ]
        }]
    }
    );

    //     `
    //     mutation(
    // 			$attribut:  ID!,
    // 			$deleted:  Boolean!,
    // 			$libelle:  String!,
    //     ) {
    //       createAttributOptions(newAttributOptions: {
    // 				attribut: $attribut,
    // 				deleted: $deleted,
    // 				libelle: $libelle,
    //       }) {attributOptions{
    //  id,
    // 				attribut,
    // 				deleted,
    // 				libelle,
    // 				}      }
    //     }
    //     `;

    return new Promise(
      (resolve, reject) => {
        this.http.post(this.utils.HOST_URL, mquery).subscribe(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = `deleted : false`, page: number = 1, limit: number = this.utils.PAGINATION, order: string = 'id') {
    const query = `
    query($page: Int!, $order: String!) {
      searchAttributOptions(${filter}){
        results(page: $page, ordering: $order)
        {
        id,
				deleted,
				libelle,
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { limit, page, order }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

