import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';
import { resolve } from 'url';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private utils: UtilsService) { }

  createGroup(name: String) {
    const query = `
      mutation( $name : String!){
        createGroup(newGroup: {
          name : $name
        }){
          ok,
          errors{
            messages
          },
          group{
            id,
            name
          }
        }
      }`;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query, {
        name
      }).then(
        (res) => {
          if (res['createGroup']['ok']) {
            resolve(res['createGroup']['group'])
          }
          reject(res['createGroup']['errors'])
        },
        (error) => {
          this.utils.setErrorsHttp(error)
          reject(error)
        }
      )
    })
  }

  updateGroup(id: String, name: String) {
    const query = `
      mutation( $id: ID!, $name : String!){
        updateGroup(newGroup: {
          id : $id
          name : $name
        }){
          ok,
          errors{
            messages
          },
          group{
            id,
            name,
            permissions{
              id,
              name
            }
          }
        }
      }`;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query, {
        id, name
      }).then(
        (res) => {
          if (res['updateGroup']['ok']) {
            resolve(res['updateGroup']['group'])
          }
          reject(res['updateGroup']['errors'])
        },
        (error) => {
          this.utils.setErrorsHttp(error)
          reject(error)
        }
      )
    })
  }

  getPermissions(role) {
    const query = `
    query{
      searchRolePermissionSuite(role : "${role}", deleted : false){
        results{
          id,
          role{
            id,
            role{
              id,
              name
            }
          },
          permission{
            id,
            displayName,
            deleted,
            permission{
              id,
              name
            }
          }
        }
      }
    }
    `
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res['searchRolePermissionSuite']);
          },
          (error) => {
            this.utils.setErrorsHttp(error)
            reject(error)
          }
        )
      }
    )
  }

  create(entreprise: String, role: String) {
    const query = `
    mutation($entreprise : ID!, $role : ID!){
      createRoleSuite(newRolesuite: { entreprise : $entreprise, role : $role }){
        ok,
        errors{
          messages
        },
        rolesuite{
          id,
          entreprise{id}
          role{
            name,
            permissions{id,name}
          }
        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {entreprise, role}).then(
          (res) => {
            if (res['createRoleSuite']['ok']) {
              resolve(res['createRoleSuite']['rolesuite']);
            }
            reject(res['createRoleSuite']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(
    id: String,
    entreprise: String,
    role: String
  ) {
    const query = `
    mutation(
      $id: UUID!,
      $entreprise : ID!,
      $role : ID!
    ){
      updateRoleSuite(newRoleSuite: {
        id: $id,
        entreprise : $entreprise,
        role : $role
      }){
        ok,
        errors{
          messages
        },
        rolesuite{
          id,
          entreprise{
            id
          }
          role{
            name,
            permissions{
              id,
              name
            }
          }
        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id,
          entreprise, role
        }).then(
          (res) => {
            resolve(res['searchRoleSuite']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = `deleted : false`, page: number = 1, limit: number = this.utils.PAGINATION, order: string = 'id') {
    const query = `
    query($page: Int!, $order: String!) {
      searchRoleSuite(${filter}){
        totalCount
        results(page: $page, ordering: $order){
          #niveau,
          id,
          entreprise{
            id
          },
          role{
            id,
            name,
            permissions{
              id,
              name,
              codename
            }
          }
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { page, order }).then(
          (res) => {
            resolve(res['searchRoleSuite']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  createRolePermissionSuite(role, permission) {
    const query = `
      mutation($role : ID!, $permission : ID!){
        createRolePermissionSuite(newRolepermissionsuite : {
          role : $role,
          permission : $permission
        }){
          ok,
          rolepermissionsuite{
            id,
            role{
              id,
              role {
                id
              },
            },
            permission{
              id,
              displayName
            }
          }
        }
      }
    `
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          role, permission
        }).then(
          (res) => {
            resolve(res)
          },
          (error) => {
            this.utils.setErrorsHttp(error)
            reject(error)
          }
        )
      }
    )
  }

  readRolePermissionSuite(filter: string = `deleted : false`, page: number = 1, limit: number = this.utils.PAGINATION, order: string = 'id') {
    const query = `
      query($page: Int!, $order: String!){
        searchRolePermissionSuite(${filter}){
          results(page: $page, ordering: $order){
            id,
            permission{
              id
            },
            role{
              id,
              role{
                name
              }
            }
          }
        }
      }
    `
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { limit, page, order }).then(
          (res) => {
            resolve(res["searchRolePermissionSuite"])
          },
          (error) => {
            this.utils.setErrorsHttp(error)
            reject(error)
          }
        )
      }
    )
  }

  updatePermissionToRole(id) {
    const query = `
    mutation($id : UUID!){
      updateRolePermissionSuite(newRolepermissionsuite : {
        id : $id,
        deleted : true
      }){
        ok,
        rolepermissionsuite{
          id,
          deleted,
          role{
            id
          },
          permission{
            id
          }
        },
        errors{
          messages
        }
      }
    }
    `
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id
        }).then(
          (res) => {
            if (res['updateRolePermissionSuite']['ok']) {
              resolve(res['updateRolePermissionSuite']['rolepermissionsuite'])
            }
            reject(res['updateRolePermissionSuite']['errors'])
          },
          (error) => {
            this.utils.setErrorsHttp(error)
            reject(error)
          }
        )
      }
    )
  }

  readProfileRoleSuite(profile: String, page: number = 1) {
    const query = `
    query($profile : ID!){
      searchProfileRoleSuite(profile : $profile, deleted : false){
        totalCount
        results(page: ${page}){
          id,
          role{
            id,
            role{
              name
            }
          }
        }
      }
      searchRoleSuite{
        totalCount
        results{
          id,
          role{
            name
          }
        }
      }
    }
    `
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          profile,
        }).then(
          (res) => {
            resolve(res);
          },
          (error) => {
            this.utils.setErrorsHttp(error)
            reject(error);
          }
        )
      }
    )
  }

  createRoleProfileSuite(profile: String, role: String) {
    const query = `
      mutation($profile : ID!, $role : ID!){
        createProfileRoleSuite(newProfilerolesuite : {
          profile : $profile,
          role : $role
        }){
          ok,
          errors{
            messages
          }
          profilerolesuite{
            id,
            role{
              id,
              role{
                name
              }
            }
          }
        }
      }
    `
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          profile, role
        }).then(
          (res: any) => {
            if (res['createProfileRoleSuite']['ok']) {
              resolve(res['createProfileRoleSuite']['profilerolesuite']);
            } else {
              reject(res['createProfileRoleSuite']['errors']['messages']);
            }
          },
          (error) => {
            this.utils.setErrorsHttp(error)
            reject(error)
          }
        )
      }
    )
  }


  deleteRoleProfileSuite(id: String) {
    const query = `
      mutation($id : UUID){
        updateProfileRoleSuite(newProfilerolesuite : {
          id : $id,
          deleted : true
        }){
          ok,
          profilerolesuite{
            id,
            role{
              id,
              role{
                name
              }
            }
          },
          errors{
            messages
          }
        }
      }
    `

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id
        }).then(
          (res: any) => {
            if (res['updateProfileRoleSuite']['ok']) {
              resolve(res['updateProfileRoleSuite']['profilerolesuite']);
            } else {
              reject(res['updateProfileRoleSuite']['errors']['messages']);
            }
          },
          (error) => {
            this.utils.setErrorsHttp(error)
            reject(error)
          }
        )
      }
    )
  }
}

