import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';

@Injectable({
  providedIn: 'root'
})
export class FonctionService {

  constructor(private utils: UtilsService) { }
  create(
      description:  String,
      entreprise : String,
      libelle:  String,
  ) {
    const query = `
      mutation(
        $description:  String!,
      
        $libelle:  String!
        $entreprise : ID!
      ){
        createFonction(newFonction: {
            description: $description,
            
            libelle: $libelle,
            entreprise : $entreprise
          }){
            ok
            fonction{
              id,
              libelle,
              description,
              
            }
            errors{
              messages
            }
          }
        }
      `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          description,
          entreprise,
          libelle,
         
        }).then(
          (res) => {
            if(res['createFonction']['ok']){
              resolve(res['createFonction']['fonction'])
            }
            reject(res['createFonction']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
  update(
      id : String,
      description:  String,
      entreprise : String,
      libelle:  String,
     
      deleted: Boolean = false
    ) {
      const query = `
        mutation(
          $id: UUID!,
          $description: String!,
      
          $libelle: String!,
          $entreprise : ID!,
          $deleted: Boolean!
        ){
          updateFonction(newFonction: {
            id : $id,
            description: $description,
            
            libelle: $libelle,
            entreprise: $entreprise,
            deleted: $deleted
          }){
            ok
            fonction{
              id,
              libelle,
              description,
        
            }
            errors{
              messages
            }
          }
        }`;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id,
          description,
          entreprise,
          libelle,
          deleted
        }).then(
          (res) => {
            if(res['updateFonction']['ok']){
              resolve(res['updateFonction']['fonction'])
            }
            reject(res['updateFonction']['errors']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  readAllFonction(filter: string = `deleted : false`, page: number = 1, order: String='-created_at') {
    filter = this.utils.fullFilter(filter, "entreprise")
    const query = `
    query($page: Int!, $order: String!){
      searchFonction(${filter}){
        totalCount,
        results(page: $page, ordering: $order){
          id,
          description,
          etiquette
          libelle
        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {page , order}).then(
          (res) => {
            resolve(res['searchFonction']);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

