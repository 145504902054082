import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';

@Injectable({
  providedIn: 'root'
})
export class FormCoucheService {

  constructor(private utils: UtilsService) { }
  create(
    couche: String,
    deleted: Boolean,
    description: String,
    libelle: String,
  ) {
    const query = `
    mutation(
			$couche:  ID!,
			$deleted:  Boolean!,
			$description:  String!,
			$libelle:  String!,
    ) {
      createFormCouche(newFormcouche: {
				couche: $couche,
				deleted: $deleted,
				description: $description,
				libelle: $libelle,
      }) {formcouche{
				couche{
          id
          libelle
        }
				createdAt,
				deleted,
				description,
				id,
				libelle,
				updateAt,
				}      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          couche,
          deleted,
          description,
          libelle,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
  update(
    id: String,
    couche: String,
    deleted: Boolean,
    description: String,
    libelle: String,
  ) {
    const query = `
    mutation(
      $id: UUID!,
			$couche:  ID!,
			$deleted:  Boolean!,
			$description:  String!,
			$libelle:  String!,
    ) {
      updateFormcOuche(newFormcouche: {
        id: $id,
				couche: $couche,
				description: $description,
        libelle: $libelle,
        deleted: $deleted
      }) {formcouche{
        id,
				couche{
          id,
          libelle
        }
				createdAt,
				deleted,
				description,
				libelle,
				updateAt,
				}      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id,
          couche,
          deleted,
          description,
          libelle,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = `deleted : false`, page: number = 1, order: string = '-created_at') {
    const query = `
    query($page: Int!, $order: String!) {
      searchFormCouche(${filter}){
        totalCount
        results(page: $page, ordering: $order)
        {
        id,
        formschemajsonUrl,
        couche{
          id
          libelle
          typeCouche{
            id
            libelle
          }
        },
				createdAt,
				deleted,
				description,
				libelle,
				updateAt,
        }
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { page, order }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

