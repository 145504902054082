import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProfileService } from '../_services/profile.service';
import { FonctionService } from '../_services/fonction.service';
import { ProfileModel, FonctionModel } from '../models';
import 'jquery';
import 'bootstrap';
import { delay } from 'q';
import { UtilsService } from '../_services/utils.service';
declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  allFct: any;
  allPrf: any;
  prfUpdate: any;
  toDelete: any;
  nomProfile: any;
  prenomProfile: any;
  fonctionProfile: any;
  displaynameProfile: any;
  genreProfile: any;
  dateNaissanceProfile: any;
  fields: any = [];
  updting = false;
  updated = false;
  pageAct: any = 1;
  userInfo: any

  constructor(private profile: ProfileService, private fonction: FonctionService, private service: UtilsService) { }

  ngOnInit() {
    this.userInfo = JSON.parse(this.service.decrypt(this.service.getCookie('_wefly_')))
    this.fonction.readAllFonction(`deleted: false, entreprise: "${this.userInfo.profile.entreprise.id}"`).then(data => {
      this.allFct = data;
      this.allFct = this.allFct.queryAllFonction;
    });
    // this.onReadAll();
  }

  onSubmit(f: NgForm) {
  }

  onSubmitToUpdate(f: NgForm) {
    this.toDelete.nom = f.value.nom;
    this.toDelete.prenoms = f.value.prenom;
    this.toDelete.displayName = f.value.displayname;
    this.toDelete.dateNaissance = f.value.dateNaissance;
    this.toDelete.genre = f.value.genre;
    this.toDelete.fonction = f.value.fonction;
    this.toDelete.deleted = false;
  }

  deleteProfile(profile) {
    this.toDelete = profile;
    this.toDelete.deleted = true;
    $('#profileDelete').modal('show');
  }

  // onReadAll() {
  //   let prfRes: any;

  //   this.profile.read(`entreprise: "${this.userInfo.profile.entreprise.id}", deleted: false`,).then(
  //     (res) => {
  //       prfRes = res;
  //       this.allPrf = prfRes.queryAllProfile;
  //     }, (err) => {
  //     }
  //   ).catch(reason =>  this.service.setErrorsHttp(reason));
  // }

  // onScroll(e) {
  //   this.pageAct += 1;
  //   this.profile.read(`entreprise: "${this.userInfo.profile.entreprise.id}", deleted: false`).then(
  //     (res) => {
  //       const profs: any = res;
  //       this.allPrf = [...this.allPrf, ...profs.queryAllProfile];
  //     }, (err) => {
  //     }
  //   ).catch(reason =>  this.service.setErrorsHttp(reason));
  // }

  onDelete() {
    this.toDelete.deleted = true;
    this.toDelete.fonction = this.toDelete.fonction.id;
  }
  clickUpdate(profile) {
    profile.deleted = false;
    this.toDelete = profile;
    this.nomProfile = this.toDelete.nom;
    this.prenomProfile = this.toDelete.prenoms;
    this.fonctionProfile = this.toDelete.fonction;
    this.genreProfile = this.toDelete.genre;
    this.displaynameProfile = this.toDelete.displayName;
    this.dateNaissanceProfile = this.toDelete.dateNaissance;
    this.updting = true;
  }

  showhide() {
    (async () => {
      // Do something before delay
      this.updated = true;
      await delay(2000);
      // Do something after
      this.updated = false;
      this.updting = false;
    })();
  }
  /**
   * Ajout de nouveau
   */
  addNew(f: NgForm) {
    f.reset();
    this.updting = false;
  }

}
