import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class InformationsMainDoeuvreActeurPlantationService {

  constructor(private utils: UtilsService) { }

  create(acteur: String, deleted: Boolean, fourchettePaiement: String, identMobile: String, momentUtilMainDoeuvre: String, moyenPaiement: String, natureMainDoeuvre: String, origineMaindoeuvre: String, tempsTravailParJour: String, typeMainDoeuvre: String, typePaiement: String, user: String, ) {
	const dateCreationMobile = new Date();
	const query = `
    mutation(
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$deleted: Boolean,
		$fourchettePaiement: String,
		$identMobile: String,
		$momentUtilMainDoeuvre: String,
		$moyenPaiement: String,
		$natureMainDoeuvre: String,
		$origineMaindoeuvre: String,
		$tempsTravailParJour: String,
		$typeMainDoeuvre: String,
		$typePaiement: String,
		$user: ID,

	) {
      createInformationsMainDoeuvreActeurPlantation(newInformationsmaindoeuvreacteurplantation: {
			dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			deleted: $deleted,
			fourchettePaiement: $fourchettePaiement,
			identMobile: $identMobile,
			momentUtilMainDoeuvre: $momentUtilMainDoeuvre,
			moyenPaiement: $moyenPaiement,
			natureMainDoeuvre: $natureMainDoeuvre,
			origineMaindoeuvre: $origineMaindoeuvre,
			tempsTravailParJour: $tempsTravailParJour,
			typeMainDoeuvre: $typeMainDoeuvre,
			typePaiement: $typePaiement,
			user: $user,

		}) {
        informationsmaindoeuvreacteurplantation {
          id
					dateCreationMobile,
					acteur{ id },
					deleted,
					fourchettePaiement,
					identMobile,
					momentUtilMainDoeuvre,
					moyenPaiement,
					natureMainDoeuvre,
					origineMaindoeuvre,
					tempsTravailParJour,
					typeMainDoeuvre,
					typePaiement,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {dateCreationMobile,
					acteur,
					deleted,
					fourchettePaiement,
					identMobile,
					momentUtilMainDoeuvre,
					moyenPaiement,
					natureMainDoeuvre,
					origineMaindoeuvre,
					tempsTravailParJour,
					typeMainDoeuvre,
					typePaiement,
					user,
					}).then(
          (res) => {
            if (res['createInformationsMainDoeuvreActeurPlantation']['ok']) {
              resolve(res['createInformationsMainDoeuvreActeurPlantation']['informationsmaindoeuvreacteurplantation'])
            }
            reject(res['createInformationsMainDoeuvreActeurPlantation']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, dateCreationMobile: String, acteur: String, deleted: Boolean, fourchettePaiement: String, identMobile: String, momentUtilMainDoeuvre: String, moyenPaiement: String, natureMainDoeuvre: String, origineMaindoeuvre: String, tempsTravailParJour: String, typeMainDoeuvre: String, typePaiement: String, user: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$deleted: Boolean,
		$fourchettePaiement: String,
		$identMobile: String,
		$momentUtilMainDoeuvre: String,
		$moyenPaiement: String,
		$natureMainDoeuvre: String,
		$origineMaindoeuvre: String,
		$tempsTravailParJour: String,
		$typeMainDoeuvre: String,
		$typePaiement: String,
		$user: ID,
		) {
      updateInformationsMainDoeuvreActeurPlantation(newInformationsmaindoeuvreacteurplantation: {
				id: $id, dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			deleted: $deleted,
			fourchettePaiement: $fourchettePaiement,
			identMobile: $identMobile,
			momentUtilMainDoeuvre: $momentUtilMainDoeuvre,
			moyenPaiement: $moyenPaiement,
			natureMainDoeuvre: $natureMainDoeuvre,
			origineMaindoeuvre: $origineMaindoeuvre,
			tempsTravailParJour: $tempsTravailParJour,
			typeMainDoeuvre: $typeMainDoeuvre,
			typePaiement: $typePaiement,
			user: $user,
			}) {
        informationsmaindoeuvreacteurplantation {
          id
					dateCreationMobile,
					acteur{ id },
					deleted,
					fourchettePaiement,
					identMobile,
					momentUtilMainDoeuvre,
					moyenPaiement,
					natureMainDoeuvre,
					origineMaindoeuvre,
					tempsTravailParJour,
					typeMainDoeuvre,
					typePaiement,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, dateCreationMobile,
					acteur,
					deleted,
					fourchettePaiement,
					identMobile,
					momentUtilMainDoeuvre,
					moyenPaiement,
					natureMainDoeuvre,
					origineMaindoeuvre,
					tempsTravailParJour,
					typeMainDoeuvre,
					typePaiement,
					user,
					}).then(
          (res) => {
            if (res['updateInformationsMainDoeuvreActeurPlantation']['ok']) {
              resolve(res['updateInformationsMainDoeuvreActeurPlantation']['informationsmaindoeuvreacteurplantation'])
            }
            reject(res['updateInformationsMainDoeuvreActeurPlantation']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchInformationsMainDoeuvreActeurPlantation (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					dateCreationMobile,
					acteur{ id },
					deleted,
					fourchettePaiement,
					identMobile,
					momentUtilMainDoeuvre,
					moyenPaiement,
					natureMainDoeuvre,
					origineMaindoeuvre,
					tempsTravailParJour,
					typeMainDoeuvre,
					typePaiement,
					user{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

