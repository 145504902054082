import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";

@Injectable({
  providedIn: "root",
})
export class InformationProductionActeurPlantationService {
  constructor(private utils: UtilsService) {}

  create(
    dateProduction: String,
    acteur: String,
    deleted: Boolean,
    identMobile: String,
    qteProduite: Number,
    userProduction: String,
    typeAcheteur: String,
    nomAcheteur: String
  ) {
    const dateCreationMobile = new Date().toISOString().toString();
    const query = `
    mutation(
		$dateCreationMobile: CustomDateTime!,
		$dateProduction: CustomDateTime!,
		$acteur: ID,
		$deleted: Boolean,
		$identMobile: String,
		$qteProduite: Int,
    $userProduction: ID,
    $typeAcheteur: String,
    $nomAcheteur: String
	) {
      createInformationProductionActeurPlantation(newInformationproductionacteurplantation: {
			dateCreationMobile: $dateCreationMobile,
			dateProduction: $dateProduction,
			acteur: $acteur,
			deleted: $deleted,
			identMobile: $identMobile,
			qteProduite: $qteProduite,
			user: $userProduction,
      typeAcheteur: $typeAcheteur,
      nomAcheteur: $nomAcheteur
		}) {
        informationproductionacteurplantation {
          id
					dateCreationMobile,
					dateProduction,
					acteur{ id },
					deleted,
					identMobile,
					qteProduite,
					user{ id },
					createdAt,
          updateAt,
          nomAcheteur,
          typeAcheteur
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          dateCreationMobile,
          dateProduction,
          acteur,
          deleted,
          identMobile,
          qteProduite,
          userProduction,
          typeAcheteur,
          nomAcheteur,
        })
        .then(
          (res) => {
            if (res["createInformationProductionActeurPlantation"]["ok"]) {
              resolve(
                res["createInformationProductionActeurPlantation"][
                  "informationproductionacteurplantation"
                ]
              );
            }
            reject(
              res["createInformationProductionActeurPlantation"]["errors"]
            );
          },
          (err) => {
            this.utils.setErrorsHttp(err);
            reject(err);
          }
        );
    });
  }

  update(
    id: String,
    dateCreationMobile: String,
    dateProduction: String,
    acteur: String,
    deleted: Boolean,
    identMobile: String,
    qteProduite: Number,
    userProduction: String
  ) {
    const query = `
    mutation(
			$id: UUID!,
		$dateCreationMobile: CustomDateTime!,
		$dateProduction: CustomDateTime!,
		$acteur: ID,
		$deleted: Boolean,
		$identMobile: String,
		$qteProduite: Int,
		$userProduction: ID,
		) {
      updateInformationProductionActeurPlantation(newInformationproductionacteurplantation: {
				id: $id, dateCreationMobile: $dateCreationMobile,
			dateProduction: $dateProduction,
			acteur: $acteur,
			deleted: $deleted,
			identMobile: $identMobile,
			qteProduite: $qteProduite,
			userProduction: $userProduction,
			}) {
        informationproductionacteurplantation {
          id
					dateCreationMobile,
					dateProduction,
					acteur{ id },
					deleted,
					identMobile,
					qteProduite,
					userProduction{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          id,
          dateCreationMobile,
          dateProduction,
          acteur,
          deleted,
          identMobile,
          qteProduite,
          userProduction,
        })
        .then(
          (res) => {
            if (res["updateInformationProductionActeurPlantation"]["ok"]) {
              resolve(
                res["updateInformationProductionActeurPlantation"][
                  "informationproductionacteurplantation"
                ]
              );
            }
            reject(
              res["updateInformationProductionActeurPlantation"]["errors"]
            );
          },
          (err) => {
            this.utils.setErrorsHttp(err);
            reject(err);
          }
        );
    });
  }

  read(
    filter: string = "deleted: false",
    page: number = 1,
    order: String = "-created_at"
  ) {
    filter = filter == null || filter == "" ? "deleted: false" : filter;
    const query =
      `
    query {
      searchInformationProductionActeurPlantation (${filter}){
        results(page:` +
      page +
      `, ordering:` +
      order +
      `) {
          id
					dateCreationMobile,
					dateProduction,
					acteur{ id },
					deleted,
					identMobile,
					qteProduite,
					userProduction{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          this.utils.setErrorsHttp(err);
          reject(err);
        }
      );
    });
  }
}
