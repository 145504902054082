import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, AfterViewInit {
	@ViewChild('one', { static: false }) d1: ElementRef;
	@Input() htmlToAdd: string = null;
	constructor() { }

	ngOnInit() {
		// this.htmlToAdd = '<div class="two" style="background: red"><a href="https://facebook.com">clique</a></div>';
	}

	ngAfterViewInit() {
		this.d1.nativeElement.insertAdjacentHTML('beforeend', this.htmlToAdd);
	}
}


