import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/_services/user.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/_services/utils.service';
import { ConnexionService } from 'src/app/_services/connexion.service';

@Component({
  selector: 'app-reset-password-employe',
  templateUrl: './reset-password-employe.component.html',
  styleUrls: ['./reset-password-employe.component.scss']
})
export class ResetPasswordEmployeComponent implements OnInit {
  fields: any = [];
  error: any = {};
  userInfo: any;
  resetPassForm : FormGroup
  loader:boolean = false
  loginFieldAlert:boolean = false
  alertIdentiquePassWord:boolean = false
  oldPassErreur:boolean = false

  isPassword:string="password";
  isPassword1:string="password";
  isPassword2:string="password";
  show:boolean = false;
  show1:boolean = false;
  show2:boolean = false

  constructor(
    public dialogRef: MatDialogRef<ResetPasswordEmployeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userServ: UserService,
    private utils: UtilsService,
    private login: ConnexionService,
    private fb:FormBuilder
  ) {
    this.resetPassForm = this.fb.group({
      "oldPass":['', [Validators.required, Validators.minLength(8)]],
      "newPass":['', [Validators.required, Validators.minLength(8)]],
      "confNewPass":['',[Validators.required, Validators.minLength(8)]]
    })
   }

  ngOnInit() {
    console.log(this.data)
    this.isPassword = "password";
    this.userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
  }

  onNoClick(result: any): void {
    this.dialogRef.close(result);
  }



  resetPassword(id:string, newPassword:string) {
    this.userServ.resetPasswordByAdmin(id, newPassword).then(
      (res: any) => {
        console.log('resert pass ok', res)
        this.utils.snack('mot de pass modifié avec succès')
        this.loader = false
        this.dialogRef.close(true)
      }, (err) => {
        console.log('erreur reset pass', err)
      }
    )
  }
  onIsPassword() {
    this.show = !this.show;
    this.show === true? (this.isPassword = "text") : (this.isPassword = "password");

  }
  onIsPassword1() {

    this.show1 = !this.show1;
    this.show1 === true? (this.isPassword1 = "text") : (this.isPassword1 = "password");
   
  }
  onIsPassword2() {
    
    this.show2 = !this.show2;
    this.show2 === true? (this.isPassword2 = "text") : (this.isPassword2 = "password");
  }
  cancelDialog(){
    this.dialogRef.close()
  }
  resetPass(formData:FormGroup){
    console.log(formData)
    if(formData.valid){
      
      if(formData.value.newPass === formData.value.confNewPass){
        if(this.alertIdentiquePassWord || this.loginFieldAlert || this.oldPassErreur){
          this.alertIdentiquePassWord = false
          this.loginFieldAlert = false
          this.oldPassErreur = false
        }
        this.loader = true
        console.log('form value', formData.value)

        if(this.data.profile.admin){
          // RESET ADMIN
          
          this.login.connexion(this.userInfo.username, formData.value.oldPass).then(
            (res) => {
              console.log('conect reset ok', res)
              this.resetPassword(this.data.profile.user.id, formData.value.newPass)
             
            }, (err) => {
              console.log('erreur conect reset ok', JSON.stringify(err))
              this.loader = false
              this.oldPassErreur = true
            }
          )
        }
      }else{
        this.loader = false
        this.alertIdentiquePassWord = true
      }
    }else if(formData.invalid && formData.value.newPass && formData.value.confNewPass){
      if(formData.value.newPass === formData.value.confNewPass){
        if(this.alertIdentiquePassWord || this.loginFieldAlert){
          this.alertIdentiquePassWord = false
          this.loginFieldAlert = false
        }
        this.loader = true
        console.log('form ivalid', formData)
      this.resetPassword(this.data.profile.user.id, formData.value.newPass)
      }else{
        this.loader = false
        this.alertIdentiquePassWord = true
      }
    }
    else{
      this.loader = false
      this.loginFieldAlert = true
    }
    

  }
}
