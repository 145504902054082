import { UtilsService } from 'src/app/_services/utils.service';
import { ReponseOffreService } from './../_services/reponse-offre.service';
import { UniteDeMesureService } from './../../_services/unite-mesure.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-reponse-offre',
  templateUrl: './reponse-offre.component.html',
  styleUrls: ['./reponse-offre.component.scss']
})
export class ReponseOffreComponent implements OnInit {
  unites = [];
  fields = [];
  requesting = false;
  user: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ReponseOffreComponent>,
    private uniteService: UniteDeMesureService,
    private reponseService: ReponseOffreService,
    private utils: UtilsService) { }

  ngOnInit() {
   
    this.user = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')));
    this.getUnite();
  }
  getUnite() {
    this.uniteService.read().then(
      (res: []) => {
        this.unites = res;
      }
    );
  }

  submit(f) {
    this.requesting = true;
    this.reponseService.create(
      f.value.commentaire,
      false,
      this.data.instance.id,
      f.value.prix,
      f.value.qte,
      this.data.instance.region.id,
      f.value.unite,
      this.user.id
    ).then(
      (res) => {
        this.dialogRef.close(res);
      },
      (err) => {
       
        this.utils.setErrorsHttp(err);
        this.dialogRef.close(false);
      }
    );
  }
}
