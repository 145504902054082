import { Injectable } from "@angular/core";
import { UtilsService } from "../../_services/utils.service";
import { request } from "graphql-request";

@Injectable({
  providedIn: "root",
})
export class DemandeDeProductionService {
  constructor(private utils: UtilsService) {}

  create(
    dateDebut: Date,
    dateFin: Date,
    deleted: Boolean,
    description: String,
    libelle: String,
    region: number,
    uniteMesure: String,
    user: number,
    ville: String
  ) {
    const query = `
    mutation($dateDebut: CustomDateTime!,$dateFin: CustomDateTime!,$deleted: Boolean!,$description: String!,$libelle: String!,$region: ID!,$uniteMesure: ID!,$user: ID!,$ville: String!,) {
      createDemandedeproduction(newDemandedeproduction: {
				dateDebut: $dateDebut,
				dateFin: $dateFin,
				deleted: $deleted,
				description: $description,
				libelle: $libelle,
				region: $region,
				uniteMesure: $uniteMesure,
				user: $user,
				ville: $ville,
      }) {
        demandedeproduction{
          id,
				  dateDebut,
				  dateFin,
				  deleted,
				  description,
				  libelle,
				  region{ id },
				  uniteMesure {id},
				  user{ id },
				  ville,
        }
      }
    }
    `;

    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          dateDebut,
          dateFin,
          deleted,
          description,
          libelle,
          region,
          uniteMesure,
          user,
          ville,
        })
        .then(
          (res) => {
           
            // if (res['createDemandeproduction']['ok']) {
            resolve(res["createDemandedeproduction"]["demandedeproduction"]);
            // }
            // reject(res['createDemandeproduction']['errors']);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  update(
    id: String,
    dateDebut: Date,
    dateFin: Date,
    deleted: Boolean,
    description: String,
    libelle: String,
    region: number,
    uniteMesure: String,
    user: number,
    ville: String
  ) {
    const query = `
    mutation($id: UUID!, $dateDebut: CustomDateTime!, $dateFin: CustomDateTime!, $deleted: Boolean!, $description: String!, $libelle: String!, $region: ID!, $uniteMesure: ID!, $user: ID!, $ville: String!,) {
      updateDemandedeproduction(newDemandedeproduction: {
        id: $id,
				dateDebut: $dateDebut,
				dateFin: $dateFin,
				deleted: $deleted,
				description: $description,
				libelle: $libelle,
				region: $region,
				uniteMesure: $uniteMesure,
				user: $user,
				ville: $ville,
      }) {
        demandedeproduction{
          id,
				  dateDebut,
				  dateFin,
				  deleted,
				  description,
				  libelle,
				  region { id },
				  uniteMesure { id },
				  user { id },
				  ville,
        }
      }
    }
    `;

    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          id,
          dateDebut,
          dateFin,
          deleted,
          description,
          libelle,
          region,
          uniteMesure,
          user,
          ville,
        })
        .then(
          (res) => {
            // if (res['updateDemandeproduction']['ok']) {
             
              resolve(res['updateDemandedeproduction']['demandedeproduction'])
            // }
            // reject(res['updateDemandeproduction']['errors']);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  read(
    filter: string = `deleted : false`,
    page: number = 1,
    limit: number = this.utils.PAGINATION,
    order: string = "-created_at"
  ) {
    const query = `
    query($page: Int!, $order: String!) {
      searchDemandedeproduction(${filter}){
        totalCount,
        results(page: $page, ordering: $order) {
          id,
         	dateDebut,
				  dateFin,
				  deleted,
				  description,
	  			libelle,
          compositionDemandeDeProduction {
            id, culture { id, description, libelle, culture { id, libelle } }, qteDemandee
          }
          caracteristiquesDemandeDeProduction {
            id, prixAchat, qteGenDemandee,
          }
		  		region{ id, nomRegion },
			  	uniteMesure { id, libelle },
          user{ id
            profile {
              admin
              id,
              codeDeVerification,
              contact,
              deleted,
              displayName,
              genre,
              nom,
              prenom,
              entreprise {
                id,
                raisonSociale,
                telephone
                email
                categorie{
                  libelle,
                  numero
                }
              }
            }
          },
				  ville,
        }
      }
    }
    `;

    return new Promise((resolve, reject) => {
      this.utils.client.request(query, { limit, page, order }).then(
        (res:any) => {
          
          resolve(res.searchDemandedeproduction);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
