import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';
import { query, mutation, subscription } from 'gql-query-builder';
import * as gql from 'gql-query-builder';
import { HttpClient } from '@angular/common/http';
import { type } from 'os';
@Injectable({
  providedIn: 'root'
})
export class AttributService {

  constructor(private utils: UtilsService, private http: HttpClient) { }
  create(
    autogenere: Boolean,
    champsDeBase: Boolean,
    compartiment: String,
    libelle: String,
    libellebd: String,
    requis: Boolean,
    typeAttribut: String,
    deleted: Boolean,
  ) {
    const mquery = gql.mutation({
      operation: 'createAttribut',
      variables: {
        newAttribut: {
          value: {
            autogenere,
            champsDeBase,
            compartiment,
            libelle,
            libellebd,
            requis,
            typeAttribut,
            deleted,
          },
          type: 'AttributCreateGenericType!'
        }
      },
      fields: [
        {
          attribut: [
            'id',
            'libelle',
            'champsDeBase',
            'libellebd',
            'requis',
            'autogenere',
            'deleted',
            {
              compartiment: [
                'id',
                'libelle',
                'description',
                'deleted'
              ]
            },
            {
              typeAttribut : [
                'id',
                "libelle"
              ]
            },
            {
              attributsOptions : [
                "id",
                "libelle",
              ]
            }
          ]
        }
      ]
    });

    //     `
    //     mutation(
    // 			$autogenere:  Boolean!,
    // 			$champsDeBase:  Boolean!,
    // 			$compartiement:  ID!,
    // 			$libelle:  String!,
    // 			$libellebd:  String!,
    // 			$options:  String!,
    // 			$requis:  Boolean!,
    // 			$typeAttribut:  ID!,
    // 			$deleted:  Boolean!,
    //     ) {
    //       createAttribut(newAttribut: {
    // 				autogenere: $autogenere,
    // 				champsDeBase: $champsDeBase,
    // 				compartiement: $compartiement,
    // 				libelle: $libelle,
    // 				libellebd: $libellebd,
    // 				options: $options,
    // 				requis: $requis,
    // 				typeAttribut: $typeAttribut,
    // 				deleted: $deleted,
    //       }) {attribut{
    //  id,
    // 				autogenere,
    // 				champsDeBase,
    // 				compartiement,
    // 				libelle,
    // 				libellebd,
    // 				options,
    // 				requis,
    // 				typeAttribut,
    // 				deleted,
    // 				}      }
    //     }
    //    `;

    return new Promise(
      (resolve, reject) => {
        this.http.post(this.utils.HOST_URL, mquery).subscribe(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
  update(
    id: String,
    autogenere: Boolean,
    champsDeBase: Boolean,
    compartiment: String,
    libelle: String,
    libellebd: String,
    requis: Boolean,
    typeAttribut: String,
    deleted: Boolean,
  ) {
    const mquery = gql.mutation({
      operation: 'updateAttribut',
      variables: {
        newAttribut: {
          value: {
            id,
            autogenere,
            champsDeBase,
            compartiment,
            libelle,
            libellebd,
            requis,
            typeAttribut,
            deleted,
          },
          type: 'AttributUpdateGenericType!'
        }
      },
      fields: [
        {
          attribut: [
            'id',
            'libelle',
            'champsDeBase',
            'libellebd',
            'requis',
            'autogenere',
            'deleted',
            {
              compartiment: [
                'id',
                'libelle',
                'description',
                'deleted'
              ]
            },
            {
              typeAttribut : [
                'id',
                "libelle"
              ]
            },
            {
              attributsOptions : [
                "id",
                "libelle",
              ]
            }
          ]
        }
      ]
    });
    return new Promise(
      (resolve, reject) => {
        this.http.post(this.utils.HOST_URL, mquery).subscribe(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = `deleted : false`, page: number = 1, limit: number = this.utils.PAGINATION, order: string = '-created_at') {
    const mquery = `query($page: Int!, $order: String!) {
       searchAttribut(${filter}){
         totalCount
       results(page: $page, ordering: $order)
         {
        id,
        autogenere,
     		champsDeBase,
     		compartiment{
        id,
        libelle,
        description
         }
     		libelle,
     		libellebd,
     		requis,
     		typeAttribut{
           id,
           libelle
         }
         deleted,
         attributsOptions{
          id,
          libelle
        }
        }
       }
     }
     `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(mquery, {page, order}).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

