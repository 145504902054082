import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-layer-switch-btn',
  templateUrl: './layer-switch-btn.component.html',
  styleUrls: ['./layer-switch-btn.component.scss']
})
export class LayerSwitchBtnComponent implements OnInit {
  @Input() img: string;
  @Input() imgActive: string;
  @Input() lib: string;
  @Input() active: boolean;
  @Output() Switch: EventEmitter<string> = new EventEmitter<string>();
  isactive: boolean = false;
  isClick: boolean = false;
  constructor() { }
  ngOnInit() {

  }

  hover() {
    this.active = true;
  }

  leave() {
    if (!this.isClick) {
      this.active = false;
    }
  }

  switch() {
    this.isClick = true;
    this.active = true;
    this.Switch.emit();
  }

}
