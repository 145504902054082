import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class InformationsSourceRevActeurPersService {

  constructor(private utils: UtilsService) { }

  create(acteur: String, benefLastRecolte: Number, deleted: Boolean, identMobile: String, montantEpargne: Number, partDesRevenusOrigineActPrinc: String, revenusSuffisant: String, srceRevenuActPrinc: String, user: String, ) {
	const dateCreationMobile = new Date();
    const query = `
    mutation(
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$benefLastRecolte: Float,
		$deleted: Boolean,
		$identMobile: String,
		$montantEpargne: Int,
		$partDesRevenusOrigineActPrinc: String,
		$revenusSuffisant: String,
		$srceRevenuActPrinc: String,
		$user: ID
	) {
      createInformationsSourceRevActeurPers(newInformationsourcerevacteurpers: {
			dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			benefLastRecolte: $benefLastRecolte,
			deleted: $deleted,
			identMobile: $identMobile,
			montantEpargne: $montantEpargne,
			partDesRevenusOrigineActPrinc: $partDesRevenusOrigineActPrinc,
			revenusSuffisant: $revenusSuffisant,
			srceRevenuActPrinc: $srceRevenuActPrinc,
			user: $user,

		}) {
			informationsourcerevacteurpers {
          id
					dateCreationMobile,
					acteur{ id },
					benefLastRecolte,
					deleted,
					identMobile,
					montantEpargne,
					partDesRevenusOrigineActPrinc,
					revenusSuffisant,
					srceRevenuActPrinc,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {dateCreationMobile,
					acteur,
					benefLastRecolte,
					deleted,
					identMobile,
					montantEpargne,
					partDesRevenusOrigineActPrinc,
					revenusSuffisant,
					srceRevenuActPrinc,
					user,
					}).then(
          (res) => {
            if (res['createInformationsSourceRevActeurPers']['ok']) {
              resolve(res['createInformationsSourceRevActeurPers']['informationssourcerevacteurpers'])
            }
            reject(res['createInformationsSourceRevActeurPers']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, dateCreationMobile: String, acteur: String, benefLastRecolte: Number, deleted: Boolean, identMobile: String, montantEpargne: Number, partDesRevenusOrigineActPrinc: String, revenusSuffisant: Boolean, srceRevenuActPrinc: Boolean, user: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$dateCreationMobile: CustomDateTime!,
		$acteur: ID,
		$benefLastRecolte: Float,
		$deleted: Boolean,
		$identMobile: String,
		$montantEpargne: Int,
		$partDesRevenusOrigineActPrinc: String,
		$revenusSuffisant: Boolean,
		$srceRevenuActPrinc: Boolean,
		$user: ID,
		) {
      updateInformationsSourceRevActeurPers(newInformationssourcerevacteurpers: {
				id: $id, dateCreationMobile: $dateCreationMobile,
			acteur: $acteur,
			benefLastRecolte: $benefLastRecolte,
			deleted: $deleted,
			identMobile: $identMobile,
			montantEpargne: $montantEpargne,
			partDesRevenusOrigineActPrinc: $partDesRevenusOrigineActPrinc,
			revenusSuffisant: $revenusSuffisant,
			srceRevenuActPrinc: $srceRevenuActPrinc,
			user: $user,
			}) {
        informationssourcerevacteurpers {
          id
					dateCreationMobile,
					acteur{ id },
					benefLastRecolte,
					deleted,
					identMobile,
					montantEpargne,
					partDesRevenusOrigineActPrinc,
					revenusSuffisant,
					srceRevenuActPrinc,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, dateCreationMobile,
					acteur,
					benefLastRecolte,
					deleted,
					identMobile,
					montantEpargne,
					partDesRevenusOrigineActPrinc,
					revenusSuffisant,
					srceRevenuActPrinc,
					user,
					}).then(
          (res) => {
            if (res['updateInformationsSourceRevActeurPers']['ok']) {
              resolve(res['updateInformationsSourceRevActeurPers']['informationssourcerevacteurpers'])
            }
            reject(res['updateInformationsSourceRevActeurPers']['errors']);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchInformationsSourceRevActeurPers (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					dateCreationMobile,
					acteur{ id },
					benefLastRecolte,
					deleted,
					identMobile,
					montantEpargne,
					partDesRevenusOrigineActPrinc,
					revenusSuffisant,
					srceRevenuActPrinc,
					user{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
			this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

