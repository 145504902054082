import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';
import { ProfileService } from 'src/app/_services/profile.service';
import { TrackItemService } from 'src/app/_services/track-item.service';
import { StatisitiquesService } from 'src/app/_services/statisitiques.service';
import { TrackingComponent } from '../tracking/tracking.component';

declare var $;
declare var L: any;

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss'],
  providers: [TrackingComponent]
})

export class PerformanceComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
