import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { EmployeStatRead } from 'src/app/_models/user';
import { StatisitiquesService } from 'src/app/_services/statisitiques.service';
import { environment } from 'src/environments/environment';
import { EmployeFormComponent } from '../_modale/employe-form/employe-form.component';
import { ResetPasswordEmployeComponent } from '../_modale/reset-password-employe/reset-password-employe.component';

@Component({
  selector: 'app-new-employe-detail',
  templateUrl: './new-employe-detail.component.html',
  styleUrls: ['./new-employe-detail.component.scss']
})
export class NewEmployeDetailComponent implements OnInit {
  environment = environment
  loader:boolean = true
  
  statsEmploye:any
  photoEmploye:string  = this.environment.file + this.data.photoUrl  
  constructor( private statServ: StatisitiquesService,private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NewEmployeDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data)
    console.log(this.photoEmploye)
    this.readStatsemployes(this.data.user.id,this.data.entreprise.id)
  }

 readStatsemployes(employe: string, entreprise:string): void {
    this.statServ.NewReadStatsemployes(new EmployeStatRead(employe, entreprise)).then(
      (res: any) => {
        console.log('employe stat', res)
        this.loader = false
        
        if (res.data.searchStatsemployes.results.length === 0) {
          
          this.statsEmploye = {
            nbrePlanteurs: 0,
            nbrePlantations: 0,
            nbrePlantationsNotees: 0,
            supTotale: 0
          };
        } else {
          this.statsEmploye = res.data.searchStatsemployes.results[0];
        }
      }, (err) => {
        console.log('erreur employe stat', err)
      }
    );
  }

  closeDetail(){
    this.dialogRef.close()
  }

  updatePasse(data:any){

    const dialogRef = this.dialog.open(ResetPasswordEmployeComponent, {
      width: '750px',
      data: { profile: data }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log('passWord restok', result)
      }
    });
   
    
    }
  



}
