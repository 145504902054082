import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DeletedComponent } from '../_modale/deleted/deleted.component';
import { SubdivisionService } from '../_services/subdivision.service';


@Component({
  selector: 'app-village',
  templateUrl: './village.component.html',
  styleUrls: ['./village.component.scss']
})
export class VillageComponent implements OnInit {
  listVillage: any
  villageId:string
  submitted:boolean = false
  updateElementSelected:number
  loading:boolean = false
  filter:string | any = null

  totalCount: number = 0;
  maxOfPages: number = 20;
  currentPage: number = 1;
  showData = null
  fields_selected_: Array<any> = [
    { libelle_db: "libelle_Icontains", libelle: "Libelle" },
  ]

  

  villageForm: FormGroup;

  constructor(private fb: FormBuilder,
    private subDivisionService:SubdivisionService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) { 
    this.villageForm = this.fb.group({
      libelle:["", Validators.required]
    })
  }

  ngOnInit() {
    this.loading = true
    this.subDivisionService.searchVillage(this.currentPage,"").then(
      (res: any)=>{
        this.loading = false
        this.listVillage = res.searchSubdivision6.results
        this.totalCount = res.searchSubdivision6.totalCount
      },
      (err: any)=>{
      }
    )
  }
  onChangePage(data:any) {
    this.loading = true
    this.currentPage = data.id;
    this.subDivisionService.searchVillage(this.currentPage,"").then(
      (res: any)=>{
        this.loading = false
        this.listVillage = res.searchSubdivision6.results
        this.totalCount = res.searchSubdivision6.totalCount
      },
      (err: any)=>{
        this.loading = false
      }
    )
  }

  sendVillageLibelle(villagelabelle:FormGroup){
    this.submitted = true
    this.subDivisionService.createVillage(this.villageId,villagelabelle.value.libelle).then(
      (res: any)=>{
        this.submitted = false
        this.villageForm.reset()
        if(this.villageId){
          for(const obj of this.listVillage){
            if(obj.id === this.villageId){
              obj.libelle =res.updateSubdivision6.subdivision6.libelle
            }
          }
        }

        if(!this.villageId){
          this.listVillage.push(res.createSubdivision6.subdivision6)
        }
       
        
        this.snackBar.open('Village enregistré avec succès', 'Ok', { duration: 3000 });
      },
      (err)=>{
        this.submitted = false
        this.snackBar.open("Erreur d'enregistrement, veuillez réessayer", 'Ok', { duration: 3000 });
      }
    )
  }
  updateClick(village,index:number){
    this.villageForm = this.fb.group({
      libelle:[village.libelle,Validators.required]
    })
    this.villageId = village.id
    this.updateElementSelected = index
  }
  openDeletedDialog(village: any,index:number){
     const dialogRef = this.dialog.open(DeletedComponent,{
       width: '300px'
     });
     dialogRef.afterClosed().subscribe(
       (result:any) =>{
         if(result){
           this.subDivisionService.deleteVillage(village.id).then(
             (res:any)=>{
              this.snackBar.open('Village supprimé avec succès', 'Ok', { duration: 3000 });
               this.listVillage.splice(index, 1);
             }, (err: any) => {
              this.snackBar.open('Erreur de suppression', 'Ok', { duration: 3000 });
             }
           )
         }
       }
     )
  }

  filterData(value:string){
    this.loading = true
   this.filter = value;
   this.subDivisionService.searchVillage(null,value).then((res: any)=>{
     this.loading = false;
    this.listVillage = res.searchSubdivision6.results
   },(err: any) => {
     this.loading = false
   })
  }

}
