import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import 'bootstrap';
import 'jquery';
import { FormCoucheService } from '../_services/form-couche.service';
import { UtilsService } from '../_services/utils.service';
import { CoucheService } from '../_services/couche.service';
declare const $: any;

@Component({
  selector: 'app-formcouche',
  templateUrl: './formcouche.component.html',
  styleUrls: ['./formcouche.component.css']
})
export class FormcoucheComponent implements OnInit {
  @Input() showlist: any;
  constructor(
    private formServ: FormCoucheService,
    private utils: UtilsService,
    private snackBar: MatSnackBar,
    private coucheServ: CoucheService
  ) { }

  fields = [];
  updating = false;
  Couches: any = [];
  couche = {
    id: '',
    libelle: '',
    description: '',
    couche: ''
  };
  Fcouches: any = [];

  totalCount :  number = 0;
  maxOfPages : number = 25;
  currentPage : number = 1;

  userInfo: any;

  ngOnInit() {
    this.userInfo = JSON.parse(this.utils.decrypt(this.utils.getCookie('_wefly_')))
    this.getCouche();
  }

  getCouche(page : number = 1) {
    this.coucheServ.read(`entreprise: "${this.userInfo.profile.entreprise.id}", deleted: false`).then(
      (res: any) => {
        this.Couches = res.results;
        this.Couches.forEach(element => {
          this.formServ.read(`couche_Entreprise_Id: "${this.userInfo.profile.entreprise.id}", deleted: false`, page).then(
            (fres: any) => {
              console.clear();
              if (fres.searchFormCouche.results) {
                console.log('fres3',fres);
                this.Fcouches = fres.searchFormCouche.results
                this.totalCount = fres.searchFormCouche.totalCount
              }
            },
            (err) => {
            }
          );
        });
      },
      (err) => {
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason));
  }

  clickUpdate(mcouche) {
    this.updating = true;
    // this.couche = mcouche;
    this.couche.libelle = mcouche.libelle;
    this.couche.description = mcouche.description;
    this.couche.id = mcouche.id;
    this.couche.couche = mcouche.couche.id
  }

  onSubmitformCouch(f) {
    this.fields = this.utils.formIsNotNull(f, ['couche.libelle', 'couche.description', 'couche.couche']);
    if (this.fields.length === 0) {
      if (this.updating) {
        this.formServ.update(
          this.couche.id,
          this.couche.couche,
          false,
          this.couche.description,
          this.couche.libelle
        ).then(
          (res) => {
            this.updating = false;
            this.snackBar.open('Mise à jour effectuée avec succès', 'Ok', {
              duration: 5000
            });
            f.reset();
            this.getCouche();
          },
          (err) => {
          }
        ).catch(reason =>  this.utils.setErrorsHttp(reason));
      } else {
        console.clear();
        this.formServ.create(f.value.couche, false, f.value.description, f.value.libelle).then(
          (res: any) => {
            //  this.getCouche();
             this.Fcouches.unshift(res.createFormCouche.formcouche);
             this.totalCount += 1;
            this.snackBar.open('Formulaire Créée avec succès', 'Ok', { duration: 5000 });
          }, (err) => {
          }
        ).catch(reason =>  this.utils.setErrorsHttp(reason));
      }
    }

  }

  delete(mcouche) {
    this.couche.libelle = mcouche.libelle;
    this.couche.description = mcouche.description;
    this.couche.id = mcouche.id;
    this.couche.couche = mcouche.couche.id
    $('#fonctionDelete').modal('show');
  }

  onDelete() {
    this.formServ.update(
      this.couche.id,
      this.couche.couche,
      true,
      this.couche.description,
      this.couche.libelle
    ).then(
      (res) => {
        this.totalCount -= 1;
        this.snackBar.open('Suppression effectuée avec succès', 'Ok', {
          duration: 5000
        });
        this.getCouche();
        $('#fonctionDelete').modal('hide');
      },
      (err) => {
      }
    ).catch(reason =>  this.utils.setErrorsHttp(reason));
  }

  addNew(f) {
    f.reset();
  }

  onChangePage(data){
    this.currentPage = data.id;
    this.getCouche(data.id)
  }
}
