import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UtilsService } from "../_services/utils.service";
import { UserService } from "../_services/user.service";
import { ProfileService } from "../_services/profile.service";
import { ConnexionService } from "../_services/connexion.service";
import { MatDialog } from "@angular/material";
import { AlertDialogComponent } from "../alert-dialog/alert-dialog.component";
import { CreateProfil } from "../_models/user";

declare var $: any;

@Component({
  selector: "app-inscription",
  templateUrl: "./inscription.component.html",
  styleUrls: ["./inscription.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class InscriptionComponent implements OnInit {
  isLinear = false;
  alertField:boolean = false
  alertIdentiquePassWord:boolean = false
  isAlreadyMsg: string = 'Un utilisateur avec ce nom / email existe déjà.'
  alertError: boolean = false
  subDivision1: any;
  subDivision2: any;
  subDivision3: any;
  existUser: boolean = false;
  jour: number[] = [];
  mois: number[] = [];
  annee: number[] = [];
  nav = 1; // étape du formlaire à laquelle le user se trouve
  maxStep = 5; // nombre d'étape pour le formulaire
  toSend: any = {
    username: "",
    password: "",
    nom: "",
    prenom: "",
    email: "",
    contact: "",
    fonction: "",
    dateNais: "",
    genre: "",
    year: "",
    month: "",
    day: "",
  }; // se charge de sauvegarder les données envoyées par le formulaire à chaque étape
  fields: any = []; // contient tout les champs à problème
  step = [1, 2, 3, 4];
  msg: any = {};
  user: any = {
    userId: "",
    userRole: "",
    userActive: false,
    userStatus: false,
    userProfile: null,
    userDeleted: false,
    userUsername: "",
    userPassword: "",
    userSuperuser: false,
    userDisplayName: "",
  };
  loader = false;
  profile: any = {
    profileId: "",
    profilePhoto: "",
    profileNom: "",
    profilePrenom: "",
    profiledateNaissanceance: "",
    profileUser: "",
    profileDisplayName: "",
    profileContact: "",
    profileCategorie: "",
    profileGenre: "",
    profileMail: "",
    profileDeleted: "",
    profileFonction: "",
  };

  error: {
    view: Boolean;
    msg: String;
  }[] = [{ view: false, msg: "L'utilisateur ayant ce pseudo/mail existe." }];

  @Output() closeForm = new EventEmitter();
  checkbox: any;

  registerForm:FormGroup

  constructor(
    private router: Router,
    private utils: UtilsService,
    private userServ: UserService,
    private profilServ: ProfileService,
    private auth: ConnexionService,
    private fb:FormBuilder,
    private dialog:MatDialog
   
  ) { 

    this.registerForm = this.fb.group({
      "nom":['', Validators.required],
      "prenom":['', Validators.required],
      "email":['', [Validators.required, Validators.email]],
      "contact":['', [Validators.required, Validators.minLength(8)]],
      "date":['',Validators.required],
      "genre":['', Validators.required],
      "username":['', [Validators.required, Validators.minLength(5)]],
      "password":['', [Validators.required, Validators.minLength(8)]],
      "confpassword":['',[Validators.required, Validators.minLength(8)]]

    })

  }

  closeRegister() {
    this.closeForm.emit();
  }

  ngOnInit() {
    this.jour = this.utils.genTabNumber(31);
    this.mois = this.utils.genTabNumber(12);
    const nowYear = new Date().getFullYear();
    this.annee = this.utils.genTabNumber(nowYear - 18, nowYear - 75).reverse();
  }

  getConnectForm(){
    this.router.navigate(['/login'])
  }



  stepNextView() {
    const f: any = {};

    f.value = this.toSend;
    // console.log("données du formulaire:", f.value);
    this.fields = [];
    // tslint:disable-next-line:max-line-length
    this.fields = this.utils.formIsNotNull(f, [
      "username",
      "password",
      "nom",
      "prenom",
      "email",
      "contact",
      "genre",
    ]);
    // if (this.getCategorie === "-") {
    //   this.fields.push("fonction");
    // }
    if (this.fields.length === 0) {
      this.msg = {};
      for (const key in f.value) {
        // on parcours les éléments envoyés par le formulaire et on les sauvegardes dans toSend
        if (key === "contact") {
          // c'est à ce niveau qu'on vérifie le champs contact
          if (this.utils.regexTel(f.value[key])) {
            this.toSend[key] = f.value[key];
            // console.log('(format) numero correct');
          } else {
            this.msg.tel = "Le format du numéro est incorrect.";
            // console.error('(format) numéro incorrect:', f.value[key], '\n message:', this.msg);
          }
        } else if (key === "email") {
          // c'est à ce niveau qu'on vérifie le champs email
          if (this.utils.regexMail(f.value[key])) {
            // console.log('(format) email correct.');
            this.toSend[key] = f.value[key];
          } else {
            this.msg.mail = "Le format du mail est incorrect.";
            // console.error('(format) email incorrect:', f.value[key], '\n message:', this.msg);
          }
        } else if (key == "password") {
          if (this.utils.regexPassword(f.value["password"])) {
            this.toSend[key] = f.value[key];
            console.log("f.value[key]", f.value[key]);
            console.log(" this.toSend[key]", this.toSend[key]);

          } else {
            this.msg.password =
              "Le mot de passe doit avoir entre 8 et 25 caractères avec au moins une majuscule et un chiffre.";
          }
        } else if (key === "confpassword") {
          if (f.value["confpassword"] == f.value.password) {
            this.toSend[key] = f.value[key];
          } else {
            this.msg.confpassword =
              "Les mots de passe entrés doivent être identiques.";
          }
        } else if (key === "year") {
          if (f.value["month"] && f.value["year"] && f.value["day"]) {
            f.value["month"] =
              f.value["month"] < 10 ? "0" + f.value["month"] : f.value["month"];
            this.toSend["dateNais"] = new Date(
              f.value["year"],
              f.value["month"],
              f.value["day"]
            )
              .toISOString()
              .slice(0, 10);
          } else {
            console.clear();
            // console.log(
            //   f.value["month"] + " - " + f.value["year"] + " -" + f.value["day"]
            // );
            this.msg.dateNais = "Veuillez entrer votre date de naissance.";
          }
        } else if (key === "username") {
          let u = f.value[key];
          // if (u.length > 4) {
          //   this.userServ.existUser(u).then(
          //     (res: boolean) => {
          //       if (res == false) {
          //         this.toSend['username'] = u;
          //       } else {
          //         this.msg.username = "Ce pseudo est déjà pris."
          //       }
          //     }, (err) => {
          //       console.log(err);
          //     }
          //   )
          // }
          if (u.length < 4) {
            this.msg.username =
              "Votre nom d'utilisateur doit avoir au minimum 5 caractères.";
          }
        } else {
          this.toSend[key] = f.value[key];
        }
      }
      if (
        !this.msg.tel &&
        !this.msg.mail &&
        !this.msg.confpassword &&
        !this.msg.password &&
        !this.msg.dateNais &&
        !this.msg.username
      ) {
      } 0
      this.onSubmit();
      // console.log("to send =", this.toSend);
    } else {
      // console.log("long=", this.fields.length, this.fields, this.getCategorie);
    }
  }

  stepPrevView(step: number = 1) {
    this.fields = [];
    this.msg = {};
    // console.log("retour à l'étape:", step, "\ntosend=", this.toSend);
    this.nav = step;
  }
  // CREATE PROFILE
  onSubmit() {
    this.profile
    this.alertError = false
    /************** NEW INSCRIPTION *******************/
    // this.toSend.categorie = this.getCategorie;
    // console.log("A envoyer", this.toSend);
    this.loader = true;
    this.profile.profileNom = this.toSend.nom;
    this.profile.profileMail = this.toSend.email;
    this.profile.profilePhoto = "";
    this.profile.profileGenre = this.toSend.genre;
    this.profile.profilePrenom = this.toSend.prenom;
    this.profile.profileContact = this.toSend.contact;
    this.profile.profileCategorie = this.toSend.categorie;
    this.profile.profiledateNaissanceance = this.toSend.dateNais;
    this.user.userUsername = this.toSend.username;
    this.user.userPassword = this.toSend.password;

    if (
      this.toSend.password == this.toSend.confpassword &&
      this.toSend.password.length > 7
    ) {
      this.userServ
        .inscription(
          this.user.userUsername,
          this.profile.profileMail,
          this.profile.profileNom,
          this.profile.profilePrenom,
          this.user.userPassword
        )
        .then(
          (res: any) => {
            if (res.errors === null) {
             
              console.log('res inscription', res)
              // console.log(res);
              this.auth
                .connexion(this.user.userUsername, this.user.userPassword).then(
                  (resAuth: any) => {

                    console.log('res conection in inscription', resAuth)
                    this.utils.setStorage('weflyHeader_token', resAuth.tokenAuth.token)
                    // this.cookie.set('weflyHeader_token', resAuth.tokenAuth.token, this.utils.setExpired(), '/', '', false, 'Lax');
                    setTimeout(()=>{

                      this.profilServ
                      .create(
                        "",
                        this.profile.profileContact,
                        this.profile.profiledateNaissanceance,
                        false,
                        "",
                        "",
                        "",
                        this.profile.profileGenre,
                        this.profile.profileNom,
                        "",
                        this.profile.profilePhoto,
                        this.profile.profilePrenom,
                        false,
                        res.user.id,
                        "",
                      )
                      .then(
                        (profres: any) => {
                          console.log('user profile inscription', profres)
                          // console.log("____User___", profres);
                          sessionStorage.username = this.utils.encrypt(
                            this.user.userUsername
                          );
                          sessionStorage.password = this.utils.encrypt(
                            this.user.userPassword
                          );
                          if (profres.createProfile.profile) {
                            // console.log("profile creer");
                            this.loader = false;
                            // window.alert(
                            //   "Inscription Réussi veuiller consulter votre boite Email"
                            // );
                            this.router.navigate(['/login'])

                            // window.location.href = 'accueil?categorie=' + this.utils.encrypt(this.toSend.categorie)
                          }
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                    
                    },3000)
                    // this.utils.saveUserToken(resAuth.tokenAuth.token, "", "", "").then(
                    //   (reauth) => {
                        // tslint:disable-next-line: max-line-length
                        // console.log("profile", this.profile);
                        // tslint:disable-next-line: max-line-length

                      //},
                      // (err) => {
                      //   this.loader = false;
                      //   console.log(err);
                      // }
                    //)
                      // .catch((reason) => this.utils.setErrorsHttp(reason));
                  },
                  (err) => {
                    console.log(err);
                    this.loader = false;
                  }
                )
                .catch((reason) => this.utils.setErrorsHttp(reason));

            } else {
              this.loader = false;
              this.alertError = true
              console.log('erreur inscription', this.isAlreadyMsg)
            }

          },
          (err) => {
            console.log('erreur inscription', err)
            if (JSON.stringify(err).indexOf("Utilisateur existe déjà") > -1) {
              // this.error[0].view = true;
              this.msg.userExistant = "L'utilisateur ayant ce pseudo/mail existe.";
              this.loader = false;
            } else if (
              JSON.stringify(err).indexOf("Error: Not enough segments") > -1
            ) {
              // window.location.href = 'accueil?categorie=' + this.toSend.categorie;
            } else {
              console.log(err);
              this.alertError = true
              this.loader = false;
            }
          }
        );
    } else {
      this.nav = 2;
      this.loader = false;
    }


  }

  sendRegisterForm(registerData:FormGroup){
    let registervalue = registerData.value
    if(registerData.valid){
     
      if(registerData.value.password === registerData.value.confpassword){
        this.loader = true
        if(this.alertIdentiquePassWord || this.alertError){
          this.alertIdentiquePassWord = false
          this.alertError = false
        }
        this.userServ.inscription(
          registervalue.username,
          registervalue.email,
          registervalue.prenom,
          registervalue.nom,
          registervalue.password).then(
          (res: any) => {
            if (res.errors === null) {
              console.log('res inscription', res)

              this.auth.connexion(registervalue.username, registervalue.password).then(
                (resAuth: any) => {

                  console.log('res conection in inscription', resAuth)
                  this.utils.setStorage('weflyHeader_token', resAuth.tokenAuth.token)
                   setTimeout(()=>{
                    this.profilServ.createUserProfile(new CreateProfil(registervalue.contact,registervalue.date,false,registervalue.genre,registervalue.nom,registervalue.prenom,res.user.id)).then(
                      (profres: any) => {
                        console.log('user profile inscription', profres)
                      
                        sessionStorage.username = this.utils.encrypt(
                          registervalue.username
                        );
                        sessionStorage.password = this.utils.encrypt( 
                          registervalue.password
                        );
                        if (profres.data.createProfile.profile) {
                          this.loader = false;
                          const dialogRef = this.dialog.open(AlertDialogComponent,{
                            width:"30rem",
                            data:{alertTitle:"FÉLICITATIONS!", msg:"Félicitations, votre inscription a été effectuée avec succès.", }
                          })
                          dialogRef.afterClosed().subscribe(()=>{
                            this.router.navigate(['/login'])
                          })
                        }
                        this.loader = false
                      },
                      (err) => {
                        this.loader = false
                        console.log(err);
                      }
                      )

                   }, 1000)
                
                })

            } else {
              this.loader = false;
              this.alertError = true
              console.log('erreur inscription', this.isAlreadyMsg)
            }
          },(err:any)=>{
            this.alertError = true
            console.log(err)
            this.loader = false
          })
        console.log(registerData.value)
        console.log(registerData.status);
      }else{
        this.alertIdentiquePassWord = true
      }
    }else{
      this.alertField = true
    }

     
    
  }





}


