import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../_services/utils.service';

@Component({
  selector: 'app-page-dashboard',
  templateUrl: './page-dashboard.component.html',
  styleUrls: ['./page-dashboard.component.scss']
})
export class PageDashboardComponent implements OnInit {
  rapportList: any = [];

  constructor(public utils: UtilsService) { }

  ngOnInit() {
  }
  public gotsearchValue(e): any {}


}
