import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
	providedIn: 'root'
})
export class InformationsFamilialesActeurPersService {

	constructor(private utils: UtilsService) { }

	create(acteur: String, concubinage: String, deleted: Boolean, nbreConcubines: Number, nbreDepouses: Number, nbreFillesScol: Number, nbreGarconsScol: Number, nbrePersACharge: Number, nbreTotalEnf: Number, sitMat: String, user: String, ) {
		const dateCreationMobile = new Date();
		const query = `
    mutation(
		$acteur: ID,
		$concubinage: String,
		$deleted: Boolean,
		$nbreConcubines: Int,
		$nbreDepouses: Int,
		$nbreFillesScol: Int,
		$nbreGarconsScol: Int,
		$nbrePersACharge: Int,
		$nbreTotalEnf: Int,
		$sitMat: String,
		$user: ID,
		$dateCreationMobile: CustomDateTime!
	) {
		createInformationsFamillialesActeurPers(newInformationsfamilialesacteurpers: {
			acteur: $acteur,
			concubinage: $concubinage,
			deleted: $deleted,
			nbreConcubines: $nbreConcubines,
			nbreDepouses: $nbreDepouses,
			nbreFillesScol: $nbreFillesScol,
			nbreGarconsScol: $nbreGarconsScol,
			nbrePersACharge: $nbrePersACharge,
			nbreTotalEnf: $nbreTotalEnf,
			sitMat: $sitMat,
			user: $user,
			dateCreationMobile: $dateCreationMobile
		}) {
        informationsfamilialesacteurpers {
          id
			dateCreationMobile,
			acteur{ id },
			concubinage,
			deleted,
			identMobile,
			nbreConcubines,
			nbreDepouses,
			nbreFillesScol,
			nbreGarconsScol,
			nbrePersACharge,
			nbreTotalEnf,
			sitMat,
			user{ id },
			createdAt
			updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query, {
					dateCreationMobile,
					acteur,
					concubinage,
					deleted,
					nbreConcubines,
					nbreDepouses,
					nbreFillesScol,
					nbreGarconsScol,
					nbrePersACharge,
					nbreTotalEnf,
					sitMat,
					user,
				}).then(
					(res) => {
						if (res['createInformationsFamillialesActeurPers']['ok']) {
							resolve(res['createInformationsFamillialesActeurPers']['informationsfamilialesacteurpers'])
						}
						reject(res['createInformationsFamillialesActeurPers']['errors']);
					}, (err) => {
						this.utils.setErrorsHttp(err)
						reject(err);
					}
				);
			}
		);
	}

	update(id: String, acteur: String, concubinage: Boolean, deleted: Boolean, nbreConcubines: Number, nbreDepouses: Number, nbreFillesScol: Number, nbreGarconsScol: Number, nbrePersACharge: Number, nbreTotalEnf: Number, sitMat: String, user: String, ) {
		const query = `
    mutation(
			$id: UUID!,
		$acteur: ID,
		$concubinage: Boolean,
		$deleted: Boolean,
		$nbreConcubines: Int,
		$nbreDepouses: Int,
		$nbreFillesScol: Int,
		$nbreGarconsScol: Int,
		$nbrePersACharge: Int,
		$nbreTotalEnf: Int,
		$sitMat: String,
		$user: ID,
		) {
      updateInformationsFamilialesActeurPers(newInformationsfamilialesacteurpers: {
				id: $id,
			acteur: $acteur,
			concubinage: $concubinage,
			deleted: $deleted,
			nbreConcubines: $nbreConcubines,
			nbreDepouses: $nbreDepouses,
			nbreFillesScol: $nbreFillesScol,
			nbreGarconsScol: $nbreGarconsScol,
			nbrePersACharge: $nbrePersACharge,
			nbreTotalEnf: $nbreTotalEnf,
			sitMat: $sitMat,
			user: $user,
			}) {
        informationsfamilialesacteurpers {
          id
					dateCreationMobile,
					acteur{ id },
					concubinage,
					deleted,
					identMobile,
					nbreConcubines,
					nbreDepouses,
					nbreFillesScol,
					nbreGarconsScol,
					nbrePersACharge,
					nbreTotalEnf,
					sitMat,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query, {
					id,
					acteur,
					concubinage,
					deleted,
					nbreConcubines,
					nbreDepouses,
					nbreFillesScol,
					nbreGarconsScol,
					nbrePersACharge,
					nbreTotalEnf,
					sitMat,
					user,
				}).then(
					(res) => {
						if (res['updateInformationsFamilialesActeurPers']['ok']) {
							resolve(res['updateInformationsFamilialesActeurPers']['informationsfamilialesacteurpers'])
						}
						reject(res['updateInformationsFamilialesActeurPers']['errors']);
					}, (err) => {
						this.utils.setErrorsHttp(err)
						reject(err);
					}
				);
			}
		);
	}

	read(filter: string = 'deleted: false', page: number = 1, order: String = '-created_at') {
		filter = (filter == null || filter == '') ? 'deleted: false' : filter;
		const query = `
    query {
      searchInformationsFamilialesActeurPers (${filter}){
        results(page:` + page + `, ordering:` + order + `) {
          id
					dateCreationMobile,
					acteur{ id },
					concubinage,
					deleted,
					identMobile,
					nbreConcubines,
					nbreDepouses,
					nbreFillesScol,
					nbreGarconsScol,
					nbrePersACharge,
					nbreTotalEnf,
					sitMat,
					user{ id },
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
		return new Promise(
			(resolve, reject) => {
				this.utils.client.request(query).then(
					(res) => {
						resolve(res);
					}, (err) => {
						this.utils.setErrorsHttp(err)
						reject(err);
					}
				);
			}
		);
	}
}

