import { ErrorMsgComponent } from './../../_modale/error-msg/error-msg.component';
import { SuccessMsgComponent } from './../../_modale/success-msg/success-msg.component';
import { ReponseOffreComponent } from './../reponse-offre/reponse-offre.component';
import { Component, OnInit, Input, OnDestroy, ErrorHandler } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/_services/utils.service';
import { UserService } from 'src/app/_services/user.service';
import { PanierService } from '../_services/panier.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatSnackBar, MatDialog } from '@angular/material';
import * as moment from 'moment';
@Component({
  selector: 'app-article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.css']
})
export class ArticleItemComponent implements OnInit {
  @Input() data: any;
  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  Acheter() {
    const dialogRef = this.dialog.open(ReponseOffreComponent,
      {
        width: '35rem',
        data: { instance: this.data }
      });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.dialog.open(SuccessMsgComponent,
            {
              width: '25rem',
              data: {
                msg: 'Votre commande à été enregistrée avec succès. Un administrateur vous contactera pour les détails de la transaction. vous pouvez également suivre votre commande dans la rubrique tableau de bord'
              }
            });
        }else{
          this.dialog.open(ErrorMsgComponent,
            {
              width: '25rem',
              data: {
                msg: 'Une erreur est survenue veuillez réessayer plus tard.'
              }
            })
        }
      }
    )
  }
}
