import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pagineFilter'
})
export class PagineFilterPipe implements PipeTransform {

  transform(items : any[], startIndex : number = 1, endIndex  : number = 5): any[] {
    if (!items) return [];
    return items.filter(value => value.id >= startIndex &&  value.id <= endIndex)
  }
}
