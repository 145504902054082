import { Component, OnInit } from '@angular/core';
import { StatisitiquesService } from '../_services/statisitiques.service';
import { UtilsService } from '../_services/utils.service';

@Component({
  selector: 'app-trafic',
  templateUrl: './trafic.component.html',
  styleUrls: ['./trafic.component.css']
})
export class TraficComponent implements OnInit {
  coutLocal: any = [];
  coutInter: any = [];

  constructor(
    private utils: UtilsService,
    private statServ: StatisitiquesService,
  ) { }

  ngOnInit() {
    this.readCoutLocalmp();
  }

  public readCoutLocalmp(): void {
    this.statServ.readCoutLocalmp().then(
      (res: any) => {
        this.coutLocal = res;
        this.coutLocal.results.splice(2, this.coutLocal.results.length-1)
        this.coutLocal.results.forEach((elt, i) => {
          let traite = (this.utils.replaceAll('P', 'p', this.utils.replaceAll('\'', '"', this.utils.replaceAll('é', 'e', elt.detailLocal))))
          this.coutLocal.results[i].detailLocal = JSON.parse(traite);
        });
      }, (err) => {
      }
    );

    this.statServ.readCoursintermp().then(
      (res: any) => {
        this.coutInter = res;
        this.coutInter.results.splice(1, this.coutInter.results.length-1)
        this.coutInter.results.forEach((elt, i) => {
          let traite = (this.utils.replaceAll('P', 'p', this.utils.replaceAll('A', 'a', this.utils.replaceAll('\'', '"', this.utils.replaceAll(' ', '', elt.detailsInter)))))
          this.coutInter.results[i].detailsInter = JSON.parse(traite);
        });
       
      }, (err) => {
      }
    );
  }
}
