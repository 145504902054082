import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';

@Injectable({
  providedIn: 'root'
})
export class InformationDelactPlantationService {

  constructor(private utils: UtilsService) { }
  delimitationQuery = `queryData : {
    informationDelimitationActeurPlantationNomGuide : "",
    informationDelimitationActeurPlantationUser : "",
    informationDelimitationActeurPlantationActeurConc : "",
    informationDelimitationActeurPlantationTelPhone : "",
    informationDelimitationActeurPlantationTypeDeDelimitation : ""
    informationDelimitationActeurPlantationRang : "",
    informationDelimitationActeurPlantationErosion : true,
    informationDelimitationActeurPlantationCourDEau : true,
    informationDelimitationActeurPlantationTypeCourEau : "",
    informationDelimitationActeurPlantationGeometrie : "",
    informationDelimitationActeurPlantationTempsDelim : "",
    informationDelimitationActeurPlantationSurface : 12
    informationDelimitationActeurPlantationPerimetre : 20
    informationDelimitationActeurPlantationPointDrone : "",
    informationDelimitationActeurPlantationDateDelimitation: "",
    informationDelimitationActeurPlantationIdentMobile : "",
    informationDelimitationActeurPlantationDateCreationMobile: DateTime ,
    informationDelimitationActeurPlantationDeleted : true
  }){
    informationDelimitationActeurPlantationId,
    informationDelimitationActeurPlantationNomGuide,
    informationDelimitationActeurPlantationUser{
      // User
    },
    informationDelimitationActeurPlantationActeurConc{
      // Acteur
    },
    informationDelimitationActeurPlantationTelPhone
    informationDelimitationActeurPlantationTypeDeDelimitation
    informationDelimitationActeurPlantationRang
    informationDelimitationActeurPlantationErosion
    informationDelimitationActeurPlantationCourDEau
    informationDelimitationActeurPlantationTypeCourEau
    informationDelimitationActeurPlantationGeometrie
    informationDelimitationActeurPlantationTempsDelim
    informationDelimitationActeurPlantationSurface
    informationDelimitationActeurPlantationPerimetre
    informationDelimitationActeurPlantationPointDrone
    informationDelimitationActeurPlantationDateDelimitation
    informationDelimitationActeurPlantationIdentMobile
    informationDelimitationActeurPlantationDateCreationMobile
    informationDelimitationActeurPlantationDeleted,
    status,
  }
}`;

  readInformationDelimitation(filter: string = `{op:"and", value: [{field:"information_delact_plantation_deleted", op:"=", value:"false"}]}`, ) {

    const pagination = this.utils.PAGINATION;
    const query = `
  query($pagination: Int!) {
    searchInformationDelimitationActeurPlantation(queryData:{
      data: [
        {
          family: "information_delimitation_acteur_plantation_table"
          select: []
          queryFilter: [
            ` + filter + `
          ]
        }
      ]
      pagination: {
        limit: $pagination,
        page: 1,
        orderBy: {
          field: "information_delimitation_acteur_plantation_id"
          value: "ASC"
        }
      }
    })
    {
      data {
    informationDelimitationActeurPlantationId
		informationDelimitationActeurPlantationNomGuide
		informationDelimitationActeurPlantationTelPhone
		informationDelimitationActeurPlantationTypeDeDelimitation
		informationDelimitationActeurPlantationRang
		informationDelimitationActeurPlantationErosion
		informationDelimitationActeurPlantationCourDEau
		informationDelimitationActeurPlantationTypeCourEau
		informationDelimitationActeurPlantationGeometrie
		informationDelimitationActeurPlantationTempsDelim
		informationDelimitationActeurPlantationSurface
		informationDelimitationActeurPlantationPerimetre
		informationDelimitationActeurPlantationPointDrone
		informationDelimitationActeurPlantationDateDelimitation
		informationDelimitationActeurPlantationIdentMobile
		informationDelimitationActeurPlantationDateCreationMobile
		informationDelimitationActeurPlantationDeleted
    },
    status,
    }`;
    const variables = {
      pagination
    };
    return new Promise(
      (resolve, reject) => {
        request(this.utils.HOST_URL, query, variables).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  createInformationDelimitation(data) {
    const informationDelimitationActeurPlantationNomGuide = data.nomguide;
    const informationDelimitationActeurPlantationUser = data.plantationUser;
    const informationDelimitationActeurPlantationActeurConc = data.acteurConcerne;
    const informationDelimitationActeurPlantationTelPhone = data.acteurTel;
    const informationDelimitationActeurPlantationTypeDeDelimitation = data.typeDelimitation;
    const informationDelimitationActeurPlantationErosion = data.plantationErosion;
    const informationDelimitationActeurPlantationCourDEau = data.plantationCourdeau;
    const informationDelimitationActeurPlantationTypeCourEau = data.plantationtypeCourdeau;
    const informationDelimitationActeurPlantationGeometrie = data.plantationGeometrie;
    const informationDelimitationActeurPlantationTempsDelim = data.plantationDelim;
    const informationDelimitationActeurPlantationSurface = data.plantationSurface;
    const informationDelimitationActeurPlantationPerimetre = data.plantationPerimetre;
    const informationDelimitationActeurPlantationPointDrone = data.pointdrone;
    const informationDelimitationActeurPlantationDateDelimitation = data.dateDelimitation;
    const informationDelimitationActeurPlantationIdentMobile = data.indentMobile;
    const informationDelimitationActeurPlantationDateCreationMobile = data.dateCrea;
    const informationDelimitationActeurPlantationDeleted = data.plantationDeleted;
    const query = `
   query()
   {
      createInformationDelimitationActeurPlantation(` + this.delimitationQuery;
    const variables = {
      informationDelimitationActeurPlantationNomGuide,
      informationDelimitationActeurPlantationUser,
      informationDelimitationActeurPlantationActeurConc,
      informationDelimitationActeurPlantationTelPhone,
      informationDelimitationActeurPlantationTypeDeDelimitation,
      informationDelimitationActeurPlantationErosion,
      informationDelimitationActeurPlantationCourDEau,
      informationDelimitationActeurPlantationTypeCourEau,
      informationDelimitationActeurPlantationGeometrie,
      informationDelimitationActeurPlantationTempsDelim,
      informationDelimitationActeurPlantationSurface,
      informationDelimitationActeurPlantationPerimetre,
      informationDelimitationActeurPlantationPointDrone,
      informationDelimitationActeurPlantationDateDelimitation,
      informationDelimitationActeurPlantationIdentMobile,
      informationDelimitationActeurPlantationDateCreationMobile,
      informationDelimitationActeurPlantationDeleted,
    };
    return new Promise(
      (resolve, reject) => {
        request(this.utils.HOST_URL, query, variables).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  updateInformationDelimitation(data) {
    const informationDelimitationActeurPlantationNomGuide = data.nomguide;
    const informationDelimitationActeurPlantationUser = data.plantationUser;
    const informationDelimitationActeurPlantationActeurConc = data.acteurConcerne;
    const informationDelimitationActeurPlantationTelPhone = data.acteurTel;
    const informationDelimitationActeurPlantationTypeDeDelimitation = data.typeDelimitation;
    const informationDelimitationActeurPlantationErosion = data.plantationErosion;
    const informationDelimitationActeurPlantationCourDEau = data.plantationCourdeau;
    const informationDelimitationActeurPlantationTypeCourEau = data.plantationtypeCourdeau;
    const informationDelimitationActeurPlantationGeometrie = data.plantationGeometrie;
    const informationDelimitationActeurPlantationTempsDelim = data.plantationDelim;
    const informationDelimitationActeurPlantationSurface = data.plantationSurface;
    const informationDelimitationActeurPlantationPerimetre = data.plantationPerimetre;
    const informationDelimitationActeurPlantationPointDrone = data.pointdrone;
    const informationDelimitationActeurPlantationDateDelimitation = data.dateDelimitation;
    const informationDelimitationActeurPlantationIdentMobile = data.indentMobile;
    const informationDelimitationActeurPlantationDateCreationMobile = data.dateCrea;
    const informationDelimitationActeurPlantationDeleted = data.plantationDeleted;
    const query = `
   query()
   {
    updateInformationDelimitationActeurPlantation(` + this.delimitationQuery;
    const variables = {
      informationDelimitationActeurPlantationNomGuide,
      informationDelimitationActeurPlantationUser,
      informationDelimitationActeurPlantationActeurConc,
      informationDelimitationActeurPlantationTelPhone,
      informationDelimitationActeurPlantationTypeDeDelimitation,
      informationDelimitationActeurPlantationErosion,
      informationDelimitationActeurPlantationCourDEau,
      informationDelimitationActeurPlantationTypeCourEau,
      informationDelimitationActeurPlantationGeometrie,
      informationDelimitationActeurPlantationTempsDelim,
      informationDelimitationActeurPlantationSurface,
      informationDelimitationActeurPlantationPerimetre,
      informationDelimitationActeurPlantationPointDrone,
      informationDelimitationActeurPlantationDateDelimitation,
      informationDelimitationActeurPlantationIdentMobile,
      informationDelimitationActeurPlantationDateCreationMobile,
      informationDelimitationActeurPlantationDeleted,
    };
    return new Promise(
      (resolve, reject) => {
        request(this.utils.HOST_URL, query, variables).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err);
            reject(err);
          }
        );
      }
    );
  }
}
