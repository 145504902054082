// export const domaine = 'http://46.101.252.174:8014'
// export const domaine = 'https://dev.back.jool-id.com'
export const domaine = 'https://prod.back.new.jool-id.com'

export const environment = {
  domaine: domaine,
  production: false,
  url: domaine + "/graphql/",
  file:domaine +'/media/',
  hmr: false,
  weathe_url: " http://adb.jool-tech.com/_db/db/weather_api/",
  filliable: [
    "dateCreationMobile",
    "identMobile",
    "updateAt",
    "deleted",
    "id",
    "photo",
    "geometrie",
    "imageParcelle",
    "imageParcelle",
    "pointDrone",
    "imageParcelleUrl",
    "photoUrl",
    "kml",
    "typeDeDelimitation",
    "rang",
    "tempsDelim",
  ],
  correspondance: {
    acteurPerso: "Personnelles",
    acteurFamilliale: "Famille",
    acteurFormation: "Formation",
    acteurInfoSociopro: "Socio - professionnelle",
    acteurGen: "Général",
    acteurMaindoeuvre: "Main d'Oeuvre",
    acteurEntretien: "Entretien",
    acteurProductionplantation: "Production",
    acteurDelimitation: "Delimitation",
    acteurQltevie: "Qualité de vie",
    acteurOrga: "Organisation",
    acteurNotation: "Notation",
    acteurEnfant: "Enfants du planteur",
    acteurEngagement: "Engagements",
    acteurBancaire: "Banques & Financement",
    acteurAnnexePerso: "Fichiers annexes",
    prodplant: "Production du planteur",
    prodplantation: "Productions par plantation",
    acteurSrceRev: "Source de revenus",
    acteur: "Acteur",
    autrePiece: "Autre pièce",
    codePlanteur: "Code planteur",
    contact: "Contact",
    dateNaissance: "Date naissance",
    genre: "Genre",
    nationalite: "Nationalite",
    nom: "Nom",
    createdAt: "Date d'enregistrement",
    numeroDePiece: "Numero de piece",
    origine: "Origine",
    photo: "Photo",
    prenoms: "Prenoms",
    section: "Section",
    typePiece: "Type pièce",
    concubinage: "Concubinage",
    nbreConcubinage: "Nombre concubines",
    nbreDepouses: "Nombre d'épouses",
    nbreFillesScol: "Nombre de fille(s) scolarisée(s)",
    nbreGarconsScol: "Nombre de garçon(s) scolarisé(s)",
    nbrePersACharge: "Nombre de personne(s) à charge",
    nbreTotalEnf: "Nombre total d'enfant(s)",
    sitMat: "Situation matrimoniale",
    ameliorationTravail: "Amelioration du travail",
    certification: "Certification",
    dateCertif: "Date de certification",
    nivDEtude: "Niveau d'etude",
    nombreDeSessionFormation: "Nombre de session formation",
    nonScolarise: "Non scolarisé",
    participationFormation: "Participation formation",
    preuveCertifImage: "Preuve de la certification en image",
    raisonInutile: "Raison pour laquelle la formation est inutile",
    activitePrincipale: "Activité principale",
    activiteSecondaire: "Activité secondaire",
    anneesDExperienceActPrinc: "Années d'expérience acteur principal",
    anneesDExperienceActSec: "Années d'expérience activité secondaire",
    anneeObtentionParcelle: "Année d 'obtention de la parcelle",
    codePlantation: "Code de la plantation",
    modeAcquisitionParcelle: "Mode d'acquisition de la parcelle",
    modeGestionParcelle: "Mode de gestion de la parcelle",
    preuveProprietaire: "Preuve de la proprietaire",
    fourchettePaiement: "Fourchette de paiement",
    momentUtilMainDoeuvre: "Moments d'utilisation de la main d'oeuvre",
    moyenPaiement: "Moyen paiement de la main d'oeuvre",
    natureMainDoeuvre: "Nature de la main d'oeuvre",
    origineMaindoeuvre: "Origine de la main d'oeuvre",
    tempsTravailParJour: "Temps de travail par jour",
    typeMainDoeuvre: "Type de main d'oeuvre",
    typePaiement: "Type de paiement",
    achetePar: "Acheter par",
    coutAngrais: "Coût angrais",
    freqTraitementPhyto: "Fréquence de traitement phyto",
    lieuAchatAngrais: "Lieu d'achat des angrais",
    nomOrgAchat: "Nom de l'organisation d'achat de l'angrais",
    periodeTraitementPhyto: "Période de traitement phyto",
    qteAngrais: "Quantité d'angrais",
    traitementPhyto: "Traitement phyto",
    typeAngrais: "Type d'angrais",
    utilisationAngrais: "Utilisation d'angrais",
    dateProduction: "Date de production",
    qteProduite: "Quantité produite",
    courDEau: "Cours d'eau",
    dateDelimitation: "Date de délimitation",
    erosion: "Erosion",
    geometrie: "Géometrie",
    imageParcelle: "Image parcelle",
    kml: "KML",
    nomGuide: "Nom du guide",
    perimetre: "Périmetre",
    pointDrone: "Point drone",
    rang: "Rang",
    surface: "Surface",
    telPhone: "Téléphone",
    tempsDelim: "Temps délimitation",
    typeCourEau: "Type cours d'au",
    typeDeDelimitation: "Type de délimitation",
    distanceMaisonEcole: "Distance entre la maison et l'école",
    distanceMaisonHospis: "Distance entre la maison et l'hospital",
    moyenDeDeplacement: "Moyen de déplacement",
    natureDuLogement: "Nature du logement",
    natureDuLogementAutre: "Nature du logement autre",
    natureToitDuLogement: "Nature toît du logement",
    nbrePiecesMenage: "Nombre de pièces du menage",
    sourceDeau: "Source d'eau",
    sourceDEclairage: "Source d'éclairage",
    statutOccupationLogement: "Statut de l'occupation logement",
    appartenanceCoop: "Appartenance à une coopérative",
    dateEntreeCoop: "Date d'entrée dans une coopérative",
    desirAppartenanceCoop: "Desir appartenance coopérative",
    niveauSatisfaction: "Niveau de satisfaction",
    prkwPasSatisfaisant: "Pourquoi n'êtes vous pas satisfait?",
    receptionEquipementAnneeEnCours:
      "Réception d'équipement au cours de cette année",
    receptionIntrantAnneeEnCours: "Réception d'intrant cours de cette année",
    acces: "Accessiblité à la plantation",
    commentaire: "Commentaire",
    densite: "Densité",
    nettoyage: "Nettoyage",
    santePlantes: "Santé des plantes",
    actionRemediation: "Action remédiation",
    actionsSociales: "Actions sociales",
    classeSiScolarise: "Classe(si scolarisé)",
    codeEnfant: "Code enfant",
    quellesActionsSociales: "Quelles actions sociales",
    statutEnfant: "Statut enfant",
    statutSuivi: "Statut suivi",
    statutSurPlantation: "Statut de l'enfant sur plantation",
    travauxExecutes: "Travaux executés par l'enfant",
    benefLastRecolte: "Bénefice de la dernière récolte",
    montantEpargne: "Montant épargne",
    partDesRevenusOrigineActPrinc:
      "Part des revenus provenant de l' acteur principal",
    revenusSuffisant: "Revenus suffisant",
    srceRevenuActPrinc: "Source de revenu de l'acteur principal",
    engagementWeflyUrl: "Engagement Wefly",
    engagementClientUrl: "Engagement Client",
    numeroCompte: "Numéro de compte",
    numeroRib: "RIB",
    bulletinPayeUrl: "Bulletin de paye",
    ficheControleUrl: "Fiche de contrôle",
    attestionPlantationUrl: "Attestation de la plantation",
    statutCompte: "Statut du compte",
    nomBanque: "Nom de la banque",
    imageRibUrl: "Fiche RIB",
    annexe1Url: "Annex1",
    annexe1Libelle: "Annex1",
    annexe2Url: "Annexe2",
    annexe2Libelle: "Annexe2",
    annexe3Url: "Annexe3",
    annexe3Libelle: "Annexe3",
    annexe4Url: "Annexe4",
    annexe4Libelle: "Annexe4",
    annexe5Url: "Annexe5",
    annexe5Libelle: "Annexe5",
    annexe6Url: "Annexe6",
    annexe6Libelle: "Annexe6",
    profession: "Profession",
    village: "Village",
    chefVillage: "Chef de village",
    natureDemande: "Nature de la demande",
    typeAcheteur: "Type acheteur",
    nomAcheteur: "Nom acheteur",
    autreCodePlanteur: "Code de préinscription",
    blanc1Sociopro: "Si agriculteur, qu'est-ce que le membre cultive?",
    blanc1Formation:
      "Si le membre n’a jamais reçu de formation, veut-il en avoir ?",
  },
  libelleBd: {
    blanc1_formation: "blanc1Formation",
    annee_d_experience_act_secondaire: "anneesDExperienceActSec",
    autre_code_planteur: "autreCodePlanteur",
    identMobile: "identMobile",
    autre_piece: "autrePiece",
    code_planteur: "codePlanteur",
    contact: "contact",
    date_naiss: "dateNaissance",
    genre: "genre",
    nationalite: "nationalite",
    nom: "nom",
    numero_de_piece: "numeroDePiece",
    origine: "origine",
    photo: "photo",
    prenoms: "prenoms",
    section: "section",
    type_piece: "typePiece",
    concubinage: "concubinage",
    nbre_concubinage: "nbreConcubines",
    nbre_epouses: "nbreDepouses",
    nbre_filles_scol: "nbreFillesScol",
    nbre_garcons_scol: "nbreGarconsScol",
    nbre_pers_a_charge: "nbrePersACharge",
    nbre_total_enf: "nbreTotalEnf",
    sit_mat: "sitMat",
    amelioration_travail: "ameliorationTravail",
    certification: "certification",
    date_certif: "dateCertif",
    niv_d_etude: "nivDEtude",
    nombre_de_session_formation: "nombreDeSessionFormation",
    non_scolarise: "nonScolarise",
    part_des_revenus_origine_act_princ: "",
    participation_formation: "participationFormation",
    preuve_certif_image: "preuveCertifImage",
    raison_inutile: "raisonInutile",
    activite_principale: "activitePrincipale",
    activite_secondaire: "activiteSecondaire",
    annees_d_experience_act_princ: "anneesDExperienceActPrinc",
    annees_d_experience_act_sec: "anneesDExperienceActSec",
    annee_obtention_parcelle: "anneeObtentionParcelle",
    code_plantation: "codePlantation",
    mode_acquisition_parcelle: "modeAcquisitionParcelle",
    mode_gestion_parcelle: "modeGestionParcelle",
    preuve_proprietaire: "preuveProprietaire",
    fourchette_paiement: "fourchettePaiement",
    moment_util_main_doeuvre: "momentUtilMainDoeuvre",
    moyen_paiement: "moyenPaiement",
    nature_main_doeuvre: "natureMainDoeuvre",
    origine_maindoeuvre: "origineMaindoeuvre",
    temps_travail_par_jour: "tempsTravailParJour",
    type_main_doeuvre: "typeMainDoeuvre",
    type_paiement: "typePaiement",
    achete_par: "achetePar",
    cout_angrais: "coutAngrais",
    freq_traitement_phyto: "freqTraitementPhyto",
    lieu_achat_angrais: "lieuAchatAngrais",
    nom_org_achat: "nomOrgAchat",
    periode_traitement: "periodeTraitementPhyto",
    qte_angrais: "qteAngrais",
    traitement_phyto: "traitementPhyto",
    type_angrais: "typeAngrais",
    utilisation_angrais: "utilisationAngrais",
    date_production: "dateProduction",
    qte_produite: "qteProduite",
    cour_d_eau: "courDEau",
    date_delimitation: "dateDelimitation",
    erosion: "erosion",
    geometrie: "geometrie",
    image_parcelle: "imageParcelle",
    photoUrl: "photoUrl",
    kml: "kml",
    nom_guide: "nomGuide",
    perimetre: "perimetre",
    point_drone: "pointDrone",
    rang: "rang",
    surface: "surface",
    tel_phone: "telPhone",
    temps_delim: "tempsDelim",
    type_cour_eau: "typeCourEau",
    type_de_delimitation: "typeDeDelimitation",
    distance_maison_ecole: "distanceMaisonEcole",
    distance_maison_hopital: "distanceMaisonHospis",
    moyen_de_deplacement: "moyenDeDeplacement",
    nature_du_logement: "natureDuLogement",
    nature_du_logement_autre: "natureDuLogementAutre",
    nature_toit_du_logement: "natureToitDuLogement",
    nbre_pieces_menage: "nbrePiecesMenage",
    nbre_depouses: "nbreDepouses",
    blanc1_sociopro: "blanc1Sociopro",
    source_d_eau: "sourceDEau",
    source_d_eclairage: "sourceDEclairage",
    statut_occupation_logement: "statutOccupationLogement",
    appartenance_coop: "appartenanceCoop",
    date_entree_coop: "dateEntreeCoop",
    desir_appartenance_coop: "desirAppartenanceCoop",
    niveau_satisfaction: "niveauSatisfaction",
    prkw_pas_satisfaisant: "prkwPasSatisfaisant",
    reception_equipement_annee_en_cours: "receptionEquipementAnneeEnCours",
    reception_intrant_annee_en_cours: "receptionIntrantAnneeEnCours",
    acces: "access",
    commentaire: "commentaire",
    densite: "densite",
    nettoyage: "nettoyage",
    sante_plante: "santePlantes",
    action_remediations: "actionRemediation",
    action_socilae: "actionsSociales",
    classe_si_scolarise: "classeSiScolarise",
    code_enfants: "codeEnfant",
    quelles_actions_sociales: "quellesActionsSociales",
    statut_enfant: "statutEnfant",
    statut: "statutSuivi",
    statut_sur_plantation: "statutSurPlantation",
    travaux_executes: "travauxExecutes",
    benef_last_recolte: "benefLastRecolte",
    epargne: "montantEpargne",
    par_revenu: "partDesRevenusOrigineActPrinc",
    revenus_suffisant: "revenusSuffisant",
    srce_revenu_autre_act_princ: "srceRevenuActPrinc",
    cultures: "culture",
    culture: "culture",
    engagement_wefly_url: "engagementWeflyUrl",
    engagement_client_url: "engagementClientUrl",
    engagement_client: "engagementClient",
    engagement_wefly: "engagementWefly",
    numero_compte: "numeroCompte",
    numero_rib: "numeroRib",
    bulletin_paye_url: "bulletinPayeUrl",
    fiche_controle_url: "ficheControleUrl",
    attestion_plantation_url: "attestionPlantationUrl",
    bulletin_paye: "bulletinPaye",
    fiche_controle: "ficheControle",
    attestion_plantation: "attestionPlantation",
    image_rib: "imageRib",
    statut_compte: "statutCompte",
    nom_banque: "nomBanque",
    image_rib_url: "imageRibUrl",
    annexe1_url: "annexe1Url",
    annexe1_libelle: "annexe1Libelle",
    annexe2_url: "annexe2Url",
    annexe2_libelle: "annexe2Libelle",
    annexe3_url: "annexe3Url",
    annexe3_libelle: "annexe3Libelle",
    annexe4_url: "annexe4Url",
    annexe4_libelle: "annexe4Libelle",
    annexe5_url: "annexe5Url",
    annexe5_libelle: "annexe5Libelle",
    annexe6_url: "annexe6Url",
    annexe6_libelle: "annexe6Libelle",
    annexe1: "annexe1",
    annexe2: "annexe2",
    annexe3: "annexe3",
    annexe4: "annexe4",
    annexe5: "annexe5",
    annexe6: "annexe6",
    nature_demande: "natureDemande",
    profession: "profession",
    type_acheteur: "typeAcheteur",
    nom_acheteur: "nomAcheteur",
  },
  MARKET_DATA: [
    {
      type: "Feature",
      properties: {
        libelle: "Maïs Violet",
        description: "Très bon mais de korogo",
        disponibilite: "Immédiat",
        unite_de_mesure: "Tonnes",
        image: "../assets/img/mais_violet.jpg",
        code_vente: "KK-605",
      },
      geometry: {
        type: "Point",
        coordinates: [-104.99404, 39.75621],
      },
    },
    {
      type: "Feature",
      properties: {
        libelle: "Maïs Violet",
        description: "Très bon mais de korogo",
        disponibilite: "Immédiat",
        unite_de_mesure: "Tonnes",
        image: "../assets/img/mais_violet.jpg",
        code_vente: "KK-605",
      },
      geometry: {
        type: "Point",
        coordinates: [-104.99404, 39.75621],
      },
    },
    {
      type: "Feature",
      properties: {
        libelle: "Maïs Violet",
        description: "Très bon mais de korogo",
        disponibilite: "Immédiat",
        unite_de_mesure: "Tonnes",
        image: "../assets/img/mais_violet.jpg",
        code_vente: "KK-605",
      },
      geometry: {
        type: "Point",
        coordinates: [-104.99404, 39.75621],
      },
    },
  ],
};

export const CREATE_STOCK = `
    mutation($culture : ID, $description : String, $libelle : String, $nature : String, $numStock : String, $prixUnitaire : String, $qteDispo : Float, $user : ID){
      createStock(
        newStock : {
          culture : $culture,
          description: $description,
          libelle: $libelle,
          nature: $nature,
          numStock: $numStock,
          prixUnitaire: $prixUnitaire,
          qteDispo: $qteDispo
          user: $user
        }){
          ok,
          errors{
            field,
            messages
          },
          stock{
            id
          }
        }
      }
    `;

export const UPDATE_STOCK = `
    mutation(id : String, $culture : ID, $description : String, $libelle : String, $nature : String, $numStock : String, $prixUnitaire : String, $qteDispo : Float, $user : ID, $deleted : Boolean){
      updateStock(
        newStock : {
          id : String,
          culture : $culture,
          description: $description,
          libelle: $libelle,
          nature: $nature,
          numStock: $numStock,
          prixUnitaire: $prixUnitaire,
          qteDispo: $qteDispo
          user: $user,
          deleted : $deleted
        }){
          ok,
          errors{
            field,
            messages
          },
          stock{
            id
          }
        }
      }
    `;

export const READ_STOCK = `
    query($page: Int!, $order: String!){
      searchStock{
        totalCount,
        results(page: $page, ordering: $order){
          id,
          nature,
          status,
          user{
            id
          },
          culture{
            id
          },
          libelle,
          numStock,
          description,
          qteDispo,
          prixUnitaire
        }
      }
    }
  `;

export const READ_VARIETE_CULTURE = `
    query{
      searchVarieteParCultures{
        results{
          libelle,
          id
        }
      }
    }
  `;

export const CREATE_COMMANDE = `
    mutation($user : ID){
      createCommande(newCommande: {
        user : $user
      }){
        ok,
        errors{
          messages
        }
        commande{
          id
        }
      }
    }
  `;

export const CREATE_COMPOSER = `
    mutation($commande : ID, $stock : ID, $qteCom : Float){
      createComposer(newComposer : {
        commande : $commande,
        stock : $stock,
        qteCom : $qteCom
      }){
        composer{
          commande{
            id
          }
        }
      }
    }
  `;
