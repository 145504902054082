import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-weather-day',
  templateUrl: './weather-day.component.html',
  styleUrls: ['./weather-day.component.scss']
})
export class WeatherDayComponent implements OnInit {
  @Input() open: boolean = false;
  @Input() weather: any | null;
  @Input() weatherHebdo: any | null;
  @Input() weatherIcon: any;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()
 
  constructor() { }

  ngOnInit() {
  }

  closeWeather(){
    this.close.emit(true)
  }

}
