import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { request } from 'graphql-request';

@Injectable({
  providedIn: 'root'
})
export class CompartimentService {

  constructor(private utils: UtilsService) { }
  create(
    description: String,
    libelle: String,
    libelledb: String,
    typeCouche: String,
    comicone: String,
    deleted: Boolean,
  ) {
    const query = `
    mutation(
			$description:  String!,
			$libelle:  String!,
			$libelledb:  String!,
      $typeCouche:  ID!,
      $comicone: String!,
			$deleted:  Boolean!,
    ) {
      createCompartiment(newCompartiment: {
				description: $description,
				libelle: $libelle,
				libelledb: $libelledb,
        typeCouche: $typeCouche,
        comicone: $comicone,
				deleted: $deleted,
      }) {compartiment{
        id,
				description,
				libelle,
				libelledb,
				typeCouche{
          id,
          libelle,
          description
        }
        comicone
				deleted,
				}      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          description,
          libelle,
          libelledb,
          typeCouche,
          comicone,
          deleted,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
  update(id: String,
    description: String,
    libelle: String,
    libelledb: String,
    typeCouche: String,
    comicone: String,
    deleted: Boolean,
  ) {
    const query = `
    mutation($id: UUID!,
			$description:  String!,
			$libelle:  String!,
			$libelledb:  String!,
      $typeCouche:  ID!,
      $comicone: String!,
			$deleted:  Boolean!,
    ) {
      updateCompartiment(newCompartiment: {
        id: $id,
				description: $description,
				libelle: $libelle,
				libelledb: $libelledb,
        typeCouche: $typeCouche,
        comicone: $comicone,
				deleted: $deleted,
      }) {compartiment{
        id,
				description,
				libelle,
				libelledb,
				typeCouche{
          id,
          libelle,
          description
        }
        comicone
				deleted,
				}      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {
          id,
          description,
          libelle,
          libelledb,
          typeCouche,
          comicone,
          deleted,
        }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = `deleted : false`, page: number = 1, order: string = 'id') {
    const query = `
    query($page: Int!, $order: String!) {
      searchCompartiment(${filter}){
        totalCount
        results(page: $page, ordering: $order)
        {
        id,
        description,
				libelle,
				libelledb,
				typeCouche{
          id,
          libelle,
          deleted
        }
        comicone
				deleted,
        }
      }
    }
    `;

    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { page, order }).then(
          (res) => {
            resolve(res);
          }, (err) => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
      }
    );
  }
}

