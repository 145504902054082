import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";

@Injectable({
  providedIn: "root"
})
export class InformationEnfantActeurPersService {
  constructor(private utils: UtilsService) {}

  create(
    dateIdentification: String,
    dateNaissance: String,
    modeIdentification: String,
    acteur: String,
    actionRemediation: String,
    actionsSociales: String,
    classeSiScolarise: String,
    codeEnfant: String,
    deleted: Boolean,
    genre: String,
    nom: String,
    photo: String,
    prenoms: String,
    quellesActionsSociales: String,
    statutEnfant: String,
    statutSuivi: String,
    statutSurPlantation: String,
    travauxExecutes: String
  ) {
    const query = `
    mutation(
		$dateIdentification: CustomDateTime!,
		$dateNaissance: CustomDateTime!,
		$modeIdentification: String,
		$acteur: ID,
		$actionRemediation: String,
		$actionsSociales: String,
		$classeSiScolarise: String,
		$codeEnfant: String,
		$deleted: Boolean,
		$genre: String,
		$nom: String,
		$photo: String,
		$prenoms: String,
		$quellesActionsSociales: String,
		$statutEnfant: String,
		$statutSuivi: String,
		$statutSurPlantation: String,
		$travauxExecutes: String,

	) {
      createInformationEnfantActeurPers(newInformationenfantacteurpers: {
			dateIdentification: $dateIdentification,
			dateNaissance: $dateNaissance,
			modeIdentification: $modeIdentification,
			acteur: $acteur,
			actionRemediation: $actionRemediation,
			actionsSociales: $actionsSociales,
			classeSiScolarise: $classeSiScolarise,
			codeEnfant: $codeEnfant,
			deleted: $deleted,
			genre: $genre,
			nom: $nom,
			photo: $photo,
			prenoms: $prenoms,
			quellesActionsSociales: $quellesActionsSociales,
			statutEnfant: $statutEnfant,
			statutSuivi: $statutSuivi,
			statutSurPlantation: $statutSurPlantation,
			travauxExecutes: $travauxExecutes,

		}) {
        informationenfantacteurpers {
          id
					dateIdentification,
					dateNaissance,
					modeIdentification,
					acteur{ id },
					actionRemediation,
					actionsSociales,
					classeSiScolarise,
					codeEnfant,
					deleted,
					genre,
					nom,
					photo,
					prenoms,
					quellesActionsSociales,
					statutEnfant,
					statutSuivi,
					statutSurPlantation,
					travauxExecutes,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          dateIdentification,
          dateNaissance,
          modeIdentification,
          acteur,
          actionRemediation,
          actionsSociales,
          classeSiScolarise,
          codeEnfant,
          deleted,
          genre,
          nom,
          photo,
          prenoms,
          quellesActionsSociales,
          statutEnfant,
          statutSuivi,
          statutSurPlantation,
          travauxExecutes
        })
        .then(
          res => {
            if (res["createInformationEnfantActeurPers"]["ok"]) {
              resolve(
                res["createInformationEnfantActeurPers"][
                  "informationenfantacteurpers"
                ]
              );
            }
            reject(res["createInformationEnfantActeurPers"]["errors"]);
          },
          err => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
    });
  }

  update(
    id: String,
    dateIdentification: String,
    dateNaissance: String,
    modeIdentification: String,
    acteur: String,
    actionRemediation: String,
    actionsSociales: String,
    classeSiScolarise: String,
    codeEnfant: String,
    deleted: Boolean,
    genre: String,
    nom: String,
    photo: String,
    prenoms: String,
    quellesActionsSociales: String,
    statutEnfant: String,
    statutSuivi: String,
    statutSurPlantation: String,
    travauxExecutes: String
  ) {
    const query = `
    mutation(
			$id: UUID!,
		$dateIdentification: CustomDateTime!,
		$dateNaissance: CustomDateTime!,
		$modeIdentification: CustomDateTime!,
		$acteur: ID,
		$actionRemediation: String,
		$actionsSociales: String,
		$classeSiScolarise: String,
		$codeEnfant: String,
		$deleted: Boolean,
		$genre: String,
		$nom: String,
		$photo: String,
		$prenoms: String,
		$quellesActionsSociales: String,
		$statutEnfant: String,
		$statutSuivi: String,
		$statutSurPlantation: String,
		$travauxExecutes: String,
		) {
      updateInformationEnfantActeurPers(newInformationenfantacteurpers: {
				id: $id, dateIdentification: $dateIdentification,
			dateNaissance: $dateNaissance,
			modeIdentification: $modeIdentification,
			acteur: $acteur,
			actionRemediation: $actionRemediation,
			actionsSociales: $actionsSociales,
			classeSiScolarise: $classeSiScolarise,
			codeEnfant: $codeEnfant,
			deleted: $deleted,
			genre: $genre,
			nom: $nom,
			photo: $photo,
			prenoms: $prenoms,
			quellesActionsSociales: $quellesActionsSociales,
			statutEnfant: $statutEnfant,
			statutSuivi: $statutSuivi,
			statutSurPlantation: $statutSurPlantation,
			travauxExecutes: $travauxExecutes,
			}) {
        informationenfantacteurpers {
          id
					dateIdentification,
					dateNaissance,
					modeIdentification,
					acteur{ id },
					actionRemediation,
					actionsSociales,
					classeSiScolarise,
					codeEnfant,
					deleted,
					genre,
					nom,
					photo,
					prenoms,
					quellesActionsSociales,
					statutEnfant,
					statutSuivi,
					statutSurPlantation,
					travauxExecutes,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client
        .request(query, {
          id,
          dateIdentification,
          dateNaissance,
          modeIdentification,
          acteur,
          actionRemediation,
          actionsSociales,
          classeSiScolarise,
          codeEnfant,
          deleted,
          genre,
          nom,
          photo,
          prenoms,
          quellesActionsSociales,
          statutEnfant,
          statutSuivi,
          statutSurPlantation,
          travauxExecutes
        })
        .then(
          res => {
            if (res["updateInformationEnfantActeurPers"]["ok"]) {
              resolve(
                res["updateInformationEnfantActeurPers"][
                  "informationenfantacteurpers"
                ]
              );
            }
            reject(res["updateInformationEnfantActeurPers"]["errors"]);
          },
          err => {
            this.utils.setErrorsHttp(err)
            reject(err);
          }
        );
    });
  }

  read(
    filter: string = "deleted: false",
    page: number = 1,
    order: String = "-created_at"
  ) {
    filter = filter == null || filter == "" ? "deleted: false" : filter;
    const query =
      `
    query {
      searchInformationEnfantActeurPers (${filter}){
        results(page:` +
      page +
      `, ordering:` +
      order +
      `) {
          id
					dateIdentification,
					dateNaissance,
					modeIdentification,
					acteur{ id },
					actionRemediation,
					actionsSociales,
					classeSiScolarise,
					codeEnfant,
					deleted,
					genre,
					nom,
					photo,
					prenoms,
					quellesActionsSociales,
					statutEnfant,
					statutSuivi,
					statutSurPlantation,
					travauxExecutes,
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise((resolve, reject) => {
      this.utils.client.request(query).then(
        res => {
          resolve(res);
        },
        err => {
          this.utils.setErrorsHttp(err)
          reject(err);
        }
      );
    });
  }
}
