import { Injectable } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class PropositionDemandeService {

  constructor(private utils: UtilsService) { }

  create(
    commentaire: String,
     deleted: Boolean,
      demandeProduction: String,
       prixProposee: Number,
        qtePropose: Number,
        region: String,
        uniteMesure: String,
         user: number, ) {
    const query = `
    mutation(
		$commentaire: String,
		$deleted: Boolean,
		$demandeProduction: ID,
		$prixProposee: Float,
		$qtePropose: Float,
		$region: ID,
		$uniteMesure: ID,
		$user: ID,

	) {
      createPropositiondemande(newPropositiondemande: {
			commentaire: $commentaire,
			deleted: $deleted,
			demandeProduction: $demandeProduction,
			prixProposee: $prixProposee,
			qtePropose: $qtePropose,
			region: $region,
			uniteMesure: $uniteMesure,
			user: $user,

		}) {
        propositiondemande {
          id
					commentaire,
					deleted,
					demandeProduction{ id },
					prixProposee,
					qtePropose,
					region{ id },
					uniteMesure{
            id
          }
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
   
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {commentaire,
					deleted,
					demandeProduction,
					prixProposee,
					qtePropose,
					region,
					uniteMesure,
					user,
					}).then(
          (res:any) => {
         
              if (res['createPropositiondemande']['ok']) {
                resolve(res['createPropositiondemande']['propositiondemande'])
            }
              reject(res['createPropositiondemande']['errors']);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, commentaire: String, deleted: Boolean, demandeProduction: String, prixProposee: Number, qtePropose: Number, region: String, uniteMesure: String, user: String, ) {
    const query = `
    mutation(
			$id: UUID!,
		$commentaire: String,
		$deleted: Boolean,
		$demandeProduction: ID,
		$prixProposee: Float,
		$qtePropose: Float,
		$region: ID,
		$uniteMesure: String,
		$user: ID,
		) {
      updatePropositiondemande(newPropositiondemande: {
				id: $id, commentaire: $commentaire,
			deleted: $deleted,
			demandeProduction: $demandeProduction,
			prixProposee: $prixProposee,
			qtePropose: $qtePropose,
			region: $region,
			uniteMesure: $uniteMesure,
			user: $user,
			}) {
        propositiondemande {
          id
					commentaire,
					deleted,
					demandeProduction{ id },
					prixProposee,
					qtePropose,
					region{ id },
					uniteMesure,
					user{ id },
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, commentaire,
					deleted,
					demandeProduction,
					prixProposee,
					qtePropose,
					region,
					uniteMesure,
					user,
					}).then(
          (res) => {
         
            if (res['updatePropositionDemande']['ok']) {
              resolve(res['updatePropositionDemande']['propositiondemande'])
            }
            reject(res['updatePropositionDemande']['errors']);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchPropositionDemande (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					commentaire,
					deleted,
					demandeProduction{ id },
					prixProposee,
					qtePropose,
					region{ id },
					uniteMesure,
					user{
						profile {
            admin
            deleted
            photo
            photoUrl
            id, 
            codeDeVerification, 
            contact, 
            dateNaissance, 
            deleted, 
            displayName, 
            genre, 
            nom, 
            prenom, 
            tracking, 
            entreprise {
              logo
              logoUrl 
              id, 
              raisonSociale,
              telephone
               email
              categorie{
                libelle,
                numero
              }
            }
          }
        }
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }
}

