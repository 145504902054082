import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "zoneFilter",
  pure: false,
})
export class LibZoneFilterPipe implements PipeTransform {
  transform(value: any[], args: string ) {

    if (!value) return null
    if(!args) return value

    args = args.toLowerCase();

    return value.filter((value: any) => value.libelle.toLowerCase().includes(args));
  }
}
