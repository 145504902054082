import { PropositionDemandeComponent } from './../_modals/proposition-demande/proposition-demande.component';
import { MatDialog } from '@angular/material';
import { Component, OnInit, Input } from '@angular/core';
import { SuccessMsgComponent } from 'src/app/_modale/success-msg/success-msg.component';
import { ErrorMsgComponent } from 'src/app/_modale/error-msg/error-msg.component';

@Component({
  selector: 'app-demande-de-production-item',
  templateUrl: './demande-de-production-item.component.html',
  styleUrls: ['./demande-de-production-item.component.scss']
})
export class DemandeDeProductionItemComponent implements OnInit {
  @Input() data: any;
  constructor(private dialog: MatDialog, ) { }

  ngOnInit() {
  }

  repondre() {
    const dialogRef = this.dialog.open(PropositionDemandeComponent,
      {
        width: '35rem',
        data: { instance: this.data }
      });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.dialog.open(SuccessMsgComponent,
            {
              width: '25rem',
              data: {
                msg:
                'Votre proposition à été enregistrée avec succès. Un administrateur vous contactera pour les détails de la transaction.'
              }
            });
        } else {
          this.dialog.open(ErrorMsgComponent,
            {
              width: '25rem',
              data: {
                msg: 'Une erreur est survenue veuillez réessayer plus tard.'
              }
            });
        }
      }
    );
  }

}
