import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmComponent } from 'src/app/confirm/confirm.component';

@Component({
  selector: 'app-new-alert-confirm',
  templateUrl: './new-alert-confirm.component.html',
  styleUrls: ['./new-alert-confirm.component.scss']
})
export class NewAlertConfirmComponent implements OnInit { 
  sendToParent: any = '___'; // cette variable est utilisé dans la mesure où le user clique à l'extérieur du pop-up pour le fermer, dans ce cas on envoie 3 underscores pour différencier.
  constructor(
    private matdialog: MatDialogRef<NewAlertConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ){ }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.matdialog.close(this.sendToParent);
  }

  yes() {
    this.sendToParent = true;
    this.matdialog.close(true);
  }

  no() {
    this.sendToParent = false;
    this.matdialog.close(false);
  }
  cancelDialog(){
    this.sendToParent = false;
    this.matdialog.close(false)
  }
}
