import { Injectable } from '@angular/core';
import { UtilsService } from 'src/app/_services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CaracteristiquesOffreService {

  constructor(private utils: UtilsService) { }

  create(deleted: Boolean, offreVente: String, prixVente: Number) {
    const query = `
    mutation(
		$deleted: Boolean,
		$offreVente: ID,
		$prixVente: Float,
	) {
      createCaracteristiquesoffre(newCaracteristiquesoffre: {
			deleted: $deleted,
			offreVente: $offreVente,
			prixVente: $prixVente,
		}) {
        caracteristiquesoffre {
          id
					deleted,
					offreVente{ id },
					prixVente,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, {deleted,
			    offreVente,
					prixVente,
					}).then(
          (res:any) => {
            
            if (res.createCaracteristiquesoffre.ok) {
                resolve(res.createCaracteristiquesoffre.caracteristiquesoffre);
            }
            reject(res.createCaracteristiquesoffre.errors);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  update(id: String, deleted: Boolean, offreVente: String, prixVente: Number, qteGenDispo: Number, ) {
    const query = `
    mutation(
			$id: UUID!,
		$deleted: Boolean,
		$offreVente: ID,
		$prixVente: Float,
		$qteGenDispo: Float,
		) {
      updateCaracteristiquesoffre(newCaracteristiquesoffre: {
				id: $id, deleted: $deleted,
			offreVente: $offreVente,
			prixVente: $prixVente,
			qteGenDispo: $qteGenDispo,
			}) {
        caracteristiquesoffre {
          id
					deleted,
					offreVente{ id },
					prixVente,
					qteGenDispo,
					createdAt
					updateAt
				}
        errors {
          messages
          field
        }
        ok
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query, { id, deleted,
					offreVente,
					prixVente,
					qteGenDispo,
					}).then(
          (res:any) => {
            
            if (res.updateCaracteristiquesoffre.ok) {
                resolve(res.updateCaracteristiquesoffre.caracteristiquesoffre);
            }
            reject(res.updateCaracteristiquesoffre.errors);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }

  read(filter: string = 'deleted: false', page: number=1, order: String='-created_at') {
    filter = (filter == null || filter == '')?'deleted: false':filter;
    const query = `
    query {
      searchCaracteristiquesOffre (${filter}){
        results(page:` + page +`, ordering:` + order + `) {
          id
					deleted,
					offreVente{ id },
					prixVente,
					qteGenDispo,
					createdAt
					updateAt
				}
          totalCount
      }
    }
    `;
    return new Promise(
      (resolve, reject) => {
        this.utils.client.request(query).then(
          (res) => {
            resolve(res);
          }, (err) => {
            reject(err);
          }
        );
      }
    );
  }
}

