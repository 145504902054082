import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-lecteur-video',
  templateUrl: './lecteur-video.component.html',
  styleUrls: ['./lecteur-video.component.css']
})
export class LecteurVideoComponent implements OnInit {

  @Input() 
  movieLink = "https://instagram.fabj4-1.fna.fbcdn.net/vp/ee56b8eb997092bf7fd32084eb42bb9a/5DBBD168/t50.2886-16/69752457_2343991529177745_7238707016135590506_n.mp4?_nc_ht=instagram.fabj4-1.fna.fbcdn.net&_nc_cat=109&_nc_ig_catcb=1";

  constructor() { }

  ngOnInit() {
    
  }

}
